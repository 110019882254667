
import React, { Suspense, Component } from 'react';
import ReactDOM from 'react-dom';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // initialized i18next instance

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import configureStore from './store';
import { BrowserRouter } from 'react-router-dom';

const { store, persistor } = configureStore();


ReactDOM.render(
					<I18nextProvider i18n={ i18n }>

						<Provider store={store}>

							<PersistGate loading={null} persistor={persistor}>

								<BrowserRouter>

									<Suspense fallback="loading"> 


										<App i18n={ i18n }/>


									</Suspense>

								</BrowserRouter>

							</PersistGate>

						</Provider>

					</I18nextProvider>

					,
					document.getElementById('root')
);//

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
