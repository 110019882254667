
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, multiSelectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';


import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {getInstallationGroups} from '../actions/core';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import CONFIG from '../config/config';

import Select from 'react-select';
import { Trans } from 'react-i18next';

import { withTranslation } from 'react-i18next';

import SelectorInstalaciones from './components/installations_select';



import { setup } from 'axios-cache-adapter';



const { ExportCSVButton } = CSVExport;

const $ = window.$;


const selectOptions =
						{
							"has_uv_sensor": "UV Sensor",
							"has_polen_sensor": "Polen Sensor",
							"has_presence": "Presence Sensor",
							"has_traffic_sensor": "Traffic Sensor",
							"has_noise_sensor": "Noise Sensor",
							"has_air_sensor": 'Air Sensor',
							"hybrid": 'Hybrid',
							"has_surveillance": "Surveillance"
						};


const axios = setup(
						{
							cache:
									{
										maxAge: 15 * 1000
									}
						}
					) // setup()


var CONT_axios_Alarms = 0;
var AUX_str_ALL_SLs_Alarms_Disabled =  "";						


//---------------------------------------------------------------------------------------------------------------------


class Farolas extends Component
{

	constructor (  props  )
	{

		super( props );

		let installations= props.core.installations.slice();

		installations.shift();

		this.state =	{
							installations: installations,

							//selected_installation: {installation_name: "", id:"", streetlights: []},

							selected_installationgroup:
							{
								group_name: "",
								id: "",

								info:
								{
									streetlights: [],
									available_sensors:[]
								}

							},

							selected_streetlight: {  name: ""  },

							view_as_cards: true,

							total_streetlights: 0,
							current_page: 0,


							str_ALL_SLs_Alarms_Disabled: ""
						};

		
		this.exportarCSV = this.exportarCSV.bind( this );

	} // constructor (  props  )



	//----


	
	componentDidMount (  )
	{


		CONT_axios_Alarms = 0;

	} // componentDidMount (  ) ----------------------------------------------------------------------------------------------------------------



	//----



	exportarCSV (  ) // -----------------------------------------------------------------------------------------------------------------------
	{
		let csvContent = "serie;modelo;luminaria;panel;bateria;sensores"  +  "\r\n";

		//console.log(  this.state.selected_installationgroup.info.streetlights  );

		this.state.selected_installationgroup.info.streetlights.map(
																		( item, index ) =>
																		{
																			csvContent += item.serial_streetlight  +  ";";
																			csvContent += item.model  +  ";";
																			csvContent += item.total_power  +  ";";
																			csvContent += item.panel.peak_power  +  ";";
																			csvContent += item.battery.description  +  ";";
																			csvContent += item.combined_sensors.join(",")  +  "\r\n";
																		}
		);    

		// The download function takes a CSV string, the filename and mimeType as parameters
		// Scroll/look down at the bottom of this snippet to see how download is called

		var download =
						function (  content, fileName, mimeType  )
						{
							var a = document.createElement( 'a' );
							mimeType = mimeType  ||  'application/octet-stream';

							if (  navigator.msSaveBlob  )
							{ 
								// IE10
								navigator.msSaveBlob( 
														new Blob( 
																	[ content ],
																	{
																		type: mimeType
																	}
														)
														, fileName
								);
							}
							else
								if (  URL  &&  'download' in a  )
								{
									//html5 A[download]

									a.href = URL.createObjectURL(
																	new Blob(
																				[ content ],
																				{
																					type: mimeType
																				}
																	)
									);

									a.setAttribute(  'download', fileName  );
									document.body.appendChild(  a  );

									a.click();
									document.body.removeChild(  a  );
								}
								else
								{
									this.window.location.href =  'data:application/octet-stream,'  +  encodeURIComponent( content ) ;	// only this mime type is supported
								}
						} // function


		download(
					csvContent,
					'dowload.csv',
					'text/csv;encoding:utf-8'
		);

		//console.log(  this.state.selected_installationgroup.info.streetlights  );

	} // exportarCSV (  ) -------------------------------------------------------------------------------------------------------------------------------------



	//----



	render (  )
	{

		const [ page,  sizePerPage,  totalSize ] =
													[
														this.state.current_page,
														250,
														this.state.total_streetlights
													];

		setTimeout(
					( e ) =>
					{
						if(  $( "select>option[value='has_air_sensor']" ).parent().parent().find( "span" ).length < 1  )
						{
							$( "<span>Sensores</span>" ).insertBefore(  $("select>option[value='has_air_sensor']").parent()  )
						}

						$( "select>option[value='has_air_sensor']" ).parent().removeAttr( "multiple" );
						$( ".react-bootstrap-table-pagination" ).hide();
					}

					, 100
		);
		
		/*
		if (this.state.selected_installationgroup.group_name==""){
			if(this.state.groups.length>0) {
				this.setState({selected_installation: this.state.installations[0]});
			}
		}

		if (this.state.selected_streetlight.name=="" ) {
			if(this.state.installations.length>0 && this.state.installations[0].streetlights.length>0) {
				this.setState({selected_streetlight: this.state.installations[0].streetlights[0]});
			}
		}
		*/

		
		const columns =
						[
						
						
						
							{
								dataField: 'id',

								text: '',

								sort: true,
								headerClasses: 'd-none d-md-table-cell',
								classes: 'd-none d-md-table-cell',
								
								formatter: 	( cell, row ) =>
											{
												return	<div style = { { width: "30px" } } >
														{
															!row.cpu_is_master  && 

															<img 
																src =	{
																			/*
																			(   (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( row.serial_streetlight ).toLowerCase()   )  <=  -1    )
																			?
																			
																				(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )		?	"https://api.ilumek.com/static/markers/greendothole32.png"	:	`${CONFIG.server_url}/${row.marker}`
																			:
																				(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )		?	`${CONFIG.server_url}/${row.marker}`	:	"https://api.ilumek.com/static/markers/greendothole32.png"			//`${CONFIG.server_url}/${row.marker}`
																			*/


																			(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
																			?
																				`${ CONFIG.server_url }/${ row.marker }`
																			:
																				(
																					(    (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( row.serial_streetlight ).toLowerCase()   )  <=  -1    )
																			
																					?
																						(
																							"https://api.ilumek.com/static/markers/green"	
																																			+	(
																																					(  row.cpu_is_master  ==  true  )
																																					?
																																						"dot"
																																					:
																																						""
																																				)

																																			+	"hole32.png"
																						)
																					:
																						`${ CONFIG.server_url }/${ row.marker }`
																				)
																		}

																style = { { marginLeft: "10px" } }
															/>
														}

														{
															row.cpu_is_master  && 

															<img
																src =	{
																			(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
																			?
																				`${ CONFIG.server_url }/${ row.marker }`
																			:
																				(
																					(    (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( row.serial_streetlight ).toLowerCase()   )  <=  -1    )
																			
																					?
																						(
																							"https://api.ilumek.com/static/markers/green"	
																																			+	(
																																					(  row.cpu_is_master  ==  true  )
																																					?
																																						"dot"
																																					:
																																						""
																																				)

																																			+	"hole32.png"
																						)
																					:
																						`${ CONFIG.server_url }/${ row.marker }`
																				)
																		}

																style = { { marginLeft: "0px" } }
															/>
														}
														</div>
											}
							},






							{
								dataField: 'name',

								text: <Trans>installation.streetlight</Trans> ,

								sort: true,
								filter: textFilter( {placeholder: '...'} ),
								
								
								formatter:
											( value, row ) =>
											{
											
												return (
												
														<div>
															<p  style={{marginTop: "-10px", marginBottom: "-20px"}}
															
																onMouseOver=    {
																				( event ) => 
																				{
																					//window.alert( event.target.innerHTML );
																					//event.target.innerHTML = ":)";
																					
																					event.target.style.color = "blue";
																				}
																			}
																			
																onMouseOut=    {
																					( event ) => 
																					{
																						//window.alert( event.target.innerHTML );
																						//event.target.innerHTML = ":)";
																						
																						event.target.style.color = "black";
																					}
																				}
																				
																onClick=    {
																			( event ) => 
																			{
																				//window.alert( /*event.target.innerHTML*/ this.props.location.search );
																				
																				
																				//console.log( this.state.selected_installationgroup );
																				
																				let my_SL_NAME =  event.target.innerHTML;
																				
																				let my_SL_id = "";
																				let my_INSTA_id ="";
																				
																				let ar_SLs =  this.state.selected_installationgroup.info.streetlights;
																				
																				ar_SLs.every(
																								( element ) =>
																								{
																								
																									if ( element.serial_streetlight  ==  my_SL_NAME )
																									{
																										my_SL_id    = element.id;
																										my_INSTA_id = element.installation_id;
																									}// if
																								
																									return ( element.serial_streetlight  !=  my_SL_NAME );
																								}
																				); //.every()
																				
																				
																				//let new_url = "https://apps.ilumek.com/instalaciones?sl_name="  +  my_SL_NAME;

																				let new_url =   "https://apps.ilumek.com/instalaciones" +
																				
																								"?installation_id=" +   my_INSTA_id     +
																								"&sl_id="           +   my_SL_id;
																				
									
																				
																				window.location.assign( new_url );
																				
																				//window.alert( new_url );
																				
																				//event.target.innerHTML = ":)";
																			}
																		}

															>{value}</p>
															
														</div>
														
												)// return
											}								
							},




							{
								dataField: 'serial_streetlight',

								text: <Trans>installation.serial</Trans> ,   

								headerClasses: 'd-none d-lg-table-cell',
								classes: 'd-none d-lg-table-cell',      
								sort: true,
								filter: textFilter( {placeholder: '...'} ),
											
							},





							{
								dataField: 'model',

								text: <Trans>installation.model</Trans> ,

								headerClasses: 'd-none d-lg-table-cell',
								classes: 'd-none d-lg-table-cell',              
								sort: true,
								filter: textFilter( {placeholder: '...'} )
							},




							{
								dataField: 'total_power',

								text: <Trans>sl.luminary_power</Trans> ,

								sort: true,
								filter: textFilter( {placeholder: '...'} )
							},




							{
								dataField: 'panel.peak_power',

								text: <Trans>sl.solar_panel_power</Trans> ,

								sort: true,
								filter: textFilter( {placeholder: '...'} )
							},





							{
								dataField: 'battery.capacity',

								text: <Trans>sl.battery_capacity</Trans> ,

								sort: true,
								filter: textFilter( {placeholder: '...'} )
							},




							{
								dataField: 'combined_sensors',

								text: '',
								headerClasses: 'd-none d-md-table-cell',
								classes: 'd-none d-md-table-cell',

								headerStyle:
											( colum, colIndex ) =>
											{
												return { minWidth: '160px', textAlign: 'center' };
											},

								formatter:
											( value, row ) =>
											{
												let items = [];

												value.map(
															( item, index ) =>
															{
																items.push(
																			<img
																				src = { CONFIG.getIconPath(item).img }
																				alt = { CONFIG.getIconPath(item).name }
																				style = { { width: "20px", margin: "5px" } }
																				data-toggle = "tooltip"
																				data-placement = "top"
																				title = { CONFIG.getIconPath(item).name }
																			/>
																)	
															}

												); // value.map()

												return ( <div> { items } </div> )
											},

								filter:
										multiSelectFilter(
															{
																options: selectOptions,
																placeholder: `${this.props.t("main.sensors")}`,

																render:
																		( e ) =>
																		{
																			return <div> aaa </div>
																		},

																defaultValue: []
															}
									) // multiSelectFilter
							}
							
							
							
						]; // const columns = []


		const defaultSorted =	[
									{
										dataField: 'name',
										order: 'asc'
									}
								];



		//-----------------------------------------------------------------------------------------
		
		
		if (   CONT_axios_Alarms  <=  0   )
		{

			//window.alert(  this.props.auth.user.user_profile.userlevel  );

			let str_FILTER_VAL =  (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )	?	"true"	:	"false";


			let str_QUERY_INST_GROUP_NAME =		(   this.state.selected_installationgroup.group_name  !=  ""   )
												?
													(   this.state.selected_installationgroup.group_name  !=  "All"   )
													?
														'"streetlight.installation_group":'		+
														'{'										+
															'"filterVal":'	+	'"'				+  this.state.selected_installationgroup.group_name		+	'"'		+	','		+
															'"filterType":"SELECT",'			+
															'"comparator":"=",'					+
															'"caseSensitive":false'				+
														'}, '
													:
														""
												:
													"";

			//--------

			if (
					this.state.selected_installationgroup.group_name  !=  ""	&&
					this.state.selected_installationgroup.group_name  !=  "All"
			)
			{
			
				axios.get(
								CONFIG.api_url	+	"/alarms"	+
																"?page=1"	+
																"&filtro="	+

																'{'			+
																	'"type":"filter",'	+
																	
																	'"value":'				+
																	'{'						+
																		'"sortField":null,'	+
																		
																		'"filters":'		+
																		'{'					+


																			str_QUERY_INST_GROUP_NAME	+


																			'"disabled_alarms":'					+
																			'{'										+
																				'"filterVal":'						+	str_FILTER_VAL	+	','		+
																				'"filterType":"SELECT",'			+
																				'"comparator":"=",'					+
																				'"caseSensitive":false'				+
																			'}'										+

																		'},'										+
																		
																		'"page":0,'									+
																		'"sizePerPage":100'							+

																	'}'												+

																'}'
								,																//'&fromDate=1587748699&toDate=1653377439',
																								//  "filterVal":false,  -->  AHORA: "filterVal":true,
								{
									"headers":
												{
													'Content-Type': 'application/json',
													'Authorization': "Token " + this.props.auth.token
												}
								}
				).then(

							( result ) =>
							{


								AUX_str_ALL_SLs_Alarms_Disabled =  JSON.stringify(  result.data.info.all_streetlights  );


								//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );


								AUX_str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled.toLowerCase();



								//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );



								//console.log(  "HA LLEGADOF AQUÍ, ALARMAS"  );
								//console.log(  AUX_str_ALL_SLs_Alarms_Disabled  );
								//console.log(  result.data  );



								this.state.str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled;

													
								this.setState(	
												{
													str_ALL_SLs_Alarms_Disabled: 	AUX_str_ALL_SLs_Alarms_Disabled,
												}
								);//


								//CONT_axios_Alarms++;
								
							}

				);// ... .then() -----



				//-----

				CONT_axios_Alarms++;

			}// if ( ... )



		}// if (   CONT_axios_Alarms == 0   )


		//-----------------------------------------------------------------------------------------




		return (
					/* 1ero  <div className="container-fluid" >*/
					<div className="container-fluid" >

						<div className="row">

							{  /*<div className="row">: LISTA DESPLEGABLE INSTALLATION_GROUP*/  }
							<div id="cab-general" className="container-fluid" style={{marginLeft: "3px"}}>
								<div className="actions-left col-md-4 col-xs-12" style={{marginTop: "-5px"}}>
								

									<li className="nav-item dropdown" >

										<SelectorInstalaciones

											showAll = { false }

											onDataLoaded =
															{
																( e ) =>
																{
																	//this.setState({selected_installationgroup: e});

																	CONT_axios_Alarms = 0;

																}
															}
											
											onDataChanged =
															{
																( e ) =>
																{

																	CONT_axios_Alarms = 0;

																	this.setState(
																					{
																						selected_installationgroup: e,

																						total_streetlights: e.info.streetlights.length
																					}
																	);

																	//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);
																	//CONFIG["setPreferredInstallation"](e.value);                                                      
																}
															}
										/>

									</li>

								</div>
							</div>
							{  /*<div className="row">: END: LISTA DESPLEGABLE INSTALLATION_GROUP*/  }

						</div>



						{ /* 2do <div className="container-fluid"> */  }
						<div className="container-fluid">
							<div className=" vista-tabla" style={{marginBottom: "100px"}}>
							{
								this.state.selected_installationgroup.info.streetlights.length > 0  &&

								<ToolkitProvider
								
									keyField = "id"
									data = { this.state.selected_installationgroup.info.streetlights }
									columns = { columns }
									
									exportCSV
								>
								{
									props =>
									(
										//console.log(   "SERGIO_2"   ),
										
										<div style={{marginTop: "-1em"}} className="compactTable">
											
											{/*<br/>*/}

											<div style={{textAlign: "right", width: "100%"}}>
												<button
													className="btn btn-orange"
													onClick=
															{
																( e ) =>
																{
																	this.exportarCSV()
																}
															}
												>
													<Trans>sl.export</Trans>
												</button>
											</div>

											
											{ /* TABLA DE FAROLAS*/ }
											
											<hr />

											{
											this.props.auth.user.user_profile.userlevel == "EKIONA" &&
											
											<center> <h3> { this.state.selected_installationgroup.group_name } </h3> </center>
											}

											<BootstrapTable

												keyField='id'
												data=	{  this.state.selected_installationgroup.info.streetlights }
												columns={ columns }
												filter = { filterFactory() }

												pagination =
															{
																paginationFactory(
																					{
																						page, 					// Specify the current page. It's necessary when remote is enabled
																						sizePerPage,			 // Specify the size per page. It's necessary when remote is enabled
																						totalSize,				// Total data size. It's necessary when remote is enabled
																						pageStartIndex: 0,	 // first page will be 0, default is 1
																						paginationSize: 5,	 // the pagination bar size, default is 5
																						showTotal: true, 		// display pagination information

																						sizePerPageList:	[
																												{
																													text: '5',
																													value: 5
																												},

																												{
																													text: '10',
																													value: 10
																												},

																												{
																													text: 'All', value: this.state.total_alarms
																												}
																											],		 	// A numeric array is also available: [5, 10]. the purpose of above example is custom the text

																						withFirstAndLast: false,	 // hide the going to first and last page button
																						alwaysShowAllBtns: true,	// always show the next and previous page button

																						firstPageText: 'First',			// the text of first page button
																						prePageText: 'Prev',			// the text of previous page button
																						nextPageText: 'Next',			// the text of next page button
																						lastPageText: 'Last',			// the text of last page button
																						nextPageTitle: 'Go to next',		// the title of next page button
																						prePageTitle: 'Go to previous',	// the title of previous page button
																						firstPageTitle: 'Go to first',		// the title of first page button
																						lastPageTitle: 'Go to last',			// the title of last page button
																						
																						hideSizePerPage: true,				// hide the size per page dropdown
																						hidePageListOnlyOnePage: true, 	// hide pagination bar when only one page, default is false

																						onPageChange:
																										( page, sizePerPage, c ) =>
																										{
																											//console.log( c );
																											this.recogerAlarmas( page )
																										},		 // callback function when page was changing

																						onTableChange:
																										( type, newState ) =>
																										{
																											console.log(type);
																										}
																					}
																) // paginationFactory(
															}
											/>

										</div>
									)
								}
								</ToolkitProvider>
							}

							</div>
						
						</div>
						{ /*END:  2do  <div className="container-fluid"> */  }
					</div>
					/* END: 1ero <div className="container-fluid" >*/

		) // return(

		//---

	} // render (  ) ---------------------------

	//-----
  
} // class Farolas extends Component ---------------------------------------------------------------


//------------


const mapStateToProps =		function ( state )
							{
								return   {
											auth: state.auth,
											core: state.core
										}
							}


const mapDispatchToProps =	{
  								getInstallationGroups: getInstallationGroups,
							}


export default withTranslation() (  connect( mapStateToProps, mapDispatchToProps  )( Farolas )  );
