import React, { Component } from 'react';
//import ReactDOM from 'react-dom';				//----------------------------------------------
import { Link } from "react-router-dom";

import { connect } from 'react-redux';

//import {login} from '../actions/auth';

import {saveProfile} from '../actions/core';

import 'rc-slider/assets/index.css';
import i18n from '../i18n';


import Tooltip from 'rc-tooltip';


import Slider from 'rc-slider';

import Moment from 'moment';



import Spinner from 'react-spinner-material';


import { withTranslation } from 'react-i18next';

import { setup } from 'axios-cache-adapter';

import CONFIG from '../config/config';


import SelectorInstalaciones from './components/installations_select';


//------------------------

import Select from 'react-select';
import { Trans } from 'react-i18next';


//-------------------------

//var offsHOUR_timezone_installation_group =  0;

const years = ['  -  ', 2022, 2023, 2024];

let preferredLang = localStorage.getItem("ilumekcache_preferredLanguage");

if (preferredLang === null) {

	preferredLang = "es"

} else {

	preferredLang = JSON.parse(preferredLang)

}

// Añadir meses en Euskera luego DPA
const months = preferredLang === "es" 
									  ?
											[
												'  -  ',
												'Enero',
												'Febrero',
												'Marzo',
												'Abril',
												'Mayo',
												'Junio',
												'Julio', 
												'Agosto',
												'Septiembre',
												'Octubre',
												'Noviembre',
												'Diciembre'
											]
									  :
											[
												'  -  ',
												'January',
												'February',
												'March',
												'April',
												'May',
												'June',
												'July', 
												'August',
												'September',
												'October',
												'November',
												'December'
											]

const createSliderWithTooltip = Slider.createSliderWithTooltip;

const Range = createSliderWithTooltip( Slider.Range );
const Handle = Slider.Handle;


const $ = window.$;




var pdfMake = require( 'pdfmake/build/pdfmake.js' );
var pdfFonts = require( 'pdfmake/build/vfs_fonts.js' );

pdfMake.vfs = pdfFonts.pdfMake.vfs;

/*
var my_docDefinition =	{
							content : [ 'hellloo helloo hellooo' ]
						};
*/

//-----------------------------------------------------




const selectStyles =	{
							menu:
								styles =>
								(
									{
										...styles,
										zIndex: 999
									}
								)
						};

const axios =	setup(
						{
							cache:	{
										maxAge: 1000
									}
						}
				)//


const handle =	( props ) =>
				{
					const	{
								value,
								dragging,
								index,
								...restProps
							}
							= props;


					return	(
								<Tooltip
										prefixCls = "rc-slider-tooltip"
										overlay = { value }
										visible = { dragging }
										placement = "top"

										key = { index }
								>
									<Handle value = { value } { ...restProps } />

								</Tooltip>
					);
				}; //

let initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );



let _data_PREV  = [];		// UNAI

let _rangos_PREV = [];	// UNAI


let _chart_data = {};


// --------------


var _data_Retrieved = false;    // 		_data_Retrieved = false;  (en ComponentDidMount)



var _Num_Renders_HTML_to_IMG = 0;

var _Num_Renders_RENDER_FUNC = 0;




var STR_meses =	[
					"enero",
					"febrero",
					"marzo",

					"abril",
					"mayo",
					"junio",

					"julio",
					"agosto",
					"septiembre",

					"octubre",
					"noviembre",
					"diciembre"
				];





var NUM_mondayStart_date__TIMEZONE;
var our_monday_date;


//-------------------




var NUM_SEMANAS_mes = 0;


var NUM_MES_Selected =  -1; //  Desde 1 hasta 12

var NUM_DAYS_MONTH =  -1;	// Desde 28 hasta 31





var DAYS_MONTH__last_week = -1;  // la 5ta semana


var NUM__FIRST_day_MONTH = -1;
var NUM__LAST_day_MONTH = -1;




var STR__FIRST_day_MONTH = "";
var STR__LAST_day_MONTH =  "";


var TIMESTAMP__FIRST_day_MONTH = -1;
var TIMESTAMP__LAST_day_MONTH = -1;





var GLOBAL_SIG_dat_SL_week =  3;	// 3	//1;






var STR_DiAS_MES = [];



var thisYEAR =  -1;  // INIT A UN VALOR NO POSIBLE


//var PULSADO_BOTON =  false;



var group_id =  "";
var INI_componentDidMount__COUNT = 0;



//--------------------------------------------------------





function DaysMonth (  month_num,  year  )		// month_num: del 1 al 12
{

	return new Date(   year,  month_num,  0  ).getDate( );

}//






// ---------------------------------------------


Date.prototype.addDays =	function ( days )
							{
								var dat = new Date(  this.valueOf() );

								dat.setDate(  dat.getDate() + days  );


								return dat;
							}//--



function getDatesBetween ( startDate,  stopDate )
{

	var dateArray = new Array();

	var currentDate = startDate;


	while (  currentDate <= stopDate  )
	{
		dateArray.push(  currentDate  );

		currentDate = currentDate.addDays( 1 );
	}


	//console.log(  dateArray  );

	return dateArray;

 }// function getDates ( startDate,  stopDate )





//-------------------------------------------------



//---------------------------------------------------------------------------------------------------------------------------------------------------




//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------



class Informes extends Component
{

	constructor ( props )
	{
		super( props );


		//_chart_data =  	_data_PREV;  // data comienza siendo array vacio



		this.state =	{
							//isNew: true,

							showSpinner: false,

							/*
							LAST_re_render: "false",

							SCRIPT_svg_rendered: "false",

							ar_sunriseDAY: [],
							ar_sunsetDAY: [],
							*/
							//sunriseDAY: "",
							//sunsetDAY: "",

							/*
							sunRise_sunSet_GET: "false",
							*/

							//pulsado_Button:  "false",


							id:	"",		//props.match.params.id,


							//schedule_id : -1,

							//scheduleprofile: {},


							viene_de_antes:  "true",



							LAST_re_render: "false",

							SCRIPT_svg_rendered: "false",

							//ar_sunriseDAY: [],
							//ar_sunsetDAY: [],

							//sunriseDAY: "",
							//sunsetDAY: "",

							sunRise_sunSet_GET: "false",








							selected_installationgroup:	{
															group: "",

															id: "",

															streetlights: []
														},


							//comp_CHART_DATA: [],


							view_as_cards: true,
							modalIsOpen: true,
							cntrlIsPressed: false,
							capsIsPressed: false,
							initialCaps: null,
							showAlert: false,

							profileName: "",
							profileComments: "",
							selectedYear: 2024,
            				selectedMonth: new Date().getMonth(),

							//wait_in: "seconds",

							//time_to_wait: 60,

							//dimming: 0,//,


							//waiting: true

							//htmltoIMG_onLOAD: false

						}; //--------------------------------



		//this.checkValue = this.checkValue.bind( this );

		this.cntrlIsPressed = false;
		this.capsIsPressed = false;


		this.upperSlider = undefined;



	}//	constructor ( props ) --------------------------------


	componentWillUnmount ( )
	{

		//CONT_procesado_SLs =  0;




		//NUM_CALL_API =  0;



		//TODOS_DATOS_ACCESIBLES =  false;

		//HTML_GRAFS_GEN_DATOS_ACCESIBLES =  false;




		//NUM_renders_SVG_rendered =  0;

		//CONT_procesado_SLs =  0;





		this.state.LAST_re_render = "false";

		this.state.sunRise_sunSet_GET = "false";

		this.state.SCRIPT_svg_rendered = "false";


		this.setState(

			{
				LAST_re_render: "false",

				sunRise_sunSet_GET: "false",

				SCRIPT_svg_rendered: "false",


				total_streetlights: 0,


				selected_installationgroup:  {},

			}

		); // this.setState(

	}// componentWillUnmount ( )




	//-------------------------------------------------------------------------------------------------------------------------


	componentDidMount ( )
	{
				//$( "header" ).innerHTML = "";
		//		$( "header" ).html( "" );


		//		$( "footer" ).css(  "background-color",  $( "body" ).css( "background-color" )  );

				//$( "footer" ).innerHTML = "";
		//		$( "footer" ).html( "" );

				//---



		//AMANECERES_WEEK = new Array();
		//ATARDECERES_WEEK = new Array();

		// TERMINAR ESTO * DPA *
		let localStorageMonth = localStorage.getItem('selectedMonth')
		let localStorageYear = localStorage.getItem('selectedYear')

		let actualDate = new Date();
		let previousMonth = undefined;

		if (actualDate.getMonth() === 1){

			previousMonth = 12

		}else{
			
			previousMonth = actualDate.getMonth()

		}
		

		localStorageMonth !== null 
									? this.state.selectedMonth = localStorageMonth 
									: this.state.selectedMonth = previousMonth
		
		localStorageYear !== null 
									? this.state.selectedYear = localStorageYear
									: this.state.selectedYear = actualDate.getFullYear()

		localStorage.setItem('selectedMonth', this.state.selectedMonth)
		localStorage.setItem('selectedYear', this.state.selectedYear)

		
		//PULSADO_BOTON =  false;

		_Num_Renders_HTML_to_IMG = 0;   // 190122


		_Num_Renders_RENDER_FUNC = 0;

			//_cont_rerender_svg = 0;


		this.state.viene_de_antes =  "true";


		this.state.LAST_re_render = "false";

		this.state.sunRise_sunSet_GET = "false";

		this.state.SCRIPT_svg_rendered = "false";



		this.setState(

			{
				LAST_re_render: "false",

				sunRise_sunSet_GET: "false",

				SCRIPT_svg_rendered: "false",


				viene_de_antes:  "true",


				total_streetlights: 0,


				selected_installationgroup:  {}
			}

		); // this.setState(





		this.setState(   this.constructor()   );

		//---


		if	(
				(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
			)
		{

			//-----

			/*

			this.state.pulsado_Button =  "true";

			this.setState(
							{
								pulsado_Button: "true",
							}
			); //----
			*/



			if (  true /*PULSADO_BOTON  ==  false*/  )
			{

				//let initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );

				//const this_ =  this;		// (1ero puesto)


				//---

				/*
				GLOBAL_SIG_dat_SL_week =  parseInt(  this.props.location.search.split(  "&drate="  )[ 1 ].split( "&monday=" )[ 0 ]  );

				if (
						(  GLOBAL_SIG_dat_SL_week < 1  ) ||
						(  GLOBAL_SIG_dat_SL_week > 3  )
				)
				{
					GLOBAL_SIG_dat_SL_week =  3;

				}// if ---
				*/


				GLOBAL_SIG_dat_SL_week =  3;


				//---

				//  SERGIO **********************************************************************************************************************************************************/
				/*
				let aux_Today = new Date();



				let our_MONTH =  aux_Today.getMonth()  +  1;		// 1 ... 12


				let our_MONTH_ANT =	(  our_MONTH  >=  2  )
									?
										(  our_MONTH - 1  )
									:
										12;		// AÑO ANTERIOR!


				let str_month_ANT =	(  our_MONTH_ANT  >=  10     )			// aux_Today.getMonth()  =>   de 0 ...11
									?
										String( our_MONTH_ANT  )
									:
										"0"  +  String( our_MONTH_ANT  );			//..... PONERLE EL '0' POR DELANTE

									//  +  0  =>  MES ANTERIOR AL ACTUAL


				let our_YEAR =	(  our_MONTH_ANT  !=  12  )
								?
									aux_Today.getFullYear()
								:
									(  aux_Today.getFullYear() - 1  );


				//window.alert(   aux_Today.getMonth()   );
				* SERGIO ***************************************************************************************************************************************************/
				
				let our_YEAR = this.state.selectedYear
				let our_MONTH_ANT = this.state.selectedMonth

				let str_month_ANT =	(  our_MONTH_ANT  >=  10     )			// aux_Today.getMonth()  =>   de 0 ...11
									?
										String( our_MONTH_ANT  )
									:
										"0"  +  String( our_MONTH_ANT  );			//..... PONERLE EL '0' POR DELANTE

									//  +  0  =>  MES ANTERIOR AL ACTUAL
				

				var str_mondayStart_date =	our_YEAR   +   "-"   +   str_month_ANT   +   "-01";					//this.props.location.search.split(  "monday="  )[ 1 ];


				//window.alert( str_mondayStart_date );



				/*
				var dd = String(today.getDate()).padStart(2, '0');

				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!

				var yyyy = today.getFullYear();
				*/




				/*var*/ our_monday_date =  new Date( str_mondayStart_date );




				//window.alert(  ( new Date() ).addDays(7)  );

				//
				//window.alert( str_mondayStart_date + " ?=  "  +  our_monday_date  );		// SÍ, LO HACE BIEN




				/*var*/ NUM_mondayStart_date__TIMEZONE =  Math.round(  parseFloat(   our_monday_date.getTime()  )  /  1000.0  ); // new Date( str_mondayStart_date  )


				//var STR_mondayStart_date = NUM_mondayStart_date.toString();



				/*

				let our_MONTH_ANT =	(  our_MONTH  >=  2  )
									?
										(  our_MONTH - 1  )
									:
										12;		// AÑO ANTERIOR!


				let str_month_ANT =	(  our_MONTH_ANT  >=  10     )			// aux_Today.getMonth()  =>   de 0 ...11
									?
										String( our_MONTH_ANT  )
									:
										"0"  +  String( our_MONTH_ANT  );			//..... PONERLE EL '0' POR DELANTE

									//  +  0  =>  MES ANTERIOR AL ACTUAL


				let our_YEAR =	(  our_MONTH_ANT  !=  12  )
								?
									aux_Today.getFullYear()
								:
									(  aux_Today.getFullYear() - 1  );
				*/


				thisYEAR =  our_YEAR;	//our_monday_date.getFullYear();


				NUM_MES_Selected =   our_MONTH_ANT;		//;our_monday_date.getMonth()  +  1; 		//  Desde 1 hasta 12 ("getMonth()" devuelve de 0-11)

				//window.alert(  NUM_MES_Selected  );




				NUM_DAYS_MONTH =  	DaysMonth 	(
													NUM_MES_Selected,

													thisYEAR
												);									// Desde 28 hasta 31



				DAYS_MONTH__last_week =  NUM_DAYS_MONTH  -  ( 7 * 4 ); 		//  ... -  28		 // la 5ta semana

				// TAMBIEN SI "DAYS_MONTH__last_week"= 0, ENTONCES SON 4 SEMANAS Y NO 5
				NUM_SEMANAS_mes =  Math.ceil(  NUM_DAYS_MONTH  /  7.0  );

				//window.alert(  "NUM_SEMANAS_mes: "  +  NUM_SEMANAS_mes  );


				//-----


				STR_DiAS_MES = [];


				//------------------------------------------------------------------------------


				for (  let id = 0;  id <  NUM_DAYS_MONTH;  id++  )
				{
					var dday =  id + 1;

					STR_DiAS_MES[ id ] =   String(  ( dday  <  10  )	?	( "0"  +  dday )  :	 dday  )  	/*+  ""  +  ( STR_meses[  our_monday_date.getMonth() ] ).slice( 0, 3 )*/;


					//////console.log(  STR_DiAS_MES[ id ]  );

				}// for (  let id = 0;  id <  NUM_DAYS_MONTH;  id++  )













				NUM__FIRST_day_MONTH = 	1;
				NUM__LAST_day_MONTH = 	NUM_DAYS_MONTH;


				var str_new_aux =  (   NUM_MES_Selected  <  10  )	?	( "0"  +  NUM_MES_Selected )	:		NUM_MES_Selected;


				STR__FIRST_day_MONTH =  thisYEAR  +  "-"  +  str_new_aux  +  "-"  +  "01"				+	"T00:00:01";     //"T00:01:00";		//our_monday_date.getDay();
				STR__LAST_day_MONTH  =  thisYEAR  +  "-"  +  str_new_aux  +  "-"  +  NUM_DAYS_MONTH		+	"T23:59:59";     //"T23:59:00";



				TIMESTAMP__FIRST_day_MONTH = 	Math.floor(   parseFloat(   ( new Date( STR__FIRST_day_MONTH ) ).getTime()    /   1000.0   )     ); //  -  0;	// FINALMENTE NO SE AÑADE PORQUE CAMBIA LA FRECUENCIA DE RECEPCIÓN DE DATOS  (YA NO SE HACE: RESTAMOS 1 HORA PARA QUE COJA MEJOR LOS DATOS)
				TIMESTAMP__LAST_day_MONTH  = 	Math.round(   parseFloat(   ( new Date(  STR__LAST_day_MONTH ) ).getTime()    /   1000.0   )     ); //  +  0;	// FINALMENTE NO SE AÑADE PORQUE CAMBIA LA FRECUENCIA DE RECEPCIÓN DE DATOS (YA NO  SE HACE: SUMAMOS 1 HORA  PARA QUE COJA MEJOR LOS DATOS)





				//window.alert(  TIMESTAMP__FIRST_day_MONTH  );
				//window.alert(  TIMESTAMP__LAST_day_MONTH  );



				// str_mondayStart_date

				//////console.log(  "Nuestra fecha:  ", our_monday_date  );		// DaysMonth (  month_num, year  )
				//////console.log(  "Núméro de Mes: ", NUM_MES_Selected  );		// DaysMonth (  month_num, year  )
				//////console.log(  "Núméro de Año: ", our_monday_date.getFullYear() );		// DaysMonth (  month_num, year  )

				//////console.log(  "Días del mes elegido: ",
				//////										NUM_DAYS_MONTH
				//////);										// DaysMonth (  month_num, year  )

				//////console.log( "Días de la última semana (5ta, digamos): " +  DAYS_MONTH__last_week );		// "SEMANA" INDEPENDIENTEMENTE DEL DÍA DE LA SEMANA QUE COMIENCE LA SEMANA 1, VAN EN PERIODOS DE 7 DÍAS.... PUEDEN EMPEZAR EN JUEVES POR EJEMPLO, EN CUALQUIERA


				//////console.log(  "Fechas comienzo y final del mes (STR):  "  +  STR__FIRST_day_MONTH   +  ",  "  +  STR__LAST_day_MONTH  );

				//////console.log(  "Fechas comienzo y final del mes (TIMESTAMP):  "  +  TIMESTAMP__FIRST_day_MONTH   +  ",  "  +  TIMESTAMP__LAST_day_MONTH  );

				//----


				//informes.js:2936 Fechas comienzo y final del mes (TIMESTAMP):  164 099 1600,  164 358 3600


				//------------


				let CONFIG_getPreferredInstallationGroup =  CONFIG[ "getPreferredInstallationGroup" ]();


				//window.alert(  CONFIG_getPreferredInstallationGroup  );
				//window.alert(  this.state.selected_installationgroup.id  );
				//

				const str_aux_all =  "?all=";

				let aux_INDX_all =  parseInt(   String(  CONFIG_getPreferredInstallationGroup  ).indexOf(  str_aux_all  )   );		// 4c1952b1-61c3-407b-888b-ff5cf1fe8da1 ?all= true

				//window.alert(  aux_INDX_all  );



				CONFIG_getPreferredInstallationGroup =	(  aux_INDX_all  !=  -1  )
														?
															String(  CONFIG_getPreferredInstallationGroup  ).split( str_aux_all )[ 0 ]
														:
															CONFIG_getPreferredInstallationGroup;



				CONFIG[ "setPreferredInstallationGroup" ](   CONFIG_getPreferredInstallationGroup    );




				group_id =		//String(  CONFIG_getPreferredInstallationGroup  );


								(   (  this.state.selected_installationgroup.id  !=  ""  )   &&   (  this.state.selected_installationgroup.id  !=  "All"  )   &&   (  this.state.selected_installationgroup.id  !=  "all"  )   )
								?
									this.state.selected_installationgroup.id
								:
									(
										//String(  CONFIG_getPreferredInstallationGroup  );

										(   (  CONFIG_getPreferredInstallationGroup  !=  "All"  )   &&   (  CONFIG_getPreferredInstallationGroup  !=  "all"  )   )
										?
											String(  CONFIG_getPreferredInstallationGroup  )		//this.props.location.search.split(  "installationgroup_id="  )[ 1 ].split( "&drate=" )[ 0 ];	//.split( "&monday=" )[ 0 ];
										:
											""  &&  window.alert(   "Seleccione una de sus Instalaciones por favor"   )
									);


				this.state.selected_installationgroup.id =  group_id;



				//------------


				////window.alert(  group_id  );


				INI_componentDidMount__COUNT +=  1;


				//-------------


				if (  this.props.auth.user.user_profile.userlevel == "EKIONA"  )
				{
					//window.alert(  "userlever ==  EKIONA   -->   Para sacar el Informe con los datos apropiados a las Installations correspondientes mejor hacer Log In con user concreto de las Installations"  );
				}//

			}// if (  true /*PULSADO_BOTON  ==  false*/  )






		} // if (   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
		else
		{
			window.location.replace(   'https://apps.ilumek.com/'  );

		}// else




	}//componentDidMount ( ) -------------------------------------



//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


	showSpinner ( )
	{

		if (  !this.state.showSpinner  )
		{
			return ( <div/> )
		}

		return(
				<div style={{position: "fixed", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "9999999"}}>

					<div style={{position: "absolute", left: "48%", top: "45%"}}>
						<Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>

				</div>
		);


	}//showSpinner( )

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



	render ( )
	{

		//var num_renders_post_svg = 0;


		_Num_Renders_RENDER_FUNC++;

		//console.log( "_Num_Renders_RENDER_FUNC: " + _Num_Renders_RENDER_FUNC );



		let slider_index = -1;



		//---------------------------------------

		if (  true   )	 /*_data_Retrieved ==*/	  //--------------------------------------------------------------------
		{


			const this_ =  this;		// (2do puesto


			//---

			//console.log( "desde RENDER  AMANECERES___"  +  /*this.state.sunriseDAY*/  AMANECERES_WEEK );



			//console.log( "DATOS DE TODAS __INSTALACIONES __" + "" + ":\n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs,   null,   "		"  ) );
			//console.log( "DATOS DE _INSTALACIONES _ #0" + "" + ":\n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs[0],   null,   "		"  ) );

			//console.log( "DATOS DE _INSTALACIONES _ #0, STREELIGHT #0" + "" + ":\n"  +  JSON.stringify(  ( _ar_INSTALLATIONS__SLs_VARVALs[0] ) ?  _ar_INSTALLATIONS__SLs_VARVALs[0][0] : undefined,   null,   "		"  ) );


			//console.log( this_.state.comp_CHART_DATA.slice( 10, 45 )   );


			/*
			console.log( "DATOS DE _INSTALACIONES _ TODAS, STREELIGHT #0 / 9" + "" + ":\n"  +

							JSON.stringify(
											( typeof(_ar_ALL__STREETLIGHTS_VARVALs )  !=  "undefined" )

												?
													(  typeof( _ar_ALL__STREETLIGHTS_VARVALs[0] )  != "undefined"  )  ?   _ar_ALL__STREETLIGHTS_VARVALs[0]  :   "FAROLA 0 VACIA" 	 //.slice( 1, _ar_INSTALLATIONS__SLs_VARVALs[0][0].data - 2 )
												:
													"STREETLIGHTS ARRAY NO EXISTE",  //undefined,

										   null,   "		"  )
						);
			*/

			//-------

			/*
							this.setState(
											{
												//profileName:"Schedule",
												//showSpinner: false,

												sunriseDAY: this.state.sunriseDAY.push( day_sunrise ),
												sunsetDAY:  this.state.sunsetDAY.push(  day_sunset ),
											}
							);//
			*/

			if	(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
			{

				//NUM_renders_SVG_rendered

				//if (  this.state.sunRise_sunSet_GET  ==  "true"  )
				//if (  this.state.LAST_re_render  ==  "true"  )   // (  this.state.SCRIPT_svg_rendered = "true"  )

				//if (   (  NUM_renders_SVG_rendered  ==  2  )   &&   (  PULSADO_BOTON  ==  false  )   )





				// VERIFICAR QUE EL OBJETO ESTÉ CREADO

				/*
				if (   (  _Num_Renders_RENDER_FUNC  ==  6  )   &&   (  PULSADO_BOTON  ==  false  )   )
				{

					//window.alert(  "ENTRA en zona boton 1"  );

					//--------

					const targetElement = document.getElementById( "id_Div_button" );		//.querySelector( '#iframeContainer_PDFMake' );		//document.getElementById( "iframeContainer_PDFMake" ); 	//document.querySelector( '#iframeContainer_PDFMake' );

					const our_button = document.createElement( "button" );

					//const our_p = document.createElement( "p" );


					our_button.id = "id_my_button";


					//our_button.name = "Segundo Boton";


					our_button.innerHTML = "Generar Informe";



					our_button.onclick =	() =>
											{
												PULSADO_BOTON =  true;


												//window.alert(  "EEEEEEOOOOOOOO"  );
											};


					//iframe.width = "1120"; // 1280 // "980"
					//iframe.height = "1235";	// 1195

					//iframe.style = "margin: auto;";




					targetElement.appendChild( our_button );


					//-------------------

					//-------------------





				}// if ( NUM_renders_SVG_rendered  ==  1  ) //----------------------------------------------------------------------
				*/


				//-------




					/*
					this.state.pulsado_Button =  "true";

					this.setState(
									{
										pulsado_Button: "true",
									}
					);
					*/


					/*

									<button
										onclick ="Generar_Informe()"
									>
										Generar PDF Informe
									</button>
					*/



				//------------------


				if (  true /*PULSADO_BOTON  ==  false*/  )
				{

					//window.alert(  "ENTRA en zona boton 2"  );





					if (  document.getElementById( "id_div_informe_mensual" )  )
					{
						////window.alert(  "EXISTE" );

						//document.getElementById( "id_div_informe_mensual" ).hidden =  true;

						document.getElementById( "id_div_informe_mensual" ).innerHTML =  "";

						//document.getElementById( "id_div_informe_mensual" ).outerHTML =  "";

						document.getElementById( "id_div_informe_mensual" ).remove();

						//-----

						//React.Component(  "Informe_mensual"  ).componentWillUnmount();

						//Informe_mensual.componentWillUnmount();


					}// if





					//------------

					let CONFIG_getPreferredInstallationGroup =  CONFIG[ "getPreferredInstallationGroup" ]();


					//window.alert(  CONFIG_getPreferredInstallationGroup  );
					//window.alert(  this.state.selected_installationgroup.id  );
					//

					const str_aux_all =  "?all=";

					let aux_INDX_all =  parseInt(   String(  CONFIG_getPreferredInstallationGroup  ).indexOf(  str_aux_all  )   );		// 4c1952b1-61c3-407b-888b-ff5cf1fe8da1 ?all= true

					//window.alert(  aux_INDX_all  );



					CONFIG_getPreferredInstallationGroup =	(  aux_INDX_all  !=  -1  )
															?
																String(  CONFIG_getPreferredInstallationGroup  ).split( str_aux_all )[ 0 ]
															:
																CONFIG_getPreferredInstallationGroup;



					CONFIG[ "setPreferredInstallationGroup" ](   CONFIG_getPreferredInstallationGroup    );




					group_id =		//String(  CONFIG_getPreferredInstallationGroup  );


									(   (  this.state.selected_installationgroup.id  !=  ""  )   &&   (  this.state.selected_installationgroup.id  !=  "All"  )   &&   (  this.state.selected_installationgroup.id  !=  "all"  )   )
									?
										this.state.selected_installationgroup.id
									:
										(
											//String(  CONFIG_getPreferredInstallationGroup  );

											(   (  CONFIG_getPreferredInstallationGroup  !=  "All"  )   &&   (  CONFIG_getPreferredInstallationGroup  !=  "all"  )   )
											?
												String(  CONFIG_getPreferredInstallationGroup  )		//this.props.location.search.split(  "installationgroup_id="  )[ 1 ].split( "&drate=" )[ 0 ];	//.split( "&monday=" )[ 0 ];
											:
												""  &&  window.alert(   "Seleccione una de sus Instalaciones por favor"   )
										);


					this.state.selected_installationgroup.id =  group_id;




					//------------




					function getMenuImg ( url, option )
					{
						if ( this_.state.location == option )
						{
							url = url.replace( "/white/", "/orange/" );
						}

						return url;

					}// function getMenuImg ( url, option )






					//-----------------------


					return (

								<div	id ="dropdowncontainer"
										className = "container-fluid"
										width="100%"

										//onload=""

										style={{margin: "auto"}}

								> 	{/*marginBottom:"35px", marginLeft:"-10px"*/}


									<div className="row">
										{  /*<div className="row">: LISTA DESPLEGABLE INSTALLATION_GROUP*/  }
										<div id="cab-general" className="container-fluid" style={{marginLeft: "3px"}}>
											<div className="actions-left col-md-4 col-xs-12" style={{marginTop: "-5px"}}>


												<li className="nav-item dropdown" >

													<SelectorInstalaciones

														showAll = { false }


														onDataChanged =
																		{
																			( e ) =>
																			{

																				//CONT_axios_Alarms = 0;

																				this.setState(
																								{
																									selected_installationgroup: e,

																									total_streetlights: e.info.streetlights.length
																								}
																				);

																				//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);



																				CONFIG[ "setPreferredInstallation" ](  e.value  );
																			}
																		}
													/>

												</li>

											</div>
										</div>
										{  /*<div className="row">: END: LISTA DESPLEGABLE INSTALLATION_GROUP*/  }
									</div>



									<center>

									<br/>






















									{


									<div>

										<center>
										<br/>
										{ /*console.log(  JSON.stringify(  svg_01_outerHTML  )  )*/  }

										<h2> {  this.state.selected_installationgroup.group_name  } </h2>

										</center>

									</div>
									}


									{
									/*
									//( this.state.LAST_re_render != "true" )  &&		// this_.LAST_re_render

									<div id="id_Div_button">

										{
											//<p> sitio del boton </p>
										}
									</div>
									*/
									}

									<br/>
									<br/>

									{
										/*
										(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )  &&

										<p> Generar PDF Informe </p>
										*/
									}













									{

									(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )  &&


									<Link to= { "/informe_mensual/?"  +  group_id } >

										<img
											id='report-img'
											src={ getMenuImg( "/img/ico/orange/ico-b-informe.svg", "/informes" ) }

											data-toggle="tooltip"
											data-placement="bottom"

											title = { this_.props.i18n.t( "app.reports" ) }
											alt = { this_.props.i18n.t( "app.reports" ) }

											width = "100"

										/>

									</Link>

									}




									<br/>
									<br/>





									{

									(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  /*&&  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )*/   )  &&


									<div className = "miclase0101" >

										{
										
										
										/*
										<Select

											id = "react_select"

											isMulti = { false }
											searchable = { true }

											placeholder = { placeholder }

											defaultValue= { this.state.selected_installationgroup.id }

											value = { defaultValue }

											onChange =
														{
															( e ) =>
															{
																let all =  false;

																if (  e.value.indexOf( "?all" )  >  0  )
																{
																	all =  true;
																}

																this.getInstallationGroupDetail(  e.value,  all  );

																CONFIG[ "setPreferredInstallationGroup" ]( e.value );
															}
														}


											maxMenuHeight = { 150 }

											options = { installationGroupNames }
										/>
										*/

										/*handleYearChange = (event) => {
											this.setState({ selectedYear: event.target.value });
											localStorage.setItem('selectedYear', String(event.target.value))
										};
									
										handleMonthChange = (event) => {
											this.setState({ selectedMonth: event.target.value });
											localStorage.setItem('selectedMonth', String(event.target.value))
										};*/


										<div className='report-selectors--container'>
											<div className='select-container'>
												<label>{this.props.i18n.t("reports.select_year")}</label>
												<Select
													className='select-year'
													isMulti={false}
													searchable={true}
													defaultValue={{value: this.state.selectedYear, label: this.state.selectedYear}}

													options={
																years.map((year) => {
																	return {value: year, label: year}
																})
													}
													
													onChange={
																(e) => {
																	this.setState({ selectedYear: e.value });
																	localStorage.setItem('selectedYear', String(e.value))
																}
													}
													
												/>
											</div>
											<div className='select-container'>
												<label>{this.props.i18n.t("reports.select_month")}</label>
												<Select
													className='select-month'
													isMulti={false}
													searchable={true}
													defaultValue={{value: this.state.selectedMonth, label: months[this.state.selectedMonth]}}
													
													options={
																months.map((month, index) => {
																	return {value: index, label: month}
																})
													}

													onChange={
																(e) => {
																	this.setState({ selectedMonth: e.value });
																	localStorage.setItem('selectedMonth', String(e.value))
																}
													}
												/>
											</div>
											
										</div>
										
										/*<select name="select">


											<option
													value="1"

												
											>
												Informe Enero 

											</option>


											<option
													value="2"

													
											>
												Informe Febrero

											</option>


											<option
													value="3"

													
											>
												Informe Marzo

											</option>


											<option
													value="4"

													
											>
												Informe Abril
											</option>


											<option
													value="5"

													
											>
												Informe Mayo

											</option>


											<option
													value="6"

													
											>
												Informe Junio

											</option>


											<option
													value="7"

													
											>
												Informe Julio

											</option>


											<option
													value="8"

													
											>
												Informe Agosto

											</option>


											<option
													value="9"

													
											>
												Informe Septiembre 

											</option>


											<option
													value="10"

													
											>
												Informe Octubre 

											</option>


											<option
													value="11"

													
											>
												Informe Noviembre 

											</option>


											<option
													value="12"

													
											>
												Informe Diciembre 

											</option>


										</select>
										*/

										}


									</div>

									}





									</center>



								</div>

					) // return

				} // if (  true /*PULSADO_BOTON  ==  false*/  )

			}// if	(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
			else
			{
				return(
							<div>

								<br/>
								<br/>

								<center>

									No tiene contratado el servicio de Informes

									{
										window.location.replace(   'https://apps.ilumek.com/'  )
									}

								</center>

							</div>
				) //

			}// else


		}// if (  /*_data_Retrieved ==*/ true  )





	}// render ()-------------------------------------------------------------------------------------------


}// class Informes extends Component


//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const mapStateToProps =	function ( state )
						{
							return	{
										auth:	 state.auth,
										core: 	state.core
									}
						}

const mapDispatchToProps =	{
								saveProfile: 	saveProfile
							}


export default withTranslation() (  connect( mapStateToProps, mapDispatchToProps )( Informes )  );