
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';

import { connect } from 'react-redux';

//import axios from 'axios';
import { setup } from 'axios-cache-adapter';

import CONFIG from '../../config/config';

import Select from 'react-select';


const axios = setup(
						{
							cache:
							{
								maxAge: 15 * 1000
							}
						}
					)



let lastRequest =  undefined;






class SelectorInstalaciones extends Component // ----------------------------------------------------------------------------------------------------------------------------------------------------
{



	constructor ( props )
	{

		super ( props );

		this.state =
					{
						selected_installationgroup:	{
														group_name: "",
														id: ""
													},
						installationgroups: [],

						waiting: false
					};


		this.getAllInstallationGroups = this.getAllInstallationGroups.bind( this );
		this.getInstallationGroupDetail = this.getInstallationGroupDetail.bind( this );


	} // constructor ( props ) ------------------------------------------------------------------------------------------------------------------------------------------





	//---




	componentDidMount (  ) // -------------------------------------------------------------------------------------------------------------------------------------------
	{
		this.getAllInstallationGroups(  );

	} // componentDidMount (  ) ------------------------------------------------------------------------------------------------------------------------------------------





	//---




	getAllInstallationGroups (  )
	{

		if ( lastRequest !== undefined )
		{

			let now = new Date();


			if(   ( now.getTime() - lastRequest.getTime() )  <  1000*2   )
			{
				//return;
			}

		}// if


		lastRequest = new Date();


		this.setState( { waiting: true } );


		let show_all = "?all=true";


		if ( this.props.showAll == false )
		{
			show_all =  "";
		}//


		axios.get(
					CONFIG.api_url + "/installationgroups"	+	show_all,

					{
						"headers":
									{
										'Content-Type':		'application/json',
										'Authorization':	"Token " 	+ 	this.props.auth.token
									}
					}

		).then(

				( result ) =>
				{
					if (   result.data[0].group_name == "All"   &&   this.props.showAll !== true   )
					{
						result.data.shift();

					}// if


					this.setState(
									{
										waiting: false,
										installationgroups: result.data
									}
					);//


					if (  this.props.onDataLoaded  )
					{
						this.props.onDataLoaded( result.data );
					}

				}//

		).catch(

				error =>

							this.setState(
											{
												error,
												waiting: false
											}
							)

		); // axios.get.then.catch

	} // getAllInstallationGroups (  ) ---------------------------------------------------------------------------------------------------------------




	//---



	getInstallationGroupDetail ( id, all ) // --------------------------------------------------------------------------------------------------------
	{

		let extra = "";

		if (  all  &&  id.indexOf("?all") < 0  )
		{
			extra = "?all=true";
			all = true;
		}
		else
		{
			if (  ( all === undefined )  &&  id.indexOf( "?all" ) > 0  )
			{
				extra = "?all=true";
				all = true;
			}
		}

		this.state.waiting =  true;

		this.setState( { waiting: true } ) ;


		axios.get(

					CONFIG.api_url  +  "/installationgroups/"  +  id  +  extra,

					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': "Token " + this.props.auth.token
									}
					}

		).then(
		//
			( result ) =>
			{

				if ( all )
				{
					result.data.id = "all";
					result.data.group_name = "All";
				}//

				this.setState(
								{
									waiting: false,

									selected_installationgroup: result.data
								}
				);


				if(  this.props.parent  )
				{
					if (  this.props.parent.updateOptionVisibility  )
					{
						this.props.parent.updateOptionVisibility( result.data.info.max_cpu_version );
					}
				}


				if (  this.props.onDataChanged  )
				{
					this.props.onDataChanged( result.data );
				}

				//  this.setState({
				//      selected_installationgroup: this.state.installationgroups[0],
				//    });
				// Actualizando
				//  this.props.parent.updateOptionVisibility(this.state.installationgroups[0].max_cpu_version);

			}
		//
		).catch(

				error =>
							this.setState(
											{
												error,
												waiting: false
											}
										)
		);

	} // getInstallationGroupDetail ( id, all ) ----------------------------------------------------------------------------------------------------------------




	//---




	render (  ) // ---------------------------------------------------------------------------------------------------------------------------------------------
	{

		/*
		"getPreferredInstallationGroup":
		function ( )
		{
			return CONFIG["getLocalStorage"]("preferredInstallationGroup");
		},
		*/


		//console.log(   CONFIG[ "getLocalStorage" ]( "preferredInstallationGroup" )    );
		//console.log(   CONFIG[ "getPreferredInstallationGroup" ]()   );


		//-------------------------

		const installationGroupNames =  this.state.installationgroups.map(
																			( item, index ) =>
																			{

																				let group_name = item.group_name;


																				if (  item.id.indexOf( "all" )  >  0  )
																				{
																					group_name =		this.props.t( "calendar.all" );
																					item.group_name =	group_name;

																				}// if ( item.id.indexOf( "all" ) > 0 )


																				//---


																				if (
																						(  ! this.props.preselectedInstallation  )						&&

																						(  this.state.selected_installationgroup.group_name  ==  ""  )	&&

																						(  item.id  ==  CONFIG[ "getPreferredInstallationGroup" ]()  )	&&

																						(  this.state.selected_installationgroup.id.split( "?" )[ 0 ]  !==  item.id.split( "?" )[ 0 ]  )
																					)
																				{


																					this.setState(
																									{
																										selected_installationgroup:  item
																									}
																					);//


																					this.getInstallationGroupDetail( item.id );

																					//this.props.parent.updateOptionVisibility( item.max_cpu_version );
																				}
																				else
																				{
																				}// else


																				//---

																				if ( item.group_name == "All" )
																				{
																					item.group_name =  this.props.t( "calendar.all" );

																				}// if ( item.group_name == "All" )


																				//---

																				return	{
																							value:	item.id,
																							label:	group_name,
																							index:	index
																						}

																			} //---
		) // this.state.installationgroups.map()

		//------


		if (  installationGroupNames.length  == 0  )
		{
			return <div/>
		}

		let placeholder = "";


		if ( !this.state.selected_installationgroup.group_name  &&  this.state.waiting == false )
		{

			if ( this.props.preselectedInstallation )
			{

				for ( let i = 0; i < this.state.installationgroups.length; i++ )
				{
					for ( let j = 0; j < this.state.installationgroups[i].installations.length; j++ )
					{
						if (  this.state.installationgroups[i].installations[j].id  ==  this.props.preselectedInstallation  )
						{
							//placeholder=this.state.installationgroups[0].group_name;
							this.setState( { selected_installationgroup:this.state.installationgroups[i] } );
							this.getInstallationGroupDetail( this.state.installationgroups[i].id );

							return <div/>;
						}
					}//for

				}//for
			}
			else
			{
				placeholder = this.state.installationgroups[0].group_name;

				this.setState(  { selected_installationgroup: this.state.installationgroups[0] }  );

				this.getInstallationGroupDetail(  this.state.installationgroups[0].id  );
			}
		}
		else
		{
			placeholder = this.state.selected_installationgroup.group_name;

			if ( placeholder == "All" )
			{
				placeholder = this.props.t( "calendar.all" );
			}
		}


		let selectorClass = "col-md-10 col-xs-12";

		if(  this.props.selectorClass  )
		{
			selectorClass = this.props.selectorClass;
		}


		var selectedValue = undefined;


		installationGroupNames.map(
									( item, index ) =>
									{
										if ( item.value == this.state.selected_installationgroup.id )
										{
											selectedValue = item;
										}
									}
		);// installationGroupNames.map(


		//-----------------

		return	(
					<div className = { selectorClass } >

						<Select

							id = "react_select"

							isMulti = { false }
							searchable = { true }

							placeholder = { placeholder }

							//defaultValue={this.state.selected_installationgroup.id}

							value = { selectedValue }

							onChange =
										{
											( e ) =>
											{
												let all =  false;

												if (  e.value.indexOf( "?all" )  >  0  )
												{
													all =  true;
												}

												this.getInstallationGroupDetail(  e.value,  all  );

												CONFIG[ "setPreferredInstallationGroup" ]( e.value );
											}
										}

							maxMenuHeight = { 150 }

							options = { installationGroupNames }
						/>

					</div>
				)


	} // render(  ) ----------------------------------------------------------------


} // class SelectorInstalaciones extends Component



//---

const mapStateToProps =
						function( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}


const mapDispatchToProps = {}

export default withTranslation() (	connect( mapStateToProps, mapDispatchToProps ) ( SelectorInstalaciones )  );
