
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { login } from '../actions/auth';


import Spinner from 'react-spinner-material';

import fileDownload from 'js-file-download';

//import axios from 'axios';
import { setup } from 'axios-cache-adapter';
import CONFIG from '../config/config';



import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";



//import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Moment from 'moment';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';


const $ = window.$;
// Paleta cíclica de colores distintos
const COLORS = [ '#FF8042','#2f4b7c','#ffa600','#0088FE', '#00C49F', '#FFBB28','#FF2323' ];


const NOMBRE_CACHE = "graficador_ilumek_cache";


let headers = [];

//am4core.useTheme(am4themes_animated);

am4core.options.queue = true;
am4core.options.onlyShowOnViewport = true;

//--------

const axios = setup(
					{
						cache:	{
									maxAge: 15 * 1000
								}
					}
)//const

//--------

const decodeBase64 =	function b64DecodeUnicode( str )
						{
							// Going backwards: from bytestream, to percent-encoding, to original string.

							return decodeURIComponent(
														atob( str ).split( '' ).map(
																						function( c )
																						{
																							return '%'  +  (  '00'  +  c.charCodeAt(0).toString(16)  ).slice( -2 );
																						}
														).join( '' )
							);// return...

						};// function b64DecodeUnicode( str )

//--------


class CustomizedAxisTick extends Component
{

	render ()
	{
		const { x, y, stroke, payload } =  this.props;

		return (
					<g transform={`translate(${x},${y})`}>

						<text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-10),scale(0.8)">
							{  payload.value.split( "+" )[ 0 ].replace( "T", " " )  }
						</text>

					</g>
		);//

	}//render ()

};// class CustomizedAxisTick extends Component

//--------


class CustomTooltip extends Component
{

	render ( )
	{
		const { active } = this.props;
		let { payload, label } = this.props;

		if (  active && payload  )
		{
			label = label.replace( "T", " " ).split( "+" )[ 0 ];

			return (
						<div className="custom-tooltip" style={{backgroundColor: "lightgrey", padding: "10px", opacity: "0.8"}}>

							<p className="label">
								{`${label}`}
							</p>
						
							<hr/>

							{
							payload.map(
											( item, index ) =>
											{
												return (
															<p className="item" style={{color: item.color}}>
																{item.name} : {this.props.var_unit}
															</p>
												)//
											}
							)//.. .map()
							}
						</div>
			);// return

		}//if

		return null;

	}// render()


};// class CustomTooltip extends Component

//--------


class SingleChart extends Component
{

	constructor ( props )
	{
		super( props );

		this.state =	{
							selected_installationgroup: props.selected_installationgroup, //{installation_name: "", id:"", streetlights: []},
							selected_streetlight: {name: ""},

							start_date: this.props.startDate,
							end_date: this.props.endDate,

							view_as_cards: true,
							chartingVars: {},

							results: {},

							isLoading: false,
							showSpinner: false,
							checkboxes: {},
							error: null,
							focusedInput: undefined,

							minState: "-",

							disable_can_chart: true,
							disabled: props.disabled,

							dont_show_axis: "checked"
						};

		//console.log(props.selected_streetlights);

		this.state.chartingVars[ this.props.var_unit ] =	{
																"vars": [],
																"streetlights": []
															};

		this.state.results[ this.props.var_unit ] = [];

		this.selectedVars = {};
		this.selectedVars[ props.var_unit ] = [];

		this.selectedStreetlights = {};
		this.selectedStreetlights[ props.var_unit ] = props.selected_streetlights;



		this.showSpinner = this.showSpinner.bind( this );

		this.doChart = this.doChart.bind( this );

		this.result = "";

		this.checkCache = this.checkCache.bind( this );

		this.prevSelectedInstallationGroupId = -1;

		this.justDoItOnceDone = false;

	} // constructor ( props )


	//------


	componentDidMount ( )
	{    
		let data =
					[
						{
							"timestamp":"2019-03-12T12:00:00+01:00",
							"date":"2019-03-12 12:00:00","480014f000m_B":13.9
						},

						{
							"timestamp":"2019-03-12T12:05:00+01:00",
							"date":"2019-03-12 12:05:00","480014f000m_B":12.9
						}
					];

		this.doChart( [] );

		setTimeout(
					( ) =>
					{
						this.justDoItOnceDone = true;
					}

					,10000
		);//setTimeout

	}// componentDidMount ( )


	//------


	checkCache ( )
	{
		this.selectedVars[ this.props.var_unit ] = [];

		if (  "vartype"  in  this.state.chartingVars[ this.props.var_unit ]  )
		{
			this.state.disable_can_chart = true;

			Object.keys(
							this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
			).map(
					( item, index ) =>
					{
						let this_value=false;
						let cache_data = JSON.parse(  localStorage.getItem( NOMBRE_CACHE )  );

						if (  cache_data  &&  cache_data[ this.state.selected_installationgroup.id ]  !==  undefined  )
						{
							if(  this.props.var_unit  in  cache_data[ this.state.selected_installationgroup.id ]  )
							{
								if (
									cache_data[ this.state.selected_installationgroup.id ][ this.props.var_unit ].indexOf(  this.state.chartingVars[ this.props.var_unit ][ "vartype" ][ item ].code  )  >  -1
								)
								{
									this.selectedVars[ this.props.var_unit ][  this.state.chartingVars[ this.props.var_unit ][ "vartype" ][ item ].code  ] = true;
									this_value = true;
									this.state.disable_can_chart = false;
								}//if

							}//if
						}//if

						this.state.checkboxes[index] = this_value;
					}//

			);//... .map()

		}//if

	}// checkCache ( )


	//------


	doChart ( data, just_prepare )
	{
		// ---

		//console.log("Charting");
		let axisList = {};

		if (  this.chart  )
		{
			this.chart.dispose();
		}


		if (  data == ""  )
		{
			return;
		}

		let vars = [];


		if (  this.selectedStreetlights[ this.props.var_unit ]  !==  undefined  )
		{
			if (  Object.keys(  this.selectedStreetlights[ this.props.var_unit ]  ).length  >  0  )
			{
				Object.keys(
								this.selectedStreetlights[ this.props.var_unit ]
				).map(
						( item_sl, index ) =>
						{
							Object.keys(
											this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
							).map(
									( item, index ) =>
									{
										let varname = "";

										if (  this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  !=  false  )
										{
											varname = this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  +  "_" +  item;
											vars.push( varname );
										}//if
									}//

							)//.map()
						}
				)// .map()
			}// if
		}// if
		
		//--------

		if (  just_prepare  !==  undefined  )
		{
			return;
		}


		if (  vars.length == 0  )
		{
			return;
		}//


		//console.log("Creating chart");
		let chart = am4core.create(  this.chartdiv, am4charts.XYChart  );

		chart.data = data;


		// Enable export
		if (  data  )
		{
			if (  data.length > 0  )
			{
				chart.exporting.menu = new am4core.ExportMenu();
			}  
		}//
	

		// Set input format for the dates	

		// Create axes
		var dateAxis = chart.xAxes.push(  new am4charts.DateAxis()  );

		dateAxis.dateFormatter = new am4core.DateFormatter();

		dateAxis.dateFormatter.inputDateFormat = "dd/MM/dd";
		dateAxis.dateFormats.setKey(  "hour", "dd/MM/yyyy H:mm"  );


		//dateAxis.dateFormatter.utc=true;

		let tempDate = new Date();
		let TIME_OFFSET = tempDate.getTimezoneOffset(); //-1 * 60;

		let temp = Moment();


		if (  temp.isDST()  )
		{
			TIME_OFFSET -= 60;
		}

		//dateAxis.dateFormatter.timezoneOffset= TIME_OFFSET;

		var valueAxis;

		let scrollbarX = new am4charts.XYChartScrollbar();

		var series;
		var unit = this.props.var_unit;


		if (  unit  == "all" )
		{
			unit = "";
		}
		else
		{
			//console.log();
			valueAxis = chart.yAxes.push(  new am4charts.ValueAxis()  );
			valueAxis.numberFormatter = new am4core.NumberFormatter();
			valueAxis.numberFormatter.numberFormat = "#.#"; // + unit;
			valueAxis.groupData = true;
		}//else

		
		var colorIndex = -1;

		//console.log("Preparing chart...");

		vars.map(
					( item, index ) =>
					{
						colorIndex++;

						if (  colorIndex >= COLORS.length  )
						{
							colorIndex = 0;
						}


						let all_unit = "";

						if (  unit == ""  )
						{
							if(item.split("_")[1] in this.selectedVars[this.props.var_unit] == false )
							{ return; }

							all_unit = item.split("_")[1];
						}
						else
						{}


						if (  this.props.var_unit == "all"  )
						{
							if (  all_unit  in  axisList == false  )
							{
								valueAxis = chart.yAxes.push(  new am4charts.ValueAxis()  );
								valueAxis.numberFormatter = new am4core.NumberFormatter();
								valueAxis.numberFormatter.numberFormat = "#.00" + unit;
								valueAxis.groupData = true;

								valueAxis.renderer.line.disabled = $( "#show_axis" ).is( ":checked" ); //disables axis line
								valueAxis.renderer.labels.template.disabled = $( "#show_axis ").is( ":checked" ); //disables labels
								valueAxis.renderer.grid.template.disabled = $( "#show_axis" ).is( ":checked" );  //disables grid        
								axisList[all_unit] = valueAxis;
							}
							else
							{
								valueAxis = axisList[ all_unit ];
							}
						}// if

						series = chart.series.push(  new am4charts.LineSeries()  );

						series.yAxis = valueAxis;
						series.dataFields.dateX = "epoch";

						series.strokeWidth = 2;
						series.dataFields.valueY = item;
						series.name = item;
						series.tooltipText = "{name}: >> [bold]{valueY}[/]"  +  unit;
						series.tensionX = 0.8;


						if (  this.props.var_unit == "all"  )
						{
							valueAxis.renderer.line.strokeOpacity = 1;
							valueAxis.renderer.line.strokeWidth = 2;
							valueAxis.renderer.line.stroke = series.stroke;
							valueAxis.renderer.labels.template.fill = series.stroke;
							valueAxis.renderer.opposite = false;
							valueAxis.renderer.grid.template.disabled = true;      
						}// if

						series.stroke = am4core.color(  COLORS[ colorIndex ]  ); 
						series.tooltip.getFillFromObject = false;
						series.tooltip.background.fill = am4core.color(  COLORS[ colorIndex ]  );

						//series.tooltip.label.fill = am4core.color(COLORS[colorIndex]); 

						scrollbarX.series.push( series );
					}//
		);// .map()


		var interfaceColors = new am4core.InterfaceColorSet();

		chart.dateFormatter.dateFormat = "dd/MM/yyyy H:m:s";

		
		chart.scrollbarX = scrollbarX;

		chart.cursor = new am4charts.XYCursor();

		//console.log("Finished");


		this.chart = chart;


	} // doChart ( data, just_prepare )


	//------


	componentWillUnmount ( )
	{
		if (  this.chart  )
		{
			this.chart.dispose();
		}
	} // componentWillUnmount ( )


	//------


	componentWillReceiveProps ( nextProps )
	{
		this.setState(  { selected_installationgroup: nextProps.selected_installationgroup }  );

		this.state.chartingVars[ this.props.var_unit ] =
															{
																"vars": [],
																"streetlights": []
															};
		if ( this.result  !=  "" )
		{
			this.doChart( "" );
		}
	
	}// componentWillReceiveProps ( nextProps )

	//------


	showSpinner ( )
	{
		if (  !this.state.showSpinner  )
		{
			return ( <div/> )
		}

		return(
				<div style={{position: "absolute", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "10"}}>
					<div style={{position: "absolute", left: "45%", top: "40%"}}>
						<Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>
				</div>     
		);

	}// showSpinner ( )


	//------


	recogerDatos ( unit )
	{
		// And save to LocalStorage for future options...

		let token =  "Token "  +  this.props.auth.token;

		let filtro =
					{
						"vars": [],
						"streetlights": []
					};

		let cache_data = localStorage.getItem( NOMBRE_CACHE );
		let installationgroup_id =  this.state.selected_installationgroup.id;


		if (  !cache_data  )
		{
			cache_data =	{
								"main": {},
								"all": {},
							};

			cache_data[ installationgroup_id ] = {};
			cache_data[ installationgroup_id ][ unit ] = [];
		}
		else
		{
			cache_data = JSON.parse( cache_data );
		}


		if (  ( this.state.selected_installationgroup.id  in  cache_data )  ==  false  )
		{
			cache_data[ this.state.selected_installationgroup.id ] = {};
		}

		cache_data[ this.state.selected_installationgroup.id ][ unit ] = [];


		Object.keys(
						this.selectedVars[ unit ]
		).map(
				( item, index ) =>
				{
					if (  this.selectedVars[ unit ][ item ] == true  )
					{
						filtro.vars.push( item );
						cache_data[ this.state.selected_installationgroup.id ][ unit ].push( item );
					}
				}
		);// .map()


		Object.keys(
						this.selectedStreetlights[ unit ]
		).map(
				( item, index ) =>
				{
					if (  this.selectedStreetlights[ unit ][ item ]  !=  false  )
					{
						filtro.streetlights.push( item );
					}
				}//

		);//.map() 

		cache_data[ this.state.selected_installationgroup.id ][ "streetlights" ] = filtro.streetlights;
		cache_data[ "selected_installationgroup_id" ] = this.state.selected_installationgroup.id;

		// Save cache
		localStorage.setItem(  NOMBRE_CACHE, JSON.stringify( cache_data )  );    

		this.setState(  { showSpinner: true }  );


		let startDate = this.props.startDate.unix();
		let endDate;


		if ( this.props.endDate )
		{
			endDate = this.props.endDate.unix();
		}
		else
		{
			endDate = Moment().unix();
		}


		if(  this.props.endDate.isSameOrAfter( Moment() ,"day" )  )
		{
			endDate = Moment().unix();
		}
		

		axios.get(
					CONFIG.api_url  +
					"/installations/"  						+  this.state.selected_installationgroup.id  +
					"/groupdata2.json?page_size=1000&vars="	+  filtro.vars  +
					"&sl="  								+  filtro.streetlights  +
					"&start_date="  						+  startDate  +
					"&end_date="							+  endDate,
		
					{
						"headers":
									{      
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
					( result ) =>
					{
						result = result.data;

						let temp = this.state.results;
						temp[ this.props.var_unit ] = result.data;

						console.log( "Read!" );

						this.result = result.data;

						this.doChart( result.data );

						this.setState( { showSpinner: false } );
					}
		).catch(
					error =>	this.setState(
												{
													error,
													isLoading: false,
													showSpinner: false
												}
								)//.setState
		);//.catch

	}// recogerDatos ( unit )



	//------


	render ( )
	{
		if (  this.state.selected_installationgroup.id == ""  ||  this.selectedStreetlights[ this.props.var_unit ] == undefined  )
		{
			return <div/>
		}//


		// Var -----------------------------------------

		if(  "vartype"  in  this.state.chartingVars[ this.props.var_unit ] == false  )
		{ 
			let V_vars =
						{
							"vartype": {},
							"streetlights": {}
						};

			if (  this.state.selected_installationgroup.info.available_vars.length  >  0  )
			{
				this.state.selected_installationgroup.info.available_vars.map(
																				( item, index ) =>
																				{
																					if (  this.props.var_unit == "all"  ||  ( item.unit == this.props.var_unit  &&  item.code in V_vars[ "vartype" ] == false )  )
																					{
																						V_vars[ "vartype" ][ item.code ] =	{
																																"name": item.name,
																																"unit": item.unit,
																																"code": item.code
																															};
																					}//if

																					this.state.selected_installationgroup.info.streetlights.map(
																																					( sl_item, sl_index ) =>
																																					{
																																						let found=false;

																																						if(  sl_item.available_vars.length > 0  )
																																						{
																																							sl_item.available_vars.map(
																																														( sl_var_item, sl_var_index ) =>
																																														{
																																															if (  sl_var_item.code == item.code  )
																																															{
																																																found = true;
																																															}
																																														}
																																							);//.map()
																																						}//if


																																						if ( found )
																																						{
																																							V_vars[ "streetlights" ][ sl_item.id ] =
																																																	{
																																																		"name": sl_item.serial_centralita
																																																	};
																																						}// if
																																					}//
																					)//.map()
																				}//
				)//.map()

			}// if

			let temp = this.state.chartingVars;
			temp[ this.props.var_unit ] = V_vars;

			this.setState( { chartingVars: temp } );                

			this.checkCache();
		}// if


		let vars = [];

		if (  Object.keys(  this.selectedStreetlights[ this.props.var_unit ]  ).length > 0  )
		{
			Object.keys(
						this.selectedStreetlights[ this.props.var_unit ]
			).map(
					( item_sl, index ) =>
					{
						Object.keys(
										this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
						).map(
								( item, index ) =>
								{
									let varname = "";

									if (  this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  !=  false  )
									{
										varname = this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  +  "_" +  item;
										vars.push( varname );
									}
								
								}
						)//.map()
					}//
			)//.map()   
		}//if

		let unit = this.props.var_unit;

		if (  unit == "all" )
		{
			unit="Todas las vars.";
		}

		let state_disabled =  this.state.disable_can_chart  ||  this.props.disabled;

		if (  !this.justDoItOnceDone  &&  !state_disabled  )
		{
			this.selectedStreetlights[ this.props.var_unit ] = this.props.selected_streetlights;

			this.recogerDatos( this.props.var_unit );

			this.justDoItOnceDone = true;
		}

		return(
				<div className="container2-fluid">


					<div className="row" style={{paddingLeft:"10px"}}>
						<div className="col-md-6 col-xs-12 row rowCheck" style={{textAlign: "center", marginTop: "10px"}}>

							<h6 className="col-md-4 col-xs-6 graficador-title" style={{marginLeft:"10px", marginTop:"5px"}}>

								{  this.props.title  }

								<img src="img/ico/gray/ico-a-circle-arrow.svg" alt=""/>

								[
								<a
									href = ""
									className = "nav-link"
									style = { { color: "grey" } }

									onClick = 
												{
													( e ) =>
													{
														e.preventDefault();

														if (  this.state.minState == "-"  )
														{
															this.setState( { minState: "+" } );
															$( this.chartdiv ).hide();
														}
														else
														{
															this.setState( { minState: "-" } );
															$( this.chartdiv ).show();
														}
													}
												}

								>
									{  this.state.minState  }
								</a>
								]
							</h6>


							<div id="graficador-selects" className="col-md-3 col-xs-6" style = { { marginBottom: "20px", textAlign: "center" } }>

								<ul className="dropdown-graficador">

									<li className="nav-item dropdown" style = { { marginLeft: "10px " } } >

										<a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" style={{width: "100%"}}>
											<Trans>charts.variable_type</Trans>
										</a>

										<ul className="dropdown-menu" >
										{
											(  "vartype" in this.state.chartingVars[ this.props.var_unit ]  )  &&

											Object.keys(
															this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
											).map(
													( item, index ) =>
													{
														let to_return =
																		(
																		<li>

																			<input
																				className = "dropdown-item form-check-input"
																				type = "checkbox"
																				value = { true }
																				checked = { this.state.checkboxes[ index ] }

																				style = { { marginLeft: "-65px" } }

																				onChange =	{
																								( e ) =>
																								{ 
																									this.selectedVars[ this.props.var_unit ][ item ] = e.target.checked;
																									let temp = this.state.checkboxes;
																									temp[ index ] = e.target.checked;

																									this.setState( { checkboxes: temp } );
																									this.setState( { disable_can_chart:	(  Object.values(  this.selectedVars[ this.props.var_unit ]  ).indexOf(true)  <  0  ) }	);
																								}
																							}
																			/>

																			<a className="dropdown-item" href="#">
																				{`${this.state.chartingVars[this.props.var_unit]["vartype"][item].name} (${this.state.chartingVars[this.props.var_unit]["vartype"][item].code})`}
																			</a>

																		</li>
																		);

														return to_return;
													}//
											)//.map()
										}
										</ul>

									</li>

								</ul>
							</div>


						</div>


						<div className="col-md-6 col-xs-12" style={{textAlign: "right"}}>

							<button
								type="submit"
								className="btn btn-orange btnRecogerDatos col-sm-12 col-md-5"
								disabled={state_disabled} 
								style={{marginRight: "10px", marginTop: "10px"}}
								data-toggle="modal"
								data-target=".bd-filtrar-modal-sm"
								onClick =
											{
												( e ) =>
												{
													this.selectedStreetlights[ this.props.var_unit ] = this.props.selected_streetlights;

													this.recogerDatos( this.props.var_unit );
													e.preventDefault();
												}
											}
							>
								<Trans>charts.get_data</Trans>
							</button>


							<button
									type = "submit"
									className = "btn btn-info col-sm-12 col-md-5"
									disabled = { state_disabled } 
									style = { {marginTop: "10px"} }
									data-toggle = "modal"
									data-target = ".bd-filtrar-modal-sm"

									onClick =	{
													( e ) =>
													{

														let filtro =	{
																			"vars": [],
																			"streetlights": []
																		};

														let cache_data = localStorage.getItem( NOMBRE_CACHE );

														let installationgroup_id = this.state.selected_installationgroup.id;

														if (  !cache_data  )
														{
															cache_data =	{
																				"main": {},
																				"all": {},
																			};

															cache_data[ installationgroup_id ] = {};
															cache_data[ installationgroup_id ][ unit ] = [];
														}
														else
														{
															cache_data = JSON.parse( cache_data );
														}


														if (  ( this.state.selected_installationgroup.id in cache_data ) == false  )
														{
															cache_data[ this.state.selected_installationgroup.id ] = {};
														}


														cache_data[ this.state.selected_installationgroup.id ][ unit ] = [];


														Object.keys(
																		this.selectedVars[ unit ]
														).map(
																( item, index ) =>
																{
																	if (  this.selectedVars[ unit ][ item ] == true  )
																	{
																		filtro.vars.push( item );
																		cache_data[ this.state.selected_installationgroup.id ][ unit ].push( item );
																	}
																}
														);//.map()


														Object.keys(
																		this.selectedStreetlights[ unit ]
														).map(
																( item, index ) =>
																{
																	if (  this.selectedStreetlights[ unit ][ item ] != false  )
																	{
																		filtro.streetlights.push( item );
																	}
																}
														);//.map() 



														let startDate = this.props.startDate.unix();

														let endDate;


														if (  this.props.endDate  )
														{
															endDate = this.props.endDate.unix();
														}
														else
														{
															endDate = Moment().unix();
														}


														if (  this.props.endDate.isSameOrAfter( Moment() ,"day")  )
														{
															endDate = Moment().unix();
														}


														let url =	CONFIG.api_url	+
																						"/installations/"  						+  this.state.selected_installationgroup.id  +
																						"/groupdata2.csv?page_size=1000&vars="  +  filtro.vars  +
																						"&sl=" 									+  filtro.streetlights  +
																						"&start_date=" 							+  startDate  +
																						"&end_date="							+  endDate;

														console.log( url );

														let token = "Token " + this.props.auth.token;

														axios(
																{
																	url: url,

																	method: 'GET',

																	responseType: 'blob', // important

																	"headers":
																				{
																					'Content-Type': 'application/text',
																					'Authorization': token
																				}
																}
														).then(
																( response ) =>
																{
																	const url = window.URL.createObjectURL(  new Blob( [ response.data ] )  );

																	const link = document.createElement( 'a' );

																	link.href = url;

																	link.setAttribute( 'download', 'data.csv' ); 	//or any other extension

																	document.body.appendChild( link );

																	link.click();
																}
														);//.then()

														//document.location.href=url;
														
														e.preventDefault();
													}
												}
							>
								<Trans>charts.download_data</Trans>

							</button>

						</div>
					</div>




					{ /* ------------   GRAFICAS   ----------   */}


					<div className="col-md-12 cont-graph" style= { { marginTop:"15px" } }>

						<div className="perfil" style= { { padding: "0px", marginLeft: "-5px", marginRight: "-15px" } } >

							<div className="row">

								<div className="col-sm-12 col-md-12 col-xl-12">

									{  this.showSpinner()  }

									<div
										ref =
											{
												( e ) =>
												{
													// AQUI SE PROPORCIONAN LOS DATOS DE LAS GRÁFICAS EN SÍ MISMAS, SI COMENTAS YA NO SE VEN

													this.chartdiv = e;
												}
											}

										style = { { width: "100%", height: "500px", zIndex: "1" } }
									>

										<center style = { { marginTop: "200px" } }>
											<Trans>charts.select_vars</Trans>
										</center>

									</div>





									{
									this.props.var_unit == "all"  &&

									<div style = { { marginLeft: "30px" } } >
										
										<input
											type = "checkbox"
											id = "show_axis"

											defaultChecked = { this.state.dont_show_axis }

											onChange =	{
															( e ) =>
															{
																//console.log(e);
															}
														}
										/>

										<Trans>charts.no_axis</Trans>

									</div>
									}

								</div>
							</div>
						</div>

					</div>


				</div>
		)// return

	}// render ( )

  
}// class SingleChart extends Component

//---------------------------------------


const mapStateToProps =	function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}

const mapDispatchToProps = {}


export default connect(  mapStateToProps, mapDispatchToProps  ) ( SingleChart );
