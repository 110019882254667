
import CONFIG from '../config/config';

import * as auth from '../actions/core';


const initialState =	{
							installationgroups: [],

							installations: [],
							installations_buffer: [],
							installationDetail: undefined,

							scheduleprofiles: [],
							scheduleprofile: {},

							waiting_for_info: false,
							waiting_for_profile_info: false
						}


export default	( state = initialState, action ) =>
				{
					let value;

					switch( action.type )
					{
						case auth.INSTALLATION_REQUEST:

							if(  CONFIG["cacheDateIsValid"]("GET_INSTALLATION")  )
							{
								return state;    
							}


							if (  state.installationDetail === undefined  )
							{ // If "installation" is empty...

								return Object.assign(
														{},

														state,

														{
															//installations: [],

															installationDetail: undefined,
															waiting_for_info: true
														}
								) // return
							}

							return state;

						//----

						case auth.INSTALLATION_SUCCESS:

							value = action.payload;

							// Do we have that in our list?
							let inst_index = -1;

							state.installations.map(
														( item, index ) =>
														{
															if( item.id == value.id )
															{
																inst_index = index;
															}
														}
							); // .. .map()

							if ( inst_index > 0 )
							{
								state.installations[ inst_index ] = value;
								CONFIG.setCache( "GET_INSTALLATIONS", state.installations );
							}

							CONFIG.setCache( "GET_INSTALLATION", value );

							return 	Object.assign(
													{},

													state,

													{
														installationDetail: value,
														installations: state.installations,
														waiting_for_info: false
													}
									) // return

						//----

						case auth.INSTALLATIONS_REQUEST:

							if(  CONFIG["cacheDateIsValid"]("GET_INSTALLATIONS")  )
							{
								return 	Object.assign(
														{},
														
														state,

														{
															//installations: [],
															waiting_for_info: false
														}
										) // return
							}


							if (  state.installations.length < 1  )
							{
								// If "installations" is empty...

								return 	Object.assign(
														{},
														
														state,

														{
															//installations: [],
															installationDetail: undefined,
															waiting_for_info: true
														}
										) // return
							}


							if(  CONFIG["cache"]["GET_INSTALLATIONS"] == undefined  )
							{
								return	Object.assign(
														{},
														
														state,

														{
															installations: [],
															installationDetail: undefined,
															waiting_for_info: true
														}
										) // return
							}

							return state;

						//----

						case auth.INSTALLATIONGROUPS_REQUEST:

							if(  CONFIG["cacheDateIsValid"]("GET_INSTALLATIONGROUPS")  )
							{
								return	Object.assign(
														{},

														state,

														{
															//installations: [],
															waiting_for_info: false
														}
										) // return
							}


							if (  state.installationgroups === undefined  )
							{
								return	Object.assign(
														{},

														state,

														{
															//installations: [],
															installationGroupDetail: undefined,
															waiting_for_info: true
														}
										) // return
							}


							if (  state.installationgroups.length < 1  )
							{
								// If "installationgroups" is empty...

								return	Object.assign(
														{},

														state,

														{
															//installations: [],
															installationGroupDetail: undefined,
															waiting_for_info: true
														}
										) // return
							}


							if(  CONFIG["cache"]["GET_INSTALLATIONGROUPS"] == undefined  )
							{
								return	Object.assign(
														{},

														state,

														{
															installationgroups: [],

															installationGroupDetail: undefined,
															waiting_for_info: true
														}
										) // return
							}

							return state;


						//----

						case auth.INSTALLATIONS_SUCCESS:

							value = action.payload;
							CONFIG.setCache( "GET_INSTALLATIONS", value );

							return	Object.assign(
													{},

													state,

													{
														installations: value,
														waiting_for_info: false
													}
									) // return

						//----

						case auth.INSTALLATIONGROUPS_SUCCESS:

							value = action.payload;
							CONFIG.setCache( "GET_INSTALLATIONGROUPS",  value  );

							return	Object.assign(
													{},

													state,

													{
														installationgroups: value,
														waiting_for_info: false
													}
									) // return

						//----

						case auth.SCHEDULES_REQUEST:

							return	Object.assign(
													{},

													state,

													{
														scheduleprofiles: [],
														waiting_for_profile_info: true
													}
									) // return

						//----

						case auth.SCHEDULES_SUCCESS:

							value = action.payload;
							CONFIG.setCache( "GET_SCHEDULEPROFILES_" + value.id,  value );

							return	Object.assign(
													{},

													state,

													{
														scheduleprofiles: value,
														waiting_for_profile_info: false
													}
									) // return

						//----

						case auth.SCHEDULE_SUCCESS:

							value = action.payload;
							CONFIG.setCache( "GET_SCHEDULEPROFILE_" + value.id,  value );

							return	Object.assign(
													{},

													state,

													{
														waiting_for_profile_info: false,
														scheduleprofile: value
													}
									) // return

						//----

						/*
						case auth.SCHEDULE_REQUEST:
							value = {};
							CONFIG.setCache("GET_SCHEDULEPROFILE_" + value.id, value);

							return Object.assign({}, state, {
									scheduleprofile: value
								})  
						*/

						//----

						default:
							return state

					} // switch


				} // export default	( state = initialState, action ) =>