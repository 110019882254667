
/*
 * src/store.js
 * With initialState
*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Router, Route, browserHistory } from 'react-router';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { apiMiddleware } from 'redux-api-middleware';


const persistConfig =
						{
  							key: 'root',
  							storage,
						}

const persistedReducer = persistReducer( persistConfig, rootReducer );



export default function configureStore( initialState = {} )
{
    let store = createStore(
       							persistedReducer,

        						applyMiddleware( thunk, apiMiddleware )
    );//

    let persistor = persistStore( store );


    return { store, persistor };
}
