
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import { getUserInfo } from '../actions/auth';



import Main from './main';
import Instalaciones from './instalaciones';
import Farolas from './farolas';
import Calendar from './calendar';
import CalendarDetail from './calendar_detail';
import Perfiles from './perfiles';
import PerfilesMaestros from './perfiles_maestros';
import Graficador from './graficador';
import Alarmas from './alarmas';

import Historial from './historial';

import Informes from './informes';


import Informe_mensual from './informe_mensual';




import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { logout } from '../actions/auth';

import CONFIG from '../config/config';


const $ = window.$;



class LoggedIn extends Component
{

	componentDidMount ( )
	{

		this.props.getUserInfo(  this.props.auth.token  );


		let expiration_date= new Date( this.props.auth.user.expiration_date );
		
		let now = new Date();

		let days_to_expire = (  -now.getTime()  +  expiration_date.getTime()  )  /  ( 1000 * 60 * 60 * 24 );

		//console.log( expiration_date );
		//console.log( days_to_expire );

		if (  days_to_expire < 1  )
		{
			setTimeout(
			
						( ) =>
						{
							$( "#infoExpireModal" ).html( this.props.t( "login.expired" ) );
							$( "#expireModal" ).modal( "show" );

							setTimeout(
										( ) =>
										{
											$( "#expireModal" ).modal( "hide" );
											this.props.logout();
										}

										,8000
							);//
						}

						,2000
			);//
		}
		else
		{
			if (  days_to_expire < 60  )
			{
				const this_ = this;
				let dont_show = false;

				if (  CONFIG["getLocalStorage"]( "expiration_notification" )  )
				{
					if(  (  now.getTime()  -  CONFIG[ "getLocalStorage" ]( "expiration_notification" )  )  /  (  1000 * 60 * 60 * 24  )  <  1  )
					{
						dont_show = true;
					}
					
				}// if

				//----

				if (  !dont_show  )
				{
					setTimeout(
								( ) =>
								{
									$( "#infoExpireModal" ).html( this.props.t( "login.expires_soon" ) );
									$( "#expireModal" ).modal( "show" );

									setTimeout(
												( ) =>
												{
													$( "#expireModal" ).modal( "hide" );
												}

												, 8000
									);//

								}//

								, 2000
					);//
					
					CONFIG.setLocalCache( "expiration_notification",  now.getTime() );
				}//if

			}// if
		
		}// else
		//---


		let t =	setInterval(
								( ) =>
								{
									if (  $( "#main_container" ).offset() === undefined  )
									{
										return;
									}

									// Size
									if (  $("#main_container").height()  !==  (  $(window).height()  -  $(".footer").height()  -  $("#main_container").offset().top  )  )
									{
										$( "#main_container" ).height(
																		$(window).height()  -  $(".footer").height()  -  $("#main_container").offset().top
										);//
									}

									// Top
									if(  $( "#cab-general" ).height() > 0  )
									{
										if (  $("#main_container").offset().top  !==  (  $("#topNavbar").height() + $("#cab-general").height() + 40  )  )
										{
											$( "#main_container" ).css(
																		{
																			top: (  $("#topNavbar").height() + $("#cab-general").height() + 40  )
																		}
											);//
										}
									}
									else
									{
										if (  $("#main_container").offset().top  !==  ( $("#topNavbar").height() + 20 )  )
										{
											$( "#main_container" ).css(  {top: ( $("#topNavbar").height() + 20) }  );
										}//
									}//

								}

								, 500
				);//

	}// componentDidMount ( )


	//-----------------------------------------------


	render ( )
	{
		return(
				<div id="loggedindiv">


					<Route
						exact path = '/'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState( { location: "/" } );

										return  <Main	{...props}
														parent = { this.props.parent }
														isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path ='/instalaciones'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState( { location: "/instalaciones" } );

										return	<Instalaciones	{...props}
																parent = { this.props.parent }
																isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path = '/farolas'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState( { location: "/farolas" } );

										return	<Farolas	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						exact path = '/calendar'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState(  { location: "/calendar" } );

										return	<Calendar	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path = '/calendar/:installid/:month/:year'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState({location: "/calendar"});

										return	<CalendarDetail	{...props}
																parent = { this.props.parent }
																isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						exact path = '/perfiles'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState( { location: "/perfiles" } );

										return	<PerfilesMaestros	{...props}
																	parent = { this.props.parent }
																	isAuthed = { true }
										/>;
									}
								}
					></Route>


					<Route
						path = '/perfiles/:id/detalle'
						render =
								{
									( props ) =>
									{
										this.props.parent.setState(  { location: "/perfiles" }  );

										return	<Perfiles	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path = '/graficador'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState( { location: "/graficador" } );

										return	<Graficador	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
						}
					/>


					<Route
						path = '/alarmas'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState(  { location: "/alarmas" }  );

										return	<Alarmas	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path = '/historial'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState(  { location: "/historial" }  );

										return	<Historial	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path ='/informes'			// :id/detalle'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState(  { location: "/informes" }  );

										return	<Informes	{...props}
															parent = { this.props.parent }
															isAuthed = { true }
												/>;
									}
								}
					/>


					<Route
						path ='/informe_mensual'			// :id/detalle'

						render =
								{
									( props ) =>
									{
										this.props.parent.setState(  { location: "/informe_mensual" }  );

										return	<Informe_mensual	{...props}
																	parent = { this.props.parent }
																	isAuthed = { true }
												/>;
									}
								}
					/>



					<div id="expireModal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">

								<div className="modal-header">
									<h5 className="modal-title">
									</h5>

									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">
											×
										</span>
									</button>
								</div>

								<div className="modal-body" style={{minHeight: "80px"}}>
									<center id="infoExpireModal">
									</center>
								</div>

								<div className="modal-footer">
								</div>

							</div>
						</div>
					</div>


				</div>

		)// return ( )

	}// render()

	
}// class LoggedIn extends Component


//--------

const mapDispatchToProps =	( dispatch ) =>
							{
								return	{
											getUserInfo: 
														( token ) =>
														{
															dispatch(  getUserInfo( token )  )
														},

											logout:
													( ) =>
													{ 
														dispatch( logout() );
													}
										}
							}//


const mapStateToProps = function ( state )
						{
							return	{
										auth: state.auth
									}
						}//

export default withTranslation() (  withRouter( connect( mapStateToProps,  mapDispatchToProps ) ( LoggedIn ) )  );
