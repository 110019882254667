
import { RSAA } from 'redux-api-middleware';

import CONFIG from '../config/config';

export const INSTALLATIONS_REQUEST = '@@auth/INSTALLATIONS_REQUEST';
export const INSTALLATIONS_SUCCESS = '@@auth/INSTALLATIONS_SUCCESS';
export const INSTALLATIONS_FAILURE = '@@auth/INSTALLATIONS_FAILURE';

export const INSTALLATIONGROUPS_REQUEST = '@@auth/INSTALLATIONGROUPS_REQUEST';
export const INSTALLATIONGROUPS_SUCCESS = '@@auth/INSTALLATIONGROUPS_SUCCESS';
export const INSTALLATIONGROUPS_FAILURE = '@@auth/INSTALLATIONGROUPS_FAILURE';

export const INSTALLATION_REQUEST = '@@auth/INSTALLATION_REQUEST';
export const INSTALLATION_SUCCESS = '@@auth/INSTALLATION_SUCCESS';
export const INSTALLATION_FAILURE = '@@auth/INSTALLATION_FAILURE';

export const SCHEDULES_REQUEST = '@@auth/SCHEDULES_REQUEST';
export const SCHEDULES_SUCCESS = '@@auth/SCHEDULES_SUCCESS';
export const SCHEDULES_FAILURE = '@@auth/SCHEDULES_FAILURE';

export const SCHEDULE_REQUEST = '@@auth/SCHEDULE_REQUEST';
export const SCHEDULE_SAVEREQUEST = '@@auth/SCHEDULE_SAVEREQUEST';
export const SCHEDULE_SUCCESS = '@@auth/SCHEDULE_SUCCESS';
export const SCHEDULE_FAILURE = '@@auth/SCHEDULE_FAILURE';



export const getInstallations = 
								( token, show_ALL ) =>
								(
									{
										[RSAA]:
												{
													endpoint: CONFIG["api_url"]  +  '/installations?all='  +  show_ALL,

													method: 'GET',

													headers:
															{ 
																'Content-Type': 'application/json',
																'Authorization': 'Token ' + token
															},
													types:
															[
																INSTALLATIONS_REQUEST,
																INSTALLATIONS_SUCCESS,
																INSTALLATIONS_FAILURE
															],

													fetch:
															async (...args) =>
															{
																try
																{
																	const cached = CONFIG.checkCache( "GET_INSTALLATIONS" );
																}
																catch( error )
																{
																	console.log( error );
																}
																
																const cached = CONFIG.checkCache( "GET_INSTALLATIONS" );

																if ( cached )
																{ 
																	return new Response(
																							JSON.stringify( cached ),


																							{
																								status: 200,

																								headers: { 'Content-Type': 'application/json'}
																							}
																	);
																}

																// Fetch as usual if not cached
																return fetch(...args);
															}    
												}
									}
								)//


export const getInstallationGroups =
										( token, show_ALL ) =>
										(
											{
												[RSAA]:
														{
															endpoint: CONFIG["api_url"] + '/installationgroups?all=' + show_ALL,
															method: 'GET',
															
															headers:
																	{ 
																		'Content-Type': 'application/json',
																		'Authorization': 'Token ' + token
																	},

															types:
																	[
																		INSTALLATIONGROUPS_REQUEST,
																		INSTALLATIONGROUPS_SUCCESS,
																		INSTALLATIONGROUPS_FAILURE
																	],

															fetch:
																	async (...args) =>
																	{
																		try
																		{
																			const cached = CONFIG.checkCache("GET_INSTALLATIONGROUPS");
																		}
																		catch( error )
																		{
																			console.log( error );
																		}
																		
																		const cached = CONFIG.checkCache("GET_INSTALLATIONGROUPS");

																		if ( cached )
																		{ 
																			return new Response(
																									JSON.stringify(cached),
																									{
																										status: 200,

																										headers:
																												{
																													'Content-Type': 'application/json'
																												}
																									}
																			);
																		}
																		
																		// Fetch as usual if not cached
																		return fetch(...args);
																	}    
														}
											}
										)//


export const getInstallationDetail =
										( token, id ) =>
										(
											{
												[RSAA]:
														{
															endpoint: CONFIG["api_url"]  +  '/installations/'  +  id,

															method: 'GET',

															headers:
																	{ 
																		'Content-Type': 'application/json',
																		'Authorization': 'Token ' + token
																	},

															types:
																	[
																		INSTALLATION_REQUEST,
																		INSTALLATION_SUCCESS,
																		INSTALLATION_FAILURE
																	],

															fetch:
																	async (...args) =>
																	{
																		// NO CACHE here...
																		
																		// Fetch as usual if not cached
																		return fetch(...args);
																	}    
														}
											}
										)//



export const getScheduleProfiles =
									( token, id ) =>
									(
										{
											[RSAA]:
													{
														endpoint: CONFIG["api_url"]  +  '/installations/'  +  id  +  '/scheduleprofiles',

														method: 'GET',

														headers:
																{ 
																	'Content-Type': 'application/json',

																	'Authorization': 'Token ' + token
																},

														types:
																[
																	SCHEDULES_REQUEST,
																	SCHEDULES_SUCCESS,
																	SCHEDULES_FAILURE
																],

														fetch:
																async (...args) =>
																{
																	const cached = CONFIG.checkCache( "GET_SCHEDULEPROFILES_"  +  id );

																	if ( cached )
																	{
																		return new Response(
																								JSON.stringify( cached ),
																								{
																									status: 200,

																									headers:
																											{
																												'Content-Type': 'application/json'
																											
																											}
																								}
																		);
																	}

																	// Fetch as usual if not cached
																	return fetch(...args);
																}    
													}
										}
									)//


export const getScheduleProfile =
									( token, id ) =>
									(
										{
											[RSAA]:
													{
														endpoint:  CONFIG["api_url"]  +  '/scheduleprofiles/'  +  id,

														method: 'GET',

														headers:
																{ 
																	'Content-Type': 'application/json',
																	'Authorization': 'Token ' + token
																},
														
														types:
																[
																	SCHEDULE_REQUEST,
																	SCHEDULE_SUCCESS,
																	SCHEDULE_FAILURE
																],

														fetch:
																async (...args) =>
																{
																	const cached = CONFIG.checkCache( "GET_SCHEDULEPROFILE_" + id ); 
																	
																	if ( cached )
																	{
																		return new Response(
																								JSON.stringify(cached),
																								{
																									status: 200,
																									headers:
																											{
																												'Content-Type': 'application/json'
																											}
																								}
																		);
																	}
																	
																	// Fetch as usual if not cached
																	return fetch(...args);
																}    
													}
										}
									)//


export const deleteScheduleProfile = 
									( token, id ) =>
									(
										{
											[RSAA]:
													{
														endpoint: CONFIG["api_url"]  +  '/scheduleprofiles/'  +  id,

														method: 'DELETE',

														headers:
																{ 
																	'Content-Type': 'application/json',
																	'Authorization': 'Token ' + token
																},

														types:
																[
																	SCHEDULE_REQUEST,
																	SCHEDULE_SUCCESS,
																	SCHEDULE_FAILURE
																],

														fetch:
																async (...args) =>
																{
																	const cached = CONFIG.checkCache(  "GET_SCHEDULEPROFILE_"  +  id  );

																	CONFIG.setCache(  "GET_SCHEDULEPROFILE_" + id,  undefined  );
																	
																	// Fetch as usual if not cached
																	return fetch(...args);

																}
													}
										}
									)//


export const saveProfile =
							( token, info ) =>
							(
								{
									[RSAA]:
											{
												endpoint: CONFIG["api_url"]  +  '/scheduleprofiles/'  +  info.id,

												method: 'PUT',

												headers:
														{ 
															'Content-Type': 'application/json',
															'Authorization': 'Token ' + token
														},

														body: JSON.stringify(info),

														types:
																[
																	SCHEDULE_SAVEREQUEST,
																	SCHEDULE_SUCCESS,
																	SCHEDULE_FAILURE
																]    
											}
								}
							)//
