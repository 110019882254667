
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isString from 'lodash/isString';
import React, { Component } from 'react';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';


class ToggleSwitch extends Component
{

	constructor ( props )
	{
		super( props );

		this.state =
					{
						checked:	props.checked,
						onlabel:	props.onlabel,
						offlabel:	props.offlabel,
						style:		props.style
					}    

	} // constructor ( props )


	componentDidMount ( )
	{
		//this.props.getInstallations( this.props.auth.token );

	} //--------------------------------------------------------------


	render ( )
	{
		if (  this.props.checked  )  // Si no está--> Mostrar opción "ON"
		{
			return		<a
							href = "#"

							onClick =	{
											(  ) =>
											{
												this.props.onChange( false );
												this.setState(  { checked:false }  );
											}
										}
						>
							<img 
								src = "img/force_off.png"
								style = { { width: "35px", marginTop: "-3px" } }
								data-toggle = "tooltip"
								data-placement = "top"

								title = { this.state.onlabel }
							/>
						</a>
		}
		else
		{
			return		<a 
							href = "#"

							onClick =	{
											(  ) =>
											{
												this.props.onChange( true );
												this.setState(  { checked:true }  );
											}
										}
						>
							<img
								src ="img/force_on.png"
								style ={{width: "35px", marginTop: "-3px"}}
								data-toggle ="tooltip"
								data-placement ="top"

								title = { this.state.offlabel }
							/>

						</a>
		}

	} // render ( )


} // class ToggleSwitch extends Component

export default ToggleSwitch;

