
import { RSAA } from 'redux-api-middleware';

import CONFIG from '../config/config';

export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';

export const LOGOUT_REQUEST = '@@auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@@auth/LOGOUT_FAILURE';

export const USERINFO_REQUEST = '@@auth/USERINFO_REQUEST';
export const USERINFO_SUCCESS = '@@auth/USERINFO_SUCCESS';
export const USERINFO_FAILURE = '@@auth/USERINFO_FAILURE';

export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';
export const LOGOUT = '@@auth/LOGOUT';




export const login =	( username, password ) =>
						(
							{
								[RSAA]:	{
											endpoint:	CONFIG["server_url"]  +  '/api-token-auth/',

											method:		'POST',

											body:		JSON.stringify( { username, password } ),

											headers:	{ 'Content-Type': 'application/json' },

											types:		[
															LOGIN_REQUEST,
															LOGIN_SUCCESS,
															LOGIN_FAILURE
														]
										}
							}
						)

export const logout =	( ) =>
						{
							return	{
										type: LOGOUT_SUCCESS,
										payload: {}
									}
						}


export const getUserInfo =	( token ) =>
							(
								{
									[RSAA]:	{
												endpoint:	CONFIG["api_url"]  +  '/users/me',

												method:		'GET',

												headers:	{ 
																'Content-Type': 'application/json',
																'Authorization': 'Token ' + token
															},

												types:		[
																USERINFO_REQUEST,
																USERINFO_SUCCESS,
																USERINFO_FAILURE
															]
											}
								}
							)


export const refreshAccessToken =	( token ) =>
									(
										{
											[RSAA]:
											{
												endpoint:	'/api/auth/token/refresh/',

												method:		'POST',

												body:		JSON.stringify( { refresh: token } ),
												headers:	{
																'Content-Type': 'application/json'
															},

												types:		[
																TOKEN_REQUEST,
																TOKEN_RECEIVED,
																TOKEN_FAILURE
															]
											}
										}
									)