
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {getInstallations, saveProfile, getScheduleProfile, getScheduleProfiles} from '../actions/core';

import 'rc-slider/assets/index.css';

import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Moment from 'moment';

import { ComposedChart, LineChart, Line, Area, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";

import Spinner from 'react-spinner-material';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter, selectFilter } from 'react-bootstrap-table2-filter';

import axios from 'axios';
import CONFIG from '../config/config';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { getUserInfo } from '../actions/auth';



const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip( Slider.Range );
const Handle = Slider.Handle;


const $ = window.$;




//var ar_alarms_selected = [];




class Alarmas extends Component
{

	constructor ( props )
	{
		super( props );

		this.state =	{
							isNew: true,
							showLoader: false,
							id: props.match.params.id,
							schedule_id: -1,

							profileName: props.core.scheduleprofile.name,
							profileComments: props.core.scheduleprofile.comments,
							installations: props.core.installations,

							selected_installation:	{
														installation_name: "",
														id: "",
														streetlights: []
													},

							selected_streetlight: { name: "" },
							view_as_cards: true,

							total_alarms: 0,

							current_page: 0,

							alarms: "",
							options_streetlights: {},
							options_installations: {},
							options_installation_groups: {},

							options_disabled_alarms: {},


							startDate: Moment( props.auth.user.oldest_alarm ). subtract( 1, "days" ),
							endDate: Moment(),
						};



		this.checkTranslation = this.checkTranslation.bind( this );
		this.recogerAlarmas = this.recogerAlarmas.bind( this );
		this.validateAlarm = this.validateAlarm.bind( this );

		this.filter = {};
		this.group_options = "";
		this.sl_options = "";

		this.alarmId = "";

	}// constructor ( props ) --------------------------------------------------------------------




	checkTranslation ( )
	{
		let html=$(".react-bootstrap-table-pagination-total").html();

		$(".react-bootstrap-table-pagination-total").hide();

		if (  html === undefined  )
		{
			setTimeout(
						( ) =>
						{
							this.checkTranslation();
						}

						,100
			)//
		}
		else
		{
			let html = $( ".react-bootstrap-table-pagination-total" ).html();
			
			let rows = this.props.t( "app.table.showing_rows" );
			let to = this.props.t( "app.table.to" );
			let of = this.props.t( "app.table.of" );

			html = html.replace( "Showing rows", rows ).replace( "to&nbsp;", to ).replace( "of&nbsp;", of );

			setTimeout(
						( ) =>
						{
							$( ".react-bootstrap-table-pagination-total" ).html( html );
						}

						,500
			);//setTimeout
		}//

	}// checkTranslation ( ) ----------------------------------------------------------------------------



	
	componentDidMount ( )
	{
		this.setState( { showLoader: true } );

		this.recogerAlarmas(
								this.state.current_page,

								/*
								{
									"type": tipo,
									"value": temp
								}*/

								{
									"type": "filter",  //"disabled_alarms",

									"value":
												(
													{
														"filters":
														{
															"disabled_alarms":
															{
																"filterVal":		false,
																"filterType":		"SELECT",
																"comparator":		"=",
																"caseSensitive":	false
															}
														},
														
														"page":0
													}
												)
								}
		);//


		this.checkTranslation();

	}// componentDidMount ( ) --------------------------------------------------------------------------




	validateAlarm ( id, message )
	{

		let token = "Token " + this.props.auth.token;


		if (  !message  )
		{
			message = "-";
		}

		this.setState( { isLoading: true } );

		// Cargar perfiles diarios...

		axios.put(
					CONFIG.api_url  +  "/alarms/"  +  id  +  "/validate",

					{ message: message },

					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
				( result ) =>
				{
					console.log( result );

					this.setState(
									{
										isLoading: false,
										total_alarms:		this.state.total_alarms - 1
									} 
					);//

					this.props.getUserInfo( this.props.auth.token );
				}
		).catch(
				error =>
					this.setState(
									{
										error,
										isLoading: false
									}
					)
		);//

		$( "#inputModal" ).val( "" );


	}// validateAlarm ( id, message ) ----------------------------------------------------------------------------------------------------------





	recogerAlarmas ( pagina, filtro )
	{
		if (  this.state.showLoader  )
		{
			return;
		}


		if (  pagina == undefined  )
		{
			pagina = this.state.current_page + 1;
		}
		else
		{
			pagina = pagina + 1;
		}


		let token = "Token " + this.props.auth.token;

		this.state.showLoader = true;

		this.setState(  { showLoader: true, isLoading: true }  );

		this.group_options = "";
		this.sl_options = "";

		// Cargar perfiles diarios...




		axios.get(						//"/alarms/unread"
					CONFIG.api_url  + 	"/alarms?page="  +   pagina  +

										"&filtro="  +  JSON.stringify( filtro )  +				///     <---------------------------------------------------------------------------------

										"&fromDate="  +  this.state.startDate.unix()  +
										"&toDate="  +  this.state.endDate.unix()
						,
					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					},  
		).then(
				( result ) =>
				{
					let group_options_keys = [];
					let group_options = [];

					let sl_options_keys = [];
					let sl_options = []

					this.state.options_installation_groups = [];
					this.state.options_streetlights = [];

					this.state.options_installation_groups = result.data.info.all_installation_groups;
					this.state.options_streetlights = result.data.info.all_streetlights;
					


					this.state.options_disabled_alarms = [ 

															{
																value:  false,
																label:  this.props.i18n.t("alarms.enabled_alarms"),
															},

															{
																value:  true,
																label:  this.props.i18n.t("alarms.disabled_alarms"),
															},
															/*
															{
																value:  null,
																label:  "TODAS",
															},*/
														];


					//--------------------------------

					// SORT!
					// groups
					group_options_keys =  Object.keys( this.state.options_installation_groups );
					group_options_keys.sort();

					group_options_keys.map(
											( item, index ) =>
											{
												if( item !== "" )
												{
													group_options.push(
																		{
																			value: item,
																			label: item
																		}
													);  
												}
											}
					);//.. .map()


					if (  this.group_options == ""  )
					{
						this.group_options = group_options;
					}


					this.state.options_installation_groups = this.group_options;


					//---------------------------------------------------------------

					// sl

					sl_options_keys = Object.keys( this.state.options_streetlights );

					sl_options_keys.sort();

					sl_options_keys.map(
											( item, index ) =>
											{
												if(  item !== ""  )
												{
													sl_options.push(
																		{
																			value: item,
																			label: item
																		}
													);
												}//if
											}
					);// .. .map() --------------------------------------------------


					if(  this.sl_options == ""  )
					{
						this.sl_options = sl_options;
					}

					this.state.options_streetlights = this.sl_options;


					//-------------------------------------------------


					//console.log(  JSON.stringify(  result.data.results  )   );





					/*	PRUEBA
					( result.data.results ).map(

												( item, index ) =>
												{

													//console.log(  JSON.stringify(  item )   );

													console.log(  (  item.only_EKIONA )   );

													//ar_alarms_selected.push(  item  );

													if(  String( item.only_EKIONA )  ==  "true"  )
													{
														ar_alarms_selected.push(  item  );
													}//if
												}
					);  // .map() ------------------------------------------------------
					*/





					this.setState(
									{
										alarms: 		result.data.results,

										total_alarms:	result.data.count,

										current_page:	 pagina - 1,
										isLoading:		false
									}

					);//this.setState(



					setTimeout(
								( ) =>
								{
									this.setState( { showLoader: false } )
								}

								, 1000
					);//

				}

		).catch(
					error =>

						this.setState(
										{
											error,
											isLoading: false
										}
						)//
		).finally(

				() =>
				{
					/*
					setTimeout(
								() =>
								{
									console.log(  JSON.stringify(  ar_alarms_selected  )   );
								},

								500

					);
					*/

				}


		);//.. .finally()



	}// recogerAlarmas ( pagina, filtro ) ------------------------------------------------------------------------------------------------------



	render ( )
	{

		const showSpinner =		<div style={{position: "absolute", left: "48%", top: "45%"}}>
									<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
								</div>;


		// Used for translations...
		let language_code = CONFIG[ "getPreferredLanguage" ]();



		let global_language_code =	CONFIG.idiomas.find(
															x => 
																( Object.keys( x )[0] === language_code )

														)[ language_code ]

									.replace( "-", "_" );


		const columns =	[

							{
								dataField: 'id',

								text: '',
								sort: true,
								width: "280px",

								formatter: 
											( cell, row ) =>
											{
												let color=row.alarm.severity;

												color = color.replace( "rojo", "red" ).replace( "amarillo", "yellow" ).replace( "verde", "green" );
												color = color.replace( "azul", "blue" ).replace( "naranja", "orange" );

												return	<div style={{minWidth: "45x"}} >

															<button 
																data-toggle = "modal" 
																data-target = ".bd-filtrar-modal-sm"
																className = "" 
																alarmId = { row.id }

																onClick =
																			{
																				( e ) =>
																				{
																					this.alarmId = $( e.target ).attr( "alarmId" );
																				}
																			}       
															>
																&#10004;
															</button>

															<div className="circle" style={{backgroundColor: `${color}`, float: "right"}}>
															</div>

														</div>
											}
							},




							//-------------------------------------------------------------------------




							{
								dataField: 'timestamp',

								text: <Trans>alarms.date</Trans>,
								sort: true,

								headerStyle:	{
													verticalAlign: 'middle',
												},

								formatter:
											( e, row ) =>
											{
												//let date = e.replace("T"," ").split("+")[0];
												let date = Moment( e ).format( 'DD/MM/YYYY HH:mm' );

												if(  !row.read  )
												{
													return <b>{date}</b>
												}

												return <span>{date}</span>
											}
							},





							//-------------------------------------------------------------------------




							{
								dataField: 'streetlight.installation_group',

								text: <Trans>alarms.installation_group</Trans>,

								sort: true,
								width: "200px",

								filter:	selectFilter(
														{
															options: this.state.options_installation_groups,
															placeholder: `${this.props.t("alarms.installation_group")}`
														}
										),

								formatter:
											( value, row ) =>
											{
												if(  !row.read  )
												{
													return <div> <b>{value}</b> </div>
												}


												return (
															<div>{value}</div>
												)
											}
							},




							//-------------------------------------------------------------------------




							{
								dataField: 'streetlight.serial_streetlight',
								text: <Trans>alarms.streetlight</Trans>,

								sort: true,

								filter:	selectFilter(
														{
															options: this.state.options_streetlights,
															placeholder: `${this.props.t("alarms.streetlight")}`
														}
										),

								formatter:
										( value, row ) =>
										{
											if ( !row.read )
											{
												return (														
														<div>
															<p  style={{marginTop: "-10px", marginBottom: "-20px"}}
															
																onMouseOver=    {
																					( event ) => 
																					{
																						//window.alert( event.target.innerHTML );
																						//event.target.innerHTML = ":)";
																						
																						event.target.style.color = "blue";
																					}
																				}
																			
																onMouseOut=    {
																					( event ) => 
																					{
																						//window.alert( event.target.innerHTML );
																						//event.target.innerHTML = ":)";
																						
																						event.target.style.color = "black";
																					}
																				}
																				
																onClick=    {
																					( event ) => 
																					{
																						//window.alert( /*event.target.innerHTML*/ this.props.location.search );
																						
																						let new_url = "https://apps.ilumek.com/instalaciones?sl_name="  +  event.target.innerHTML;
																						
																						window.location.assign( new_url );
																						
																						//window.alert( new_url );
																						
																						//event.target.innerHTML = ":)";
																					}
																				}
															><b>{value}</b></p>
															
														</div>
												)
											}//


											return (
													<div>
														<p  style={{marginTop: "-10px", marginBottom: "-20px"}}
														
															onMouseOver=    {
																			( event ) => 
																			{
																				//window.alert( event.target.innerHTML );
																				//event.target.innerHTML = ":)";
																				
																				event.target.style.color = "blue";
																			}
																		}
																		
															onMouseOut=    {
																				( event ) => 
																				{
																					//window.alert( event.target.innerHTML );
																					//event.target.innerHTML = ":)";
																					
																					event.target.style.color = "black";
																				}
																			}
																			
															onClick=    {
																				( event ) => 
																				{
																					//window.alert( /*event.target.innerHTML*/ this.props.location.search );
																					
																					let new_url = "https://apps.ilumek.com/instalaciones?sl_name="  +  event.target.innerHTML;
																					
																					window.location.assign( new_url );
																					
																					//window.alert( new_url );
																					
																					//event.target.innerHTML = ":)";
																				}
																			}
														>{value}</p>
													</div>
											)
										}
							},


							//-------------------------------------------------------------------------


							{
								dataField: 'type',
								text: this.props.i18n.t("alarms.type"),
								sort: true,

								headerStyle:
											{
												verticalAlign: 'middle',
											},

								formatter:
											( cell, row ) =>
											{
												//console.log(row);

												return	<div style={{width: "30px"}}>
															<img src={`${CONFIG.server_url}${row.alarm.thumbnail}`} />
														</div>
											}
							},
							




							//-------------------------------------------------------------------------



							{
								dataField: `alarm.description_${global_language_code}`,

								text: <Trans>alarms.description</Trans>,
								sort: true,
								classes: 'd-none d-md-table-cell',
								headerClasses: 'd-none d-md-table-cell',

								filter:
										textFilter(
													{
														className: 'test-classname',
														delay: 1000,
														placeholder: `${this.props.t("alarms.description")}`
													}
										),

								formatter:
											( value, row ) =>
											{
												if (  !row.read  )
												{
													return <div><b>{value}</b></div>
												}

												return <div>{value}</div>
											}
							},




							//-------------------------------------------------------------------------



							(

							this.props.auth.user.user_profile.userlevel == "EKIONA" &&
							
							{
								dataField: 'disabled_alarms',

								text: this.props.i18n.t("alarms.selected_alarms"),

								sort: true,

								width: "200px",


								filter:		selectFilter(
															{
																options: this.state.options_disabled_alarms,


																placeholder: 	`${ this.props.i18n.t("alarms.all_alarms") }`,

																// {"value":false,"label":"Alarmas Habilitadas"}

																/*
																selected: //window.alert(
																	
																	//JSON.stringify(
																		
																		( this.state.options_disabled_alarms  !=  null )
																		?
																			(
																				( this.state.options_disabled_alarms[ 0 ]  !=  null )
																				?
																					this.state.options_disabled_alarms[ 1 ]["value"]
																				:
																					"this.state.options_disabled_alarms[ 0 ] Es Null"
																			)
																		:
																		
																		"this.state.options_disabled_alarms Es Null"
																	//)
																//), 		//this.state.options_disabled_alarms[0]
																*/
															}
											),



								formatter:
											( value, row ) =>
											{
												//window.alert(  JSON.stringify(row)  );

												if (  !row.read  )
												{
													return 	<div> <p> { ( row.only_EKIONA == true ) ? this.props.i18n.t("alarms.disabled_alarms") : this.props.i18n.t("alarms.enabled_alarms") } </p>  </div>		//<div> <p> { "Alarma Deshabilitada:   " } <b> { "   " + String( row.only_EKIONA ) } </b> </p>  </div>
												}


												return	(
															<div> <p> { ( row.only_EKIONA  == true ) ? this.props.i18n.t("alarms.disabled_alarms") : this.props.i18n.t("alarms.enabled_alarms") } </p>  </div>		//<div> <p> { "Alarma Deshabilitada:   " } <b> { "   " + String( row.only_EKIONA ) } </b> </p>  </div>
														)
											},

								//default: "Alarmas Habilitadas"		//this.state.options_disabled_alarms[ 0 ]
							
								/*
								this.state.options_disabled_alarms = [ 

																		{
																			value:  false,
																			label:  "Alarmas Habilitadas",
																		},

																		{
																			value:  true,
																			label:  "Alarmas Deshabilitadas",
																		},
																	];
								*/
							}
							
							
							)
							
						]; // const columns



		//-------------------------------------------------------------------------


		const defaultSorted =
								[
									{
										dataField: 'name',
										order: 'asc'
									}
								];


		const [ page, sizePerPage, totalSize ] =	[
														this.state.current_page,
														50,
														this.state.total_alarms
													];


		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------


		return (
					<div className="container-fluid" style={{paddingBottom: "100px"}}>

						{
						this.state.showLoader &&

						<showSpinner/>
						}

						<div className="row">

							<div id="cab-general" className="container-fluid" style={{minHeight: "80px"}}>
								
								<div className="actions-left d-none d-md-block">
									<h2> <Trans>alarms.alarms</Trans> </h2>
								</div>


								<div className="actions-middle" style={{height: "0px", marginLeft: "30px"}}>
									<div className="">

										<DateRangePicker

											startDate={this.state.startDate} // momentPropTypes.momentObj or null,
											startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,

											endDate={this.state.endDate} // momentPropTypes.momentObj or null,
											endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,

											isOutsideRange = { () => false }
											numberOfMonths = { 1 }

											onDatesChange =	{
																( { startDate, endDate } ) =>
																{
																	this.setState( { startDate, endDate } );

																	setTimeout(
																				( ) =>
																				{
																					this.recogerAlarmas( this.state.current_page );
																				}

																				,500
																	);	
																}

															} // PropTypes.func.isRequired,

											focusedInput = { this.state.focusedInput }	 // PropTypes.oneOf([START_DATE, END_DATE]) or null,

											onFocusChange =	{
																focusedInput =>

																	this.setState({ focusedInput })
															}		// PropTypes.func.isRequired,
										/>

									</div>
								</div>


								<div style={{float:"right", marginRight: "5px"}}>

									<div className="alarmas">
										{ this.state.total_alarms } <Trans>alarms.alarms</Trans>
									</div>

								</div>

							</div>

						</div>

						<br/>
						<br/>
						<br/>


						{
						this.state.alarms  !==  ""  &&

						<div	style = {
											{
												opacity: 	this.state.isLoading  ?  "15%"  :  "100%"
											}
										}

								className = "compactTable"
						>

							<BootstrapTable

								columns = { columns }

								data = { this.state.alarms }
 
								keyField = 'id'

								classes = "table table-hover tabla-comun"

								filter = { filterFactory( {remote: true} ) }

								remote = 	{
												{
													pagination: true,
													filter: true,
													sort: true
												}
											}

								onTableChange =
												{
													( tipo, evento ) =>
													{ 
														//window.alert( tipo );

														let temp = evento;
														temp.data = undefined;

														//window.alert( JSON.stringify(  evento  )  );
														//window.alert( JSON.stringify(  temp  ) );

														//window.alert( temp.data );



														this.recogerAlarmas( 
																				this.state.current_page,

																				{
																					"type": tipo,
																					"value": temp
																				}
														);//this.recogerAlarmas( 

													}//
												}


									pagination =	{
														paginationFactory(
																			{
																				page, // Specify the current page. It's necessary when remote is enabled
																				sizePerPage, // Specify the size per page. It's necessary when remote is enabled
																				totalSize, // Total data size. It's necessary when remote is enabled
																				pageStartIndex: 0, // first page will be 0, default is 1
																				paginationSize: 5,  // the pagination bar size, default is 5
																				showTotal: true, // display pagination information

																				sizePerPageList:
																								[
																									{
																										text: '5',
																										value: 5
																									},

																									{
																										text: '10',
																										value: 10
																									},

																									{
																										text: 'All',
																										value: this.state.total_alarms
																									}
																								], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text

																				withFirstAndLast: false, // hide the going to first and last page button
																				alwaysShowAllBtns: true, // always show the next and previous page button
																				firstPageText: 'First', // the text of first page button
																				prePageText: 'Prev', // the text of previous page button
																				nextPageText: 'Next', // the text of next page button
																				lastPageText: 'Last', // the text of last page button
																				nextPageTitle: 'Go to next', // the title of next page button
																				prePageTitle: 'Go to previous', // the title of previous page button
																				firstPageTitle: 'Go to first', // the title of first page button
																				lastPageTitle: 'Go to last', // the title of last page button
																				hideSizePerPage: true, // hide the size per page dropdown
																				hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false

																				onPageChange:
																								( page, sizePerPage, c ) =>
																								{
																									console.log( c );
																									this.recogerAlarmas( page )

																								}, // callback function when page was changing

																				onTableChange:
																								( type, newState ) =>
																								{
																									console.log( type );
																								}
																			}
														)// paginationFactory
													}
							/>
						</div>

						}
									

						<br/>


						<div id="filtrar" className="modal fade bd-filtrar-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
							<div className="modal-dialog modal-sm modal-dialog-centered">
								<div className="modal-content">

									<div className="modal-header">

										<h5 className="modal-title">
											Raz&oacute;n
										</h5>

										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">
												×
											</span>
										</button>

									</div>

									<div className="modal-body">
										<input
											className="campo-modal form-control form-control-lg" 
											id="inputModal" 
											type="text"
											placeholder="Texto..."
										/>
									</div>

									<div className="modal-footer">

										<button
											type="button"
											data-dismiss="modal"
											className="btn btn-orange"

											onClick =
														{
															( e ) =>
															{
																console.log( e );
																console.log( this.alarmId );
																console.log( this.state.alarms );

																let index = -1;

																for(  let i = 0;  i < this.state.alarms.length;  i++  )
																{
																	let item = this.state.alarms[i];

																	if(  item.id == this.alarmId  )
																	{
																		index = i;
																		break;
																	}//
																};//

																this.setState(
																				{
																					"alarms":
																								this.state.alarms.slice( 0, index ).concat( 
																																			this.state.alarms.slice( index + 1 )
																								)//.concat 
																				}
																);//

																this.validateAlarm(
																						this.alarmId,

																						$( "#inputModal" ).val()
																);//
															}
														}
										>
											Validar
										</button>

									</div>

								</div>
							</div>
						</div>
						
				


				</div>

		)//return()

	}//render() ----------------------------------------------------------------------------------------------------------------

	
}// class Alarmas extends Component ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


//-----

const mapStateToProps = function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}


const mapDispatchToProps =	{
								getInstallations: getInstallations,
								getUserInfo: getUserInfo,
								getScheduleProfile: getScheduleProfile,
								getScheduleProfiles: getScheduleProfiles
							}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Alarmas));