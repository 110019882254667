
import React, { Component } from "react";

import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';


const $ = window.$;

class PasswordShowHide extends Component
{
	constructor(props) {
		super(props);

		this.state = {
		hidden: true,
		password: this.props.password
		};

		this.toggleShow = this.toggleShow.bind(this);
		this.callback = props.callback.bind(this);;
	}

	toggleShow(e) {
		this.setState({ hidden: !this.state.hidden });
		return false;
	}

	componentDidMount() {
		if (this.props.password) {
		//this.setState({ password: this.props.password });      
		console.log(this.props.password);
		}
	}

	render() {
		if(this.state.password=="" && this.props.password!=="") {
		this.state.password=this.props.password;
		$("#inputPassword").val(this.props.password);
		}

		return (
		<div className="form-label-group">

			<input  id="inputPassword" className="form-control inputPassword" placeholder={this.props.i18n.t("app.password")}
				type={this.state.hidden ? "password" : "text"}
				//value={this.state.password}
				onChange={(e)=>{
				this.callback(e.target.value);
				}}        

				required="" autoComplete="off" 
				style={{backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC')",backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%"}}/>
				<label htmlFor="inputPassword"><Trans>login.password</Trans></label>

			<br/>
			<input type="checkbox" onChange={this.toggleShow}/> <Trans>login.show_password</Trans>
		</div>
		);
	}
}

export default withTranslation()(PasswordShowHide);
