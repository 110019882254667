
import React, { Component } from 'react';

import { withRouter } from "react-router";

import {getUserInfo} from '../actions/auth';

import { connect } from 'react-redux';

import {login} from '../actions/auth';

import PasswordShowHide from './passShowHide';

import {getInstallations} from '../actions/core';

import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


import CONFIG from '../config/config';
import axios from 'axios';

import qs  from 'query-string';


const $ = window.$;



class LoggedOut extends Component
{
	constructor ( props )
	{
		super( props );

		this.state =	{
							username: "",
							password: "",

							csrf_cookie: "",
							reset_password_token: "",
							confirm_token:"",
							reset_sent: false,
							new_pass: "",
							new_pass2: "",
							pass_updated: ""
						};

		this.handlePasswordChange = this.handlePasswordChange.bind( this );

	}// constructor ( props )



	handlePasswordChange ( value )
	{
		if ( value )
		{
			this.setState( { password: value } );
		}//
		
	}// handlePasswordChange ( value )



	componentDidMount ( )
	{

		console.log(  "LoggedOut ComponentDidMount"  );


		let user_cache_data =   CONFIG.getLocalStorage( "ilumekuser" );

		let password_cache_data =  CONFIG.getLocalStorage( "ilumekuserpwd" );
		


		let user_remember_cache_data =  CONFIG.getLocalStorage( "ilumekuserremember" );
		

		if (user_remember_cache_data)
		{
			$( "#remember_me" ).attr( "checked", true );

			this.setState(  { "username": user_cache_data } );
			this.setState(  { "password": password_cache_data } );
		}
		else
		{
			$( "#remember_me" ).attr( "checked", false );
		}//


		if (  document.location.href.indexOf( "?confirm=" )  >  -1  )
		{
			let url = document.location.href.split( "?confirm=" )[1];

			url = url.split( "confirm/" )[1];

			this.setState(  { confirm_token: url }  );

			$( "#confirmPass" ).modal( "show" )

		}// 


		$( ".img-login" ).css(  "height",  window.screen.availHeight * 1.3  );
		
		let height = $( window ).height()  -  $( "#topNavbar" ).height() - $( ".footer" ).height();


		$( "#mainrow" ).height( height );

		$( ".htmlForm-login" ).css(
									{
										top:	(  (  height/2  -  $( ".form-signin" ).height() / 2  )  )
									}
		);//

	}// componentDidMount ( )



	render ( )
	{
	
		return (
					<div className="container-fluid">

						<ToastContainer />

						{

						<div className="row" id="mainrow">


							<div className="col-sm-12 col-md-6 img-login d-none d-md-block" style={{backgroundImage: "url(/img/login/img.jpg)", height: "1000px"}}>
							</div>


							<div className="col-sm-12 col-md-6 htmlForm-login">


								<form className="form-signin" _lpchecked="1" style={{marginBottom: "10%", opacity: "95%"}}>

									<div className="text-center mb-4">
										<img className="mb-4" src="/img/login/Ekiona.png" alt="" width="40%"/>

										<h1 className="h3 mb-3 font-weight-normal">

											<Trans>login.init_sesion</Trans>

										</h1>
									</div>



									<div className="form-label-group">

										<input
											type = "text"
											id = "inputEmail"

											className = "form-control inputEmail"

											placeholder = { this.props.i18n.t( "app.user" ) }

											required=""
											autoFocus=""
											autoComplete="off" 
											value = { this.state.username }

											onChange =
														{
															( e ) =>
															{
																this.setState( { username: e.target.value } );
															}
														}

											style =
													{
														{
															border: "0px",
															backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC')",
															backgroundRepeat: "no-repeat",
															backgroundAttachment: "scroll",
															backgroundSize: "16px 18px",
															backgroundPosition: "98% 50%"
														}
													}
										/>

										<label htmlFor="inputEmail">
											<Trans>login.user</Trans>
										</label>

									</div>



		




									<div className={/*"form-label-group"*/"checkbox mb-3"}>

										<PasswordShowHide
														//password = ""  
														password = { this.state.password }
														parent = { this }
														callback = { this.handlePasswordChange }
										/>

									</div>



									<div className={"checkbox mb-3"}>

										<label>
											<input type="checkbox" value="remember-me" id="remember_me"/>

											<Trans>login.remember_me</Trans>

										</label>

									</div>





									<center>
									<table style={{marginTop: "-1rem"}}>

										<tr>

											<td>
												<button
														className={"btn btn-ilumek centerH"/*"btn btn-xs"*/}

														onClick =
																{
																	( e ) =>
																	{
																		e.preventDefault();


																		this.props.login(
																							this.state.username,
																							this.state.password
																		);

																		if (  $( "#remember_me" ).is( ":checked" )  )
																		{
																			CONFIG.setLocalCache( "ilumekuser", this.state.username );
																			CONFIG.setLocalCache( "ilumekuserpwd", this.state.password );
																			CONFIG.setLocalCache( "ilumekuserremember", "true" );
																		}
																		else
																		{
																			CONFIG.setLocalCache( "ilumekuser", undefined );
																			CONFIG.setLocalCache( "ilumekuserpwd", undefined );
																			CONFIG.setLocalCache( "ilumekuserremember", "" );
																		}

																		const this_ = this;

																		setTimeout(
																					( ) =>
																					{
																						this_.props.parent.updateMe();

																						if(  this.props.auth.access_error  )
																						{
																							toast.warning(
																											this.props.i18n.t( "login.cant_login" ),

																											{
																												position: "top-right",
																												autoClose: 3500,
																												hideProgressBar: true,
																												closeOnClick: true,
																												pauseOnHover: true,
																												draggable: false,
																												progress: undefined,
																											}
																							);//
																						}
																						else
																						{
																							getInstallations(  this.props.auth.token,  true  );

																							setTimeout(
																										( ) =>
																										{
																											//this.props.history.push('/');

																											window.location.href =  window.location.href.split( "//" )[0]  +  "//"  +  window.location.href.split( "//" )[1].split( "/" )[0];
																										}

																										, 1000
																							);//
																						}// else
																					}

																					, 1000
																		);//
																		/*
																		setTimeout(
																			()=>{
																				//this.props.history.push('/');
																				window.location.href=window.location.href.split("//")[0]+"//"+window.location.href.split("//")[1].split("/")[0];
																			}
																		,1000);
																		*/
																	}
																}
												>
													<Trans>login.init_sesion</Trans>

												</button>
											</td>




											<td>
												<button
													className={"btn btn-xs"/*"btn btn-ilumek centerH"*/}

													onClick =
																{
																	( e ) =>
																	{
																		let url = CONFIG.server_url  +  '/password_reset/';

																		axios.get(
																					url
																		).then(
																				( result ) =>
																				{
																					//$("#infoModal").html($(result.data).find("#content").html());

																					console.log( result );
																					let token = $( result.data ).find( "input[name='csrfmiddlewaretoken']" ).val();

																					console.log( token );
																					this.setState( { reset_password_token: token } );
																				}
																		);//

																		e.preventDefault();

																		//$("#infoModal").load(CONFIG.server_url+'/password_reset/');

																		$( "#resetPass" ).modal( "show" );
																	}
																}
												>
													<Trans>login.forgot_password</Trans>

												</button>
											</td>

										</tr>
									</table>
									</center>





								</form>



								<div className="d-md-none" style={{width: "105%", height: "100%", backgroundImage: "url('/img/login/img.jpg')", position: "fixed", zIndex: "-9999", top: "0px", marginLeft: "-30px"}}>
								</div>

							</div>



							{
							
							<div id="resetPass" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
								<div className="modal-dialog modal-xs modal-dialog-centered">
									<div className="modal-content">


										<div className="modal-header">

											<h5 className="modal-title">
												<Trans>login.reset_password</Trans>
											</h5>

											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">
													×
												</span>
											</button>

										</div>


										<div className="modal-body" id="infoModal">

											{
											!this.state.reset_sent &&

											<div className="row" style={{padding: "20px"}}>

												<form action="/">

													<div className="form-group">
														<label for="email">Email:</label>
														<input type="email" className="form-control" placeholder="Email" id="reset_email" />
													</div>

													<button
														type="submit"
														className="btn btn-secondary"
														id="reset_button_ok"

														onClick =
																	{
																		( e ) =>
																		{
																			e.preventDefault();

																			$( "#reset_button_ok" ).hide();

																			let url = CONFIG.server_url  +  '/api/password_reset/';
																			let token = this.state.reset_password_token;

																			let info =	{
																							csrfmiddlewaretoken:	token,
																							email:	$("#reset_email").val()
																						};

																			document.cookie  =  "_ga=GA1.2.1395076383.1595920884; csrftoken="  +  token;

																			const headers =	{
																								"X-CSRFTOKEN":	token,
																								"content-type":	"text/html; charset=utf-8"
																							};

																			axios.post(
																						url,
																						info
																			).then(
																					( result ) =>
																					{
																						console.log(result.data);
																						this.setState({reset_sent: true});
																					}
																			).catch(
																					( result ) =>
																					{
																						console.log(result.data);
																						this.setState({reset_sent: true});
																					}
																			);//
																		}
																	}
													>
														<Trans>login.OK</Trans>
													</button>

												</form>

											</div>
											}


											{
											this.state.reset_sent  &&

											<div
												class="alert alert-success"
												role="alert"

												onClick =
														{
															( ) =>
															{
																$( "#resetPass" ).modal( "hide" )
															}
														}
											>
												<center>
													<Trans>login.OK</Trans>
												</center>
											</div>
											}


										</div>


									</div>
								</div>
							</div>
							
							}



							{
							<div id="confirmPass" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
								<div className="modal-dialog modal-xs modal-dialog-centered">
									<div className="modal-content">


										<div className="modal-header">
											<h5 className="modal-title">
												<Trans>login.confirm_password</Trans>
											</h5>

											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">
													×
												</span>
											</button>
										</div>


										{
										
										this.state.pass_updated ==""  &&

										<div className="modal-body" id="infoConfirmModal">

											<form>

												<input 
													type="password"
													id="newPass"

													className="form-control inputEmail"

													placeholder = { this.props.i18n.t( "login.new_pass" ) }

													onChange =
																{
																	( e ) =>
																	{
																		this.setState( { new_pass:	e.target.value } );
																		console.log( e );
																	}
																}
												/>


												<br/>


												<input
													type = "password"
													id = "newPass2"
													className = "form-control inputEmail"
													placeholder = { this.props.i18n.t( "login.confirm_password" ) }

													onChange =
																{
																	( e ) =>
																	{
																		this.setState( { new_pass2:	e.target.value } );
																		console.log( e );
																	}
																}
												/>

												<br/>
												<br/>

												{
												
												<button
													className = "btn btn-primary"

													disabled =	{
																	(  this.state.new_pass != ""  &&  ( this.state.new_pass == this.state.new_pass2 )  )
																	?
																		false 
																	:
																		true
																}

													onClick =	{
																	( e ) =>
																	{
																		e.preventDefault();

																		let url = CONFIG.server_url  +  '/api/password_reset/confirm/';

																		let info =	{
																						token: this.state.confirm_token,
																						password: this.state.new_pass
																					};

																		axios.post(
																					url,
																					info
																		).then(
																				( result ) =>
																				{
																					this.setState( { pass_updated: "ok" } );
																				}
																		).catch(
																				( result ) =>
																				{
																					this.setState( { pass_updated: "error" } );
																				}
																		);//
																	}
																}
												>
													<Trans>login.OK</Trans>
												</button>
												
												}

												<br/>
												<br/>

											</form>


										</div>
										
										}




										{
										this.state.pass_updated !== ""  &&

										<div
											class = "alert alert-info"
											role = "alert"
											style = {{width: "90%", margin:"auto", marginBottom: "30px"}}

											onClick =
														{
															( ) =>
															{
																$( "#confirmPass" ).modal( "hide" )
															}
														}
										>
											<center>
												{
												this.state.pass_updated == "ok"  &&

												<Trans>login.OK</Trans>
												}

												{
												this.state.pass_updated  !==  "ok"  &&

												<span> { this.state.pass_updated } </span>
												}
											</center>
										</div>
										}


									</div>
								</div>
							</div>
							}

						</div>
					
						}

					</div>

		);// return


	}// render ( )


}//



//----


const mapStateToProps =	function ( state )
						{
							return	{
										auth: state.auth
									}
						}



const mapDispatchToProps =	( dispatch ) =>
							{
								return	{
											login:
													( a, b ) =>
													{
														dispatch(login(a,b));
													},

											getUserInfo:
														( token ) =>
														{
															dispatch(  getUserInfo( token )  )
														},

											getInstallations:
																( a, b ) =>
																{
																	dispatch(  getInstallations( a, b )  )
																},
										}
							}



/* MGH Sustituí export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedOut)); */

export default withRouter(   connect(  mapStateToProps, mapDispatchToProps  )(  withTranslation()( LoggedOut )  )   );
