
/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'

import simpleReducer from './simpleReducer';
import auth, * as fromAuth from './auth.js';

import core from './core.js';


export default combineReducers(
								{
									simpleReducer,
									routing: routerReducer,
									auth: auth,
									core: core,
								}
);

