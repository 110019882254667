
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import axios from 'axios';
import CONFIG from '../config/config';

import SingleChart from './singleChart';
import CalculatedChart from './calculatedChart';

import 'react-dates/initialize'; // necessary for latest version import { SingleDatePicker } from 'react-dates'; 

import './css/datepicker.css';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

import Moment from 'moment';
import esLocale from 'moment/locale/es';

import Select from 'react-select';

import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import SelectorInstalaciones from './components/installations_select';



const NOMBRE_CACHE = "graficador_ilumek_cache";

const $ = window.$;


class Graficador extends Component
{

	constructor ( props )
	{

		super( props );

		Moment.locale( 'es', esLocale );

		this.state =
						{
							selected_installationgroup:
														{
															group_name: "",
															id:"",

															info:
																	{
																		streetlights: [],
																		available_sensors:[]
																	}
														},
														
							selected_streetlight:	{
														name: ""
													},

							isLoading: false,

							chartingVars:	{
												"all": {}
											},

							error: null,

							startDate: Moment().subtract( 7,'d' ),
							endDate: Moment(),

							disabled: false
						};


		this.selectedVars = {};
		this.selectedStreetlights = {};

		this.maxSelectedSL = 4;

		this.updateVars = this.updateVars.bind( this );
		this.checkCache = this.checkCache.bind( this );

		this.charts = [];

	} // constructor ( props )


	componentDidMount ( )
	{
	} // componentDidMount()



	checkCache ( )
	{
		this.selectedStreetlights = {};

		let cache_data = JSON.parse(  localStorage.getItem( NOMBRE_CACHE )  );

		// Streetligths
		if (  Object.keys(  this.state.chartingVars[ "all" ]  ).indexOf( "streetlights" )  >  -1  )
		{
			this.state.disabled = false;
			this.selectedStreetlights = {};

			Object.keys(
							this.state.chartingVars[ "all" ][ "streetlights" ]
			).map(
					( item, index ) =>
					{
						if (  cache_data && cache_data[ this.state.selected_installationgroup.id ] !== undefined  )
						{
							if(  cache_data[ this.state.selected_installationgroup.id ][ "streetlights" ].indexOf( item )  > -1  )
							{
								this.selectedStreetlights[ item ] = this.state.chartingVars[ "all" ][ "streetlights" ][item].serial_centralita;
								this.state.disabled = false;
							}
						} // if

					} //
			); // .. .map()
		} // if

		//this.setState({disabled: this.state.disabled});

	}// checkCache()


	updateVars ( )
	{
		let V_vars = 
					{
						"vartype": {},

						"streetlights": {}
					};

		let var_unit = "all";


		if (  this.state.selected_installationgroup.id  ==  ""  )
		{
			return;
		}

		this.maxSelectedSL = this.state.selected_installationgroup.max_vars_in_chart;


		if (  this.state.selected_installationgroup.info.available_vars.length > 0  )
		{
			this.state.selected_installationgroup.info.available_vars.map(
																			( item, index ) =>
																			{
																				if (  var_unit == "all"  ||  ( item.unit == var_unit  &&  item.code in V_vars[ "vartype" ] == false )  )
																				{
																					V_vars[ "vartype" ][ item.code ] = 
																													{
																														"name": item.name,
																														"unit": item.unit,
																														"code": item.code
																													};
																				}

																				this.state.selected_installationgroup.info.streetlights.map(
																																				( sl_item, sl_index ) =>
																																				{
																																					let found = false;

																																					if (  sl_item.available_vars.length > 0  )
																																					{
																																						sl_item.available_vars.map(
																																													( sl_var_item, sl_var_index ) =>
																																													{
																																														if(  sl_var_item.code == item.code  )
																																														{
																																															found = true;
																																														}
																																													} //
																																						); 
																																					}

																																					if( found )
																																					{
																																						V_vars[ "streetlights" ][sl_item.id] =
																																															{
																																																"name": sl_item.name,
																																																"serial_centralita": sl_item.serial_centralita
																																															};
																																					}
																																				} //
																				) // .... .map( ... )
																			} //
			) // ... .map( ... )

		} // if

		let temp = this.state.chartingVars;
		temp[var_unit] = V_vars;

		this.setState(  { chartingVars: temp }  );

	} // updateVars ( )



	render()
	{

		if (  "streetlights" in this.state.chartingVars[ "all" ] == false  )
		{ 
			//} && this.state.selected_installation.id!="") {

			this.updateVars();
			this.checkCache();
		}


		if (  this.state.selected_installationgroup.id != ""  &&  this.state.selected_streetlight.name == ""  )
		{
			if (  this.state.selected_installationgroup.info.streetlights.length > 0  )
			{
				this.setState(  { selected_streetlight: this.state.selected_installationgroup.info.streetlights[ 0 ] }  );
			} // if

		} // if


		return(
				<div className="container-fluid chartcontainer">

					<div className="d-none d-md-block" style = { { height: "100px" } }>
						&nbsp;
					</div>


					<div className="row">
						<div id="cab-general" className="container-fluid">

							<div className="row">

								{/*-----------------------*/}

								<div className="col-md-4" style = { { marginRight: "30px" } }>

									<ul className="dropdown-instalaciones">


										<SelectorInstalaciones

											showAll = { false }

											onDataLoaded =
															{
																( e ) =>
																{
																	//console.log(e);
																	//this.setState({selected_installationgroup: e});

																}
															}

											onDataChanged =
															{
																( e ) =>
																{
																	this.setState(
																					{
																						chartingVars:
																										{
																											"all": {}
																										},

																						selected_installationgroup: e,
																						total_streetlights: e.info.streetlights.length
																					}
																	); // .setState

																	this.updateVars();
																	this.checkCache();

																	//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);
																	//CONFIG["setPreferredInstallation"](e.value);                                                      
																}
															}
										/>

									</ul>

								</div>

								{ /* -----------------*/ }


								<div className="actions-left" style={ { marginBottom: "10px" } }>
									<center>

										<DateRangePicker
											startDate = { this.state.startDate } // momentPropTypes.momentObj or null,
											startDateId = "your_unique_start_date_id" // PropTypes.string.isRequired,

											endDate = { this.state.endDate } // momentPropTypes.momentObj or null,
											endDateId = "your_unique_end_date_id" // PropTypes.string.isRequired,

											isOutsideRange = { () => false }
											numberOfMonths = { 1 }

											onDatesChange =	{
																( { startDate, endDate } ) =>
																{
																	let days = ( endDate - startDate )  /  ( 3600 * 1000 * 24 );

																	if(  days  >  3 * 31  )
																	{
																		setTimeout(
																					( ) =>
																					{
																						//endDate = startDate.add(3* 31, "days");

																						this.setState( { endDate: this.state.startDate.clone().add( 3 * 31, "days" ) } );

																						$( "#infoModal" ).html( this.props.t( "charts.range_too_long" ) );
																						$( "#fullModal" ).modal();
																					}

																					, 500

																		); // setTimeout
																	}

																	this.setState( { startDate, endDate } );
																	//console.log("ASDADASD");

																} //

															} // PropTypes.func.isRequired,

											focusedInput = { this.state.focusedInput } 	// PropTypes.oneOf([START_DATE, END_DATE]) or null,

											onFocusChange =	{
																focusedInput =>  this.setState( { focusedInput } )

															} // PropTypes.func.isRequired,
										/>
									</center>
								</div>

								{ /* -----------------*/ }


								<div className="actions-right col-md-2 col-xs-12">

									<a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
										<Trans>charts.streetlight</Trans>
									</a>

									<ul className="dropdown-menu" style = { {marginLeft: "30px", maxHeight: "300px", overflowY: "auto", minWidth: "250px"} }>

										<li>
										{
											( "streetlights" in this.state.chartingVars[ "all" ] )  &&

											Object.keys(
															this.state.chartingVars[ "all" ][ "streetlights" ]
											).map(
													( item, index ) =>
													{
														if (  this.selectedStreetlights[item] === undefined  )
														{
															//this.selectedStreetlights[item]=this.state.chartingVars["all"]["streetlights"][item]["serial_centralita"];
															this.selectedStreetlights[item] = false;
														}

														let to_return =
																		(
																		<li>

																			<div class="form-group">
																				<div class="form-check" style={{margin: "8px"}}>

																					<input
																						class = "form-check-input sl_checkboxes"

																						name = { "check_"  +  index }
																						id = { "check_"  +  index }

																						type = "checkbox"
																						value = { true }
																						checked = { this.selectedStreetlights[item] }

																						onChange =
																									{
																										( e ) =>
																										{  
																											let disable = false;

																											if(  e.target.checked  )
																											{
																												if(  $( '.sl_checkboxes:checked' ).length < this.maxSelectedSL  )
																												{
																													this.selectedStreetlights[item] = this.state.chartingVars["all"]["streetlights"][item]["serial_centralita"];
																												}
																												//this.state.disabled=false;

																												disable = false;
																											}
																											else
																											{
																												this.selectedStreetlights[item] = false
																											}//

																											this.setState(
																															{
																																disabled:	!$( ".sl_checkboxes:checkbox:checked" ).length  >  0
																															}
																											); //
																										}
																									} //
																					/>

																					<label class="form-check-label" for={"check_" + index}>
																					{
																						this.state.chartingVars["all"]["streetlights"][item].name
																					}
																					</label>

																				</div>
																			</div>

																		</li>
														) // let to_return = ()

														return to_return;

													} //
											) // ... .map( ... )
										}
										</li>

									</ul>

								</div>

								{/*-----------------------*/}

							</div>

						</div>
					</div>


					{ /* -----------------------------------------------------------------------   ChartS  ------------------------------------------------------------------------   */}

					{
					/*
					false &&							//    ¡¡¡¡¡   FALSE   !!!!!      ESTO YA ESTABA ANTES (DEL CÓDIGO DE UNAI)

					<div className="container2 cont-graph2" style={ {marginTop: "100px"} }>

						<CalculatedChart
							var_unit=" '%;"
							vartype =
										{
											[
												{
													"name": "consumo",
													"vars": [ "B", "C" ],

													"code": "Cons.",
													
													"formula":	( varvalues, sl_id, sl_props ) =>
																{
																	let result;

																	result = (  100 * varvalues["B"] * varvalues["C"]  /  sl_props.total_power  ).toFixed(2); // totalpower

																	if (  result >= 100  )
																	{
																		result = 100;
																	}

																	return result;
																}
												},

												{
													"name": "generacion",
													"vars": [ "P","PC" ],
													
													"code": "Gen.",

													"formula":	( varvalues, sl_id, sl_props ) =>
																{
																	let result = "";

																	result = (  100 * varvalues["P"] * varvalues["PC"]  /  sl_props.panel_peak_power  ).toFixed(2);

																	if (  result >= 1  )
																	{
																		result = 100;
																	}

																	return result;
																} //
												},

												{
													"name": "carga",
													"vars": [ "B" ],

													"code": "Carga",

													"formula":	( varvalues, sl_id, sl_props ) =>
																{
																	let result = "";

																	result = (  100 * varvalues["B"]  /  sl_props.battery_nominal_voltage  ).toFixed(2);

																	if (  result >= 100  )
																	{
																		result = 100;
																	}

																	return result;
																} //
												}
											]
										}//

							title = "Energía (Wh) Generada y consumida"

							selected_installationgroup = { this.state.selected_installation }
							selected_streetlights = { this.selectedStreetlights }

							startDate = { this.state.startDate }
							endDate = { this.state.endDate }

							disabled = { this.state.disabled }
						/>

					</div>
					*/
					}





					<div className="d-md-none" style = { {height: "100px" } }>
						&nbsp;

					</div>

					{ /* ------------   Chart  ----------   */}

					{
					true &&

					<div className="container2 cont-graph2" style={ {marginTop: "130px"} }>

						{
						this.props.auth.user.user_profile.userlevel == "EKIONA" &&
							
						<center> <h3> { this.state.selected_installationgroup.group_name } </h3> </center>
						}


						<SingleChart
							var_unit = "V"

							ref =	{
										( e ) =>
										{
											this.charts.push( e )
										}
									}

							title = {<Trans>charts.voltaje</Trans>}

							selected_installationgroup = { this.state.selected_installationgroup }
							selected_streetlights={ this.selectedStreetlights }

							startDate = { this.state.startDate }
							endDate = { this.state.endDate }

							disabled= { this.state.disabled }
						/>
					</div>
					}


					{ /* ------------   Chart  ----------   */}
					{
					true &&

					<div className="container2 cont-graph2" >

						<SingleChart
							var_unit = "A"

							ref =	{
										( e ) =>
										{
											this.charts.push( e )
										}
									}

							selected_installationgroup = { this.state.selected_installationgroup }
							selected_streetlights = { this.selectedStreetlights }

							title = {<Trans>charts.corriente</Trans>}

							startDate = { this.state.startDate }
							endDate = { this.state.endDate }

							disabled = { this.state.disabled }
						/>
					</div>
					}


					{ /* ------------   All  ----------   */}
					{
					true &&

					<div className="container2 cont-graph2" >

						<SingleChart
							var_unit = "all"

							ref =	{
										( e ) =>
										{
											this.charts.push( e )
										}
									}

							selected_installationgroup = { this.state.selected_installationgroup }
							selected_streetlights = { this.selectedStreetlights }

							startDate = { this.state.startDate }
							endDate = { this.state.endDate }

							disabled= { this.state.disabled }

							title= {<Trans>charts.all_variables</Trans>}
						/>
					</div>
					}


					{ /* ------------   Chart  ----------   */}
					{
					true &&

					<div className="container2 cont-graph2">

						<SingleChart
							var_unit = "ºC"

							ref =	{
										( e ) =>
										{
											this.charts.push(e)
										}
									}

							selected_installationgroup = { this.state.selected_installationgroup }
							selected_streetlights = { this.selectedStreetlights }

							title = {<Trans>charts.temperatura</Trans>}

							startDate = { this.state.startDate }
							endDate = { this.state.endDate } 
							disabled= { this.state.disabled }
						/>
					</div> 
					}
					

					<div style = { { marginBottom: "100px" } }>
						&nbsp;
					</div>


					<div id="fullModal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

						<div className="modal-dialog modal-lg modal-dialog-centered">

							<div className="modal-content">

								<div className="modal-header">

									<h5 className="modal-title">
									</h5>

									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>

								</div>

								<div className="modal-body" id="infoModal">
								</div>


								<div className="modal-footer">
									<button type="button" data-dismiss="modal" className="btn btn-orange">
										Ok
									</button>
								</div>

							</div>

						</div>

					</div>


				</div>

		) // return(

	} // render()

} // class Graficador extends Component

//---------


const mapStateToProps =	function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						} //

const mapDispatchToProps = {}


export default withTranslation()(  connect(  mapStateToProps, mapDispatchToProps  )( withTranslation()(Graficador) ) );
