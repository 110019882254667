
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { login } from '../actions/auth';

import { getInstallations } from '../actions/core';

import	{
			ComposedChart, LineChart, Line, Area, CartesianGrid, 
			Tooltip, Brush, Bar,
			Legend, XAxis, YAxis, ResponsiveContainer

		} from 'recharts';


import 'react-dates/initialize'; 		// necessary for latest version import { SingleDatePicker } from 'react-dates'; 
import './css/datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';


import Spinner from 'react-spinner-material';

import { CSVLink, CSVDownload } from "react-csv";


import Moment from 'moment';
import esLocale from 'moment/locale/es';

import fileDownload from 'js-file-download';

//import axios from 'axios';
import { setup } from 'axios-cache-adapter';
import CONFIG from '../config/config';


const COLORS = [ '#0088FE', '#00C49F', '#f33500', '#FF8042' ];


let headers = [];


const axios = setup(
					{
						cache:	{
									maxAge: 60 * 1000
								}
					}
)// setup()

let tempDate = new Date();

let TIME_OFFSET = tempDate.getTimezoneOffset();	 //-1 * 60;




var vars = [];

var our_Received_DATA =  undefined;

var Max_value_C =  undefined;	//parseFloat(  /*-100000*/0.0  );






let temp = Moment();


if (  temp.isDST()  )
{
	//console.log(temp);
	TIME_OFFSET += 1 * 60;
}//



const TIMEZONE_OFFSET = 0;//( TIME_OFFSET) * 60 * 1000;






const decodeBase64 =	function b64DecodeUnicode ( str )
						{
							// Going backwards: from bytestream, to percent-encoding, to original string.

							return decodeURIComponent(
														atob( str ).split( '' ).map(
																						function ( c )
																						{
																							return '%' + (  '00' + c.charCodeAt(0).toString(16)  ).slice( -2 );
																						}//

																				).join( '' )
							); // return

						}; // function b64DecodeUnicode ( str )

//------





class CustomizedAxisTick extends Component
{

	render ()
	{
		const { x, y, stroke, payload } = this.props;

		let date = new Date();

		date.setTime( payload.value );
		date.setTime( payload.value - TIMEZONE_OFFSET );

		//let tempstr= date.toISOString().replace("T"," ").split(".")[0];

		let tempstr = Moment( date ).format( 'DD/MM/YYYY HH:mm' );

		return (
					<g transform = {`translate(${x},${y})`}>

						<text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-10),scale(0.8)">
							{ tempstr }
						</text>

					</g>

		); // return

	} // render ()


}; // class CustomizedAxisTick extends Component

//-----




class CustomTooltip extends Component
{

	render ( )
	{    
		const { active } = this.props;

		let { payload, label } = this.props;


		if (  active && payload  )
		{
			let date = new Date();

			date.setTime( label );
			date.setTime( label -  TIMEZONE_OFFSET );

			//let tempstr= date.toLocaleString().replace(", ","-").split(" ")[0].replace("-"," ")          
			//let tempstr= date.toISOString().replace("T"," ").split(".")[0];

			let tempstr = Moment( date ).format( 'DD/MM/YYYY HH:mm' );

			label = tempstr;


			return (
						<div className="custom-tooltip" style={ {backgroundColor: "lightgrey", padding: "10px", opacity: "0.8"} }>

							<p className="label">
								{ `${label}` }
							</p>
							
							<hr/>

							{
							payload.map(
							//
								( item, index ) =>
								{
									if (  item.name != "installation.myRS"  )
									{
										return (
													<p className = "item" style = { { color: item.color } } >
														{ item.name } : { item.value } { this.props.var_unit }
													</p>
										) //
									}
								}//
							//
							) // payload.map()
							}

						</div>

			); // return
		}

		return null;

	} // render ( )

}; // class CustomTooltip extends Component


//------------------------------------------------------------------------------------------------------------------------------------------





class BasicChart extends Component
{

	constructor ( props )
	{
		Moment.locale( 'es', esLocale );


		super( props );

		this.state =	{
							results:	{
											"480014f000m_B": [],
											"480014f000m_C": []
										},


							isLoading: false,
							showSpinner: this.props.showSpinner,

							error: null,
							yrange: null,
							yunit: this.props.yunit,

							startDate: Moment().subtract(7,'d'),
							endDate: Moment(),

							focusedInput: undefined
						};



		if (  props.maxy  )
		{
			this.state.yrange = [ 0, props.maxy ];
		}

		this.showSpinner = this.showSpinner.bind( this );

		this.csvLink = "";

	} // constructor ( props )





	componentDidMount ( )
	{
	} // componentDidMount ( )




	componentWillReceiveProps ( nextProps )
	{



		//console.log( vars );
		//console.log(  our_Received_DATA );





		var EXISTE_var_C =  false;



		this.state.vars =  nextProps.vars;



		vars = this.state.vars;

		vars.forEach(
		//
			( item, index ) =>
			{

				if (  item  ==  "Consumo (A)"  )  // HACER TRADUC
				{
					EXISTE_var_C =  true;

					//return;
				}//

			}

		//
		); //.forEach(






		//----


		this.state.results =  nextProps.data.data;


		our_Received_DATA =  this.state.results;



		Max_value_C =  parseFloat(  /*-100000*/ 0.0  );


		if (  EXISTE_var_C  ==  true  )
		{

			if (   (  our_Received_DATA  !=  undefined  )  &&  (  our_Received_DATA  !=  null  )  &&  (  our_Received_DATA.length  >=  1  )   )
			{
	
				our_Received_DATA.forEach(
				//
					( item, index ) =>
					{
						const our_aux_val =  parseFloat(  item[ "Consumo (A)" ]  );
	
						if (   our_aux_val  >=  Max_value_C   )
						{
							Max_value_C =  our_aux_val;

						}// if
	
	
						//console.log(  item[ "Consumo (A)" ]  );
					}//
		
				); //forEach(
		
				//-----
	
				//console.log(  Max_value_C  );
	
			}// if (   (  our_Received_DATA  !=  undefined  )  &&  (  our_Received_DATA  !=  null  )  &&  (  our_Received_DATA.length  >=  1  )   )
	
		
		}//
		else
		{
			Max_value_C =  undefined;	//0.0;

		}//else



		//---------------------------------------------------------------------------------------------------------


		
		this.setState(  { vars: nextProps.vars }  );

		this.setState(  { results: nextProps.data.data }  );




		//----------------------------------------------------------------------------------------------------------------



	} // componentWillReceiveProps ( nextProps )

	


	showSpinner ( )
	{
		if (  !this.props.showSpinner  )
		{
			return ( <div/> )
		}

		return(
				<div style={{position: "absolute", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "9999999"}}>

					<div style={{position: "absolute", left: "45%", top: "35%"}}>
						<Spinner radius={80} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>

				</div>

		);// return

	} // showSpinner ( )




	render ( )
	{
		//console.log("Going to chart...");

		if (  this.props.data === undefined  )
		{
			return <div/>
		}

		vars = this.state.vars;


		if (  vars == undefined  )		// ==
		{
			return <div/>;  
		}

		//---------------------


		//console.log(   this.props.yunit  );


		//const varC_MAX =  parseInt(  Math.ceil(  Max_value_C  )   ); //this.props.maxy;
		
		//window.alert(  varC_MAX  );


		//----------------------------------------------------------------------------------------------------------------


		return(
		
				<div className="container-fluid">

					<br/>

					<div className = "perfil">
						<div className = "row">

							<div className = "col-sm-12 col-md-12 col-xl-12" >
								{
								this.showSpinner()

								//&& console.log(  JSON.stringify( our_Received_DATA )  )
								}

								<ResponsiveContainer width="100%" height = { this.props.height } id="chart">


									<ComposedChart   data = { our_Received_DATA }>

										{
										vars.length == 1  &&

										<Legend 
											payload =	{
															[
																{
																	value: vars[0],
																	type: 'square',
																	color: `${COLORS[0]}`
																}
															]
														}
										/>
										}



										{/*
										vars.length > 1  &&
										<Legend iconType="plainline" iconSize={15} />
										*/}



										{
										vars.length > 1  &&  vars.length <= 3  &&

										<Legend 
											iconType="plainline"
											iconSize={15}

											payload =	{
															[
																{
																	value: vars[0],
																	type: 'square',
																	color: `${COLORS[0]}`,
																},

																{
																	value: vars[1],
																	type: 'square',
																	color: `${COLORS[1]}`
																},

																{
																	value: vars[2],
																	type: 'square',
																	color: `${COLORS[2]}`
																}

															]
														}

										/>
										}






										{
										(  vars.length  ==  4  )  &&

										<Legend 
											iconType="plainline"
											iconSize={15}

											payload =	{
															[
																{
																	value: vars[0],
																	type: 'square',
																	color: `${COLORS[0]}`
																},

																{
																	value: vars[1],
																	type: 'square',
																	color: `${COLORS[1]}`
																},

																{
																	value: vars[2],
																	type: 'square',
																	color: `${COLORS[2]}`
																},


																{
																	value: (  vars[ 3 ]  !=  "installation.myRS"  )   ?   vars[3]  :   "Conexión a red", //"Híbrida (conexión a red)", 		//vars[3],		if (  item.name != "installation.myRS"  )
																	type: 'square',
																	color: (  vars[ 3 ]  !=  "installation.myRS"  )   ?   `${COLORS[3]}`  :   `grey`	// `grey`
																}

															]
														}

										/>
										}



										<Tooltip

											content = { <CustomTooltip/> }

											var_unit = { this.props.yunit }
										/>



										{

										vars.map(
													( item, index ) =>
													{

														if (  item == "installation.myRS"  )
														{
															//console.log( item + ":  " + index );

															return(
																		<Area
																			type='step'

																			dataKey = { item }

																			fill='#9a9a9a'

																			stroke='transparent'

																			fillOpacity="0.3"

																			isAnimationActive = { false }



																			yAxisId="left"
																		/>

															) // return  

														}
														else
														{
															if (  this.props.chartType == "bars"  )
															{
																return(
																		<Bar
																			dataKey = { item }
																			stroke = { COLORS[ index ] } 
																			color = { COLORS[ index ] }

																			isAnimationActive = { false }
																			strokeWidth = { 2 }
																			dot = { false }

																			yAxisId="left"

																		/>
																) // return  
															}
															else
															{


																if (  item  ==  "FC"  )  	//  HACER CON TRADUC  // "installation.consumption_a" 
																{
																	return(
																			<Area
																				type='step'

																				dataKey = { item }

																				fill='#9a9a9a'

																				stroke='transparent'

																				fillOpacity="0.3"

																				isAnimationActive = { false }



																				yAxisId="left"
																			/>
																	) // return  																	

																}
																else
																{
																	if (  item  ==  "Consumo (A)"  )  	//  HACER CON TRADUC  // "installation.consumption_a" 
																	{
																		//console.log(  item  );


																		return (
																				
																					<Line
																						type  = "monotone"

																						dataKey = { item }
																						stroke = { COLORS[index] }
																						isAnimationActive = { false }
																						strokeWidth = { 2 }

																						
																						yAxisId="right"


																						dot = {false}
																						//dot = { { strokeWidth: 1, r: 2 } }




																						//yunit = { /*this.state.yunit*/ " A" }
																					/>



																		)  // return
																	}
																	else
																	{

																		return(
																					<Line
																						type  = "monotone"

																						dataKey = { item }
																						stroke = { COLORS[index] }
																						isAnimationActive = { false }
																						strokeWidth = { 2 }

																						
																						yAxisId="left"


																						dot = {false}
																						//dot = { { strokeWidth: 1, r: 2 } }
																					/>
																		)  // return
																	}																	

																}// else







															}//
														}

													}
										) // vars.map()
										}



										<CartesianGrid stroke="#ccc" />


										
										{
										(   (  our_Received_DATA  !=  undefined  )  &&  (  our_Received_DATA  !=  null  )  &&  (  our_Received_DATA.length  >  0  )   )   &&

										<XAxis dataKey="epoch" tick = { <CustomizedAxisTick/> } height = { 50 }/>
										}

										{
										(   (  our_Received_DATA  ==  undefined  )  ||  (  our_Received_DATA  ==  null  )  ||  (  our_Received_DATA.length  ==  0  )   )   &&

										<XAxis dataKey="" tick="" height = { 50 }/>
										}





										<YAxis yAxisId="left" orientation="left" domain = { [ 0, this.props.maxy ] } unit = { this.state.yunit } width = { 100 } />


										{
										//(  EXISTE_var_C  ==  true  )  &&

										<YAxis

											yAxisId="right"
											orientation="right"
											
											domain = 	{
															[
																0,
																
																(  Max_value_C  !=  undefined  )
																?
																	Math.ceil(  Max_value_C  )
																:
																	Math.ceil(  Max_value_C  )
															]
														}
											
											unit = { /*this.state.yunit*/ " A" }
											
										/>
										}





									</ComposedChart>

								</ResponsiveContainer>

							</div>

						</div>
					</div>
	
				</div>

		) // return

	} // render ( )

} // class BasicChart extends Component


//------


const mapStateToProps = function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						} //

const mapDispatchToProps =
							{
								getInstallations: getInstallations,
							}

export default connect( mapStateToProps, mapDispatchToProps )( BasicChart );
