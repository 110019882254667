
import CONFIG from '../config/config';

import * as auth from '../actions/auth'


const initialState =
						{
							loggedIn: false,
							access: undefined,
							access_error: false,
							refresh: undefined,

							errors: {},

							user:	{
										username: "",

										summary_info: {},

										user_profile: { thumbnail: "" }
									},
						}


export default	(  state = initialState, action  ) =>
				{
					switch( action.type )
					{
						case auth.LOGIN_SUCCESS:

							console.log( "LOGIN_SUCCESS" );

							return Object.assign(
													{},

													state,

													{
														token: action.payload.token,
														loggedIn: true,
														refresh: action.payload.refresh,
														access_error: false,
														expiration_date: "",

														errors: {}
													}
							) // return

						//---

						case auth.USERINFO_SUCCESS:

							console.log(  "USERINFO_SUCCESS"  );

							
							return Object.assign(
													{},

													state,

													{
														user:	{
																	username: action.payload.username,

																	oldest_alarm: action.payload.oldest_alarm,
																	oldest_action: action.payload.oldest_action, 
																	id: action.payload.id,
																	first_name: action.payload.first_name,
																	user_profile: action.payload.user_profile,

																	summary_info: action.payload.summary_info,

																	expiration_date: action.payload.expiration_date,
																	email: action.payload.email
																}
													}
							) // return

						//---

						case auth.USERINFO_FAILURE:

							console.log("Error accessing user info...");


							return Object.assign(
													{},

													state,

													{
														loggedIn: false,
														access: undefined
													}
							) // return

						//---

						case auth.TOKEN_RECEIVED:


							return Object.assign(
													{},

													state,

													{
														token: action.payload.token,
														loggedIn: true
													}
							) // return


						//---
						
						case auth.LOGIN_FAILURE:

							console.log( "Error logging in..." );


							return Object.assign(
													{},

													state,

													{
														loggedIn: false,
														access: undefined,
														access_error: true
													}
							) // return

						//---

						case auth.LOGOUT_SUCCESS:

							console.log( "Logout..." );

							CONFIG.setCache( "GET_INSTALLATIONS",  undefined );

							/*
							CONFIG.setLocalCache( "ilumekuser", undefined );
							CONFIG.setLocalCache( "ilumekuserpwd", undefined );
							CONFIG.setLocalCache( "ilumekuserremember", "" );

							console.log( "Hecho" );
							*/

							return Object.assign(
													{},

													state,

													{
														loggedIn: false,
														access: undefined,

														//refresh: false,

														user: {}
													}
							
							) // return

						//---

						case auth.TOKEN_FAILURE:


							return Object.assign(
													{},

													state,

													{
														loggedIn: false,
														access: undefined,
														refresh: undefined,
														user: {},

														errors: 
															action.payload.response || 	{'non_field_errors': action.payload.statusText},
													}
							) // return

						//---

						default:
							return state

					} // switch

				} // export default	(  state = initialState, action  ) =>