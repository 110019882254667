
import utils from './utils';
import aviso_legal from './aviso_legal';
import cookies from './cookies';
import React, { Component } from 'react';


const CACHE_MS = 30000;

const LOCALSTORAGEBASE = "ilumekcache";


const CONFIG =
				{
					"server_url": "https://api.ilumek.com",

					"api_url": "https://api.ilumek.com/api/v1",

					"google_maps_api_key": "AIzaSyDP9mFCHLKk3gCcuD1BJHX2hHOx6QKyq8Y",


					"timezoneDB_api_key": "ZLTVM9412DXN",


					"utils": utils,

					"cache": {},

					"aviso_legal": aviso_legal,
					
					"cookies": cookies,

					"idiomas":	[
									{"es": "es-es"},
									{"en": "en-us"},
									{"eu": "es-eu"}
								],
					
					"cacheDateIsValid":
										function ( key )
										{
											if (  CONFIG["cache"][key] == undefined  )
											{
												return false;
											}

											if (  key in CONFIG["cache"]  &&  (  new Date().getTime() - CONFIG["cache"][key]["time"]  <  CACHE_MS  )  )
											{
												return true;
											}

											return false;
										},

					"checkCache":
									function ( key )
									{
										if (  CONFIG["cacheDateIsValid"](key)  )
										{
											//return JSON.parse(CONFIG["cache"][key]["value"]);
											return JSON.parse(  CONFIG["cache"][key]["value"]  );
										}
										else
										{
											return undefined
										}
									},

					"setCache":
								function ( key, value )
								{
									if ( value === undefined )
									{ 
										CONFIG["cache"][key] = undefined;
									}
									else
									{
										CONFIG["cache"][key] =	{
																	"value": JSON.stringify( value ), 
																	"time": new Date().getTime()
																};
									}
								},

					"getLocalStorage":
										function ( key )
										{
											const keyname = LOCALSTORAGEBASE  +  "_"  +  key;

											let value = localStorage.getItem( keyname );

											if (  value !== undefined  &&  value !== "undefined"  )
											{
												return JSON.parse( value );
											}

											return value;
										},

					"setLocalCache":
									function( key, value )
									{
										const keyname = LOCALSTORAGEBASE + "_" + key;

										localStorage.setItem(  keyname,  JSON.stringify(value)  );
									},

					"getPreferredInstallation":
												function ( )
												{
													return CONFIG["getLocalStorage"]("preferredInstallation");
												},

					"setPreferredInstallation":
												function ( id )
												{
													CONFIG["setLocalCache"]( "preferredInstallation", id );
												},

					"getPreferredInstallationGroup":
													function ( )
													{
														return CONFIG["getLocalStorage"]("preferredInstallationGroup");
													},

					"setPreferredInstallationGroup":
													function ( id )
													{
														CONFIG["setLocalCache"]("preferredInstallationGroup", id);
													},

					"getPreferredLanguage":
											function ( )
											{
												let temp= CONFIG["getLocalStorage"]("preferredLanguage");

												if ( !temp )
												{
													temp = "es";
												}

												return temp;
											},

					"setPreferredLanguage":
											function ( id )
											{
												CONFIG["setLocalCache"]( "preferredLanguage", id );
											},

					"getIconPath":
									function ( name, translating_function )
									{
										if (  translating_function === undefined  )
										{
											translating_function =	function ( what )
																	{
																		return what;
																	}
										}

										const base_path = "img/ico/white/";

										if ( name == "has_presence" )
										{
											return	{
														"img": base_path  +  "presencia.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_surveillance" )
										{
											return	{
														"img": base_path + "videovigilancia.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "hybrid" )
										{
											return	{
														"img": base_path + "conex_red.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_noise_sensor" )
										{
											return	{
														"img": base_path + "ruido.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_polen_sensor" )
										{
											return	{
														"img": base_path + "polen.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_uv_sensor" )
										{
											return	{
														"img": base_path + "rayos_uv.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_sos_sensors" )
										{
											return	{
														"img": base_path + "so2.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_sos_button" )
										{
											return	{
														"img": base_path + "so2.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_air_sensor" )
										{
											return	{
														"img": base_path + "sensor_aire.png",
														"name": translating_function( "icons." + name )
													}
										}

										if ( name == "has_traffic_sensor" )
										{
											return	{
														"img": base_path + "ruido.png",
														"name": translating_function( "icons." + name )
													}
										}

										return	{
													"img": "",
													"name": name
												};
									},

					"getIconImg":
									function ( name, translating_function )
									{
										return (
													<img
														src = { CONFIG.getIconPath( name ).img }
														
														alt = ""
														data-toggle = "tooltip"
														data-placement = "top"
														
														title = { CONFIG.getIconPath( name, translating_function ).name }
														
														style = { {margin: "5px", width: "20px"} }
													/>
										)
									},
				};//


//-------------------


export default CONFIG;
