

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {saveProfile} from '../actions/core';
import { Link } from "react-router-dom";

import 'rc-slider/assets/index.css';

import Tooltip from 'rc-tooltip';


import Slider from 'rc-slider';

import Moment, { suppressDeprecationWarnings } from 'moment';

import { ComposedChart, LineChart, Line, Area, Tooltip as Ttip, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ReferenceArea, ReferenceLine, Bar } from 'recharts';
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";

import Spinner from 'react-spinner-material';

import Select from 'react-select';
import { Trans } from 'react-i18next';

import { withTranslation } from 'react-i18next';

import { setup } from 'axios-cache-adapter';


import CONFIG from '../config/config';

import vCPU from '../config/vCPU';

import { getJSON } from 'redux-api-middleware';



const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip( Slider.Range );
const Handle = Slider.Handle;


const $ = window.$;



const selectStyles =	{
							menu:
								styles =>
								(
									{
										...styles,
										zIndex: 999
									}
								)
						};



const axios =	setup(
						{
							cache:	{
										maxAge: 1000  //4000	//3000 //1000
									}
						}
				)


const handle =	( props ) =>
				{
					const	{
								value,
								dragging,
								index,
								...restProps
							}

							= props;


					return	(
								<Tooltip
										prefixCls = "rc-slider-tooltip"

										overlay = { value }
										visible = { dragging }
										placement = "top"
										key = { index }
								>
									<Handle value = { value } { ...restProps } />

								</Tooltip>

					);// return()
				};



const marks =	{
					0: "0%",
					10: "10%",
					20: "20%",
					30: "30%",
					40: "40%",
					50: "50%",
					60: "60%",
					70: "70%",
					80: "80%",
					90: "90%",
					100: "100%",
				};

const minutes_list =	[
							{ value: 0,  label: "0"   },
							{ value: 1,  label: "1"  },
							{ value: 2,  label: "2"  },
							{ value: 3,  label: "3"  },
							{ value: 4,  label: "4"  },
							{ value: 5,  label: "5"  },
							{ value: 6,  label: "6"  },
							{ value: 7,  label: "7"  },
							{ value: 8,  label: "8"  },
							{ value: 9,  label: "9"  },
							{ value: 10,  label: "10"  },
							{ value: 11,  label: "11"  },
							{ value: 12,  label: "12"  },
							{ value: 13,  label: "13"  },
							{ value: 14,  label: "14"  },
							{ value: 15,  label: "15"  }
						];

const seconds_list =	[
							{ value: 0,   label: "0"   },
							{ value: 10,  label: "10"  },
							{ value: 20,  label: "20"  },
							{ value: 30,  label: "30"  },
							{ value: 40,  label: "40"  },
							{ value: 50,  label: "50"  },
							{ value: 60,  label: "60"  } //,

							//{ value: 70,  label: "70"  },
							//{ value: 80,  label: "80"  },
							//{ value: 90,  label: "90"  },
							//{ value: 100, label: "100" }
						];


let initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );


let data_DIM  = [];
let rangos_DIM = [];


//-----------------------------


/*
var m5_dat_vals_tramos_dat_TRAMA 	=
								[
									{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
									{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


									{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
									{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
									{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
									{  ts_mins: ( 1 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
								];
*/



/*
var m5_dat_vals_tramos_GRAFICA_REAL	=
										[
											{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
											{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


											{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
											{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
											{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
											{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
										];
*/

var m5_dat_vals_tramos_GRAFICA_REAL = [];		//new Array();




var m5_dat_vals_tramos_GRAFICA_TEOR	=
										[
											{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
											{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


											{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
											{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
											{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
											{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
										];




var m5_dat_vals_tramos_GRAFICA_NOCHECORTA	=
											[
												{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
												{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


												{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
												{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
												{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
												{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
											];


var m5_dat_vals_tramos_GRAFICA_NOCHELARGA	=
											[
												{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
												{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


												{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
												{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
												{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
												{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
											];


//-------------------------------

/*
		-----

			+ 60 min = + 60 ts

		-----

		17:00 ->  0    ts

		18:00 ->  60   ts
		19:00 ->  120  ts
		20:00 ->  180  ts
		21:00 ->  240  ts
		22:00 ->  300  t

		23:00 ->  360  ts
		00:00 ->  420  ts

		01:00 ->  480  ts
		02:00 ->  540  ts

		03:00 ->  600  ts
		04:00 ->  660  ts

		05:00 ->  720  ts
		06:00 ->  780  ts

		07:00 ->  840  ts
		08:00 ->  900  ts
		09:00 ->  960  ts

		10:00 ->  1020 ts

		-----



	(17-18 :: 0%;		delta:  1h)


	18-22 :: 100%;		delta:  4h

	22-00 :: 30%;		delta:  2h

	00-02 :: 30%;		delta:  2h


	02-04 :: 30%;		delta:  2h

	04-07 :: 30%;		delta:  3h

	07-09 :: 100%;		delta:  2h


	(09-10 :: 0%;		delta:  1h)

*/

var m3_dat_vals_tramos_GRAFICA	=
									[
										{  ts_mins: ( 4 * 60 ) + 0,		   abs_timestamp_mins__START:    60,    abs_timestamp_mins__END:    300,   		porcent: 100  },		// 18-22 :: 100%;		delta:  4h
										{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   300,    abs_timestamp_mins__END:    420,   		porcent:  30  },		// 22-00 :: 30%;		delta:  2h
										{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   420,    abs_timestamp_mins__END:    540,   		porcent:  30  },		// 00-02 :: 30%;		delta:  2h

										{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   540,    abs_timestamp_mins__END:    660,   		porcent:  30  },		// 02-04 :: 30%;		delta:  2h
										{  ts_mins: ( 3 * 60 ) + 0,		   abs_timestamp_mins__START:   660,    abs_timestamp_mins__END:    780,   		porcent:  30  },		// 04-07 :: 30%;		delta:  3h
										{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   780,    abs_timestamp_mins__END:    960,   		porcent: 100  }			// 07-09 :: 100%;		delta:  2h
									];


//-------------------------------




var ar_Struct_DIMMING_save =  [];




//------

//var STR_time_SUNRISE_REAL = "";
var STR_time_SUNRISE_REAL = [];
//STR_time_SUNRISE_REAL.push( "" );


//var STR_time_SUNSET_REAL  = "";
var STR_time_SUNSET_REAL  = [];
//STR_time_SUNSET_REAL.push( "" );


//---

//var timestamp_minut_SUNSET_REAL = 0;
var timestamp_minut_SUNSET_REAL = [];
//timestamp_minut_SUNSET_REAL.push( 0 );



//var timestamp_minut_SUNRISE_REAL = 0;
var timestamp_minut_SUNRISE_REAL = [];
//timestamp_minut_SUNRISE_REAL.push( 0 );




//-------------------------------------------------------


var STR_time_SUNRISE_TEOR = "";		// 7:57
var STR_time_SUNSET_TEOR  = "";		// 17:57

var timestamp_minut_SUNSET_TEOR = 0;
var timestamp_minut_SUNRISE_TEOR = 0;


//---------------------------------




// ELora V1   -->  elorav1   = como se le llama ahora a CPU 3

var ar_DAT_elora_M5__REAL = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos




var ar_DAT_elora_M5__TEOR = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos



var ar_DAT_elora_M5__NOCHECORTA = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos

var ar_DAT_elora_M5__NOCHELARGA = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos


//--

var ar_DAT_elora_M3__REAL = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos


//------


var HORAS_TEORICAS_TOT =  14;

var backup_val_HORAS_TEORICAS_TOT =  HORAS_TEORICAS_TOT;


var MITAD_HORAS_TEORICAS =   parseFloat(   parseFloat( HORAS_TEORICAS_TOT )   /   2.0   );



//------


const const_MAX_HORAS_GRAFICA =  18;     // Previous value = 17



const const_hora_start_horario =  16;  // Previous value = 17




const const_horas_entre_comienzo_y_medianoche =  24 - 16;   // 7 Previous value = 17


const const_timestamp_medianoche =   const_horas_entre_comienzo_y_medianoche  *  60;






//-------------


//var timestamp_total_de_Noche  =  0;
var timestamp_total_de_Noche  =  [];
//timestamp_total_de_Noche.push( 0 );


//var Mitad_tiempo_noche__ts = 0;
var Mitad_tiempo_noche__ts = [];
//Mitad_tiempo_noche__ts.push( 0 );


//var TS_ABS__Mitad_Noche = 0;
var TS_ABS__Mitad_Noche = [];
//TS_ABS__Mitad_Noche.push( 0 );

//---

//var str_HOURMIN_tsABS__Mitad_Noche =  "";
var str_HOURMIN_tsABS__Mitad_Noche = [];
//str_HOURMIN_tsABS__Mitad_Noche.push( "" );

//-------------





var TS_START_grafica =  0;

var TS_FINAL_grafica =  0;


//---

var CONT_compDidMount =  0;


var str_hora_Linea_IZQ_1_2 = "";

var str_hora_Linea_DCHA_1_3 = "";
var str_hora_Linea_DCHA_3_4 = "";
var str_hora_Linea_DCHA_4_2 = "";



// --------------

//var glob_chart_data = {};

// --------------


var ar_sunset_sunrise_YEAR =  [];


//-------------------------------------------------------




var time_SUNSET_NOCHECORTA = "";
var time_MITAD_NOCHECORTA = "";
var time_SUNRISE_NOCHECORTA = "";

var time_SUNSET_NOCHELARGA = "";
var time_MITAD_NOCHELARGA = "";
var time_SUNRISE_NOCHELARGA = "";



var		timestamp_minut_SUNRISE_NOCHECORTA 		= 0;
var		timestamp_minut_MITAD_NOCHECORTA 		= 0;
var		timestamp_minut_SUNSET_NOCHECORTA		 = 0;

var		timestamp_minut_SUNRISE_NOCHELARGA 		= 0;
var		timestamp_minut_MITAD_NOCHELARGA 		= 0;
var		timestamp_minut_SUNSET_NOCHELARGA		 = 0;


/*
var STR_time_SUNRISE_NOCHECORTA = "";
var STR_time_SUNSET_NOCHECORTA  = "";

var timestamp_minut_SUNSET_NOCHECORTA = 0;
var timestamp_minut_SUNRISE_NOCHECORTA = 0;


var STR_time_SUNRISE_NOCHELARGA = "";
var STR_time_SUNSET_NOCHELARGA  = "";

var timestamp_minut_SUNSET_NOCHELARGA = 0;
var timestamp_minut_SUNRISE_NOCHELARGA = 0;
*/

//---------------------------------------


//------------------------------

var NOCHECORTA_month =  -1;
var NOCHECORTA_day   =  -1;



var MITAD_DIA__NOCHECORTA_str =  "";

var MITAD_DIA__NOCHELARGA_str =  "";


//---

var NOCHELARGA_month =  -1;
var NOCHELARGA_day   =  -1;


//------


var tipoPERFIL =  0;	//1;


//------


var ready_toRENDER =  false;


const EXTRA_MONTHS_CALC =  12;



var SCH_PROF__IS_TEMPLATE = false;


//----------------------------------------------------------------------------------



function glob_INIT__ARs_ChartData_M5 ()
{

	//--------------------

	timestamp_minut_SUNSET_TEOR  =  TS_ABS__Mitad_Noche[ 0 ]   -  (  MITAD_HORAS_TEORICAS  *  60 );
	timestamp_minut_SUNRISE_TEOR =  TS_ABS__Mitad_Noche[ 0 ]   +  (  MITAD_HORAS_TEORICAS  *  60 );

	//--------------------


	//console.log(  timestamp_minut_SUNSET_REAL[ 0 ]   );		//  33
	//console.log(  timestamp_minut_SUNRISE_REAL[ 0 ]  );		//  920


	/*
	console.log(  timestamp_minut_SUNSET_NOCHECORTA   );
	console.log(  timestamp_minut_MITAD_NOCHECORTA  );
	console.log(  timestamp_minut_SUNRISE_NOCHECORTA  );


	console.log(  timestamp_minut_SUNSET_NOCHELARGA   );
	console.log(  timestamp_minut_MITAD_NOCHELARGA  );
	console.log(  timestamp_minut_SUNRISE_NOCHELARGA  );
	*/



	//var ar_DAT_elora_M5__REAL = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos

	ar_DAT_elora_M5__REAL = new Array( /*17 * 60*/ );



	ar_DAT_elora_M5__TEOR = new Array( /*17 * 60*/ );

	ar_DAT_elora_M5__NOCHECORTA = new Array( /*17 * 60*/ );

	ar_DAT_elora_M5__NOCHELARGA = new Array( /*17 * 60*/ );


	//---

	//-------------


	// SÓLO SE HACE EL  0 !!!!!  (_ EN ÉSTE PUNTO, MÁS ABAJO SE HACEN EL RESTO_ )

	for (  let iar = 0;  iar  <= 0;  iar++  )	// SÓLO SE HACE EL  0 !!!!!			// 1 +  12 elementos ( 1 "el bueno" (único de ésta serie que se mostrará en éste módulo de Perfiles) +  12 más por mes para luego usarse para los Informes)
	{

		ar_DAT_elora_M5__REAL.push(  new Array()  );


	} // for (  let iar = 0;  iar  <= 0;  iar++  )



	//-------------


	// for : k

	for (  let k = 0;  k  <  (  const_MAX_HORAS_GRAFICA  *  60  );  k++  ) // --------------------------------------------------------------------------------------------------------------------------[
	{

		let aux_horass =   Math.floor(  (  (  const_hora_start_horario  * 60 )  +  k )  /  60  );


		aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    /*&&  (  k  >=  ( 7 * 60 )  )*/

		aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;



		let aux_minss =  k  %  60;

		aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;




		//------

		let aux_cond_SUNRISE_SUNSET__real = 	(
													(   (	k >=  timestamp_minut_SUNSET_REAL[ 0 ]  )  /* &&   (  k <  (  7 * 60  ) )*/   )

													&&

													(		k <=  timestamp_minut_SUNRISE_REAL[ 0 ]  )
												);

		//------

		//timestamp_minut_SUNSET_TEOR


		let aux_cond_SUNRISE_SUNSET__TEOR = 	(
													(   (	k >=  timestamp_minut_SUNSET_TEOR   )  /* &&   (  k <  (  7 * 60  ) )*/   )

													&&

													(		k <=  timestamp_minut_SUNRISE_TEOR  )
												);

		//------

		//timestamp_minut_SUNSET_TEOR


		let aux_cond_SUNRISE_SUNSET__NOCHECORTA = 	(
														(   (	k >=  timestamp_minut_SUNSET_NOCHECORTA   )  /* &&   (  k <  (  7 * 60  ) )*/   )

														&&

														(		k <=  timestamp_minut_SUNRISE_NOCHECORTA  )
													);



		//timestamp_minut_SUNSET_TEOR


		let aux_cond_SUNRISE_SUNSET__NOCHELARGA = 	(
														(   (	k >=  timestamp_minut_SUNSET_NOCHELARGA   )  /* &&   (  k <  (  7 * 60  ) )*/   )

														&&

														(		k <=  timestamp_minut_SUNRISE_NOCHELARGA  )
													);

		//------



		ar_DAT_elora_M5__REAL[ 0 ].push(
											{
												val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   /*( 42.0  +  37.0*Math.sin( k / 10.0 ) )*/  0  :  0,


												timestamp:  k, 		/** 60,*/		// timestamp en MINUTOS

												time_hour:  aux_horass  +  ':'  +  aux_minss,



												val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

												val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
											}
		);// .push()


		//------


		//console.log(  k  );
		//console.log(  aux_horass  +  ':'  +  aux_minss  );


		ar_DAT_elora_M5__TEOR.push(
											{
												val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   /*( 42.0  +  37.0*Math.sin( k / 10.0 ) )*/  0  :  0,


												timestamp:  k, 		/** 60,*/		// timestamp en MINUTOS

												time_hour:  aux_horass  +  ':'  +  aux_minss,


												val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

												val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
											}
		);// .push()


		//------


		//console.log(  k  );
		//console.log(  aux_horass  +  ':'  +  aux_minss  );


		ar_DAT_elora_M5__NOCHECORTA.push(
												{
													val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   /*( 42.0  +  37.0*Math.sin( k / 10.0 ) )*/  0  :  0,


													timestamp:  k, 		/** 60,*/		// timestamp en MINUTOS

													time_hour:  aux_horass  +  ':'  +  aux_minss,


													val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

													val_sunrise_sunset__NOCHECORTA:		(  aux_cond_SUNRISE_SUNSET__NOCHECORTA  ==  true  )   ?   100  :  0
												}
		);// .push()



		//------


		//console.log(  k  );
		//console.log(  aux_horass  +  ':'  +  aux_minss  );


		ar_DAT_elora_M5__NOCHELARGA.push(
												{
													val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   /*( 42.0  +  37.0*Math.sin( k / 10.0 ) )*/  0  :  0,


													timestamp:  k, 		/** 60,*/		// timestamp en MINUTOS

													time_hour:  aux_horass  +  ':'  +  aux_minss,



													val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

													val_sunrise_sunset__NOCHELARGA:		(  aux_cond_SUNRISE_SUNSET__NOCHELARGA  ==  true  )   ?   100  :  0
												}
		);// .push()


	}// for : k ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------]

	//


	//-------------


	// EMPIEZA EN 1 !!!

	for (  let iar = 1;  iar  <= EXTRA_MONTHS_CALC;  iar++  )		// 1 +  12 elementos ( 1 "el bueno" (único de ésta serie que se mostrará en éste módulo de Perfiles) +  12 más por mes para luego usarse para los Informes)
	{


		ar_DAT_elora_M5__REAL.push(  new Array()  );


		//---

		for (  let k = 0;  k  <  (  const_MAX_HORAS_GRAFICA  *  60  );  k++  ) // --------------------------------------------------------------------------------------------------------------------------[
		{

			let aux_horass =   Math.floor(  (  (  const_hora_start_horario  * 60 )  +  k )  /  60  );


			aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    /*&&  (  k  >=  ( 7 * 60 )  )*/

			aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;



			let aux_minss =  k  %  60;

			aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;




			//------

			let aux_cond_SUNRISE_SUNSET__real = 	(
														(   (	k >=  timestamp_minut_SUNSET_REAL[ iar ]  )  /* &&   (  k <  (  7 * 60  ) )*/   )

														&&

														(		k <=  timestamp_minut_SUNRISE_REAL[ iar ]  )
													);

			//------

			//timestamp_minut_SUNSET_TEOR


			let aux_cond_SUNRISE_SUNSET__TEOR = 	(
														(   (	k >=  timestamp_minut_SUNSET_TEOR   )  /* &&   (  k <  (  7 * 60  ) )*/   )

														&&

														(		k <=  timestamp_minut_SUNRISE_TEOR  )
													);

			//------



			ar_DAT_elora_M5__REAL[ iar ].push(
												{
													val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   /*( 42.0  +  37.0*Math.sin( k / 10.0 ) )*/  0  :  0,


													timestamp:  k, 		/** 60,*/		// timestamp en MINUTOS

													time_hour:  aux_horass  +  ':'  +  aux_minss,



													val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

													val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
												}
			);// .push()


			//------


		}// for : k ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------]

		//------

	} // for : iar



	//-------------


	//console.log(  ar_DAT_elora_M5__REAL  );		// correcto


}// function glob_INIT__ARs_ChartData_M5 () ----------------------------------------------------------------------------]




//-----------------------------------




function glob_INIT__ARs_ChartData_M3 ()
{


	ar_DAT_elora_M3__REAL = new Array( /*17 * 60*/ );



	//---


	// :: CARGAMOS EL PERFIL POR DEFECTO O LO QUE CONTENGA EL SCHEDULE PROFILE CARGADO :: (es decir, aquí leemos el contenido del array "m3_dat_vals_tramos_GRAFICA" que ya viene PRE-CARGADO con esos datos)



	// m3_dat_vals_tramos_GRAFICA


	//----------------

	// ÉSTA SIRVE PARA PONERLA A 0, AL COMPLETO


	// const_MAX_HORAS_GRAFICA = 17

	for (  let k = 0;  k  <  (  const_MAX_HORAS_GRAFICA  *  60  );  k++  )			// SE RELLENAN TODOS LOS MINUTOS EXISTENTES
	{


		let aux_horass =   Math.floor(  (  (  const_hora_start_horario  * 60 )  +  k )  /  60  );



		aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    //&&  (  k  >=  ( 7 * 60 )  )/

		aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;



		let aux_minss =  k  %  60;

		aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;




		//------
		//
		//let aux_cond_SUNRISE_SUNSET__real = 	(
		//											(   (	k >=  timestamp_minut_SUNSET_REAL[ 0 ]  )  // &&   (  k <  (  7 * 60  ) )/   )
		//
		//											&&
		//
		//											(		k <=  timestamp_minut_SUNRISE_REAL[ 0 ]  )
		//										);
		//

		//------


		ar_DAT_elora_M3__REAL.push(
											{
												val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   //( 42.0  +  37.0*Math.sin( k / 10.0 ) )//  0  :  0,

												// Math.round(50+(50*Math.sin(k/10.0)))

												timestamp:  k, 		// 60,/		// timestamp en MINUTOS

												time_hour:  aux_horass  +  ':'  +  aux_minss		//,



												//val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

												//val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
											}
		);// .push()



	}// for : k -----------------------------------------




	//-------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------



	//console.log(  m3_dat_vals_tramos_GRAFICA  );


	for (  let i = 0;  i  <  6;  i++  )			// SE RELLENAN TODOS LOS MINUTOS EXISTENTES POR CADA UNO DE LOS 6 TRAMOS
	{

		// m3_dat_vals_tramos_GRAFICA[ i ].ts_mins
		// m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__START
		// m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__END
		// m3_dat_vals_tramos_GRAFICA[ i ].porcent

		//---------


		/*
		document.getElementById(  "id_input_M3_PORC_WT__" + i  ).value = m3_dat_vals_tramos_GRAFICA[ i ].porcent;


		//--

		let aux_horass =   Math.floor(  m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__START  /  60  );

		aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    //&&  (  k  >=  ( 7 * 60 )  )//

		aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;


		let aux_minss =  m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__START  %  60;

		aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;


		document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value =  aux_horass  +  ':'  +  aux_minss;



		//--


		aux_horass =   Math.floor(  m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__START  /  60  );

		aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    //&&  (  k  >=  ( 7 * 60 )  )//

		aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;


		aux_minss =  m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__START  %  60;

		aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;



		document.getElementById(  "id_input_M3_TIME_WT__FIN_" + i  ).value =  aux_horass  +  ':'  +  aux_minss;
		*/



		//---------




		for (  let k = m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__START;  k  <  m3_dat_vals_tramos_GRAFICA[ i ].abs_timestamp_mins__END;  k++  )			// SE RELLENAN TODOS LOS MINUTOS EXISTENTES
		{




			ar_DAT_elora_M3__REAL[ k ].val = m3_dat_vals_tramos_GRAFICA[ i ].porcent;

			/*
			ar_DAT_elora_M3__REAL.push(
												{
													val:  m3_dat_vals_tramos_GRAFICA[ i ].porcent,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   //( 42.0  +  37.0*Math.sin( k / 10.0 ) )/  0  :  0,

													// Math.round(50+(50*Math.sin(k/10.0)))

													timestamp:  k, 		// 60,/		// timestamp en MINUTOS

													time_hour:  aux_horass  +  ':'  +  aux_minss		//,



													//val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

													//val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
												}
			);// .push()
			*/


		}// for : k -----------------------------------------


	}// for : i -------------------------------------------------------------------------------------------------------------]


	//console.log(  m3_dat_vals_tramos_GRAFICA  );






	/*

	// const_MAX_HORAS_GRAFICA = 17

	for (  let k = 0;  k  <  (  const_MAX_HORAS_GRAFICA  *  60  );  k++  )			// SE RELLENAN TODOS LOS MINUTOS EXISTENTES
	{


		let aux_horass =   Math.floor(  (  (  const_hora_start_horario  * 60 )  +  k )  /  60  );



		aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    //&&  (  k  >=  ( 7 * 60 )  )/

		aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;



		let aux_minss =  k  %  60;

		aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;




		//------
		//
		//let aux_cond_SUNRISE_SUNSET__real = 	(
		//											(   (	k >=  timestamp_minut_SUNSET_REAL[ 0 ]  )  // &&   (  k <  (  7 * 60  ) )/   )
		//
		//											&&
		//
		//											(		k <=  timestamp_minut_SUNRISE_REAL[ 0 ]  )
		//										);
		//

		//------


		ar_DAT_elora_M3__REAL.push(
											{
												val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   //( 42.0  +  37.0*Math.sin( k / 10.0 ) )//  0  :  0,

												// Math.round(50+(50*Math.sin(k/10.0)))

												timestamp:  k, 		// 60,/		// timestamp en MINUTOS

												time_hour:  aux_horass  +  ':'  +  aux_minss		//,



												//val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

												//val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
											}
		);// .push()



	}// for : k -----------------------------------------

	*/


	//return true;


}// function glob_INIT__ARs_ChartData_M3 () ----------------------------------------------------------------------------]




//------------------------------------------------------------------------------------



// coge de la INIT de los ARs ya predefinidos o cargados y los pone a los INPUTS

function glob_INIT__All_INPUTS__VALS___Mode_5 (  )
{

	//var cont_SUM_hours = 0;


	if (  document.getElementById(  "id_in_total_horas_teor"  )  !=  null  )
	{
		document.getElementById(  "id_in_total_horas_teor"  ).value =   HORAS_TEORICAS_TOT;

	} // if (  document.getElementById(  "id_in_total_horas_teor"  )  !=  null  )


	//-------------------------------------------------------------------------------------

	// for : i !

	for (  let  i = 1;  i <=  6;  i++  ) // -----------------------------------------------------------------------------------------------------[
	{


		let id_input_TIME =  "id_input_TIME_WT_0"  +  i;

		let id_input_PORC =  "id_input_PORC_WT_0"  +  i;

		/*
		let id_label_PORC =  "id_label_PORC_WT_0"  +  i;


		let name_input_TIME =  "nam_input_TIME_WT_0"  +  i;
		let name_input_PORC =  "nam_input_PORC_WT_0"  +  i;
		*/

		//let aux_id_TiME = "id_input_PORC_WT_0"  +  ( event.target.id) .split( "_0" )[ 1 ];



		/*
		var m5_dat_vals_tramos_GRAFICA_REAL[ 0 ] =	[
			{  hours: 5,  porcent: 100  },

			{  hours: 2,  porcent: 30   },
			{  hours: 0,  porcent: 0    },

			{  hours: 0,  porcent: 0    },
			{  hours: 6,  porcent: 30   },
			{  hours: 1,  porcent: 100  }
		];
		*/



		if (  document.getElementById(  id_input_TIME  )  !=  null  )
		{

			if (  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i - 1 ].ts_mins  !=  0   )
			{

				let tshours =  Math.floor(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i - 1 ].ts_mins  /  60/*.0*/  );

				let tsmins =   (  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i - 1 ].ts_mins  %  60  );



				//console.log( tshours + ", " + tsmins );


				document.getElementById(  id_input_TIME  ).value =  	String(
																				(  tshours  <  10  )
																				?
																					( '0' + tshours )
																				:
																					tshours
																		)// String()

																		+  ':'  +

																		String(
																				(  tsmins  <  10  )
																				?
																					( '0' + tsmins )
																				:
																					tsmins
																		);//
				//---


				if (  document.getElementById(  id_input_PORC  )  !=  null  )
				{
					document.getElementById(  id_input_PORC  ).value =  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i - 1 ].porcent;
				}//

				//cont_SUM_hours +=  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ] [ i - 1 ].hours;

			}// if
			else
			{
				document.getElementById(  id_input_TIME  ).value =  "00:00";	//"--:--";

				document.getElementById(  id_input_PORC  ).value =  0;	//"";
				//document.getElementById(  id_input_PORC  ).disabled = true;


				//document.getElementById(  "id_label_PORC_WT_0"  +  i  ).style.color =  "grey";

			}//

		} // if (  document.getElementById(  id_input_TIME  )  !=  null  )


	} // for (  let  i = 1;  i <=  6;  i++  ) --------------------------------------------------------------------------------------------------]


	//------------------


	let str_tiempo_total_noche_HM =	(
										(  Math.floor( timestamp_total_de_Noche[ 0 ] / 60.0 ) < 10  )
										?
											('0' + Math.floor( timestamp_total_de_Noche[ 0 ] / 60.0 ))
										:
											Math.floor( timestamp_total_de_Noche[ 0 ] / 60.0 )
									)  +

									":" +

									(
										( ( timestamp_total_de_Noche[ 0 ] % 60 )<10 )
										?
											( '0' + ( timestamp_total_de_Noche[ 0 ] % 60 ) )
										:
											( timestamp_total_de_Noche[ 0 ] % 60 )
									);

									//+ " m";

	//------


	if (  document.getElementById(  "id_input_NIGHTTIME"  )  !=  null  )
	{
		//window.alert( str_tiempo_total_noche_HM );

		document.getElementById( "id_input_NIGHTTIME" ).value =  str_tiempo_total_noche_HM;		//cont_SUM_hours  +  ':00';
	}//

	//------

	return "";


}// function glob_INIT__All_INPUTS__VALS___Mode_5 (  ) -----------------




//------------------------------------------------------------------------------------









function generateCurveData (  /*initial_data,*/  sunrise_,  sunset_  )			// 1ER PARAM COMENTADO POR SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA LLAMADA)
{
	rangos_DIM = [];
	data_DIM = [];


	let curve_data =	[
							0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ,0, 0, 0, 0, 0, 0,
							0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
						];


	sunset_  = sunset_.split( "T")[ 1 ];
	sunrise_ = sunrise_.split("T")[ 1 ];

	// MIRAR LO COMENTADO DE ABAJO (POR ESTO MISMAMENTE SE HAN COMENTADO ÉSTAS 2 LÍNEAS QUE SÍ QUE ESTABAN DE ANTES)

		/*
		let temp =	generateCurveData(
										//data,		   ///   <----------------------------------------------------------------------------------------  COMENTADO SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA DECLARACION)


										//resultDATA.info.weather.sun_info["sunrise_local"],
										//resultDATA.info.weather.sun_info["sunset_local"]

										(   "T"	+  String( ar_obj_sunset_sunrise_13elems[ 0 ][  "sunrise"  ] )  +  ":00+00:00"   ),
										(   "T"	+  String( ar_obj_sunset_sunrise_13elems[ 0 ][  "sunset"   ] )	 +  ":00+00:00"   )

					);// generateCurveData(
		*/


	let dawn =		Moment(  "20180101 "  +  sunset_,  "YYYYMMDD hh:mm"  );
	let sunrise =	Moment(  "20180102 "  +  sunrise_, "YYYYMMDD hh:mm"  );

	// -------------- Rangos ----------------

	let t_moment = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );



	for (  let i = 0;  i < 48;  i++  )
	{
		let t_moment2 =			t_moment.clone();

		let t_moment2_m45 =		t_moment.clone().add( "-45", "minutes" );
		let t_moment2_p45 =		t_moment.clone().add( "45", "minutes" );


		let sun0_value = 0;


		if (   t_moment2_p45 > dawn   &&   t_moment2_m45 < sunrise   )
		{
			sun0_value = 100;
		}//


		let sun_value = 0;

		if (  t_moment2 > dawn  &&  t_moment2 < sunrise  )
		{
			sun_value = 100;
		}//


		data_DIM.push(
						{
							name:	t_moment2.format( "HH:mm" ),

							sp:		curve_data[ i ],

							sun:	sun_value,
							sun0:	sun0_value
						}
		);//


		rangos_DIM.push(
							[
								t_moment2.format( "HH:mm" ),
								t_moment2.add( "30", "minutes" ).format( "HH:mm" ),
							]
		);//


		t_moment = t_moment.add( "30","minutes" );


	}// for -----------

	//---

	return	{
				"data":		data_DIM,

				"rangos":	rangos_DIM
			};


}//function generateCurveData (  initial_data,  sunrise_,  sunset_  )







/*
var tempDate = new Date();

var TIME_OFFSET = tempDate.getTimezoneOffset();	 //-1 * 60;
*/

//const TIMEZONE_OFFSET = 0;//( TIME_OFFSET) * 60 * 1000;


//-----------------------------------------------------------------------------------------------------------------------

// 			<XAxis dataKey="epoch" tick = { <CustomizedAxisTick/> } height = { 50 }/>







class CustomizedAxisTick extends Component
{

	render ()
	{
		const { x, y, stroke, payload } =  this.props;

		//let date = new Date();

		//console.log(  payload   );

		//date.setTime( payload.value );
		//date.setTime( payload.value - TIMEZONE_OFFSET );

		//let tempstr= date.toISOString().replace("T"," ").split(".")[0];

		let tempstr =  payload.value;	//Moment( date ).format( 'HH:mm' );		// .format( 'DD/MM/YYYY HH:mm' );

		return (
					<g transform = {`translate(${x},${y})`}>

						<text x={0} y={0} dx={5} dy={12} textAnchor="end" fill="#666" transform="rotate(-15),scale(0.8)">

							{ tempstr }

						</text>

					</g>

		); // return

	} // render ()


}; // class CustomizedAxisTick extends Component




//-----------------------------------------------------------------------------------------------------------------------






// --------------

let glob_chart_data = {};

// --------------





class Perfiles extends Component
{

	constructor ( props )
	{

		super( props );


		glob_chart_data =  data_DIM;


		///*var*/ ar_DAT_elora_M5__REAL = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos


		this.state =	{
							isNew: 				true,

							showSpinner: 		false,


							id:					props.match.params.id,

							schedule_id: 		-1,

							scheduleprofile: 	{},

							selected_installationgroup:	{
															group: "",

															id: "",

															group_name: "",

															streetlights: [],

															max_cpu_version: ""
														},

							Chart_Data_M1: 		[],


							view_as_cards: 		true,
							modalIsOpen: 		true,
							cntrlIsPressed: 	false,
							capsIsPressed: 		false,
							initialCaps: 		null,

							showAlert: 			false,

							profileName: 		"",
							profileComments: 	"",


							waiting:			false,

							wait_in: 			"seconds",


							time_to_wait: 		10,	//0, 				 // 60


							dimming: 			100,	//0,


							CPU_elorav1_Chart_Data_M5: 	[],

							CPU_elorav1_Chart_Data_M3: 	[],


							ready_RENDER:		false			// PARA CONSEGUIR NO PINTAR UNO DE LOS DIMMINGS QUE NO CORRESPONDE... AUNQUE NO LO HEMOS CONSEGUIDO...

						}; //

		//----------------------------


		this.sliders = [];

		//--

		for(  let i = 0;  i < 100;  i++  )
		{
			this.sliders[ i ] = {}

		}// for ----


		//---------------

		/*
		for ( let imon = 0;  imon <= EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0
		{

			this.state.CPU_elorav1_Chart_Data_M5.push(  new Array()  );


		} // for ( let imon = 0;  imon <= EXTRA_MONTHS_CALC;  imon++ )
		*/

		//---------------


		this.zoom = this.zoom.bind( this );
		this.checkValue = this.checkValue.bind( this );


		this.cntrlIsPressed = false;
		this.capsIsPressed = false;


		this.upperSlider = undefined;


		//--------

		this.getScheduleProfile = this.getScheduleProfile.bind( this );		// --------------------------------->  getScheduleProfile ( )

		this.getInstallationGroupDetail = this.getInstallationGroupDetail.bind( this );		// --------------------------------->  getInstallationGroupDetail ( )


		//-----


		this.insert_HTML_INPUTS_M_5 =  this.insert_HTML_INPUTS_M_5.bind( this );

		this.insert_HTML_INPUTS_M_3 =  this.insert_HTML_INPUTS_M_3.bind( this );



		this.FROM_ARs__SET_ALL_CURVE_VALUES__M5 =  this.FROM_ARs__SET_ALL_CURVE_VALUES__M5.bind( this );

		this.FROM_ARs__SET_ALL_CURVE_VALUES__M3 =  this.FROM_ARs__SET_ALL_CURVE_VALUES__M3.bind( this );

		//FROM_ARs__SET_ALL_CURVE_VALUES__M5

	}//	constructor ( props )

_



	/*function*/
	insert_HTML_INPUTS_M_5 ( )
	{

		var for_tr = [];

		//---------


		for (  let  i = 1;  i <=  6;  i++  )
		{

			let id_input_TIME =  "id_input_TIME_WT_0"  +  i;

			let id_input_PORC =  "id_input_PORC_WT_0"  +  i;

			let id_label_PORC =  "id_label_PORC_WT_0"  +  i;


			let name_input_TIME =  "nam_input_TIME_WT_0"  +  i;
			let name_input_PORC =  "nam_input_PORC_WT_0"  +  i;

			/*
			if (  i == 3  )
			{
				for_tr.push(
								(
								<tr>

									<td>
										<td>
											<label>
												---------------
											</label>
										</td>

										<td>
											<label>
												------------------
											</label>
										</td>
									</td>


									<td>
										<td>
											<label
											>
												-------------------
											</label>
										</td>
									</td>


									<td>
										<td>
											<label
											>
												-------------------
											</label>
										</td>
									</td>

								</tr>
								)
				);// .push()
			}//
			*/


			//---------------------------------


			for_tr.push(	(  i  ==  3  )  ?   (<tr>  <td> <hr/> </td>  <td> <hr/> </td> </tr>)  :  ""	); 				// ( 0  == 0 )   ?  (<tr>)  :  (<tr>) }	); </tr>


			//---------------------------------


			for_tr.push(
							(
							<tr>
								<td>
									<td>

										<label
												for = { id_input_TIME }

												style= {{width: "90px", marginRight:"1rem"}}
										>

											{ this.props.i18n.t("profiles.work_time") } <b>{ i }</b>

										</label>

									</td>


									<td>

										<input
												id={id_input_TIME}

												type="time"

												name={name_input_TIME}
												style= {{width: "80px"}}

												min="00:00"

												max="16:00"

												//value="05:00"

												/*
												onfocusout =
															{
																( event ) =>
																{
																	window.alert( "OUT OF FOCUS" );
																}//
															}
												*/

												//---------------------------------------------------------------------------------------------------------------------------------

												onChange=	{
																( event ) =>
																{

																	//window.alert( "value: " );
																	//window.alert( JSON.stringify( e.target.value ) );

																	//document.getElementById( 'id_input_NIGHTTIME' ).value =  event.target.value;



																	let cont_SUM_Hours   =  0;
																	let cont_SUM_Minutes =  0;

																	let elem_sel_H =  parseInt(   ( event.target.value ).split( ':' )[ 0 ]   );
																	let elem_sel_M =  parseInt(   ( event.target.value ).split( ':' )[ 1 ]   );

																	let timestamp_elem =  0;


																	//------------------------------

																	if (
																			( elem_sel_H  !=  undefined  )  &&
																			( elem_sel_M  !=  undefined  )  &&

																			( elem_sel_H  !=  NaN  )  &&
																			( elem_sel_M  !=  NaN  )  &&

																			(  Number.isInteger( elem_sel_H )  )  &&
																			(  Number.isInteger( elem_sel_M )  )
																		)
																	{

																		//window.alert( ( event.target.value ).split( ':' )[ 0 ] );
																		//window.alert( cont_SUM_Hours )


																		let elem_id_num =  ( event.target.id ).split( "_0" )[ 1 ];


																		timestamp_elem =  (  elem_sel_H * 60  )  +  elem_sel_M;



																		//window.alert(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins  );


																		//---

																		for ( let imon = 0;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0	!!!!!!! 	// <= 12
																		{

																			m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																		} // for ( let imon = 0;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0

																		//---


																		m5_dat_vals_tramos_GRAFICA_TEOR[ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																		m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ elem_id_num - 1 ].ts_mins =  timestamp_elem;

																		m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																		//window.alert(  "[" + ( elem_id_num - 1 ) + "] =  " +   timestamp_elem );
																		//window.alert(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins  );



																		//----

																		ar_DAT_elora_M5__REAL = [];


																		ar_DAT_elora_M5__TEOR = [];


																		ar_DAT_elora_M5__NOCHECORTA = [];

																		ar_DAT_elora_M5__NOCHELARGA = [];


																		//------------------------------

																		this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																		this.setState(
																		//
																						{
																							CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																						}
																		//
																		);//


																		//------------------------------


																		glob_INIT__ARs_ChartData_M5();			//  !!

																		//--

																		setTimeout(
																		//
																					( ) =>
																					{

																						/*
																						this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																						this.setState(
																						//
																										{
																											CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																										}
																						//
																						);//
																						*/

																						//-----------------------------------------

																						this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

																						//----------------------------------------



																						this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL[ 0 ];

																						this.setState(
																										{
																											CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL[ 0 ]
																										}
																						);//


																						//window.alert( MITAD_HORAS_TEORICAS );

																					} //

																					, 1000  //2000
																		//
																		);// setTimeout(


																		//-----



																		cont_SUM_Hours   =  elem_sel_H;
																		cont_SUM_Minutes =  elem_sel_M;



																		//-----------


																		//if (   elem_id_num  ==  2   )
																		//{
																		//	if 	(
																		//			(  timestamp_elem  == 0  )  ||
																		//			(  timestamp_elem  >  const_timestamp_medianoche  )
																		//		)
																		//	{
																		//		timestamp_elem = 0;
																		//
																		//		//cont_SUM_Hours   =  0;
																		//		//cont_SUM_Minutes =  0;
																		//
																		//		document.getElementById(  event.target.id  ).value =  "--:--";
																		//
																		//		window.alert( "El valor en ésta posicion debe de ser mayor a 00:00 y menor/igual a 07:00" );
																		//	}//

																		//}//---


																		//-----------

																		/*
																		if (   elem_id_num  ==  1   )
																		{

																			let str_id_WT_2 =  "id_input_TIME_WT_0"  +  '2';

																			let val_WT_2 =  document.getElementById(  str_id_WT_2  ).value;



																			let aux_hours =  val_WT_2.split( ':' )[ 0 ];
																			let aux_mins  =  val_WT_2.split( ':' )[ 1 ];


																			if (  cont_SUM_Hours != "--"  )
																			{
																				cont_SUM_Hours   +=  parseInt(  aux_hours   );
																			}

																			if (  cont_SUM_Minutes != "--"  )
																			{
																				cont_SUM_Minutes +=  parseInt(  aux_mins    );
																			}//




																			let timestamp_WT_2 =  ( cont_SUM_Hours * 60  )  +  cont_SUM_Minutes;


																			let dif_tss =  const_timestamp_medianoche  -  timestamp_WT_2;



																			//if 	(
																			//		(  timestamp_elem  == 0  )  ||
																			//		(  timestamp_elem  >  dif_tss  )
																			//	)
																			//{
																			//	timestamp_elem = 0;
																			//
																			//	//cont_SUM_Hours   =  0;
																			//	//cont_SUM_Minutes =  0;
																			//
																			//	document.getElementById(  event.target.id  ).value =  "--:--";
																			//
																			//	window.alert( "El valor en ésta posicion debe de ser <= a la diferencia entre 7 y el timestamp del WT_2 (además de mayor a 0)" );
																			//}//



																		}// if (   elem_id_num  ==  1   )
																		*/


																		//-----------


																		for (  let k = 1;  k <= 2;  k++  )
																		{

																			//if (  k  !=  i  )
																			//{

																				let AUX_id_input_TIME =  "id_input_TIME_WT_0"  +  k;

																				if (

																					( event.target.id  !=  AUX_id_input_TIME   )  &&

																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  null   )  &&
																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  undefined   ) &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )
																				)
																				{

																					//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																					let aux_hours =  ( document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 0 ];
																					let aux_mins  =  (  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 1 ];


																					if (  cont_SUM_Hours  !=  "--"  )
																					{
																						cont_SUM_Hours   +=  parseInt(   aux_hours   );
																					} //


																					if (  cont_SUM_Minutes  !=  "--"  )
																					{
																						cont_SUM_Minutes +=  parseInt(   aux_mins    );
																					} //

																				} //  if ( ... )


																			//}//

																		}// for (  let k = 1;  k <= 2;  k++  )


																		//-------


																		for (  let k = 3;  k <= 6;  k++  )
																		{

																			//if (  k  !=  i  )
																			//{

																				let AUX_id_input_TIME =  "id_input_TIME_WT_0"  +  k;

																				if (

																					( event.target.id  !=  AUX_id_input_TIME   )  &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  != null   )  &&
																					( document.getElementById(  AUX_id_input_TIME  ).value  != undefined   ) &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )
																				)
																				{

																					//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																					let aux_hours =  ( document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 0 ];
																					let aux_mins  =  ( document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 1 ];


																					if (  cont_SUM_Hours != "--"  )
																					{
																						cont_SUM_Hours   +=  parseInt(  aux_hours  );
																					}//


																					if (  cont_SUM_Minutes != "--"  )
																					{
																						cont_SUM_Minutes +=  parseInt(  aux_mins   );
																					}//

																				} // if ( ... )



																			//}//

																		}// for (  let k = 3;  k <= 6;  k++  )




																		//-------



																		if (  cont_SUM_Minutes  >=  60   )
																		{

																			cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );

																			cont_SUM_Minutes =  cont_SUM_Minutes % 60;

																		}// if (  cont_SUM_Minutes  >=  60   )


																		//-------



																		//window.alert( cont_SUM_Hours );
																		//window.alert( cont_SUM_Minutes );


																		let str_final_hour =	(
																									(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																									':'		+
																									(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																								);




																		// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:

																		//window.alert(  str_final_hour  );

																		// if (  document.getElementById( "id_input_NIGHTTIME" ).value  )
																		document.getElementById( "id_input_NIGHTTIME" ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;



																		//-------


																		let aux_id_PORC = "id_input_PORC_WT_0"  +  ( event.target.id ) .split( "_0" )[ 1 ];
																		document.getElementById(  aux_id_PORC  ).disabled =  false;


																		if	(
																				(  document.getElementById(  aux_id_PORC  ).value  ==  null )  ||
																				(  document.getElementById(  aux_id_PORC  ).value  ==  undefined )  ||
																				(  document.getElementById(  aux_id_PORC  ).value  ==  ""  )
																			)
																		{
																			document.getElementById(  aux_id_PORC  ).value =  0;

																		} // if


																		//----

																		let aux_label_PORC = "id_label_PORC_WT_0" +  ( event.target.id ) .split( "_0" )[ 1 ];

																		document.getElementById(  aux_label_PORC  ).style.color =  "black";


																	}// if not NaN
																	else
																	{
																		/*

																		window.alert( "Entra en el Else" );



																		cont_SUM_Hours =    0;
																		cont_SUM_Minutes =  0;

																		//------

																		for (  let k = 1;  k <= 6;  k++  )
																		{

																			//if (  k  !=  i  )
																			//{

																				let AUX_id_input_TIME =  "id_input_TIME_WT_0"  +  k;

																				if (

																					( event.target.id  !=  AUX_id_input_TIME   )  &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  != null   )  &&
																					( document.getElementById(  AUX_id_input_TIME  ).value  != undefined   ) &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )
																				)
																				{

																					//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																					let aux_hours =  ( document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 0 ];
																					let aux_mins =  (  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 1 ];

																					if (  cont_SUM_Hours != "--"  )
																					{
																						cont_SUM_Hours   +=  parseInt(  aux_hours   );
																					}//


																					if (  cont_SUM_Minutes != "--"  )
																					{
																						cont_SUM_Minutes +=  parseInt(  aux_mins    );
																					}//


																				} // if


																			//}//

																		} // for : k




																		//-------

																		if (  cont_SUM_Minutes  >=  60   )
																		{
																			cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );;


																			cont_SUM_Minutes =  cont_SUM_Minutes % 60;
																		}//

																		//-------



																		//window.alert( cont_SUM_Hours );
																		//window.alert( cont_SUM_Minutes );


																		let str_final_hour =	(
																									(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																									':'		+
																									(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																								);




																		// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:

																		//window.alert(  str_final_hour  );

																		document.getElementById( 'id_input_NIGHTTIME' ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;

																		//------



																		let aux_id_PORC = "id_input_PORC_WT_0"  +  ( event.target.id) .split( "_0" )[ 1 ];

																		document.getElementById(  aux_id_PORC  ).disabled =  true;
																		document.getElementById(  aux_id_PORC  ).value =  "";



																		let aux_label_PORC = "id_label_PORC_WT_0" +  ( event.target.id ).split( "_0" )[ 1 ];

																		document.getElementById(  aux_label_PORC  ).style.color =  "grey";

																		*/

																	}// else


																	//window.alert(  "onChange END"  );


																}// ( event ) =>

															}// onChange=	{


												//---------------------------------------------------------------------------------------------------------------------------------

												onClick =
															{
																( event ) =>
																{

																	//window.alert( "onCLICK" );

																	//window.alert( "CANCEL" );

																	//----------

																	//window.alert( "value: " );
																	//window.alert( JSON.stringify( e.target.value ) );

																	//document.getElementById( "id_input_NIGHTTIME" ).value =  event.target.value;



																	let cont_SUM_Hours =    parseInt(   ( event.target.value ).split( ':' )[ 0 ]   );
																	let cont_SUM_Minutes =  parseInt(   ( event.target.value ).split( ':' )[ 1 ]   );





																	if (
																			( cont_SUM_Hours  ==  undefined  )  ||
																			( cont_SUM_Minutes  ==  undefined  )  ||

																			( cont_SUM_Hours  ==  NaN  )  ||
																			( cont_SUM_Minutes  ==  NaN  )  ||

																			(  ! Number.isInteger( cont_SUM_Hours )  )  ||
																			(  ! Number.isInteger( cont_SUM_Minutes )  )
																		)
																	{
																		//window.alert( ( event.target.value ).split( ':' )[ 0 ] );
																		//window.alert( cont_sum_Hours );


																		//window.alert( event.target.value );


																		cont_SUM_Hours   =  0;
																		cont_SUM_Minutes =  0;

																		for (  let k = 1;  k <= 6;  k++  )
																		{

																			//if (  k  !=  i  )
																			//{
																				let AUX_id_input_TIME =  "id_input_TIME_WT_0"  +  k;


																				//window.alert(  k  + "= " + document.getElementById(  AUX_id_input_TIME  ).value  + " :  " + String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  );


																				if (

																					( event.target.id  !=  AUX_id_input_TIME   )  &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  != null   )  &&
																					( document.getElementById(  AUX_id_input_TIME  ).value  != undefined   ) &&


																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																					( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )  //&&


																					//(   String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  ==  -1   )
																				)
																				{

																					//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																					let aux_hours =  ( document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 0 ];
																					let aux_mins =  (  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value ).split( ':' )[ 1 ];

																					if (  cont_SUM_Hours != "--"  )
																					{
																						cont_SUM_Hours   +=  parseInt(  aux_hours   );
																					}

																					if (  cont_SUM_Minutes != "--"  )
																					{
																						cont_SUM_Minutes +=  parseInt(  aux_mins    );
																					}//
																				}
																				else
																				{


																					document.getElementById(  AUX_id_input_TIME  ).value =  "--:--";

																				}//else

																			//}//

																		}// for : k


																		//-------

																		if (  cont_SUM_Minutes  >=  60   )
																		{
																			cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );;


																			cont_SUM_Minutes =  cont_SUM_Minutes % 60;
																		}//

																		//-------



																		//window.alert( cont_SUM_Hours );
																		//window.alert( cont_SUM_Minutes );


																		let str_final_hour =	(
																									(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																									':'		+
																									(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																								);




																		// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:


																		//window.alert(  str_final_hour  );

																		// if (  document.getElementById( "id_input_NIGHTTIME" )  !=  null  )
																		document.getElementById( "id_input_NIGHTTIME" ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;

																		//-------------

																		let aux_id_PORC = "id_input_PORC_WT_0"  +  ( event.target.id ) .split( "_0" )[ 1 ];

																		document.getElementById(  aux_id_PORC  ).disabled =  true;
																		document.getElementById(  aux_id_PORC  ).value =  "";



																		let aux_label_PORC = "id_label_PORC_WT_0" +  ( event.target.id) .split( "_0" )[ 1 ];

																		document.getElementById(  aux_label_PORC  ).style.color =  "grey";
																	}//
																	else
																	{

																		for (  let k = 1;  k <= 6;  k++  )
																		{

																			let AUX_id_input_TIME =  "id_input_TIME_WT_0"  +  k;


																			//window.alert(  k  + "= " + document.getElementById(  AUX_id_input_TIME  ).value  + " :  " + String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  );


																			if (

																				(  event.target.id  !=  AUX_id_input_TIME   )  &&


																				(  document.getElementById(  AUX_id_input_TIME  ).value  == null   )  ||
																				(  document.getElementById(  AUX_id_input_TIME  ).value  == undefined   )  ||


																				(  document.getElementById(  AUX_id_input_TIME  ).value  ==  ""   )  ||

																				(  document.getElementById(  AUX_id_input_TIME  ).value  ==  "--:--"   )  //&&


																				//(   String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  ==  -1   )
																			)
																			{

																				document.getElementById(  AUX_id_input_TIME  ).value =  "--:--";

																			}//else

																		}// for : k
																	}//


																	//window.alert( "value: " );

																}// ( event ) =>
															}


												//disabled={ (  i >= 2  )   ?  true  :  false  }
										/>
									</td>
								</td>


								{ /* ------------------------------------------------------------------------------------------------------------------------ */ }


								<td>

									<td>
										<input
												id={id_input_PORC}
												type="number"
												name={name_input_PORC}
												style= {{marginLeft:"1rem", width: "60px"}}

												//value="0"

												min="0"
												max="100"

												//disabled


												onChange =	{
																( event ) =>
																{
																	if	(
																			(  event.target.value  <  0  )  ||
																			(  event.target.value  >  100  )
																		)
																	{
																		event.target.value = 0;
																	}
																	else
																	{

																		event.target.value =  Math.round(  event.target.value  );



																		//----


																		let elem_id_num =  ( event.target.id ).split( "_0" )[ 1 ];



																		//m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].porcent =  event.target.value;


																		//---

																		for ( let imon = 0;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0	!!!!!!! 	// <= 12
																		{

																			m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ elem_id_num - 1 ].porcent =  event.target.value;


																		} // for ( let imon = 0;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0

																		//---



																		m5_dat_vals_tramos_GRAFICA_TEOR[ elem_id_num - 1 ].porcent =  event.target.value;


																		m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ elem_id_num - 1 ].porcent =  event.target.value;

																		m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ elem_id_num - 1 ].porcent =  event.target.value;

																		//----

																		ar_DAT_elora_M5__REAL = [];

																		ar_DAT_elora_M5__TEOR = [];


																		ar_DAT_elora_M5__NOCHECORTA = [];

																		ar_DAT_elora_M5__NOCHELARGA = [];


																		glob_INIT__ARs_ChartData_M5();


																		setTimeout(
																					( ) =>
																					{


																						this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																						this.setState(
																										{
																											CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																										}
																						);//

																						//-----------------------------------------

																						this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

																						//----------------------------------------



																						this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL[ 0 ];

																						this.setState(
																										{
																											CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL[ 0 ]
																										}
																						);//


																						//window.alert( MITAD_HORAS_TEORICAS );

																					}//

																					, 1000  //2000
																		);



																	}

																}// ( event ) =>
															}


												//disabled={ (  i >= 2  )   ?  true  :  false  }
										/>
									</td>

									<td>

										<label
												id={id_label_PORC}
												for={id_input_PORC}
												style= { {color:"black", marginLeft:"1rem"} }
										>
											%
										</label>

									</td>


									<td>

										<label style= { {marginLeft:"2rem"} }>

											{
												(  i == 1  )
												?
													""
												:
													(  i == 2  )
													?
														<b> * </b>
													:
														(  i == 3  )
														?
															<b> *** </b>
														:
															(  i == 4  )
															?
																<b> * </b>
															:
																(  i == 6  )
																?
																	<b> ** </b>
																:
																	""
											}

										</label>

									</td>

								</td>


								{
								/*
								(  (  i == 2  )  ||  (  i == 4  )  )   &&

								<td>

									<td>
										<td>
											<label for={"id_input_P" + ( i / 2 ) +  "_NIGHTTIME"}  style= {{marginTop:"1rem",marginRight:"1rem"}}>    Partial { i / 2 } Night Time (h) </label>
										</td>

										<td>
											<input id={"id_input_P" + ( i / 2 ) + "_NIGHTTIME"} type="text" name={"in_P" + ( i / 2 ) + "_NIGHTTIME"} style= {{marginTop:"0.5rem", width: "51px"}}  disabled />		// value="00:00"
										</td>

										<td>
											{
											(  i == 2  )		// "  / 7"			// "  / 7"
											?

											"  / " + Mitad_tiempo_noche__ts + " min = "  +
																						(   (Math.floor( Mitad_tiempo_noche__ts / 60.0 )<10) ? ('0' + Math.floor( Mitad_tiempo_noche__ts / 60.0 )) : Math.floor( Mitad_tiempo_noche__ts / 60.0 )   ) +

																						" h: " +

																						(   ( ( Mitad_tiempo_noche__ts % 60 )<10 ) ?  ( '0' + ( Mitad_tiempo_noche__ts % 60 ) ) :  ( Mitad_tiempo_noche__ts % 60 ) )

																						+ " m"
											:

											"  / " + Mitad_tiempo_noche__ts + " min = "  +
																						(   (Math.floor( Mitad_tiempo_noche__ts / 60.0 )<10) ? ('0' + Math.floor( Mitad_tiempo_noche__ts / 60.0 )) : Math.floor( Mitad_tiempo_noche__ts / 60.0 )   ) +

																						" h: " +

																						(   ( ( Mitad_tiempo_noche__ts % 60 )<10 ) ?  ( '0' + ( Mitad_tiempo_noche__ts % 60 ) ) :  ( Mitad_tiempo_noche__ts % 60 ) )

																						+ " m"
											}
										</td>
									</td>

								</td>
								*/
								}

							</tr>
							)

			);//.push()

			//-----------

		}// for : i

		//--------------------------------------------------------------

		/*
		for_tr.push(
						(
						<tr>

							<td>
								<td>
									<label>
										---------------
									</label>
								</td>

								<td>
									<label>
										------------------
									</label>
								</td>
							</td>


							<td>
								<td>
									<label
									>
										-------------------
									</label>
								</td>
							</td>


							<td>
								<td>
									<label
									>
										-------------------
									</label>
								</td>
							</td>

						</tr>
						)
					);
		*/

		//--------------------------------------------------------------


		return (  for_tr  );	// return()



	}// /*function*/ insert_HTML_INPUTS_M_5 ( ) ---------------------------




	//----------------------------------------------------------------------------------------------------------------------------------------------------------------




	/*function*/
	insert_HTML_extra_12_GRAPHS__OPTIONAL ( )
	{

		var for_Graph = [];

		//----

						// 1

		//for ( let imon = 1;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 1	!!!!!!! 	// <= 12
		for ( let imon = 0;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 1	!!!!!!! 	// <= 12      ------------------------------------------------------------------------------------
		{
			/*
			console.log(  "MES: " + String( imon )  );
			console.log(  STR_time_SUNSET_REAL[ imon ]  );
			console.log(  STR_time_SUNRISE_REAL[ imon ]  );
			*/

			//---

			for_Graph.push(
			//
				(
					<div id = "dropdowncontainer" className = "container-fluid" style = {{marginTop:"-20px", marginBottom:"0px"/*, marginLeft:"-10px"*/}}>

						<h6 style={{marginLeft:"2rem"}} > { "DIMMING  #" +  String( imon ) } </h6>

						{
						//(  this_.state.Chart_Data_M1.length > 10  )  &&

						<ResponsiveContainer
											width="100%"
											height={/*300*/ 140}

											id={"id_RP_mychart_cpu_elorav1_A" +  "_EXTRA_" + String( imon ) }

											//debounce={0}
						>

							<ComposedChart
											data =	{
														//ar_DAT_elora_M5__REAL

														ar_DAT_elora_M5__REAL[ imon ]

														//this_.state.CPU_elorav1_Chart_Data_M5
													}

											/*
											ref =	{
														( e ) =>
														{
															this.chart = e;
														}
													}*/


											isAnimationActive = { false }

											//dot="false"
							>


								{
								/*
								<ReferenceLine
												x={ str_hora_Linea_IZQ_1_2 }
												stroke="green"
												label={ "IZQ.1.2" }
								/>
								<ReferenceLine
												x={ str_hora_Linea_DCHA_1_3 }
												stroke="green"
												label={ "DCHA.1.3" }

								/>
								*/
								}



								<Line
									type="monotone"

									dataKey="val"

									stroke="orange"

									strokeWidth={2}

									dot={false}

									isAnimationActive = { false }

									/*
									onMouseDown=	{
														( event ) =>
														{
															window.alert( "YEE" );
														}
													}
									*/
									//strokeWidth={1}

									//isAnimationActive={true}
								/>




								<CartesianGrid stroke="#ccc" />


								<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#ADADFF' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>



								<Area type='step' dataKey='val_sunrise_sunset__TEOR' fill='#C0C0C0' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>



								{/*
								<XAxis
									dataKey="time_hour"

									//tickCount={7}

									interval={  29  }
								/>
								*/
								}


								<XAxis

									dataKey="time_hour"

									tick = { <CustomizedAxisTick/> }

									height = { 50 }

									interval={  29  }
								/>



								<YAxis
									domain = { [ 0, 100 ] }
									//interval = { 1 }

									tickCount={ 3 }
								/>


								<ReferenceLine
												x={ str_HOURMIN_tsABS__Mitad_Noche[ imon ] }
												stroke="blue"

												label={ str_HOURMIN_tsABS__Mitad_Noche[ imon ] }

												strokeWidth={1}
								/>





								{
								<ReferenceLine
												x={ STR_time_SUNSET_REAL[ imon ] }

												stroke="red"
												label={ STR_time_SUNSET_REAL[ imon ] }

												strokeWidth={0}
								/>
								}


								{ /*window.alert( time_SUNRISE )*/ }
								<ReferenceLine
												//x={ '0'  +  STR_time_SUNRISE_REAL[ imon ] }
												x={  STR_time_SUNRISE_REAL[ imon ] }

												stroke="red"

												//label={ '0'  +  STR_time_SUNRISE_REAL[ imon ] }
												label={  STR_time_SUNRISE_REAL[ imon ] }

												strokeWidth={0}

								/>



								{
									/*
								(  refAreaLeft  &&  refAreaRight  )		?
																		(	<ReferenceArea
																							yAxisId = "1"
																							x1 = { refAreaLeft }
																							x2 = { refAreaRight }
																							strokeOpacity = { 0.3 }
																			/>
																		)
																		:
																		null
								*/
								}

							</ComposedChart>

						</ResponsiveContainer>
						}
					</div>

				)

			//
			); // .push(


		}// for ( let imon = 1;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )


		for_Graph.push(

						<div>
							<br/>
							<br/>

							<hr/>
							<hr/>

							<br/>
							<br/>

						</div>
		); //


		//--


		return (  for_Graph  );


	}// insert_HTML_extra_12_GRAPHS ( )


	//----------------------------------------------------------------------------------------------------




	/*function*/
	insert_HTML_INPUTS_M_3 ( )
	{

		var for_tr = [];

		//---------


		const FILAS_TAB = 6;
		const COLS_TAB = 1;			// !!


		const FILASxCOLS =  FILAS_TAB  *  COLS_TAB;


		//-----


		for (  let  i = 1;  i <= FILASxCOLS;  i++  )
		{


			//let aux_j =  Math.floor(  ( i - 1 )  /  2  )  +  1;

			//let aux_i =  (  ( i - 1 ) % 2 )  +  1;



			//const STR_OFFSji =  String( aux_j )  +  "_"  +  String( aux_i );

			//-------


			//console.log( "i = "  + i  +  " ....  aux_j = "  +  aux_j  +  ";  aux_i = "  +  aux_i );





			let id_input_TIME_INI_i =  "id_input_M3_TIME_WT__INI_"  +  String( i );

			let name_input_TIME_INI_i =  "nam_input_M3_TIME_WT__INI_"  +  String( i );

			//---


			let id_input_TIME_FIN_i =  "id_input_M3_TIME_WT__FIN_"  +  String( i );

			let name_input_TIME_FIN_i =  "nam_input_M3_TIME_WT__FIN_"  +  String( i );



			//----------


			let id_input_PORC =  "id_input_M3_PORC_WT__"  +  String( i );


			let id_label_PORC =  "id_label_M3_PORC_WT_"  +  String( i );

			let name_input_PORC =  "nam_input_M3_PORC_WT_"  +  String( i );


			//---


			//---------------------------------


			//for_tr.push(	(  i  ==  3  )  ?   (<tr>  <td> <hr/> </td>  <td> <hr/> </td> </tr>)  :  ""	); 				// ( 0  == 0 )   ?  (<tr>)  :  (<tr>) }	); </tr>


			//---------------------------------


			for_tr.push(
							(


							<tr>

								{ /* ------------------------------------------------------------------------------------------------------------------------ */ }


								<td>

									<label

										// for = { id_input_TIME_INI_i }

										style= {{width: "90px", marginRight:"1rem"}}
									>

										Time <b>( {i} )</b>

										{
										// { aux_j + ", " + aux_i }
										}
									</label>

								</td>



								{ /* ------------------------------------------------------------------------------------------------------------------------ */ }


								{ /* (1º) 1era HORA (INICIAL) ... aaab*/ }

								<td>

									<td>

										<input

											id={ id_input_TIME_INI_i }

											type="time"

											name={name_input_TIME_INI_i}

											style= {{width: "80px"}}

											min="00:00"

											max="23:59"


											/*
											value="05:00"
											*/

											/*
											onfocusout =
														{
															( event ) =>
															{
																window.alert( "OUT OF FOCUS" );
															}//
														}
											*/

											//---------------------------------------------------------------------------------------------------------------------------------

											/*
											onChange=	{
															( event ) =>
															{

																//window.alert( "value: " );
																//window.alert( JSON.stringify( e.target.value ) );

																//document.getElementById( "id_input_NIGHTTIME" ).value =  event.target.value;



																let cont_SUM_Hours   =  0;
																let cont_SUM_Minutes =  0;

																let elem_sel_H =  parseInt(   ( event.target.value ).split( ':' )[ 0 ]   );
																let elem_sel_M =  parseInt(   ( event.target.value ).split( ':' )[ 1 ]   );

																let timestamp_elem =  0;


																//------------------------------

																if (
																		( elem_sel_H  !=  undefined  )  &&
																		( elem_sel_M  !=  undefined  )  &&

																		( elem_sel_H  !=  NaN  )  &&
																		( elem_sel_M  !=  NaN  )  &&

																		(  Number.isInteger( elem_sel_H )  )  &&
																		(  Number.isInteger( elem_sel_M )  )
																	)
																{

																	//window.alert( ( event.target.value ).split( ':' )[ 0 ] );
																	//window.alert( cont_SUM_Hours )


																	let elem_id_num =  ( event.target.id ).split( "WT_" )[ 1 ];		// split( "_0" )[ 1 ];


																	timestamp_elem =  (  elem_sel_H * 60  )  +  elem_sel_M;



																	//window.alert(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins  );


																	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																	m5_dat_vals_tramos_GRAFICA_TEOR[ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ elem_id_num - 1 ].ts_mins =  timestamp_elem;

																	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																	//window.alert(  "[" + ( elem_id_num - 1 ) + "] =  " +   timestamp_elem );
																	//window.alert(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins  );



																	//----

																	ar_DAT_elora_M5__REAL = [];

																	ar_DAT_elora_M5__TEOR = [];

																	ar_DAT_elora_M5__NOCHECORTA = [];

																	ar_DAT_elora_M5__NOCHELARGA = [];

																	//------------------------------

																	this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																	this.setState(
																	//
																					{
																						CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																					}
																	//
																	);//


																	//------------------------------



																	glob_INIT__ARs_ChartData_M5();

																	//----


																	setTimeout(
																	//
																				( ) =>
																				{


																					// this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																					// this.setState(
																					// //
																					// 				{
																					// 					CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																					// 				}
																					// //
																					// );//


																					//-----------------------------------------

																					this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

																					//----------------------------------------



																					this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL;

																					this.setState(
																									{
																										CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL
																									}
																					);//


																					//window.alert( MITAD_HORAS_TEORICAS );

																				}//

																				, 1000  //2000
																	//
																	);// setTimeout(


																	//-----



																	cont_SUM_Hours   =  elem_sel_H;
																	cont_SUM_Minutes =  elem_sel_M;



																	//-----------


																		for (  let k = 1;  k <= FILASxCOLS;  k++  )		//  <=  2
																		{

																			// FILASxCOLS

																			let ax2_j =  Math.floor(  ( k - 1 )  /  2  )  +  1;

																			let ax2_i =  (  ( k - 1 )  %  2 )  +  1;

																			const STR_ax2_OFFSji =  String( ax2_j )  +  "_"  +  String( ax2_i );




																		//if (  k  !=  i  )
																		//{

																			let AUX_id_input_TIME =  "id_input_M3_TIME_WT_"  +  STR_ax2_OFFSji;


																			if (

																				( event.target.id  !=  AUX_id_input_TIME   )  &&

																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  null   )  &&
																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  undefined   ) &&


																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )
																			)
																			{

																				//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																				//let aux_hours =  (  document.getElementById( "id_input_M3_TIME_WT_"  +  k  ).value ).split( ':' )[ 0 ];
																				//let aux_mins  =  (  document.getElementById( "id_input_M3_TIME_WT_"  +  k  ).value ).split( ':' )[ 1 ];

																				let aux_hours =  (  document.getElementById( AUX_id_input_TIME ).value ).split( ':' )[ 0 ];
																				let aux_mins  =  (  document.getElementById( AUX_id_input_TIME ).value ).split( ':' )[ 1 ];


																				if (  cont_SUM_Hours  !=  "--"  )
																				{
																					cont_SUM_Hours   +=  parseInt(   aux_hours   );

																				} //


																				if (  cont_SUM_Minutes  !=  "--"  )
																				{
																					cont_SUM_Minutes +=  parseInt(   aux_mins    );

																				} //

																			} //  if ( ... )


																		//}//

																	}// for (  let k = 1;  k <= 2;  k++  )




																	//-------



																	if (  cont_SUM_Minutes  >=  60   )
																	{

																		cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );

																		cont_SUM_Minutes =  cont_SUM_Minutes % 60;

																	}// if (  cont_SUM_Minutes  >=  60   )


																	//-------



																	//window.alert( cont_SUM_Hours );
																	//window.alert( cont_SUM_Minutes );


																	let str_final_hour =	(
																								(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																							':'		+
																								(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																							);




																	// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:

																	//window.alert(  str_final_hour  );

																	if (  document.getElementById( "id_input_NIGHTTIME" )  !=  null  )
																	{
																		document.getElementById( "id_input_NIGHTTIME" ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;

																	}// if




																	//-------


																	let aux_id_PORC = "id_input_M3_PORC_WT_"  +  ( event.target.id ) .split( "WT_" )[ 1 ];	// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_id_PORC  ).disabled =  false;


																	if	(
																			(  document.getElementById(  aux_id_PORC  ).value  ==  null )  ||
																			(  document.getElementById(  aux_id_PORC  ).value  ==  undefined )  ||
																			(  document.getElementById(  aux_id_PORC  ).value  ==  ""  )
																		)
																	{
																		document.getElementById(  aux_id_PORC  ).value =  0;

																	} // if


																	//----


																	let aux_label_PORC = "id_label_M3_PORC_WT_" +  ( event.target.id ).split( "WT_" )[ 1 ];		// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_label_PORC  ).style.color =  "black";


																}// if not NaN
																else
																{
																}// else


																//window.alert(  "onChange END"  );


															}// ( event ) =>

														}// onChange=	{
											*/


											//---------------------------------------------------------------------------------------------------------------------------------

											/*
											onClick =
														{
															( event ) =>
															{

																//window.alert( "onCLICK" );

																//window.alert( "CANCEL" );

																//----------

																//window.alert( "value: " );
																//window.alert( JSON.stringify( e.target.value ) );

																//document.getElementById( "id_input_NIGHTTIME" ).value =  event.target.value;



																let cont_SUM_Hours =    parseInt(   ( event.target.value ).split( ':' )[ 0 ]   );
																let cont_SUM_Minutes =  parseInt(   ( event.target.value ).split( ':' )[ 1 ]   );





																if (
																		( cont_SUM_Hours  ==  undefined  )  ||
																		( cont_SUM_Minutes  ==  undefined  )  ||

																		( cont_SUM_Hours  ==  NaN  )  ||
																		( cont_SUM_Minutes  ==  NaN  )  ||

																		(  ! Number.isInteger( cont_SUM_Hours )  )  ||
																		(  ! Number.isInteger( cont_SUM_Minutes )  )
																	)
																{
																	//window.alert( ( event.target.value ).split( ':' )[ 0 ] );
																	//window.alert( cont_sum_Hours );


																	//window.alert( event.target.value );


																	cont_SUM_Hours   =  0;
																	cont_SUM_Minutes =  0;

																	for (  let k = 1;  k <= FILASxCOLS;  k++  )		// 6
																	{

																		// FILASxCOLS

																		let ax2_j =  Math.floor(  ( k - 1 )  /  2  )  +  1;

																		let ax2_i =  (  ( k - 1 )  %  2 )  +  1;

																		const STR_ax2_OFFSji =  String( ax2_j )  +  "_"  +  String( ax2_i );



																		//if (  k  !=  i  )
																		//{
																			let AUX_id_input_TIME =  "id_input_M3_TIME_WT_"  +  STR_ax2_OFFSji;


																			//window.alert(  k  + "= " + document.getElementById(  AUX_id_input_TIME  ).value  + " :  " + String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  );


																			if (

																				( event.target.id  !=  AUX_id_input_TIME   )  &&


																				( document.getElementById(  AUX_id_input_TIME  ).value  != null   )  &&
																				( document.getElementById(  AUX_id_input_TIME  ).value  != undefined   ) &&


																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )  //&&


																				//(   String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  ==  -1   )
																			)
																			{

																				//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																				let aux_hours =  ( document.getElementById( AUX_id_input_TIME  ).value ).split( ':' )[ 0 ];
																				let aux_mins =  (  document.getElementById( AUX_id_input_TIME  +  k  ).value ).split( ':' )[ 1 ];

																				if (  cont_SUM_Hours != "--"  )
																				{
																					cont_SUM_Hours   +=  parseInt(  aux_hours   );
																				}

																				if (  cont_SUM_Minutes != "--"  )
																				{
																					cont_SUM_Minutes +=  parseInt(  aux_mins    );
																				}//
																			}
																			else
																			{


																				document.getElementById(  AUX_id_input_TIME  ).value =  "--:--";

																			}//else

																		//}//

																	}// for : k


																	//-------

																	if (  cont_SUM_Minutes  >=  60   )
																	{
																		cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );;


																		cont_SUM_Minutes =  cont_SUM_Minutes % 60;
																	}//

																	//-------



																	//window.alert( cont_SUM_Hours );
																	//window.alert( cont_SUM_Minutes );


																	let str_final_hour =	(
																								(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																								':'		+
																								(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																							);




																	// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:


																	//window.alert(  str_final_hour  );

																	if (  document.getElementById( "id_input_NIGHTTIME" ) !=  null  )
																	{
																		document.getElementById( "id_input_NIGHTTIME" ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;
																	}// if



																	//-------------

																	let aux_id_PORC = "id_input_M3_PORC_WT_"  +  ( event.target.id ) .split( "WT_" )[ 1 ];		// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_id_PORC  ).disabled =  true;
																	document.getElementById(  aux_id_PORC  ).value =  "";



																	let aux_label_PORC = "id_label_M3_PORC_WT_" +  ( event.target.id) .split( "WT_" )[ 1 ];		// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_label_PORC  ).style.color =  "grey";

																}//
																else
																{

																	for (  let k = 1;  k <= 6;  k++  )			// 6
																	{

																		// FILASxCOLS

																		let ax2_j =  Math.floor(  ( k - 1 )  /  2  )  +  1;

																		let ax2_i =  (  ( k - 1 )  %  2 )  +  1;


																		const STR_ax2_OFFSji =  String( ax2_j )  +  "_"  +  String( ax2_i );



																		let AUX_id_input_TIME =  "id_input_M3_TIME_WT_"  +  STR_ax2_OFFSji;


																		//window.alert(  k  + "= " + document.getElementById(  AUX_id_input_TIME  ).value  + " :  " + String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  );


																		if (

																			(  event.target.id  !=  AUX_id_input_TIME   )  &&


																			(  document.getElementById(  AUX_id_input_TIME  ).value  == null   )  ||
																			(  document.getElementById(  AUX_id_input_TIME  ).value  == undefined   )  ||


																			(  document.getElementById(  AUX_id_input_TIME  ).value  ==  ""   )  ||

																			(  document.getElementById(  AUX_id_input_TIME  ).value  ==  "--:--"   )  //&&


																			//(   String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  ==  -1   )
																		)
																		{

																			document.getElementById(  AUX_id_input_TIME  ).value =  "--:--";

																		}//else

																	}// for : k
																}//


																//window.alert( "value: " );

															}// ( event ) =>

														} // onClick =

														*/

														//disabled={ (  i >= 2  )   ?  true  :  false  }
										/>

									</td>

								</td>


								{ /* ------------------------------------------------------------------------------------------------------------------------ */ }


								{ /* (2º) 2da HORA (FINAL) */ }



								<td>

									<td>

										<input

											id={ id_input_TIME_FIN_i }

											type="time"

											name={name_input_TIME_FIN_i}

											style= {{width: "80px"}}

											min="00:00"

											max="23:59"

											//value="05:00"

											/*
											onfocusout =
														{
															( event ) =>
															{
																window.alert( "OUT OF FOCUS" );
															}//
														}
											*/

											//---------------------------------------------------------------------------------------------------------------------------------

											/*
											onChange=	{
															( event ) =>
															{

																//window.alert( "value: " );
																//window.alert( JSON.stringify( e.target.value ) );

																//document.getElementById( "id_input_NIGHTTIME" ).value =  event.target.value;



																let cont_SUM_Hours   =  0;
																let cont_SUM_Minutes =  0;

																let elem_sel_H =  parseInt(   ( event.target.value ).split( ':' )[ 0 ]   );
																let elem_sel_M =  parseInt(   ( event.target.value ).split( ':' )[ 1 ]   );

																let timestamp_elem =  0;


																//------------------------------

																if (
																		( elem_sel_H  !=  undefined  )  &&
																		( elem_sel_M  !=  undefined  )  &&

																		( elem_sel_H  !=  NaN  )  &&
																		( elem_sel_M  !=  NaN  )  &&

																		(  Number.isInteger( elem_sel_H )  )  &&
																		(  Number.isInteger( elem_sel_M )  )
																	)
																{

																	//window.alert( ( event.target.value ).split( ':' )[ 0 ] );
																	//window.alert( cont_SUM_Hours )


																	let elem_id_num =  ( event.target.id ).split( "WT_" )[ 1 ];		// split( "_0" )[ 1 ];


																	timestamp_elem =  (  elem_sel_H * 60  )  +  elem_sel_M;



																	//window.alert(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins  );


																	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																	m5_dat_vals_tramos_GRAFICA_TEOR[ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ elem_id_num - 1 ].ts_mins =  timestamp_elem;

																	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ elem_id_num - 1 ].ts_mins =  timestamp_elem;


																	//window.alert(  "[" + ( elem_id_num - 1 ) + "] =  " +   timestamp_elem );
																	//window.alert(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].ts_mins  );



																	//----

																	ar_DAT_elora_M5__REAL = [];

																	ar_DAT_elora_M5__TEOR = [];

																	ar_DAT_elora_M5__NOCHECORTA = [];

																	ar_DAT_elora_M5__NOCHELARGA = [];

																	//------------------------------

																	this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																	this.setState(
																	//
																					{
																						CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																					}
																	//
																	);//


																	//------------------------------



																	glob_INIT__ARs_ChartData_M5();

																	//----


																	setTimeout(
																	//
																				( ) =>
																				{


																					// this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																					// this.setState(
																					// //
																					// 				{
																					// 					CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																					// 				}
																					// //
																					// );//


																					//-----------------------------------------

																					this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

																					//----------------------------------------



																					this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL;

																					this.setState(
																									{
																										CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL
																									}
																					);//


																					//window.alert( MITAD_HORAS_TEORICAS );

																				}//

																				, 1000  //2000
																	//
																	);// setTimeout(


																	//-----



																	cont_SUM_Hours   =  elem_sel_H;
																	cont_SUM_Minutes =  elem_sel_M;



																	//-----------


																		for (  let k = 1;  k <= FILASxCOLS;  k++  )		//  <=  2
																		{

																			// FILASxCOLS

																			let ax2_j =  Math.floor(  ( k - 1 )  /  2  )  +  1;

																			let ax2_i =  (  ( k - 1 )  %  2 )  +  1;

																			const STR_ax2_OFFSji =  String( ax2_j )  +  "_"  +  String( ax2_i );




																		//if (  k  !=  i  )
																		//{

																			let AUX_id_input_TIME =  "id_input_M3_TIME_WT_"  +  STR_ax2_OFFSji;


																			if (

																				( event.target.id  !=  AUX_id_input_TIME   )  &&

																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  null   )  &&
																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  undefined   ) &&


																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )
																			)
																			{

																				//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																				//let aux_hours =  (  document.getElementById( "id_input_M3_TIME_WT_"  +  k  ).value ).split( ':' )[ 0 ];
																				//let aux_mins  =  (  document.getElementById( "id_input_M3_TIME_WT_"  +  k  ).value ).split( ':' )[ 1 ];

																				let aux_hours =  (  document.getElementById( AUX_id_input_TIME ).value ).split( ':' )[ 0 ];
																				let aux_mins  =  (  document.getElementById( AUX_id_input_TIME ).value ).split( ':' )[ 1 ];


																				if (  cont_SUM_Hours  !=  "--"  )
																				{
																					cont_SUM_Hours   +=  parseInt(   aux_hours   );

																				} //


																				if (  cont_SUM_Minutes  !=  "--"  )
																				{
																					cont_SUM_Minutes +=  parseInt(   aux_mins    );

																				} //

																			} //  if ( ... )


																		//}//

																	}// for (  let k = 1;  k <= 2;  k++  )




																	//-------



																	if (  cont_SUM_Minutes  >=  60   )
																	{

																		cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );

																		cont_SUM_Minutes =  cont_SUM_Minutes % 60;

																	}// if (  cont_SUM_Minutes  >=  60   )


																	//-------



																	//window.alert( cont_SUM_Hours );
																	//window.alert( cont_SUM_Minutes );


																	let str_final_hour =	(
																								(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																							':'		+
																								(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																							);




																	// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:

																	//window.alert(  str_final_hour  );

																	if (  document.getElementById( "id_input_NIGHTTIME" )  !=  null  )
																	{
																		document.getElementById( "id_input_NIGHTTIME" ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;

																	}// if




																	//-------


																	let aux_id_PORC = "id_input_M3_PORC_WT_"  +  ( event.target.id ) .split( "WT_" )[ 1 ];	// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_id_PORC  ).disabled =  false;


																	if	(
																			(  document.getElementById(  aux_id_PORC  ).value  ==  null )  ||
																			(  document.getElementById(  aux_id_PORC  ).value  ==  undefined )  ||
																			(  document.getElementById(  aux_id_PORC  ).value  ==  ""  )
																		)
																	{
																		document.getElementById(  aux_id_PORC  ).value =  0;

																	} // if


																	//----


																	let aux_label_PORC = "id_label_M3_PORC_WT_" +  ( event.target.id ).split( "WT_" )[ 1 ];		// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_label_PORC  ).style.color =  "black";


																}// if not NaN
																else
																{
																}// else


																//window.alert(  "onChange END"  );


															}// ( event ) =>

														}// onChange=	{
											*/


											//---------------------------------------------------------------------------------------------------------------------------------

											/*
											onClick =
														{
															( event ) =>
															{

																//window.alert( "onCLICK" );

																//window.alert( "CANCEL" );

																//----------

																//window.alert( "value: " );
																//window.alert( JSON.stringify( e.target.value ) );

																//document.getElementById( "id_input_NIGHTTIME" ).value =  event.target.value;



																let cont_SUM_Hours =    parseInt(   ( event.target.value ).split( ':' )[ 0 ]   );
																let cont_SUM_Minutes =  parseInt(   ( event.target.value ).split( ':' )[ 1 ]   );





																if (
																		( cont_SUM_Hours  ==  undefined  )  ||
																		( cont_SUM_Minutes  ==  undefined  )  ||

																		( cont_SUM_Hours  ==  NaN  )  ||
																		( cont_SUM_Minutes  ==  NaN  )  ||

																		(  ! Number.isInteger( cont_SUM_Hours )  )  ||
																		(  ! Number.isInteger( cont_SUM_Minutes )  )
																	)
																{
																	//window.alert( ( event.target.value ).split( ':' )[ 0 ] );
																	//window.alert( cont_sum_Hours );


																	//window.alert( event.target.value );


																	cont_SUM_Hours   =  0;
																	cont_SUM_Minutes =  0;

																	for (  let k = 1;  k <= FILASxCOLS;  k++  )		// 6
																	{

																		// FILASxCOLS

																		let ax2_j =  Math.floor(  ( k - 1 )  /  2  )  +  1;

																		let ax2_i =  (  ( k - 1 )  %  2 )  +  1;

																		const STR_ax2_OFFSji =  String( ax2_j )  +  "_"  +  String( ax2_i );



																		//if (  k  !=  i  )
																		//{
																			let AUX_id_input_TIME =  "id_input_M3_TIME_WT_"  +  STR_ax2_OFFSji;


																			//window.alert(  k  + "= " + document.getElementById(  AUX_id_input_TIME  ).value  + " :  " + String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  );


																			if (

																				( event.target.id  !=  AUX_id_input_TIME   )  &&


																				( document.getElementById(  AUX_id_input_TIME  ).value  != null   )  &&
																				( document.getElementById(  AUX_id_input_TIME  ).value  != undefined   ) &&


																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  ""   )  &&

																				( document.getElementById(  AUX_id_input_TIME  ).value  !=  "--:--"   )  //&&


																				//(   String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  ==  -1   )
																			)
																			{

																				//window.alert( k + " = " +  document.getElementById( "id_input_TIME_WT_0"  +  k  ).value );


																				let aux_hours =  ( document.getElementById( AUX_id_input_TIME  ).value ).split( ':' )[ 0 ];
																				let aux_mins =  (  document.getElementById( AUX_id_input_TIME  +  k  ).value ).split( ':' )[ 1 ];

																				if (  cont_SUM_Hours != "--"  )
																				{
																					cont_SUM_Hours   +=  parseInt(  aux_hours   );
																				}

																				if (  cont_SUM_Minutes != "--"  )
																				{
																					cont_SUM_Minutes +=  parseInt(  aux_mins    );
																				}//
																			}
																			else
																			{


																				document.getElementById(  AUX_id_input_TIME  ).value =  "--:--";

																			}//else

																		//}//

																	}// for : k


																	//-------

																	if (  cont_SUM_Minutes  >=  60   )
																	{
																		cont_SUM_Hours +=  Math.floor(  cont_SUM_Minutes  /  60  );;


																		cont_SUM_Minutes =  cont_SUM_Minutes % 60;
																	}//

																	//-------



																	//window.alert( cont_SUM_Hours );
																	//window.alert( cont_SUM_Minutes );


																	let str_final_hour =	(
																								(  (  cont_SUM_Hours  <  10  )   ?   ( '0' + cont_SUM_Hours )  :  cont_SUM_Hours  )		+
																								':'		+
																								(  (  cont_SUM_Minutes  <  10  )   ?   ( '0' + cont_SUM_Minutes )  :  cont_SUM_Minutes )
																							);




																	// EXISTE UN CAMBIO POR LO TANTO HAY QUE ACTUALIZAR LOS SUMADORES DE HORAS TOTALES NOCHE:


																	//window.alert(  str_final_hour  );

																	if (  document.getElementById( "id_input_NIGHTTIME" ) !=  null  )
																	{
																		document.getElementById( "id_input_NIGHTTIME" ).value =  str_final_hour;			//'15' + ':' + '30';			//cont_SUM_Hours;	//str_final_hour;
																	}// if



																	//-------------

																	let aux_id_PORC = "id_input_M3_PORC_WT_"  +  ( event.target.id ) .split( "WT_" )[ 1 ];		// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_id_PORC  ).disabled =  true;
																	document.getElementById(  aux_id_PORC  ).value =  "";



																	let aux_label_PORC = "id_label_M3_PORC_WT_" +  ( event.target.id) .split( "WT_" )[ 1 ];		// .split( "_0" )[ 1 ];

																	document.getElementById(  aux_label_PORC  ).style.color =  "grey";

																}//
																else
																{

																	for (  let k = 1;  k <= 6;  k++  )			// 6
																	{

																		// FILASxCOLS

																		let ax2_j =  Math.floor(  ( k - 1 )  /  2  )  +  1;

																		let ax2_i =  (  ( k - 1 )  %  2 )  +  1;


																		const STR_ax2_OFFSji =  String( ax2_j )  +  "_"  +  String( ax2_i );



																		let AUX_id_input_TIME =  "id_input_M3_TIME_WT_"  +  STR_ax2_OFFSji;


																		//window.alert(  k  + "= " + document.getElementById(  AUX_id_input_TIME  ).value  + " :  " + String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  );


																		if (

																			(  event.target.id  !=  AUX_id_input_TIME   )  &&


																			(  document.getElementById(  AUX_id_input_TIME  ).value  == null   )  ||
																			(  document.getElementById(  AUX_id_input_TIME  ).value  == undefined   )  ||


																			(  document.getElementById(  AUX_id_input_TIME  ).value  ==  ""   )  ||

																			(  document.getElementById(  AUX_id_input_TIME  ).value  ==  "--:--"   )  //&&


																			//(   String( document.getElementById(  AUX_id_input_TIME  ).value ).indexOf( '-' )  ==  -1   )
																		)
																		{

																			document.getElementById(  AUX_id_input_TIME  ).value =  "--:--";

																		}//else

																	}// for : k
																}//


																//window.alert( "value: " );

															}// ( event ) =>

														} // onClick =

														*/

														//disabled={ (  i >= 2  )   ?  true  :  false  }
										/>

									</td>

								</td>


								{ /* ------------------------------------------------------------------------------------------------------------------------ */ }



								{ /* (3º) PARTE DE LOS PORCENTAJES */ }

								<td>

									<td>

										<input

											id= { id_input_PORC }

											type="number"

											name= { name_input_PORC }


											style= {{marginLeft:"1rem", width: "60px"}}


											//value="0"				// si lo pones se queda fijado y no puede cambiar


											min="0"

											max="100"

											//disabled

											/*
											onLoad =		{
																( event ) =>
																{

																	event.target.value = 0;

																} // ( event ) =>

															}
											*/

											onChange =	{
															( event ) =>
															{
																if	(
																		(  event.target.value  <  0  )  ||
																		(  event.target.value  >  100  )
																	)
																{
																	event.target.value = 0;
																}

																/*
																else
																{

																	event.target.value =  Math.round(  event.target.value  );



																	//----


																	let elem_id_num =  ( event.target.id ).split( "WT_" )[ 1 ];			// .split( "_0" )[ 1 ];



																	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ elem_id_num - 1 ].porcent =  event.target.value;


																	m5_dat_vals_tramos_GRAFICA_TEOR[ elem_id_num - 1 ].porcent =  event.target.value;


																	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ elem_id_num - 1 ].porcent =  event.target.value;

																	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ elem_id_num - 1 ].porcent =  event.target.value;

																	//----

																	ar_DAT_elora_M5__REAL = [];

																	ar_DAT_elora_M5__TEOR = [];


																	ar_DAT_elora_M5__NOCHECORTA = [];

																	ar_DAT_elora_M5__NOCHELARGA = [];

																	//--

																	glob_INIT__ARs_ChartData_M5();

																	//--

																	setTimeout(
																				( ) =>
																				{


																					this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																					this.setState(
																									{
																										CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																									}
																					);//

																					//-----------------------------------------


																					this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

																					//----------------------------------------



																					this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL;

																					this.setState(
																									{
																										CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL
																									}
																					);//


																					//window.alert( MITAD_HORAS_TEORICAS );

																				}//

																				, 1000  //2000
																	);


																}*/


															}// ( event ) =>
														}



											//disabled={ (  i >= 2  )   ?  true  :  false  }
										/>
									</td>

									<td>

										<label

											// id= { id_label_PORC }

											// for= { id_input_PORC }

											style= { {color:"black", marginLeft:"1rem"} }
										>
											%
										</label>

									</td>


									<td>

										{
										/*
										<label style= { {marginLeft:"2rem"} }>

											{
												(  i == 1  )
												?
													""
												:
													(  i == 2  )
													?
														<b> * </b>
													:
														(  i == 3  )
														?
															<b> *** </b>
														:
															(  i == 4  )
															?
																<b> * </b>
															:
																(  i == 6  )
																?
																	<b> ** </b>
																:
																	""
											}

										</label>
										*/
										}

									</td>

								</td>


								{
								}

							</tr>


							)

				//------------------------

			);//.push()

			//-----------

		}// for : i



		//-----------------


		// 		<br/>

		for_tr.push(
						(

							<br/>

						)
		);  // .push()


		//-----------------


		for_tr.push(
						(

						<tr>

							<td>

							</td>


							<center>

							<td>


								<center>

								<button

									id= { "id_Button_Draw_M3_Graph" }

									type="button"


									//type="button"

									//name= { name_input_PORC }


									//style= {{marginLeft:"1rem", width: "60px"}}


									//value="0"				// si lo pones se queda fijado y no puede cambiar

									onClick =
												{
													( event ) =>
													{

														//event.target.value = 0;

														//window.alert( "Falta lanzar el procesamiento de la gráfica (función)" );

														this.FROM_ARs__SET_ALL_CURVE_VALUES__M3();

													} // ( event ) =>

												}


									//disabled

									/*
									onLoad =		{
														( event ) =>
														{

															event.target.value = 0;

														} // ( event ) =>

													}
									*/

									//disabled={ (  i >= 2  )   ?  true  :  false  }
								>

									Graficar

								</button>
								</center>

							</td>

							</center>

						</tr>

						)

		); // .push()


		//-----------------



		return (  for_tr  );	// return()



	}// /*function*/ insert_HTML_INPUTS_M_3 ( ) ---------------------------



//---------------------------------	-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//---------------------------------	-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//---------------------------------	-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//---------------------------------	-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



/*function*/ FROM_ARs__SET_ALL_CURVE_VALUES__M5 ( )
{



	//var aux_timestamp_From_start_hour = 0;
	//var dif_timestamp_medianoche_fin_1er_tramo = 0;


	// timestamp_minut_SUNSET_REAL[ 0 ]
	// timestamp_minut_SUNRISE_REAL[ 0 ]


	// const const_hora_start_horario =  17;
	// const const_horas_entre_comienzo_y_medianoche =  24 - 17;	// 7





	//aux_timestamp_From_start_hour =  timestamp_minut_SUNSET_REAL[ 0 ]  +  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ 0 ].relativ_timestamp_min;
	//dif_timestamp_medianoche_fin_1er_tramo =  const_timestamp_medianoche  -  aux_timestamp_From_start_hour;



	//console.log(  "timestamp_minut_SUNSET_REAL[ 0 ]:  "   +  timestamp_minut_SUNSET_REAL[ 0 ]  );
	//console.log(  "timestamp_minut_SUNRISE_REAL[ 0 ]: "   +  timestamp_minut_SUNRISE_REAL[ 0 ]  );


	//-----------------------------------------------------------------------------
	//-----------------------------------------------------------------------------


	/*

	var m5_dat_vals_tramos_GRAFICA_REAL[ 0 ]	=
											[
												{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
												{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


												{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
												{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
												{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
												{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
											];

	*/


	//-----------------------------------------------------------------------------
	//-----------------------------------------------------------------------------


	let aux_comienzo =  -1;		// ini a un valor y ver si falla por algún sitio		//m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ 1 ].abs_timestamp_mins__START;  // FIN WORKTIME 1
	let aux_fin      =  -1;		// ini a un valor y ver si falla por algún sitio		//m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ 1 ].abs_timestamp_mins__END;  // FIN WORKTIME 2


	//-----



	for ( let imon = 0;  imon <= /*0*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0		// <= 12      ------------------------------------------------------------------------------------
	{


		aux_comienzo =  -1;		// ini a un valor y ver si falla por algún sitio		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START;  // FIN WORKTIME 1
		aux_fin      =  -1;		// ini a un valor y ver si falla por algún sitio		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__END;  // FIN WORKTIME 2


		//-------------


		TS_START_grafica =  timestamp_minut_SUNSET_REAL[ imon ];

		TS_FINAL_grafica =  timestamp_minut_SUNRISE_REAL[ imon ];


		//------



		//   IZQ.1  =  WORT TIME 2  =  INDICE 1   (FIJA)    ....... OK


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START =	(  ( TS_ABS__Mitad_Noche[ imon ]  -  (  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].ts_mins   ) )  >=   TS_START_grafica   )
																			?
																				TS_ABS__Mitad_Noche[ imon ]  -  (  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].ts_mins   )
																			:
																				TS_START_grafica;


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__END   =   TS_ABS__Mitad_Noche[ imon ];


		//-------------------------------------------


		//   IZQ.2  =  WORT TIME 1  =  INDICE 0		(VARIABLE Y DEPENDIENTE DE LA DE ARRIBA)   ...... OK


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 0 ].abs_timestamp_mins__START =   TS_START_grafica;		// DEPENDE DE DONDE COMIENCE GRAFICA (LIMITE SUNSET REAL Y DONDE COMIENZA LA IZQ.1)


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 0 ].abs_timestamp_mins__END   =    (   ( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  0  ].ts_mins )  <=  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START  )
																			?
																				( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  0  ].ts_mins )
																			:
																				m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START;							// DONDE COMIENZA IZQ.1



		//-------
		//-------


		ar_DAT_elora_M5__REAL[ imon ].map(
											(  item, index  ) =>
											{
												if (  /*index*/ item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START  )
												{
													str_hora_Linea_IZQ_1_2 =  item.time_hour;

												}// if

											}//---
		);//.map()_

		//----

		/*
		ar_DAT_elora_M5__TEOR.forEach(
								(  item, index  ) =>
								{
									if ( item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START  )		//  index
									{
										str_hora_Linea_IZQ_1_2 =  item.time_hour;

									}// if

								}//---
		);//.forEach()
		*/

		//----


		//-----------------------------------------------------------------------------------------
		//-----------------------------------------------------------------------------------------
		//-----------------------------------------------------------------------------------------




		//   DCHA.1  =  WORT TIME 3  =  INDICE 2	(FIJA)  ....... OK


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__START =   TS_ABS__Mitad_Noche[ imon ];


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END   =	(  ( TS_ABS__Mitad_Noche[ imon ]  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins )  <=   TS_FINAL_grafica   )
																			?
																				TS_ABS__Mitad_Noche[ imon ]  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins
																			:
																				TS_FINAL_grafica;




		//-------------------------------------------


		//   DCHA.2  =  WORT TIME 6  =  INDICE 5	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START  = 	( (  TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].ts_mins  )  >=  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END  )
																			?
																				TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].ts_mins
																			:
																				m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins;


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__END   =   TS_FINAL_grafica;





		/*
		ar_DAT_elora_M5__REAL[ imon ].forEach(
							(  item, index  ) =>
							{
								if (  item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START )     // index
								{
									str_hora_Linea_DCHA_1_3 =  item.time_hour;

								}// if

							}//---
		);//.forEach()
		*/



		//-------------------------------------------




		//   DCHA.3  =  WORT TIME 4  =  INDICE 3	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins;


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START  )
																			?
																				(  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].ts_mins  )
																			:
																				m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins;






		//-------------------------------------------




		//   DCHA.4  =  WORT TIME 5  =  INDICE 4	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins;


		m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START  )
																			?
																				(  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].ts_mins  )
																			:
																				m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].ts_mins;















		//---------------------------------------------------------------------------------------------------------------------------------------------
		//---------------------------------------------------------------------------------------------------------------------------------------------
		//---------------------------------------------------------------------------------------------------------------------------------------------



		// IZQ.1

		// Work Time 2 (ENTERA) :  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ _1_ ].HOURS  ...................   NO PUEDE SER MAYOR DE 7 HORAS (DE 17H A 00H), TIENE QUE SER NECESARIAMENTE MENOR (Y SE VALIDARÁ)

		/*let*/ aux_comienzo =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

		/*let*/ aux_fin      =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 1 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



		//console.log(  "["  +  aux_comienzo + ", " + aux_fin  + "], mitad noche: " +  TS_ABS__Mitad_Noche  );

		//console.log(  );



		for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
		//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
		//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
		{

			//console.log( m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent );

			ar_DAT_elora_M5__REAL[ imon ][  k  ].val =   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent;


		}// for : k ---------------------------------------------------------------------------------------------------



		//----------------------------------------------------------------------------
		//----------------------------------------------------------------------------


		// IZQ.2

		// Work Time 1 (PUEDE NO ESTAR ENTERA SINO PARCIAL, DE ADELANTE A ATRÁS) :


		aux_comienzo =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 0 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

		aux_fin      =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 0 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



		//console.log(  );



		for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
		//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
		//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
		{

			//console.log( m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent );

			ar_DAT_elora_M5__REAL[ imon ][  k  ].val =   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  0  ].porcent;


		}// for : k ---------------------------------------------------------------------------------------------------






		//----------------------------------------------------------------------------
		//----------------------------------------------------------------------------
		//----------------------------------------------------------------------------




		// DCHA. 1


		// Work Time 3 (ENTERA) :

		aux_comienzo =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

		aux_fin      =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 2 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



		//console.log(  );



		for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
		//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
		//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
		{

			//console.log( m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent );

			ar_DAT_elora_M5__REAL[ imon ][  k  ].val =   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  2  ].porcent;


		}// for : k ---------------------------------------------------------------------------------------------------




		//----------------------------------------------------------------------------
		//----------------------------------------------------------------------------


		// DCHA. 2


		// Work Time 6 (PUEDE QUE PARCIAL) :

		aux_comienzo =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

		aux_fin      =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 5 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




		//console.log(  );



		for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
		//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
		//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
		{

			//console.log( m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent );

			ar_DAT_elora_M5__REAL[ imon ][  k  ].val =   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  5  ].porcent;


		}// for : k ---------------------------------------------------------------------------------------------------




		//----------------------------------------------------------------------------
		//----------------------------------------------------------------------------


		// DCHA. 3


		// Work Time 6 (PUEDE QUE PARCIAL) :

		aux_comienzo =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

		aux_fin      =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 3 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




		//console.log(  );



		for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
		//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
		//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
		{

			//console.log( m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent );

			ar_DAT_elora_M5__REAL[ imon ][  k  ].val =   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  3  ].porcent;


		}// for : k ---------------------------------------------------------------------------------------------------




		//----------------------------------------------------------------------------
		//----------------------------------------------------------------------------


		// DCHA. 4


		// Work Time 6 (PUEDE QUE PARCIAL) :

		aux_comienzo =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

		aux_fin      =  m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ 4 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




		//console.log(  );



		for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
		//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
		//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
		{

			//console.log( m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  1  ].porcent );

			ar_DAT_elora_M5__REAL[ imon ][  k  ].val =   m5_dat_vals_tramos_GRAFICA_REAL[ imon ][  4  ].porcent;


		}// for : k ---------------------------------------------------------------------------------------------------



	} // for ( let imon = 0;  imon <= 12;  imon++ ) ----------------------------------------------------------------------------------------------------------


	//------------

	/*
	const aux_json_ars = JSON.stringify( ar_DAT_elora_M5__REAL );


	console.log(  JSON.stringify( ar_DAT_elora_M5__REAL[ 0 ][600] ).length  );

	console.log(  ar_DAT_elora_M5__REAL[ 0 ][600]  );

	//---

	console.log( "----------------" );

	//---

	console.log(  aux_json_ars.length  );
	*/
	//console.log(  aux_json_ars  );






	//console.log(  ar_DAT_elora_M5__REAL  );


	//-------------



	//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------




	// .................... GRÁFICA TEÓRICA: ............................................


	//window.alert(  timestamp_minut_SUNSET_TEOR  );


	TS_START_grafica =  timestamp_minut_SUNSET_TEOR;

	TS_FINAL_grafica =  timestamp_minut_SUNRISE_TEOR;

	//------



	//   IZQ.1  =  WORT TIME 2  =  INDICE 1   (FIJA)    ....... OK


	m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__START =	(  ( TS_ABS__Mitad_Noche[ 0 ]  -  (  m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].ts_mins   ) )  >=   TS_START_grafica   )
																		?
																			TS_ABS__Mitad_Noche[ 0 ]  -  (  m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].ts_mins   )
																		:
																			TS_START_grafica;


	m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__END   =   TS_ABS__Mitad_Noche[ 0 ];


	//-------------------------------------------


	//   IZQ.2  =  WORT TIME 1  =  INDICE 0		(VARIABLE Y DEPENDIENTE DE LA DE ARRIBA)   ...... OK


	m5_dat_vals_tramos_GRAFICA_TEOR[ 0 ].abs_timestamp_mins__START =   TS_START_grafica;		// DEPENDE DE DONDE COMIENCE GRAFICA (LIMITE SUNSET REAL Y DONDE COMIENZA LA IZQ.1)


	m5_dat_vals_tramos_GRAFICA_TEOR[ 0 ].abs_timestamp_mins__END   =   	(   ( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_TEOR[  0  ].ts_mins )  <=  m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__START  )
																		?
																			( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_TEOR[  0  ].ts_mins )
																		:
																			m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__START;							// DONDE COMIENZA IZQ.1



	//-------
	//-------

	/*
	ar_DAT_elora_M5__TEOR.forEach(
							(  item, index  ) =>
							{
								if (item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__START  )
								{
									str_hora_Linea_IZQ_1_2 =  item.time_hour;

								}// if

							}//---
	);//.forEach()
	*/

	//----

	/*
	ar_DAT_elora_M5__TEOR.forEach(
							(  item, index  ) =>
							{
								if ( item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__START  )		//  index
								{
									str_hora_Linea_IZQ_1_2 =  item.time_hour;

								}// if

							}//---
	);//.forEach()
	*/

	//----


	//-----------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------




	//   DCHA.1  =  WORT TIME 3  =  INDICE 2	(FIJA)  ....... OK


	m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__START =   TS_ABS__Mitad_Noche[ 0 ];


	m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END   =	(  ( TS_ABS__Mitad_Noche[ 0 ]  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins )  <=   TS_FINAL_grafica   )
																		?
																			TS_ABS__Mitad_Noche[ 0 ]  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins
																		:
																			TS_FINAL_grafica;




	//-------------------------------------------


	//   DCHA.2  =  WORT TIME 6  =  INDICE 5	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START  = 	( (  TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].ts_mins  )  >=  m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END  )
																		?
																			TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].ts_mins
																		:
																			m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__END   =   TS_FINAL_grafica;





	/*
	ar_DAT_elora_M5__TEOR.forEach(
						(  item, index  ) =>
						{
							if (  item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START )     // index
							{
								str_hora_Linea_DCHA_1_3 =  item.time_hour;

							}// if

						}//---
	);//.forEach()
	*/



	//-------------------------------------------




	//   DCHA.3  =  WORT TIME 4  =  INDICE 3	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START  )
																		?
																			(  m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].ts_mins  )
																		:
																			m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins;






	//-------------------------------------------




	//   DCHA.4  =  WORT TIME 5  =  INDICE 4	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START  )
																		?
																			(  m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].ts_mins  )
																		:
																			m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;		 //TS_ABS__Mitad_Noche  +   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].ts_mins;















	//---------------------------------------------------------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------------------------------------------------------



	// IZQ.1

	// Work Time 2 (ENTERA) :  m5_dat_vals_tramos_GRAFICA_TEOR[ _1_ ].HOURS  ...................   NO PUEDE SER MAYOR DE 7 HORAS (DE 17H A 00H), TIENE QUE SER NECESARIAMENTE MENOR (Y SE VALIDARÁ)

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_TEOR[ 1 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  "["  +  aux_comienzo + ", " + aux_fin  + "], mitad noche: " +  TS_ABS__Mitad_Noche  );

	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )		// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )		// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent );

		ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------



	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// IZQ.2

	// Work Time 1 (PUEDE NO ESTAR ENTERA SINO PARCIAL, DE ADELANTE A ATRÁS) :


	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_TEOR[ 0 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_TEOR[ 0 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent );

		ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  0  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------






	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------




	// DCHA. 1


	// Work Time 3 (ENTERA) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_TEOR[ 2 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent );

		ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  2  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 2


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_TEOR[ 5 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent );

		ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  5  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 3


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_TEOR[ 3 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent );

		ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  3  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 4


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_TEOR[ 4 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent );

		ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  4  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------





	//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------




	// .................... GRÁFICA NOCHECORTA: ............................................




	TS_START_grafica =  timestamp_minut_SUNSET_NOCHECORTA;

	TS_FINAL_grafica =  timestamp_minut_SUNRISE_NOCHECORTA;

	//------



	//   IZQ.1  =  WORT TIME 2  =  INDICE 1   (FIJA)    ....... OK


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__START =	(  ( timestamp_minut_MITAD_NOCHECORTA  -  (  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].ts_mins   ) )  >=   TS_START_grafica   )
																			?
																				timestamp_minut_MITAD_NOCHECORTA  -  (  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].ts_mins   )
																			:
																				TS_START_grafica;


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__END   =   timestamp_minut_MITAD_NOCHECORTA;


	//-------------------------------------------


	//   IZQ.2  =  WORT TIME 1  =  INDICE 0		(VARIABLE Y DEPENDIENTE DE LA DE ARRIBA)   ...... OK


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 0 ].abs_timestamp_mins__START =   TS_START_grafica;		// DEPENDE DE DONDE COMIENCE GRAFICA (LIMITE SUNSET REAL Y DONDE COMIENZA LA IZQ.1)


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 0 ].abs_timestamp_mins__END   =		(   ( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  0  ].ts_mins )  <=  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__START  )
																				?
																					( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  0  ].ts_mins )
																				:
																					m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__START;							// DONDE COMIENZA IZQ.1



	//-------
	//-------

	/*
	ar_DAT_elora_M5__NOCHECORTA.forEach(
							(  item, index  ) =>
							{
								if (item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__START  )
								{
									str_hora_Linea_IZQ_1_2 =  item.time_hour;

								}// if

							}//---
	);//.forEach()
	*/
	//----

	/*
	ar_DAT_elora_M5__NOCHECORTA.forEach(
							(  item, index  ) =>
							{
								if ( item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__START  )		//  index
								{
									str_hora_Linea_IZQ_1_2 =  item.time_hour;

								}// if

							}//---
	);//.forEach()
	*/

	//----


	//-----------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------



	//   DCHA.1  =  WORT TIME 3  =  INDICE 2	(FIJA)  ....... OK


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__START =   timestamp_minut_MITAD_NOCHECORTA;


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END   =	(  ( timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins )  <=   TS_FINAL_grafica   )
																			?
																				timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins
																			:
																				TS_FINAL_grafica;




	//-------------------------------------------


	//   DCHA.2  =  WORT TIME 6  =  INDICE 5	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START  = 	( (  TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].ts_mins  )  >=  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END  )
																				?
																					TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].ts_mins
																				:
																					m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__END   =   TS_FINAL_grafica;





	/*
	ar_DAT_elora_M5__NOCHECORTA.forEach(
						(  item, index  ) =>
						{
							if (  item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START )     // index
							{
								str_hora_Linea_DCHA_1_3 =  item.time_hour;

							}// if

						}//---
	);//.forEach()
	*/



	//-------------------------------------------




	//   DCHA.3  =  WORT TIME 4  =  INDICE 3	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START  )
																			?
																				(  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].ts_mins  )
																			:
																				m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins;






	//-------------------------------------------




	//   DCHA.4  =  WORT TIME 5  =  INDICE 4	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START  )
																			?
																				(  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].ts_mins  )
																			:
																				m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHECORTA  +   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].ts_mins;








	//---------------------------------------------------------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------------------------------------------------------



	// IZQ.1

	// Work Time 2 (ENTERA) :  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ _1_ ].HOURS  ...................   NO PUEDE SER MAYOR DE 7 HORAS (DE 17H A 00H), TIENE QUE SER NECESARIAMENTE MENOR (Y SE VALIDARÁ)

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 1 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  "["  +  aux_comienzo + ", " + aux_fin  + "], mitad noche: " +  timestamp_minut_MITAD_NOCHECORTA  );

	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )		// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )		// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHECORTA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------



	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// IZQ.2

	// Work Time 1 (PUEDE NO ESTAR ENTERA SINO PARCIAL, DE ADELANTE A ATRÁS) :


	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 0 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 0 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHECORTA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  0  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------






	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------




	// DCHA. 1


	// Work Time 3 (ENTERA) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 2 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHECORTA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  2  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 2


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 5 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHECORTA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  5  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 3


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 3 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHECORTA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  3  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 4


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ 4 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHECORTA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHECORTA[  4  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------




	// .................... GRÁFICA NOCHELARGA: ............................................




	TS_START_grafica =  timestamp_minut_SUNSET_NOCHELARGA;

	TS_FINAL_grafica =  timestamp_minut_SUNRISE_NOCHELARGA;

	//------



	//   IZQ.1  =  WORT TIME 2  =  INDICE 1   (FIJA)    ....... OK


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__START =	(  ( timestamp_minut_MITAD_NOCHELARGA  -  (  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].ts_mins   ) )  >=   TS_START_grafica   )
																			?
																				timestamp_minut_MITAD_NOCHELARGA  -  (  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].ts_mins   )
																			:
																				TS_START_grafica;


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__END   =   timestamp_minut_MITAD_NOCHELARGA;


	//-------------------------------------------


	//   IZQ.2  =  WORT TIME 1  =  INDICE 0		(VARIABLE Y DEPENDIENTE DE LA DE ARRIBA)   ...... OK


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 0 ].abs_timestamp_mins__START =   TS_START_grafica;		// DEPENDE DE DONDE COMIENCE GRAFICA (LIMITE SUNSET REAL Y DONDE COMIENZA LA IZQ.1)


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 0 ].abs_timestamp_mins__END   =		(   ( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  0  ].ts_mins )  <=  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__START  )
																				?
																					( TS_START_grafica  +  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  0  ].ts_mins )
																				:
																					m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__START;							// DONDE COMIENZA IZQ.1



	//-------
	//-------

	/*
	ar_DAT_elora_M5__NOCHELARGA.forEach(
							(  item, index  ) =>
							{
								if (item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__START  )
								{
									str_hora_Linea_IZQ_1_2 =  item.time_hour;

								}// if

							}//---
	);//.forEach()
	*/
	//----

	/*
	ar_DAT_elora_M5__NOCHELARGA.forEach(
							(  item, index  ) =>
							{
								if ( item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__START  )		//  index
								{
									str_hora_Linea_IZQ_1_2 =  item.time_hour;

								}// if

							}//---
	);//.forEach()
	*/

	//----


	//-----------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------



	//   DCHA.1  =  WORT TIME 3  =  INDICE 2	(FIJA)  ....... OK


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__START =   timestamp_minut_MITAD_NOCHELARGA;


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END   =	(  ( timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins )  <=   TS_FINAL_grafica   )
																			?
																				timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins
																			:
																				TS_FINAL_grafica;




	//-------------------------------------------


	//   DCHA.2  =  WORT TIME 6  =  INDICE 5	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START  = 	( (  TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].ts_mins  )  >=  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END  )
																				?
																					TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].ts_mins
																				:
																					m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__END   =   TS_FINAL_grafica;





	/*
	ar_DAT_elora_M5__NOCHELARGA.forEach(
						(  item, index  ) =>
						{
							if (  item.timestamp  ==  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START )     // index
							{
								str_hora_Linea_DCHA_1_3 =  item.time_hour;

							}// if

						}//---
	);//.forEach()
	*/



	//-------------------------------------------




	//   DCHA.3  =  WORT TIME 4  =  INDICE 3	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START  )
																			?
																				(  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].ts_mins  )
																			:
																				m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins;






	//-------------------------------------------




	//   DCHA.4  =  WORT TIME 5  =  INDICE 4	(VARIABLE Y DE DEPENDIENTE DE LA DE ARRIBA)



	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].abs_timestamp_mins__START  = 	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__END;		//m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins;


	m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].abs_timestamp_mins__END  = 	( (  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].abs_timestamp_mins__START  +  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].ts_mins  )  <=  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START  )
																			?
																				(  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].abs_timestamp_mins__START +  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].ts_mins  )
																			:
																				m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;		//m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		//TS_FINAL_grafica  -  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;	 //m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;		 //timestamp_minut_MITAD_NOCHELARGA  +   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].ts_mins;








	//---------------------------------------------------------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------------------------------------------------------



	// IZQ.1

	// Work Time 2 (ENTERA) :  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ _1_ ].HOURS  ...................   NO PUEDE SER MAYOR DE 7 HORAS (DE 17H A 00H), TIENE QUE SER NECESARIAMENTE MENOR (Y SE VALIDARÁ)

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 1 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  "["  +  aux_comienzo + ", " + aux_fin  + "], mitad noche: " +  timestamp_minut_MITAD_NOCHELARGA  );

	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )		// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )		// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHELARGA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------



	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// IZQ.2

	// Work Time 1 (PUEDE NO ESTAR ENTERA SINO PARCIAL, DE ADELANTE A ATRÁS) :


	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 0 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 0 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHELARGA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  0  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------






	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------




	// DCHA. 1


	// Work Time 3 (ENTERA) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 2 ].abs_timestamp_mins__END;  // FIN WORKTIME 2



	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHELARGA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  2  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 2


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 5 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHELARGA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  5  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 3


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 3 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHELARGA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  3  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------




	//----------------------------------------------------------------------------
	//----------------------------------------------------------------------------


	// DCHA. 4


	// Work Time 6 (PUEDE QUE PARCIAL) :

	aux_comienzo =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].abs_timestamp_mins__START;  // FIN WORKTIME 1

	aux_fin      =  m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ 4 ].abs_timestamp_mins__END;  // FIN WORKTIME 2




	//console.log(  );



	for (  let k = aux_comienzo;  k  <  aux_fin;  k++  )
	//for (  let k = const_timestamp_medianoche;  k  >=  0;  k--  )	// ( 17 * 60 )
	//for (  let k = 0;  k  <  const_timestamp_medianoche;  k++  )	// ( 17 * 60 )
	{

		//console.log( m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  1  ].porcent );

		ar_DAT_elora_M5__NOCHELARGA[  k  ].val =   m5_dat_vals_tramos_GRAFICA_NOCHELARGA[  4  ].porcent;


	}// for : k ---------------------------------------------------------------------------------------------------






	//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



	this.state.CPU_elorav1_Chart_Data_M5 =  [];

	//----

	this.setState(
					{
						CPU_elorav1_Chart_Data_M5:  []
					}
	); //

	//----


	//console.log(  ar_DAT_elora_M5__REAL  );




	//console.log(  STR_time_SUNSET_REAL  );
	//console.log(  STR_time_SUNRISE_REAL  );


	//-----


	this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL[ 0 ];

	//----


	this.setState(
					{
						CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL[ 0 ]
					}
	); //


	//---------------------------------------------------------------------------


	return "";



}// function FROM_ARs__SET_ALL_CURVE_VALUES__M5 ( ) ------------------------------------------------------------------------------------------------------------------------------------------]




//-------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------




/*function*/ FROM_ARs__SET_ALL_CURVE_VALUES__M3 ()
{


	/*
	const const_MAX_HORAS_GRAFICA =  17;



	const const_hora_start_horario =  17;

	//--


	const const_horas_entre_comienzo_y_medianoche =  24 - 17;	// 7


	const const_timestamp_medianoche =   const_horas_entre_comienzo_y_medianoche  *  60;
	*/




	//-----

	// GRAF FROM: 17 -> 00 -> 10  =  7 + 10 =  17   ......   17 * 60 =  1020 PUNTOS

	// GRAF HTML:  id_RP_mychart_cpu__tipo_3_1


	//-----------------------------


	/*
		m3_dat_vals_tramos_GRAFICA	=
										[
											{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
											{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
											{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
											{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
											{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
											{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  }
										];
	*/





//ar_DAT_elora_M3__REAL = new Array( /*17 * 60*/ );

//glob_INIT__ARs_ChartData_M3 ();





	//--

	// ar_DAT_elora_M


	//--------

	//window.alert( document.getElementById(  "id_input_M3_TIME_WT__INI_" + "1"  ).value );

	var aux_ANT__ts_MIN_fin = 0;

	var aux_ANT__STR_ini = "";
	var aux_ANT__STR_fin = "";


	//---------


	for (  let i = 1;  i <= 6;  i++  )   // 6 RANGOS DE TIEMPO ----------------------------------------------------------------------[
	{

		let STR_ts_ini =  document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value;
		let STR_ts_fin =  document.getElementById(  "id_input_M3_TIME_WT__FIN_" + i  ).value;

		let STR_PORCENT = document.getElementById(  "id_input_M3_PORC_WT__" + i  ).value;


		//-----











		if (  ( STR_ts_ini.length <= 0 )   )
		{

			STR_ts_ini = "17:00";
			document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value =  STR_ts_ini;
			INT_ts_HOUR_ini = parseInt(  STR_ts_ini.split( ":" )[ 0 ]  );

			aux_ANT__STR_ini = STR_ts_ini;

		}//


		if (  ( STR_ts_fin.length <= 0 )   )
		{

			STR_ts_fin = "17:00";
			document.getElementById(  "id_input_M3_TIME_WT__FIN_" + i  ).value =  STR_ts_fin;
			INT_ts_HOUR_fin = parseInt(  STR_ts_fin.split( ":" )[ 0 ]  );

			aux_ANT__STR_fin =  STR_ts_fin;
		}//


		if ( STR_PORCENT.length <= 0 )
		{
			STR_PORCENT = 0;
			document.getElementById(  "id_input_M3_PORC_WT__" + i  ).value = 0;
			int_PORCENT = parseInt(  STR_PORCENT  );

		}//









		//------------------------------------------------------------------------------------------------


		//onsole.log( STR_ts_ini );
		//console.log( STR_ts_fin );
		//console.log( STR_PORCENT );

		//--

		let INT_ts_HOUR_ini = parseInt(  STR_ts_ini.split( ":" )[ 0 ]  );

		if (  ( INT_ts_HOUR_ini > 10 )  &&  ( INT_ts_HOUR_ini < 17 )  )
		{

			STR_ts_ini = "17:00";

			document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value =  STR_ts_ini;

			INT_ts_HOUR_ini = parseInt(  STR_ts_ini.split( ":" )[ 0 ]  );

			aux_ANT__STR_ini = STR_ts_ini;

		}// if


		let INT_ts_MIN_ini = parseInt(  STR_ts_ini.split( ":" )[ 1 ]  );

		let INT_ts_ABS_ini =
								(   (  parseInt(  INT_ts_HOUR_ini  )  >= 0   )  &&  (   INT_ts_HOUR_ini  )  <  17   )
								?
									(  ( INT_ts_HOUR_ini* 60 )  +  INT_ts_MIN_ini  )   +  (  7 * 60 )
								:
									(  ( INT_ts_HOUR_ini* 60 )  +  INT_ts_MIN_ini  ) - (  const_hora_start_horario * 60  );


		//console.log( INT_ts_ABS_ini );

		//----

		if (  INT_ts_ABS_ini  <  aux_ANT__ts_MIN_fin  )
		{

			//console.log( "NO SE HACE NADA " + i + "(2)" );

			//console.log( "SE PONE EL INI ACTUAL COMO EL FIN ANTERIOR" );

			INT_ts_ABS_ini = aux_ANT__ts_MIN_fin;

			document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value =  aux_ANT__STR_fin

			//window.alert(  "INI =  "  +    aux_ANT__STR_fin  );

		}// if

		//----

		//console.log( STR_ts_ini );



		let INT_ts_HOUR_fin = parseInt(  STR_ts_fin.split( ":" )[ 0 ]  );

		if (  ( INT_ts_HOUR_fin > 10 )  &&  ( INT_ts_HOUR_fin < 17 )  )
		{

			STR_ts_fin = "17:00";

			document.getElementById(  "id_input_M3_TIME_WT__FIN_" + i  ).value =  STR_ts_fin;

			INT_ts_HOUR_fin = parseInt(  STR_ts_fin.split( ":" )[ 0 ]  );

			aux_ANT__STR_fin =  STR_ts_fin;

		}// if

		let INT_ts_MIN_fin = parseInt(  STR_ts_fin.split( ":" )[ 1 ]  );


		//(  ( INT_ts_HOUR_fin * 60 )  +  INT_ts_MIN_fin  ) - (  const_hora_start_horario * 60  );

		let INT_ts_ABS_fin =
								(   (  parseInt(  INT_ts_HOUR_fin  )  >= 0   )  &&  (   INT_ts_HOUR_fin  )  <  17   )
								?
									(  ( INT_ts_HOUR_fin* 60 )  +  INT_ts_MIN_fin  )  +  (  7 * 60 )
								:
									(  ( INT_ts_HOUR_fin* 60 )  +  INT_ts_MIN_fin  ) - (  const_hora_start_horario * 60  );

		//---


		if (  INT_ts_ABS_fin  <  aux_ANT__ts_MIN_fin  )		// <=
		{

			//console.log( "NO SE HACE NADA " + i + "(4)" );

			INT_ts_ABS_fin = aux_ANT__ts_MIN_fin;

			document.getElementById(  "id_input_M3_TIME_WT__FIN_" + i  ).value =  aux_ANT__STR_fin

			//window.alert(  "FIN =  "  +  aux_ANT__STR_fin  );

		}// if

		//---

		//console.log( INT_ts_ABS_fin );


		//---

		if (  INT_ts_ABS_ini  >  INT_ts_ABS_fin  )
		{

			//console.log( "NO SE HACE NADA (2)" );

			INT_ts_ABS_fin = INT_ts_ABS_ini;

			document.getElementById(  "id_input_M3_TIME_WT__FIN_" + i  ).value =  document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value

			//window.alert(  "FIN (DE INI)=  "  +  document.getElementById(  "id_input_M3_TIME_WT__INI_" + i  ).value  );

		}// IF



		//---

		aux_ANT__ts_MIN_fin =  INT_ts_ABS_fin;

		aux_ANT__STR_ini = STR_ts_ini;
		aux_ANT__STR_fin = STR_ts_fin;

		//---

		let int_PORCENT = parseInt(  STR_PORCENT  );


		for (  let k = INT_ts_ABS_ini;  k  <  INT_ts_ABS_fin;  k++  )		// <=   	// SE RELLENAN TODOS LOS MINUTOS EXISTENTES
		{

			let aux_horass =   Math.floor(  (  (  const_hora_start_horario  * 60 )  +  k )  /  60  );



			aux_horass =  (   (   (  aux_horass  >=  24  )   )  ?  ( aux_horass - 24 )  :  aux_horass   );    /*&&  (  k  >=  ( 7 * 60 )  )*/

			aux_horass =  (  aux_horass  <  10 )    ?    (  '0'  +  aux_horass  )   :    aux_horass;



			let aux_minss =  k  %  60;

			aux_minss =  (  aux_minss  <  10  )  ?   ( '0' + aux_minss )  :  aux_minss;

			//--

			ar_DAT_elora_M3__REAL[ k ].val =  int_PORCENT;
			ar_DAT_elora_M3__REAL[ k ].timestamp =  k;
			ar_DAT_elora_M3__REAL[ k ].time_hour =  aux_horass  +  ':'  +  aux_minss;


		}// for : K






		//---


		//---

	}// for : I





	/*

		let id_input_TIME_INI_i =  "id_input_M3_TIME_WT__INI_"  +  String( i );

		let name_input_TIME_INI_i =  "nam_input_M3_TIME_WT__INI_"  +  String( i );

		//---


		let id_input_TIME_FIN_i =  "id_input_M3_TIME_WT__FIN_"  +  String( i );

		let name_input_TIME_FIN_i =  "nam_input_M3_TIME_WT__FIN_"  +  String( i );



		//----------


		let id_input_PORC =  "id_input_M3_PORC_WT__"  +  String( i );


		let id_label_PORC =  "id_label_M3_PORC_WT_"  +  String( i );

		let name_input_PORC =  "nam_input_M3_PORC_WT_"  +  String( i );


	*/



	/*
		ar_DAT_elora_M5__REAL[ 0 ].push(
										{
											val:  0,	//(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )  ?   //( 42.0  +  37.0*Math.sin( k / 10.0 ) )/  0  :  0,


											timestamp:  k, 		// 60,/		// timestamp en MINUTOS

											time_hour:  aux_horass  +  ':'  +  aux_minss,



											val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

											val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
										}
		);// .push()

	*/


	//--------



	//m3_dat_vals_tramos_GRAFICA.abs_timestamp_mins__START =

	//window.alert( "[ Dentro de _FROM_ARs__SET_ALL_CURVE_VALUES__M3 ]" );


	//--------



	//----------------------------------------------------------------------------



	this.state.CPU_elorav1_Chart_Data_M3 =  [];

	//----

	this.setState(
					{
						CPU_elorav1_Chart_Data_M3:  []
					}
	); //


	//---------------------------------------------------------------------------


	this.state.CPU_elorav1_Chart_Data_M3 =  ar_DAT_elora_M3__REAL;

	//----


	this.setState(
					{
						CPU_elorav1_Chart_Data_M3:  ar_DAT_elora_M3__REAL
					}
	); //



	//---------------------------------------------------------------------------



	//return true;


}// function FROM_ARs__SET_ALL_CURVE_VALUES__M3 ( )




//-------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------




	getInstallationGroupDetail ( id,  all )
	{

		let t_genCurvDAT = {};


		//--


		let ar_obj_sunset_sunrise_13elems = new Array();


		ar_obj_sunset_sunrise_13elems.push(
												{}
		); // .push()


		//--

		let extra = "";


		if (  all  )
		{
			extra = "?all=true";

		}// if


		//----

		this.state.waiting =  true;


		this.setState(
						{
							waiting: true,
							showSpinner: true
						}
		);// .setState()


		//---


		axios.get(
		//
						CONFIG.api_url  +  "/installationgroups/"  +  id  +  extra,


						{
							"headers":
										{
											'Content-Type': 'application/json',
											'Authorization': "Token " + this.props.auth.token
										}
						}

		//
		).then(
		//

			( result ) =>
			{

				const resultDATA =  result.data;


				//console.log(  resultDATA  );


				if (
						( resultDATA.info.weather.sun_info === undefined )
					||
						( resultDATA.info.weather.sun_info == {} )
				)
				{

					resultDATA.info.weather =	{
													"sun_info":
																{
																	"sunrise_local": "xT8:00",
																	"sunset_local": "xT21:00",
																}
												}

				}// if ( ... )


				//---

				if (  all  )
				{

					resultDATA.id = "all";
					resultDATA.group_name = "All";

				} // if ( ... )








				//--------------------------------------------


				// UN INI POR DEFECTO (PARA LOS PERFILES YA EXISTENTES DIGAMOS)         (LUEGO HABRÁ QUE CAMBIARLO SEGUN EL TIPO QUE DEUVELVA LA API)



				// LUEGO YA COGEMOS LA DEL SCHEDULE PROFILE GUARDADA DENTRO (tipoPERFIL)




				/*
				tipoPERFIL =	(  resultDATA.info.max_cpu_version  ==  vCPU[ "elorav1" ]  )
								?
									2
								:
									1;
				*/




				//---

				/*

				/perfiles/de8e3697-948b-41fd-abc6-7de768332739/detalle/
				/perfiles/-1/detalle/

				if (  this.props.location.search.indexOf( "installation_id" ) > -1  )  //  >= 0
				{

					const aux_presel_inst_id =  this.props.location.search.split( "?installation_id=" )[1].split( "&" )[0];

					const aux_presel_sl_id 	 =	this.props.location.search.split( "&sl_id=" )[1];

				*/


				let locationPathname =  this.props.location.pathname;
				//console.log(  locationPathname  );


				let aux_locationPathname_ID =  locationPathname.split(  "/perfiles/"  );

				//--

				if (   aux_locationPathname_ID.length  >  0  )
				{

					aux_locationPathname_ID =  aux_locationPathname_ID[  1  ].split(  "/detalle/"  );


					if (   aux_locationPathname_ID.length  >  0  )
					{

						aux_locationPathname_ID =  aux_locationPathname_ID[  0  ];

						//console.log(  aux_locationPathname_ID  );


						//console.log( resultDATA );
						//console.log( resultDATA.info.max_cpu_version );


						//if (
						//		(   aux_locationPathname_ID  ==  "-1"   )  &&			//  ==  -1   )  &&				//  SON PEREFILES NUEVOS, RECIEN CREADOS
						//
						//		(  this.props.auth.user.user_profile.userlevel == "EKIONA"  )  &&
						//
						//		(  resultDATA.info.max_cpu_version  ==  vCPU[ "elorav1" ]  )
						//
						////(  this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]  )
						//)
						//{
						//
						////	//let aux_tipoPERFIL = prompt(  "( 1 ) Classic Dimming\n( 5 ) EPEVER-LORA Special Dimming\n( 3 ) NEW EPEVER-LORA new Special Dimming\n  \n( Otherwise DEFAULT= 1)\n  \n",  "1"  );
						//
						//		//window.alert(  aux_tipoPERFIL  );
						//
						//		//window.alert( resultDATA.ELORA_dimming_mode );
						//
						//
						//	tipoPERFIL =	resultDATA.ELORA_dimming_mode;
						//
						//	//console.log( tipoPERFIL );
						//
						//					/*
						//					(  aux_tipoPERFIL  ==  5  )
						//					?
						//						tipoPERFIL =  5
						//					:
						//						(  aux_tipoPERFIL  ==  3  )
						//						?
						//							tipoPERFIL =  3
						//						:
						//							tipoPERFIL =  1;
						//					*/
						//
						//	//window.alert(  tipoPERFIL  );
						//
						//
						//	//let mival = window.confirm( "Crear un perfil tipo ELORA?" );
						//	//window.alert(  mival  );
						//
						//} // if ------


						tipoPERFIL =	resultDATA.ELORA_dimming_mode;


					} // if (   aux_locationPathname_ID.length  >  0  )

				}// if (   aux_locationPathname_ID.length  >  0  )






				//--------------------------------------------



				//this.state.selected_installationgroup.max_cpu_version =  resultDATA.info.max_cpu_version;

				//console.log( resultDATA );

				//console.log(  ( resultDATA ).sunrise_sunset_year  );


				let json_sunrise_sunset_year  =  JSON.parse(   ( resultDATA ).sunrise_sunset_year   );


				//console.log(  json_sunrise_sunset_year  );


				ar_sunset_sunrise_YEAR = [];

				/*
				for (  let im = 0;  im < 12;  im++  )
				{

					console.log(   json_sunrise_sunset_year[  im  ]   );

					//ar_sunset_sunrise_YEAR[ im ]

				}//
				*/

				//----





				json_sunrise_sunset_year.forEach(
				//
					( item, index ) =>
					{

						ar_sunset_sunrise_YEAR.push(  item  )

						//console.log( item );

					}//

				); //.forEach(





				//console.log(  ar_sunset_sunrise_YEAR  );


				//----


				//-------------------

				//--------------------------------------------------------------------------------------------------------------------------------


				// CALCULAR LA NOCHE MÁS CORTA Y MÁS LARGA DEL AÑO:


				// CALC :: NOCHE MÁS LARGA, NOCHE MÁS CORTA


				let NOCHECORTA_tsDELTA =  48 * 60;  // INICIALIZAMOS CON UN SUPUESTO MÁXIMO POSIBLE (PERO NO REAL)

				let NOCHECORTA_tsDELTA_DIV2 =  0;


				NOCHECORTA_month =  -1;
				NOCHECORTA_day   =  -1;


				//let MITAD_DIA__NOCHECORTA_ts =  0;

				MITAD_DIA__NOCHECORTA_str =  "";



				//--

				let NOCHELARGA_tsDELTA =  0;  // INICIALIZAMOS CON UN SUPUESTO MÍNIMO POSIBLE (PERO NO REAL)

				let NOCHELARGA_tsDELTA_DIV2 =  0;


				NOCHELARGA_month =  -1;
				NOCHELARGA_day   =  -1;


				//let MITAD_DIA__NOCHELARGA_ts =  0;

				MITAD_DIA__NOCHELARGA_str =  "";


				//---


				for (  let im = 0;  im < 12;  im++  )
				{

					for (  let id = 0;  id < 31;  id++  )
					{

						let str_time_SUNSET  =  ar_sunset_sunrise_YEAR[  im  ][  id  ].sunset;
						let str_time_SUNRISE =  ar_sunset_sunrise_YEAR[  im  ][  id  ].sunrise;


						if (  ( str_time_SUNSET  !=  "" )  &&  ( str_time_SUNRISE  !=  "" )  )
						{

							let ar_str_time_SUNSET =  str_time_SUNSET.split( ":" );
							let TS_time_SUNSET =  (  parseInt(  ar_str_time_SUNSET[ 0 ]  ) * 60  )  +  parseInt(  ar_str_time_SUNSET[ 1 ]  );

							let ar_str_time_SUNRISE =  str_time_SUNRISE.split( ":" );
							let TS_time_SUNRISE =  (   (  parseInt(  ar_str_time_SUNRISE[ 0 ]  )  +  24  )  *  60   )   +   parseInt(  ar_str_time_SUNRISE[ 1 ]   );

							let DELTA_TS =  TS_time_SUNRISE  -  TS_time_SUNSET;


							if (  DELTA_TS  <=  NOCHECORTA_tsDELTA  )
							{

								NOCHECORTA_month =  im;
								NOCHECORTA_day   =  id;

								NOCHECORTA_tsDELTA =  DELTA_TS;

							}// if (  DELTA_TS  <  NOCHECORTA_tsDELTA  )



							if (  DELTA_TS  >=  NOCHELARGA_tsDELTA  )
							{

								NOCHELARGA_month =  im;
								NOCHELARGA_day   =  id;

								NOCHELARGA_tsDELTA =  DELTA_TS;

							}// if (  DELTA_TS  <  NOCHECORTA_tsDELTA  )

						}// if (  ( str_time_SUNSET  !=  "" )  &&  ( str_time_SUNRISE  !=  "" )  )


					}// for (  let id = 0;  id < 31;  id++  )

				}// for (  let im = 0;  im < 12;  im++  ) ------------------------

				// ----


				let NOCHECORTA_sunRise_HOUR =  parseInt(   (  ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunrise  ).split( ":" )[ 0 ]   );
				let NOCHECORTA_sunRise_MINS =  parseInt(   (  ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunrise  ).split( ":" )[ 1 ]   );


				let NOCHELARGA_sunRise_HOUR =  parseInt(   (  ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunrise  ).split( ":" )[ 0 ]   );
				let NOCHELARGA_sunRise_MINS =  parseInt(   (  ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunrise  ).split( ":" )[ 1 ]   );


				//---


				NOCHECORTA_tsDELTA_DIV2 =  Math.floor(   NOCHECORTA_tsDELTA  /  2.0   );


				let NOCHECORTA_MITAD_DIA__HOUR =  Math.floor( NOCHECORTA_tsDELTA_DIV2  /  60  );
				let NOCHECORTA_MITAD_DIA__MINS =  ( NOCHECORTA_tsDELTA_DIV2  %  60  );



				let MITAD_DIA__NOCHECORTA_HOUR_adjust =		(  NOCHECORTA_sunRise_HOUR  -  NOCHECORTA_MITAD_DIA__HOUR  );

				MITAD_DIA__NOCHECORTA_HOUR_adjust =		( MITAD_DIA__NOCHECORTA_HOUR_adjust < 0 )
														?
															24 - MITAD_DIA__NOCHECORTA_HOUR_adjust
														:
															MITAD_DIA__NOCHECORTA_HOUR_adjust;



				let MITAD_DIA__NOCHECORTA_MINS_adjust =	(	  NOCHECORTA_sunRise_MINS  -  NOCHECORTA_MITAD_DIA__MINS  );

				MITAD_DIA__NOCHECORTA_HOUR_adjust =		( MITAD_DIA__NOCHECORTA_MINS_adjust < 0 )
														?
															MITAD_DIA__NOCHECORTA_HOUR_adjust  -  1
														:
															MITAD_DIA__NOCHECORTA_HOUR_adjust;


				MITAD_DIA__NOCHECORTA_HOUR_adjust =		( MITAD_DIA__NOCHECORTA_HOUR_adjust >= 24 )
														?
															MITAD_DIA__NOCHECORTA_HOUR_adjust  -  24
														:
															MITAD_DIA__NOCHECORTA_HOUR_adjust;


				MITAD_DIA__NOCHECORTA_MINS_adjust =		( MITAD_DIA__NOCHECORTA_MINS_adjust < 0 )
														?
															60 - MITAD_DIA__NOCHECORTA_MINS_adjust
														:
															MITAD_DIA__NOCHECORTA_MINS_adjust;



				MITAD_DIA__NOCHECORTA_HOUR_adjust =		( MITAD_DIA__NOCHECORTA_HOUR_adjust < 10 )
														?
															"0"  +  String(  MITAD_DIA__NOCHECORTA_HOUR_adjust )
														:
															String(  MITAD_DIA__NOCHECORTA_HOUR_adjust  );


				MITAD_DIA__NOCHECORTA_MINS_adjust =		( MITAD_DIA__NOCHECORTA_MINS_adjust < 10 )
														?
															"0"  +  String(  MITAD_DIA__NOCHECORTA_MINS_adjust )
														:
															String(  MITAD_DIA__NOCHECORTA_MINS_adjust  );



				MITAD_DIA__NOCHECORTA_str =   String(  MITAD_DIA__NOCHECORTA_HOUR_adjust  )  +  ":"  +  String(  MITAD_DIA__NOCHECORTA_MINS_adjust  );


				//--



				NOCHELARGA_tsDELTA_DIV2 =  Math.floor(   NOCHELARGA_tsDELTA  /  2.0   );

				let NOCHELARGA_MITAD_DIA__HOUR =  Math.floor( NOCHELARGA_tsDELTA_DIV2  /  60  );
				let NOCHELARGA_MITAD_DIA__MINS =  ( NOCHELARGA_tsDELTA_DIV2  %  60  );



				let MITAD_DIA__NOCHELARGA_HOUR_adjust =		(  NOCHELARGA_sunRise_HOUR  -  NOCHELARGA_MITAD_DIA__HOUR  );

				MITAD_DIA__NOCHELARGA_HOUR_adjust =		( MITAD_DIA__NOCHELARGA_HOUR_adjust < 0 )
														?
															24 - MITAD_DIA__NOCHELARGA_HOUR_adjust
														:
															MITAD_DIA__NOCHELARGA_HOUR_adjust;



				let MITAD_DIA__NOCHELARGA_MINS_adjust =		(  NOCHELARGA_sunRise_MINS  -  NOCHELARGA_MITAD_DIA__MINS  );

				MITAD_DIA__NOCHELARGA_HOUR_adjust =		( MITAD_DIA__NOCHELARGA_MINS_adjust < 0 )
														?
															MITAD_DIA__NOCHELARGA_HOUR_adjust  -  1
														:
															MITAD_DIA__NOCHELARGA_HOUR_adjust;


				MITAD_DIA__NOCHELARGA_HOUR_adjust =		( MITAD_DIA__NOCHELARGA_HOUR_adjust >= 24 )
														?
															MITAD_DIA__NOCHELARGA_HOUR_adjust  -  24
														:
															MITAD_DIA__NOCHELARGA_HOUR_adjust;


				MITAD_DIA__NOCHELARGA_MINS_adjust =		( MITAD_DIA__NOCHELARGA_MINS_adjust < 0 )
														?
															60 + MITAD_DIA__NOCHELARGA_MINS_adjust // Previously was 60 - MITAD
														:
															MITAD_DIA__NOCHELARGA_MINS_adjust;



				MITAD_DIA__NOCHELARGA_HOUR_adjust =		( MITAD_DIA__NOCHELARGA_HOUR_adjust < 10 )
														?
															"0"  +  String(  MITAD_DIA__NOCHELARGA_HOUR_adjust )
														:
															String(  MITAD_DIA__NOCHELARGA_HOUR_adjust  );


				MITAD_DIA__NOCHELARGA_MINS_adjust =		( MITAD_DIA__NOCHELARGA_MINS_adjust < 10 )
														?
															"0"  +  String(  MITAD_DIA__NOCHELARGA_MINS_adjust )
														:
															String(  MITAD_DIA__NOCHELARGA_MINS_adjust  );



				MITAD_DIA__NOCHELARGA_str =   String(  MITAD_DIA__NOCHELARGA_HOUR_adjust  )  +  ":"  +  String(  MITAD_DIA__NOCHELARGA_MINS_adjust  );




				//-----

				/*
				//console.log(  NOCHECORTA_month  );
				//console.log(  NOCHECORTA_day  );

				console.log(  ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ]  );


				console.log(  NOCHECORTA_tsDELTA  );
				console.log(  NOCHECORTA_tsDELTA_DIV2  );

				console.log( "NOCHECORTA_sunset_HOUR_ts: "  +  NOCHECORTA_sunRise_HOUR );
				console.log( "NOCHECORTA_sunset_MINS_ts: "  +  NOCHECORTA_sunRise_MINS );

				//console.log(  "NOCHECORTA_MITAD_DIA__HOUR: "  +  NOCHECORTA_MITAD_DIA__HOUR  );
				//console.log(  "NOCHECORTA_MITAD_DIA__MINS: "  +  NOCHECORTA_MITAD_DIA__MINS  );

				console.log(  "MITAD_DIA__NOCHECORTA_str: "  +  MITAD_DIA__NOCHECORTA_str  );

				//-----

				//console.log(  NOCHELARGA_month  );
				//console.log(  NOCHELARGA_day  );

				console.log(  ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ]  );


				console.log(  NOCHELARGA_tsDELTA  );
				console.log(  NOCHELARGA_tsDELTA_DIV2  );

				console.log( "NOCHELARGA_sunset_HOUR_ts: "  +  NOCHELARGA_sunRise_HOUR );
				console.log( "NOCHELARGA_sunset_MINS_ts: "  +  NOCHELARGA_sunRise_MINS );

				//console.log(  "NOCHELARGA_MITAD_DIA__HOUR: "  +  NOCHELARGA_MITAD_DIA__HOUR  );
				//console.log(  "NOCHELARGA_MITAD_DIA__MINS: "  +  NOCHELARGA_MITAD_DIA__MINS  );

				console.log(  "MITAD_DIA__NOCHELARGA_str: "  +  MITAD_DIA__NOCHELARGA_str  );
				*/


				//---------------


				time_SUNSET_NOCHECORTA =  ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunset;

				time_SUNRISE_NOCHECORTA =  ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunrise;

				time_MITAD_NOCHECORTA =  MITAD_DIA__NOCHECORTA_str;

				//---

				time_SUNSET_NOCHELARGA =  ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunset;

				time_SUNRISE_NOCHELARGA =  ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunrise;

				time_MITAD_NOCHELARGA =  MITAD_DIA__NOCHELARGA_str;



				//------


				let aux_STR_hour =  "";
				let aux_STR_mins =  "";

				//let aux_INT_hour =  0;
				//let aux_INT_mins =  0;


				//---

				aux_STR_hour =  time_SUNSET_NOCHECORTA.split( ":" )[ 0 ];
				aux_STR_mins =  time_SUNSET_NOCHECORTA.split( ":" )[ 1 ];

				timestamp_minut_SUNSET_NOCHECORTA		=  (  parseInt(  aux_STR_hour  )  *  60  )  +  (  parseInt(  aux_STR_mins  )   )  -  (  const_hora_start_horario  *  60  );

				//---

				aux_STR_hour =  time_SUNRISE_NOCHECORTA.split( ":" )[ 0 ];
				aux_STR_mins =  time_SUNRISE_NOCHECORTA.split( ":" )[ 1 ];

				timestamp_minut_SUNRISE_NOCHECORTA 		=  (  parseInt(  aux_STR_hour  )  *  60  )  +  (  parseInt(  aux_STR_mins  )   )  +  const_timestamp_medianoche;


				//---

				aux_STR_hour =  time_MITAD_NOCHECORTA.split( ":" )[ 0 ];
				aux_STR_mins =  time_MITAD_NOCHECORTA.split( ":" )[ 1 ];

				timestamp_minut_MITAD_NOCHECORTA 		=  (  parseInt(  aux_STR_hour  )  *  60  )  +  (  parseInt(  aux_STR_mins  )   )  +  const_timestamp_medianoche;




				//---------------------------------------------------------



				aux_STR_hour =  time_SUNSET_NOCHELARGA.split( ":" )[ 0 ];
				aux_STR_mins =  time_SUNSET_NOCHELARGA.split( ":" )[ 1 ];

				timestamp_minut_SUNSET_NOCHELARGA		=  (  parseInt(  aux_STR_hour  )  *  60  )  +  (  parseInt(  aux_STR_mins  )   )  -  (  const_hora_start_horario  *  60  );

				//---

				aux_STR_hour =  time_SUNRISE_NOCHELARGA.split( ":" )[ 0 ];
				aux_STR_mins =  time_SUNRISE_NOCHELARGA.split( ":" )[ 1 ];

				timestamp_minut_SUNRISE_NOCHELARGA 		=  (  parseInt(  aux_STR_hour  )  *  60  )  +  (  parseInt(  aux_STR_mins  )   )  +  const_timestamp_medianoche;


				//---

				aux_STR_hour =  time_MITAD_NOCHELARGA.split( ":" )[ 0 ];
				aux_STR_mins =  time_MITAD_NOCHELARGA.split( ":" )[ 1 ];

				timestamp_minut_MITAD_NOCHELARGA 		=  (  parseInt(  aux_STR_hour  )  *  60  )  +  (  parseInt(  aux_STR_mins  )   )  +  const_timestamp_medianoche;



				//-------------------

				/*
				console.log( "time_SUNSET_NOCHECORTA: "  +  time_SUNSET_NOCHECORTA );
				console.log( "timestamp_minut_SUNSET_NOCHECORTA: "  +  timestamp_minut_SUNSET_NOCHECORTA );

				console.log( "time_MITAD_NOCHECORTA: "  +  time_MITAD_NOCHECORTA );
				console.log( "time_SUNRISE_NOCHECORTA: "  +  time_SUNRISE_NOCHECORTA );




				console.log( "time_SUNSET_NOCHELARGA: "  +  time_SUNSET_NOCHELARGA );
				console.log( "timestamp_minut_SUNSET_NOCHELARGA: "  +  timestamp_minut_SUNSET_NOCHELARGA );

				console.log( "time_MITAD_NOCHELARGA: "  +  time_MITAD_NOCHELARGA );
				console.log( "time_SUNRISE_NOCHELARGA: "  +  time_SUNRISE_NOCHELARGA );
				*/



				//--------------------------------------------------------------------------------------------------------------------------------
				//-------------------



				let aux_Today = new Date();


				let our_ind_DAY =  aux_Today.getDate()  -  1;

				let our_ind_MONTH =  aux_Today.getMonth()  +  0;		// ind:  0 ... 11

				let our_YEAR =	aux_Today.getFullYear();


				//console.log(   parseInt( our_ind_DAY + 1 )  +  " /  "  +  parseInt( our_ind_MONTH + 1 )  +  " / "  +  our_YEAR  );


				//---

				//console.log(  ar_sunset_sunrise_YEAR  );


				timestamp_minut_SUNSET_REAL = new Array();  // []
				timestamp_minut_SUNRISE_REAL = new Array();

				STR_time_SUNSET_REAL  = new Array();
				STR_time_SUNRISE_REAL = new Array();


				timestamp_total_de_Noche =  new Array();

				Mitad_tiempo_noche__ts = new Array();

				str_HOURMIN_tsABS__Mitad_Noche =  new Array();


				//--

				ar_obj_sunset_sunrise_13elems[ 0 ] =  ar_sunset_sunrise_YEAR[  our_ind_MONTH  ][  our_ind_DAY  ];

				//--

				for ( let imon = 1;  imon <= 12;  imon++ )	// imon = 1  ...  !!
				{

					ar_obj_sunset_sunrise_13elems.push(

														ar_sunset_sunrise_YEAR[  imon - 1 ][  15  ]				// DIA 16 DEL MES

					); // .push(

				}// for : imon ----------------------


				//console.log(  ar_obj_sunset_sunrise_13elems  );		// [ 0 ]







				//-------------------

				//--------------------------------------------





					// ........



					// ERROR QUE SALTA DE VEZ EN CUANDO:    ar_DAT_elora_M5__TEOR[  k  ].val =   m5_dat_vals_tramos_GRAFICA_TEOR[  1  ].porcent;




				//--------------------------------------------









				//-------
				/*
				// 1ero que hacemos en ésta sección (luego al final de ésta sección volvemos a hacer Render (ASI ARREGLAMOS QUE LA SUPERFICIE EN GRÁFICAS DE AMANECER Y ATARDECER SALGA BIEN))

				this.state.selected_installationgroup =  resultDATA;

				this.setState(
								{
									waiting:	false,

									selected_installationgroup:		resultDATA
								}

				); //.setState()
				*/




				for ( let imon = 0;  imon <= /*12*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0
				{

					//-------

					let	hour_sunset = 0, min_sunset = 0,
						hour_sunrise = 0, min_sunrise = 0; //,
						//aux_hour = 0, aux_min = 0;

					let aux_timez = [];



					//console.log( this.state.selected_installationgroup );



					aux_timez =  (  ar_obj_sunset_sunrise_13elems[ imon ][  "sunset"  ]  ).split( ':' );		//( resultDATA.info.weather.sun_info["sunset_local"] ).split( 'T' )[ 1 ].split( '+' )[ 0 ].split( ':' );


					hour_sunset = parseInt( aux_timez[ 0 ] );
					min_sunset =  parseInt( aux_timez[ 1 ] );


					//STR_time_SUNSET_REAL.push(  hour_sunset  +  ':'  +  min_sunset  );
					STR_time_SUNSET_REAL.push(  ar_obj_sunset_sunrise_13elems[ imon ][  "sunset"  ]  );




					// timestamp_minut_SUNSET_REAL[ 0 ]
					timestamp_minut_SUNSET_REAL.push(
														(
														( hour_sunset  -  const_hora_start_horario )  *  60
														)
														+
														min_sunset
					); // .push



					//----------------------------------------


					aux_timez = [];

					aux_timez =  (  ar_obj_sunset_sunrise_13elems[ imon ][  "sunrise"  ]  ).split( ':' );		//( resultDATA.info.weather.sun_info["sunrise_local"] ).split( 'T' )[ 1 ].split( '+' )[ 0 ].split( ':' );

					hour_sunrise = parseInt( aux_timez[ 0 ] );
					min_sunrise =  parseInt( aux_timez[ 1 ] );




					//STR_time_SUNRISE_REAL.push(  hour_sunrise  +  ':'  +  min_sunrise  );
					STR_time_SUNRISE_REAL.push(  ar_obj_sunset_sunrise_13elems[ imon ][  "sunrise"  ]  );


					// timestamp_minut_SUNRISE_REAL[ 0 ]
					timestamp_minut_SUNRISE_REAL.push(
														(
															(   hour_sunrise  +   const_horas_entre_comienzo_y_medianoche   )  *  60
														)
														+
														min_sunrise
					); // .push


					/*
					console.log(  ar_obj_sunset_sunrise_13elems[ imon ][  "sunset"  ]  );
					console.log(  STR_time_SUNSET_REAL[ imon ]  );

					console.log(  ar_obj_sunset_sunrise_13elems[ imon ][  "sunrise"  ]  );
					console.log(  STR_time_SUNRISE_REAL[ imon ]  );

					console.log( "----------------" );
					*/


					//--------





					timestamp_total_de_Noche.push(  timestamp_minut_SUNRISE_REAL[ imon ]  -  timestamp_minut_SUNSET_REAL[ imon ]  );



					Mitad_tiempo_noche__ts.push(  Math.round(  timestamp_total_de_Noche[ imon ]  /  2.0  )  );


					TS_ABS__Mitad_Noche.push(  timestamp_minut_SUNSET_REAL[ imon ]  +  Mitad_tiempo_noche__ts[ imon ]  );


					//---



					let aux_hh =  Math.floor(  TS_ABS__Mitad_Noche[ imon ]  /  60.0  )  +  const_hora_start_horario;

					aux_hh =  (  aux_hh  >=  24  )   ?   (  aux_hh - 24  )   :   aux_hh;

					aux_hh =  (  aux_hh  <  10  )   ?   (  "0"  +  aux_hh  )   :   aux_hh;


					let aux_mm =  (  TS_ABS__Mitad_Noche[ imon ]  %  60  );

					aux_mm = (  aux_mm  <  10  )   ?   (  "0"  +  aux_mm  )   :   aux_mm;


					//----


					str_HOURMIN_tsABS__Mitad_Noche.push(  aux_hh  +  ':'  +  aux_mm  );




				} // for : imon = 0;  imon <= 12; ------------------------------------------



				//--------------------------------------------------------------------------------------------------------------------------------------



				//-------


				/*
				var HORAS_TEORICAS_TOT =  14;

				var backup_val_HORAS_TEORICAS_TOT =  HORAS_TEORICAS_TOT;


				var MITAD_HORAS_TEORICAS =   parseFloat(   parseFloat( HORAS_TEORICAS_TOT )  /  2.0  );
				*/



				timestamp_minut_SUNSET_TEOR  = 	TS_ABS__Mitad_Noche[ 0 ]  -  ( MITAD_HORAS_TEORICAS * 60 );
				timestamp_minut_SUNRISE_TEOR = 	TS_ABS__Mitad_Noche[ 0 ]  +  ( MITAD_HORAS_TEORICAS * 60 );



				STR_time_SUNSET_TEOR  =  Math.floor(  ( (timestamp_minut_SUNSET_TEOR + ( const_hora_start_horario * 60 ))  /  60.0 ) < 10
													? "0" + ( (timestamp_minut_SUNSET_TEOR + ( const_hora_start_horario * 60 ))  /  60.0 )
													: ( (timestamp_minut_SUNSET_TEOR + ( const_hora_start_horario * 60 ))  /  60.0 )
													)
													 +  ':'  +
													(  ((timestamp_minut_SUNSET_TEOR + (const_hora_start_horario * 60))  %  60) < 10
													? "0" + ((timestamp_minut_SUNSET_TEOR + (const_hora_start_horario * 60))  %  60)
													: ((timestamp_minut_SUNSET_TEOR + (const_hora_start_horario * 60))  %  60)
													)

				STR_time_SUNRISE_TEOR =  Math.floor(  ( (timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche * 60))  /  60.0) < 10
													? "0" + ( (timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche * 60))  /  60.0)
													: ( (timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche * 60))  /  60.0)
													)
													+  ':'  +
													(  ((timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche * 60))  %  60) < 10
													? "0" + ((timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche * 60))  %  60)
													: ((timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche * 60))  %  60)
													);

				//window.alert( STR_time_SUNSET_TEOR + "  AND  "  + STR_time_SUNRISE_TEOR  );

				//--------



				//console.log(  "ATARDECER TS, AMANECER TS:  "  +  STR_time_SUNSET_REAL[ 0 ]  +  ",  " +  STR_time_SUNRISE_REAL[ 0 ] +  ",  TSs: "  +  timestamp_minut_SUNSET_REAL[ 0 ] + ", " + timestamp_minut_SUNRISE_REAL[ 0 ] + ",  DIF =  " +  timestamp_total_de_Noche[ 0 ]  );

				//console.log(  "timestamp_total_de_Noche[ 0 ], Mitad_tiempo_noche, TS_ABS__Mitad_Noche:  "  +  timestamp_total_de_Noche[ 0 ]  +  ",  " +  Mitad_tiempo_noche__ts +  ",  : "  +  TS_ABS__Mitad_Noche  );



				//----------------------------------------------------------------------------------------------------------------------------------------
				//----------------------------------------------------------------------------------------------------------------------------------------

				// AÑADIMOS ÉSTA AQUÍ (ASI ARREGLAMOS QUE LA SUPERFICIE EN GRÁFICAS DE AMANECER Y ATARDECER SALGA BIEN)

				glob_INIT__ARs_ChartData_M5();


				//----------------------------------------------------------------------------------------------------------------------------------------
				//----------------------------------------------------------------------------------------------------------------------------------------


				//console.log(  resultDATA.info.weather.sun_info  );

				// Object { sunrise_local: "2022-07-20   T   07:05  :08   +02:00", sunset_local: "2022-07-20T20:51:16+02:00" }


				//let t_genCurvDAT
				t_genCurvDAT =	generateCurveData(
													//data,		   ///   <----------------------------------------------------------------------------------------  COMENTADO SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA DECLARACION)


													resultDATA.info.weather.sun_info[ "sunrise_local" ],
													resultDATA.info.weather.sun_info[ "sunset_local" ]

													//resultDATA.info.weather.sun_info["sunrise_local"],
													//resultDATA.info.weather.sun_info["sunset_local"]

													//(   "T"	+  String( ar_obj_sunset_sunrise_13elems[ 0 ][  "sunrise"  ] )  +  ":00+00:00"   ),
													//(   "T"	+  String( ar_obj_sunset_sunrise_13elems[ 0 ][  "sunset"   ] )	 +  ":00+00:00"   )


								);// generateCurveData(


				//console.log( "Data generated" );



				//console.log(  t_genCurvDAT[ "rangos" ]  );
				//console.log(  t_genCurvDAT[ "data" ]  );


				//----

				// FINALMENTE LO COLOCAMOS AQUÍ (ASI ARREGLAMOS QUE LA SUPERFICIE EN GRÁFICAS DE AMANECER Y ATARDECER SALGA BIEN)


				this.state.selected_installationgroup =  resultDATA;


				//console.log(  resultDATA  );



				/*
				selected_installationgroup:	{
												group: "",

												id: "",

												group_name: "",

												streetlights: [],

												max_cpu_version: ""
				},
				*/


				this.setState(
								{
									selected_installationgroup:		resultDATA,

									waiting:	false

								}

				); //.setState()


			}// ( result ) =>
		).catch(
		//
					( error ) =>
					{

						this.setState(
										{
											error,
											waiting: false
										}

						)// .this.setState()

					}//
		//
		).finally(
		//
			( ) =>
			{
				/*
																// Object { sunrise_local: "2022-07-20   T   07:05  :08   +02:00", sunset_local: "2022-07-20T20:51:16+02:00" }
				let t_genCurvDAT =	generateCurveData(
							//
								//data,		   ///   <----------------------------------------------------------------------------------------  COMENTADO SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA DECLARACION)

								//resultDATA.info.weather.sun_info["sunrise_local"],
								//resultDATA.info.weather.sun_info["sunset_local"]

								//(   "T"	+  String( ar_obj_sunset_sunrise_13elems[ 0 ][  "sunrise"  ] )  +  ":00+00:00"   ),
								//(   "T"	+  String( ar_obj_sunset_sunrise_13elems[ 0 ][  "sunset"   ] )	 +  ":00+00:00"   )

							//
							);// generateCurveData(


				//console.log( "Data generated" );


				rangos_DIM = t_genCurvDAT["rangos"];
				data_DIM   = t_genCurvDAT["data"];
				*/



				rangos_DIM =	(   ( t_genCurvDAT != null )  &&  ( t_genCurvDAT != undefined )  &&  ( t_genCurvDAT != {} )   )
								?
									(   ( t_genCurvDAT[ "rangos" ] != null )  &&  ( t_genCurvDAT[ "rangos" ] != undefined )  &&  ( t_genCurvDAT[ "rangos" ].length >= 1 )   )
									?
										t_genCurvDAT[ "rangos" ].slice()		//t_genCurvDAT[ "rangos" ];
									:
										[]
								:
									[];


				data_DIM =		(   ( t_genCurvDAT != null )  &&  ( t_genCurvDAT != undefined )  &&  ( t_genCurvDAT != {} )   )
								?
									(   ( t_genCurvDAT[ "data" ] != null )  &&  ( t_genCurvDAT[ "data" ] != undefined )  &&  ( t_genCurvDAT[ "data" ].length >= 1 )   )
									?
										t_genCurvDAT[ "data" ].slice()		//t_genCurvDAT[ "rangos" ];
									:
										[]
								:
									[];



				//console.log(  rangos_DIM  );
				//console.log(  data_DIM  );



				//----


				if (  this.props.match.params.id  !==  '-1'  )
				{

					this.getScheduleProfile( this.props.match.params.id );		// --------------------------------->  getScheduleProfile( )

				}
				else
				{

					this.state.Chart_Data_M1 =  data_DIM; // AÑADIDO PERO SE PODRÍA QUITAR, PRUEBA


					this.setState(
									{
										Chart_Data_M1:  data_DIM
									}
					);//.setState

				}// else


				// Actualizando
				//  this.props.parent.updateOptionVisibility(this.state.installationgroups[0].max_cpu_version);

			}//

		//
		);//.finally()


		///------------------------------------------------------------------------------------------
		///------------------------------------------------------------------------------------------


	}//	getInstallationGroupDetail ( id, all ) ----------------------------------------




	//---------------------------------------------------------------------------
	//---------------------------------------------------------------------------



	getScheduleProfile ( id )
	{

		this.state.waiting = true;


		this.setState(
						{
							waiting: true,
							showSpinner: true
						}
		);//.setState()


		//----------

		axios.get(
					CONFIG.api_url  +  "/scheduleprofiles/"  +  id,

					{
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': "Token " + this.props.auth.token
									}
					}
		).then(
		//

			( result ) =>
			{

				const resultDATA =  result.data;


				//console.log( resultDATA );

				//---


				SCH_PROF__IS_TEMPLATE =  resultDATA.is_template;

				tipoPERFIL =  resultDATA.tipoPERFIL;


				//--


				let schProfile_resultDATA = [];


				schProfile_resultDATA =  resultDATA.json_data;

				/*
				console.log(  "schProfile_resultDATA: "  );
				console.log(  schProfile_resultDATA  );


				console.log(  "resultDATA.json_data_AR_SchProf: "  );
				console.log(  resultDATA.json_data_AR_SchProf  );
				*/


				//--------------------------------


				if (

					(  this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]  )   ||

					//(  resultDATA.tipoPERFIL  ==  1  )

					(  tipoPERFIL  ==  1  )

				)		// 3
				{



					// Actualizamos sunset y sunrise
					if (  data_DIM.length > 10  )
					{
						//window.alert( "NOOOOOOO" );


						for (  let i = 0;  i < data_DIM.length;  i++  )
						{
							schProfile_resultDATA[i]["sun"]  = data_DIM[i].sun;
							schProfile_resultDATA[i]["sun0"] = data_DIM[i].sun0;

							schProfile_resultDATA[i]["name"] = rangos_DIM[i][0];
						}//for

					}// if

					//console.log( schProfile_resultDATA );



				}// if
				else
				{


					//window.alert( "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA" );

					//let new_Dim_json =  resultDATA.json_data;


					ar_Struct_DIMMING_save =  [];

					ar_Struct_DIMMING_save =  resultDATA.json_data;				//  <-----------------



					//console.log( ar_Struct_DIMMING_save  );


					//window.alert( "EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE" );

					//console.log(  "_______ar_Struct_DIMMING_save: "  );
					//console.log(  ar_Struct_DIMMING_save  );
					//console.log(  JSON.stringify(  ar_Struct_DIMMING_save  )  );


					//---

					if (  (  tipoPERFIL  ==  5  )  )
					{

						if (
								(  ar_Struct_DIMMING_save[ 0 ].LT  !=  null  )  &&
								(  ar_Struct_DIMMING_save[ 0 ].LT  !=  undefined  )
						)
						{
							//window.alert(  (ar_Struct_DIMMING_save[ 0 ].LT).split( ':' )[ 0 ]  );


							//window.alert( ar_Struct_DIMMING_save.length );


							for (  let i = 0;  i  <  ar_Struct_DIMMING_save.length;  i++  )
							{


								let ax_Hs =  parseInt(  ( ar_Struct_DIMMING_save[ i ].LT ).split( ':' )[ 0 ]  );

								let ax_Ms =  parseInt(  ( ar_Struct_DIMMING_save[ i ].LT ).split( ':' )[ 1 ]  );

								//-----


								for ( let imon = 0;  imon <= EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0	!!!!!!! 	// <= 12
								{

									m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ i ].ts_mins =  ( ax_Hs *  60 )  +  ax_Ms;

									m5_dat_vals_tramos_GRAFICA_REAL[ imon ][ i ].porcent =  parseInt(  ar_Struct_DIMMING_save[ i ].LD  );


								} // for ( let imon = 0;  imon <= EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0	!!!!!!! 	// <= 12




								//-------


								m5_dat_vals_tramos_GRAFICA_TEOR[ i ].ts_mins =  ( ax_Hs *  60 )  +  ax_Ms;

								m5_dat_vals_tramos_GRAFICA_TEOR[ i ].porcent =  parseInt(  ar_Struct_DIMMING_save[ i ].LD  );


								//-------


								m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ i ].ts_mins =  ( ax_Hs *  60 )  +  ax_Ms;

								m5_dat_vals_tramos_GRAFICA_NOCHECORTA[ i ].porcent =  parseInt(  ar_Struct_DIMMING_save[ i ].LD  );


								//-------


								m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ i ].ts_mins =  ( ax_Hs *  60 )  +  ax_Ms;

								m5_dat_vals_tramos_GRAFICA_NOCHELARGA[ i ].porcent =  parseInt(  ar_Struct_DIMMING_save[ i ].LD  );


							}// for : i

						}// if

					}// if (  (  tipoPERFIL  ==  5  )  )
					else
					{	// if (  (  tipoPERFIL  ==  3  )  )


						window.alert( "MODE 3" );


						/*
							LT10
							LT11
							LD1

							LT20
							LT21
							LD2

							...
						*/


						/*
						[
							{
							  "LT": "05:00",
							  "LD": "090"
							},

							{
							  "LT": "03:00",
							  "LD": "030"
							},

							{
							  "LT": "00:00",
							  "LD": "000"
							},

							{
							  "LT": "00:00",
							  "LD": "000"
							},

							{
							  "LT": "07:00",
							  "LD": "030"
							},

							{
							  "LT": "01:00",
							  "LD": "090"
							}
						  ]
						  */



						  //	ar_Struct_DIMMING_save =  resultDATA.json_data;


						  /*

						  var m3_dat_vals_tramos_GRAFICA	=
						  [
							  {  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
							  {  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
							  {  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },

							  {  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
							  {  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
							  {  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  }
						  ];

						  */

					}// else


				}// else

				//------------------------------------------------------------------------------------------



				this.setState(
								{
									waiting:				false,

									scheduleprofile:		resultDATA,

									profileName:			resultDATA.name,
									profileComments:		resultDATA.comments,

									wait_in:				resultDATA.wait_in,

									time_to_wait:			resultDATA.time_to_wait,


									dimming:				resultDATA.dimming,



									Chart_Data_M1:			schProfile_resultDATA,			//(  this.state.selected_installationgroup.info.max_cpu_version  !=  3  )		?		schProfile_resultDATA	:	[],



									showSpinner:			false
								}
				); //


				//console.log(  schProfile_resultDATA  );

				/*
				console.log( "_________resultDATA.json_data: " );
				console.log( resultDATA.json_data );


				console.log( "_________resultDATA.dimming: " );
				console.log( resultDATA.dimming );
				*/
			}

		//
		).catch(
		//
			error =>

				this.setState(
								{
									error,

									waiting: false
								}
				)
		//
		);//.catch()

	}// getScheduleProfile ( id )



	//-------------------------------------------------



	checkValue ( )
	{

		let totalAConsumir = 0.0;
		let totalPower = this.state.selected_installationgroup.installations[ 0 ].mean_power;
		let totalCapacity = this.state.selected_installationgroup.installations[ 0 ].mean_capacity;


		this.state.Chart_Data_M1.forEach(
		//

			( item, index ) =>
			{
				totalAConsumir +=  totalPower * ( item.sp / 100 ) * 0.5;
			}//

		//
		);//.forEach()


		let hours = this.state.time_to_wait;


		if (  this.state.wait_in == "seconds" )
		{
			hours = hours / 3600;
		}
		else
		{
			hours = hours / 60;
		}// if


		let power_per_detections =  this.state.selected_installationgroup.installations[ 0 ].estimacion_detecciones  *  hours;


		if (  totalAConsumir + power_per_detections  >  totalCapacity  )
		{
			this.setState(  { showAlert: true }  );
		}
		else
		{
			this.setState(  { showAlert: false }  );
		}// if


	}// checkValue ( )



	//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------



	componentDidMount ( )
	{

		let initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );

		const this_ =  this;


		// let
		const GROUP_ID = this.props.location.search.split(  "installationgroup_id="  )[ 1 ];

		//console.log( GROUP_ID );


		//---------------------------------------------------------------------------




		initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );


		data_DIM  = [];
		rangos_DIM = [];



		SCH_PROF__IS_TEMPLATE = false;



		//-----------------------------



		var m3_dat_vals_tramos_GRAFICA	=
											[
												{  ts_mins: ( 4 * 60 ) + 0,		   abs_timestamp_mins__START:    60,    abs_timestamp_mins__END:    300,   		porcent: 100  },		// 18-22 :: 100%;		delta:  4h
												{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   300,    abs_timestamp_mins__END:    420,   		porcent:  30  },		// 22-00 :: 30%;		delta:  2h
												{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   420,    abs_timestamp_mins__END:    540,   		porcent:  30  },		// 00-02 :: 30%;		delta:  2h

												{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   540,    abs_timestamp_mins__END:    660,   		porcent:  30  },		// 02-04 :: 30%;		delta:  2h
												{  ts_mins: ( 3 * 60 ) + 0,		   abs_timestamp_mins__START:   660,    abs_timestamp_mins__END:    780,   		porcent:  30  },		// 04-07 :: 30%;		delta:  3h
												{  ts_mins: ( 2 * 60 ) + 0,		   abs_timestamp_mins__START:   780,    abs_timestamp_mins__END:    960,   		porcent: 100  }			// 07-09 :: 100%;		delta:  2h
											];



		//-----------------------------


		/*
		m5_dat_vals_tramos_GRAFICA_REAL	=
												[
													{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
													{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


													{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
													{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
													{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
													{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
												];
		*/

		m5_dat_vals_tramos_GRAFICA_REAL = new Array();

		for ( let imon = 0;  imon <= /*12*/ EXTRA_MONTHS_CALC;  imon++ )		//  imon = 0
		{

			m5_dat_vals_tramos_GRAFICA_REAL.push(

													[
														{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
														{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


														{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
														{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
														{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
														{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
													]

			); // .push(

		} // for ( let imon = 0;  imon <= 12;  imon++ )		//  imon = 0



		//--------------------------


		m5_dat_vals_tramos_GRAFICA_TEOR	=
												[
													{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
													{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


													{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
													{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
													{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
													{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
												];




		m5_dat_vals_tramos_GRAFICA_NOCHECORTA	=
													[
														{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
														{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


														{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
														{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
														{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
														{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
													];


		m5_dat_vals_tramos_GRAFICA_NOCHELARGA	=
													[
														{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
														{  ts_mins: ( 3 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


														{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
														{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
														{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
														{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
													];


		ar_Struct_DIMMING_save =  [];




		//------

		//STR_time_SUNRISE_REAL = "";
		STR_time_SUNRISE_REAL = new Array();
		//STR_time_SUNRISE_REAL.push( "" );


		//STR_time_SUNSET_REAL  = "";
		STR_time_SUNSET_REAL  = new Array();
		//STR_time_SUNSET_REAL.push( "" );



		timestamp_minut_SUNSET_REAL = new Array();  // []
		//timestamp_minut_SUNSET_REAL[ 0 ]
		//timestamp_minut_SUNSET_REAL.push( 0 );


		timestamp_minut_SUNRISE_REAL = new Array();
		//timestamp_minut_SUNRISE_REAL = 0;
		//timestamp_minut_SUNRISE_REAL.push( 0 );

		//-----


		STR_time_SUNRISE_TEOR = "";		// 7:57
		STR_time_SUNSET_TEOR  = "";		// 17:57

		timestamp_minut_SUNSET_TEOR = 0;
		timestamp_minut_SUNRISE_TEOR = 0;


		//---------------------------------




		// ELora V1   -->  elorav1   = como se le llama ahora a CPU 3

		ar_DAT_elora_M5__REAL = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos

		ar_DAT_elora_M5__TEOR = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos



		ar_DAT_elora_M5__NOCHECORTA = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos

		ar_DAT_elora_M5__NOCHELARGA = new Array( /*17 * 60*/ );		// 17 horas ( de 17:00h a 00:00h y de 00:00h a 10:00h ) *  60 minutos


		//------


		HORAS_TEORICAS_TOT =  14;

		backup_val_HORAS_TEORICAS_TOT =  HORAS_TEORICAS_TOT;


		MITAD_HORAS_TEORICAS =   parseFloat(   parseFloat( HORAS_TEORICAS_TOT )   /   2.0   );



		//------

		//timestamp_total_de_Noche  =  0;
		timestamp_total_de_Noche  =  new Array();
		//timestamp_total_de_Noche.push( 0 );



		//Mitad_tiempo_noche__ts = 0;
		Mitad_tiempo_noche__ts = new Array();
		//Mitad_tiempo_noche__ts.push( 0 );


		//TS_ABS__Mitad_Noche = 0;
		TS_ABS__Mitad_Noche = [];
		//TS_ABS__Mitad_Noche.push( 0 );


		//---

		//str_HOURMIN_tsABS__Mitad_Noche =  "";
		str_HOURMIN_tsABS__Mitad_Noche =  [];
		//str_HOURMIN_tsABS__Mitad_Noche.push( "" );



		TS_START_grafica =  0;

		TS_FINAL_grafica =  0;


		//---

		CONT_compDidMount =  0;


		str_hora_Linea_IZQ_1_2 = "";

		str_hora_Linea_DCHA_1_3 = "";
		str_hora_Linea_DCHA_3_4 = "";
		str_hora_Linea_DCHA_4_2 = "";



		// --------------

		//glob_chart_data = {};

		// --------------


		ar_sunset_sunrise_YEAR =  [];


		//-------------------------------------------------------




		time_SUNSET_NOCHECORTA = "";
		time_MITAD_NOCHECORTA = "";
		time_SUNRISE_NOCHECORTA = "";

		time_SUNSET_NOCHELARGA = "";
		time_MITAD_NOCHELARGA = "";
		time_SUNRISE_NOCHELARGA = "";



		timestamp_minut_SUNRISE_NOCHECORTA 		= 0;
		timestamp_minut_MITAD_NOCHECORTA 		= 0;
		timestamp_minut_SUNSET_NOCHECORTA		 = 0;

		timestamp_minut_SUNRISE_NOCHELARGA 		= 0;
		timestamp_minut_MITAD_NOCHELARGA 		= 0;
		timestamp_minut_SUNSET_NOCHELARGA		 = 0;


		/*
		time_SUNRISE_NOCHECORTA = "";
		time_SUNSET_NOCHECORTA  = "";

		timestamp_minut_SUNSET_NOCHECORTA = 0;
		timestamp_minut_SUNRISE_NOCHECORTA = 0;


		time_SUNRISE_NOCHELARGA = "";
		time_SUNSET_NOCHELARGA  = "";

		timestamp_minut_SUNSET_NOCHELARGA = 0;
		timestamp_minut_SUNRISE_NOCHELARGA = 0;
		*/

		//---------------------------------------


		//------------------------------

		NOCHECORTA_month =  -1;
		NOCHECORTA_day   =  -1;



		MITAD_DIA__NOCHECORTA_str =  "";

		MITAD_DIA__NOCHELARGA_str =  "";


		//---

		NOCHELARGA_month =  -1;
		NOCHELARGA_day   =  -1;


		//------



		//---------------------------------------------------------------------------





		tipoPERFIL =  0;	//1;	// UN INI POR DEFECTO


		//------

		/*
		console.log( "GROUP_ID: " + GROUP_ID );


		console.log( "this.props.location =  "  +  JSON.stringify( this.props.location )  );

		console.log( "this.props.location.search =  "  +  this.props.location.search  );


		console.log( "this.props.match =  "  +  JSON.stringify( this.props.match ) );

		console.log( "this.props.match.params =  "  +  JSON.stringify( this.props.match.params ) );


		console.log( "this.props.match.params.id =  "  +  this.props.match.params.id  );

		*/






		this.getInstallationGroupDetail( GROUP_ID );		// --------------------------------->  ggetInstallationGroupDetail( )












		if (  this.props.match.params.id  !==  '-1'  )
		{
			//console.log( "Reading" );
		}
		else
		{
			this.state.wait_in = "seconds";

			this.state.time_to_wait =  10;	 //0;

			this.state.profileName = "Schedule";


			if (  this.upperSlider  )
			{
				this.upperSlider.setState( { value: 0 } );
			}


			this.setState(
							{
								profileName:"Schedule",
								showSpinner: false
							}
			);//setState

		}//if -----------------------------

		//---------------------------------------------



		if (  this.state.wait_in == "seconds"  )
		{
			setTimeout(
							( ) =>
							{
								$( "#option2" ).click();
							}//

							, 500
			);//
		}
		else
		{
			setTimeout(
						( ) =>
						{
							$( "#option1" ).click();
						}//

						, 500
			);//
		}//


		$( document ).keydown(
								function ( event )
								{
									if (  event.which  ==  "17"  )
									{
										this_.state.cntrlIsPressed = true;
									}//

									if (  event.which  ==  "16"  )
									{
										this_.state.capsIsPressed = true;
									}//

								}//
		);//


		$( document ).keyup(
								function ( )
								{
									this_.state.cntrlIsPressed = false;
									this_.state.capsIsPressed = false;
									this_.state.initialCaps = null;
								}//
		);//



		//-----------------------------------------------------------------------
		//-----------------------------------------------------------------------




		//---


		setTimeout(

						( ) =>
						{
							//ar_DAT_elora_M3__REAL = new Array( /*17 * 60*/ );



							if ( this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]  )
							{

								if (( tipoPERFIL  ==  3 ))
								{

									glob_INIT__ARs_ChartData_M3 ();

									this.FROM_ARs__SET_ALL_CURVE_VALUES__M3();

								}// if : tipoPerfil == 3


								//---


								if (( tipoPERFIL  ==  5 ))
								{

									glob_INIT__ARs_ChartData_M5();


									//-----------------------------------------------------------------------


									//glob_INIT__All_INPUTS__VALS___Mode_5();

									// SERGIO DESCOMENTANDO 12/01/23
									glob_INIT__All_INPUTS__VALS___Mode_5();


									//----


									this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

								}// if : tipoPerfil == 5


								//glob_INIT__ARs_ChartData_M3 ();


								//INIT_SetValues_Inputs_generales_HTeor_HReals__Mode_3();

								//-------

								//ar_DAT_elora_M3__REAL = new Array( /*17 * 60*/ );

								//glob_INIT__ARs_ChartData_M3 ();


								//-------


								//FROM_ARs__SET_ALL_CURVE_VALUES__M5();


							}// if


							//----

							/*
							if (
									( tipoPERFIL  ==  3 )
									&&
									( this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]  )
							)
							{
								this.FROM_ARs__SET_ALL_CURVE_VALUES__M3();

							}//



							//--


							if (
									( tipoPERFIL  ==  5  )
									&&
									( this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ] )
							)
							{
								this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

							}//
							*/



							//---


							if (  document.getElementById(  "id_Tiempo_Espera_SensorPresen"  )  !=  null  )
							{
								document.getElementById(  "id_Tiempo_Espera_SensorPresen"  ).value =  this.state.time_to_wait;
							}//

							//---

							if (  document.getElementById(  "id_porc_Dimming"  )  !=  null  )
							{
								document.getElementById(  "id_porc_Dimming"  ).value =  this.state.dimming;
							}//

							//------------------
						}//

						, 1500		//1000  		//2000
		);//


		CONT_compDidMount ++;


		//console.log( "(" + CONT_compDidMount + ")____timestamp_minut_SUNSET_TEOR: "  +  timestamp_minut_SUNSET_TEOR  );

		//window.alert(  "____timestamp_minut_SUNSET_TEOR: " + timestamp_minut_SUNSET_TEOR   );


		//console.log(  ar_DAT_elora_M5__REAL  );


		ready_toRENDER =  true;


		this.setState(
						{
							ready_toRENDER:  true
						}
		);


		//glob_INIT__ARs_ChartData_M3 ();


		//-------------------------




	}//componentDidMount ( ) ---------------------------------------------------------------------------------------------------------------------------------------------------



	//-------------------------------------------------


	zoom ( )
	{
		//console.log( "-------" );

	}//zoom ( )



	//-------------------------------------------------



	showSpinner ( )
	{
		if (  !this.state.showSpinner  )
		{
			return (  <div/>  )
		}//

		return(

				<div style={{position: "fixed", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "9999999"}}>

					<div style={{position: "absolute", left: "48%", top: "45%"}}>
						<Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>

				</div>
		);//

	}//showSpinner( )



	//-------------------------------------------------





	render ( )
	{

		const this_= this;

		let refAreaLeft = null;
		let refAreaRight = null;



		//window.alert(  "m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ 0 ].ts_mins:  "  +  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ 0 ].ts_mins  );


		//---------------------------------------------------------------------------------------------------------------------
		//---------------------------------------------------------------------------------------------------------------------


		/*

		function guardarPerfil_3 ( ret_function )
		{

			this_.setState(  { showSpinner: true }  );

			let info = {};

			info["id"] = this_.state.id;
			info["name"] = this_.state.profileName;
			info["comments"] = this_.state.profileComments;

			info["json_data"] = this_.state.Chart_Data_M1;				//Chart_Data_M1;

			info["installationgroup_id"] = this_.state.selected_installationgroup.id;
			info["wait_in"] = this_.state.wait_in;
			info["time_to_wait"] = this_.state.time_to_wait;

			info["dimming"] = this_.state.dimming;



			// Get Image

			$("#id_RP_mychart_cpu_1_2").css( "width", "300px" );			// "chart"


			$($(".recharts-line>path")[0]).attr( "stroke-width", 3 );
			$($(".recharts-line>path")[0]).attr( "stroke-height", 3 );

			$(".recharts-area").hide();
			$(".recharts-cartesian-grid").hide();
			$(".recharts-xAxis").hide();
			$(".recharts-yAxis").hide();
			$(".recharts-line-dot").hide();


			setTimeout(
			//
				( ) =>
				{
					domtoimage.toBlob(

						document.getElementById( "id_RP_mychart_cpu_1_2" )		// 'chart'

					).then(
					//
						function ( blob )
						{
							var reader = new FileReader( );

							reader.readAsDataURL( blob );


							reader.onloadend =	function()
												{
													let base64data = reader.result;

													info["image_blob"] = base64data;

													this_.props.saveProfile( this_.props.auth.token, info );

													setTimeout(
													//
														( ) =>
														{
															ret_function();
														}

														, 2000
													);//

												}//

						}//

					);
				}

				, 800
			);

		}//

		*/


		//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------[



		function guardarPerfil ( ret_function )
		{

			//console.log( "FUNC GUARDAR PERFIL" );

			//window.alert( "FUNC GUARDAR PERFIL" );



			this_.setState(
								{ showSpinner: true }

			); // .setState(



			let info = {};


			//console.log(  "tipoPERFIL: " + tipoPERFIL  );
			//console.log(  this_.state.selected_installationgroup.info.max_cpu_version  );



			let str_ID_graph =	(
									(  tipoPERFIL  ==  1  )   ||

									(  this_.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]  )	// 3
								)
								?
									'id_RP_mychart_cpu_1_2'
								:
									'id_RP_mychart_cpu_elorav1_A';



			info["id"] =					this_.state.id;
			info["name"] = 					this_.state.profileName;
			info["comments"] = 				this_.state.profileComments;



			info[ "json_data_AR_SchProf" ] = [];


			//-----



			if (
					(  tipoPERFIL  ==  1  )   ||

					(  this_.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]  )
			)		// 3
			{


				//console.log(  this_.state.Chart_Data_M1  );


				info["json_data"] = 				this_.state.Chart_Data_M1;

				info["json_data_AR_SchProf"] = 		[];									//this_.state.Chart_Data_M1;			//[];


			}// if
			else
			{

				/*var*/ ar_Struct_DIMMING_save =  [];


				if (  (  tipoPERFIL  ==  5  )  )
				{

					//window.alert(  tipoPERFIL  );

					/*
					var m5_dat_vals_tramos_GRAFICA_REAL[ 0 ]	=
					[
						{  ts_mins: ( 5 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  },
						{  ts_mins: ( 2 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },


						{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
						{  ts_mins: ( 0 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 0    },
						{  ts_mins: ( 6 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 30   },
						{  ts_mins: ( 1 * 60 ) + 0,   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   porcent: 100  }
					];
					*/


					for (  let i = 0;  i  <  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ].length;  i++  )
					{

						let aux_H =  Math.floor(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].ts_mins  /  60.0  );

						aux_H =		(  aux_H  <  10  )
									?
										'0'  +  aux_H
									:
										aux_H;



						let aux_M =  (  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].ts_mins  %  60  );

						aux_M =  (  aux_M  <  10  )
								?
									'0'  +  aux_M
								:
									aux_M;



						ar_Struct_DIMMING_save.push(
														{
															LT:		aux_H    +    ':'	+	aux_M,


															LD:		(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent  <  100  )
																	?
																		(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent  >=  10  )
																		?
																			( '0'	+	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent )
																		:
																			( '00'	+	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent )
																	:
																		"" + m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent + ""

														}
						);// push() ---------------------


					}// for : i --------------------------------------------------------------------------]

					//---

					//console.log(  ar_Struct_DIMMING_save  );


					//console.log(  ar_DAT_elora_M5__REAL  );


					/*
					ar_DAT_elora_M5__REAL[ 0 ].push(
															{


																timestamp:  k, 		// 60,		// timestamp en MINUTOS

																time_hour:  aux_horass  +  ':'  +  aux_minss,



																val_sunrise_sunset__REAL:		(  aux_cond_SUNRISE_SUNSET__real  ==  true  )   ?   100  :  0,

																val_sunrise_sunset__TEOR:		(  aux_cond_SUNRISE_SUNSET__TEOR  ==  true  )   ?   100  :  0
															}
					);// .push()
					*/

					/*
					time_hour: "17:00"
					timestamp: 0
					val: 0
					val_sunrise_sunset__REAL: 0
					val_sunrise_sunset__TEOR: 0
					*/

					let ar_NEW_INTERVALS =  new Array();

					//--

					for ( let ar_i = 0;  ar_i <= 12; ar_i++ )										// ar_i = 0  !!!!
					{

						//---

						ar_NEW_INTERVALS.push(

												new Array()

						 ); // .push(


						let timestamp_INTERVAL_INI =   ar_DAT_elora_M5__REAL[ ar_i ][ 0 ].timestamp;
						let str_time_hour_INTERVAL_INI =   ar_DAT_elora_M5__REAL[ ar_i ][ 0 ].time_hour;


						let timestamp_INTERVAL_END =   ar_DAT_elora_M5__REAL[ ar_i ][ 0 ].timestamp;		// TAMBIÉN SE COGE POS "0" PARA ENTRAR AL BUCLE
						let str_time_hour_INTERVAL_END =   ar_DAT_elora_M5__REAL[ ar_i ][ 0 ].time_hour;	// TAMBIÉN SE COGE POS "0" PARA ENTRAR AL BUCLE

						let last_interval_VAL =  ar_DAT_elora_M5__REAL[ ar_i ][ 0 ].val;


						//---

						for (  let our_k = 1;  our_k <  ar_DAT_elora_M5__REAL[ ar_i ].length;  our_k ++   )		//  our_k = 1;   !!!!!!
						{


							if (  ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].val  !=  ar_DAT_elora_M5__REAL[ ar_i ][ our_k - 1 ].val  )
							{


								ar_NEW_INTERVALS[ ar_i /*- 1*/ ].push(

																{

																	timestamp_INI:			timestamp_INTERVAL_INI,
																	str_time_hour_INI:		str_time_hour_INTERVAL_INI,


																	timestamp_END:			timestamp_INTERVAL_END,
																	str_time_hour_END:		str_time_hour_INTERVAL_END,


																	interval_VAL:			last_interval_VAL		//ar_DAT_elora_M5__REAL[ ar_i ][ our_k - 1 ].val

																}
								); // .push(


								//----


								timestamp_INTERVAL_INI =   ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].timestamp;
								str_time_hour_INTERVAL_INI =   ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].time_hour;


								timestamp_INTERVAL_END =   ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].timestamp;		// TAMBIÉN SE COGE POS "0" PARA ENTRAR AL BUCLE
								str_time_hour_INTERVAL_END =   ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].time_hour;	// TAMBIÉN SE COGE POS "0" PARA ENTRAR AL BUCLE


								last_interval_VAL =  ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].val;

							} // if
							else
							{

								timestamp_INTERVAL_END =   ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].timestamp;
								str_time_hour_INTERVAL_END =   ar_DAT_elora_M5__REAL[ ar_i ][ our_k ].time_hour;

							}// else



						}// for (  let our_k = 1;  our_k <  ar_DAT_elora_M5__REAL[ ar_i ].length;  our_k ++   )		//  our_k = 1;   !!!!!!


						//--


						ar_NEW_INTERVALS[ ar_i /*- 1*/  ].push(

																		{

																			timestamp_INI:			timestamp_INTERVAL_INI,
																			str_time_hour_INI:		str_time_hour_INTERVAL_INI,


																			timestamp_END:			timestamp_INTERVAL_END,
																			str_time_hour_END:		str_time_hour_INTERVAL_END,


																			interval_VAL:			last_interval_VAL

																		}

						); // .push(


					} // for ( let ar_i = 0;  ar_i <= 12; ar_i++ )  	 // ar_i = 0  !!!!






					//----


					//console.log(   ar_NEW_INTERVALS   );












					//--

					//let str_JSON_ar = JSON.stringify(  ar_DAT_elora_M5__REAL  );			//.split();


					//console.log(  ar_DAT_elora_M5__REAL/*[ 0 ]*/  );


					//console.log(  str_JSON_ar  );


					//let ar_new_aux = [];


					//info["json_data"] =
					//info[ "json_data_AR_SchProf_1" ] = [];


					info[ "json_data_AR_SchProf" ] = [];


					for ( let ar_i = 0;  ar_i <= 12; ar_i++ )   // ar_DAT_elora_M5__REAL.length
					{

						//ar_new_aux.push(

						info[ "json_data_AR_SchProf" ].push(
																ar_NEW_INTERVALS[ ar_i ]

																	//JSON.stringify(  ar_DAT_elora_M5__REAL[ ar_i ]  )
						); // .push(

					}// for


					//console.log(  info[ "json_data_AR_SchProf" ]  );


					//----


					//info[ "json_data_AR_SchProf_2" ] = [];

					/*
					for ( let ar_i = 6;  ar_i <= 12; ar_i++ )				//  3 !!!!
					{

						//ar_new_aux.push(

						info[ "json_data_AR_SchProf_2" ].push(
																	ar_DAT_elora_M5__REAL[ ar_i ]

																	//JSON.stringify(  ar_DAT_elora_M5__REAL[ ar_i ]  )
						); // .push(

					}// for
					*/







					//info[ "json_data_AR_SchProf_1" ] = 		ar_new_aux;		//ar_DAT_elora_M5__REAL[ 0 ];		//str_JSON_ar;			//ar_DAT_elora_M5__REAL;		//ar_Struct_DIMMING_save;		//[];		//ar_DAT_elora_M5__REAL;			//  M5 !


					//----

					/*
					ar_new_aux = new Array();
					ar_new_aux = [];


					for ( let ar_i = 6;  ar_i < 12; ar_i++ )
					{

						ar_new_aux.push(
											ar_DAT_elora_M5__REAL[ ar_i ]
						); // .push(

					}// for


					info[ "json_data_AR_SchProf_2" ] = 		ar_new_aux;		//ar_DAT_elora_M5__REAL[ 0 ];		//str_JSON_ar;			//ar_DAT_elora_M5__REAL;		//ar_Struct_DIMMING_save;		//[];		//ar_DAT_elora_M5__REAL;			//  M5 !

					*/
					//----


					//let str_JSON_ar = JSON.stringify(  ar_new_aux  );			//.split();


					//console.log(  str_JSON_ar.length  );

					//console.log(  ar_new_aux  );



				}// if (  (  tipoPERFIL  ==  5  )  )
				else
				{	// if (  (  tipoPERFIL  ==  3  )  )


					/*

					var m3_dat_vals_tramos_GRAFICA	=
									[
										{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
										{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
										{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },

										{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
										{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  },
										{  ts_mins: ( 0 * 60 ) + 0,		   abs_timestamp_mins__START:  0,   abs_timestamp_mins__END:  0,   		porcent: 0  }
									];

					*/

					/*
					for (  let i = 0;  i  <  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ].length;  i++  )
					{

						let aux_H =  Math.floor(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].ts_mins  /  60.0  );

						aux_H =		(  aux_H  <  10  )
									?
										'0'  +  aux_H
									:
										aux_H;



						let aux_M =  (  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].ts_mins  %  60  );

						aux_M =  (  aux_M  <  10  )
								?
									'0'  +  aux_M
								:
									aux_M;



						ar_Struct_DIMMING_save.push(
														{
															LT:		aux_H    +    ':'	+	aux_M,


															LD:		(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent  <  100  )
																	?
																		(  m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent  >=  10  )
																		?
																			( '0'	+	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent )
																		:
																			( '00'	+	m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent )
																	:
																		"" + m5_dat_vals_tramos_GRAFICA_REAL[ 0 ][ i ].porcent + ""

														}
						);// push()


					}// for : i --------------------------------------------------------------------------]


					//---

					*/


					info["json_data_AR_SchProf"] = 	[];						//ar_DAT_elora_M5__REAL;		//  M5 !!!    // ar_Struct_DIMMING_save;

					//info["json_data_AR_SchProf_2"] = 	[];					//ar_DAT_elora_M5__REAL;		//  M5 !!!    // ar_Struct_DIMMING_save;


				} // else --------------------------------------


				//-----------






				info["json_data"] = 				ar_Struct_DIMMING_save;





				//--

				// PUESTO ARRIBA

				//info["json_data_AR_SchProf_1"] = 	ar_DAT_elora_M5__REAL;		//  M5 !!!    // ar_Struct_DIMMING_save;



			}// else ---------




			//----

			info["installationgroup_id"] =	this_.state.selected_installationgroup.id;

			info["wait_in"] = 				this_.state.wait_in;
			info["time_to_wait"] = 			this_.state.time_to_wait;


			info["dimming"] = 				this_.state.dimming;


			//console.log(  tipoPERFIL  );

			info["tipoPERFIL"] = 				tipoPERFIL;	//this_.state.dimming;



			//--------------------------------------

			// Get Image




			//$( "svg" ).id = "id_aaaaa";

			//$( "svg" ).css( "width", "800px" );	// 380
			//$( "svg" ).css( "height", "330px" );




			$( "#" + str_ID_graph ).css( "width", "300px" );			// "chart"



			//$( "#" + str_ID_graph  ).css( "width", "600px" );  // #chart   // 500
			//$( "#" + str_ID_graph  ).css( "height", "300px" );  // #chart

			//window.alert(  $( '#' + str_ID_graph  ).style.width  );

			//console.log( document.getElementById(  "id_RP_mychart_cpu_1_2"  ).width );



			//---


			$(  $( ".recharts-line>path" )[0]  ).attr( "stroke-width", 3 );		// 5
			$(  $( ".recharts-line>path" )[0]  ).attr( "stroke-height", 3 );				//"stroke-height"

			$( ".recharts-area" ).hide();
			$( ".recharts-cartesian-grid" ).hide();
			$( ".recharts-xAxis" ).hide();
			$( ".recharts-yAxis" ).hide();
			$( ".recharts-line-dot" ).hide();

			$( "tspan" ).hide();
			$( ".recharts-reference-line-line" ).hide();


			//----

			setTimeout(
							( ) =>
							{
								domtoimage.toBlob(
													document.getElementById(
																				str_ID_graph 		/*$( "svg" )*/
													)
													// 'id_RP_mychart_cpu_1_2'			 // chart
								).then(
								//

									function ( blob )
									{

										var reader = new FileReader();

										//---

										reader.readAsDataURL( blob );

										//---

										reader.onloadend =		function ( )
																{
																	let base64data = reader.result;

																	//console.log(  base64data  );


																	info[ "image_blob" ] =  base64data;

																	//--

																	this_.props.saveProfile(
																								this_.props.auth.token,

																								info

																	); // this_.props.saveProfile(

																	//--

																	setTimeout(
																					( ) =>
																					{

																						ret_function();

																					}//

																					, 200 		//200 			//2000

																	);// setTimeout()

																} // function

									} // function

								//
								); // ).then(

							}// () =>


							, 1800		// 1800 	 //800

			); // setTimeout(


		}// function guardarPerfil ( ret_function ) ---------------------------------------------


		//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------]




		//---------------------------------------------------------------------------------------------------------------------
		//---------------------------------------------------------------------------------------------------------------------


		let master_sl = {};



		if(  this.state.selected_installationgroup.id  ==  ""  )
		{

			return <div/>

		};

		//----


		this.state.selected_installationgroup.info.streetlights.map(
		//
			( item, index ) =>
			{
				if (  item.cpu_is_master  )
				{
					master_sl = item;
				}
			}
		//
		);//.map()



		//console.log( "[ master_sl.combined_sensors.indexOf( has_presence ) ]: " + master_sl.combined_sensors.indexOf( "has_presence" )  );
		//console.log( "[ master_sl.combined_sensors ]: " + master_sl.combined_sensors  );



		//----

		let slider_index = -1;

		let minutes_active = "";
		let seconds_active = "";


		if (  this.state.wait_in  ==  "seconds"  )
		{
			seconds_active ="btn btn-sm btn-light active";
			minutes_active = "btn btn-sm btn-light ";
		}
		else
		{
			seconds_active = "btn btn-sm btn-light ";
			minutes_active = "btn btn-sm btn-light active";
		}//


		//------


		let aux_Hss =  Math.floor( timestamp_total_de_Noche[ 0 ]  /  60.0 );

		aux_Hss =
					(  aux_Hss  <  10  )
					?
						'0' + aux_Hss
					:
						aux_Hss;


		let aux_Mss =  (  timestamp_total_de_Noche[ 0 ]  %  60 );

		aux_Mss =
					(  aux_Mss  <  10  )
					?
						'0' + aux_Mss
					:
						aux_Mss;



		const const_str_timestamp_total_de_Noche =  String(
																aux_Hss  +

																":"	+

																aux_Mss
													);

		//window.alert( const_str_timestamp_total_de_Noche );



		//------------------------------------------------------------------



		return (
		//

			<div className="container-fluid">


				<div className="row">


					<div id="cab-general" className="container-fluid">


						<Link className="actions-left" to="/perfiles" style={{maxWidth: "200px", marginTop: "-5px"}}>

							<div className="volver" style={{width: "150px"}}>

								<img src="/img/ico/gray/ico-c-turnleft.svg" alt="" style={{display: "inline-block"}}/>

								<div className="nav-link dropdown-toggle noafter" role="button" aria-haspopup="true" aria-expanded="false" style={{display: "inline-block"}}>
									<Trans>profiles.back</Trans>
								</div>
							</div>

						</Link>



						<div>

							<center>
							<h4 style={{paddingTop:"0.5rem", marginBottom:"-2.5rem", marginTop:"0.0rem", marginRight:"13rem", marginLeft:"0.0rem"}}>
								{ this.state.selected_installationgroup.group_name }
							</h4>
							</center>

						</div>



						<div className="actions-right" >

							<button
									type="submit"
									className="btn btn-orange d-none d-md-block"
									data-toggle="modal"

									data-target=".bd-filtrar-modal-sm"
									style = { { float: "right" } }

									onClick =	{
													( e ) =>
													{
														e.preventDefault()
													}
												}
							>
								<Trans>profiles.save</Trans>

							</button>

						</div>


						<div className="actions-right" style={{paddingRight: "20px"}}>

							<button
									type="submit"
									className="btn btn-orange d-block d-md-none"
									style={{float: "right"}}
									data-toggle = "modal"

									data-target=".bd-filtrar-modal-sm"

									onClick =	{
													( e ) =>
													{
														e.preventDefault()
													}
												}
							>
								<Trans>profiles.save</Trans>
							</button>
						</div>

					</div>






					<div id="saveModal" className="modal fade bd-filtrar-modal-sm" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

						<div className="modal-dialog modal-sm modal-dialog-centered">

							<div className="modal-content">

								<div className="modal-header">

									<h5 className="modal-title"> Añade un nombre </h5>

									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">
											×
										</span>
									</button>

								</div>


								<div className="modal-body">

									<p> Antes de guardar, debes añadir un t&iacute;tulo al rango de horarios </p>

									<input
											className = "campo-modal form-control form-control-lg"
											typeV = "text"
											placeholder = "Horario sin nombre"

											value = { this_.state.profileName }

											onChange =	{
															( e ) =>
															{
																this_.setState(  { profileName: e.target.value }  );
															}
														}
									/>

									<textarea
											className="campo-modal form-control form-control-lg"
											type="text"
											placeholder="Escribir un comentario..."
											style= { { borderBottom: "0px white" } }
											value= { this.state.profileComments }

											onChange =	{
															( e ) =>
															{
																this_.setState(  { profileComments: e.target.value }  );
															}
														}
										/>

								</div>




								<div className="modal-footer">

									<button
											type="button"
											className="btn btn-orange"

											onClick =	{
															( e ) =>
															{
																$( "#saveModal" ).modal( "hide" );


																guardarPerfil(

																				( ) =>
																				{

																					setTimeout(

																								( ) =>
																								{

																									this.props.history.push(
																																"/perfiles/"
																									); // .push(

																								} //

																								, 1000
																					)//

																				} //

																); //

																e.preventDefault();

															} //

														} //
									>
										<Trans>profiles.save</Trans>

									</button>

								</div>

							</div>
						</div>

					</div>


				</div>








				<div id="dropdowncontainer" className="container-fluid">
					<div className="row vista-bloques">


						<div
							className="container-fluid"

							style=	{
										{
											marginTop: "0.4rem",

											marginBottom: 	(
																(  ready_toRENDER   ==   true  )   &&

																(
																	(

																		//(  tipoPERFIL  ==  1  )   ||

																		(  this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]  )		// 3
																	)
																)

																&&

																(
																	master_sl.combined_sensors == undefined
																||
																	master_sl.combined_sensors.indexOf( "has_presence" ) == -1
																)

															)
															?
																"-1.3rem"
															:
																"-0.1rem"
										}
									}
						>

							{
							/*
							<br/>
							*/
							}


							{

							<br/>

							}

							<center>
								<h3> { this.state.profileName }  </h3>
							</center>

							{

							<br/>

							}

						</div>





						{
						false &&

						<div>
							<br/>
							<center>

							<p
								onClick =	{
												( e ) =>
												{
													//window.alert( "YEEEEEEEEE!!" );

													//window.alert(  this.props.tipoPerfil   );

													//window.alert( tipoPERFIL );
													//window.prompt("sometext","defaultText");
												}
											}
							>
								Pulsa aquí
							</p>

							<br/>

							</center>
						</div>
						}





						{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
						{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
						{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }



						{ /* ------------------------------------------------------ MODO 1 / TIPO PERFIL 1 --------------------------------------------------------------------------------------------------------------- */ }




						{

						(  ready_toRENDER   ==   true  )   &&

						(
							(  tipoPERFIL  ==  1  )   ||

							this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]

						)

						&&	// 3

						(  master_sl.combined_sensors !== undefined )  &&

						(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&




						<div className="col-sm-12 col-md-12 col-xl-12" >

							<div className="perfil" style={{paddingBottom: "0px", paddingRight: "40px"}}>


								<div className="row">

									<div className="col-sm-2 col-md-2 col-xl-2 pull-left">


										<h5>
											<Trans>profiles.duration</Trans>
										</h5>


										<Select
											searchable={false}

											placeholder=	{	this.state.time_to_wait
																/*
																( this.state.schedule_id  !=  -1 )
																?
																this.state.time_to_wait
																:
																10  //this.state.time_to_wait //10
																*/
															}

											value=	{ 	this.state.time_to_wait
														/*
														( this.state.schedule_id  !=  -1 )
														?
														this.state.time_to_wait
														:
														10  //this.state.time_to_wait  //10
														*/
													}

											styles={selectStyles}



											onChange =
														{
															( e ) =>
															{
																const value = e.value;


																this.state.time_to_wait = value;

																this.setState(  {  time_to_wait: value }  );

																//this.state.time_to_wait = value;


																this.checkValue();
															}
														}

											options = 	{
															//minutes_list

															( this.state.wait_in == "seconds" )  ?  seconds_list  :  minutes_list
														}
										/>






										<div style={{width: "300px", paddingTop: "15px", paddingBottom: "15px"}}>
											<div className="btn-group btn-group-toggle" id="timerange" data-toggle="buttons">


												<label
													className = { minutes_active }

													onClick =	{
																	( e ) =>
																	{
																		setTimeout(
																					( ) =>
																					{
																						//this.getSLData();

																						this.setState(
																										{  wait_in: "minutes", time_to_wait: 0  }

																						);//.setState()
																					}

																					, 250
																		)//
																	}
																}
												>
													<input type="radio" name="options" id="option1" autoComplete="off"/>

													<Trans>profiles.minutes</Trans>

												</label>



												<label
													className = {seconds_active}

													onClick =	{
																	( e ) =>
																	{
																		setTimeout(
																					( ) =>
																					{
																						//this.getSLData();
																						this.setState(  { wait_in: "seconds", time_to_wait: 0 }  );
																					}

																					, 250
																		)//
																	}
																}
												>
													<input type="radio" name="options" id="option2" autoComplete="off" />

													<Trans>profiles.seconds</Trans>
												</label>

											</div>
										</div>

									</div>




									{

									<div className="col-sm-10 col-md-10 col-xl-10">

										<h5>
											<Trans>profiles.dimming</Trans>
										</h5>

										<Slider
												min = { 0 }
												max = { 100 }
												defaultValue = { this.state.dimming }
												value = { this.state.dimming }
												step = { 10 }
												handle = { handle }

												ref =	{
															( e ) =>
															{
																this.upperSlider = e;
															}
														}

												trackStyle = { { backgroundColor: 'orange' } }

												handleStyle =	{
																	{
																		borderColor: 'orange'
																	}
																}

												dots dotStyle = { { borderColor: "lightgrey" } }

												onChange =	{
																( value ) =>
																{
																	this.setState({dimming: value});
																	this.state.dimming=value;
																	this.checkValue();
																}
															}
										/>

									</div>

									}


								</div>


							</div>
						</div>
						}


					</div>
				</div>



				{
				/*
				<br/>
				*/
				}


				{ /* -------------------- MODE 1 INPUTS  -------------------- */}


				<div id="dropdowncontainer" className="container-fluid" style={{marginBottom:"30px", marginLeft:"10px", padding: "0"}}>

					<div className="row ">


						{

						(  ready_toRENDER   ==   true  )   &&

						(
							(  tipoPERFIL  ==  1  )   ||

							this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]

						)   &&			// 3


						<div className="col-sm-12 col-md-12 col-xl-12" style={{paddingLeft: "20px", paddingRight:"40px"}}>

							<div className="perfil selector_seleccion" style={{paddingBottom: "25px", paddingRight: "20px"}}>

								<div className="row" style={{paddingRight: "25px"}}>



									<div className="col-sm-2 col-md-2 col-xl-2">
										<div className="form-check" style={{marginLeft: "10px"}}>

											<label className="form-check-label" for="defaultCheck2">
												<Trans>profiles.change_selection</Trans>
											</label>

										</div>
									</div>



									{
									// SLIDER GENERAL

									<div className="col-sm-10 col-md-10 col-xl-10 select_slider" style={{marginTop: "3px"}}>

										<Slider
												min={0}
												max={100}
												defaultValue={0}

												step={10}

												handle={handle}

												marks={marks}





												trackStyle = { { backgroundColor: 'orange' } }

												handleStyle =	{
																	{
																		borderColor: 'orange'
																	}
																}

												//dots dotStyle = { { borderColor: "lightgrey" } }


												//height="900px"




												onAfterChange =	{
																	( value ) =>
																	{

																		let t_ChartM1_slice  = this_.state.Chart_Data_M1.slice();


																		$( ".profileCheck" ).map(
																									( index, item ) =>
																									{

																										let id_index = parseInt(  $( item ).attr( "idval" )  );

																										if (   $(  $( ".profileCheck" )[ index ]  ).is( ":checked" )   )
																										{
																											this.sliders[ id_index ].setState(  { value: value }  );
																											this.sliders[ id_index ].onMouseUp();


																											t_ChartM1_slice[ id_index ][ "sp" ] = value;
																										}

																									}//
																		);//.nap()

																		this_.setState(  { Chart_Data_M1: t_ChartM1_slice }  );

																		setTimeout(
																					( ) =>
																					{
																						this_.checkValue();
																					}

																					, 500
																		);//
																	}//
																}


												style = {{ color: "red" }}
										/>

									</div>

									}


								</div>

							</div>
						</div>
						}




						{
						// "TODOS" inpput checkbox

						(  ready_toRENDER   ==   true  )   &&

						(
							(  tipoPERFIL  ==  1  )   ||

							this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]

						)  &&		// 3

						<div className="col-sm-12 col-md-12 col-xl-12" style={{paddingLeft: "20px", paddingRight:"40px", marginTop: "10px", height: "20px"}}>

							<div style={{marginLeft: "45px"}}>

								<input
										className="form-check-input"
										type="checkbox"
										value=""
										id=""

										onChange =	{
														( e ) =>
														{
															let value = false;
															let increment = 1;
															let i = 0;

															if (  $( e.target ).is( ":checked" )  )
															{
																value = true;
															}
															else
															{
															}

															$( ".profileCheck" ).map(
																						( index, item ) =>
																						{
																							$( item ).attr( "checked", value );
																							$( item ).prop( "checked", value );

																						}
															);//
														}
													}
								/>

								<Trans>profiles.all</Trans>

							</div>
						</div>

						}





						<div className="col-sm-12 col-md-12 col-xl-12" style={{ marginTop: "10px", paddingRight: "25px", paddingLeft:"20px"}}>

							{
							//console.log( "this.state.selected_installationgroup.max_cpu_version: " + this.state.selected_installationgroup.max_cpu_version )

							(  ready_toRENDER   ==   true  )   &&

							(
								(  tipoPERFIL  ==  1  )   ||

								this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]

							)  &&		// 3


							<div className="perfil" style={{height: "400px", overflowY: "scroll", paddingBottom: "25px"}}>
							{

								(  this_.state.Chart_Data_M1 !== undefined  &&  this_.state.Chart_Data_M1.length > 0  )  &&

								//window.alert(  rangos_DIM  )  &&

								//rangos_DIM.forEach(			....  con "forEach" no funciona.... parece que el ".map" proporciona además de Salida un array (de los returns sucesivos) que parece que es lo que hace que el código Html final, lo asimile y aparezca en pantalla

								rangos_DIM.map(
								//
									function ( x, i )
									{

										if (  i < 10  )
										{
											return <div/>
										};


										if (  i > 44  )
										{
											return <div/>
										};


										slider_index++;


										//console.log( "forEach_"  +  slider_index );

										//----

										return	(
										//

											<div className="col-sm-12 col-md-12 col-xl-12">
												<div className="perfil selector_horas" style={{paddingBottom: "1px", paddingRight: "15px", "paddingLeft": "0px", paddingTop: "0px"}}>

													<div className="row">


														<div className="col-sm-4 col-md-2 col-xl-2">

															<div className="form-check" style={{marginLeft: "1px"}}>


																<input
																	className = "form-check-input profileCheck"

																	type = "checkbox"

																	idVal = { i }
																	value = ""
																	id = ""

																	onChange =	{

																					( e ) =>
																					{
																						if (  this_.state.capsIsPressed  )
																						{
																							if ( this_.state.initialCaps == null )
																							{
																								this_.state.initialCaps = i;
																							}
																							else
																							{
																								let increment = 1;

																								if(  i < this_.state.initialCaps  )
																								{
																									increment = -1;
																								}//

																								for (  let z = this_.state.initialCaps + increment;   z != i;   z = z + increment  )
																								{
																									$( ".profileCheck" ).map(
																																( index, item ) =>
																																{

																																	if (  $( item ).attr( "idVal" ) == z  )
																																	{
																																		let value = $( item ).is( ":checked" );

																																		$( item ).attr( "checked", !value );
																																		$( item ).prop( "checked", !value );
																																	}//

																																}//
																									);//.map()

																								}// for -------

																							}// if
																						}
																					}// ( e ) =>

																				} //
																/>

																<label className="form-check-label" for="defaultCheck2">
																	{ x[0] } - { x[1] }
																</label>

															</div>
														</div>


														{

														<div className="col-sm-8 col-md-10 col-xl-10" style={{marginTop: "0px", marginLeft: "-15px", paddingRight: "0px"}}>

															<Slider
																	min = {0}
																	max = {100}
																	defaultValue = {  ( this_.state.Chart_Data_M1[i] )  ?  this_.state.Chart_Data_M1[i].sp  :  0  }
																	step={10}
																	handle={handle}

																	ref =	{
																				( e ) =>
																				{
																					this_.sliders[i] = e;

																					setTimeout(
																								( ) =>
																								{

																									if (  this_.sliders[i]  )
																									{
																										this_.sliders[i].setState(
																																	{
																																		value:	this_.state.Chart_Data_M1[i]  ?  this_.state.Chart_Data_M1[i].sp  :  0
																																	}
																										);//.setState(
																									}// if

																								}//

																								, 100
																					);//setTimeout()
																				}
																			}

																	onChange =	{
																					( value ) =>
																					{
																						let t_ChartM1_slice  = this_.state.Chart_Data_M1.slice();

																						t_ChartM1_slice[ i ][ "sp" ] = value;

																						this_.setState(  { Chart_Data_M1: t_ChartM1_slice }  );
																					}
																				}



																	trackStyle = { { backgroundColor: 'orange' } }

																	handleStyle =	{
																						{
																							borderColor: 'orange'
																						}
																					}

																	//dots dotStyle = { { borderColor: "orange" } }
															/>

														</div>

														}

													</div>


												</div>
											</div>

										//
										) // return


									}// function ( x, i )
								//
								)//.forEach()
							}
							</div>
							}


						</div>

					</div>
				</div>



				{ /*-----------------------  PERFIL 1  -----------------------*/ }



				{ /*-----------------------  GRÁFICA PERFIL 1  -----------------------*/ }

					{/* SE PINTA LA GRÁFICA DEL DIMMING DETALLADA */}



				{

				(  ready_toRENDER   ==   true  )   &&

				(
					(  tipoPERFIL  ==  1  )   ||

					this.state.selected_installationgroup.info.max_cpu_version  !=  vCPU[ "elorav1" ]

				)
				&&		// 3


				<div id = "dropdowncontainer" className = "container-fluid" style = {{marginBottom:"35px", marginLeft:"-10px"}}>
					{
					(  this_.state.Chart_Data_M1.length > 10  )  &&


					<ResponsiveContainer
										width="100%"
										height={/*300*/150}

										id="id_RP_mychart_cpu_1_2"

										debounce={0}

										isAnimationActive = { false }
					>

						<ComposedChart
										data = { this_.state.Chart_Data_M1.slice( 10, 45 ) }

										ref =	{
													( e ) =>
													{
														this.chart = e;
													}
												}


										isAnimationActive = { false }
						>

							<Area type='step' dataKey='sun' fill='#9a9a9a' stroke='transparent' fillOpacity="0.3"  isAnimationActive={false} />

							<Area type='step' dataKey='sun0' fill='#dedede' stroke='transparent' fillOpacity="0.3"  isAnimationActive={false} />


							<Line type="step" dataKey="sp" stroke="orange" strokeWidth={3}  isAnimationActive={false} />


							<CartesianGrid stroke="#ccc" />


							<XAxis dataKey="name"/>

							<YAxis
								domain = { [ 0, 100 ] }

								//interval = { 1 }

								tickCount={ 3 }
							/>

							{
							(  refAreaLeft  &&  refAreaRight  )		?
																	(	<ReferenceArea
																						yAxisId = "1"
																						x1 = { refAreaLeft }
																						x2 = { refAreaRight }
																						strokeOpacity = { 0.3 }
																		/>
																	)
																	:
																	null
							}

						</ComposedChart>

					</ResponsiveContainer>

					}
				</div>
				}




				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }



				{ /* ------------------------------------------------------ MODO 5 / TIPO PERFIL 5 --------------------------------------------------------------------------------------------------------------- */ }




				{
				<center>

					{

					(  ready_toRENDER   ==   true  )   &&

					(
						(  tipoPERFIL  ==  5  )   &&

						this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

					)  &&		// 3


					<table width="96%" height="250%">

						<tr>



							{ /* -------------------- MODE 5 INPUTS  -------------------- */}


							<td>


								<div className = "container-fluid" style = {{marginTop:"-70px", marginBottom:"35px"/*, marginLeft:"30px", marginTop: "-40px"*/}}>

								<center>

									{
									/*
									<input
											id="id_irange01"
											type="range"

											name="LT1-LT6"

											onChange=	{

														}
									/>
									*/
									}



									{


									(  master_sl.combined_sensors !== undefined )  &&

									(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&

									<p>
										{ this.props.i18n.t("profiles.presence_sensor_waiting_time") }

										<input
												id = "id_Tiempo_Espera_SensorPresen"

												type="number"

												style= {{marginLeft:"0.5rem", marginBottom:"-0.3rem", width: "50px"}}


												onChange=	{
																( event ) =>
																{

																	if  (
																			( parseInt( event.target.value ) >=  0  )  &&
																			( parseInt( event.target.value ) <  60  )
																		)
																	{

																		this.state.time_to_wait = parseInt( event.target.value );

																		this.state.wait_in = "seconds";
																	}//
																	else
																	{
																		event.target.value =  this.state.time_to_wait;
																	}// else

																}//
														}
										/>

									</p>

									}



									{

									// 24/05/2023: ME COMENTA MARCOS QUE EL MODO 5 (Y EL 3), NO TIENEN, ES DECIR, LORA-KENLOK 4.0, NO TIENEN OPCIÓN DE VARIACIÓN SELECCINABLE DEL DIMMING EN EL DETECTOR DE PREESENCIA


									/*

									(  master_sl.combined_sensors !== undefined )  &&

									(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&

									<p>
										Dimming (%):

										<input
												id = "id_porc_Dimming"

												type="number"

												style= {{marginLeft:"0.5rem", marginBottom:"-0.3rem", width: "50px"}}


												onChange=	{
																( event ) =>
																{

																	if  (
																			( parseInt( event.target.value ) >=  0  )  &&
																			( parseInt( event.target.value ) <=  100  )
																		)
																	{

																		this.state.dimming = parseInt( event.target.value );

																	}//
																	else
																	{
																		event.target.value =  this.state.dimming;
																	}// else

																}//
														}

										/>

									</p>
									*/
									}



									{

									(  master_sl.combined_sensors !== undefined )  &&

									(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&


										<div>

											<br/>
											<br/>

										</div>

									}



									{ /* -------------------------------------------------------------------------------------------------- */ }



									<p>
										{this.props.i18n.t("profiles.real_night_hours")}

										<input
												id = "id_Horas_Noche_Reales"

												type="time"

												value=	{
															//window.alert( "Ahora" )

															//"08:30"

															const_str_timestamp_total_de_Noche
														}

												style= {{marginLeft:"0.5rem", marginBottom:"-0.3rem", width: "73px"}}

												disabled
										/>

									</p>


									{

									(
										(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
									/*
									||
									1	( SCH_PROF__IS_TEMPLATE == false )
									*/
									)
									&&

									<p>
										{ this.props.i18n.t("profiles.thoreical_night_hours") }

										<input

											id="id_in_total_horas_teor"

											type="number"

											style= {{margin:"0.5rem", width: "48px"}}


											onChange=	{
															( event ) =>
															{
																//window.alert( timestamp_minut_SUNRISE_TEOR );


																var val_superior_horas_teor =  Math.round(   TS_ABS__Mitad_Noche[ 0 ]  +  parseFloat(  (  parseInt( event.target.value ) * 60  )  /  2.0 )   );


																//window.alert( event.target.value  +  ";  "  +  val_superior_horas_teor );


																const MINUTES_TOTALES_GRAFICA =  17 * 60;			//  17-00h - 00h =  7 horas,  .....   00h-10h = 10h .....,    TOTAL: 17 HORAS

																//window.alert( MINUTES_TOTALES_GRAFICA );


																//var limit_superior_horas =  Math.floor(  );

																if  (
																		( event.target.value > 5  )  &&


																		(  val_superior_horas_teor  <=  MINUTES_TOTALES_GRAFICA  )		//

																		//( event.target.value < 17  )		//
																	)
																{


																	let aux_HORAS_TEORICAS_TOT =  parseInt( event.target.value );

																	let aux_MITAD_HORAS_TEORICAS =   /*Math.round*/ (   parseFloat( aux_HORAS_TEORICAS_TOT )  /  2.0   );

																	let aux_timestamp_minut_SUNSET_TEOR  = 	TS_ABS__Mitad_Noche[ 0 ]  -  ( aux_MITAD_HORAS_TEORICAS * 60.0 );


																	//console.log(  aux_timestamp_minut_SUNSET_TEOR  );


																	if (  aux_timestamp_minut_SUNSET_TEOR  >=  0  )
																	{


																		HORAS_TEORICAS_TOT =  aux_HORAS_TEORICAS_TOT;		//parseInt( event.target.value );

																		MITAD_HORAS_TEORICAS =   aux_MITAD_HORAS_TEORICAS;		//	/*Math.round*/ (   parseFloat( HORAS_TEORICAS_TOT )  /  2.0   );

																		//window.alert( MITAD_HORAS_TEORICAS );


																		timestamp_minut_SUNSET_TEOR  = 	aux_timestamp_minut_SUNSET_TEOR;	//TS_ABS__Mitad_Noche  -  ( MITAD_HORAS_TEORICAS * 60.0 );

																		timestamp_minut_SUNRISE_TEOR = 	TS_ABS__Mitad_Noche[ 0 ]  +  ( MITAD_HORAS_TEORICAS * 60.0 );





																		//window.alert(  timestamp_minut_SUNRISE_TEOR  );


																		STR_time_SUNSET_TEOR  =  Math.floor(  ( (timestamp_minut_SUNSET_TEOR  + (const_hora_start_horario*60))  /  60.0 )  )  +  ':'  +  (  (  timestamp_minut_SUNSET_TEOR  + (const_hora_start_horario*60)  )  %  60  );




																		STR_time_SUNRISE_TEOR =  Math.floor(
																											(
																												(  timestamp_minut_SUNRISE_TEOR - ( const_horas_entre_comienzo_y_medianoche    *60 )  )

																												/  60
																											)

																										)	  +  ':'  +  (  (  timestamp_minut_SUNRISE_TEOR - (const_horas_entre_comienzo_y_medianoche    *60)  )  %  60  );

																		//window.alert( (  timestamp_minut_SUNRISE_TEOR - ( MITAD_HORAS_TEORICAS    *60 )  ) );
																		//window.alert(  STR_time_SUNRISE_TEOR  );

																		//----

																		ar_DAT_elora_M5__REAL = [];

																		ar_DAT_elora_M5__TEOR = [];


																		ar_DAT_elora_M5__NOCHECORTA = [];

																		ar_DAT_elora_M5__NOCHELARGA = [];


																		glob_INIT__ARs_ChartData_M5();


																		setTimeout(
																					() =>
																					{

																						this.state.CPU_elorav1_Chart_Data_M5 =  [];	//ar_DAT_elora_M5__REAL;

																						this.setState(
																										{
																											CPU_elorav1_Chart_Data_M5:  []	//ar_DAT_elora_M5__REAL
																										}
																						);//

																						//-----------------------------------------

																						this.FROM_ARs__SET_ALL_CURVE_VALUES__M5();

																						//----------------------------------------




																						this.state.CPU_elorav1_Chart_Data_M5 =  ar_DAT_elora_M5__REAL[ 0 ];

																						this.setState(
																										{
																											CPU_elorav1_Chart_Data_M5:  ar_DAT_elora_M5__REAL[ 0 ]
																										}
																						);//


																						//window.alert( MITAD_HORAS_TEORICAS );

																					}//

																					, 1000  //2000
																		);


																		backup_val_HORAS_TEORICAS_TOT =  event.target.value;


																	}// if (  aux_timestamp_minut_SUNSET_TEOR  >=  0  )
																	else
																	{
																		event.target.value =  backup_val_HORAS_TEORICAS_TOT;

																	}// else

																}
																else
																{
																	event.target.value =  backup_val_HORAS_TEORICAS_TOT;
																}


															}//
														}
										/>
									</p>
									}

									{/*<br/>*/}



									{ /* -------------------------------------------------------------------------------------------------- */ }

									{

									(
											(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
										||
											( SCH_PROF__IS_TEMPLATE == false )
									)

									&&

									(  ready_toRENDER   ==   true  )   &&

									(
										(  tipoPERFIL  ==  5  )   &&

										this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

									)
									&&  // 3



									<table style={{margin: "auto", padding: "auto", borderColor: "black"}}>

										{/* ESTRUCTURA PERO VACIA */}
										<tr>
											<td>
												<hr/>
											</td>

											<td>
												<hr/>
											</td>

											<td>
												<hr/>
											</td>
										</tr>


										{/* ------ */}



										<glob_INIT__All_INPUTS__VALS___Mode_5 />
										{ /*
											SERGIO CAMBIO 03-03-23:  ANTES ESTABA PUESTA ALGO MÁS ABAJO
										*/}



										<this.insert_HTML_INPUTS_M_5 />



										{/* ------ */}


										<tr hidden>	{ /* hidden */ }

											{/* ESTRUCTURA PERO VACIA */}
											<td>
												<td>

												</td>

												<td>

												</td>
											</td>

											{/* ESTRUCTURA PERO VACIA */}
											<td>
												<td>

												</td>

												<td>

												</td>
											</td>



											<td>

												<td>
													{
													<label for="id_input_NIGHTTIME"  style= {{marginTop:"1rem",marginRight:"1rem"}}> Total Night Time (h) </label>
													}
												</td>

												<td>
													{
													<input id="id_input_NIGHTTIME" type="text" name="in_NIGHTTIME" style= {{marginTop:"0.5rem", width: "61px"}} disabled />
													}
												</td>

												<td>
													{ /*"  / 17 MAX  = " +  timestamp_total_de_Noche[ 0 ]  +  " m"*/ }
												</td>
											</td>

										</tr>


										<tr hidden>
											<td>
											</td>


											<td>
											</td>


											<td>
												<td>
													{
													<label for="id_input_NIGHTTIME__TEOR"  style= {{marginTop:"1rem",marginRight:"1rem"}}> Total Night Time__TEOR (h) </label>
													}
												</td>

												<td>
													{
													<input id="id_input_NIGHTTIME__TEOR" type="text" name="in_NIGHTTIME__TEOR" style= {{marginTop:"0.5rem", width: "61px"}} disabled />
													}
												</td>

												<td>
													{ /*"  / 17 MAX  = " +  timestamp_total_de_Noche[ 0 ]  +  " m"*/ }
												</td>
											</td>
										</tr>


									</table>

									}

								</center>



								{ /*

								SERGIO CAMBIO 03-03-23:

									COMENTAMOS AQUÍ Y LA PONEMOS ALGO MÁS ARRIBA (ANTES DEL "this.insert_HTML_INPUTS_M_5")


									<glob_INIT__All_INPUTS__VALS___Mode_5 />
								*/ }




								{/*
								< FROM_ARs__SET_ALL_CURVE_VALUES__M5 />
								*/}



								</div>


							</td>




							{ /* -------------------- ¡ GRÁFICAS MODE 5 !   -------------------- */}




							<td>

								{

								(  ready_toRENDER   ==   true  )   &&

								(
									(  tipoPERFIL  ==  5  )   &&

									this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

								)  &&		// 3

								<div id = "dropdowncontainer" className = "container-fluid" style = {{marginTop:"-20px", marginBottom:"0px"/*, marginLeft:"-10px"*/}}>

									<h6 style={{marginLeft:"2rem"}} > { this.props.i18n.t("profiles.real_dimming") } </h6>

									{
									//(  this_.state.Chart_Data_M1.length > 10  )  &&

									<ResponsiveContainer
														width="100%"
														height={/*300*/ 140}

														id="id_RP_mychart_cpu_elorav1_A"

														//debounce={0}
									>

										<ComposedChart
														data =	{
																	//ar_DAT_elora_M5__REAL

																	this.state.CPU_elorav1_Chart_Data_M5

																	//this_.state.CPU_elorav1_Chart_Data_M5
																}


														ref =	{
																	( e ) =>
																	{
																		this.chart = e;
																	}
																}


														isAnimationActive = { false }

														//dot="false"
										>


											{
											/*
											<ReferenceLine
															x={ str_hora_Linea_IZQ_1_2 }
															stroke="green"
															label={ "IZQ.1.2" }
											/>
											<ReferenceLine
															x={ str_hora_Linea_DCHA_1_3 }
															stroke="green"
															label={ "DCHA.1.3" }

											/>
											*/
											}



											<Line
												type="monotone"

												dataKey="val"

												stroke="orange"

												strokeWidth={2}

												dot={false}

												isAnimationActive = { false }

												/*
												onMouseDown=	{
																	( event ) =>
																	{
																		window.alert( "YEE" );
																	}
																}
												*/
												//strokeWidth={1}

												//isAnimationActive={true}
											/>




											<CartesianGrid stroke="#ccc" />


											<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#ADADFF' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>


											{

											(
												(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
											/*
											||
												( SCH_PROF__IS_TEMPLATE == false )
											*/
											)
											&&
											<Area type='step' dataKey='val_sunrise_sunset__TEOR' fill='#C0C0C0' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>

											}


											{/*
											<XAxis
												dataKey="time_hour"

												//tickCount={7}

												interval={  29  }
											/>
											*/
											}


											<XAxis

												dataKey="time_hour"

												tick = { <CustomizedAxisTick/> }

												height = { 50 }

												interval={  29  }
											/>



											<YAxis
												domain = { [ 0, 100 ] }
												//interval = { 1 }

												tickCount={ 3 }
											/>


											<ReferenceLine
															x={ str_HOURMIN_tsABS__Mitad_Noche[ 0 ] }
															stroke="blue"

															label={ str_HOURMIN_tsABS__Mitad_Noche[ 0 ] }

															strokeWidth={1}
											/>





											{
											<ReferenceLine
															x={ STR_time_SUNSET_REAL[ 0 ] }

															stroke="red"
															label={ STR_time_SUNSET_REAL[ 0 ] }

															strokeWidth={0}
											/>
											}

											{ /*window.alert( time_SUNRISE )*/ }
											<ReferenceLine
															//x={ '0'  +  STR_time_SUNRISE_REAL[ 0 ] }
															x={ STR_time_SUNRISE_REAL[ 0 ] }

															stroke="red"

															//label={ '0'  +  STR_time_SUNRISE_REAL[ 0 ] }
															label={ STR_time_SUNRISE_REAL[ 0 ] }

															strokeWidth={0}

											/>



											{
												/*
											(  refAreaLeft  &&  refAreaRight  )		?
																					(	<ReferenceArea
																										yAxisId = "1"
																										x1 = { refAreaLeft }
																										x2 = { refAreaRight }
																										strokeOpacity = { 0.3 }
																						/>
																					)
																					:
																					null
											*/
											}

										</ComposedChart>

									</ResponsiveContainer>
									}
								</div>
								}


								{

								<br/>

								}


								{

									(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

									<div>


										<hr/>

										<br/>

									</div>

								}







								{
								/*
								(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

								<div>

									<br/>

									<hr/>
									<hr/>

									<br/>
									<br/>

								</div>

								*/
								}


















								{

								// GRÁFICAS AÑADIDAS OPCIONALES, POR MES, DEL DÍA 15 DE CADA MES, AMANECER-ANOCHECER

								}





								{
								/*

								(  ready_toRENDER   ==   true  )   &&

								(

									(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

									(  tipoPERFIL  ==  5  )   &&

									this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

								)  &&		// 3

								<this.insert_HTML_extra_12_GRAPHS__OPTIONAL />
								*/
								}



























								{
								/*
								(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

								<div>



									<hr/>
									<hr/>

									<br/>
									<br/>


								</div>
								*/
								}













								{

								(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

								(  ready_toRENDER   ==   true  )   &&

								(
									(  tipoPERFIL  ==  5  )   &&

									this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

								)  &&  // 3

								<div id = "dropdowncontainer" className = "container-fluid" style = {{marginTop:"-20px", marginBottom:"-25px"/*, marginLeft:"-10px"*/}}>

									<h6 style={{marginLeft:"2rem"}} > { this.props.i18n.t("profiles.theoretical_dimming") } </h6>
									{
									//(  this_.state.Chart_Data_M1.length > 10  )  &&


									<ResponsiveContainer
														width="100%"
														height={/*300*/ 140}

														id="id_RP_mychart_cpu_elorav1_B"

														//debounce={0}
									>

										<ComposedChart
														data =	{
																	ar_DAT_elora_M5__TEOR

																	//this.state.CPU_elorav1_Chart_Data_M5

																	//this_.state.CPU_elorav1_Chart_Data_M5
																}


														ref =	{
																	( e ) =>
																	{
																		this.chart = e;
																	}
																}


														isAnimationActive = { false }

														//dot="false"
										>

											{
											/*
											<ReferenceLine
															x={ str_hora_Linea_IZQ_1_2 }
															stroke="green"
															label={ "IZQ.1.2" }
											/>
											*/
											}
											{
												/*
											<ReferenceLine
															x={ str_hora_Linea_DCHA_1_3 }
															stroke="green"
															label={ "DCHA.1.3" }

											/>
											*/
											}


											<Line
												type="monotone"

												dataKey="val"

												stroke="orange"

												strokeWidth={2}

												dot={false}

												isAnimationActive = { false }

												/*
												onMouseDown=	{
																	( event ) =>
																	{
																		window.alert( "YEE" );
																	}
																}
												*/
												//strokeWidth={1}

												//isAnimationActive={true}
											/>




											{
											/*
											<Area type='step' dataKey='val_sunrise_sunset__TEOR' fill='#BBBBBB' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>
											*/
											}


											<CartesianGrid stroke="#ccc" />

											{
											<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#C0C0C0' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>
											}


											<Area type='step' dataKey='val_sunrise_sunset__TEOR' fill='#ADADFF' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>


											{
											/*
											<XAxis
												dataKey="time_hour"

												//tickCount={7}

												interval={  29  }
											/>
											*/
											}


											<XAxis

												dataKey="time_hour"

												tick = { <CustomizedAxisTick/> }

												height = { 50 }

												interval={  29  }
											/>





											<YAxis
												domain = { [ 0, 100 ] }
												//interval = { 1 }

												tickCount={ 3 }
											/>


											<ReferenceLine
															x={ str_HOURMIN_tsABS__Mitad_Noche[ 0 ] }
															stroke="blue"

															label={ str_HOURMIN_tsABS__Mitad_Noche[ 0 ] }

															strokeWidth={1}
											/>






											{
											<ReferenceLine
															x={ STR_time_SUNSET_TEOR }

															stroke="red"
															label={ STR_time_SUNSET_TEOR }

															strokeWidth={0}
											/>
											}

											{ /*window.alert( time_SUNRISE )*/ }
											<ReferenceLine
															x={ '0'  +  STR_time_SUNRISE_TEOR }

															stroke="red"

															label={ '0'  +  STR_time_SUNRISE_TEOR }

															strokeWidth={0}

											/>

											{
												/*
											(  refAreaLeft  &&  refAreaRight  )		?
																					(	<ReferenceArea
																										yAxisId = "1"
																										x1 = { refAreaLeft }
																										x2 = { refAreaRight }
																										strokeOpacity = { 0.3 }
																						/>
																					)
																					:
																					null
											*/
											}

										</ComposedChart>

									</ResponsiveContainer>
									}

								</div>
								}



								<br/>
								<br/>


								<hr/>
								<hr/>


								{

								<div>

									<br/>
									<br/>

								</div>


								}



								{

								(  ready_toRENDER   ==   true  )   &&

								(
									(  tipoPERFIL  ==  5  )   &&

									this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

								)  &&  // 3

								<div id = "dropdowncontainer" className = "container-fluid" style = {{marginBottom:"0px"/*, marginLeft:"-10px"*/}}>

									<h6 style={{marginLeft:"2rem"}} > { this.props.i18n.t("profiles.shortest_night") } </h6>
									{


									// NOCHECORTA_month =  -1;
									// NOCHECORTA_day   =  -1;

									// NOCHELARGA_month =  -1;
									// NOCHELARGA_day   =  -1;


									// console.log(  ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ]  );


									//(  this_.state.Chart_Data_M1.length > 10  )  &&


									<ResponsiveContainer
														width="100%"
														height={/*300*/ 140}

														id="id_RP_mychart_cpu_elorav1_NOCHEMASCORTA"

														//debounce={0}
									>

										<ComposedChart
														data =	{
																	ar_DAT_elora_M5__NOCHECORTA

																	//this.state.CPU_elorav1_Chart_Data_M5

																	//this_.state.CPU_elorav1_Chart_Data_M5
																}


														ref =	{
																	( e ) =>
																	{
																		this.chart = e;
																	}
																}


														isAnimationActive = { false }

														//dot="false"
										>

											{
											/*
											<ReferenceLine
															x={ str_hora_Linea_IZQ_1_2 }
															stroke="green"
															label={ "IZQ.1.2" }
											/>
											*/
											}
											{
												/*
											<ReferenceLine
															x={ str_hora_Linea_DCHA_1_3 }
															stroke="green"
															label={ "DCHA.1.3" }

											/>
											*/
											}


											<Line
												type="monotone"

												dataKey="val"

												stroke="orange"

												strokeWidth={2}

												dot={false}

												isAnimationActive = { false }

												/*
												onMouseDown=	{
																	( event ) =>
																	{
																		window.alert( "YEE" );
																	}
																}
												*/
												//strokeWidth={1}

												//isAnimationActive={true}
											/>





											<CartesianGrid stroke="#ccc" />

											{/*
											<Area type='step' dataKey='val_sunrise_sunset__real' fill='#ADADFF' stroke='transparent' fillOpacity="0.3"/>
											*/}


											<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#C0C0C0' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>

											<Area type='step' dataKey='val_sunrise_sunset__NOCHECORTA' fill='#ADADFF' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>


											{
											/*
											<XAxis
												dataKey="time_hour"

												//tickCount={7}

												interval={  29  }
											/>
											*/
											}


											<XAxis

												dataKey="time_hour"

												tick = { <CustomizedAxisTick/> }

												height = { 50 }

												interval={  29  }
											/>





											<YAxis
												domain = { [ 0, 100 ] }
												//interval = { 1 }

												tickCount={ 3 }
											/>



											{

											<ReferenceLine
															x={ MITAD_DIA__NOCHECORTA_str }

															stroke="blue"

															label={ MITAD_DIA__NOCHECORTA_str }

															strokeWidth={1}
											/>

											}





											{

											<ReferenceLine
															x={ ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunset }

															stroke="red"

															label={ ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunset }

															strokeWidth={0}
											/>

											}


											{ /*window.alert( time_SUNRISE )*/ }
											{

											<ReferenceLine
															x={ ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunrise }

															stroke="red"

															label={ ar_sunset_sunrise_YEAR[  NOCHECORTA_month  ][  NOCHECORTA_day  ].sunrise }

															strokeWidth={0}

											/>

											}

											{
												/*
											(  refAreaLeft  &&  refAreaRight  )		?
																					(	<ReferenceArea
																										yAxisId = "1"
																										x1 = { refAreaLeft }
																										x2 = { refAreaRight }
																										strokeOpacity = { 0.3 }
																						/>
																					)
																					:
																					null
											*/
											}

										</ComposedChart>

									</ResponsiveContainer>
									}

								</div>
								}






								{

								(  ready_toRENDER   ==   true  )   &&

								(
									(  tipoPERFIL  ==  5  )  &&

									this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

								)  &&  // 3


								<div id = "dropdowncontainer" className = "container-fluid" style = {{marginBottom:"0px"/*, marginLeft:"-10px"*/}}>

									<h6 style={{marginLeft:"2rem"}} > { this.props.i18n.t("profiles.longest_night") } </h6>
									{
									//(  this_.state.Chart_Data_M1.length > 10  )  &&


									<ResponsiveContainer
														width="100%"
														height={/*300*/ 140}

														id="id_RP_mychart_cpu_elorav1_NOCHEMASLARGA"

														//debounce={0}
									>

										<ComposedChart
														data =	{
																	ar_DAT_elora_M5__NOCHELARGA

																	//this.state.CPU_elorav1_Chart_Data_M5

																	//this_.state.CPU_elorav1_Chart_Data_M5
																}


														ref =	{
																	( e ) =>
																	{
																		this.chart = e;
																	}
																}


														isAnimationActive = { false }

														//dot="false"
										>

											{
											/*
											<ReferenceLine
															x={ str_hora_Linea_IZQ_1_2 }
															stroke="green"
															label={ "IZQ.1.2" }
											/>
											*/
											}
											{
												/*
											<ReferenceLine
															x={ str_hora_Linea_DCHA_1_3 }
															stroke="green"
															label={ "DCHA.1.3" }

											/>
											*/
											}


											<Line
												type="monotone"

												dataKey="val"

												stroke="orange"

												strokeWidth={2}

												dot={false}

												isAnimationActive = { false }

												/*
												onMouseDown=	{
																	( event ) =>
																	{
																		window.alert( "YEE" );
																	}
																}
												*/
												//strokeWidth={1}

												//isAnimationActive={true}
											/>


											{
											//<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#BBBBBB' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>
											}


											<CartesianGrid stroke="#ccc" />

											{/*
											<Area type='step' dataKey='val_sunrise_sunset__real' fill='#ADADFF' stroke='transparent' fillOpacity="0.3"/>
											*/}


											<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#C0C0C0' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>

											<Area type='step' dataKey='val_sunrise_sunset__NOCHELARGA' fill='#ADADFF' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>



											{
											/*
											<XAxis
												dataKey="time_hour"

												//tickCount={7}

												interval={  29  }
											/>
											*/
											}


											<XAxis

												dataKey="time_hour"

												tick = { <CustomizedAxisTick/> }

												height = { 50 }

												interval={  29  }
											/>





											<YAxis
												domain = { [ 0, 100 ] }
												//interval = { 1 }

												tickCount={ 3 }
											/>


											<ReferenceLine
															x={ MITAD_DIA__NOCHELARGA_str }

															stroke="blue"

															label={ MITAD_DIA__NOCHELARGA_str }

															strokeWidth={1}
											/>





											{
											<ReferenceLine
															x={ ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunset }

															stroke="red"

															label={ ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunset }

															strokeWidth={0}
											/>
											}


											{ /*window.alert( time_SUNRISE )*/ }
											<ReferenceLine
															x={ ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunrise }

															stroke="red"

															label={ ar_sunset_sunrise_YEAR[  NOCHELARGA_month  ][  NOCHELARGA_day  ].sunrise }

															strokeWidth={0}

											/>

											{
												/*
											(  refAreaLeft  &&  refAreaRight  )		?
																					(	<ReferenceArea
																										yAxisId = "1"
																										x1 = { refAreaLeft }
																										x2 = { refAreaRight }
																										strokeOpacity = { 0.3 }
																						/>
																					)
																					:
																					null
											*/
											}

										</ComposedChart>

									</ResponsiveContainer>
									}

								</div>
								}






							</td>

						</tr>

					</table>
					}

				</center>
				}






				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }

				{ /* ----------------------------------------------------------------------- */  }



				{ /* ------------------------------------------------------ MODO 3 / TIPO PERFIL 3 --------------------------------------------------------------------------------------------------------------- */ }




				{

				(  ready_toRENDER   ==   true  )   &&

				(
					(  tipoPERFIL  ==  3  )  &&

					(  this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]  )

				)  &&  // 3




				<div>

					<center>



					{

					<h3> MODO 3 </h3>

					}


					{ /* ----------------------------------------------------------------------- */  }


					<br/>
					<br/>

					{
					/*
					<glob_INIT__All_INPUTS__VALS___Mode_5/>
					*/
					}





					{
						// SENSOR DE PRESENCIA EN _MODO_ 3
						// (ABAJO)
					}




					<div>

						{

						(  master_sl.combined_sensors !== undefined )  &&

						(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&

						<p>
							{ this.props.i18n.t("profiles.presence_sensor_waiting_time") }

							<input
									id = "id_Tiempo_Espera_SensorPresen"

									type="number"

									style= {{marginLeft:"0.5rem", marginBottom:"-0.3rem", width: "50px"}}


									onChange=	{
													( event ) =>
													{

														if  (
																( parseInt( event.target.value ) >=  0  )  &&
																( parseInt( event.target.value ) <  60  )
															)
														{

															this.state.time_to_wait = parseInt( event.target.value );

															this.state.wait_in = "seconds";
														}//
														else
														{
															event.target.value =  this.state.time_to_wait;
														}// else

													}//
											}
							/>

						</p>

						}



						{

						// 24/05/2023: ME COMENTA MARCOS QUE EL MODO 5 (Y EL 3), NO TIENEN, ES DECIR, LORA-KENLOK 4.0, NO TIENEN OPCIÓN DE VARIACIÓN SELECCINABLE DEL DIMMING EN EL DETECTOR DE PREESENCIA


						/*

						(  master_sl.combined_sensors !== undefined )  &&

						(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&

						<p>
							Dimming (%):

							<input
									id = "id_porc_Dimming"

									type="number"

									style= {{marginLeft:"0.5rem", marginBottom:"-0.3rem", width: "50px"}}


									onChange=	{
													( event ) =>
													{

														if  (
																( parseInt( event.target.value ) >=  0  )  &&
																( parseInt( event.target.value ) <=  100  )
															)
														{

															this.state.dimming = parseInt( event.target.value );

														}//
														else
														{
															event.target.value =  this.state.dimming;
														}// else

													}//
											}

							/>

						</p>
						*/
						}



						{

						(  master_sl.combined_sensors !== undefined )  &&

						(  master_sl.combined_sensors.indexOf( "has_presence" ) >= 0  )  &&


							<div>

								<br/>
								<br/>

							</div>

						}


					</div>






					{ /* -------------------------------------------------------------------------------------------------- */ }


ç






					{

					<this.insert_HTML_INPUTS_M_3 />

					}












					{
					// glob_INIT__All_INPUTS__VALS___Mode_5
					// glob_INIT__ARs_ChartData_M5
					}


					{ /* ----------------------------------------------------------------------- */  }



					{
					/*
					<table>

						<tr>

							<td>
							<center>
								<input
									id="id_input_TIME_mode3_00"

									type="time"

									name="name_input_TIME_mode3_00"

									style= {{width: "70px"}}

									min="00:00"

									max="23:59"

									//value="05:00"


								/>
							</center>
							</td>


							<td>
							<center>
								<input
									id="id_input_TIME_mode3_01"

									type="time"

									name="name_input_TIME_mode3_01"

									style= {{width: "70px"}}

									min="00:00"

									max="23:59"

									//value="05:00"

								/>
							</center>
							</td>

						</tr>


						<tr>

							<td>
							<center>
								<input
									id="id_input_TIME_mode3_10"

									type="time"

									name="name_input_TIME_mode3_10"

									style= {{width: "70px"}}

									min="00:00"

									max="23:59"

									//value="05:00"

								/>
							</center>
							</td>


							<td>
							<center>
								<input
									id="id_input_TIME_mode3_11"

									type="time"

									name="name_input_TIME_mode3_11"

									style= {{width: "70px"}}

									min="00:00"

									max="23:59"

									//value="05:00"

								/>
							</center>
							</td>

						</tr>

					</table>
					*/

					}

					<br/>
					<br/>

					</center>

				</div>

				}



				{ /* -------------------- ¡ GRÁFICAS MODE 3 !   -------------------- */}




				{

				(  ready_toRENDER   ==   true  )   &&

				(
					(  tipoPERFIL  ==  3  )   &&

					this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

				)  &&		// 3



				<div id = "dropdowncontainer" className = "container-fluid" style = {{marginBottom:"35px", marginLeft:"-10px"}}>

					{

					(  this_.state.Chart_Data_M1.length > 10  )  &&


					<ResponsiveContainer
										width="100%"
										height={/*300*/ 140}	// 150

										id="id_RP_mychart_cpu__tipo_3_1"

										debounce={0}		//
					>

						<ComposedChart
										data =	{
													//ar_DAT_elora_M5__REAL

													this.state.CPU_elorav1_Chart_Data_M3

													//this_.state.CPU_elorav1_Chart_Data_M5
												}


										ref =	{
													( e ) =>
													{
														this.chart = e;
													}
												}


										isAnimationActive = { false }

										//dot="false"
						>


							{
							/*
							<ReferenceLine
											x={ str_hora_Linea_IZQ_1_2 }
											stroke="green"
											label={ "IZQ.1.2" }
							/>
							<ReferenceLine
											x={ str_hora_Linea_DCHA_1_3 }
											stroke="green"
											label={ "DCHA.1.3" }

							/>
							*/
							}



							<Line
								type="monotone"

								dataKey="val"

								stroke="orange"

								strokeWidth={2}

								dot={false}

								isAnimationActive = { false }

								/*
								onMouseDown=	{
													( event ) =>
													{
														window.alert( "YEE" );
													}
												}
								*/
								//strokeWidth={1}

								//isAnimationActive={true}
							/>




							<CartesianGrid stroke="#ccc" />

							{
							/*
							<Area type='step' dataKey='val_sunrise_sunset__REAL' fill='#ADADFF' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>

							<Area type='step' dataKey='val_sunrise_sunset__TEOR' fill='#C0C0C0' stroke='transparent' fillOpacity="0.3" isAnimationActive = { false }/>
							*/
							}


							{/*
							<XAxis
								dataKey="time_hour"

								//tickCount={7}

								interval={  29  }
							/>
							*/
							}


							<XAxis

								dataKey="time_hour"

								tick = { <CustomizedAxisTick/> }

								height = { 50 }

								interval={  29  }
							/>



							<YAxis
								domain = { [ 0, 100 ] }
								//interval = { 1 }

								tickCount={ 3 }
							/>


							<ReferenceLine
											x={ str_HOURMIN_tsABS__Mitad_Noche[ 0 ] }
											stroke="blue"

											label={ str_HOURMIN_tsABS__Mitad_Noche[ 0 ] }

											strokeWidth={1}
							/>





							{/*
							<ReferenceLine
											x={ STR_time_SUNSET_REAL[ 0 ] }

											stroke="red"
											label={ STR_time_SUNSET_REAL[ 0 ] }

											strokeWidth={0}
							/>
							*/}

							{ /*window.alert( time_SUNRISE )*/ }

							{/*
							<ReferenceLine
											x={ '0'  +  STR_time_SUNRISE_REAL[ 0 ] }

											stroke="red"
											label={ '0'  +  STR_time_SUNRISE_REAL[ 0 ] }

											strokeWidth={0}

							/>
							*/}


							{
								/*
							(  refAreaLeft  &&  refAreaRight  )		?
																	(	<ReferenceArea
																						yAxisId = "1"
																						x1 = { refAreaLeft }
																						x2 = { refAreaRight }
																						strokeOpacity = { 0.3 }
																		/>
																	)
																	:
																	null
							*/
							}

						</ComposedChart>

					</ResponsiveContainer>

					}

				</div>

				}




				{ /* ----------------------------------------------------------------------- */  }



				{

				/*
				(  ready_toRENDER   ==   true  )   &&

				(
					(  tipoPERFIL  ==  3  )   &&

					this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

				)  &&		// 3

				<div id = "dropdowncontainer" className = "container-fluid" style = {{marginBottom:"35px", marginLeft:"-10px"}}>
					{
					(  this_.state.Chart_Data_M1.length > 10  )  &&


					<ResponsiveContainer
										width="100%"
										height={150}	// 300

										id="id_RP_mychart_cpu__tipo_3_2"

										debounce={0}
					>

						<ComposedChart
										data = { this_.state.Chart_Data_M1.slice( 10, 45 ) }

										ref =	{
													( e ) =>
													{
														this.chart = e;
													}
												}
						>




							<Line type="step" dataKey="sp" stroke="orange" strokeWidth={3} />   //isAnimationActive={false}


							<CartesianGrid stroke="#ccc" />


							<XAxis dataKey="name"/>

							<YAxis
								domain = { [ 0, 100 ] }

								//interval = { 1 }

								tickCount={ 3 }
							/>

							{
							(  refAreaLeft  &&  refAreaRight  )		?
																	(	<ReferenceArea
																						yAxisId = "1"
																						x1 = { refAreaLeft }
																						x2 = { refAreaRight }
																						strokeOpacity = { 0.3 }
																		/>
																	)
																	:
																	null
							}

						</ComposedChart>

					</ResponsiveContainer>
					}
				</div>
				*/
				}



				{ /* ----------------------------------------------------------------------- */  }



				{

				/*
				(  ready_toRENDER   ==   true  )   &&

				(
					(  tipoPERFIL  ==  3  )   &&

					this.state.selected_installationgroup.info.max_cpu_version  ==  vCPU[ "elorav1" ]

				)  &&		// 3

				<div id = "dropdowncontainer" className = "container-fluid" style = {{marginBottom:"35px", marginLeft:"-10px"}}>
					{
					(  this_.state.Chart_Data_M1.length > 10  )  &&


					<ResponsiveContainer
										width="100%"
										height={150}	// 300

										id="id_RP_mychart_cpu__tipo_3_3"

										debounce={0}
					>

						<ComposedChart
										data = { this_.state.Chart_Data_M1.slice( 10, 45 ) }

										ref =	{
													( e ) =>
													{
														this.chart = e;
													}
												}
						>




							<Line type="step" dataKey="sp" stroke="orange" strokeWidth={3} />		// isAnimationActive={false}


							<CartesianGrid stroke="#ccc" />


							<XAxis dataKey="name"/>

							<YAxis
								domain = { [ 0, 100 ] }

								//interval = { 1 }

								tickCount={ 3 }
							/>

							{
							(  refAreaLeft  &&  refAreaRight  )		?
																	(	<ReferenceArea
																						yAxisId = "1"
																						x1 = { refAreaLeft }
																						x2 = { refAreaRight }
																						strokeOpacity = { 0.3 }
																		/>
																	)
																	:
																	null
							}

						</ComposedChart>

					</ResponsiveContainer>
					}
				</div>
				*/
				}





				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }
				{ /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */  }









				<br/>

				<span>
					{ this.showSpinner() }
				</span>


			</div>
		//
		)// return()


	}// render () --------------------------------------------

}// class Perfiles extends Component



//----


const mapStateToProps =		function ( state )
							{
								return	{
											auth: state.auth,
											core: state.core
										}
							}

const mapDispatchToProps =	{
								saveProfile: saveProfile
							}


export default withTranslation() (   connect( mapStateToProps, mapDispatchToProps )( Perfiles )   );
