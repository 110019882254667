
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {getInstallations, getInstallationDetail} from '../actions/core';

import { withTranslation } from 'react-i18next';

import { Link } from "react-router-dom";

import Moment from 'moment';
//import Spinner from 'react-spinner-material';


import axios from 'axios';
import CONFIG from '../config/config';

import Spinner from 'react-spinner-material';


import Masonry from 'react-masonry-css'

import Select from 'react-select';

import { Trans } from 'react-i18next';

import SelectorInstalaciones from './components/installations_select';


const $ = window.$;


const nombresMeses =
					{
						0: <Trans>calendar.january</Trans>,
						1: <Trans>calendar.february</Trans>,
						2: <Trans>calendar.march</Trans>,
						3: <Trans>calendar.april</Trans>,
						4: <Trans>calendar.may</Trans>,
						5: <Trans>calendar.june</Trans>,
						6: <Trans>calendar.july</Trans>,
						7: <Trans>calendar.august</Trans>,
						8: <Trans>calendar.september</Trans>,
						9: <Trans>calendar.october</Trans>,
						10: <Trans>calendar.november</Trans>,
						11: <Trans>calendar.december</Trans>
					}

const numberOfMonths =  12;




class Calendar extends Component
{

	constructor ( props )
	{

		super ( props );


		let months = [];

		let now = Moment("2019-1-1");


		for (  let i = 0;  i < numberOfMonths;  i++  )
		{
			months.push(
						{
							name: nombresMeses[ now.month() ],
							month : now.month() + 1,
							year: now.year()
						}
			);

			let lastday = now.endOf( 'month' );
			now = lastday.add( "1", "d" );
		}

		let installations = props.core.installations.slice();


		installations.shift();


		this.state =
					{
						installations: installations,

						//selected_installation: {installation_name: "", id:"", streetlights: []},

						selected_installationgroup:	{
														group_name: "",
														id:"",
														info:	{
																	streetlights: [],
																	available_sensors:[]
																}
													},

						selected_streetlight: 	{
													name: ""
												},

						clicked_month: 1,

						months: months,
						showLoader: true,
						view_as_cards: true,

						resumen:	{
										1:	{"summary":""},
										2:	{"summary":""},
										3:	{"summary":""},
										4:	{"summary":""},
										5:	{"summary":""},
										6:	{"summary":""},
										7:	{"summary":""},
										8:	{"summary":""},
										9:	{"summary":""},
										10:	{"summary":""},
										11:	{"summary":""},
										12:	{"summary":""}
									}
					};

		this.updateSchedules = this.updateSchedules.bind(this);
		this.saveData = this.saveData.bind(this);
		this.showSpinner = this.showSpinner.bind(this);
		this.requestSummary = this.requestSummary.bind(this);

	} // constructor(props)



	componentDidMount()
	{
		//this.props.getInstallations(this.props.auth.token);

	}// componentDidMount()



	requestSummary ( selected_installation_group )
	{
		let token = "Token " + this.props.auth.token;

		this.setState(  { showLoader:true }  );

		// Cargar resumen perfiles...
		axios.get(
					CONFIG.api_url + "/installationgroups/"		+		selected_installation_group.id		+		"/summarydayscheduleprofiles",

					{
						"headers":
						{
							'Content-Type': 'application/json',
							'Authorization': token
						}
					},
		).then(
				( result ) =>
				{
					this.setState(	{
										resumen: result.data,
										showLoader:false
									}
					); //

				}
		).catch(
				error => {  console.log( error );  }
		);//

	}// requestSummary ( selected_installation_group )




	saveData(  obj, callback )
	{
		const this_ = this;


		this.setState(  { showLoader: true }  );

		//console.log( this.state );

		let toSave = obj;


		let token = "Token " + this.props.auth.token;

		// Cargar perfiles diarios...

		//--

		axios.put(
					CONFIG.api_url 	+ 	"/installationgroups/" 		+	 this.state.selected_installationgroup.id 	+ 	"/dayscheduleprofiles?month=" 	+	 this.state.clicked_month 		+	  "&year=" 		+ 		2000,

					toSave,

					{
						"headers":
						{
							'Content-Type': 'application/json',
							'Authorization': token
						}
					},

		).then(
				( result ) =>
				{
					//console.log( result );

					this_.setState(  { showLoader: false }  );

					if (  callback !== undefined )
					{
						callback();
					}
				}
		).catch(
					error =>

						this.setState(
										{
											error,
											isLoading: false
										}
						)
		);


		$( "#filtrar" ).modal( "hide" );

	}




	showSpinner()
	{
		if (  !this.state.showLoader  )
		{
			return ( <div/> )
		}

		return(
				<div style={{position: "absolute", left: "48%", top: "45%"}}>
					<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
				</div>
		);
	}




	updateSchedules ( id )
	{
			let installid = id;

			if ( !installid )
			{
				installid=this.state.installations[0].id;
			}

			if ( installid == -1 )
			{
				installid = this.state.installations[1].id;
			}

			this.setState( { showLoader:true } );

			axios.get(
						CONFIG.api_url  +  "/installationgroups/" +  installid  +  "/scheduleprofiles",

						{
							"headers":
										{
											'Content-Type': 'application/json',
											'Authorization': "Token " + this.props.auth.token
										}
						}
			).then(
					( result ) =>
					{
						//console.log( result.data );

						this.setState( { scheduleprofiles: result.data, showLoader: false } );
					}
			).catch(
						error =>
							this.setState(
											{
												error,
												isLoading: false
											}
							)
			);

	}// updateSchedules ( id )




	render()
	{

		let needUpdate = false;


		let children_elements=[];


		if (  this.state.scheduleprofiles != undefined  )
		{

			//console.log(  this.state.selected_installationgroup.ELORA_dimming_mode  );


			children_elements =	this.state.scheduleprofiles.map(
			//
				( item, index ) =>
				{

					if (  item.tipoPERFIL  ==  this.state.selected_installationgroup.ELORA_dimming_mode  )
					{
						//console.log(  "item.tipoPERFIL:  "  +  item.tipoPERFIL  );

						return	(
						//
							<div className="my-masonry-grid_column">
								<div className="masonry-grid-element">

									<center>
									<a
										href="#"
										scheduleId = {item.id}

										onClick =	{
														( e ) =>
														{
															e.preventDefault();

															let obj=	{
																			"installation_id": this.state.selected_installationgroup.id,
																			"month": this.state.clicked_month,
																			"year": 2000,
																			"dayscheduleprofiles": {}
																		};

															for(  let i = 1;  i < 32;  i++  )
															{
																obj["dayscheduleprofiles"][ i ] =	{
																										id: item.id,
																										name: item.name,
																										scheduleprofile_id: item.id
																									};
															};// for

															this.saveData(
																			obj,

																			() =>
																			{
																				this.requestSummary(this.state.selected_installationgroup);
																			}
															);//
														}
													}
									>

										<div className="" style={{textAlign: "center"}}>

											<img src={`${CONFIG.server_url}${item.thumbnail}`}  alt="" style={{width: "100%", height: "100px"}}/>

											<span>{item.name}</span>

										</div>

									</a>
									</center>

								</div>
							</div>

						//
						) // return

					}// if

				}// ( item, index ) =>

			//
			);  // ... .map()

		}// if (  this.state.scheduleprofiles != undefined  )




		return(

				<div className="container-fluid">

					<div className="row">

						{
						this.state.showLoader &&

						<div style={{position: "absolute", left: "48%", top: "45%", zIndex: 100000}} >
							<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
						</div>
						}

						<div id="cab-general" className="container-fluid" style={{marginLeft: "3px"}}>
							<div className="actions-left col-md-4 col-xs-12" style={{marginTop: "-5px"}}>

								<li className="nav-item dropdown" >

									<SelectorInstalaciones

										showAll = { false }

										onDataLoaded =	{
															( e ) =>
															{
																//console.log(e);

																this.setState({showLoader:false});

																//this.setState({selected_installationgroup: e});
															}
														}

										onDataChanged =	{
															( e ) =>
															{
																//console.log(e);

																this.setState({selected_installationgroup: e});
																this.updateSchedules(e.id);
																this.requestSummary(e);

																//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);
																//CONFIG["setPreferredInstallation"](e.value);
															}
														}

									/>
								</li>

							</div>
						</div>


						<div id="filtrar" className="modal fade bd-filtrar-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
							<div className="modal-dialog modal-lg modal-dialog-centered">



								<div className="modal-content">

									<div className="modal-header">

										<h5 className="modal-title"> <Trans>calendar.select</Trans> </h5>

										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>
									</div>


									<div className="modal-body">

										{
										children_elements.length > 0 &&

										<Masonry
											breakpointCols={3}
											className="my-masonry-grid"
											columnClassName="my-masonry-grid_column">
											{ children_elements }
										</Masonry>
										}
									</div>


									<div className="modal-footer">

										<button type="button" className="btn" data-dismiss="modal" style={{float: "right"}}>
											<Trans>calendar.exit</Trans>
										</button>

									</div>

								</div>

							</div>
						</div>


					</div>



					<div id="contenido-perfiles" className="container-fluid">


						{
						this.props.auth.user.user_profile.userlevel == "EKIONA" &&

						<center> <br/> <h3> { this.state.selected_installationgroup.group_name } </h3> <br/><br/> </center>
						}


						<div className="row vista-bloques cont-meses" style={{marginBottom: "10px"}}>


						{
							(  this.state.months.length > 0   &&   this.state.selected_installationgroup.id !== ""  )  &&

							this.state.months.map(
							//
								(  item, index  ) =>
								{
									//window.alert( item );

									//--

									return	(
									//
										<div className="col-sm-12 col-md-3 col-xl-2">
											<div className="mes">

												<div className="cab-mes">

													<h3> { item.name } </h3>

													<a
														href="#a"
														data-toggle="modal"
														data-target="#filtrar"

														onClick =	{
																		( e ) =>
																		{
																			this.setState(  { clicked_month: item.month }  )
																		}
																	}
													>
														<img src="/img/ico/gray/ico-a-settings.svg" alt=""/>
													</a>

												</div>


												<div className="cont-mes">

													<div style={{position: "absolute", fontSize: "80%", color: "grey"}}>

														<br/>
														<br/>

														[  { this.state.resumen[ index + 1 ].summary }  ]

													</div>


													<br/>

													<Link to={`/calendar/${this.state.selected_installationgroup.id}/${item.month}/${item.year}`}>

														<br/>

														<button className="btn btn-xl" style={{width: "100%"}}>
															<Trans>calendar.see</Trans>
														</button>

													</Link>

												</div>


											</div>
										</div>
									//
									)// return

								}// (  item, index  ) =>
							//
							)// ... .map()

						}

						</div>
					</div>


				</div>
		)
  }

} // clase


const mapStateToProps = function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}

const mapDispatchToProps =	{
								getInstallations: getInstallations,
								getInstallationDetail: getInstallationDetail
							}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Calendar));