
const toLocalDate =
					( which ) =>
					{
						var d = new Date(  Date.parse( which )  );

						return d.toLocaleDateString();
					}

export default 	{
    				toLocalDate: toLocalDate,
				}