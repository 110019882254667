
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {getInstallations, saveProfile, getScheduleProfile, getScheduleProfiles} from '../actions/core';

import 'rc-slider/assets/index.css';

import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import Moment from 'moment';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

import { ComposedChart, LineChart, Line, Area, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";

import Spinner from 'react-spinner-material';

import axios from 'axios';
import CONFIG from '../config/config';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter, selectFilter } from 'react-bootstrap-table2-filter';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;



const $ = window.$;


class Historial extends Component
{

	constructor ( props )
	{
		super( props );

		this.state =	{
							isNew: true,
							showLoader: false,
							isLoading: false,
							id: props.match.params.id,
							schedule_id : -1,
							profileName: props.core.scheduleprofile.name,
							profileComments: props.core.scheduleprofile.comments,
							installations: props.core.installations,
							selected_installation: {installation_name: "", id:"", streetlights: []},
							selected_streetlight: {name: ""},
							view_as_cards: true,
							total_actions: 0,
							current_page: 0,
							actions: "",

							startDate: Moment(props.auth.user.oldest_action). subtract(1, "days"),
							endDate: Moment()
						};

		this.options_installations = {};
		this.options_installation_groups = {};
		this.options_users = {};

		this.filter = {};


		this.checkTranslation = this.checkTranslation.bind( this );

		this.recogerHistorial = this.recogerHistorial.bind( this );
		this.modificarComentario = this.modificarComentario.bind( this );


		this.actId = "";

		this.group_options = "";

	}// constructor ( props )

	

	componentDidMount ( )
	{
		//this.props.getInstallations(this.props.auth.token);

		this.setState( { showLoader: true } );

		this.recogerHistorial();
		this.checkTranslation();

	}// componentDidMount ( )



	checkTranslation ( )
	{
		let html = $( ".react-bootstrap-table-pagination-total" ).html();

		$( ".react-bootstrap-table-pagination-total" ).hide();


		if (  html  ===  undefined  )
		{
			setTimeout(
						( ) =>
						{
							this.checkTranslation();
						}

						, 100
			)//
		}
		else
		{
			let html = $( ".react-bootstrap-table-pagination-total" ).html();

			console.log( html );
			
			let rows = this.props.t( "app.table.showing_rows" );
			let to = this.props.t( "app.table.to" );
			let of = this.props.t( "app.table.of" );

			html = html.replace( "Showing rows", rows ).replace( "to&nbsp;", to ).replace( "of&nbsp;", of );

			setTimeout(
						( ) =>
						{
							$( ".react-bootstrap-table-pagination-total" ).html( html );
						}

						, 500
			);//			
		}//

	}// checkTranslation ( )



	modificarComentario ( id, message )
	{
		let token = "Token "  +  this.props.auth.token;

		if(  !message  )
		{
			message = "-";
		}

		// Cargar perfiles diarios...

		axios.put(
					CONFIG.api_url  +  "/actions/"  +  id  +  "/modify", 

					{ message: message },

					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}  
		).then(
				( result ) =>
				{
					this.setState( { isLoading: false } );
				}
		).catch(
				error =>
					this.setState(
									{
										error,
										isLoading: false
									}
					)//
		);

		$( "#inputModal" ).val( "" );

	}// modificarComentario ( id, message )




	recogerHistorial ( pagina, filtro )
	{

		if ( this.state.showLoader )
		{
			return;
		}

		if (  pagina == undefined  )
		{
			pagina = this.state.current_page  +  1;
		}
		else
		{
			pagina = pagina + 1;
		}

		console.log( pagina );

		this.state.showLoader = true;


		this.setState(
						{
							showLoader: true,
							isLoading:true
						}
		);//

		this.group_options = "";

		let token = "Token " + this.props.auth.token;
		
		// Cargar perfiles diarios...

		axios.get(
					CONFIG.api_url  +	"/actions?page="  +  pagina  +
										"&filtro="  +  JSON.stringify( filtro )  +

										"&fromDate="  +  this.state.startDate.unix()  +
										"&toDate=" +  this.state.endDate.unix(),

					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					},  
		).then(
				( result ) =>
				{
					let group_options_keys = [];
					let group_options = [];

					let user_options_keys = [];
					let user_options = []

					this.state.options_installation_groups = [];
					this.state.options_users = [];

					this.state.options_installation_groups = result.data.info.all_installation_groups;

					// SORT!
					// groups
					group_options_keys = Object.keys( this.state.options_installation_groups );

					group_options_keys.sort();

					group_options_keys.map(
											( item, index ) =>
											{
												if ( item !== "" )
												{
													group_options.push(
																		{
																			value: item,
																			label: item
																		}
													);//
												}// if
											}
					);// .map()


					if ( this.group_options == "" )
					{
						this.group_options = group_options;
					}//

					this.state.options_installation_groups = this.group_options;
					
					result.data.results.map(
												( item, index ) =>
												{
													let user = item.user;
													let install = item.installation;
													let install_group = item.installation_group;

													if (  Object.keys( this.options_installations ).indexOf( item.installation ) < 0  )
													{
														this.options_installations[install] = install;
													}

													if(  Object.keys( this.options_users ).indexOf( item.installation ) < 0  )
													{
														this.options_users[user]=user;
													}
												}
					);//.map()

					// sl
					user_options_keys = Object.keys( this.state.options_users );
					user_options_keys.sort();

					user_options_keys.map(
											( item, index ) =>
											{
												if (  item !== ""  )
												{
													user_options.push(
																		{
																			value: item,
																			label: item
																		}
													);//
												}
											}
					);//.map()

					this.state.options_users = user_options;


					this.setState(
									{
										actions:	result.data.results,
										total_actions:	result.data.count,
										isLoading:	false,
										current_page: pagina - 1
									}
					);

					setTimeout(
								( ) =>
								{
									this.setState( { showLoader: false } )
								}

								,1000
					);///
				}
		).catch(
					error =>
						this.setState(
										{
											error,
											isLoading: false
										}
						)//
		);   

	}// recogerHistorial ( pagina, filtro )



	render ( )
	{

		const showSpinner =
							<div style={{position: "absolute", left: "48%", top: "45%"}}>
								<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
							</div>;

		const [ page, sizePerPage, totalSize ] =	[
														this.state.current_page,
														50,
														this.state.total_actions
													];

													

		const columnsLess =	[

							{
								dataField: 'stored_timestamp',

								headerStyle:
											() =>
											{
												return { width: "10%" };
											},

								text: <Trans>history.date</Trans>,

								sort: true,

								headerStyle:
											{
												verticalAlign: 'middle',
											},

								formatter:
											( e, row )=>
											{
												//let date = e.replace("T"," ").split("+")[0];

												let date = Moment( e ).format( 'DD/MM/YYYY HH:mm' );

												return	<span> { date } </span>
											}
							}, 



							{
								dataField: 'installation_group',
								text: <Trans>history.installation_group</Trans>,
								width: "100",
								sort: true,

								filter:
										selectFilter(
														{
															options: this.state.options_installation_groups,
															placeholder: `${this.props.t("history.installation_group")}`
														}
										),

								formatter:
											( value, row ) =>
											{
												return	 <span> { value } </span>
											}
							},


							{
								dataField: 'description',
								text: <Trans>history.description</Trans> ,
								sort: true,

								filter:
										textFilter(
													{
														className: 'test-classname',
														delay: 1000,
														placeholder: `${this.props.t('history.description2')}`
													}
										),

								formatter:
											( value, row ) =>
											{
												return	<span> { value } </span>
											}
							},


							{
								dataField: 'comment',
								text: <Trans>history.comment</Trans> ,
								sort: true,
								classes: 'd-none d-md-table-cell',
								headerClasses: 'd-none d-md-table-cell',

								filter:
										textFilter(
													{
														className: 'test-classname',
														delay: 1000,
														placeholder: `${this.props.t('history.comment')}`
													}
										),

								formatter:
											( value, row ) =>
											{
												return (	<div>

																<button 
																	actId = { row.id }
																	className="btn btn-xm"
																	data-toggle="modal" 
																	data-target=".bd-filtrar-modal-sm"

																	onClick =
																				{
																					( e ) =>
																					{
																						this.actId = row.id
																					}
																				}
																>
																	<small>
																		<Trans>history.change</Trans>
																	</small>

																</button>


																&nbsp;&nbsp;&nbsp;
																{value}

															</div>
												) //return()
											}
							}

						];




		const columnsAll =	[

							{
								dataField: 'stored_timestamp',

								headerStyle:
											() =>
											{
												return { width: "10%" };
											},

								text: <Trans>history.date</Trans>,

								sort: true,

								headerStyle:
											{
												verticalAlign: 'middle',
											},

								formatter:
											( e, row )=>
											{
												//let date = e.replace("T"," ").split("+")[0];

												let date = Moment( e ).format( 'DD/MM/YYYY HH:mm' );

												return	<span> { date } </span>
											}
							}, 


							(
							this.props.auth.user.user_profile.userlevel == "EKIONA" &&

							{
								dataField: 'user',
								text: <Trans>history.user</Trans>,
								sort: true,
								classes: 'd-none d-md-table-cell',
								headerClasses: 'd-none d-md-table-cell',

								filter:
										selectFilter(
														{
															options: this.options_users,
															placeholder: `${this.props.t("history.user")}`
														}
										),

								formatter:
										( value, row ) =>
										{
											return	<div> { value } </div>
										}
							}
							),


							{
								dataField: 'installation_group',
								text: <Trans>history.installation_group</Trans>,
								width: "100",
								sort: true,

								filter:
										selectFilter(
														{
															options: this.state.options_installation_groups,
															placeholder: `${this.props.t("history.installation_group")}`
														}
										),

								formatter:
											( value, row ) =>
											{
												return	 <span> { value } </span>
											}
							},


							{
								dataField: 'description',
								text: <Trans>history.description</Trans> ,
								sort: true,

								filter:
										textFilter(
													{
														className: 'test-classname',
														delay: 1000,
														placeholder: `${this.props.t('history.description2')}`
													}
										),

								formatter:
											( value, row ) =>
											{
												return	<span> { value } </span>
											}
							},


							{
								dataField: 'comment',
								text: <Trans>history.comment</Trans> ,
								sort: true,
								classes: 'd-none d-md-table-cell',
								headerClasses: 'd-none d-md-table-cell',

								filter:
										textFilter(
													{
														className: 'test-classname',
														delay: 1000,
														placeholder: `${this.props.t('history.comment')}`
													}
										),

								formatter:
											( value, row ) =>
											{
												return (	<div>

																<button 
																	actId = { row.id }
																	className="btn btn-xm"
																	data-toggle="modal" 
																	data-target=".bd-filtrar-modal-sm"

																	onClick =
																				{
																					( e ) =>
																					{
																						this.actId = row.id
																					}
																				}
																>
																	<small>
																		<Trans>history.change</Trans>
																	</small>

																</button>


																&nbsp;&nbsp;&nbsp;
																{value}

															</div>
												) //return()
											}
							}

						];




		const defaultSorted =	[
									{
										dataField: 'name',
										order: 'asc'
									}
								];


								
		return (
					<div className="container-fluid" style={{paddingBottom: "100px"}}>

						{
						this.state.showLoader  &&

						<showSpinner/>
						}

						<div className="row">

							<div id="cab-general" className="container-fluid" style={{minHeight: "80px"}}>
								

								<div className="actions-left d-none d-md-block">
									<h2>
										<Trans>history.history</Trans>
									</h2>
								</div>


								<div className="actions-middle" style={{height: "0px"}}>
									<div className="">

										<DateRangePicker
											startDate = { this.state.startDate } // momentPropTypes.momentObj or null,
											startDateId = "your_unique_start_date_id" // PropTypes.string.isRequired,
											endDate = { this.state.endDate } // momentPropTypes.momentObj or null,

											endDateId = "your_unique_end_date_id" // PropTypes.string.isRequired,

											isOutsideRange = { () => false }
											numberOfMonths = { 1 }

											onDatesChange =
															{
																(  { startDate, endDate }  ) =>
																{
																	this.setState(
																					{
																						startDate,
																						endDate
																					}
																	);

																	setTimeout(
																				( ) =>
																				{
																					this.recogerHistorial(  this.state.current_page  );
																				}

																				, 500
																	);
																	
																	//console.log("ASDADASD");
																}

															} // PropTypes.func.isRequired,

											focusedInput = { this.state.focusedInput } // PropTypes.oneOf([START_DATE, END_DATE]) or null,

											onFocusChange =	{
																focusedInput =>
																	this.setState( { focusedInput } )
															} // PropTypes.func.isRequired,
										/>

									</div>
								</div>

								<div className="actions-right">
								</div>

							</div>
		
						</div>




						<br/>

						<div id="filtrar" className="modal fade bd-filtrar-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
							<div className="modal-dialog modal-sm modal-dialog-centered">
								<div className="modal-content">


									<div className="modal-header">

										<h5 className="modal-title">
											Comentario
										</h5>

										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">
												×
											</span>
										</button>

									</div>


									<div className="modal-body">

										<input	className = "campo-modal form-control form-control-lg" 
												id = "inputModal" 
												type = "text"
												placeholder = "Texto..."
										/>

									</div>


									<div className="modal-footer">

										<button	type = "button"
												data-dismiss = "modal"
												className = "btn btn-orange"

												onClick =
														{
															( e ) =>
															{
																this.modificarComentario(
																							this.actId,
																							$( "#inputModal" ).val()
																);//

																setTimeout(
																			( ) =>
																			{
																				this.recogerHistorial();
																			}

																			, 250
																);//
															}
														}
										>
											OK
										</button>

									</div>

								</div>
							</div>
						</div>

						<br/>
						<br/>



						{
						this.state.actions !== ""  &&

						<div style={{opacity: this.state.isLoading?"15%":"100%"}} className="compactTable">

							<BootstrapTable
									columns = {  ( this.props.auth.user.user_profile.userlevel == "EKIONA" )  ?  columnsAll  :   columnsLess  }  
									data = { this.state.actions } 
									keyField = 'id'
									classes = "table table-hover tabla-comun"

									filter = { filterFactory({remote: true}) }

									remote = { { pagination: true, filter: true, sort: true } }

									onTableChange =
													{
														( tipo, evento ) =>
														{
															let temp = evento;
															temp.data = undefined;

															this.recogerHistorial(
																					this.state.current_page,
																					{
																						"type": tipo,
																						"value": temp
																					}
															);//this.recogerHistorial()
														}//
													}

									pagination =
												{ 
													paginationFactory(
																		{
																			page, // Specify the current page. It's necessary when remote is enabled
																			sizePerPage, // Specify the size per page. It's necessary when remote is enabled
																			totalSize, // Total data size. It's necessary when remote is enabled

																			pageStartIndex: 0, // first page will be 0, default is 1
																			paginationSize: 3,  // the pagination bar size, default is 5
																			showTotal: true, // display pagination information

																			sizePerPageList:
																							[
																								{
																									text: '5',
																									value: 5
																								},

																								{
																									text: '10',
																									value: 10
																								},

																								{
																									text: 'All',
																									value: this.state.total_actions
																								}
																							], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text

																			withFirstAndLast: false, // hide the going to first and last page button
																			alwaysShowAllBtns: true, // always show the next and previous page button

																			firstPageText: 'First', // the text of first page button
																			prePageText: 'Prev', // the text of previous page button
																			nextPageText: 'Next', // the text of next page button
																			lastPageText: 'Last', // the text of last page button
																			nextPageTitle: 'Go to next', // the title of next page button
																			prePageTitle: 'Go to previous', // the title of previous page button
																			firstPageTitle: 'Go to first', // the title of first page button
																			lastPageTitle: 'Go to last', // the title of last page button

																			hideSizePerPage: true, // hide the size per page dropdown
																			hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false

																			onPageChange:
																							( page, sizePerPage ) =>
																							{
																								this.recogerHistorial( page )
																							}, // callback function when page was changing

																			onTableChange:
																							( type, newState ) =>
																							{
																								console.log( type );
																							}
																		}
													)//
												}
							/>
						</div>
						}

						<br/>

					</div>
		)// return

	}// render()
	
}// class Historial extends Component


//----


const mapStateToProps =	function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}


const mapDispatchToProps =	{
								getInstallations: getInstallations,
								saveProfile: saveProfile,
								getScheduleProfile: getScheduleProfile,
								getScheduleProfiles: getScheduleProfiles
							}

export default connect(  mapStateToProps,  mapDispatchToProps  )(  withTranslation()(Historial)  );