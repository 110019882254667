
import React, { Suspense, Component } from 'react';

import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {getInstallations} from '../actions/core';

import { Link } from "react-router-dom";

import Moment from 'moment';

import Spinner from 'react-spinner-material';


import axios from 'axios';
import CONFIG from '../config/config';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';


const $ = window.$;

let nombresMeses;
let nombresDias;


var ELORA_dimm_MODE =  -1;




function toLocaleDate ( date )
{

	let temp = date.toLocaleDateString();

	let date_str = "";



	temp.split( "/" ).map(
							( item, index ) =>
							{
								if ( item.length == 1 )
								{
									item =  "0"  +  item;
								}

								if ( date_str == "" )
								{
									date_str = item;
								}
								else
								{
									date_str += "/"  +  item;
								}
							}
	);

	return date_str;

} // toLocaleDate ( date )



class CalendarDetail extends Component
{

	constructor ( props )
	{
		super( props );

		const t = props.t;

		nombresMeses = 
						{
							0: t("calendar.january"),
							1: t("calendar.february"),
							2: t("calendar.march"),
							3: t("calendar.april"),
							4: t("calendar.may"),
							5: t("calendar.june"),
							6: t("calendar.july"),
							7: t("calendar.august"),
							8: t("calendar.september"),
							9: t("calendar.october"),
							10: t("calendar.november"),
							11: t("calendar.december")
						}

		nombresDias =
						{
							1: t("calendar.monday"),
							2: t("calendar.tuesday"),
							3: t("calendar.wednesday"),
							4: t("calendar.thursday"),
							5: t("calendar.friday"),
							6: t("calendar.saturday"),

							0: t("calendar.sunday")
						}


		let month = props.match.params.month;
		let year = props.match.params.year;
		
		year = Moment().year();

		let days = [];

		let initial = Moment( year + "-" + month + "-1" );

		initial.add( 12, "hour" );


		for (  let i = 0;  i < initial.clone().endOf( 'month' ).date();  i++  )
		{
			let this_year = Moment().year();
			let bgcolor = "";

			//if (  Moment()	>	Moment(  initial.year()  +  "-"  +  ( initial.month() + 1 )  +  "-"  +  ( i + 1 )  /*initial.date()*/ )  ) 	// SERGIO 24-11-21
			if (  Moment()	>=	Moment(  initial.year()  +  "-"  +  ( initial.month() + 1 )  +  "-"  +  ( i + 2 )  /*initial.date()*/ )  ) 	// SERGIO 16-12-21
			{
				this_year = this_year + 1;
				bgcolor = "#dedede";
			}//


			let now2 = Moment(  this_year  +  "-"  +  ( initial.clone().month() + 1 )  +  "-"  +  ( i + 1 )  );


			now2.add( 12, "hour" );


			let dow = now2.day()  +  1;


			if (  dow > 6  )
			{
				dow = 0;
			}

			//console.log(now2);
			//console.log(i);

			if (  now2.month() == initial.month()  )
			{
				days.push(
							{
								day: now2.date(),
								month: now2.month(),
								year: this_year,
								name: nombresDias[now2.day()],
								bgcolor: bgcolor,
								dow: dow,

								name2: toLocaleDate( now2.toDate() ) //now2.date() + " de " + nombresMeses[now2.month()]
							}
				);
			}// if

		}// for

		//---

		this.state =
					{
						installations: props.core.installations,

						selected_installation:
												{
													installation_name: "",

													id: props.match.params.installid,

													group_name: "",

													streetlights: []
												},

						selected_streetlight: {  name: ""  },

						selected_days: {},
						clicked_day: -1,
						select_unselect: false,
						days: days,
						scheduleprofiles: [],
						dayscheduleprofiles: {},
						showSpinner: false,
						dayfilter: "all",
						profilefilter: "all"
					};

		this.checkboxes = [];

		this.showSpinner = this.showSpinner.bind( this );


		//-----


		let token = "Token "  +  this.props.auth.token;



		// 		https://api.ilumek.com/api/v1/installationgroups/cb88992d-dc30-4e21-8a3f-b76851c1b0d5


		axios.get(
					CONFIG.api_url  +  "/installationgroups/"  +  props.match.params.installid,

					{
						"headers":
									{      
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
				( result ) =>
				{

					//console.log(  "1st ELORA: "  +  result.data.ELORA_dimming_mode  );

					ELORA_dimm_MODE =  result.data.ELORA_dimming_mode;

					//--

					//console.log(  JSON.stringify(result)  );

					this.setState( { selected_installation: result.data } );

					this.state.selected_installation.group_name =  result.data.group_name;



					//console.log(  this.state.selected_installation.group_name  );

					//this.setState( { selected_installation.group_name: result.data.group_name } );

					//this.setState( { selected_installation.group_name: result.data.group_name } );
				}
		).catch(
					error =>

						this.setState(
										{
											error,
											isLoading: false
										}
						)
		);        



		//-------------------------

		
		// Cargar perfiles...

		axios.get(
					CONFIG.api_url  +  "/installationgroups/"  +  props.match.params.installid  +  "/scheduleprofiles",

					 {
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
				( result ) =>
				{
					//window.alert(  result.data.group_name 	);

					//console.log(  result.data  );


					this.setState( { scheduleprofiles: result.data } );

					

					//console.log(  "INST_GROUP_MOD:  "  +  /*this.state.selected_installation.ELORA_dimming_mode*/ ELORA_dimm_MODE    );


					let temp = {};

					result.data.map(
										( item, index ) =>
										{
											//console.log( index );
											//console.log( item );


											//if (  item.tipoPERFIL  ==  ELORA_dimm_MODE /*this.state.selected_installation.ELORA_dimming_mode*/  )
											//{

												//console.log(  item  );

												temp[ item.day ] = 
																	{
																		"id": item.scheduleprofile.id,

																		"name": item.scheduleprofile.name,

																		"scheduleprofile_id": item.scheduleprofile.id,
																	};
											//}// if

										}// ( item, index ) =>
					);// .map


					this.setState(  { dayscheduleprofiles: temp }  );
					
				}
		).catch(
					error =>
						this.setState(
										{
											error,
											isLoading: false
										}
						)
		);//





		//---

		// Cargar perfiles diarios...
		axios.get(
					CONFIG.api_url  +  "/installationgroups/"  +  props.match.params.installid  +  "/dayscheduleprofiles?month="  +  month  +  "&year="  +  2000,

					 {
						"headers":
									{      
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
				( result ) =>
				{
					let temp = {};

					//	if (  item.tipoPERFIL == this.state.selected_installation.ELORA_dimming_mode  )


					result.data.map(
										( item, index ) =>
										{
											temp[ item.day ] = 
																{
																	"id": item.scheduleprofile.id,
																	"name": item.scheduleprofile.name,

																	"scheduleprofile_id": item.scheduleprofile.id,
																};
										}
					);

					this.setState(  { dayscheduleprofiles: temp }  );
				}
		).catch(
					error =>
						this.setState(
										{
											error,
											isLoading: false
										}
						)
		);//

		this.selectAll = this.selectAll.bind( this );
		this.unselectAll = this.unselectAll.bind( this );

	} // constructor ( props )
	


	componentDidMount ( )
	{
		if (  this.state.installations.length < 1  )
		{
			this.props.getInstallations(  this.props.auth.token, true  );
		}    
	} // componentDidMount()



	showSpinner ( )
	{
		if ( !this.state.showSpinner )
		{
			return ( <div/> )
		}

		return(

				<div style={{position: "fixed", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "9999999"}}>
					<div style={{position: "absolute", left: "48%", top: "45%"}}>
						<Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>
				</div>     
		);

	}  // showSpinner ( )



	saveData ( )
	{
		const this_ = this;

		this.setState( {  showSpinner: true }  );

		let toSave =
					{
						installation_id: this.state.selected_installation[ "id" ],
						month: this.state.days[0][ "month" ]  +  1,
						year: 2000, //this.state.days[0]["year"],
						dayscheduleprofiles: this.state.dayscheduleprofiles
					};


		let token = "Token "  +  this.props.auth.token;


		// Cargar perfiles diarios...

		axios.put(
					CONFIG.api_url  +  "/installationgroups/"  +  this.props.match.params.installid  +  "/dayscheduleprofiles?month="  +  toSave["month"]  +  "&year="  +  2000, 
					toSave,
		
					{
						"headers":
									{      
										'Content-Type': 'application/json',
										'Authorization': token
									}
					},  
		).then(
					( result ) =>
					{
						this_.setState( { showSpinner: false } );
					}
		).catch(
					error =>
						this.setState(
										{
											error,
											isLoading: false
										}
						)
		);   
		
	} // saveData ( )




	selectAll ( )
	{
		if ( this.state.select_unselect )
		{
			this.unselectAll();
			return;
		}

		let temp = this.state.selected_days;

		this.checkboxes.map(
								( item, index ) =>
								{
									if ( !item )
									{
										return
									};

									this.checkboxes[ index ].checked = true;
									temp[  this.checkboxes[index].getAttribute( "day" )  ] = true;
								}
		);

		this.setState(
						{
							selected_days: temp,
							select_unselect: true
						}
		);

	} // selectAll ( )




	unselectAll ( )
	{
		let temp = this.state.selected_days;

		this.checkboxes.map(
								( item, index ) =>
								{
									if ( !item )
									{
										return
									};

									this.checkboxes[index].checked  = false;

									delete temp[  this.checkboxes[ index ].getAttribute( "day" )  ];
								}
		);

		this.setState(
						{
							selected_days: temp,
							select_unselect: false
						}
		);

	} // unselectAll ( )



	render ( )
	{

		if (  this.state.selected_installation.installation_name == "" )
		{
			if ( this.state.installations.length > 0 )
			{
				this.setState(
								{	selected_installation: this.state.installations[0]	}
				);
			}
		}

		if ( this.state.selected_streetlight.name == "" )
		{
			if (  this.state.installations.length > 0  &&  this.state.installations[0].streetlights.length > 0  )
			{
				this.setState(
								{
									selected_streetlight: this.state.installations[0].streetlights[0]
								}
				);
			}        
		}

		//console.log( this.state.selected_installation );

		//---

		return(
				<div className="container-fluid">

					{ this.showSpinner() }

					<div className="row">

						<div id="cab-general" className="container-fluid">
							
							<Link className="actions-left" to="/calendar" style={{maxWidth: "200px", marginTop: "-5px"}}>

								<div className="volver" style={{width: "150px"}}>
									<img src="/img/ico/gray/ico-c-turnleft.svg" alt="" style={{display: "inline-block"}}/>

									<div className="nav-link dropdown-toggle noafter" role="button" aria-haspopup="true" aria-expanded="false" style={{display: "inline-block"}}> 
										<Trans>calendar.back</Trans>
									</div>
								</div>

							</Link>

							{
							/*
							<div >
								<center> 
								<h4 style={{paddingTop:"0.5rem", marginBottom:"-2.5rem", marginTop:"0.0rem", marginRight:"13rem", marginLeft:"0.0rem"}}> { this.state.selected_installationgroup.group_name } </h4>
								</center>
							</div>
							*/
							}				

							<div className="actions-right">
								<button type="submit" className="btn btn-orange d-none d-md-block" style={{float: "right"}} onClick={(e)=>{ this.saveData()}}>
									<Trans>calendar.save</Trans>
								</button>
							</div>

							<div className="actions-right" style={{marginTop: "-20px", paddingRight: "20px"}}>
								<button
									type = "submit"
									className = "btn btn-orange d-block d-md-none"
									style = { { float: "right" } }
								
									onClick =
												{
													( e ) =>
													{
														this.saveData()
													}
												}
								>
									<Trans>calendar.save</Trans>
								</button>
							</div>

						</div>


						<div id="filtrar" className="modal fade bd-filtrar-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

							<div className="modal-dialog modal-lg modal-dialog-centered">
							
								<div className="modal-content">

									<div className="modal-header">
										<h5 className="modal-title">
											<Trans>calendar.select</Trans>
										</h5>

										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>
									</div>

									
									<div className="modal-body">

										<center>
										<div className="row">
										{
											this.state.scheduleprofiles  &&

											this.state.scheduleprofiles.map(
											//
												( item, index ) =>
												{
													//console.log( item );
													// ELORA_dimming_mode
													// tipoPERFIL


													if (  item.tipoPERFIL == this.state.selected_installation.ELORA_dimming_mode  )
													{

														return (
														//

															<div className="col-sm-12 col-md-4 col-xl-3" style = { { margin: "auto" } }>
																<div className="" style = { { backgroundColor: "#fafafa", border: "1px solid gray", padding: "3px", margin: "auto" } }>

																	<center>
																	<a
																		href = "#"
																		scheduleId = { item.id }
																		
																		style = { { margin: "auto" } }

																		onClick = 
																					{
																						( e ) =>
																						{ 
																							e.preventDefault();

																							let temp = this.state.dayscheduleprofiles;
																							let schedule = {};

																							for(  let i = 0;  i < this.state.scheduleprofiles.length;  i++  )
																							{
																								if ( this.state.scheduleprofiles[ i ].id == item.id )
																								{
																									schedule = this.state.scheduleprofiles[i];
																								}
																							} // for

																							temp[ this.state.clicked_day ] = schedule;

																							if (  this.state.clicked_day == -1  )
																							{
																								this.state.days.map(
																														( item, index ) =>
																														{
																															if (  this.checkboxes[ item.day ].checked  )
																															{
																																temp[ item.day ] = schedule;
																															}
																														}
																								);
																							}

																							this.setState( { dayscheduleprofiles: temp } );

																							$( "#filtrar" ).modal( "hide" );

																						}
																					}
																	>
																		<div className="" style={{textAlign: "center"}}>

																			<img src = {`${CONFIG.server_url}${item.thumbnail}`}  alt = "" style = { { width: "100%", height: "100px" } }/>

																			<span> { item.name } </span>

																		</div>

																	</a>
																	</center>

																</div>
															</div>
															
														//
														) // return ()

													}// if

												} // ( item, index ) =>
											//
											) // ... .map()
										}
										</div>
										</center>

									</div>
									
									<div className="modal-footer">
										<button type="button" className="btn" data-dismiss="modal" style={{float: "right"}}><Trans>calendar.exit</Trans></button>
									</div>

								</div>
								

							</div>
						</div>
		
					</div>

					{ /* ------------------------------- */}

					<div id="contenido-perfiles" className="container-fluid" style={{marginBottom: "130px"}}>

						{
						this.props.auth.user.user_profile.userlevel == "EKIONA" &&
							
						<center> <br/> <h3> { this.state.selected_installation.group_name   } </h3> </center>
						}

						<div className="row vista-bloques cont-meses">

							<div id="contenido-mes" className="container">
								<div className="row cont-meses">


									<table id="tabla-calendario" cellspacing="5" cellpadding="5">
									<tbody>

										<tr>
										<th>

										<ul>
											<li>
											</li>

											<li>
												<Trans>calendar.date</Trans>
												<br/>
											</li>

											<li>
												<Trans>calendar.day</Trans>
												<br/>

												<select
													onChange =
																{
																	( e ) =>
																	{
																		let day = e.target.value;
																		this.setState( { dayfilter: day } );
																	}
																}
												>
													<option value="all">
														{  this.props.t( "calendar.all" )  }
													</option>

													{
													Object.keys( nombresDias ).map(
																					( item, index ) =>
																					{
																						let temp =  item - 1;
																						
																						if ( temp < 0 )
																						{
																							temp = 6;
																						}

																						return	<option value={item}>
																									{ nombresDias[temp] }
																								</option>
																					}
													) // ... .map()
													}
												</select>
											</li>

											<li>
												<Trans>calendar.assigned_profile</Trans>
												<br/>

											<select
												onChange =
															{
																( e ) =>
																{
																	let profile = e.target.value;
																	this.setState( { profilefilter: profile } );
																}
															}
											>
												<option value="all">
												{
													this.props.t("calendar.all")
												}
												</option>

												{
												this.state.scheduleprofiles.map(
																					( item, index ) =>
																					{
																						return	<option value = { item.id }>
																									{ item.name }
																								</option>
																					}
												) // ... .map()
												}

											</select>

											</li>

											<li></li>
										</ul>

										</th>
										</tr>

										{
										this.state.days.map(
																( item, index ) =>
																{
																	//if (this.checkboxes[2]!==undefined && item.day>9) {
																	//  this.checkboxes[2].checked=true;
																	//}

																	if(  this.state.dayfilter  !=  "all"  )
																	{
																		if(  this.state.dayfilter  !=  item.dow  )
																		{
																			return;
																		}
																	}

																	if (  this.state.profilefilter != "all"  &&  this.state.dayscheduleprofiles[ item.day ]  )
																	{
																		if (  this.state.profilefilter  !=  this.state.dayscheduleprofiles[ item.day ].id  )
																		{
																			return;
																		}
																	}

																	return	(
																				<tr>
																					<td colspan="5">

																						<ul style={{backgroundColor: `${item.bgcolor}`}}>

																							<li>
																								<input
																									type="checkbox"
																									name="free"
																									value="fri09"
																									day = { item.day }
																									
																									ref = 
																											{
																												( e ) =>
																												{
																													this.checkboxes[ item.day ] = e
																												}
																											}

																									onClick = 
																												{
																													( e ) =>
																													{
																														let temp = this.state.selected_days;

																														let day = e.target.getAttribute( "day" );

																														temp[ day ] = e.target.checked;

																														if (  temp[day] == false  )
																														{
																															delete temp[day];
																														}

																														this.setState( {  selected_days: temp } )
																													}
																												}
																							/>
																							</li>


																							<li>
																								<strong> { item.name2 } </strong>
																							</li>


																							<li>
																								{ item.name }
																							</li>


																							<li>
																							{
																								this.state.dayscheduleprofiles[ item.day ]  !==  undefined  &&

																									<span>
																										{ this.state.dayscheduleprofiles[ item.day ].name }  
																									</span>
																							}

																							{
																								this.state.dayscheduleprofiles[ item.day ]  ===  undefined  &&

																								<span>
																									----
																								</span>
																							}
																							</li>

																							<li>
																								<a
																									href = "#a"
																									data-toggle = "modal"
																									data-target = "#filtrar"

																									onClick = 
																												{
																													( e ) =>
																													{
																														this.setState( { clicked_day: item.day } )
																													}
																												}
																								>
																									<img src="/img/ico/gray/ico-a-settings.svg" alt=""/>
																								</a>
																							</li>

																						</ul>

																					</td>
																				</tr>

																	) // return ()
																}
										) // ... .map()
										}
									</tbody>
									</table>

									<div id="cal-actions-inline">
										<ul>

										{/* To be able to center */}
										{
											Object.keys( this.state.selected_days ).length < 1  &&

											<div style={{marginLeft: "-50px"}} className="row">
												<li className="col-md-6">

													<input
														className="btn-outline-light"
														type="text"
														
														value =
																{
																	this.state.select_unselect  ?  `${this.props.t("calendar.select_none")}`  :  `${this.props.t("calendar.select_all")}`
																}

														name = ""
	
														onClick =
																	{
																		( e ) =>

																		{
																			this.selectAll();
																		}
																	}
													/>

												</li>

												<li className="col-md-6">
													<p>
														<span>
																{  Object.keys( this.state.selected_days ).length  }
														</span>
														<Trans>calendar.selected_days</Trans>
													</p>
												</li>
											</div>
										}

										{
											Object.keys( this.state.selected_days ).length > 0  &&

											<div>
												<li >
													<input
														className = "btn-outline-light"
														type = "text"

														value =
																{
																	this.state.select_unselect  ?  `${this.props.t("calendar.select_none")}`  :  `${this.props.t("calendar.select_all")}`
																}
														
														name = ""
														
														onClick =
																	{
																		( e ) =>
																		{
																			this.selectAll();
																		}
																	}
													/>
												</li>

												<li >
													<p>
														<span>
															{  Object.keys( this.state.selected_days ).length  }
														</span>

														<Trans>calendar.selected_days</Trans>
													</p>
												</li>

												<li>
													<a
														href = "#a"
														data-toggle = "modal"
														data-target = "#filtrar"

														onClick =
																	{
																		( e ) =>
																		{
																			this.setState(
																							{  clicked_day: - 1  }
																			)
																		}
																	}
													>
														<img src="/img/ico/gray/ico-a-settings.svg" alt=""/>
													</a>
												</li>
											</div>
										}

										</ul>
									</div>


								</div>
							</div>
						</div>
					</div>


				</div>

		) // return (

	} // render ()
	
} // class CalendarDetail extends Component

//----


const mapStateToProps = function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}


const mapDispatchToProps =	{
  								getInstallations: getInstallations,
							}


let temp = withTranslation()( CalendarDetail );


export default connect (mapStateToProps, mapDispatchToProps )(temp);