import React, { Component } from 'react';
import ReactDOM from 'react-dom';				//----------------------------------------------
import { Link } from "react-router-dom";

import { connect } from 'react-redux';



import {login} from '../actions/auth';

import {saveProfile} from '../actions/core';

import 'rc-slider/assets/index.css';



import Tooltip from 'rc-tooltip';


import Slider from 'rc-slider';

import Moment from 'moment';


import	{
			ComposedChart,

			Bar,

			LineChart,

			Line, // as LineRC,


			Area,


			Tooltip as Ttip,


			CartesianGrid,
			XAxis,
			YAxis,


			Scatter,
			ScatterChart,


			ResponsiveContainer,
			ReferenceArea,
			Legend

} from 'recharts';


/*
import domtoimage from 'dom-to-image';
//import fileDownload from "js-file-download";



import Select from 'react-select';
import { Trans } from 'react-i18next';
*/

import Spinner from 'react-spinner-material';


import { withTranslation } from 'react-i18next';

import { setup } from 'axios-cache-adapter';

import CONFIG from '../config/config';


import SelectorInstalaciones from './components/installations_select';










//import { ChartSvg } from './Chart';


//import reactHtmlParser from "react-html-parser";



//import parse from "html-react-parser";



//import { parse as svgparseLIB } from "svg-parser";




//import * as font_kit_next from 'fontkit-next';

//import * as PDF_KIT from 'pdfkit';

//import * as PDF_KIT_fork_Folios from '@foliojs-fork/pdfkit';



//const font_KIT_Next = require( 'fontkit-next' );	// require( 'fontkit' );

//const font_KIT = require( 'fontkit' );	// require( 'fontkit' );



//const my_fon_K_N = new font_KIT_Next;



//const PDF_K_DOC = require( 'pdfkit' );
//const doc = new PDF_K_DOC;




//import * as SVG_to_PDF_KIT from 'svg-to-pdfkit';


//const fs = require( 'fs' );

//const PDF_K_DOC = require( 'pdfkit' ).default;
//import * as PDF_K_DOC from "./pdfkit.standalone.js";


//import * as PDF_K_DOC from 'pdfkit';

//var PDFDocument = require( 'pdfkit' ).default;




//var PDF_mio = pdfMake.createPdf( docDefinition );

//import '../pdfkit.standalone.js';




//import { readFileSync } from 'fs';
//import * as fs from 'fs';

//const fs = require( 'fs' );



//import * as fs from 'fs';

//const fs = __non_webpack_require__( "fs" );



//console.log(  "log de fs:  " + fs.readFileSync( "" )  );


var offsHOUR_timezone_installation_group =  0;


const createSliderWithTooltip = Slider.createSliderWithTooltip;

const Range = createSliderWithTooltip( Slider.Range );
const Handle = Slider.Handle;


const $ = window.$;




var pdfMake = require( 'pdfmake/build/pdfmake.js' );
var pdfFonts = require( 'pdfmake/build/vfs_fonts.js' );

pdfMake.vfs = pdfFonts.pdfMake.vfs;

var my_docDefinition =	{
							content : [ 'hellloo helloo hellooo' ]
						};


//-----------------------------------------------------




const selectStyles =	{
							menu:
								styles =>
								(
									{
										...styles,
										zIndex: 999
									}
								)
						};

const axios =	setup(
						{
							cache:	{
										maxAge: 1000
									}
						}
				)//



const handle =	( props ) =>
				{
					const	{
								value,
								dragging,
								index,
								...restProps
							}
							= props;


					return	(
								<Tooltip
										prefixCls = "rc-slider-tooltip"
										overlay = { value }
										visible = { dragging }
										placement = "top"

										key = { index }
								>
									<Handle value = { value } { ...restProps } />

								</Tooltip>
					);
				}; //


/*
const marks =	{
					0: "0%",
					10: "",
					20: "",
					30: "",
					40: "",
					50: "",
					60: "",
					70: "",
					80: "",
					90: "",
					100: "100%",
				};
*/
/*
const seconds =	[
					{ value: 0,   label: "0"   },
					{ value: 10,  label: "10"  },
					{ value: 20,  label: "20"  },
					{ value: 30,  label: "30"  },
					{ value: 40,  label: "40"  },
					{ value: 50,  label: "50"  },
					{ value: 60,  label: "60"  },
					{ value: 70,  label: "70"  },
					{ value: 80,  label: "80"  },
					{ value: 90,  label: "90"  },
					{ value: 100, label: "100" }
				];
*/


//let initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );



let _data_PREV  = [];		// UNAI

let _rangos_PREV = [];	// UNAI


let _chart_data = {};


// --------------



//var _node; // =


var _data_Retrieved = false;    // 		_data_Retrieved = false;  (en ComponentDidMount)



var _Num_Renders_HTML_to_IMG = 0;

var _Num_Renders_RENDER_FUNC = 0;


var _html_graph;

/*
var _html_graph_HECHO = false;

var _AppPDFViewer_trigger;

var _my_dataURL = "";	//null;	//"";

var _ANT_dataURL = "";	//null; //"";
*/


var _cont_rerender_svg =  0;		// SÓLO LO PONEMOS A 0 AQUÍ.... (Y LO HEMOS QUITADO DEL COMPONENT DID MOUNT)


//var _getElement_htmlGRAPH_01 = document.getElementById( 'dropdowncontainer' );

// --------------


//----------------------------------------------


// src = { getMenuImg( "/img/ico/white/ico-b-casa.svg", "/" ) }

///home/tecnico3/_ILUMEK/ilumekv2_gui/public/EKIONA_PDF/ekiona_logo.png


//var _graph_PDF_01_onLOAD = false;

//var _MyPDFDoc;
/*
const MyPDFDoc =	( ) =>
					(
						<Document>
							<Page size="A4">

								<View>
									<Image src="/EKIONA_PDF/ekiona_logo.png">
									</Image>
								</View>

								<View>
									<Text>
										Esto ya es un PDF
									</Text>
								</View>

							</Page>
						</Document>
					);
*/
//const
//var
//var _AppPDFViewer;


const graph_size =	{
						xsize: 510,
						ysize: 150
					};

/*
const styles =	StyleSheet.create(	{
												page:	{
															flexDirection: 'column'
														},

												section:	{
																margin: 10,
																padding: 10,
																flexGrow: 1
															},
										}
					);
*/
/*
var	_MyPDFDoc =	( ) =>
				(
					<Document>

						<Page size="A4">

							<View>
								<Image src= "/EKIONA_PDF/ekiona_logo.png">
								</Image>
							</View>

							<View>
								<Text>
									Esto ya es un PDF
								</Text>
							</View>

						</Page>

					</Document>
				);

var _AppPDFViewer =	( ) =>
					//{
					(
						//return (
									<PDFViewer width="100px" height="100px">
										<_MyPDFDoc/>
									</PDFViewer>
						//);
					)
					//}
*/

// ---------------------------------------------
/*
document.addEventListener(
							"DOMContentLoaded",

							function ( event )
							{
								console.log( "RENDERIZADO según DOMContentLoaded" );
							}
);
*/



var	global_SVG_to_PARSE;









//const myFontKIT = require( 'fontkit' );
//const myPDFnewDOC = require( 'pdfkit' ); //new PDFDocument();

//const myPDFnewDOC = new PDFReference();  //PDFDocument();

//const SVG_to_PDF_KIT = require( 'svg-to-pdfkit' );
//const SVG_with_PK = new SVG_to_PDF_KIT;




var myNEW_SVG_k;

//--

var _ar_DATE_WEEK;    // ARRAY DE DATES DE LUNES A DOMINGO DE LA SEMANA SOLICITADA
var _ar_STR_DATE_WEEK;

//--
/*
var _ar_STR_sunriseWEEK;
var _ar_STR_sunsetWEEK;
*/
/*
var _ar_STR_sunriseWEEK = new Array();
var _ar_STR_sunsetWEEK = new Array();
*/

var STR_meses =	[
					"enero",
					"febrero",
					"marzo",

					"abril",
					"mayo",
					"junio",

					"julio",
					"agosto",
					"septiembre",

					"octubre",
					"noviembre",
					"diciembre"
				];


/*
var STR_meses_short =	[
							"ene",
							"feb",
							"mar",

							"abr",
							"may",
							"jun",

							"jul",
							"ago",
							"sep",

							"oct",
							"nov",
							"dic"
						];
*/


var _ar_NUM_dayWEEK;
var _ar_STR_dayWEEK;


var _ar_NUM_monthWEEK;
var _ar_STR_monthWEEK;



var _ar_NUM_yearWEEK;
var _ar_STR_yearWEEK;

var _lunes_INICIO_STR_completo = "";


var AMANECERES_WEEK = new Array();
var ATARDECERES_WEEK = new Array();







var NUM_mondayStart_date__TIMEZONE;
var our_monday_date;


var NUM_renders_SVG_rendered = 0;


var mi_aux;  // uso auxiliar cualesquiera



/*
var struct_SUNRISE_SUNSET =	{
								sunrise_str: "",

								sunset_str: ""
							};
*/

/*
var Inst_Group_LAT;
var Inst_Group_LON;
*/

/*
var day_sunrise;
var day_sunset;
*/



// ---------------------------------------------


//var sunRise_sunSet_GET = "false";



/*
var objSTREETLIGHT =	{
							_sl_DATA_LOADED:		"false",


							_sl_id:				"",
							_sl_name:			"",

							_sl_cpu:			{},

							_sl_battery:		{},

							_sl_panel:			{},

							_sl_luminary:		{},


							_sl_total_power:	0.0,

							_sl_total_night_time:	0.0,

							_sl_current_schedule: {}
						};



var objINSTALLATIONS =	{
							_DATA_LOADED:	"false",

							_streetlights:	[]
						};

*/


/*
var objINSTALLATION_GROUP =	{
								_ins_DATA_LOADED: "false",


								_installation_GROUP__id: "",

								_installation_GROUP__LAT: "",
								_installation_GROUP__LON: "",

								//-----

								_installations: [],

								//_installation__selected: "",

								_installation__selected__indiceNum: 0,
								_installation__selected__id: "",



								_installation_selected__name: "",

								_installation_selected__NUM_SLs: 0,

								_installation_selected__installation_date: "",

								_installation_selected__total_power: 0.0,


								_installation_selected__installation_client__id: 0    // SABEMOS QUE ESOS IDs SON SÓLO NÚMEROS
							};
							//
*/
//-------------------



var _obj_Inst_Group = {};

/*
var _obj_Inst = {};
var _obj_SL = {};
*/



var _obj_STREETLIGHT_VARIABLEVALs;  // = {}

//var _ar_ALL__STREETLIGHTS_NAMES = [];

var _ar_ALL__STREETLIGHTS_VARVALs = [];
//var _ar_ALL__STREETLIGHTS_VARVALs__ORDEN = [];


var _ar_ALL__STREETLIGHTS_VARVALs__FINAL = [];



//const const_priori_NUM_SEMANAS =  5;

var NUM_SEMANAS_mes = 0;


var _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK =  [];   //  new Array( const_priori_NUM_SEMANAS );


//var _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK =  [];   //  new Array( const_priori_NUM_SEMANAS );


var aux_SUM_STREETLIGHTS_VARVALs__per_WEEK =  [];       //  new Array( const_priori_NUM_SEMANAS );



//var _obj_INSTALLATION__SLs_VARVALs = [];

//var _ar_INSTALLATIONS__SLs_NAMES = [];  //new Array();
//var _ar_INSTALLATIONS__SLs_VARVALs = [];  //new Array();



var graph_ejeY = [ 0, 10, 20, 30, 40 ];



var NUM_MES_Selected =  -1; //  Desde 1 hasta 12

var NUM_DAYS_MONTH =  -1;	// Desde 28 hasta 31

var NUM_DAYS_MONTH_SIG =  -1;	// Desde 28 hasta 31



var DAYS_MONTH__last_week = -1;  // la 5ta semana


var NUM__FIRST_day_MONTH = -1;
var NUM__LAST_day_MONTH = -1;




var STR__FIRST_day_MONTH = "";
var STR__LAST_day_MONTH =  "";


var TIMESTAMP__FIRST_day_MONTH = -1;
var TIMESTAMP__LAST_day_MONTH = -1;

var TS__STR__FIRST_day_MONTH =  -1;	//(  new Date( STR__FIRST_day_MONTH )  ).getTime()  /  1000;
var TS__STR__LAST_day_MONTH  =  -1;	//(  new Date( STR__LAST_day_MONTH  )  ).getTime()  /  1000;

//----------

var GLOBAL_SIG_dat_SL_week =  1;	// ÉSTE VALOR SE MODIFICA MÁS ABAJO								//0;			    	// 3	//1;

//----------



var MAX_INST_GROUP__VARS_BP = 0.0;
var MAX_INST_GROUP__VARS_C = 0.0;




var ar_MEDIA_period_WEEK__FINAL =  [];      //new Array( const_priori_NUM_SEMANAS );
var ar_MEDIA_period_MONTH__FINAL =  [];






var STR_DiAS_MES = [];
var num_PORCENT_AHOORRO_ENERGÉTICO_DIAS = [];









		// STR_DiAS_MES[ id ]
		//var num_PORCENT_AHOORRO_ENERGÉTICO_DIAS = [];


var ar_datos_MEDIA_ENCENDIDO_allSL = new Array( );	// POR SEMANA...   DIA.......   PERIODO..


var ar_HORAS_ENCENDIDO_DIA_allSL = new Array( );	// POR SEMANA...   DIA.......   PERIODO..
var MEDIA_HORAS_ENCENDIDO_DIA = new Array( );	// POR DIA (PARA SACAR DIRECTAMENTE LA GRÁFICA)




const const_cota_Min_Corriente_ENCENDIDO =  parseFloat(  0.007  );	//  0.01	//  0.005	// 0.010			//0.015;	//0.045;	//0.062;



var _c_MAX_INST_GROUP__VARS_BP =  -1;
var _c_MAX_INST_GROUP__VARS_C=  -1;


var Horas_TOTALES_MES_LUMINARIA_allSLs =  0;



var SUMATORIO_MINUTOS_TOTALES = 0;
var SUMATORIO_HORAS_TOTALES = 0;


var ar_Horas_Dia__MEDIA_SL;

var ar_Ahorro_Wh_Dia__APROX;


var ar_Minutos_Dia__AllSLs =  [];
var ar_Ahorro_Dia__AllSLs  =  [];  	// a la hora



var INI_componentDidMount__COUNT =  0;



//----

var svg_01_obj = [];
var svg_02_obj = [];
var svg_03_obj = [];
var svg_04_obj = [];


//id_LINECHART_svg_MEDIA_allSLs_HORA	id_RespCont_svg_MEDIA_allSLs_HORA
var LINECHART_svg_MEDIA_allSLs_HORA_obj = [];

var MEDIAS_HORAS_obj = [];

var AHORRO_ENERG_obj =  [];

var ar_obj_SVG__SL_WEEK = [];


//----

var svg_01_outerHTML;
var svg_02_outerHTML;
var svg_03_outerHTML;
var svg_04_outerHTML;

var LINECHART_svg_MEDIA_allSLs_HORA_obj_outerHTML;
var MEDIAS_HORAS_obj_outerHTML;
var AHORRO_ENERG_obj_outerHTML;


var aux__SVG_SL_WEEK__boolean_existe;



var GLOB_numSL_Grafica_AUX = 0;	// 3


//
//const const_NUM_MAX_huecos_GAP =  5;
//


var thisYEAR =  -1;  // INIT A UN VALOR NO POSIBLE



var our_JSON_OBJ_sunrise =  null;



var ar_Horas_SunsetSunrise_dias_Mes =  [];

var suma_Horas_Reales_TEORICAS =  0;



var ar_Horas_SunsetSunrise_dias_Mes_SIG =  [];

var suma_Horas_Reales_SIG_TEORICAS =  0;


var IND_fmul_SIG_Ahorro_Energ_Mens =  parseFloat( 0.0 );


var ID_scheduleprofile_month = "";
var ID_scheduleprofile_month_SIG = "";


var INST_GROUP_coef_lum_power =  parseFloat(  0.0  );

var INDICE_fmul_coeflum_pow =  parseFloat(  0.0  );



var our_mon = -1;


var ar_DIMM_SIG =  [];
var ar_DIMM =  [];


var ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO =  [];

var ar_Ahorro_Wh_DIA_TEORICO =  [];


var group_id = "";


var CONT_procesado_SLs =  0;


//var PULSADO_BOTON =  false;







var NUM_CALL_API =  0;


var NUM_GENERATE_WEEK_GRAFS =  0;


var TODOS_DATOS_ACCESIBLES =  false;


var HTML_GRAFS_GEN_DATOS_ACCESIBLES =  false;



var ar_template_huecos_SL_MES =  new Array();



//var NUM_COUNT_MOUNT =  0;

//var global_PDF_Generado_y_Renderizado =  false;


var ar_M5_DIMMING__ANT_MONTH =   new Array();



//--------------------------------------------------------

/*
const CustomXAxisLabel =	( ) =>
							{
								return (	<img src="http://localhost:3000/img/very_cloudy.svg" alt="my_icon" width="10" height="10" style={{width: "10px"}}/>		);		//  style={{position: "fixed", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "9999999"}
							};
*/

//------------------------------------------------------------





function ReturnGraphSLweek (  ) 		/*( id_svg )*/
{

	if (  NUM_GENERATE_WEEK_GRAFS  <= 2  )  //  ==  0
	{




		////window.alert(  "ENTRA EN GENERAR GRAF SEMANAS"  );

		//-----

		var ar_svgs = [];

		var obj_svg =  undefined;


		//<GRAPH_recharts 04 />
		/*
			{

			( this.state.sunRise_sunSet_GET == "true" )  &&
		*/

		//---------

		//var aux_MULTIPLO_5 =  Math.round(  MAX_INST_GROUP__VARS_C  +  0.5  );

		//MAX_INST_GROUP__VARS_C =   Math.round(   parseFloat(  parseFloat(  MAX_INST_GROUP__VARS_C )  +  parseFloat( 1.0 / 2.0 )  )    );

		const const_MAX_INST_GROUP__VARS_C =  Math.round(   parseFloat(  parseFloat(  MAX_INST_GROUP__VARS_C )  +  parseFloat( 1.0 / 2.0 )  )    );

		//var MUL_5_aux =   Math.floor(  Math.round(   parseFloat(  parseFloat(  MAX_INST_GROUP__VARS_BP )  +  parseFloat( 5.0 )  )    )   /   5  );

		const const_MAX_INST_GROUP__VARS_BP =  Math.floor(  Math.round(   parseFloat(  parseFloat(  MAX_INST_GROUP__VARS_BP )  +  parseFloat( 5.0 )  )    )   /   5  )    *    5;



		const MIN_eje_Y =  parseFloat( 0.0 );




		//////console.log(  "MAX_INST_GROUP__VARS_C =  "  +  MAX_INST_GROUP__VARS_C   );
		//////console.log(  "MAX_INST_GROUP__VARS_BP =  "  +  MAX_INST_GROUP__VARS_BP  );




		//MAX_INST_GROUP__VARS_C =   Math.round(  MAX_INST_GROUP__VARS_C  /*+  0.5*/  );



		//var mySLS =  0;


		for (  let mySLS = 0;  mySLS <  _obj_Inst_Group.info.streetlights.length /*_ar_ALL__STREETLIGHTS_NAMES.length*/;  mySLS++  )
		{

			ar_svgs.push(
							(																																// NOS DA IGUAL LA SEMANA (0) PARA COGER EL NOMBRE
								<div hidden>
									<h4> Farola #(for){ mySLS + 1} / #{_obj_Inst_Group.info.streetlights.length} = {/*_ar_ALL__STREETLIGHTS_NAMES[ mySLS ]*/ _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 /*iweek*/ ][ mySLS ].SL_NAME } </h4>
								</div>
							)
			);


			//---------


			for (  let iweek = 0;  iweek <= 3;  iweek++  )
			{

				//var id_svg = 5 + iweek + ( mySLS * 5 );


				const ResponsiveConntainer_id =  "weeks_RespContainer_SL" + mySLS + "_W" +  iweek;
				const SVG_Conntainer_id =   "weeks_svg_SL" + mySLS + "_W" +  iweek;

				//---


				obj_svg =	(
								<div hidden>

									<ResponsiveContainer	id = { ResponsiveConntainer_id }

															width={/*2500*/1899}	// 1799

															height={/*800*/400}


															//debounce={0}

															isAnimationActive={false}

															//hidden
									>


										<LineChart
													data=	{
																(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK ) != "undefined"  )	 && 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK  !=  null  )	&&	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK.length  >  0  )  )
																?
																	(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ]) != "undefined"  )
																	?
																		//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ]		//_ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data


																		(  (  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].SL_NAME  ==  "480167f000m"  )  &&  ( iweek  ==  3 )  )
																		?
																		//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat

																		_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat
																		:
																		_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat

																	:
																		[0,0,0]
																	)
																:
																	[0,0,0]
															}

													/*
													ref =	{
																( e ) =>
																{
																	this_._chart_data  =  e;
																}
															}
													*/
													//width={graph_size.xsize} height={graph_size.ysize}


													id =  { SVG_Conntainer_id }		//{"id_ComposedChart_0" + id_svg}     //"chart_03_prueba"

													margin={{ top: 20, right: 20, left: 45, bottom: 20 }}

													isAnimationActive={false}
										>


											<CartesianGrid stroke="#ccc" /*vertical={false}*/ />


											{
											// EJE X

											<XAxis
													dataKey="NEW_timestamp_DAY"

													interval =	{
																	//(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != "undefined"  )	 && 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  )	&&	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  >  0  )  )
																	//?
																		//Math.round(_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ].length  /  7 )

																	(  (  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].SL_NAME  ==  "480167f000m"  )  &&  ( iweek  ==  3 )  )
																	?
																	//Math.round(  	parseFloat( (_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat).length )   /   parseFloat( 7.0 )   )

																	Math.round(  	parseFloat( (     _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat).length )   /   parseFloat( 7.0 )   )
																	:
																	Math.round(  	parseFloat( (     _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat).length )   /   parseFloat( 7.0 )   )

																	//:
																	//	1
																}

													tickMargin={17}


													tickCount={7}


													//padding={{ left: 50 }}

													tickSize={8}
											/>

											}





											<YAxis domain = { [ MIN_eje_Y, const_MAX_INST_GROUP__VARS_BP ] }/>

											<YAxis yAxisId="right" orientation="right" domain = { [ MIN_eje_Y, const_MAX_INST_GROUP__VARS_C ] }/>

											{
											// EJE Y


											/*
											<YAxis yAxisId="left" />
											<YAxis yAxisId="right" orientation="right" />


											<YAxis
												yAxisId="right"

												orientation="right"

												//dataKey={ graph_ejeY }

												domain = { [ 0, 6 ] }
												//interval = { 10 }
											/>
											*/
											}



											{
												/*
												<Legend/>
												*/
											}



											<Line	type="monotone"

													dataKey=	{
																	_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].SL_NAME      +  "_B"
																}

													stroke="green"

													strokeWidth={1}

													isAnimationActive={false}
											/>

											<Line	type="monotone"

													dataKey=	{
																	_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].SL_NAME  +  "_P"
																}

													stroke="red"

													strokeWidth={1}

													isAnimationActive={false}

											/>

											<Line	type="monotone"

													yAxisId="right"

													dataKey=	{
																	_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].SL_NAME  +  "_C"
																}

													stroke="blue"

													strokeWidth={1}

													isAnimationActive={false}
											/>


										</LineChart>


									</ResponsiveContainer>
								</div>
				);

				//------


				ar_svgs.push(
								(
									<div hidden>
										<p> {ResponsiveConntainer_id}  /  {SVG_Conntainer_id} </p>
									</div>
								)
				);


				ar_svgs.push(  obj_svg  );

			}//for  -------------------------------


			//--------


			//window.alert(  "LLEGA A GENERAR GRAFS DE 4 PRIMERAS SEMANAS"  );

			//---------------------------------------------------------------------------------------------


			var RESTO_DIAS_SEMANA_5 =  NUM_DAYS_MONTH  -  28;

			//----

			if (  RESTO_DIAS_SEMANA_5  >  0  )   // NUM_SEMANAS_mes == 5
			{

				const const_iweek =  4;

				const const_RESTO_DIAS_SEMANA_5 =  RESTO_DIAS_SEMANA_5;



				const const_interval =  parseInt( _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ][ mySLS ].length /  RESTO_DIAS_SEMANA_5 );



				const const_our_width =  398 *  const_RESTO_DIAS_SEMANA_5;	  // 271 *	//1213;		//352 * const_RESTO_DIAS_SEMANA_5;  // 1113;	  //353		// 1899 / 7 =





				const ResponsiveConntainer_id =  "weeks_RespContainer_SL" + mySLS + "_W" +  const_iweek;
				const SVG_Conntainer_id =   "weeks_svg_SL" + mySLS + "_W" +  const_iweek;




				obj_svg =	(
								<div hidden>
									<ResponsiveContainer
															id = { ResponsiveConntainer_id }


															isAnimationActive={false}

															width={/*2500*/ const_our_width }		// 263 * ....	// 273

															height={/*800*/400}


															//debounce={0}


															//hidden
									>


										<LineChart
													data=	{
																(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK ) != "undefined"  )	 && 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK  !=  null  )	&&	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK.length  >  0  )  )
																?
																	(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ]) != "undefined"  )
																	?
																		//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ]		//_ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data
																		_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ][ mySLS ].dat
																	:
																		[0,0,0]
																	)
																:
																	[0,0,0]
															}

													/*
													ref =	{
																( e ) =>
																{
																	this_._chart_data  =  e;
																}
															}
													*/

													//width={graph_size.xsize} height={graph_size.ysize}



													id = { SVG_Conntainer_id }     //"chart_03_prueba"


													margin={{ top: 20, right: 20, left: 45, bottom: 20 }}


													isAnimationActive={false}
										>


											<CartesianGrid stroke="#ccc" /*vertical={false}*/ />


											{
											// EJE X

											<XAxis
													dataKey="NEW_timestamp_DAY"



													//interval =	{  const_interval  }



													//allowDuplicatedCategory = {false}


													interval =	{  Math.round(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ][ mySLS ].dat.length  /  const_RESTO_DIAS_SEMANA_5  ) }		//	Math.round(  	(_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat).length  /  7   )


																	//(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != "undefined"  )	 && 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  )	&&	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  >  0  )  )
																	//?
																		//Math.round(_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ].length  /  7 )
																		//Math.round(  	_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].length  /  RESTO_DIAS_SEMANA_5   )

																	//:
																	//	1
													//			}

													tickMargin={17}

													//angle={45}

													//tickCount={ const_RESTO_DIAS_SEMANA_5 }

											/>

											}





											<YAxis domain = { [ MIN_eje_Y, const_MAX_INST_GROUP__VARS_BP ] }/>

											<YAxis yAxisId="right" orientation="right" domain = { [ MIN_eje_Y, const_MAX_INST_GROUP__VARS_C ] }/>

											{
											// EJE Y


											/*
											<YAxis yAxisId="left" />
											<YAxis yAxisId="right" orientation="right" />


											<YAxis
												yAxisId="right"

												orientation="right"

												//dataKey={ graph_ejeY }

												domain = { [ 0, 6 ] }
												//interval = { 10 }
											/>
											*/
											}



											{
												/*
												<Legend/>
												*/
											}



											<Line type="monotone" dataKey={  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ][ mySLS ].SL_NAME   +  "_B"  } stroke="green" strokeWidth={1} isAnimationActive={false}/>

											<Line type="monotone" dataKey={  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ][ mySLS ].SL_NAME   +  "_P"  } stroke="red" strokeWidth={1} isAnimationActive={false}/>


											<Line type="monotone" yAxisId="right" dataKey={  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ const_iweek ][ mySLS ].SL_NAME   +  "_C"  } stroke="blue" strokeWidth={1} isAnimationActive={false}/>


										</LineChart>


									</ResponsiveContainer>

								</div>
				);

				//------

				ar_svgs.push(
								(
									<div hidden>
										<p> {ResponsiveConntainer_id}  /  {SVG_Conntainer_id} </p>
									</div>
								)
				);

				ar_svgs.push(  obj_svg  );

			}// if


			//ar_svgs.push(  obj_svg );


			ar_svgs.push(
						( <div hidden> <br/> <hr/> <br/> </div> )
			);

		}// for : mySLS


		////window.alert( "TERMINAR GENERAR GRAFS SEMANA Y HACE DESPUÉS RETURN" );


		NUM_GENERATE_WEEK_GRAFS++;


		HTML_GRAFS_GEN_DATOS_ACCESIBLES =  true;



		//--------

		return	 ar_svgs;		//obj_svg;


	}// if (  NUM_GENERATE_WEEK_GRAFS  ==  0  )
	else
	{

		////window.alert(  "NUM_GENERATE_WEEK_GRAFS  >  0 "  );


		return ( <div></div> );

	}// else




}//func....






/*
function Returnobjhtml ()
{

	let myvarvar;  // = ( <center> <p>  <b>  TEXTO AÑADIDO DESDDE FUNCION  </b> </p>  </center> );		//[ ( <center> <p>  <b>  TEXTO AÑADIDO DESDDE FUNCION  </b> </p>  </center> ), ( <center> <p>  <b>  TEXTO AÑADIDO DESDDE FUNCION  </b> </p>  </center> )  ];

	let ar_vars = [];


	for (  let itex = 0;  itex < 5;  itex++  )
	{
		myvarvar = ( <center> <p>  <b>  TEXTO AÑADIDO DESDDE FUNCION ( {itex} )  </b> </p>  </center> );

		ar_vars.push(  myvarvar  );
	}//


	return ( ar_vars );
}//
*/



// ---------------------------------------------




function DaysMonth (  month_num,  year  )		// month_num: del 1 al 12
{
	return new Date(   year,  month_num,  0  ).getDate( );

}//






// ---------------------------------------------


Date.prototype.addDays =	function ( days )
							{
								var dat = new Date(  this.valueOf() );

								dat.setDate(  dat.getDate() + days  );


								return dat;
							}//--



function getDatesBetween ( startDate,  stopDate )
{

	var dateArray = new Array();

	var currentDate = startDate;


	while (  currentDate <= stopDate  )
	{
		dateArray.push(  currentDate  );

		currentDate = currentDate.addDays( 1 );
	}


	//console.log(  dateArray  );

	return dateArray;

 }// function getDates ( startDate,  stopDate )





//-------------------------------------------------





// devuelve un INT
function /*async*/ getInstallationGroup__timestamp_ZONE (  str_myLAT,  str_myLON /*,  str_mondayStart_date */  )  		//"timezoneDB_api_key": "ZLTVM9412DXN",
{

	//str_mondayStart_date:  Debe de ser como muy pronto el lunes de la semana anterior a la actual

	// SOBREESCRIBIMOS EL PARAM PORQUE ESTAMOS CON PRUEBAS: FINALMENTE HABRÍA QUE QUITAR DE PARÁMETROS PORQUE LO COGEMOS DE LA URL:

	/*
	window.alert( "COORDS: params "  +  str_myLAT +  ",  "  +  str_myLON  );
	window.alert( "COORDS: var glob ... data  LOADED: "  +  objINSTALLATION_GROUP._DATA_LOADED  +   " ->  "   +  objINSTALLATION_GROUP._installation_GROUP__LAT  +  ",  "  +  objINSTALLATION_GROUP._installation_GROUP__LON  );
	*/

	//-----


	str_myLAT =  (  str_myLAT.includes( ',' )  )	?	str_myLAT.replace( ',', '.' )	:	str_myLAT;
	str_myLON =  (  str_myLON.includes( ',' )  )	?	str_myLON.replace( ',', '.' )	:	str_myLON;


	//------


	/*


	var str_mondayStart_date =  this.props.location.search.split(  "monday="  )[ 1 ];




	var our_monday_date =  new Date( str_mondayStart_date );


	//window.alert(  ( new Date() ).addDays(7)  );

	//
	//window.alert( str_mondayStart_date + " ?=  "  +  our_monday_date  );		// SÍ, LO HACE BIEN


	var NUM_mondayStart_date__TIMEZONE =  Math.round(  parseInt(   our_monday_date.getTime()  )  /  1000  ); // new Date( str_mondayStart_date  )
	//var STR_mondayStart_date = NUM_mondayStart_date.toString();
	*/


	//-------------------------------------------------


	//window.alert(  "CONVERSION:  "  +  our_monday_date.toISOString().split( 'T' )[0]  );
	//STR_meses =	[







	// 16 43 06 88 00
	// 16 43 37 25 68

	//
	//window.alert( "NUM_mondayStart_date =>  "  +  str_mondayStart_date  +  "  ::  "  +  NUM_mondayStart_date  /*+  " = "  +  STR_mondayStart_date*/  );


	/*
	let extra = "";

	if (  all  )
	{
		extra = "?all=true";
	}

	this.state.waiting = true;

	this.setState(
					{
						waiting: true,
						showSpinner:true
					}
	);//.setState()
	*/

	//--


	// Conversion de "timestamp" a "fecha string" y viceversa


			//------------------------------------------------------------


	var offset_timezone_hours;


	var _ar_STR_sunriseWEEK = new Array();
	var _ar_STR_sunsetWEEK = new Array();



	/*
	var _ar_STR_sunriseWEEK = new Array();
	var _ar_STR_sunsetWEEK = new Array();
	*/
	//"timezoneDB_api_key": "ZLTVM9412DXN",

	//https://api.timezonedb.com/v2.1/get-time-zone
													// ?key=ZLTVM9412DXN


													// &format=json

													// &by=position

													// &lat=43.0989200
													// &lng=-2.3176000

													// &time=1643356658

	//const axiosCall_ZONE =
	/*await*/
	axios.get(

				"https://api.timezonedb.com/v2.1/get-time-zone"  +
																	"?key=" +  CONFIG.timezoneDB_api_key  +

																	"&format=json"  +

																	"&by=position"  +

																	"&lat="  +  str_myLAT  +
																	"&lng="  +  str_myLON  +

																	"&time="  +  NUM_mondayStart_date__TIMEZONE,
				/*																//1643068800
				{
					"headers":
								{
									'Content-Type': 'application/json',
									'Authorization': "Token " + this.props.auth.token
								}
				}*/
	).then(
	//
		( result ) =>
		{


			/*
			window.alert( "TIMEZONE_DB:: result.status: \n"  +  JSON.stringify( result.status ) );
			window.alert( "TIMEZONE_DB:: result.data.status: \n"  +  JSON.stringify( result.data.status ) );
			//window.alert( "result.data.results : \n"  +  JSON.stringify( result.data.results ) ); // En éste caso de ésta API concreta,éste parámetro no da resultados
			window.alert( "TIMEZONE_DB:: result: \n"  +  JSON.stringify( result ) );
			*/


			/*

				our_monday_date =  new Date( str_mondayStart_date );


						//window.alert(  ( new Date() ).addDays(7)  );

						//
						//window.alert( str_mondayStart_date + " ?=  "  +  our_monday_date  );		// SÍ, LO HACE BIEN


				NUM_mondayStart_date__TIMEZONE =  Math.round(  parseInt(   our_monday_date.getTime()  )  /  1000  ); // new Date( str_mondayStart_date  )

			*/

			var offset_timezone_mins =  parseInt(  result.data.gmtOffset  )  %  60;

			//var offset_timezone_hours = Math.round( parseInt(  result.data.gmtOffset  )  /  3600  );    //Math.round(  parseInt(  new Date( str_mondayStart_date  ).getTime()  )  /  1000  );

			offset_timezone_hours = parseInt(  Math.floor( parseInt(  result.data.gmtOffset  )  /  3600  )  );    //Math.round(  parseInt(  new Date( str_mondayStart_date  ).getTime()  )  /  1000  );


			offsHOUR_timezone_installation_group =  offset_timezone_hours;


			//window.alert( "TIMEZONE_DB:: result.data.gmtOffset: \n"  +  JSON.stringify( result.data.gmtOffset ) +  " = "  +  /*offset_timezone_hours*/ offsHOUR_timezone_installation_group  );








			/*
			if (  result.data.info.weather.sun_info === undefined  )
			{
				result.data.info.weather =
											{
												"sun_info":
															{
																"sunrise_local": "xT8:00",
																"sunset_local": "xT21:00",
															}
											}
			}//if

			if (  all  )
			{
				result.data.id = "all";
				result.data.group_name = "All";
			}//if


			this.setState(
							{
								waiting:	false,

								selected_installationgroup:		result.data
							}
			);//.setState()

			//console.log(  result.data.info.weather.sun_info  );


			let temp =	generateCurveData(
											//data,
											///   <----------------------------------------------  COMENTADO SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA DECLARACION)

											result.data.info.weather.sun_info["sunrise_local"],
											result.data.info.weather.sun_info["sunset_local"]
						);//

			//console.log( "Data generated" );

			_rangos_PREV = temp["rangos"];__TIMEZONE

			_data = temp["data"];


			if (  this.props.match.params.id  !==  '-1'  )
			{
				this.get_Schedules_Profiles( this.props.match.params.id );		// --------------------------------->  get_Schedules_Profiles( )
			}
			else
			{
				this.setState(
								{
									comp_CHART_DATA: _data
								}
				);//.setState
			}//

			// Actualizando
			//  this.props.parent.updateOptionVisibility(this.state.installationgroups[0].max_cpu_version);
			*/


		}// (result) =>

	).catch(
	//
		error => 	window.alert( error )
					/*
					this.setState(
									{
										error,
										waiting: false
									}
					)//.this.setState()*/
	//
	).finally(
	//
		( ) =>
		{

			/*
			var offset_timezone_mins =  parseInt(  result.data.gmtOffset  )  %  60;

			//var offset_timezone_hours = Math.round( parseInt(  result.data.gmtOffset  )  /  3600  );    //Math.round(  parseInt(  new Date( str_mondayStart_date  ).getTime()  )  /  1000  );

			offset_timezone_hours = parseInt(  Math.floor( parseInt(  result.data.gmtOffset  )  /  3600  )  );    //Math.round(  parseInt(  new Date( str_mondayStart_date  ).getTime()  )  /  1000  );


			offsHOUR_timezone_installation_group =  offset_timezone_hours;
			*/





			//console.log( "timeZONE DONE!  "  +  offsHOUR_timezone_installation_group  );


			//this.getInstallationGroup__SunriseSunset_arrayWeek( objINSTALLATION_GROUP._installation_GROUP__LAT, objINSTALLATION_GROUP._installation_GROUP__LON,
			//													offset_timezone_hours /*offsHOUR_timeZONE*/  /*offsHOUR_timezone_installation_group*/  );










			_ar_DATE_WEEK = getDatesBetween(  our_monday_date,  our_monday_date.addDays( 6 /*7*/ )  );					// SÍ, LO HACE BIEN

																							// PERO NO SON LOS SIGUIENTES 7 DÍAS, SINO LOS 6 SIGUIENTES PUES QUEREMOS LISTAR TODOS LOS DÍAS DE ESA SEMANA: LUN -> DOM












			/*
			var day_sunrise;
			var day_sunset;
			*/

			_ar_NUM_dayWEEK = new Array();
			_ar_STR_dayWEEK = new Array();

			_ar_NUM_monthWEEK = new Array();
			_ar_STR_monthWEEK = new Array();

			_ar_NUM_yearWEEK = new Array();
			_ar_STR_yearWEEK = new Array();




			/*
			AMANECERES_WEEK = new Array();
			ATARDECERES_WEEK = new Array();
			*/


			_ar_STR_DATE_WEEK = new Array();		//new String[ 7 ];

			//----

			// CON UN .forEach MEJOR


			_ar_DATE_WEEK.forEach(
			//
				( item, index ) =>
				{

					//for (  let i = 0; i < _ar_DATE_WEEK.length; i ++  )
					//{

					_ar_STR_DATE_WEEK.push(  _ar_DATE_WEEK[ index ].toLocaleString().split( ' ' )[0]  );




					const aux_STR_dates =  _ar_DATE_WEEK[ index ].toISOString().split( 'T' )[0];


					_ar_NUM_dayWEEK.push(  parseInt( aux_STR_dates.split( '-' )[ 2 ] )  );		// OK
					_ar_STR_dayWEEK.push(  /*String( _ar_NUM_dayWEEK[ i ] )*/  aux_STR_dates.split( '-' )[ 2 ]  );		// aux_STR_dates.split( '-' )[ 2 ]  // OK

					_ar_NUM_monthWEEK.push(  parseInt( aux_STR_dates.split( '-' )[1] )  );		// OK
					_ar_STR_monthWEEK.push(  STR_meses[ _ar_NUM_monthWEEK[ index] - 1 ]  );		// OK

					_ar_NUM_yearWEEK.push(  parseInt( aux_STR_dates.split( '-' )[0] )  );		// OK
					_ar_STR_yearWEEK.push(  String( _ar_NUM_yearWEEK[ index ] )  );		// OK


					// // //
					//window.alert(  "CONVERSION: todo :: "  +  _ar_STR_dayWEEK[ i ]   +  " / "  +  _ar_STR_monthWEEK[ i ]  +  " / "  +  _ar_STR_yearWEEK[ i ]  );


					/*
					var STR_meses =	[
										"enero",
										"febrero",
										"marzo",

										"abril",
										"mayo",
										"junio",

										"julio",
										"agosto",
										"septiembre",

										"octubre",
										"noviembre",
										"diciembre"
									];

					var _ar_NUM_monthWEEK;
					var _ar_STR_monthWEEK;



					var _ar_NUM_yearWEEK;
					var _ar_STR_yearWEEK;
					*/



					//
					//alert(  _ar_DATE_WEEK[ i ].toLocaleString().split( ' ' )[0]  +  " ?=  "  +  _ar_STR_DATE_WEEK[ i ]    +  " ?=  "  +  aux_STR_dates  );




					//---- -------------------   --------------------------------------


					/*
					var struct_SUNRISE_SUNSET =	{
													sunrise_str: "",

													sunset_str: ""
												};
					*/


					//https://api.sunrise-sunset.org/json
															// ?lat=43.0989200
															// &lng=-2.3176000

															// &date=2022-01-28

					//const struct_SUNRISE_SUNSET =

					var aux_result_func;


					var day_sunrise;
					var day_sunset;



					//var aux_aux_sunrise =
					//const axiosCall_SUNRISESET =
					//await
					axios.get(

								"https://api.sunrise-sunset.org/json"  +
																		"?lat="  +  str_myLAT   +
																		"&lng="  +  str_myLON   +

																		"&date="  +  aux_STR_dates  +  //str_mondayStart_date  +

																		"&formatted="  +  "0",
								/*
								{
									"headers":
												{
													'Content-Type': 'application/json',
													'Authorization': "Token " + this.props.auth.token
												}
								}*/

					).then(
					//
							//aux_result_func =
						( result ) =>
						{

							//////console.log(   result.data.results  );

							//window.alert( "result.status: \n"  +  JSON.stringify( result.status ) );
							//window.alert( "result.data.status: \n"  +  JSON.stringify( result.data.status ) );
							//window.alert( "result.data.results : \n"  +  JSON.stringify( result.data.results ) );
							//window.alert( "result: \n"  +  JSON.stringify( result ) );


							const ar_split_day_sunrise = ( result.data.results.sunrise ).split( 'T' )[1].split( '+' )[0].split( ':' );
							const ar_split_day_sunset  = ( result.data.results.sunset  ).split( 'T' )[1].split( '+' )[0].split( ':' );


							//const
							day_sunrise = (  parseInt( ar_split_day_sunrise[0] ) + offset_timezone_hours /*int_timeZONE*//*offsHOUR_timezone_installation_group*/  )  +  ":"  +  ar_split_day_sunrise[1];
							day_sunset  = (  parseInt( ar_split_day_sunset[0] )  + offset_timezone_hours /*int_timeZONE*//*offsHOUR_timezone_installation_group*/  )  +  ":"  +  ar_split_day_sunset[1];



							//return day_sunrise;



							//_ar_STR_sunriseWEEK.push( day_sunrise );
							//_ar_STR_sunsetWEEK.push(  day_sunset );



							/*
							this.setState(
											{
												//profileName:"Schedule",
												//showSpinner: false,

												sunriseDAY: this.state.sunriseDAY.push( day_sunrise ),
												sunsetDAY:  this.state.sunsetDAY.push(  day_sunset ),
											}
							);//
							*/

							/*
							window.alert(	_ar_DATE_WEEK[ i ].toLocaleString().split( ' ' )[0]  +  " ?=  "  +  _ar_STR_DATE_WEEK[ i ]    +  " ?=  "  +  aux_STR_dates  +  "\n"  +

											_ar_STR_sunriseWEEK[ i ]  +  " ?=  "  +  _ar_STR_sunsetWEEK[ i ]    +  "\n"  + // " ?=  "  +  aux_STR_dates  +  "\n"  +
											//"sunrise: "  +  result.data.results.sunrise  	+  ", sunset: "  +  result.data.results.sunset  +  "\n"  +
											"sunrise: "  +  day_sunrise  					+  ", sunset: "  +  day_sunset   );
							*/

							/*
							this.setState(
											{
												sunriseDAY: day_sunrise,
												sunsetDAY:  day_sunset
											}
							);
							*/



							//----------------------

							/*
							return	{
										sunrise_str:	day_sunrise,

										sunset_str: 	day_sunset
									};
							*/

							/*
							if (  result.data.info.weather.sun_info === undefined  )
							{
								result.data.info.weather =
															{
																"sun_info":
																			{
																				"sunrise_local": "xT8:00",
																				"sunset_local": "xT21:00",
																			}
															}
							}//if

							if (  all  )
							{
								result.data.id = "all";
								result.data.group_name = "All";
							}//if


							this.setState(
											{
												waiting:	false,

												selected_installationgroup:		result.data
											}
							);//.setState()

							//console.log(  result.data.info.weather.sun_info  );


							let temp =	generateCurveData(
															//data,
															///   <----------------------------------------------  COMENTADO SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA DECLARACION)

															result.data.info.weather.sun_info["sunrise_local"],
															result.data.info.weather.sun_info["sunset_local"]
										);//

							//console.log( "Data generated" );

							_rangos_PREV = temp["_rangos_PREV"];

							_data = temp["data"];


							if (  this.props.match.params.id  !==  '-1'  )
							{
								this.get_Schedules_Profiles( this.props.match.params.id );		// --------------------------------->  get_Schedules_Profiles( )
							}
							else
							{
								this.setState(
												{
													comp_CHART_DATA: _data
												}
								);//.setState
							}//

							// Actualizando
							//  this.props.parent.updateOptionVisibility(this.state.installationgroups[0].max_cpu_version);
							*/
					}// (result) =>
					//
					).catch(
					//
						error => 	window.alert( error )
									/*
									this.setState(
													{
														error,
														waiting: false
													}
									)//.this.setState()*/
					//
					).finally(
					//
						( ) =>
						{
							/*
							console.log(  	aux_STR_dates  +  "\n"  +
											"DENTRO axios:  sunrise: "  +  day_sunrise  	+  ", sunset: "  +  day_sunset   );
							*/

							_ar_STR_sunriseWEEK.push( day_sunrise );
							_ar_STR_sunsetWEEK.push(  day_sunset );


							if ( index >= 6 )
							{

								//console.log(  "AMANECERES:  "  +  JSON.stringify( _ar_STR_sunriseWEEK  )  );
								//console.log(  "ATARDECERES:  "  +  JSON.stringify( _ar_STR_sunsetWEEK  )  );


								AMANECERES_WEEK = new Array();
								ATARDECERES_WEEK = new Array();


								_ar_STR_sunriseWEEK.forEach(
								//
									( item, i ) =>
									{

										//for (  let k = 0; k < /*_ar_DATE_WEEK*/ _ar_STR_sunriseWEEK.length; k ++  )
										//{

											AMANECERES_WEEK.push(  _ar_STR_sunriseWEEK[ /*k*/ i ]  );
											ATARDECERES_WEEK.push(  _ar_STR_sunsetWEEK[ /*k*/ i ]  );

										// }// FOR

									}
								//
								);// _ar_STR_sunriseWEEK.forEach(


								//console.log(  "AMANECERES __GLOBAL__:  "  +  JSON.stringify( AMANECERES_WEEK  )  );
								//console.log(  "ATARDECERES: __GLOBAL__ "  +  JSON.stringify( ATARDECERES_WEEK  )  );


								//sunRise_sunSet_GET = "true;"

							} // if ( index >= 6 )

						} // ( ) =>
					//
					);//.catch() -------------


					//console.log(   "aux_aux_sunrise:  "  +   aux_aux_sunrise   );


					//---

					//_ar_STR_sunriseWEEK.push( day_sunrise );
					//_ar_STR_sunsetWEEK.push( day_sunset );




					/*
					console.log(  	aux_STR_dates  +  "\n"  +
									"FUERA axios:  sunrise: "  +  day_sunrise  	+  ", sunset: "  +  day_sunset   );
					*/


					/*
					_ar_STR_sunriseWEEK.push( this.state.sunriseDAY );
					_ar_STR_sunsetWEEK.push(  this.state.sunsetDAY );
					*/


					//console.log( "AMANECERES:  "  +  JSON.stringify( struct_SUNRISE_SUNSET )  );


				} // () =>
			//
			);	// .forEach

			//}				// for ---------------------------------------


			//----------


			/*
			_ar_NUM_dayWEEK.push(  parseInt( aux_STR_dates.split( '-' )[ 2 ] )  );		// OK
			_ar_STR_dayWEEK.push(   aux_STR_dates.split( '-' )[ 2 ]  );		// aux_STR_dates.split( '-' )[ 2 ]  // OK

			_ar_NUM_monthWEEK.push(  parseInt( aux_STR_dates.split( '-' )[1] )  );		// OK
			_ar_STR_monthWEEK.push(  STR_meses[ _ar_NUM_monthWEEK[ i ] - 1 ]  );		// OK

			_ar_NUM_yearWEEK.push(  parseInt( aux_STR_dates.split( '-' )[0] )  );		// OK
			_ar_STR_yearWEEK.push(  String( _ar_NUM_yearWEEK[ i ] )  );		// OK
			*/


			_lunes_INICIO_STR_completo =  "lunes_"  +  _ar_STR_dayWEEK[ 0 ]  +   "_"  + _ar_STR_monthWEEK[ 0 ]  +  "_"  +  _ar_STR_yearWEEK[ 0 ];



			/*
			this.setState(
							{
								sunriseDAY: _ar_STR_sunriseWEEK[ 0 ],
								sunsetDAY:  _ar_STR_sunsetWEEK[ 0 ]
							}
			);
			*/

			/*
			console.log(  "AMANECERES final:  "  +  JSON.stringify( _ar_STR_sunriseWEEK  )  );
			console.log(  "ATARDECERES final:  "  +  JSON.stringify( _ar_STR_sunsetWEEK  )  );
			*/


			//	console.log(  "AMANECERES:  "  +  JSON.stringify( _ar_STR_sunriseWEEK  )  );


			//window.alert( "AMANECERES:  "  +  JSON.stringify( _ar_STR_sunriseWEEK )  );


			//window.alert(  _lunes_INICIO_STR_completo  );

			//------------------------------------------------------------
			//------------------------------------------------------------


		}
	//
	); //.finally()


	/*
	console.log(  "AMANECERES final 2:  "  +  JSON.stringify( _ar_STR_sunriseWEEK  )  );
	console.log(  "ATARDECERES final 2:  "  +  JSON.stringify( _ar_STR_sunsetWEEK  )  );
	*/

	//-------


	//return offsHOUR_timezone_installation_group;


}//	getInstallationGroup__timestamp_ZONE (  str_myLAT,  str_myLON /*,  str_mondayStart_date */  )  		//"timezoneDB_api_key": "ZLTVM9412DXN",




//---------------------------------------------------------------------------------------------------------------------------------------------------




/*
async getInstallationGroup__SunriseSunset_arrayWeek (  str_myLAT,  str_myLON ,  int_timeZONE  )  		//"timezoneDB_api_key": "ZLTVM9412DXN",
{


	str_myLAT =  (  str_myLAT.includes( ',' )  )	?	str_myLAT.replace( ',', '.' )	:	str_myLAT;
	str_myLON =  (  str_myLON.includes( ',' )  )	?	str_myLON.replace( ',', '.' )	:	str_myLON;


	//------


	var str_mondayStart_date =  this.props.location.search.split(  "monday="  )[ 1 ];




	var our_monday_date =  new Date( str_mondayStart_date );


	//window.alert(  ( new Date() ).addDays(7)  );

	//
	//window.alert( str_mondayStart_date + " ?=  "  +  our_monday_date  );		// SÍ, LO HACE BIEN


	//var NUM_mondayStart_date__TIMEZONE =  Math.round(  parseInt(   our_monday_date.getTime()  )  /  1000  );	// new Date( str_mondayStart_date  )
	//var STR_mondayStart_date = NUM_mondayStart_date.toString();


	//----------------------






}//	getInstallationGroup__SunriseSunset_arrayWeek (  str_myLAT,  str_myLON,   int_timeZONE  )
*/






/*
function generateCurveData (   sunrise_,  sunset_  )	// initial_data,		// 1ER PARAM COMENTADO POR SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA LLAMADA)
{
	_rangos_PREV = [];	// PONE ESAS VARS GLOBALES A VACIO

	_data_PREV = [];		// PONE ESAS VARS GLOBALES A VACIO

	//-----

	let curve_data =
					[
						0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ,0, 0, 0, 0, 0, 0,
						0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
					];

	sunset_  = sunset_.split( "T")[1];
	sunrise_ = sunrise_.split("T")[1];


	let dawn =		Moment(  "20180101 "  +  sunset_,  "YYYYMMDD hh:mm"  );
	let sunrise =	Moment(  "20180102 "  +  sunrise_, "YYYYMMDD hh:mm"  );


	// -------------- Rangos ----------------

	let moment_init =  Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );

	//console.log( "moment_init ini:  " + moment_init );




	for (  let i = 0;  i < 48; i++  )			// 48 !  ( 24 * 2 )
	{

		let moment_init2 =  moment_init.clone();

		let moment_init2_m45 =  moment_init.clone().add( "-45", "minutes" );
		let moment_init2_p45 =  moment_init.clone().add( "45", "minutes" );


		let sun0_value = 0;


		if (  moment_init2_p45 > dawn  &&  moment_init2_m45 < sunrise  )
		{
			sun0_value = 100;
		}


		let sun_value = 0;


		if (  moment_init2 > dawn  &&  moment_init2 < sunrise  )
		{
			sun_value = 100;
		}


		_data_PREV.push(
							{
								name: moment_init2.format( "HH:mm" ),

								sp:  	curve_data[ i ], // LUEGO SE RELLENARAN CON VALS REALES AL LLAMAR A LA DE GET_SCHEDULE

								sun: 	sun_value,
								sun0: 	sun0_value,
							}
		);


		_rangos_PREV.push(
						[
							moment_init2.format( "HH:mm" ),

							moment_init2.add( "30", "minutes" ).format( "HH:mm" ),
						]
		);


		moment_init = moment_init.add( "30","minutes" );

	}//for




	//console.log( "CURVE_DATA[]"  + JSON.stringify(curve_data) );

	//console.log( "_data_PREV fin"  + JSON.stringify(_data_PREV) );
	//console.log( "_rangos_PREV fin"  + JSON.stringify(_rangos_PREV) );






	//---

	return	{
				"data_final_return":		_data_PREV,

				"rangos_final_return":		_rangos_PREV
			};


}//function generateCurveData (  initial_data,  sunrise_,  sunset_  )
*/

//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------



class Informe_mensual extends Component
{

	constructor ( props )
	{
		super( props );


		_chart_data =  	_data_PREV;  // data comienza siendo array vacio



		this.state =	{
							//isNew: true,

							showSpinner: false,



							LAST_re_render: "false",

							SCRIPT_svg_rendered: "false",

							//ar_sunriseDAY: [],
							//ar_sunsetDAY: [],

							//sunriseDAY: "",
							//sunsetDAY: "",

							sunRise_sunSet_GET: "false",


							global_PDF_Generado_y_Renderizado: "false",


							NUM_COUNT_MOUNT:  0,


							//viene_de_antes:  "false",



							//pulsado_Button:  "false",


							//id:	"",		//props.match.params.id,


							//schedule_id : -1,

							//scheduleprofile: {},


							selected_installationgroup:	{
															group: "",

															id: "",

															streetlights: []
														},

							comp_CHART_DATA: [],


							view_as_cards: true,
							modalIsOpen: true,
							cntrlIsPressed: false,
							capsIsPressed: false,
							initialCaps: null,
							showAlert: false,

							//profileName: "",
							profileComments: "",


							wait_in: "seconds",

							//time_to_wait: 60,

							//dimming: 0,//,


							waiting: true

							//htmltoIMG_onLOAD: false

						}; //--------------------------------

		/*
		this.sliders = [];



		for(  let i = 0;  i < 100;  i++  )
		{
			this.sliders[ i ] =  {};

		}//for
		*/

		//this.checkValue = this.checkValue.bind( this );

		this.cntrlIsPressed = false;
		this.capsIsPressed = false;


		this.upperSlider = undefined;




		this.get_Schedules_Profiles = this.get_Schedules_Profiles.bind( this );		// --------------------------------->  get_Schedules_Profiles ( )

		this.getInstallationGroupDetail = this.getInstallationGroupDetail.bind( this );		// --------------------------------->  getInstallationGroupDetail ( )


	}//	constructor ( props ) --------------------------------



	//-------------------------------------------

	// .content[ 6 ].stack[ 2 ].columns[ 1 ].stack   en éste stack se hace append

	//var thisYEAR =  our_monday_date.getFullYear();
	//NUM_MES_Selected =   our_monday_date.getMonth()  +  1; 		//  Desde 1 hasta 12

	//-------------------------------------------




	//----------------------------------------------------------------------------------------

		//https://api.timezonedb.com/v2.1/get-time-zone
														// ?key=ZLTVM9412DXN


														// &format=json

														// &lat=43.0989200
														// &lng=-2.3176000

														// &time=1643356658


		//https://api.sunrise-sunset.org/json
												// ?lat=43.0989200
												// &lng=-2.3176000

												// &date=2022-01-28






	componentWillUnmount ( )
	{


		//NUM_COUNT_MOUNT = 0;


		_cont_rerender_svg =  0;

		CONT_procesado_SLs =  0;




		NUM_CALL_API =  0;

		NUM_GENERATE_WEEK_GRAFS =  0;



		TODOS_DATOS_ACCESIBLES =  false;

		HTML_GRAFS_GEN_DATOS_ACCESIBLES =  false;




		NUM_renders_SVG_rendered =  0;

		//CONT_procesado_SLs =  0;


		_Num_Renders_RENDER_FUNC =  0;



		
		this.state.NUM_COUNT_MOUNT = 0;


		this.state.LAST_re_render = "false";

		this.state.sunRise_sunSet_GET = "false";

		this.state.SCRIPT_svg_rendered = "false";


		this.setState(

			{
				LAST_re_render: "false",

				sunRise_sunSet_GET: "false",

				SCRIPT_svg_rendered: "false",

				NUM_COUNT_MOUNT: 0,


				total_streetlights: 0,


				selected_installationgroup:  {}
			}

		); // this.setState(


		if (localStorage.getItem('selectedMonth') !== null){
			localStorage.removeItem('selectedMonth')
			console.log("local storage month removed")
		}

		if (localStorage.getItem('selectedYear') !== null){
			localStorage.removeItem('selectedYear')
			console.log("local storage year removed")
		}



		//this.setState(   this.constructor()   );


	}// componentWillUnmount ( )








	getInstallationGroupDetail (  id  /*, all*/ )
	{

		const const_timeWait_SL     =  20;  // 50  // 20	// 400  // 350	// 300	// 250

		//const timeWait_ALL_SL__plus =  50;	// 50	//300;  // 500


		var new_schema_aux;
		var new_objs_sl;


		//window.alert(  "NUM_CALL_API: " + NUM_CALL_API  );


		if ( NUM_CALL_API  ===  0  )
		{


			CONT_procesado_SLs =  0;


			NUM_GENERATE_WEEK_GRAFS =  0;


			//REPETIDO ABAJO TAMBIÉN EN EL AXIOS

			_ar_ALL__STREETLIGHTS_VARVALs =  null;

			_ar_ALL__STREETLIGHTS_VARVALs = new Array();



			//window.alert(  "getInstallationGroupDetail() .... NUM_CALL_API: " + NUM_CALL_API  );




			/*
			let extra = "";

			if (  all  )
			{
				extra = "?all=true";
			}
			*/




			this.state.waiting = true;

			this.state.showSpinner = true;


			this.setState(
							{
								waiting: true,

								showSpinner: true
							}
			);//.setState()




			//---



			//--

			//setTimeout(
			//			( ) =>
			//			{
			/*
			this.state.sunriseDAY  =  AMANECERES_WEEK[ 0 ];

			this.setState(
							{
								sunriseDAY: AMANECERES_WEEK[ 0 ]
							}
			);
			*/
			//--------



			//MAX_INST_GROUP__VARS_BP = 0.0;
			//MAX_INST_GROUP__VARS_C = 0.0;



			//objINSTALLATION_GROUP._DATA_LOADED = "false";

			axios.get(
						CONFIG.api_url  +  "/installationgroups/"  +  id,	//  +  extra,

						{
							"headers":
										{
											'Content-Type': 'application/json',
											'Authorization': "Token "		 + 		this.props.auth.token
										}
						}
			).then(
			//
				( result ) =>
				{

					//REPETIDO ARRIBA TAMBIÉN
					//_ar_ALL__STREETLIGHTS_VARVALs = new Array();


					_ar_ALL__STREETLIGHTS_VARVALs = new Array();

					ar_template_huecos_SL_MES =  new Array();



					//console.log(  JSON.stringify( _obj_Inst_Group, null, "	"  )  );
					/*
					console.log( JSON.stringify( _obj_Inst_Group.installations[0], null, "	"  )  );
					console.log(   JSON.stringify( _obj_Inst_Group.installations[0].streetlights[0], null, "	"  )  );
					*/





					//console.log( "/installationgroups/id :: result:  \n"  +  JSON.stringify( result )  );



					// latitude
					// longitude



					/*
					var objINSTALLATION_GROUP =	{

												_installation_GROUP__id: "",

												//_installation__selected: "",

												_installation__selected__indiceNum: 0,
												_installation__selected__id: "",

												_installation_selected__name: "",

												_installation_selected__NUM_SLs: 0,

												_installation_selected__installation_date: "",

												_installation_selected__total_power: 0.0,


												_installation_selected__installation_client__id: 0    // SABEMOS QUE ESOS IDs SON SÓLO NÚMEROS
											};
					*/



					_obj_Inst_Group =  result.data;



					//console.log( _obj_Inst_Group.info );








					//console.log( _obj_Inst_Group  );




					//console.log(  result.data.info.weather.sun_info  );


					//---------------------------------------------




					/*
					let genCurvDat =	generateCurveData(
															//data,     ///   <------------------------------------------  COMENTADO SERGIO 091221: PARECE QUE NO SE USA (IGUAL EN LA DECLARACION)

															_obj_Inst_Group.info.weather.sun_info[ "sunrise_local" ],
															_obj_Inst_Group.info.weather.sun_info[ "sunset_local" ]
										);//

					//console.log( "Data generated" );


					_rangos_PREV = genCurvDat[ "rangos_final_return" ];

					_data_PREV = genCurvDat[ "data_final_return" ];
					*/



					//-------------------------




					/*

					if (  this.props.match.params.id  !==  '-1'  )
					{
						this.get_Schedules_Profiles(  this.props.match.params.id  );		// --------------------------------->  get_Schedules_Profiles( )
					}
					else
					{
						this.setState(
										{
											comp_CHART_DATA:   _data_PREV   //lo que haya anteriormente ...
										}
						);//.setState
					}// else

					*/
											/*
											ID_scheduleprofile_month =  result.data[  String( NUM_MES_Selected )  ].id_schedule;


											let int_SIG_MES =	(   NUM_MES_Selected   <=   11   )
																?
																	( NUM_MES_Selected + 1 )
																:
																	1;

											ID_scheduleprofile_month_SIG =  result.data[  String( int_SIG_MES )  ].id_schedule;
											*/




					//-------------------------------------------------------------------------------


					/*
					if (  ID_scheduleprofile_month  !=  ID_scheduleprofile_month_SIG  )
					{
						console.log( "Schedule profiles, distintos" );
						window.alert( "Schedule profiles, distintos" );

					}// if (  ID_scheduleprofile_month  !=  ID_scheduleprofile_month_SIG  )
					*/



					// Actualizando
					//  this.props.parent.updateOptionVisibility(this.state.installationgroups[0].max_cpu_version);

				}// (result) => {}

			).catch(
			//
				error =>

					this.setState(
									{
										error,
										waiting: false
									}
					)//.this.setState()
			//
			).finally(
			//
				( ) =>
				{

					setTimeout(
					//
						( ) =>
						{

							//REPETIDO ARRIBA TAMBIÉN
							//_ar_ALL__STREETLIGHTS_VARVALs = new Array();

							//ar_template_huecos_SL_MES =  new Array();

							//-------------------------------------------------------


							INST_GROUP_coef_lum_power =  parseFloat(  _obj_Inst_Group.coef_lum_power  );

							INDICE_fmul_coeflum_pow =   parseFloat(  1.0  /  INST_GROUP_coef_lum_power  );


							//-------------------------------------------------------



							/*
							objINSTALLATION_GROUP._DATA_LOADED = "false";

							objINSTALLATION_GROUP._installation_GROUP__id =  result.data.id;


							//objINSTALLATION_GROUP._installation__selected =  result.data.installations[  0  ];


							var inst_selected_AUX =  result.data.installations[  0  ];


							objINSTALLATION_GROUP._installation__selected__indiceNum =  0;
							objINSTALLATION_GROUP._installation__selected__id =  inst_selected_AUX.id;		//  objINSTALLATION_GROUP._installation__selected__indiceNum =  0;


							objINSTALLATION_GROUP._installation_GROUP__LAT =  result.data.latitude;
							objINSTALLATION_GROUP._installation_GROUP__LON =  result.data.longitude;


							objINSTALLATION_GROUP._installation_selected__name =  inst_selected_AUX.installation_name;

							objINSTALLATION_GROUP._installation_selected__NUM_SLs =  inst_selected_AUX.streetlights.length;

							objINSTALLATION_GROUP._installation_selected__installation_date =  inst_selected_AUX.installation_date;

							objINSTALLATION_GROUP._installation_selected__total_power =  inst_selected_AUX.total_power;


							objINSTALLATION_GROUP._installation_selected__installation_client__id =  inst_selected_AUX.installation_client;

							//console.log( "data Installation Group ::  "  +  JSON.stringify( objINSTALLATION_GROUP )   );


							objINSTALLATION_GROUP._DATA_LOADED = "true";
							*/


							//-----

							/*
							if (  _obj_Inst_Group.info.weather.sun_info === undefined  )
							{

								_obj_Inst_Group.info.weather =
																{
																	"sun_info":
																				{
																					"sunrise_local": "xT8:00",
																					"sunset_local": "xT21:00",
																				}
																}
							}// if (  _obj_Inst_Group.info.weather.sun_info === undefined  )
							*/

							/*
							if (  all  )
							{
								_obj_Inst_Group.id = "all";
								_obj_Inst_Group.group_name = "All";

							}// if (  all  )
							*/



							this.state.selected_installationgroup =  _obj_Inst_Group;


							this.state.selected_installationgroup.group =  _obj_Inst_Group.id;

							this.state.selected_installationgroup.group =  _obj_Inst_Group.group_name;


							this.state.selected_installationgroup.streetlights =  _obj_Inst_Group.info.streetlights;



							this.state.total_streetlights = _obj_Inst_Group.info.streetlights.length;


							/*

							this.setState(
											{
												//waiting:	false,
												waiting:	true,


												total_streetlights: 	_obj_Inst_Group.info.streetlights.length,


												selected_installationgroup:		_obj_Inst_Group        //result.data
											}
							);//.setState()

							*/


							//--------------------------------------------------------------------------------


							this.get_Schedules_Profiles(  ID_scheduleprofile_month,  ID_scheduleprofile_month_SIG  );		// --------------------------------->  get_Schedules_Profiles( )


							//--------------------------------------------------------------------------------



							//console.log(  "Esto es el final de GET_INSTALLATION_GROUP__DETAIL"  );


							//const const_timeWait_Total_SLs  =  	(  Math.round(  _obj_Inst_Group.info.streetlights.length  *  const_timeWait_SL  *  3.6  )  /*3*/  )  +  /*timeWait_ALL_SL__plus*/  200;	  // * 250


							///_ar_ALL__STREETLIGHTS_VARVALs = new Array();




							//---------------------




							//var offsHOUR_timeZONE =  /*this.*/

							/*
							getInstallationGroup__timestamp_ZONE(	_obj_Inst_Group.latitude,			//objINSTALLATION_GROUP._installation_GROUP__LAT,
																	_obj_Inst_Group.longitude  );			//objINSTALLATION_GROUP._installation_GROUP__LON   );
							*/


							//----------------------


							// USARLO CON "THIS." Y REALIZAR UN SETSTATE DENTRO CON UN CAMBIO RELEVANTE AL RENDER




							//setTimeout(
							//
							//	( ) =>
							//	{

									//var _obj_STREETLIGHT_VARIABLEVAL = {};
									//var _ar_STREETLIGHT_VARVAL = [];


									/*

									_obj_Inst_Group.installations[0].streetlights[0],


									https://api.ilumek.com/api/v1

									/installations/

									4c1952b1-61c3-407b-888b-ff5cf1fe8da1

									/groupdata2.json


									?page_size=10000

									//&page=6



									&vars=B,C,P,PC,D,BS,SO2,CO,NO2,O3,PMA,PMB,PMC

									&sl=44c9b745-153a-4a78-b475-66a7cd7fccf9


									&start_date=1643706334


									&end_date=1643792734
									*/


									//--------------------------------------------------------------------------

									/*

									console.log(  "_ar_STR_DATE_WEEK: "  +  JSON.stringify(_ar_STR_DATE_WEEK)  );

									console.log(  "_ar_DATE_WEEK: "  +  JSON.stringify(_ar_STR_DATE_WEEK)  );




									var START_END_days_week_DATE = new Array();  // 2 posiciones sólo
									var START_END_days_week_TIMESTAMP = new Array();  // 2 posiciones sólo


									START_END_days_week_DATE[ 0 ] =  new Date( our_monday_date );

									START_END_days_week_DATE[ 0 ].setHours( 0 );
									START_END_days_week_DATE[ 0 ].setMinutes( 0 );
									START_END_days_week_DATE[ 0 ].setSeconds( 0 );  // 1


									START_END_days_week_TIMESTAMP[ 0 ] =  Math.round(  parseInt( START_END_days_week_DATE[ 0 ].getTime() )  /  1000  );

									START_END_days_week_TIMESTAMP[ 1 ] =  START_END_days_week_TIMESTAMP[ 0 ]  +  ( 7 * 24 * 60 * 60 );  //  - 2   //  604800 segundos / semana  ....  y le restamos 2 para que se quede dentro de la semana
																																				// comprobado, correcto


									console.log( "our_monday_date: "  +  our_monday_date + "\n"  +  START_END_days_week_DATE[ 0 ] + "\n"  +  START_END_days_week_TIMESTAMP[ 0 ]  + "\n"  +  START_END_days_week_TIMESTAMP[ 1 ]  );

									*/



									//--------------------------------------------------------------------------





										// Por Streetlight

										// En una hora exacta =  ( 12  +  1 ) captura de las variables ( 1 "entrada"/registro )

										// En 24 horas sería:  13 * 24  =  312  al día por streetlight

										// En 1 semana sería:  312 * 7  =  2184  a la semana

										//---------

										/*
										our_monday_date =  new Date( str_mondayStart_date );


										//window.alert(  ( new Date() ).addDays(7)  );

										//
										//window.alert( str_mondayStart_date + " ?=  "  +  our_monday_date  );		// SÍ, LO HACE BIEN


										NUM_mondayStart_date__TIMEZONE =  Math.round(  parseInt(   our_monday_date.getTime()  )  /  1000  ); // new Date( str_mondayStart_date  )
										*/

									//---


										//_obj_STREETLIGHT_VARIABLEVALs = {};


									_ar_ALL__STREETLIGHTS_VARVALs = undefined;
									_ar_ALL__STREETLIGHTS_VARVALs = null;		// LIBERAMOS MEMORIA


									_ar_ALL__STREETLIGHTS_VARVALs = new Array();


									//_ar_ALL__STREETLIGHTS_VARVALs = [];		//[];

									//_ar_INSTALLATIONS__SLs_VARVALs = [];		//new Array();		//[];		//new Array();



									var SL_inst_myNAME = "";


									//var NUM_Insts = 	ob_InsGroup_LENGTH;

									//console.log( "NUM de instalaciones:  " + NUM_Insts );


									var NUM_ALL_SLs = 0;




									//var instals = 0;
									var all_SLs = 0;

									/*
									// for Instals
									for ( var instals = 0;  instals <  NUM_Insts;  instals++ )

									while
									{
									*/





									//	setTimeout(

									//		( ) =>
									//		{


									//_ar_ALL__STREETLIGHTS_NAMES = [];

									//_ar_ALL__STREETLIGHTS_VARVALs = [];	//new Array();		//[];
									//_ar_ALL__STREETLIGHTS_VARVALs = [];


									NUM_ALL_SLs = _obj_Inst_Group.info.streetlights.length;			//_obj_Inst_Group.installations[  instals  ].streetlights.length;


									//window.alert(  _obj_Inst_Group.info.streetlights.length  );



									//_ar_INSTALLATIONS__SLs_NAMES[ instals ]   =  new Array(  NUM_SLs_inst  );	//[];  //new Array();
									//_ar_INSTALLATIONS__SLs_VARVALs[ instals ] =  new Array(  NUM_SLs_inst  );	//[];  //new Array();

									//console.log( "NUM de SLs en instalación " + instals + ":  " + NUM_SLs_inst );


									////console.log(  "PRIMER DÍA DE MES ini:  "  +  TIMESTAMP__FIRST_day_MONTH  );

									////console.log(  "ÚLTIMO DÍA DE MES fin:  "  +  TIMESTAMP__LAST_day_MONTH  );

									//----------------------


									//var obj_AUX_ant =	{};		// tiene una copia de un objeto Anterior existente para poder copiarlo a otro que no se reciba ningún dato y poder continuar con el algoritmo


									CONT_procesado_SLs =  0;


									_obj_Inst_Group.info.streetlights.forEach(
									//
										(  item,  index  ) =>
										{
											if (   CONT_procesado_SLs   <  _obj_Inst_Group.info.streetlights.length    )
											{

												setTimeout(
												//
													( ) =>
													{
														// SET TIME OUT ????????????????????
														console.log(TIMESTAMP__FIRST_day_MONTH + " -> FIRST DAY MONTH")
														console.log(TIMESTAMP__LAST_day_MONTH + " -> LAST DAY MONTH")

														if (   CONT_procesado_SLs   <  _obj_Inst_Group.info.streetlights.length    )
														{

															//console.log( "Recorrido .forEach de SLs (9):  SL["  +  index + "]= \n"  +  JSON.stringify(item , null, "	") );

															//--------

															//var
															const name_SL_insta =  item.name;		//_obj_Inst_Group.info.streetlights[  all_SLs  ].name;		//result.data.schema.fields[ 2 ].name.split( '_' )[ 0 ];
															

															//////console.log( "datos _obj_Inst_Group:: Farola[" + /*all_SLs*/  index + "]:  "  +  JSON.stringify(  name_SL_insta  ) );


															var URL_query =	CONFIG.api_url	+

																								"/installations/"	+	_obj_Inst_Group.id		+	 //installations[0].id  +

																								"/groupdata2.json"	+

																								"?page_size=1000"	+

																								//"&page=1"		+

																								"&vars=B,C,P"		+


																								"&sl="
																													+	item.id		+	//_obj_Inst_Group.info.streetlights[  all_SLs  ].id  +



																								"&start_date="  +  TIMESTAMP__FIRST_day_MONTH	+		//START_END_days_week_TIMESTAMP[ 0 ] 	+	//"1643706334"	+		//  "1 643 706 334"

																								"&end_date="  	+  TIMESTAMP__LAST_day_MONTH			//START_END_days_week_TIMESTAMP[ 1 ];		//"1643792734";


															//console.log( URL_query );



															//var _ob_SL_VARIABLEVALs__NOW = {};



															//-----------------------------------------------


															axios.get(

																			URL_query

																			,

																			{
																				"headers":
																							{
																								'Content-Type': 'application/json',
																								'Authorization': "Token " + this.props.auth.token
																							}
																			}
															).then(
															//
																( result ) =>
																{

																	if (   CONT_procesado_SLs   <  _obj_Inst_Group.info.streetlights.length    )
																	{

																		//_ob_SL_VARIABLEVALs__NOW =  null;
																		//_ob_SL_VARIABLEVALs__NOW = {};

																		//---


																		//console.log( "NUM SL_instal: "  + NUM_ALL_SLs  );


																			//_obj_STREETLIGHT_VARIABLEVALs = {};


																		//_obj_STREETLIGHT_VARIABLEVALs = 	result.data;		//.data;	/*.data*/;   // ENTRE LAS FECHAS, LOS DATOS DE LAS VARIABLES DE 1 SÓLA STREETLIGHT
																			//LA DE ARRIBA ERA GLOBAL, PONEMOS ABAJO UNA LOCAL POR SI ACASO


																			//  ¿¿ CON CONST ??			// PARECE QUE NO TERMINABA DE FUNCIONAR


																		//-------------------------------

																		let _ob_AUX_InstGroup =  result.data;



																		//_ob_SL_VARIABLEVALs__NOW =  result.data;	// DENTRO, A SU VEZ, HAY .SCHEMA, Y (DE NUEVO) .DATA


																		//console.log(  name_SL_insta  );
																		//console.log(  item.name  );

																		//console.log(  _ob_AUX_InstGroup  );



																		//console.log( _obj_STREETLIGHT_VARIABLEVALs );


																		//SL_inst_myNAME = _obj_STREETLIGHT_VARIABLEVALs.schema.fields[ 2 ].name.split( '_B' )[ 0 ];






																		if (  result.data.length  ==  0  )
																		{
																			//window.alert( "AXIOS:  DATOS VACIOS EN FAROLA id." + item.id  );

																			console.log( "AXIOS:  DATOS VACIOS EN FAROLA:" + SL_inst_myNAME  );

																			//window.alert( "Existen farolas sin datos alguno" );


																			//window.location.replace(  "https://apps.ilumek.com/informes/"  );


																			//-----

																			new_schema_aux =	{
																										fields:				new Array(),


																										primaryKey:			new Array(),


																										pandas_version: 	"0.20.0"

																									};


																			//-----------------------


																			new_schema_aux.fields.push(
																			//
																				{
																					name:	"timestamp",

																					type:	"datetime"
																				}
																			//
																			); //.push


																			new_schema_aux.fields.push(
																			//
																				{
																					name:	"epoch",

																					type:	"number"
																				}
																			//
																			); //.push


																			new_schema_aux.fields.push(
																			//
																				{
																					name:	name_SL_insta  +  "_B",

																					type:	"number"
																				}
																			//
																			); //.push


																			new_schema_aux.fields.push(
																			//
																				{
																					name:	name_SL_insta  +  "_P",

																					type:	"number"
																				}
																			//
																			); //.push


																			new_schema_aux.fields.push(
																			//
																				{
																					name:	name_SL_insta  +  "_C",

																					type:	"number"
																				}
																			//
																			); //.push


																			//------


																			new_schema_aux.primaryKey.push(
																			//
																				{
																					0:	"timestamp"
																				}
																			//
																			); //.push



																			//console.log(  new_schema_aux  );



																			new_objs_sl =	{

																									data:		new Array(),

																									schema:		new_schema_aux			//JSON.parse(   JSON.stringfy(  new_schema_aux  )   )

																								};


																			//console.log(  NUM_DAYS_MONTH  );				// 31
																			//console.log(  NUM_DAYS_MONTH  *  24  *  6 );	//  4464 .... como el resto de éste mes (agosto)  ..... "CADA 10 MIN"

																			const NUM_elems_array =		NUM_DAYS_MONTH  *  24  *  6;


																			//"&start_date="  +  TIMESTAMP__FIRST_day_MONTH	+		//START_END_days_week_TIMESTAMP[ 0 ] 	+	//"1643706334"	+		//  "1 643 706 334"

																			//"&end_date="  	+  TIMESTAMP__LAST_day_MONTH			//START_END_days_week_TIMESTAMP[ 1 ];		//"1643792734";


																			//console.log(   TIMESTAMP__FIRST_day_MONTH   );


																			for ( let ie = 0;  ie  <  NUM_elems_array;  ie++  )
																			{

																				const aux_epoch =  (   TIMESTAMP__FIRST_day_MONTH  +  (  ie  *  ( 10 * 60 )  )   )   *   1000;		//parseInt

																				//const aux_timestamp =  "";



																				//---





																				//const new_TS =   parseInt(   1000   *   (    (   TS__STR__FIRST_day_MONTH   +   (  /*hs*/ ie  *  10  *  60  )   )    )    );

																				//---

																				var new_STR_ts =  new Date(  /*new_TS*/ aux_epoch  );		 //String(  new Date(  new_TS  )  );



																				//console.log(  new_STR_ts  );



																				new_STR_ts =	new_STR_ts.getFullYear()					+
																								"-"  										+

																								(
																									(  ( new_STR_ts.getMonth() + 1 )  <  10  )
																									?
																										"0"  +  ( new_STR_ts.getMonth() + 1 )
																									:
																										( new_STR_ts.getMonth() + 1 )
																								)											+

																								"-"											+

																								(
																									(  new_STR_ts.getDate()  <  10  )
																									?
																										"0"  +  new_STR_ts.getDate()
																									:
																										new_STR_ts.getDate()
																								)
																																			+

																								"T"  										+

																								(
																									(  new_STR_ts.getHours()  <  10  )
																									?
																										"0"  +  new_STR_ts.getHours()
																									:
																										new_STR_ts.getHours()
																								)
																																			+
																								":"  										+

																								(
																									(  new_STR_ts.getMinutes()  <  10  )
																									?
																										"0"  +  new_STR_ts.getMinutes()
																									:
																										new_STR_ts.getMinutes()
																								)
																																			+
																								":"  										+

																								(
																									(  new_STR_ts.getSeconds()  <  10  )
																									?
																										"0"  +  new_STR_ts.getSeconds()
																									:
																										new_STR_ts.getSeconds()
																								)
																																			+

																								"Z";  // OJOOOOOO



																				//---



																				//console.log(  new_STR_ts  );




																				new_objs_sl.data.push(
																				//
																					{
																						timestamp:					new_STR_ts,		//aux_timestamp,

																						epoch:						aux_epoch,

																						[ name_SL_insta  +  "_B" ]:		0.0,

																						[ name_SL_insta  +  "_P" ]:		0.0,

																						[ name_SL_insta  +  "_C" ]:		0.0
																					}

																				//
																				);// .push()


																			}// for ( let ie = 0;  ie  <  NUM_elems_array;  ie++  )



																			//-------------------------------------

																			/*
																			console.log( "Ini new obj" );

																			console.log(  new_objs_sl  );

																			console.log( "Fin new obj" );
																			*/

																			//CONT_procesado_SLs++;


																			//1659304800

																			//epoch: 1659304800 000
																			//epoch: 1659305400 000
																			//epoch: 1659306000 000
																			//....
																			//epoch: 1661982600 000



																			//epoch: 1659304800 000
																			//epoch: 1659305400 000
																			//epoch: 1659306000 000
																			//...
																			//epoch: 1661982600 000



																			//_ob_AUX_InstGroup =  JSON.parse(  JSON.stringify(  new_objs_sl  ) );


																			_ob_AUX_InstGroup =  new_objs_sl;


																		}// if ----------------------------------------------------------------------------

																		//else
																		//{



																		/*
																		if (  CONT_procesado_SLs ==  0  )
																		{
																			obj_AUX_ant =	JSON.parse(  JSON.stringify(  _ob_AUX_InstGroup  )  );		// tiene una copia de un objeto Anterior existente para poder copiarlo a otro que no se reciba ningún dato y poder continuar con el algoritmo

																			console.log(  "Nueva copia de datos del primer obj no vacio"  );

																			console.log(  obj_AUX_ant  );

																			console.log(  "Fin de la copia de datos"  );

																		}//
																		*/



																		//---------------------------

																		/*
																		if (  SL_inst_myNAME  !=   name_SL_insta  )
																		{
																			window.alert(  "DIFFERENT SL NAME"  );
																		}
																		*/


																		//var len__obj_STREETLIGHT_VARIABLEVALs =  _obj_STREETLIGHT_VARIABLEVALs.data.length;

																		//var len__obj_STREETLIGHT_VARIABLEVALs =  _ob_AUX_InstGroup.data.length;
																		//console.log(  "NUM REGISTROS TRAMA ENTRE FECHA DE SL[" + index +   "] ( "  + name_SL_insta +  " ):  "  +  len__obj_STREETLIGHT_VARIABLEVALs  );





																		SL_inst_myNAME = _ob_AUX_InstGroup.schema.fields[ 2 ].name.split( '_B' )[ 0 ];



																		//console.log(  SL_inst_myNAME  );


																		//window.alert( _obj_Inst_Group.info.streetlights.length );



																		// SI POR LO QUE FUERA ÉSTE MÉTODO NO TE FUNCIONASE, HACER ANTES DEL AXIOS _NO_ BORRAR EL CONTENIDO DE TODO EL ARRAY, QUIZÁ NO BORRÁNDOLO SE SUBSANE (AHORA VA)


																		if (
																			//(  _ar_ALL__STREETLIGHTS_NAMES.length    <  _obj_Inst_Group.info.streetlights.length  ) &&

																			(  _ar_ALL__STREETLIGHTS_VARVALs.length  <  _obj_Inst_Group.info.streetlights.length  )
																		)
																		{

																			if (   _ar_ALL__STREETLIGHTS_VARVALs.length  >=  1   )
																			{

																				//SL_inst_myNAME = _ob_AUX_InstGroup.schema.fields[ 2 ].name.split( '_B' )[ 0 ];






																				var cont_i = 0;
																				var nombre_esta_ya =  false;


																				while	(
																							(  cont_i  <  _ar_ALL__STREETLIGHTS_VARVALs.length  )
																						&&
																							(   nombre_esta_ya ==  false  )
																						)
																				{

																					var aux_name_SL =  _ar_ALL__STREETLIGHTS_VARVALs[  cont_i  ].schema.fields[ 2 ].name.split( '_B' )[ 0 ];


																					if (  aux_name_SL  ==  SL_inst_myNAME  )
																					{
																						nombre_esta_ya =  true;
																					}//


																					//----

																					cont_i ++;

																				}// while ( ..... ) ---------------------------------------------


																				//----------


																				if  (   nombre_esta_ya  ==  false   )
																				{
																					CONT_procesado_SLs ++;


																					//ar_template_huecos_SL_MES.push(  /*new*/ TEMPLATE_OBJ_SL_MES  );


																					_ar_ALL__STREETLIGHTS_VARVALs.push(  _ob_AUX_InstGroup  );		// _obj_STREETLIGHT_VARIABLEVALs

																					//////console.log( "DATOS SL " + SL_inst_myNAME + " =  " + _ob_AUX_InstGroup.data.length + " items" );
																				}//



																			}// if (   _ar_ALL__STREETLIGHTS_VARVALs.length  >=  1   )
																			else // AUN NO HAY NADA
																			{

																				//_ar_ALL__STREETLIGHTS_NAMES.push(  SL_inst_myNAME ) ;

																				CONT_procesado_SLs ++;



																				_ar_ALL__STREETLIGHTS_VARVALs.push(  _ob_AUX_InstGroup  );		// _obj_STREETLIGHT_VARIABLEVALs

																				//console.log( _ob_AUX_InstGroup );
																				//////console.log( "DATOS SL " + SL_inst_myNAME + " =  " + _ob_AUX_InstGroup.data.length  + " items" );

																			}// else

																		}// if (  _ar_ALL__STREETLIGHTS_VARVALs.length  <  _obj_Inst_Group.info.streetlights.length  )





																		//------

																		//_ob_SL_VARIABLEVALs__NOW =  undefined;
																		//_ob_SL_VARIABLEVALs__NOW =  null;	// LIBERAMOS MEMORIA


																		SL_inst_myNAME = "";



																		/*

																		//console.log( _obj_STREETLIGHT_VARIABLEVALs );


																		//SL_inst_myNAME = _obj_STREETLIGHT_VARIABLEVALs.schema.fields[ 2 ].name.split( '_B' )[ 0 ];

																		SL_inst_myNAME = _ob_SL_VARIABLEVALs__NOW.schema.fields[ 2 ].name.split( '_B' )[ 0 ];



																		//var len__obj_STREETLIGHT_VARIABLEVALs =  _obj_STREETLIGHT_VARIABLEVALs.data.length;

																		var len__obj_STREETLIGHT_VARIABLEVALs =  _ob_SL_VARIABLEVALs__NOW.data.length;




																		console.log(  "NUM REGISTROS TRAMA ENTRE FECHA DE SL[" +  _obj_STREETLIGHT_VARIABLEVALs.data.schema.fileds[ 2 ].name  + "] ( "  + index  +  " ):  "  +  len__obj_STREETLIGHT_VARIABLEVALs  );	//  id_SL_insta


																		//window.alert( _obj_Inst_Group.info.streetlights.length );

																		if (
																			(  _ar_ALL__STREETLIGHTS_NAMES.length    <  _obj_Inst_Group.info.streetlights.length  ) &&
																			(  _ar_ALL__STREETLIGHTS_VARVALs.length  <  _obj_Inst_Group.info.streetlights.length  )
																		)
																		{
																			_ar_ALL__STREETLIGHTS_NAMES.push(  SL_inst_myNAME ) ;
																			_ar_ALL__STREETLIGHTS_VARVALs.push(    _ob_SL_VARIABLEVALs__NOW );		// _obj_STREETLIGHT_VARIABLEVALs
																		}
																		*/

																		//} // else


																	}// if (   CONT_procesado_SLs   <  _obj_Inst_Group.info.streetlights.length    )

																}// ( result ) =>

															).catch(

																error =>

																	this.setState(
																					{
																						error,
																						waiting: false
																					}
																	)//.this.setState()

															).finally(

																( ) =>
																{

																	//	console.log(  "_RESULT 2_:  " + JSON.stringify( result ) );

																	// Por Streetlight

																	// En una hora exacta =  ( 12  +  1 ) captura de las variables ( 1 "entrada"/registro )

																	// En 24 horas sería:  13 * 24  =  312  al día por streetlight

																	// En 1 semana sería:  312 * 7  =  2184  a la semana


																	//console.log( "DATOS DE STREETLIGHT[ " +  instals + " ][ " + mySLs  + " ]" +   ":  "  +  JSON.stringify(  SL_inst_myNAME,   null,   "		"  ) );


																	//-----










																	//	console.log( "DATOS DE STREETLIGHT HASTA LA :  " +  mySLs  +  " \n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs,   null,   "		"  )  );


																	/*
																	console.log( "SL variablevalues FINISHED" );
																	console.log( JSON.stringify( _obj_STREETLIGHT_VARIABLEVAL, null, "	"  )  );
																	*/



																	/*
																	if (  mySLs  ==  (  NUM_SLs_inst - 1 )  )
																	{
																		//_ar_INSTALLATIONS__SLs_VARVALs[ instals ][ mySLs ] = _obj_STREETLIGHT_VARIABLEVALs.forEach(  ( x ) => x  );



																		_ar_INSTALLATIONS__SLs_NAMES[   instals ].push(  _ar_STREETLIGHTS_NAMES  );  		//new Array();
																		_ar_INSTALLATIONS__SLs_VARVALs[ instals ].push(  _ar_STREETLIGHTS_VARVALs  );
																	}
																	*/



																	//CONT_procesado_SLs ++;


																	////window.alert(  CONT_procesado_SLs  +  " / "  +  _obj_Inst_Group.info.streetlights.length  );

																	if (   CONT_procesado_SLs  <=  _obj_Inst_Group.info.streetlights.length    )
																	{

																		if (   CONT_procesado_SLs  ==  _obj_Inst_Group.info.streetlights.length    )
																		{


																			if (    _ar_ALL__STREETLIGHTS_VARVALs.length  ==  _obj_Inst_Group.info.streetlights.length    )
																			{


																				////window.alert(  "ENTRA"  );

																				setTimeout(
																				//
																					( ) =>
																					{

																						CONT_procesado_SLs ++;		// DE ÉSTA FORMA NO VOLVERÁ A ENTRAR DE NUEVO AQUÍ (SÓLO 1 ÚNICA VEZ)


																						//////console.log( "setTimeout de sunrise y sunset!!!" );

																						//////console.log(  "_ar_ALL__STREETLIGHTS_VARVALs.length:  "  +  _ar_ALL__STREETLIGHTS_VARVALs.length   );



																						/*
																						for (  let i_sl = 0;  i_sl < _ar_ALL__STREETLIGHTS_VARVALs.length;  i_sl++   )
																						{

																							console.log( "DATOS SL _ar_ALL__STREETLIGHTS_VARVALs => "  +  _ar_ALL__STREETLIGHTS_VARVALs[  i_sl  ].length  +  " items" );

																						}// for (  let i_sl = 0;  i_sl < _ar_ALL__STREETLIGHTS_VARVALs.length;  i_sl++   )
																						*/



																						/*
																						_ar_ALL__STREETLIGHTS_VARVALs.forEach(

																							(  item2,  index2  ) =>
																							{

																								console.log( "DATOS SL _ar_ALL__STREETLIGHTS_VARVALs => "  +  item2.length  +  " items" );

																							}// () =>

																						); // .forEach(
																						*/







																						this.state.sunRise_sunSet_GET = "true";


																						/*
																						this.state.sunriseDAY  =  AMANECERES_WEEK[ 0 ];



																						this.state.ar_sunriseDAY = AMANECERES_WEEK.map( (x) => x );
																						this.state.ar_sunsetDAY  = ATARDECERES_WEEK.map( (x) => x );
																						*/



																						//this.state.selected_installationgroup =  _obj_Inst_Group;


																						this.state.selected_installationgroup.group =  _obj_Inst_Group.id;

																						this.state.selected_installationgroup.group =  _obj_Inst_Group.group_name;


																						this.state.selected_installationgroup.streetlights =  _obj_Inst_Group.info.streetlights;



																						this.state.total_streetlights = _obj_Inst_Group.info.streetlights.length;


																						/*
																						this.setState(
																										{
																											//waiting:	false,
																											waiting:	true,


																											total_streetlights:_obj_Inst_Group.info.streetlights.length,


																											selected_installationgroup:		_obj_Inst_Group        //result.data
																										}
																						);//.setState()
																						*/




																						////window.alert(  "EN ZONA API FAROLAS AL FINAL:  this.state.sunRise_sunSet_GET = true"  );

																						//console.log(  ar_template_huecos_SL_MES  );


																						this.setState(
																										{
																											//sunriseDAY: AMANECERES_WEEK[ 0 ],

																											//ar_sunriseDAY: AMANECERES_WEEK.clone(),


																											total_streetlights:  _obj_Inst_Group.info.streetlights.length,


																											selected_installationgroup:		_obj_Inst_Group,


																											sunRise_sunSet_GET: "true"




																											//waiting: false,

																											//comp_CHART_DATA: ""
																										}
																						);//

																					}//

																					,

																					300

																				); //setTimeout


																			}// if (    _ar_ALL__STREETLIGHTS_VARVALs.length  ==  _obj_Inst_Group.info.streetlights.length    )

																		}// if (   CONT_procesado_SLs  ==  _obj_Inst_Group.info.streetlights.length    )


																	}// if (   CONT_procesado_SLs  <=  _obj_Inst_Group.info.streetlights.length    )


																} // ( ) => {

															);// finallly(

														}// if (   CONT_procesado_SLs   <  _obj_Inst_Group.info.streetlights.length    )

													}//

													,

													( const_timeWait_SL /** 1*/ )

												);//setTimeout  ..... por SL

											}// if (   CONT_procesado_SLs   <  _obj_Inst_Group.info.streetlights.length    )


											//---




										}

									);//   .forEach ----------------



									//------------------------------------------------------------------



									//setTimeout(
									//
									//	( ) =>
									//	{
											//console.log( "DATOS DE __INSTALACION: __" + "0" + ":\n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs[0],   null,   "		"  ) );



											//console.log( "DATOS DE TODAS __INSTALACIONES __" + "" + ":\n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs,   null,   "		"  ) );



											//console.log( "DATOS DE TODAS STREETLIGHTS DE INSTALLATION:  \n"  +  JSON.stringify(  _ar_STREETLIGHT_VARVAL,   null,   "		"  ) );
											//
											//console.log( "DATOS DE STREETLIGHT 0:  \n"  +  JSON.stringify(  _ar_STREETLIGHTS_VARVALs[0].data,   null,   "		"  ) );



											//------------------------------------------------------------------------------------





											//------



											// ESTO VIENE A SIGNIFICAR REALMENTE CUANDO HAN ACABADO TODOS LOS PROCESOS ANTERIORES Y SE DA LA SEÑAL DE FLAG Y DE RE-RENDER

											/*
											console.log( "setTimeout de sunrise y sunset!!!" );



											this.state.sunRise_sunSet_GET = "true";



											//this.state.sunriseDAY  =  AMANECERES_WEEK[ 0 ];



											//this.state.ar_sunriseDAY = AMANECERES_WEEK.map( (x) => x );
											//this.state.ar_sunsetDAY  = ATARDECERES_WEEK.map( (x) => x );



											this.setState(
															{
																//sunriseDAY: AMANECERES_WEEK[ 0 ],

																//ar_sunriseDAY: AMANECERES_WEEK.clone(),



																sunRise_sunSet_GET: "true"




																//waiting: false,

																//comp_CHART_DATA: ""
															}
											);
											*/


									//	}

									//	//,

										// ANTES DE RE-RENDER Y QUE SE LANCEN TODOS LOS PROCESOS ASOCIADOS, ÉSTA ES LA ESPERA A CAPTURAR TODAS LAS SLs ENTRE FECHAS

										//const_timeWait_Total_SLs 				// = ( _obj_Inst_Group.info.streetlights.length  *  250 )  	//2000	//  CON 2000 CAPTURA BIEN LAS 9 SLS 		     //2000	//3000	// 1200  // SE DIRÍA QUE ÉSTE TIEMPO REALMENTE ES EL QUE MANDA SEÑAL DE RE-RENDER


									//);// setTimeout


									//-----------------------------------------------------------------------------------------


									/*
									all_SLs = 0;

									// for SLs
									//for ( var mySLs = 0;  mySLs <  NUM_SLs_inst;  mySLs++ )
									while (  all_SLs  <  NUM_ALL_SLs  )
									{


										//---

										all_SLs++;

									}// while : all_SLs -----------
									*/
										//	}
										//	,


										//	200 	// 100	 //( _obj_Inst_Group.info.streetlights.length  *  25 ) 	//200
										//);


										//---


										//---

										/*
										_obj_INSTALLATION__SLs_VARVALs = [];
										_obj_INSTALLATION__SLs_VARVALs = _ar_STREETLIGHTS_VARVALs.map(  ( x ) => x  );


										console.log( "DATOS DE STREETLIGHT 0:  \n"  +  JSON.stringify(  _ar_STREETLIGHTS_VARVALs[0].data,   null,   "		"  ) );

										console.log( "DATOS DE __INSTALACION: __" + instals + ":\n"  +  JSON.stringify(  _obj_INSTALLATION__SLs_VARVALs,   null,   "		"  ) );
										*/
										//-----

										//_obj_INSTALLATION__SLs_VARVALs = [];
										//_ar_INSTALLATIONS__SLs_VARVALs = [];


									//}// for : instals


									//-----------------------



							//	}




							//	,

							//	2200	 // 1200 //800 	 // 1500

							//	// ESPERA A CAPTURAR LA TIMESTAMP_ZONE / GMT

							//);// setTimeout




							//------






						}// ( ) =>

						,

						100

					); // setTimeout


				}// ( ) =>

			);// finally



			//			}
			//
			//			, 7000
			//);//





			NUM_CALL_API++;




			//console.log( "All DATA in 'getInstallationGroupDetail' RETRIEVED" );



		}// if (  NUM_CALL_API  ==  0  )



	}//	getInstallationGroupDetail ( id, all )


	//-----------------------------------------------------------------------------------------------------------------------------------




	get_Schedules_Profiles (  IDschedule,  IDschedule_SIG  )
	{
	//





		this.state.id =  IDschedule;
		this.state.waiting = true;



		this.setState(
						{
							id:      IDschedule,


							waiting: true,

							showSpinner: true
						}
		);//.setState()



		//--

		// ACTUAL:

		axios.get(

					CONFIG.api_url  +  "/scheduleprofiles/"  +  IDschedule,

					{
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': "Token " + this.props.auth.token
									}
					}
		).then(
					( result ) =>
					{


						let scheduleJSON =  result.data.json_data;


						ar_DIMM =  new Array();
						//ar_DIMM =  [];


						ar_DIMM =  scheduleJSON;	//result.data.json_data;



						//console.log(  "scheduleJSON:  \n" + JSON.stringify( scheduleJSON )  );


						//-------


						// Actualizamos sunset y sunrise

						if (  _data_PREV.length > 10  )
						{

							for (  let i = 0;  i < _data_PREV.length;  i++  )
							{

								scheduleJSON[i][ "sun" ]  = _data_PREV[ i ].sun;
								scheduleJSON[i][ "sun0" ] = _data_PREV[ i ].sun0;


								scheduleJSON[i][ "name" ] = _rangos_PREV[ i ][ 0 ];

							}//for

						}// if




						//------------------------------------------------------------------------------------------------------------------------------------------------------------------
						//------------------------------------------------------------------------------------------------------------------------------------------------------------------



						const resultDATA_json_data_AR_SchProf =  result.data.json_data_AR_SchProf;		//resultDATA.json_data_AR_SchProf;

						//---

						console.log(  "resultDATA_json_data_AR_SchProf: "  );
						console.log(  resultDATA_json_data_AR_SchProf  );

						console.log(  "--------------------------------"  );


						//----------------------------------------------------


						if (  (resultDATA_json_data_AR_SchProf != null)  &&  (resultDATA_json_data_AR_SchProf.length != undefined)  )
						{


							//console.log(  "resultDATA_json_data_AR_SchProf: ("  +  resultDATA_json_data_AR_SchProf.length  +  ")"  );
							//console.log(  resultDATA_json_data_AR_SchProf );				// 13



							//--

							let aux_Today = new Date();
							let our_MONTH__ANT =  aux_Today.getMonth();	//  +  1;


							//our_MONTH__ANT = our_MONTH__ANT  -  1;

							our_MONTH__ANT =	(  our_MONTH__ANT  ==  0  )
												?
													12
												:
													our_MONTH__ANT;


							//-----

							//console.log(  "our_MONTH__ANT: "  +  our_MONTH__ANT  );

							//console.log(  "resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length: "  );
							//console.log(  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length  );


							//-----


							if (  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length  >= 1  )
							{

								//console.log(  "our_MONTH__ANT: "  +  our_MONTH__ANT  );

								//---

								ar_M5_DIMMING__ANT_MONTH =  new Array();				// MES ANTERIOR A LA CONSULTA ACTUAL EN ILUMEK =  MES EN CURSO DEL PROPIO INFORME
								//var ar_M5_DIMMING__this_MONTH =  new Array();


								//const Max_posiciones_Graficas =  17 * 60;	//  1020


								//---


									/*
									timestamp_INI:			timestamp_INTERVAL_INI,
									str_time_hour_INI:		str_time_hour_INTERVAL_INI,


									timestamp_END:			timestamp_INTERVAL_END,
									str_time_hour_END:		str_time_hour_INTERVAL_END,


									interval_VAL:			last_interval_VAL		//ar_DAT_elora_M5__REAL[ our_MONTH__ANT ][ our_k - 1 ].val
									*/



								//ar_DIMM = [];



								for (   let i_interv = 0;  i_interv <  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length;  i_interv ++   )
								{


									for (  let our_k = resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ][  i_interv  ].timestamp_INI;  our_k <=  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ][  i_interv  ].timestamp_END;  our_k ++   )
									{

										let aux_INT_time_hour__HOUR =  Math.floor(  our_k  /  60  )  +   17;

										aux_INT_time_hour__HOUR =	(   aux_INT_time_hour__HOUR  >=  24   )
																	?
																		(  aux_INT_time_hour__HOUR  -  24  )
																	:
																		aux_INT_time_hour__HOUR;

										let aux_INT_time_hour__MIN =  our_k % 60;


										//---


										let aux_STR_time_hour__HOUR =	(   aux_INT_time_hour__HOUR  <  10   )
																		?
																			"0"  +  String(  aux_INT_time_hour__HOUR  )
																		:
																			String(  aux_INT_time_hour__HOUR  );


										let aux_STR_time_hour__MIN =	(   aux_INT_time_hour__MIN  <  10   )
																		?
																			"0"  +  String(  aux_INT_time_hour__MIN  )
																		:
																			String(  aux_INT_time_hour__MIN  );



											//
											//name: "12:00"
											//
											//sp: 0
											//
											//sun: 0
											//
											//sun0: 0

										//ar_DIMM
										ar_M5_DIMMING__ANT_MONTH.push(

																		//{
																		//	timestamp:		our_k,

																		//	time_hour:	    String(	aux_STR_time_hour__HOUR )  +  ":"  +  String(  aux_STR_time_hour__MIN  ),

																		//	val:			resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ][  i_interv  ].interval_VAL
																		//}

																		{
																			sun:		our_k,

																			name:	    String(	aux_STR_time_hour__HOUR )  +  ":"  +  String(  aux_STR_time_hour__MIN  ),

																			sp:			resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ][  i_interv  ].interval_VAL
																		}

										); // .push(






									} // for (   let i_interv = 0;  i_interv <  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length;  i_interv ++   )


								} // for (   let i_interv = 0;  i_interv <  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length;  i_interv ++   )

								//---


								console.log(  "ar_M5_DIMMING__ANT_MONTH: "  );
								console.log(  ar_M5_DIMMING__ANT_MONTH  );



								//---------

								ar_DIMM = []


								/*

								MAYO

								[
									{
										"timestamp_INI": 0,
										"str_time_hour_INI": "17:00",
										"timestamp_END": 266,
										"str_time_hour_END": "21:26",

										"interval_VAL": 0
									},
									{
										"timestamp_INI": 267,
										"str_time_hour_INI": "21:27",
										"timestamp_END": 365,
										"str_time_hour_END": "23:05",

										"interval_VAL": 100
									},
									{
										"timestamp_INI": 366,
										"str_time_hour_INI": "23:06",
										"timestamp_END": 764,
										"str_time_hour_END": "05:44",

										"interval_VAL": 30
									},
									{
										"timestamp_INI": 765,
										"str_time_hour_INI": "05:45",
										"timestamp_END": 824,
										"str_time_hour_END": "06:44",

										"interval_VAL": 100
									},
									{
										"timestamp_INI": 825,
										"str_time_hour_INI": "06:45",
										"timestamp_END": 1019,
										"str_time_hour_END": "09:59",

										"interval_VAL": 0
									}
								  ]
								  */


								ar_DIMM =  [

											//



											{
											"name": "12:00",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "12:30",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "13:00",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "13:30",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "14:00",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "14:30",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "15:00",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "15:30",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "16:00",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "16:30",
											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "17:00",
											"sun": 0,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "17:30",
											"sun": 0,
																"sp": 0,
											"sun0": 100
											},


											//

											{
											"name": "18:00",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "18:30",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "19:00",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},

											{
											"name": "19:30",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "20:00",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "20:30",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},

											{
											"name": "21:00",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},




											{
											"name": "21:30",

											"sun": 100,
																"sp": 100,
											"sun0": 100
											},
											{
											"name": "22:00",

											"sun": 100,
																"sp": 100,
											"sun0": 100
											},
											{
											"name": "22:30",

											"sun": 100,
																"sp": 100,
											"sun0": 100
											},





											{
											"name": "23:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "23:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "00:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "00:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "01:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "01:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "02:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "02:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "03:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "03:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "04:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "04:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "05:00",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},
											{
											"name": "05:30",

											"sun": 100,
																"sp": 30,
											"sun0": 100
											},




											{
											"name": "06:00",

											"sun": 100,
																"sp": 100,
											"sun0": 100
											},

											{
											"name": "06:30",

											"sun": 100,
																"sp": 100,
											"sun0": 100
											},





											{
											"name": "07:00",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "07:30",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},






											{
											"name": "08:00",

											"sun": 100,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "08:30",

											"sun": 0,
																"sp": 0,
											"sun0": 100
											},
											{
											"name": "09:00",

											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "09:30",

											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "10:00",

											"sun": 0,
																"sp": 0,
											"sun0": 0
											},

											//

											{
											"name": "10:30",

											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "11:00",

											"sun": 0,
																"sp": 0,
											"sun0": 0
											},
											{
											"name": "11:30",

											"sun": 0,
																"sp": 0,
											"sun0": 0
											}


											//
								  		];


								//---------



								// data = { this_.state.comp_CHART_DATA.slice( 10, 45 ) }		// this_.state.comp_CHART_DATA.slice( 10, 45 )



								/*
								ar_DIMM = []
								ar_DIMM =  ar_M5_DIMMING__ANT_MONTH
								*/

								//console.log(  "ar_DIMM: "  );
								//console.log(  ar_DIMM  );


								//console.log(  "-----------------------------------------------"  );



								// CAMBIO (NUEVO)



								scheduleJSON =  ar_DIMM;



								console.log( "scheduleJSON: " );
								console.log( scheduleJSON );


								//------------------------------------------------------




							} // if (  resultDATA_json_data_AR_SchProf[ our_MONTH__ANT ].length  >= 1  )


						} // if ( (resultDATA_json_data_AR_SchProf != undefined)  &&  (resultDATA_json_data_AR_SchProf != null) )
						else
						{

							// console.log( "resultDATA_json_data_AR_SchProf:  Es undefined o null" );		// CORRECTO, CON LAS DEL MODEO 1 QUE NO SE LES ASIGNÓ NINGÚN "OBJETO" ({}), DA ÉSTE RESULTADO (ESPERADO)


						} // else








						//------------
						//-------------------------------------------------------------------------------------------------------






						this.setState(
										{
											//waiting: 			false,

											scheduleprofile: 	result.data,

											profileName: 		result.data.name,

											profileComments: 	result.data.comments,

											wait_in: 			result.data.wait_in,

											time_to_wait: 		result.data.time_to_wait,


											dimming: 			result.data.dimming,



											comp_CHART_DATA: 		scheduleJSON,


											//

											showSpinner: 		true	//false
										}
						);

					}
		).catch(
					error =>
						this.setState(
										{
											error,
											waiting: false
										}
						)
		).finally(

			( ) =>
			{
				//------------------------------------------------------------------

				// SIG.:

				//------------------------------------------------------------------


				//setTimeout(

				//	( ) =>
				//	{

						//axios.get(
						//			CONFIG.api_url  +  "/scheduleprofiles/"  +  IDschedule_SIG,

						//			{
						//				"headers":
						//							{
						//								'Content-Type': 'application/json',
						//								'Authorization': "Token " + this.props.auth.token
						//							}
						//			}
						//).then(
						//			( result ) =>
						//			{



						//				ar_DIMM_SIG =  new Array();		//[];
						//				ar_DIMM_SIG =  result.data.json_data;


										//console.log( "AQUIIIIIIIIIIIIIIIIIIIIIIIIIII !!!!!!!!!!" );
										//console.log(  ar_DIMM_SIG  );


										// time_to_wait

										// wait_in:	seconds



										/*
										let our_SunSet_day;

										let int_SunSet_Hour;
										let int_SunSet_Mins;

										let our_TS_Hour_Min_SunSet;


										//---


										let our_SunRise_day;

										let int_SunRise_Hour;
										let int_SunRise_Mins;

										let our_TS_Hour_Min_SunRise;


										//---


										let DELTA_TS_sunSet_sunRise_FHours;


										//-----------------


										suma_Horas_Reales_TEORICAS =  parseFloat(  0.0  );

										ar_Horas_SunsetSunrise_dias_Mes = null;
										ar_Horas_SunsetSunrise_dias_Mes = [];


										for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )
										{

											our_SunSet_day =  our_JSON_OBJ_sunrise[  our_mon - 1  ][  imd  ][ "sunset" ];

											int_SunSet_Hour =  parseInt(  our_SunSet_day.split(  ":"  )[ 0 ]  );
											int_SunSet_Mins =  parseInt(  our_SunSet_day.split(  ":"  )[ 1 ]  );


											our_TS_Hour_Min_SunSet =  (  int_SunSet_Hour  *  60  )  +  int_SunSet_Mins;


											//----


											our_SunRise_day =  our_JSON_OBJ_sunrise[  our_mon - 1  ][  imd  ][ "sunrise" ];


											int_SunRise_Hour =  parseInt(  our_SunRise_day.split(  ":"  )[ 0 ]  );
											int_SunRise_Mins =  parseInt(  our_SunRise_day.split(  ":"  )[ 1 ]  );


											our_TS_Hour_Min_SunRise =   (  24  *  60  )   +   (   int_SunRise_Hour  *   60   )   +   int_SunRise_Mins;


											//------------------


											DELTA_TS_sunSet_sunRise_FHours =  parseFloat(   (  our_TS_Hour_Min_SunRise  -  our_TS_Hour_Min_SunSet  )   /   60.0   );

											//console.log( DELTA_TS_sunSet_sunRise_FHours );

											//------------------

											ar_Horas_SunsetSunrise_dias_Mes.push(  DELTA_TS_sunSet_sunRise_FHours  );

											//suma_Horas_Reales_TEORICAS  +=  DELTA_TS_sunSet_sunRise_FHours;


										}//	for ( let imd = 0;  imd < NUM_DAYS_MONTH;  imd++ )

										*/




						//			}//
						//).catch(
						//
						//	error =>
						//		this.setState(
						//						{
						//							error,
						//							waiting: false
						//						}
						//		)
						//); // .catch()
						////

				//	}//


					//, 300

				//); // setTimeout(


			} // ( ) => {

		); //.finally()



	}// get_Schedules_Profiles ( IDschedule )


	//-------


	/*

	checkValue ( )
	{

		let totalAConsumir = 0.0;
		let totalPower = this.state.selected_installationgroup.installations[ 0 ].mean_power;
		let totalCapacity = this.state.selected_installationgroup.installations[ 0 ].mean_capacity;

		this.state.comp_CHART_DATA.forEach(
									( item, index ) =>
									{
										totalAConsumir +=  totalPower * ( item.curve_data_sp / 100 ) * 0.5;
									}
		);//.forEach()

		let hours = this.state.time_to_wait;

		if (  this.state.wait_in == "seconds" )
		{
			hours = hours / 3600;
		}
		else
		{
			hours = hours / 60;
		}// if

		let power_per_detections =  this.state.selected_installationgroup.installations[0].estimacion_detecciones  *  hours;


		if (  totalAConsumir + power_per_detections > totalCapacity  )
		{
			this.setState(  { showAlert: true }  );
		}
		else
		{
			this.setState(  { showAlert: false }  );
		}// if

	}// checkValue ( )
*/


	/*
	shouldComponentUpdate ( )
	{
    	console.log('Greeting - shouldComponentUpdate lifecycle');

    	//return false;
  	}// shouldComponentUpdate ( )
	*/


	//-------------------------------------------------------------------------------------------------------------------------


	componentDidMount ( )
	{

				//$( "header" ).innerHTML = "";
		//		$( "header" ).html( "" );


		//		$( "footer" ).css(  "background-color",  $( "body" ).css( "background-color" )  );

				//$( "footer" ).innerHTML = "";
		//		$( "footer" ).html( "" );

				//---

		//window.location.reload();

		//AMANECERES_WEEK = new Array();
		//ATARDECERES_WEEK = new Array();


		//window.alert(  "this.state.global_PDF_Generado_y_Renderizado:  "  +  this.state.global_PDF_Generado_y_Renderizado  );

		/*
		if (
				//(  this.state.LAST_re_render == "true"  )  &&

				(  this.state.global_PDF_Generado_y_Renderizado  ==  "true"  )
		)

		{
			//global_PDF_Generado_y_Renderizado = false;

			window.alert(  "...... ENTRA POR EL SITIO DE NUEVO"  );

			//window.location.reload();
		}
		*/


		//PULSADO_BOTON =  false;



		//this.componentWillUnmount( );



		//window.alert(   "this.state.NUM_COUNT_MOUNT:  "  +  this.state.NUM_COUNT_MOUNT   );



		if (  this.state.NUM_COUNT_MOUNT == 0  )
		{




			this.setState(   this.constructor()   );



			////window.alert(  "____componentDidMount____  (NUM_CALL_API: "  +  NUM_CALL_API  +  " )"  );



			////window.alert(  "this.state.viene_de_antes:  "  +  this.state.viene_de_antes );






			//------------------------------




			//STR_timeZ_from_AR_SL:							"2023-03-26 T 03:00:00"
			//convert_TS_from_String_from_AR_SL:		1 679 792 400 000	(



			let AUX_STR_timeZ_from_AR_SL =  "";

			let AUX_convert_TS_from_String_from_AR_SL = 0;

			let str_time_VUELTA_DESDE_ts = "";



			//--

			/*
			console.log(  "................................................."  );


			AUX_STR_timeZ_from_AR_SL =  "2023-03-26T01:50:00";		//_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];
			AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																	(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

														); // parseInt(
			str_time_VUELTA_DESDE_ts =  String(   new Date(  AUX_convert_TS_from_String_from_AR_SL  )   );

			console.log( "AUX_STR_timeZ_from_AR_SL: "  +  AUX_STR_timeZ_from_AR_SL );
			console.log( "AUX_convert_TS_from_String_from_AR_SL: "  +  AUX_convert_TS_from_String_from_AR_SL );

			console.log( "str_time_VUELTA_DESDE_ts: "  +  str_time_VUELTA_DESDE_ts );


			console.log(  "..................."  );


			//--

			AUX_STR_timeZ_from_AR_SL =  "2023-03-26T02:00:00";		//_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];
			AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																	(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

														); // parseInt(
			str_time_VUELTA_DESDE_ts =  String(   new Date(  AUX_convert_TS_from_String_from_AR_SL  )   );

			console.log( "AUX_STR_timeZ_from_AR_SL: "  +  AUX_STR_timeZ_from_AR_SL );
			console.log( "AUX_convert_TS_from_String_from_AR_SL: "  +  AUX_convert_TS_from_String_from_AR_SL );

			console.log( "str_time_VUELTA_DESDE_ts: "  +  str_time_VUELTA_DESDE_ts );


			console.log(  "................................................."  );


			AUX_STR_timeZ_from_AR_SL =  "2023-03-26T02:10:00";		//_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];
			AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																	(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

														); // parseInt(
			str_time_VUELTA_DESDE_ts =  String(   new Date(  AUX_convert_TS_from_String_from_AR_SL  )   );

			console.log( "AUX_STR_timeZ_from_AR_SL: "  +  AUX_STR_timeZ_from_AR_SL );
			console.log( "AUX_convert_TS_from_String_from_AR_SL: "  +  AUX_convert_TS_from_String_from_AR_SL );

			console.log( "str_time_VUELTA_DESDE_ts: "  +  str_time_VUELTA_DESDE_ts );

			//--

			console.log(  "................................................."  );

			AUX_STR_timeZ_from_AR_SL =  "2023-03-26T03:00:00";		//_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];
			AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																	(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

														); // parseInt(
			str_time_VUELTA_DESDE_ts =  String(   new Date(  AUX_convert_TS_from_String_from_AR_SL  )   );

			console.log( "AUX_STR_timeZ_from_AR_SL: "  +  AUX_STR_timeZ_from_AR_SL );
			console.log( "AUX_convert_TS_from_String_from_AR_SL: "  +  AUX_convert_TS_from_String_from_AR_SL );

			console.log( "str_time_VUELTA_DESDE_ts: "  +  str_time_VUELTA_DESDE_ts );

			console.log( "..." );


			AUX_STR_timeZ_from_AR_SL =  "2023-03-26T03:10:00";		//_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];
			AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																	(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

														); // parseInt(
			str_time_VUELTA_DESDE_ts =  String(   new Date(  AUX_convert_TS_from_String_from_AR_SL  )   );

			console.log( "AUX_STR_timeZ_from_AR_SL: "  +  AUX_STR_timeZ_from_AR_SL );
			console.log( "AUX_convert_TS_from_String_from_AR_SL: "  +  AUX_convert_TS_from_String_from_AR_SL );

			console.log( "str_time_VUELTA_DESDE_ts: "  +  str_time_VUELTA_DESDE_ts );

			//--

			console.log(  "................................................."  );
			console.log(  "................................................."  );

			AUX_STR_timeZ_from_AR_SL =  "2023-03-26T04:00:00";		//_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];
			AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																	(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

														); // parseInt(
			str_time_VUELTA_DESDE_ts =  String(   new Date(  AUX_convert_TS_from_String_from_AR_SL  )   );

			console.log( "AUX_STR_timeZ_from_AR_SL: "  +  AUX_STR_timeZ_from_AR_SL );
			console.log( "AUX_convert_TS_from_String_from_AR_SL: "  +  AUX_convert_TS_from_String_from_AR_SL );

			console.log( "str_time_VUELTA_DESDE_ts: "  +  str_time_VUELTA_DESDE_ts );

			//--


			//console.log( "FALTA LA CONVERSIÓN DE VUELTA !!!" );
			*/

			//-----------------------------



			// 	global_PDF_Generado_y_Renderizado =  false;







			ar_M5_DIMMING__ANT_MONTH =   new Array();




			// 	global_PDF_Generado_y_Renderizado =  false;

			_cont_rerender_svg =  0;


			CONT_procesado_SLs =  0;




			NUM_CALL_API =  0;

			NUM_GENERATE_WEEK_GRAFS =  0;




			TODOS_DATOS_ACCESIBLES =  false;

			HTML_GRAFS_GEN_DATOS_ACCESIBLES =  false;




			NUM_renders_SVG_rendered =  0;

			//CONT_procesado_SLs =  0;

			_Num_Renders_RENDER_FUNC =  0;




			this.state.LAST_re_render = "false";

			this.state.sunRise_sunSet_GET = "false";

			this.state.SCRIPT_svg_rendered = "false";


			this.setState(

				{
					LAST_re_render: "false",

					sunRise_sunSet_GET: "false",

					SCRIPT_svg_rendered: "false",

					NUM_COUNT_MOUNT: 0,



					total_streetlights: 0,


					selected_installationgroup:  {}
				}

			); // this.setState(







			if	(
					(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
				)
			{






				//-----------------------------




				_Num_Renders_HTML_to_IMG = 0;   // 190122


				_Num_Renders_RENDER_FUNC = 0;

					//_cont_rerender_svg = 0;



				//-----

				/*
				this.state.pulsado_Button =  "true";

				this.setState(
								{
									pulsado_Button: "true",
								}
				); //----
				*/



				if (  true /*PULSADO_BOTON  ==  false*/  )
				{

					//let initTime = Moment(  "20180101 12:00",  "YYYYMMDD hh:mm"  );


					//const this_ =  this;		// (1ero puesto)




					//---

					/*
					GLOBAL_SIG_dat_SL_week =  parseInt(  this.props.location.search.split(  "&drate="  )[ 1 ].split( "&monday=" )[ 0 ]  );

					if (
							(  GLOBAL_SIG_dat_SL_week < 1  ) ||
							(  GLOBAL_SIG_dat_SL_week > 3  )
					)
					{
						GLOBAL_SIG_dat_SL_week =  3;

					}// if ---
					*/


					//---


					GLOBAL_SIG_dat_SL_week =  3;		//1;		//3;	//3;


					//---
					// DPA *******************************************************************************************************************************
					let selectedMonth = localStorage.getItem('selectedMonth')
					let selectedYear = localStorage.getItem('selectedYear')
					
					if ((selectedMonth !== null && !selectedMonth.includes('-')) || (selectedYear !== null && !selectedYear.includes('-'))) {

						selectedMonth = parseInt(selectedMonth)
						selectedYear = parseInt(selectedYear)

						/*if ((typeof selectedMonth === 'number' && !isNaN(selectedMonth) ) && (typeof selectedYear === 'number' && !isNaN(selectedYear))){

							//alert(selectedMonth)
							//alert(selectedYear)

						}else {

							//alert("Elija bien :)")

						}*/

					}else {

						window.alert ("Asegúrese de haber elegido una fecha correcta para la generación del informe.")

					}
					// DPA *******************************************************************************************************************************


					let aux_Today = new Date(selectedYear, selectedMonth, 1); // TENGO QUE HACER QUE LA FECHA SELECCIONADA SALGA AQUI



					let our_MONTH =  aux_Today.getMonth()  //+  1;		// 1 ... 12
					
					let our_MONTH_ANT = our_MONTH 

					/*let our_MONTH_ANT =	(  our_MONTH  >=  2  )
										?
											(  our_MONTH - 1  )
										:
											12;		// AÑO ANTERIOR!*/


					let str_month_ANT =	(  our_MONTH_ANT  >=  10     )			// aux_Today.getMonth()  =>   de 0 ...11
										?
											String( our_MONTH_ANT  )
										:
											"0"  +  String( our_MONTH_ANT  );			//..... PONERLE EL '0' POR DELANTE

										//  +  0  =>  MES ANTERIOR AL ACTUAL


					/*let our_YEAR =	(  our_MONTH_ANT  !=  12  )
									?
										aux_Today.getFullYear()
									:
										(  aux_Today.getFullYear() - 1  );*/


					let our_YEAR = aux_Today.getFullYear()


					//window.alert(   aux_Today.getMonth()   );

					var str_mondayStart_date =	our_YEAR   +   "-"   +   str_month_ANT   +   "-01";					//this.props.location.search.split(  "monday="  )[ 1 ];


					//window.alert( str_mondayStart_date );





					/*
					var dd = String(today.getDate()).padStart(2, '0');

					var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!

					var yyyy = today.getFullYear();
					*/













					/*var*/ our_monday_date =  new Date( str_mondayStart_date );







					//window.alert(  ( new Date() ).addDays(7)  );

					//
					//window.alert( str_mondayStart_date + " ?=  "  +  our_monday_date  );		// SÍ, LO HACE BIEN




					/*var*/ NUM_mondayStart_date__TIMEZONE =  Math.round(  parseFloat(   our_monday_date.getTime()  )  /  1000.0  ); // new Date( str_mondayStart_date  )


					//var STR_mondayStart_date = NUM_mondayStart_date.toString();



					/*

					let our_MONTH_ANT =	(  our_MONTH  >=  2  )
										?
											(  our_MONTH - 1  )
										:
											12;		// AÑO ANTERIOR!


					let str_month_ANT =	(  our_MONTH_ANT  >=  10     )			// aux_Today.getMonth()  =>   de 0 ...11
										?
											String( our_MONTH_ANT  )
										:
											"0"  +  String( our_MONTH_ANT  );			//..... PONERLE EL '0' POR DELANTE

										//  +  0  =>  MES ANTERIOR AL ACTUAL


					let our_YEAR =	(  our_MONTH_ANT  !=  12  )
									?
										aux_Today.getFullYear()
									:
										(  aux_Today.getFullYear() - 1  );
					*/

					//STR_meses

					thisYEAR =  our_YEAR;	//our_monday_date.getFullYear();


					NUM_MES_Selected =   our_MONTH_ANT;		//;our_monday_date.getMonth()  +  1; 		//  Desde 1 hasta 12 ("getMonth()" devuelve de 0-11)

					//window.alert(  NUM_MES_Selected  );




					NUM_DAYS_MONTH =  	DaysMonth 	(
														NUM_MES_Selected,

														thisYEAR
													);									// Desde 28 hasta 31



					DAYS_MONTH__last_week =  NUM_DAYS_MONTH  -  ( 7 * 4 ); 		//  ... -  28		 // la 5ta semana

					// TAMBIEN SI "DAYS_MONTH__last_week"= 0, ENTONCES SON 4 SEMANAS Y NO 5
					NUM_SEMANAS_mes =  Math.ceil(  NUM_DAYS_MONTH  /  7.0  );

					//window.alert(  "NUM_SEMANAS_mes: "  +  NUM_SEMANAS_mes  );




					//-----


					STR_DiAS_MES = [];


					//------------------------------------------------------------------------------


					for (  let id = 0;  id <  NUM_DAYS_MONTH;  id++  )
					{
						var dday =  id + 1;

						STR_DiAS_MES[ id ] =   String(  ( dday  <  10  )	?	( "0"  +  dday )  :	 dday  )  	/*+  ""  +  ( STR_meses[  our_monday_date.getMonth() ] ).slice( 0, 3 )*/;


						//////console.log(  STR_DiAS_MES[ id ]  );

					}// for (  let id = 0;  id <  NUM_DAYS_MONTH;  id++  )













					NUM__FIRST_day_MONTH = 	1;
					NUM__LAST_day_MONTH = 	NUM_DAYS_MONTH;


					var str_new_aux =  (   NUM_MES_Selected  <  10  )	?	( "0"  +  NUM_MES_Selected )	:		NUM_MES_Selected;


					STR__FIRST_day_MONTH =  thisYEAR  +  "-"  +  str_new_aux  +  "-"  +  "01"				+	"T00:00:00";     //"T00:01:00";		//our_monday_date.getDay();
					STR__LAST_day_MONTH  =  thisYEAR  +  "-"  +  str_new_aux  +  "-"  +  NUM_DAYS_MONTH		+	"T23:59:59";     //"T23:59:00";




					//------------------------
					//------------------------


					//epoch: 1654034400.000
					//timestamp: "2022-06-01T00:00:00.000Z"


					//epoch: 1656626400.000  // 1656626401
					//timestamp: "2022-07-01T00:00:00.000Z"


					//STR__FIRST_day_MONTH =  thisYEAR  +  "-"  +  str_new_aux  +  "-"  +  "01"				+	"T00:00:00";    	 //"T00:01:00";		//our_monday_date.getDay();
					//STR__LAST_day_MONTH  =  thisYEAR  +  "-"  +  str_new_aux  +  "-"  +  NUM_DAYS_MONTH		+	"T23:59:59";     //"T23:59:00";



					TS__STR__FIRST_day_MONTH =  (    (  new Date( STR__FIRST_day_MONTH )  ).getTime()  /  1000    );
					TS__STR__LAST_day_MONTH  =  (    (  new Date( STR__LAST_day_MONTH  )  ).getTime()  /  1000    );


					////console.log( STR__FIRST_day_MONTH  );
					////console.log( TS__STR__FIRST_day_MONTH  );

					/*
					let new_STR_ts =  new Date(  TS__STR__FIRST_day_MONTH  *  1000  );

					new_STR_ts =	new_STR_ts.getFullYear()					+
									"-"  										+

									(
										(  ( new_STR_ts.getMonth() + 1 )  <  10  )
										?
											"0"  +  ( new_STR_ts.getMonth() + 1 )
										:
											( new_STR_ts.getMonth() + 1 )
									)											+

									"-"											+

									(
										(  new_STR_ts.getDate()  <  10  )
										?
											"0"  +  new_STR_ts.getDate()
										:
											new_STR_ts.getDate()
									)
																				+

									"T"  										+

									(
										(  new_STR_ts.getHours()  <  10  )
										?
											"0"  +  new_STR_ts.getHours()
										:
											new_STR_ts.getHours()
									)
																				+
									":"  										+

									(
										(  new_STR_ts.getMinutes()  <  10  )
										?
											"0"  +  new_STR_ts.getMinutes()
										:
											new_STR_ts.getMinutes()
									)
																				+
									":"  										+

									(
										(  new_STR_ts.getSeconds()  <  10  )
										?
											"0"  +  new_STR_ts.getSeconds()
										:
											new_STR_ts.getSeconds()
									)
																				+
									"Z";

					console.log(  new_STR_ts  );
					*/


					////console.log( STR__LAST_day_MONTH  );
					////console.log( TS__STR__LAST_day_MONTH  );




					const huecos_DIA_10min =  24 * 6;								// 144

					const huecos_MES =  huecos_DIA_10min  *  NUM_DAYS_MONTH;		// (ejemplo:)  144 *  30  =  4320

																					// (ejemplo:)  144 *  31  =  4464



					ar_template_huecos_SL_MES =  new Array();


					// cada MEDIA HORA =  24 horas * 2

					//------------------------
					//------------------------

																					// CON EL -1 Y/O CON EL +1, FALLA EN LOS MESES DE 31 DÍAS   ... DEJARLO EN 0 !!!!

					TIMESTAMP__FIRST_day_MONTH = 	TS__STR__FIRST_day_MONTH     -    /*1*/ 0;	//Math.floor(   parseFloat(   ( new Date( STR__FIRST_day_MONTH ) ).getTime()    /   1000   )     )   -   (  ( 10 * 60 )  -  1   );								// FINALMENTE NO SE AÑADE PORQUE CAMBIA LA FRECUENCIA DE RECEPCIÓN DE DATOS  (YA NO SE HACE: RESTAMOS 1 HORA PARA QUE COJA MEJOR LOS DATOS)
					TIMESTAMP__LAST_day_MONTH  = 	TS__STR__LAST_day_MONTH      +    /*1*/ 0;		//Math.round(   parseFloat(   ( new Date(  STR__LAST_day_MONTH ) ).getTime()    /   1000   )     )   +   (  ( 10 * 60 )  -  0   )  /*+  (  24 * 60 * 60  )*/; 	//  +  0;	// FINALMENTE NO SE AÑADE PORQUE CAMBIA LA FRECUENCIA DE RECEPCIÓN DE DATOS (YA NO  SE HACE: SUMAMOS 1 HORA  PARA QUE COJA MEJOR LOS DATOS)















					//window.alert(  TIMESTAMP__FIRST_day_MONTH  );
					//window.alert(  TIMESTAMP__LAST_day_MONTH  );



					// str_mondayStart_date

					//////console.log(  "Nuestra fecha:  ", our_monday_date  );		// DaysMonth (  month_num, year  )
					//////console.log(  "Núméro de Mes: ", NUM_MES_Selected  );		// DaysMonth (  month_num, year  )
					//////console.log(  "Núméro de Año: ", our_monday_date.getFullYear() );		// DaysMonth (  month_num, year  )

					//////console.log(  "Días del mes elegido: ",
					//////										NUM_DAYS_MONTH
					/////);										// DaysMonth (  month_num, year  )

					//////console.log( "Días de la última semana (5ta, digamos): " +  DAYS_MONTH__last_week );		// "SEMANA" INDEPENDIENTEMENTE DEL DÍA DE LA SEMANA QUE COMIENCE LA SEMANA 1, VAN EN PERIODOS DE 7 DÍAS.... PUEDEN EMPEZAR EN JUEVES POR EJEMPLO, EN CUALQUIERA


					//////console.log(  "Fechas comienzo y final del mes (STR):  "  +  STR__FIRST_day_MONTH   +  ",  "  +  STR__LAST_day_MONTH  );

					//////console.log(  "Fechas comienzo y final del mes (TIMESTAMP):  "  +  TIMESTAMP__FIRST_day_MONTH   +  ",  "  +  TIMESTAMP__LAST_day_MONTH  );

					//----


					//informes.js:2936 Fechas comienzo y final del mes (TIMESTAMP):  164 099 1600,  164 358 3600






					group_id = this.props.location.search.split(  "?"  )[ 1 ];			//.split( "&monday=" )[ 0 ];

					this.state.selected_installationgroup.id =  group_id;

					/*
					let aux_setstate_OBJ =  {
												id:  group_id
											};

					this.setState(
									{
										selected_installationgroup:  aux_setstate_OBJ
									}
					);//
					*/
					//window.alert(  group_id  );




					//------------


					let CONFIG_getPreferredInstallationGroup =  CONFIG[ "getPreferredInstallationGroup" ]();


					//window.alert(  CONFIG_getPreferredInstallationGroup  );
					//window.alert(  this.state.selected_installationgroup.id  );
					//

					const str_aux_all =  "?all=";

					let aux_INDX_all =  parseInt(   String(  CONFIG_getPreferredInstallationGroup  ).indexOf(  str_aux_all  )   );		// 4c1952b1-61c3-407b-888b-ff5cf1fe8da1 ?all= true

					//window.alert(  aux_INDX_all  );



					CONFIG_getPreferredInstallationGroup =	(  aux_INDX_all  !=  -1  )
															?
																String(  CONFIG_getPreferredInstallationGroup  ).split( str_aux_all )[ 0 ]
															:
																CONFIG_getPreferredInstallationGroup;



					CONFIG[ "setPreferredInstallationGroup" ](   CONFIG_getPreferredInstallationGroup    );



					/*
					group_id =		//String(  CONFIG_getPreferredInstallationGroup  );


									(   (  this.state.selected_installationgroup.id  !=  ""  )   &&   (  this.state.selected_installationgroup.id  !=  "All"  )   &&   (  this.state.selected_installationgroup.id  !=  "all"  )   )
									?
										this.state.selected_installationgroup.id
									:
										//String(  CONFIG_getPreferredInstallationGroup  );

										(   (  CONFIG_getPreferredInstallationGroup  !=  "All"  )   &&   (  CONFIG_getPreferredInstallationGroup  !=  "all"  )   )
										?
											String(  CONFIG_getPreferredInstallationGroup  )		//this.props.location.search.split(  "installationgroup_id="  )[ 1 ].split( "&drate=" )[ 0 ];	//.split( "&monday=" )[ 0 ];
										:
											""  &&  window.alert(   "Seleccione una de sus Instalaciones por favor"   );
					*/





					//------------


					////window.alert( "0- ZONA SUMMARY SCHEDULE:  "  +  group_id  );




					axios.get(				//https://api.ilumek.com/api/v1/installationgroups/4c1952b1-61c3-407b-888b-ff5cf1fe8da1/summarydayscheduleprofiles
					//
						CONFIG.api_url  +  "/installationgroups/"  +  group_id  +  "/summarydayscheduleprofiles",

						{
							"headers":
										{
											'Content-Type': 'application/json',
											'Authorization': "Token "		 + 		this.props.auth.token
										}
						}
					//
					).then(
					//
						( result ) =>
						{

							//////console.log( "SCHEDULE PROFILESSSSSSSSSSSSSS" );

							//////console.log(  result.data  );


							ID_scheduleprofile_month =  result.data[  String( NUM_MES_Selected )  ].id_schedule;


							let int_SIG_MES =	(   NUM_MES_Selected   <=   11   )
												?
													( NUM_MES_Selected + 1 )
												:
													1;

							ID_scheduleprofile_month_SIG =  result.data[  String( int_SIG_MES )  ].id_schedule;


							//window.alert(  ID_scheduleprofile_month  );
							//window.alert(  ID_scheduleprofile_month_SIG  );


						}// (result) => {}
					//
					).catch(
					//
						error =>
						{
							this.setState(
											{
												error,
												waiting: false
											}
							)//.this.setState()

							////window.alert( "Error en captura API summary schcedule" );
						}
					//
					).finally(
					//
						( ) =>
						{
							////window.alert( "TERMINA SUMMARY SCHEDULE" );


							//------------


							// ---------------------------------------------

							// http://localhost:3000/informes/ff7c293a-d72b-4162-b07f-8c0d6df8439e/detalle/?installationgroup_id=b2129edb-6946-4adc-8ad2-d475be8a4a9f

							/*
							console.log( "GROUP_ID: " + group_id );


							console.log( "this.props.location =  "  +  JSON.stringify( this.props.location )  );

							console.log( "this.props.location.search =  "  +  this.props.location.search  );


							console.log( "this.props.match =  "  +  JSON.stringify( this.props.match ) );

							console.log( "this.props.match.params =  "  +  JSON.stringify( this.props.match.params ) );


							console.log( "this.props.match.params.id =  "  +  this.props.match.params.id  );
							*/
							// ---------------------------------------------


							if (  true
								//(  INI_componentDidMount__COUNT  >=  1  )  &&		// PARECE QUE ASÍ SÍ QUE FUNCIONA ( CON == 0 NO FUNCIONABA) Y SÓLAMENTE SE EJECUTARÁ 1 ÚNICA VEZ
								//(  INI_componentDidMount__COUNT  <=  3  )
							)
							{

								////window.alert(  "0.B. ZONA API INSTALLATION GROUP DETAIL"  );

								this.getInstallationGroupDetail( group_id );		// --------------------------------->  ggetInstallationGroupDetail( )

								_data_Retrieved = true;   // realmente ya no se usa

							}// if



							// ---------------------------------------------


							/*
							if (  this.props.match.params.id !== '-1'  )
							{
								console.log( "Reading" );
							}*/


							/*
							else
							{
								this.state.wait_in = "minutes";
								this.state.time_to_wait = 0;
								this.state.profileName = "Schedule";

								if (  this.upperSlider  )
								{
									this.upperSlider.setState( { value: 0 } );
								}


								this.setState(
												{
													profileName:"Schedule",
													showSpinner: false
												}
								);//
							}//if
							*/
							/*
							if (  this.state.wait_in == "seconds"  )
							{
								setTimeout(
											( ) =>
											{
												$( "#option2" ).click();
											}

											, 500
								);
							}
							else
							{
								setTimeout(
											( ) =>
											{
												$( "#option1" ).click();
											}

											, 500
								);
							}//
							*/


							//_node = document.getElementById( 'dropdowncontainer' ); // 'id_div_graphSOURCE_01'  // 'id_report01'  // 'dropdowncontainer'



							//window.onload = function ( ) { console.log( "RENDERIZADO según window.onload" ); };




							//const this_ =  this;		// (2do puesto

							_html_graph =
											( ) =>
											{
												return	(
															true
														)
											};//


							/*
							ReactDOM.render(
												<AppViewer />,

												document.getElementById( 'id_div_PDF' )
							);*/







							//https://api.timezonedb.com/v2.1/get-time-zone
																			// ?key=ZLTVM9412DXN


																			// &format=json

																			// &by=position
																			// &lat=43.0989200
																			// &lng=-2.3176000

																			// &time=1643356658


							//https://api.sunrise-sunset.org/json
																	// ?lat=43.0989200
																	// &lng=-2.3176000

																	// &date=2022-01-28


							/*
							if (  objINSTALLATION_GROUP._DATA_LOADED == "true"  )
							{
								window.alert( "COORDS:  "  +  objINSTALLATION_GROUP._installation_GROUP__LAT  +  ",  "  +  objINSTALLATION_GROUP._installation_GROUP__LON  );

								//id, all,
									//"43,0989200", "-2,3176000" );		// "43,0989200", "-2,3176000", "2022-01-26" );
							}// if
							*/



							//window.alert( "timeZONE:  "  +  offsHOUR_timeZONE  );



							INI_componentDidMount__COUNT +=  1;


							//-------------


							if (  this.props.auth.user.user_profile.userlevel == "EKIONA"  )
							{
								//window.alert(  "userlever ==  EKIONA   -->   Para sacar el Informe con los datos apropiados a las Installations correspondientes mejor hacer Log In con user concreto de las Installations"  );
							}//

						}

					);// .finally(



				}// if (  true /*PULSADO_BOTON  ==  false*/  )






			} // if (   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
			else
			{
				window.location.replace(   'https://apps.ilumek.com/'  );

			}// else -----


			//-----

			this.state.NUM_COUNT_MOUNT++;


		}// 	if (  NUM_COUNT_MOUNT  ==  0  )
		else
		{
			this.state.NUM_COUNT_MOUNT = 0;


			this.componentWillUnmount();


			//window.location.reload();




			//NUM_COUNT_MOUNT =  0;

			//window.location.replace(   'https://apps.ilumek.com/informes/'  );
			//window.location.replace(   'https://apps.ilumek.com/'  );



		}//else

	}//componentDidMount ( ) -------------------------------------



//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------




	showSpinner ( )
	{

		if (  !this.state.showSpinner  )
		{
			return ( <div/> )
		}

		return(
				<div style={{position: "fixed", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "9999999"}}>

					<div style={{position: "absolute", left: "48%", top: "45%"}}>
						<Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>

				</div>
		);


	}//showSpinner( )



//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



	render ( )
	{

		//var num_renders_post_svg = 0;






		//window.alert(  "this.state.global_PDF_Generado_y_Renderizado:  "  +  this.state.global_PDF_Generado_y_Renderizado  );


		//window.alert(  "Num_Renders_RENDER_FUNC:  "  +  _Num_Renders_RENDER_FUNC  );


		/*
		if (
				//(  this.state.LAST_re_render == "true"  )  &&


				(  _Num_Renders_RENDER_FUNC  === 0  )

				&&

				(  this.state.global_PDF_Generado_y_Renderizado  ==  "true"  )
		)

		{
			//global_PDF_Generado_y_Renderizado = false;

			window.alert(  "...... ENTRA POR EL SITIO DE NUEVO   !!!!!!!!!!!!!!!!!!!!!!!!!!!"  );

			//window.location.reload();
		}//
		*/






		_Num_Renders_RENDER_FUNC++;


		//console.log( "_Num_Renders_RENDER_FUNC: " + _Num_Renders_RENDER_FUNC );

		//var ac_graph01 = null;
		//var ac_graph01_SVG = null;







		/*
		if (  this.state.SCRIPT_svg_rendered == "true" )
		{
			window.alert(  "this.state.SCRIPT_svg_rendered == true"  );
		}*/

		/*
		if (  this.state.sunRise_sunSet_GET == "true"  )
		{
			////window.alert(  "this.state.sunRise_sunSet_GET == true"  );
		}//
		*/


		//console.log(  "_Num_Renders_RENDER_FUNC: "  );
		//console.log(  _Num_Renders_RENDER_FUNC  );


		//console.log( "RENDER COMIENZO (1):: _ar_ALL__STREETLIGHTS_VARVALs: "  );
		//console.log( _ar_ALL__STREETLIGHTS_VARVALs  );




		//--------


		if (
				(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )  &&

				//(  PULSADO_BOTON  ==  true  )  &&


				(   this.state.sunRise_sunSet_GET  ==  "true"   )   &&



				(  this.state.LAST_re_render  !=  "true"  )  &&


				( _cont_rerender_svg == 0 )  &&


				(  _ar_ALL__STREETLIGHTS_VARVALs  !=  null  ) &&

				(  typeof( _ar_ALL__STREETLIGHTS_VARVALs ) != "undefined"  )
		)
		{


			////window.alert(  "-> ENTRA EN 1ER IF !!"  );


			if (
					(   _ar_ALL__STREETLIGHTS_VARVALs.length  >  0  )  //&&

			)
			{

				////window.alert(  "ENTRA EN ZONA RENDER-PROCESAR"  );


				//window.alert(  _cont_rerender_svg  );

				////window.alert(  "ENTRA EN PRIMERA ZONA DE RENDER" );



				//////console.log( "__ar_ALL__STREETLIGHTS_VARVALs.length_:" +  _ar_ALL__STREETLIGHTS_VARVALs.length  );




				//console.log( "RENDER COMIENZO (2):: _ar_ALL__STREETLIGHTS_VARVALs: "  );
				//console.log( _ar_ALL__STREETLIGHTS_VARVALs  );




				if (
						(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  ) &&

						(  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != "undefined"  )
				)
				{


					//console.log( "RENDER  :: _ar_ALL__STREETLIGHTS_VARVALs__FINAL: "  );
					//console.log( _ar_ALL__STREETLIGHTS_VARVALs__FINAL  );



					////window.alert(  "if ((  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  ) &&	(  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != undefined  )"  );




					if (   _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  ==  0  )
					{


						////window.alert(  "ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  ==  0"  );



						//////console.log(  "_obj_Inst_Group.info.streetlights.length:  "  +  _obj_Inst_Group.info.streetlights.length  );
						//console.log( "_obj_Inst_Group: \n" + JSON.stringify( _obj_Inst_Group ) );


						const my_SL_Array_length =  _ar_ALL__STREETLIGHTS_VARVALs.length;

						//----

						if (   my_SL_Array_length  ===  _obj_Inst_Group.info.streetlights.length   )
						{
							// AQUI ES DONDE REALMENTE TENEMOS CAPTURADOS LOS DATOS:




							//console.log( "RENDER COMIENZO (3):: _ar_ALL__STREETLIGHTS_VARVALs: "  );
							//console.log( _ar_ALL__STREETLIGHTS_VARVALs  );

							//-----------------



							/*
							let cont_repes =  0;

							for(  let iar =  0;  iar  <  ( _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data.length );  iar++   )		/// ( _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data.length  -  1 )
							{

								//if (   _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar  ].epoch  ==  _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar + 1  ].epoch   )			// BASTANTES REPETIDOS
								if (   _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar  ].timestamp  ==  _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar + 1  ].timestamp   )	// SIN REPETICIÓN
								{

									console.log(  _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar  ]   );
									console.log(  _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar  +  1  ]   );
									console.log(  "---------------------"  );

									cont_repes++;

								}// if (   _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar  ].epoch  ==  _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data[  iar + 1  ].epoch   )


							}// for(  let iar =  0;  iar  <  ( _ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data.length  -  1 );  iar++   )

							console.log(  "REPETIDOS!:  "  +  cont_repes  );
							*/




							//-----------------------------------------------------------------------------------




							for ( let our_iSL = 0;	our_iSL  < _ar_ALL__STREETLIGHTS_VARVALs.length;  our_iSL++   )
							{



								//console.log(  _ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ]  );


								/*
								let TEMPLATE_OBJ_SL_MES =	{

									"schema":	{},

									"data":		[]

								};*/


								var aux_our_OBJ =	//Object.create(

														{

															"schema":	{},

															"data":		new Array()

														};

													//);//


								aux_our_OBJ.schema =  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].schema;





								//aux_our_OBJ.schema =  _ob_AUX_InstGroup.schema;

								//--




								const aux_name_SL =  String(  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].schema.fields[ 2 ].name.split( '_B' )[ 0 ]  );


								const aux_name_SL_var_B =  String(  /*"'"  +*/  aux_name_SL  +  "_B"  );
								const aux_name_SL_var_P =  String(  /*"'"  +*/  aux_name_SL  +  "_P"  );
								const aux_name_SL_var_C =  String(  /*"'"  +*/  aux_name_SL  +  "_C"  );



								//console.log( aux_name_SL_var_B );


								const huecos_DIA_10min =	24 * 6;										// 144

								const huecos_MES =			huecos_DIA_10min  *  NUM_DAYS_MONTH /*(10)*/;			//;	NUM_DAYS_MONTH;		// (ejemplo:)  144 *  30  =  4320


								//------------------------------------------------------

								//let auxcont =  0;



								//let ar_AUX_tiempos = [];


								//----------------------------------------------------------------------------------------------------------------------------------------------------


								let icont = 0;


								for (  let hs = 0;  hs  <=  huecos_MES;  hs++ )			//  <= huecos_MES   ...   =   huecos_MES + 1
								{


									const new_TS_from_i_Huecos =	parseInt(
																					1000   *   (    (   parseInt( TS__STR__FIRST_day_MONTH )   +   (  hs  *  10  *  60  )   )    )

																	); // parseInt(


									//---

									let new_STR_ts_from_i_Huecos =  new Date(  new_TS_from_i_Huecos  );	 //String(  new Date(  new_TS  )  );


									//--

									//console.log(  new_STR_ts_from_i_Huecos  );



									new_STR_ts_from_i_Huecos =		new_STR_ts_from_i_Huecos.getFullYear()		+

																	"-"  										+

																	(
																		(  ( new_STR_ts_from_i_Huecos.getMonth() + 1 )  <  10  )
																		?
																			"0"  +  ( new_STR_ts_from_i_Huecos.getMonth() + 1 )
																		:
																			( new_STR_ts_from_i_Huecos.getMonth() + 1 )
																	)											+

																	"-"											+

																	(
																		(  new_STR_ts_from_i_Huecos.getDate()  <  10  )
																		?
																			"0"  +  new_STR_ts_from_i_Huecos.getDate()
																		:
																			new_STR_ts_from_i_Huecos.getDate()
																	)
																												+

																	"T"  										+

																	(
																		(  new_STR_ts_from_i_Huecos.getHours()  <  10  )
																		?
																			"0"  +  new_STR_ts_from_i_Huecos.getHours()
																		:
																			new_STR_ts_from_i_Huecos.getHours()
																	)
																												+
																	":"  										+

																	(
																		(  new_STR_ts_from_i_Huecos.getMinutes()  <  10  )
																		?
																			"0"  +  new_STR_ts_from_i_Huecos.getMinutes()
																		:
																			new_STR_ts_from_i_Huecos.getMinutes()
																	)
																												+
																	":"  										+

																	(
																		(  new_STR_ts_from_i_Huecos.getSeconds()  <  10  )
																		?
																			"0"  +  new_STR_ts_from_i_Huecos.getSeconds()
																		:
																			new_STR_ts_from_i_Huecos.getSeconds()
																	)
																												+

																	"Z";  // OJOOOOOO




									// AUX_STR_timeZ_from_AR_SL:		2023-03-26 T 02:00:00
																							// 2023-03-31T22:50:00Z
									// 2023-03-31 T 23:30



									// SERGIO PRUEBA
									/*
									const AUX_new_STR_ts_from_i_Huecos =  String(  new_STR_ts_from_i_Huecos  ).split(  ":00Z"  )[ 0 ].split( "-" );

									//console.log(  String(  new_STR_ts_from_i_Huecos  )  );

									console.log(  AUX_new_STR_ts_from_i_Huecos  );		// [ "2023", "03", "31T23:40" ]
									*/



									//-------------------------




									/*
									//icont =  auxcont;

									while	(
												(  icont  <   _ob_AUX_InstGroup.data.length  )   &&


												(  new_TS   >=   (  _ob_AUX_InstGroup.data[  icont  ].epoch  )  )		//   <=  !!!
											)
									{



										icont++;

									}// while -------------------------
									*/


									//----

									//auxcont =  icont;


									//---------
									//Object { epoch: 1654056600000, timestamp: "2022-06-01T06:10:00.000Z" }


									//-----------------------------------------------------------------------


									let aux_DATA_obj = {};

									aux_DATA_obj = new Array();



									//----


									if (   icont  <   (_ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data.length)   )		  //(  icont  >=  0   )
									{


										//const new_index_cont =  icont;	//  -  1;


										//const EPOCH_from_AR_SL =  parseInt(   _ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].epoch   );


										//console.log(  "EPOCH_from_AR_SL: "  +  EPOCH_from_AR_SL  );



										// timestamp:	"2023-03-01T00:10:00.000Z"		//  .000Z

										//				"2023-03-28T19:40:00"


										const STR_timeZ_from_AR_SL =  _ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];			//split( "Z" )[ 0 ];


										let convert_TS_from_String_from_AR_SL =   parseInt(
																									(   new Date(  STR_timeZ_from_AR_SL  )   ).getTime()

																					); // parseInt(
										//----



										//-------------------------------------------------------------------------------------------------------


																// POR  INSTALLATION GROUP:


																	// 1) CONSTANTE / API QUE DETERMINA SI SE HARÁ O NO EN CUALQUIER CASO...

																	// 2) DESCRIBIR (API) LOS 2 POSIBLES MESES, DIA, HORA DE CAMBIOS DE HORA




										let MES_TIENE_CAMBIO_HORA_MENOS__PRIMAVERA =  false;		//true;		//false;		// !!!!			//true;
										let MES_TIENE_CAMBIO_HORA_MAS__OTONO =  false;


										const STR_MES_CAMBIO_HORA =  "03";			// METERLO DESDE API ????

										const STR_DIA_CAMBIO_HORA =  "26";

										const STR_HORA_CAMBIO_HORA =  "03";



										//console.log(  "NUM_MES_Selected: "  +  NUM_MES_Selected  );


										//---




										// CALCULO DEL TS/HS A PARTIR DEL CUAL SE APLICA


										const INT_MES_CAMBIO_HORA =  parseInt(  STR_MES_CAMBIO_HORA  );


										const INT_DIA_CAMBIO_HORA =  parseInt(  STR_DIA_CAMBIO_HORA  );

										const INT_HORA_CAMBIO_HORA =  parseInt(  STR_HORA_CAMBIO_HORA  );





										//----

										const hs_HORA =  6;					// cada 10 min; exiten 10 tramos de ellos en 1 hora
										const hs_DIA =  hs_HORA * 24;




										const HS_CAMBIO_HORA =  (  ( INT_DIA_CAMBIO_HORA  -  1 ) *  hs_DIA  )  +  (  INT_HORA_CAMBIO_HORA  *  hs_HORA  );		// CORRECTO (COMPROBADO), RESULTA:   3618;


										//console.log(  "HS_CAMBIO_HORA: "  +  HS_CAMBIO_HORA  );





										//  !!!!!

										if (
												(  MES_TIENE_CAMBIO_HORA_MENOS__PRIMAVERA  ==  true  )
											||
												(  MES_TIENE_CAMBIO_HORA_MAS__OTONO  ==  true  )
										)
										{

											//-------------------------------------------------------------------------------------------------------



											const STR_HORA_CAMBIO_HORA_plus_1 =  String(  "0"  +   INT_HORA_CAMBIO_HORA /*parseInt( STR_HORA_CAMBIO_HORA )*/  );








											//-------------------------------------------------------------------------------------------------------


											if (
													(  hs  >=  HS_CAMBIO_HORA  )
											)
											{

												const UNA_HORA_MILIS =  600000 * 6;		//  600000 = 10 min = 60 sec * 10 min * 1000 milis


												let SUMA_ts_1_HORA =  convert_TS_from_String_from_AR_SL;		//-1;


												//---

												if (
														(  MES_TIENE_CAMBIO_HORA_MENOS__PRIMAVERA  ==  true  )
													&&
														(  MES_TIENE_CAMBIO_HORA_MAS__OTONO  ==  false  )
												)
												{

													SUMA_ts_1_HORA =  convert_TS_from_String_from_AR_SL  +  UNA_HORA_MILIS;

												} // if ( ... )
												else
												{

													// EN PRINCIPIO NO HARÍA FALTA:

													/*
													if (
															(  MES_TIENE_CAMBIO_HORA_MAS__OTONO  ==  true  )
														&&
															(  MES_TIENE_CAMBIO_HORA_MENOS__PRIMAVERA  ==  false  )
													)
													{

														SUMA_ts_1_HORA =  convert_TS_from_String_from_AR_SL  -  UNA_HORA_MILIS;		//  "-"   MENOS !!!

													} // if ( ... )
													*/

												} // else ......




												//----------------------------------------------------------------------------------------------------



												// "2023-03-26 T 02:00:00"
												const AR_AUX_STR_timeZ_from_AR_SL =  String(  STR_timeZ_from_AR_SL  ).split( "-" );				//String(  STR_timeZ_from_AR_SL  ).split(  ":00Z"  )[ 0 ].split( "-" );

												let aux_mes =  AR_AUX_STR_timeZ_from_AR_SL[ 1 ];
												let aux_dia =  AR_AUX_STR_timeZ_from_AR_SL[ 2 ].split( "T" )[ 0 ];
												let aux_hor =  AR_AUX_STR_timeZ_from_AR_SL[ 2 ].split( "T" )[ 1 ].split( ":" )[ 0 ];

												//--

												if (
														(  aux_mes  ==  STR_MES_CAMBIO_HORA  )
													&&
														(  aux_dia  ==  STR_DIA_CAMBIO_HORA  )
													&&
														(  (  aux_hor  ==  STR_HORA_CAMBIO_HORA  )  ||  (  aux_hor  ==  STR_HORA_CAMBIO_HORA_plus_1  )  )
												)
												{

													//---

													let aux_POST_conversion_NEW_TS =   String(  new Date(  convert_TS_from_String_from_AR_SL  )  );	 		//String(  new Date(  new_TS  )  );

													//---


													/*
													console.log( "-------------------------------------------------------------------" );

													console.log( "hs:  " +  hs );
													console.log( "new_TS_from_i_Huecos:  " +  new_TS_from_i_Huecos );
													console.log( "new_STR_ts_from_i_Huecos:  " +  new_STR_ts_from_i_Huecos );

													console.log( ".." );
													console.log( ".." );

													//---

													console.log(  "(ant) STR_timeZ_from_AR_SL:    "  +  STR_timeZ_from_AR_SL  +  " ... (desde Array STREETLIGHTS)" );

													console.log(  "(ant) convert_TS_from_String_from_AR_SL:    "  +  convert_TS_from_String_from_AR_SL  );
													console.log(  "(ant info) aux_POST_conversion_NEW_TS:    "  +  aux_POST_conversion_NEW_TS  );

													console.log( ".." );
													*/
													//---


													const aux_partes   =  _ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ].split( "-" );

													const aux_partes_2 =  aux_partes[ 2 ].split( ":" );

													//


													let aux_STR_timeZ_from_AR_SL =	aux_partes[ 0 ]  +  "-"  +  aux_mes  +  "-"  +  aux_dia  +  "T"  +  "0"  +  String(   parseInt( aux_hor )  -  1   )  +  ":"  +
																					aux_partes_2[ 1 ]  +  ":"  +  aux_partes_2[ 2 ];

													// "2023-03-26T02:00:00"



													//let NEW_convert_TS_from_String_from_AR_SL =  convert_TS_from_String_from_AR_SL;
													//let NEW_convert_TS_from_String_from_AR_SL =			parseInt(
													//																(   new Date(  aux_STR_timeZ_from_AR_SL  )   ).getTime()
													//													)
													//												+
													//													UNA_HORA_MILIS; // parseInt(


													//let NEW_aux_POST_conversion_NEW_TS =   String(  new Date(  NEW_convert_TS_from_String_from_AR_SL  )  );	 		//String(  new Date(  new_TS  )  );



													//---

													/*
													console.log(  "(NEW) aux_STR_timeZ_from_AR_SL:    "  +  aux_STR_timeZ_from_AR_SL  );


													//console.log(  "(change) NEW_convert_TS_from_String_from_AR_SL:    "  +  NEW_convert_TS_from_String_from_AR_SL  );

													//console.log(  "(NEW info) NEW_aux_POST_conversion_NEW_TS:    "  +  NEW_aux_POST_conversion_NEW_TS  );

													//--

													console.log( ".." );
													console.log(  "(SUMA 1 HORA ts) :    "  +  ( SUMA_ts_1_HORA )  );

													console.log( "-------------------------------------------------------------------" );
													*/

													// "2023-03-26T02:00:00"

												}// if ( ... .... .... )



												//----------------------------------------------------------------------------------------------------


												convert_TS_from_String_from_AR_SL =  SUMA_ts_1_HORA;			//NEW_convert_TS_from_String_from_AR_SL;


												//----------------------------------------------------------------------------------------------------


											} // if ( (  hs  >=  HS_CAMBIO_HORA  ) )

										} // if ( ... ... )




										//----------------------------------------------------------------------------------------------------

										/*
										console.log(   AR_AUX_STR_timeZ_from_AR_SL   );		// [ "2023", "03", "26T03:00:00" ]						// [ "2023", "03", "31T23:40" ]

										console.log(  aux_mes  +  "  "  +  aux_dia  +  "  "  +  aux_hor   );

										console.log( "----------------------------" );
										*/

										//---

										/*
										console.log(  "new_TS_from_i_Huecos: "  +  new_TS_from_i_Huecos  );
										console.log(  "new_STR_ts_from_i_Huecos: "  +  new_STR_ts_from_i_Huecos  );


										console.log(  "EPOCH_from_AR_SL: "  +  EPOCH_from_AR_SL  );

										console.log(  "STR_timeZ_from_AR_SL: "  +  STR_timeZ_from_AR_SL  );
										console.log(  "convert_TS_from_String_from_AR_SL: "  +  convert_TS_from_String_from_AR_SL  );


										console.log(  ",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,"  );


										//const STR_timeZ
										*/


										//---


										// ¡¡¡  AUX PARA VER DATOS EN LA CONSOLA !!!! Y QUE NOS AYUDE EN LOCALIZACIÓN DE ERRORES

										/*
										ar_AUX_tiempos.push(
										//
																{
																	"new_TS_from_i_Huecos":					new_TS_from_i_Huecos,

																	"new_STR_ts_from_i_Huecos":				new_STR_ts_from_i_Huecos,



																	"our_HS":								hs,

																	"our_ICONT":							icont,


																	"STR_timeZ_from_AR_SL":					STR_timeZ_from_AR_SL,

																	"convert_TS_from_String_from_AR_SL":	convert_TS_from_String_from_AR_SL


																	//"EPOCH_from_AR_SL":
																}
										//
										); //.push( ...
										*/


															//if (   new_STR_ts   ==  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  icont  ].timestamp   )			// TAMBIÉN VÁLIDA, Y TE AHORRAS CÁLCULO INMEDIATO DE ENCIMA A ÉSTE




										//--------------------------------------------------------------------------------------------------------------------------------


										if (   /*String*/( new_TS_from_i_Huecos )   ==   /*String*/( convert_TS_from_String_from_AR_SL )   )				// String(  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  /*new_index_cont*/ icont  ].epoch  )
										{

											/*
											AR
											{
												"timestamp":			"2023-03-28 T 18:40:00 .000Z",
												"epoch":				1 680 022 080 000,

												"480169F000M_B":		13.7,
												"480169F000M_P":		19.9,
												"480169F000M_C":		0
											}
											*/


											/*
											console.log(  "-----------------------"  );

											console.log( "**************COINCIDENCIA!!**************" );

											console.log(  "new_TS_from_i_Huecos: "  +  new_TS_from_i_Huecos  );
											console.log(  "new_STR_ts_from_i_Huecos: "  +  new_STR_ts_from_i_Huecos  );


											console.log(  "EPOCH_from_AR_SL: "  +  EPOCH_from_AR_SL  );
											console.log(  "STR_timeZ_from_AR_SL: "  +  STR_timeZ_from_AR_SL  );

											console.log(  "convert_TS_from_String_from_AR_SL: "  +  convert_TS_from_String_from_AR_SL  );

											console.log(  "-----------------------"  );
											*/

											//aux_DATA_obj =  _ob_AUX_InstGroup.data[  new_index_cont  ];

											aux_DATA_obj =	{
																[  aux_name_SL_var_B  ]:			parseFloat(  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  icont  ][  aux_name_SL_var_B  ]  ),

																[  aux_name_SL_var_P  ]:			parseFloat(  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  icont  ][  aux_name_SL_var_P  ]  ),

																[  aux_name_SL_var_C  ]:			parseFloat(  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  icont  ][  aux_name_SL_var_C  ]  ),


																"epoch":							convert_TS_from_String_from_AR_SL,		//parseInt(    _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  icont  ].epoch  ),

																"timestamp":						STR_timeZ_from_AR_SL			//_ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  icont  ].timestamp.split( "Z" )[ 0 ]
															};

											//---


											icont++;


											//---


											/*
											if (  aux_DATA_obj  ==  undefined  )
											{
												console.log( "aux_DATA_obj undefined:  "  +  hs );
											}//
											*/

										}
										else
										{


											/*
											console.log(  "-----------------------"  );

											console.log(  "new_TS_from_i_Huecos: "  +  new_TS_from_i_Huecos  );
											console.log(  "new_STR_ts_from_i_Huecos: "  +  new_STR_ts_from_i_Huecos  );

											console.log(  "---" );

											console.log(  "EPOCH_from_AR_SL: "  +  EPOCH_from_AR_SL  );
											console.log(  "STR_timeZ_from_AR_SL: "  +  STR_timeZ_from_AR_SL  );

											console.log(  "convert_TS_from_String_from_AR_SL: "  +  convert_TS_from_String_from_AR_SL  );

											console.log(  "-----------------------"  );
											*/


											/*
											console.log(
															String( new_TS )
											);
											*/

											//console.log(  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data[  /*new_index_cont*/ icont  ].timestamp  );






											//-----------------------------------


											// !!!!!!!!!!!!!!!!!!!!!!

											// CAMBIO (POSIBLE):

												// POR SI HUBIESE QUE HACER AJUSTE CON POSIBLES TRAMAS QUE ENVIÉN HORAS DE TRAMAS SON SECUENCIALMENTE INCREMENTABLES

												// (HAYA ALGUNA QUE DE PRONTO, RESPECTO A LAS DEMÁS, EN VEZ DE INCREMENTAR, EXISTA UN DECREMENTO PUNTUAL -UNO O VARIOS SEGUIDOS-)


											// !!!!!!!!!!!!!!!!!!!!!!


											/*
											if (   ( new_TS_from_i_Huecos )   >   ( convert_TS_from_String_from_AR_SL )   )
											{

												let AUX_convert_TS_from_String_from_AR_SL =  0;

												let AUX_STR_timeZ_from_AR_SL = "";

												let SALIR_WHILE =  false;

												//---

												do
												{

													icont++;


													//---

													if (   icont  <   (_ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data.length)   )
													{

														AUX_STR_timeZ_from_AR_SL =  _ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ].data[  icont  ].timestamp.split( ".000Z" )[ 0 ];


														AUX_convert_TS_from_String_from_AR_SL =   parseInt(
																												(   new Date(  AUX_STR_timeZ_from_AR_SL  )   ).getTime()

																									); // parseInt(
													}
													else
													{
														SALIR_WHILE =  true;
													}//

													//---

												} while (
															(  SALIR_WHILE	==  false )
															&&
															( new_TS_from_i_Huecos )   >   ( AUX_convert_TS_from_String_from_AR_SL )

												);// while( ... )

											} //
											else
											{

												//--

											} // else -------------------------------------------------------------------]
											*/


											//-----------------------------------




											//----


											aux_DATA_obj =	{
																[  aux_name_SL_var_B  ]:			parseFloat( 0.0 ),

																[  aux_name_SL_var_P  ]:			parseFloat( 0.0 ),

																[  aux_name_SL_var_C  ]:			parseFloat( 0.0 ),


																"epoch":							new_TS_from_i_Huecos,

																"timestamp":						new_STR_ts_from_i_Huecos.split( "Z" )[ 0 ]
															};


										}// else ---------------------------------------------------------------------------------------------------------]





									}// if (  icont  <  _ar_ALL__STREETLIGHTS_VARVALs[ our_iSL ].data.length   )		 //(  icont  >=  0   )
									else
									{

										aux_DATA_obj =	{
															[  aux_name_SL_var_B  ]:			parseFloat( 0.0 ),

															[  aux_name_SL_var_P  ]:			parseFloat( 0.0 ),

															[  aux_name_SL_var_C  ]:			parseFloat( 0.0 ),


															"epoch":							new_TS_from_i_Huecos,

															"timestamp":						new_STR_ts_from_i_Huecos.split( "Z" )[ 0 ]
														};

									}// else ----------------------------------------



									//------------------------------------------------------------------------------------------------------------



									if (  aux_DATA_obj  ==  undefined  )
									{

										console.log( "aux_DATA_obj undefined:  "  +  hs );

									}// if (  aux_DATA_obj  ==  undefined  )


									//---




									aux_our_OBJ.data.push(
															aux_DATA_obj
									); // .push(





									// BUSCAR DENTRO DE:  _ob_AUX_InstGroup.data

									//let new_STR_ts =  new Date(  TS__STR__FIRST_day_MONTH  *  1000  );



								}// for (  let hs = 0;  hs  <  huecos_MES;  hs++ ) --------------------------------------------------------------------------------------------------------------------------------------]


								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|


								//console.log(  aux_our_OBJ  );


								//-------

								ar_template_huecos_SL_MES.push(
																	aux_our_OBJ
								); // .push(


								//-------


								//----

								/*
								console.log(  "ar_AUX_tiempos: "  );
								console.log(  ar_AUX_tiempos  );
								console.log(  "-----------------------------------------"  );
								*/

								//----



							} //for ( let our_iSL = 0;	our_iSL  < _ar_ALL__STREETLIGHTS_VARVALs.length;  our_iSL++   ) -------------------------------------------------------------------------------------------------------------------]



							//---------------------------------------------------------------------------------------------


							////console.log(  ar_template_huecos_SL_MES  );


							//---------------------------------------------------------------------------------------------






							//----
							/*
							console.log(  "ar_AUX_tiempos: "  );
							console.log(  ar_AUX_tiempos  );
							console.log(  "-----------------------------------------"  );
							*/
							//---------------------------------------------------------------------------------------------


							////console.log(  ar_template_huecos_SL_MES  );


							//---------------------------------------------------------------------------------------------

							/*
							console.log(  "(2) _ar_ALL__STREETLIGHTS_VARVALs[0].data[4000]: "  );
							console.log(  _ar_ALL__STREETLIGHTS_VARVALs[0].data[4000]  );


							console.log(  "aux_our_OBJ.data[4000]: "  );
							console.log(  aux_our_OBJ.data[4000]  );

							console.log(  "---------------------------"  );
							*/












							for ( let our_iSL = 0;	our_iSL  < _ar_ALL__STREETLIGHTS_VARVALs.length;  our_iSL++   )
							{

								_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ] =  ar_template_huecos_SL_MES[  our_iSL  ];


								//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ] =  ar_template_huecos_SL_MES[  our_iSL  ]; ::  "  );
									//console.log(  ar_template_huecos_SL_MES[  our_iSL  ]  );
								//console.log(  _ar_ALL__STREETLIGHTS_VARVALs[  our_iSL  ]  );


							} // for ( let our_iSL = 0;	our_iSL  < _ar_ALL__STREETLIGHTS_VARVALs.length;  our_iSL++   )






							////console.log(  _ar_ALL__STREETLIGHTS_VARVALs  );

							/*
							console.log(  "(3) [_AQUÍ_] _ar_ALL__STREETLIGHTS_VARVALs[0].data[4000]: "  );
							console.log(  _ar_ALL__STREETLIGHTS_VARVALs[0].data[4000]  );
							*/







							//---------------------------------------------------------------------------------------------



							//------------------------------------------
							//------------------------------------------

							//console.log(  "YEAR: "  +  thisYEAR  );
							//console.log(  "MONTH: "  +  NUM_MES_Selected  );

							//var our_JSON_OBJ_sunrise =  null;


							const our_JSON_sunrise =  _obj_Inst_Group.sunrise_sunset_year;		//this.state.selected_installationgroup.sunrise_sunset_year;


							if (   our_JSON_sunrise  !==  null   )
							{

								if (   our_JSON_sunrise  !=  undefined   )
								{
									//if (   our_JSON  !=  ""   )
									//{

									our_JSON_OBJ_sunrise =  JSON.parse(  our_JSON_sunrise  );


									//console.log( our_JSON_OBJ_sunrise );

									//console.log(  our_JSON_OBJ_sunrise[   parseInt( NUM_MES_Selected ) - 1   ]  );

									//}

									//-----


									//ar_Horas_SunsetSunrise_dias_Mes

									our_mon =  parseInt(  NUM_MES_Selected  );		// - 1;


									//---


									let our_SunSet_day;

									let int_SunSet_Hour;
									let int_SunSet_Mins;

									let our_TS_Hour_Min_SunSet;


									//---


									let our_SunRise_day;

									let int_SunRise_Hour;
									let int_SunRise_Mins;

									let our_TS_Hour_Min_SunRise;


									//---


									let DELTA_TS_sunSet_sunRise_FHours;


									//-----------------


									suma_Horas_Reales_TEORICAS =  parseFloat(  0.0  );

									ar_Horas_SunsetSunrise_dias_Mes = null;
									ar_Horas_SunsetSunrise_dias_Mes = [];



									for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )
									{

										our_SunSet_day =  our_JSON_OBJ_sunrise[  our_mon - 1  ][  imd  ][ "sunset" ];

										int_SunSet_Hour =  parseInt(  our_SunSet_day.split(  ":"  )[ 0 ]  );
										int_SunSet_Mins =  parseInt(  our_SunSet_day.split(  ":"  )[ 1 ]  );


										our_TS_Hour_Min_SunSet =  (  int_SunSet_Hour  *  60  )  +  int_SunSet_Mins;


										//----


										our_SunRise_day =  our_JSON_OBJ_sunrise[  our_mon - 1  ][  imd  ][ "sunrise" ];


										int_SunRise_Hour =  parseInt(  our_SunRise_day.split(  ":"  )[ 0 ]  );
										int_SunRise_Mins =  parseInt(  our_SunRise_day.split(  ":"  )[ 1 ]  );


										our_TS_Hour_Min_SunRise =   (  24  *  60  )   +   (   int_SunRise_Hour  *   60   )   +   int_SunRise_Mins;


										//------------------


										DELTA_TS_sunSet_sunRise_FHours =  parseFloat(   (  our_TS_Hour_Min_SunRise  -  our_TS_Hour_Min_SunSet  )   /   60.0   );

										//console.log( DELTA_TS_sunSet_sunRise_FHours );

										//------------------

										ar_Horas_SunsetSunrise_dias_Mes.push(
																				{
																					sunset_TS:		our_TS_Hour_Min_SunSet,

																					sunrise_TS:		our_TS_Hour_Min_SunRise,

																					num_horas:		DELTA_TS_sunSet_sunRise_FHours
																				}
										); //

										suma_Horas_Reales_TEORICAS  +=  DELTA_TS_sunSet_sunRise_FHours;

									}//	for ( let imd = 0;  imd < NUM_DAYS_MONTH;  imd++ )

									//----

									suma_Horas_Reales_TEORICAS  *=  parseFloat(  this.state.selected_installationgroup.info.streetlights.length  );


									//window.alert(   parseFloat( suma_Horas_Reales_TEORICAS ) );
									//console.log(  ar_Horas_SunsetSunrise_dias_Mes  );



									//-------------------------------------------------------------------------------------------


									// CALC HORAS NOCHE TEÓRICAS SIGUIENTE MES  (_SIG_)

									/*
									var suma_Horas_Reales_TEORICAS =  0;

									var ar_Horas_SunsetSunrise_dias_Mes_SIG =  [];
									var suma_Horas_Reales_SIG_TEORICAS =  0;
									*/


									let our_SIG_mon =	(  our_mon  <=  11  )
														?
															parseInt( our_mon + 1 )
														:
															parseInt( 1 );


									//window.alert(  our_SIG_mon  );
									//console.log(  our_JSON_OBJ_sunrise[  our_SIG_mon - 1  ]  );



									NUM_DAYS_MONTH_SIG =  	DaysMonth (
																			our_SIG_mon,


																			(  our_mon  <=  11  )
																			?
																				thisYEAR
																			:
																				( thisYEAR + 1 )

															); //									// Desde 28 hasta 31



									suma_Horas_Reales_SIG_TEORICAS =  parseFloat(  0.0  );

									ar_Horas_SunsetSunrise_dias_Mes_SIG = null;
									ar_Horas_SunsetSunrise_dias_Mes_SIG = [];




									for (  let imd = 0;  imd < NUM_DAYS_MONTH_SIG;  imd++  )
									{

										our_SunSet_day =  our_JSON_OBJ_sunrise[  our_SIG_mon - 1  ][  imd  ][ "sunset" ];


										int_SunSet_Hour =  parseInt(  our_SunSet_day.split(  ":"  )[ 0 ]  );
										int_SunSet_Mins =  parseInt(  our_SunSet_day.split(  ":"  )[ 1 ]  );


										our_TS_Hour_Min_SunSet =  (  int_SunSet_Hour  *  60  )  +  int_SunSet_Mins;


										//----


										our_SunRise_day =  our_JSON_OBJ_sunrise[  our_SIG_mon - 1  ][  imd  ][ "sunrise" ];


										int_SunRise_Hour =  parseInt(  our_SunRise_day.split(  ":"  )[ 0 ]  );
										int_SunRise_Mins =  parseInt(  our_SunRise_day.split(  ":"  )[ 1 ]  );


										our_TS_Hour_Min_SunRise =   (  24  *  60  )   +   (   int_SunRise_Hour  *   60   )   +   int_SunRise_Mins;


										//------------------


										DELTA_TS_sunSet_sunRise_FHours =  parseFloat(   (  our_TS_Hour_Min_SunRise  -  our_TS_Hour_Min_SunSet  )   /   parseFloat( 60.0 )   );

										//window.alert(  DELTA_TS_sunSet_sunRise_FHours  );

										//console.log( DELTA_TS_sunSet_sunRise_FHours );


										//------------------


										ar_Horas_SunsetSunrise_dias_Mes_SIG.push(
																					{
																						sunset_TS:		our_TS_Hour_Min_SunSet,

																						sunrise_TS:		our_TS_Hour_Min_SunRise,

																						num_horas:		DELTA_TS_sunSet_sunRise_FHours
																					}

										); // ar_Horas_SunsetSunrise_dias_Mes_SIG.push(


										suma_Horas_Reales_SIG_TEORICAS  +=  DELTA_TS_sunSet_sunRise_FHours;

									}//	for ( let imd = 0;  imd < NUM_DAYS_MONTH_SIG;  imd++ ) - - - - - - - - - - - - - - - -

									//----

									//window.alert(  suma_Horas_Reales_SIG_TEORICAS  );

									suma_Horas_Reales_SIG_TEORICAS  *=  parseFloat(  this.state.selected_installationgroup.info.streetlights.length  );



									//window.alert(   parseFloat( suma_Horas_Reales_TEORICAS ) );
									//window.alert(   parseFloat( suma_Horas_Reales_SIG_TEORICAS ) );
									//console.log(  ar_Horas_SunsetSunrise_dias_Mes_SIG  );


									IND_fmul_SIG_Ahorro_Energ_Mens =  parseFloat(  suma_Horas_Reales_SIG_TEORICAS  /  suma_Horas_Reales_TEORICAS  );


									//window.alert(  IND_fmul_SIG_Ahorro_Energ_Mens  );


								}// if (   our_JSON_sunrise  !=  undefined   )


							}// if (   our_JSON_sunrise  !==  null   ) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -



							//----------------------------------------------------
							//----------------------------------------------------



							// TEORICA DE ESTE MES:


							if (   (  ar_DIMM  !=  undefined  )   &&   (  ar_DIMM  !=  null  )   &&   (  ar_DIMM.length  >=  1	  )   )
							{

								ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO =  [];


								for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )
								{

									ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO[  imd  ] =  [];


									//ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO.push(  []  );

									ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO.push(
																				new Array()

																			); // .push(

									ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO[  imd  ] =  [];


								} // for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )


								//-------------------------------------

								//console.log(  ar_DIMM  );



								if (  ar_DIMM.length  <=  6   )		// ENTONCES, YA SABEMOS, QUE SE TRATA DE DIMMING NUEVO DE EPEVER-LORO QUE GUARDA SÓLO 6 POSICIONES EN SU ARRAY, COMO AÚN NO ESTÁ IMPLEMENTADO VAMOS A HACER QUE SALGA
								{


									/*
									window.alert( "Instalación con Epever-LORA:  En Desarrollo" );

									this.state.NUM_COUNT_MOUNT = 0;

									this.componentWillUnmount();


									window.location.replace(   'https://apps.ilumek.com/'  );
									*/



									//return -1;

								}// if (  ar_DIMM.length  <=  6   ) 	----------------------------------------------------------------------------------------]




								//----


								console.log(  "ar_DIMM: "  );
								console.log(  ar_DIMM  );


								//----


								ar_DIMM.forEach(
								//
									(  item,  index  ) =>
									{

										//console.log(  item.name  );


										let aux_DIM_hour_DATA =  item.name;
										
										console.log(aux_DIM_hour_DATA)

										let aux_DIM_hour_HOUR =  parseInt(  aux_DIM_hour_DATA.split(  ":"  )[ 0 ]  );
										let aux_DIM_hour_MINS =  parseInt(  aux_DIM_hour_DATA.split(  ":"  )[ 1 ]  );



										let our_DIM_TS_hourmins =	(  aux_DIM_hour_HOUR  >=  12  )
																?
																	(  aux_DIM_hour_HOUR  *  60  )  +  aux_DIM_hour_MINS
																:
																	(   (  aux_DIM_hour_HOUR  *  60  )  +  aux_DIM_hour_MINS   )   +   (  24  *  60  );


										//console.log(  our_DIM_TS_hourmins  );

										// 	ar_Horas_SunsetSunrise_dias_Mes_SIG.push(  DELTA_TS_sunSet_sunRise_FHours  );

										for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )
										{

											if (	(
														//(  our_DIM_TS_hourmins  >=  ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunset_TS  )  ||

														//(  (  our_DIM_TS_hourmins  -  (  15  )  )   >=   ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunset_TS  )  ||

														(  (  our_DIM_TS_hourmins  +  (  30  )  )   >   ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunset_TS  )
													)

													&&

													(
														//(  our_DIM_TS_hourmins  <=  ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunrise_TS  )  ||

														//(  (  our_DIM_TS_hourmins  -  (  15  )  )  <=  ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunrise_TS  ) ||

														(  (  our_DIM_TS_hourmins  -  (  30  )  )  <  ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunrise_TS  )

													)
												)
											{


												let coef_amount_time_mediaH =  parseFloat(  1.0  );

												//--

												if	(

														(  (  our_DIM_TS_hourmins  +  (  0  )  )   <=   ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunset_TS  )   &&
														(  (  our_DIM_TS_hourmins  +  (  30 )  )   >    ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunset_TS  )
													)
												{

													let DELTA_sun_dim_DAY =  30  -  Math.abs(  ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunset_TS  -  our_DIM_TS_hourmins  );

													coef_amount_time_mediaH =  parseFloat(  parseFloat( DELTA_sun_dim_DAY )  /  parseFloat( 30.0 )  );

												}
												else
												{

													if	(
															(  (  our_DIM_TS_hourmins  +  (  0  )  )   <   ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunrise_TS  )   &&
															(  (  our_DIM_TS_hourmins  +  (  30 )  )   >=    ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunrise_TS  )
														)
													{

														let DELTA_sun_dim_DAY =  Math.abs(  ar_Horas_SunsetSunrise_dias_Mes[ imd ].sunrise_TS  -  our_DIM_TS_hourmins  );

														coef_amount_time_mediaH =  parseFloat(  parseFloat( DELTA_sun_dim_DAY )  /  parseFloat( 30.0 )  );

													}/*
													else
													{
														coef_amount_time_mediaH =  parseFloat(  1.0  );

													}// else
													*/

												}// else


												// _ar_STR_dayWEEK



												let fhours_calc =  parseFloat( 0.5 )  *  coef_amount_time_mediaH;

												let new_COEF =  parseFloat(   INDICE_fmul_coeflum_pow  *  parseFloat(  item.sp  )   );


												let result_Wh_mediaHora =  parseFloat(  new_COEF  *  parseFloat( _obj_Inst_Group.installations[0].streetlights[0].luminary.led_power )  *  fhours_calc  );	// 0.5 horas


												//////console.log( "day: " + ( imd + 1 )  +  ";  item: "  +  item.name  +  ";  SP: "  +   item.sp  +  ";  newCOEF: "  +  new_COEF  +  ";  result_Wh_mediaHora: "  +  result_Wh_mediaHora  );


												ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO[  imd  ].push(  result_Wh_mediaHora  );


												// CADA FRANJA:  0.5 HORAS
												//
												//INST_GROUP_coef_lum_power =  parseFloat(  _obj_Inst_Group.coef_lum_power  );
												//INDICE_fmul_coeflum_pow =   parseFloat(  1.0  /  INST_GROUP_coef_lum_power  );


											}// if ( ... )


										}// for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )

									} // (  item,  index  ) =>

								//
								); // .forEach() ----------------------------------------



								//-------------------------------------


								//console.log(  ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO  );

								ar_Ahorro_Wh_DIA_TEORICO =  [];


								for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )
								{
									const len_trams_day =  ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO[  imd  ].length;

									//////console.log( len_trams_day );

									let aux_our_SUM =  parseFloat(  0.0 );

									for (  let itram = 0;  itram < len_trams_day;  itram++  )
									{
										aux_our_SUM +=  ar_ar_Ahorro_Wh_DIA_Tramos_TEORICO[  imd  ][  itram  ];

									}// for (  let itram = 0;  itram < len_trams_day;  itram++  )


									ar_Ahorro_Wh_DIA_TEORICO.push(  aux_our_SUM  );

								}// for (  let imd = 0;  imd < NUM_DAYS_MONTH;  imd++  )

								//------

								//console.log(  ar_Ahorro_Wh_DIA_TEORICO  );


							}// if (   ar_DIMM  !=  null   ) -----------------------------------------------------------------------------------------------------------------------------------------------]




							//--------------------------------------------------------------------------------------



							//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs (MITAD RENDER (1)): " );
							//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );


							//------------------------------------------
							//------------------------------------------


							//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK =  null;	// LIBERAR MEMORIA

							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK =  new Array(  NUM_SEMANAS_mes  );  /*5*/


							//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK =  new Array( NUM_SEMANAS_mes );

							/*
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ] =  null;
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 1 ] =  null;
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 2 ] =  null;
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ] =  null;
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 4 ] =  null;
							*/


							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ] =  new Array(  _obj_Inst_Group.info.streetlights.length  );
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 1 ] =  new Array(  _obj_Inst_Group.info.streetlights.length  );
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 2 ] =  new Array(  _obj_Inst_Group.info.streetlights.length  );
							_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ] =  new Array(  _obj_Inst_Group.info.streetlights.length  );


							/*
							_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ] =  new Array( _obj_Inst_Group.info.streetlights.length );
							_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 1 ] =  new Array( _obj_Inst_Group.info.streetlights.length );
							_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 2 ] =  new Array( _obj_Inst_Group.info.streetlights.length );
							_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ] =  new Array( _obj_Inst_Group.info.streetlights.length );
							*/


							if (  NUM_SEMANAS_mes  ===  5  )
							{

								_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 4 ] =  new Array( _obj_Inst_Group.info.streetlights.length );

								//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 4 ] =  new Array( _obj_Inst_Group.info.streetlights.length );

							} // if (  NUM_SEMANAS_mes  ==  5  )


							//-----------------------------------------------------



							var AUXXXXX__ALL__STREETLIGHTS_VARVAL = [];
							AUXXXXX__ALL__STREETLIGHTS_VARVAL = new Array();


							//--

							for (  let isl =  0;  isl  <  _ar_ALL__STREETLIGHTS_VARVALs.length;  isl++  )	// empieza en 0
							{

								AUXXXXX__ALL__STREETLIGHTS_VARVAL.push(
																		_ar_ALL__STREETLIGHTS_VARVALs[  isl  ]

																	); // .push(


							}// for (  let isl =  0;  isl  <  _ar_ALL__STREETLIGHTS_VARVALs.length;  isl++  )	// empieza en 0



							//console.log(   "AUXXXXX__ALL__STREETLIGHTS_VARVAL: "   );
							//console.log(   AUXXXXX__ALL__STREETLIGHTS_VARVAL   );


							//_-------------------------------------------------------------------------------------------------------------------------------------------------
							//_-------------------------------------------------------------------------------------------------------------------------------------------------
							//_-------------------------------------------------------------------------------------------------------------------------------------------------
							//_-------------------------------------------------------------------------------------------------------------------------------------------------




							//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs (MITAD RENDER (2)): " );
							//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );





							// con un map ???



							const  NUM_INTALS_igroup = _obj_Inst_Group.installations.length;



							//--



							// TENEMOS LAS FAROLAS POR INSTALACION, SIN REPETICIONES, PERO SIN ORDEN


								// FUNCIONA YA CON NUMERO DE INSTALACIONES  = 1  Y  > 1, PONIENDO ÉSTE "IF" A TRUE

							if (

									/*ob_InsGroup_LENGTH  >= 2*/

									true

							)  // AQUI SEPARAMOS POR INSTALACION
							{

								//aux_instalss[ 0 ].push(   AUXXXXX__ALL__STREETLIGHTS_VARVAL[ 0 ]   );
								//AUXXXXX__ALL__STREETLIGHTS_VARVAL =  AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(   1,  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length  );


								//console.log(   AUXXXXX__ALL__STREETLIGHTS_VARVAL.length   );


								//-----------------------------------

								/*
								var OUR_aux_instalss =  new Array();

								console.log(  " (0) >>>>>>>>>>>>>>>>>>>>>>> OUR_aux_instalss: "  );
								console.log(  OUR_aux_instalss  );
								*/


								//window.alert(  JSON.stringify(  aux_instalss  )  );



								//console.log( _obj_Inst_Group );



								//---------------------------



							// ESTO ES SUPONIENDO QUE SÓLO HAY 1 MASTER POR INSTALACIÓN.... (MASTER Y ESCLAVAS COMPARTEN LA RÁIZ DEL NOMBRE)


							// LO QUE HACE ES SEPRARAR POR GRUPOS (POR ÍNDICE DE ARRAY) LAS FAROLAS DE LAS DISTINTAS INSTALACIONES

																										//for (  let isl =  0;  isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length;  isl++  )   // EMPIEZA EN 0

								if (

										//(  NUM_INTALS_igroup  ==  1  )

										true

								)
								{






									//-------------------



								// CREAR ARRAY DE NOMBRES_RAICES (VA A SERVIR PARA TODOS LOS CASOS), SE USARÁ ADEMÁS ABAJO

								// NOMBRES RAICES NO REPETIDOS .... LUEGO COMPARAR CON NÚM DE INSTALACIONES... DEPENDIENDO DE RESULTADO, HACER DE UNA FORMA U OTRA

									//---


									var ar_ROOT_names = new Array();

									// POS '0'

									ar_ROOT_names.push(

															AUXXXXX__ALL__STREETLIGHTS_VARVAL[   0   ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice( 0, -5 )

									); // .push(


									//---


									//  let i_SL =  1;   !!!   		(el '0' ya lo incorporamos al Array arriba..)

									for (   let i_SL =  1;  i_SL  <  /*_ar_ALL__STREETLIGHTS_VARVALs*/ AUXXXXX__ALL__STREETLIGHTS_VARVAL.length;  i_SL++   )
									{
										// recorrer por cada array lenght total del fijo, dentro, recorrer el que se va generando con los nuevos y añadir aquel que no esté, y repetimos

										const RAIZ_SL =  AUXXXXX__ALL__STREETLIGHTS_VARVAL[   i_SL   ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice( 0, -5 );


										let ESTA_NOMBRE_RAIZ = false;

										let i_ROOT_name =  0;


										while (
													(  ESTA_NOMBRE_RAIZ == false  )
													&&
													(  i_ROOT_name  <  ar_ROOT_names.length  )
										)
										{

											ESTA_NOMBRE_RAIZ =   (   ar_ROOT_names[  i_ROOT_name  ]   ==   RAIZ_SL   );

											//---

											i_ROOT_name++


										} // while ( ... ) ----------------------------------------------------------------


										//--


										if (  ESTA_NOMBRE_RAIZ  ==  false  )
										{

											ar_ROOT_names.push(

																		AUXXXXX__ALL__STREETLIGHTS_VARVAL[   i_SL   ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice( 0, -5 )

											); // .push(

										} // if (  ESTA_NOMBRE_RAIZ  ==  false  )


									} // for (   let i_SL =  1;  i_SL  <  _ar_ALL__STREETLIGHTS_VARVALs.length;  i_SL++   ) -------------------------------------


									//window.alert(  ar_ROOT_names  );

									//console.log(  "ar_ROOT_names: "  );
									//console.log(  ar_ROOT_names.length  );
									//console.log(  ar_ROOT_names  );

									//console.log(  "ar_DIMM: "  );
									//console.log(  ar_DIMM  );

									console.log(  "-------------------"  );



									//------------------------------------------------------------------



								// ORDENAMOS LAS MAESTRAS / INSTALACIONES (DADO QUE ANTES NOS QUEDAMOS CON LOS NOMBRES_RAIZ SIN REPETICIONES -->  VIENEN A SER LAS MAESTRAS O INCLUSO "INSTALACIONES" DIGAMOS..):



									let aux_intercambio_VAL =  -1;


									for (   let OUR_J =  0;   OUR_J  <=  ( ar_ROOT_names.length - 2);  OUR_J++   )
									{
												// i = 1		!!!
										for (   let i =  1;   i  <=  (  (  ar_ROOT_names.length  -  1 )  -  OUR_J   );  i++   )
										{


											if (  ar_ROOT_names[  i - 1  ]   >   ar_ROOT_names[  i  ]   )
											{

												aux_intercambio_VAL =  ar_ROOT_names[  i - 1  ];

												ar_ROOT_names[  i - 1  ] =  ar_ROOT_names[  i  ];

												ar_ROOT_names[  i  ] =  aux_intercambio_VAL;

											} // if (  ar_ROOT_names[  i - 1  ]   >   ar_ROOT_names[  i  ]   )

										} // for (   let i =  1;   i  <  ar_ROOT_names.length;  i++   )

									} // for (   let OUR_J =  0;   OUR_J  <=  ( ar_ROOT_names.length - 2);  OUR_J++   )



									console.log(  "ar_ROOT_names: "  );
									console.log(  ar_ROOT_names.length  );
									console.log(  ar_ROOT_names  );

									//console.log(  "ar_DIMM: "  );
									//console.log(  ar_DIMM  );

									console.log(  "-------------------"  );



								// ARRIBA:  nombres ROOT ORDENADOS DE MAYOR A MENOR



									//--------------------

									/*
									10: Object { name: "17:00", sun: 0, sp: 0, … }
									​
									11: Object { name: "17:30", sun: 0, sp: 80, … }
									​
									12: Object { name: "18:00", sun: 100, sp: 80, … }
									​
									13: Object { name: "18:30", sun: 100, sp: 80, … }
									​
									14: Object { name: "19:00", sun: 100, sp: 80, … }
									​
									15: Object { name: "19:30", sun: 100, sp: 80, … }
									​
									16: Object { name: "20:00", sun: 100, sp: 80, … }
									​
									17: Object { name: "20:30", sun: 100, sp: 80, … }
									​
									18: Object { name: "21:00", sun: 100, sp: 80, … }
									​
									19: Object { name: "21:30", sun: 100, sp: 80, … }
									​
									20: Object { name: "22:00", sun: 100, sp: 80, … }
									​
									21: Object { name: "22:30", sun: 100, sp: 80, … }
									​
									22: Object { name: "23:00", sun: 100, sp: 80, … }
									​
									23: Object { name: "23:30", sun: 100, sp: 30, … }
									​
									24: Object { name: "00:00", sun: 100, sp: 30, … }
									​
									25: Object { name: "00:30", sun: 100, sp: 30, … }
									​
									26: Object { name: "01:00", sun: 100, sp: 30, … }
									​
									27: Object { name: "01:30", sun: 100, sp: 30, … }
									​
									28: Object { name: "02:00", sun: 100, sp: 30, … }
									​
									29: Object { name: "02:30", sun: 100, sp: 30, … }
									​
									30: Object { name: "03:00", sun: 100, sp: 30, … }
									​
									31: Object { name: "03:30", sun: 100, sp: 30, … }
									​
									32: Object { name: "04:00", sun: 100, sp: 30, … }
									​
									33: Object { name: "04:30", sun: 100, sp: 30, … }
									​
									34: Object { name: "05:00", sun: 100, sp: 30, … }
									​
									35: Object { name: "05:30", sun: 100, sp: 30, … }
									​
									36: Object { name: "06:00", sun: 100, sp: 80, … }
									​
									37: Object { name: "06:30", sun: 100, sp: 80, … }
									​
									38: Object { name: "07:00", sun: 100, sp: 80, … }
									​
									39: Object { name: "07:30", sun: 100, sp: 80, … }
									​
									40: Object { name: "08:00", sun: 100, sp: 80, … }
									​
									41: Object { name: "08:30", sun: 0, sp: 80, … }
									​
									42: Object { name: "09:00", sun: 0, sp: 80, … }
									​
									43: Object { name: "09:30", sun: 0, sp: 80, … }
									​
									44: Object { name: "10:00", sun: 0, sp: 0, … }
									*/




									/*
									[
										{
										  "timestamp_INI": 0,
										  "str_time_hour_INI": "17:00",
										  "timestamp_END": 232,
										  "str_time_hour_END": "20:52",
										  "interval_VAL": 0
										},
										{
										  "timestamp_INI": 233,
										  "str_time_hour_INI": "20:53",
										  "timestamp_END": 369,
										  "str_time_hour_END": "23:09",
										  "interval_VAL": 90
										},
										{
										  "timestamp_INI": 370,
										  "str_time_hour_INI": "23:10",
										  "timestamp_END": 805,
										  "str_time_hour_END": "06:25",
										  "interval_VAL": 30
										},
										{
										  "timestamp_INI": 806,
										  "str_time_hour_INI": "06:26",
										  "timestamp_END": 865,
										  "str_time_hour_END": "07:25",
										  "interval_VAL": 90
										},
										{
										  "timestamp_INI": 866,
										  "str_time_hour_INI": "07:26",
										  "timestamp_END": 1019,
										  "str_time_hour_END": "09:59",
										  "interval_VAL": 0
										}
									  ]
									  */


									//------------------------------------------------------








									//if (   NUM_INTALS_igroup  <  ar_ROOT_names.length  )


									//--



								// ARRAY DE ARRAYS "aux_instalss" CREADOS CON POSICIONES PRIMARIAS SEGÚN NUMERO DE NOMBRES_ROOT (MAESTRAS, O PUEDE, INSTALACIONES)

								// DE AQUÍ SALEN ORDENADOS AL FINAL TANTO "aux_instalss" COMO "AUXXXXX__ALL__STREETLIGHTS_VARVAL":


									var aux_instalss =  new Array();
									aux_instalss =  [];



									//for (   let num_instal =  0;  num_instal  <  NUM_INTALS_igroup;  num_instal++   )		// ANTERIOR A 05/04/23
									for (   let j_ROOT =  0;  j_ROOT  <  ar_ROOT_names.length;  j_ROOT++   )
									{

										//var j_ROOT = 0;

										aux_instalss.push(

																new Array()

														); // .push(

										//aux_instalss[ j_ROOT ] =  [];

										//--

										//const SL_1ERA =  0;  // DE LAS QUE QUEDEN PORQUE VAMOS SACANDO ELEMENTOS...

										//const Nomb_RAIZ_1ero =   ar_ROOT_names[  j_ROOT /*SL_1ERA*/  ];		//AUXXXXX__ALL__STREETLIGHTS_VARVAL[  SL_1ERA  ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice( 0, -5 );

										//console.log(  AUXXXXX__ALL__STREETLIGHTS_VARVAL[  SL_1ERA  ].schema.fields[ 2 ].name.split( '_B' )[ 0 ]  );
										//console.log(   Nombre_PRIMERA_INSTALACION   );



										//////console.log(  "PRIMERA_INSTALACION: "  +  nombre_PRIMERA_INSTALACION  );



										//var ENCONTRADO_Nombre = false;	// SERGIO 04/04/23
										var isl =  0;

										//--

										while (
													(  isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length  )
													//&&
													//(  ENCONTRADO_Nombre  ===  false  )
										)
										{

											const CABECERA__i_SL =   AUXXXXX__ALL__STREETLIGHTS_VARVAL[   isl   ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice( 0, -5 );		//  ar_ROOT_names[  isl  ];



											if (   /*Nomb_RAIZ_1ero*/ 	(  CABECERA__i_SL   ===   ar_ROOT_names[  j_ROOT ]  )		/*SL_1ERA*/   )
											{

												aux_instalss[  j_ROOT  ].push(
																					AUXXXXX__ALL__STREETLIGHTS_VARVAL[  isl  ]

																				); // .push(


												//--------------------------------


												var temp_slice_p1 =  AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(	0,  isl  );

												var temp_slice_p2 =  AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(	isl + 1,  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length     );


												AUXXXXX__ALL__STREETLIGHTS_VARVAL =  temp_slice_p1.concat(  temp_slice_p2  );	// TAMBIÉN LO ORDENAMOS


												/*
												AUXXXXX__ALL__STREETLIGHTS_VARVAL =   AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(
																															0,  isl

												).concat(
													AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(   isl + 1,  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length		)
												);
												*/

												//ENCONTRADO_Nombre = true;

											}//
											else
											{

												isl ++;

											}// else


										}// while (    isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length   )



									}// for (   let j_ROOT =  0;  j_ROOT  <  NUM_INTALS_igroup;  j_ROOT++   )


								} // if ( true )


								//--------------------------------------


								let AUX_auxxxxx_instalss = new Array();

								for (  let icopy =  0;  icopy <  aux_instalss.length;  icopy++  )
								{

									AUX_auxxxxx_instalss.push(

																aux_instalss[  icopy  ]

									); //.push(

								} //

								//--

								console.log(  " (1) >>>>>>>>>>>>>>>>>>>>>>> AUX_auxxxxx_instalss: "  );
								console.log(  AUX_auxxxxx_instalss  );

								//window.alert(  JSON.stringify(  AUX_auxxxxx_instalss  )  );

								//--------------------------------------




								//---------------------------------------------------------------------------------------------------------------------------------------------------------------
								//--------------------------------------------------------------------------------------------------------------------------------------------------------------








						// NO SE RECORRE "NUM_INTALS_igroup", SINO LAS POSCIONES _PRIMARIAS_ (ARRAY PADRE DE ARRRAYS) DEL ANTERIOR ARRAY RECIÉN CREADO "aux_instalss"

								// además hacer bien lo de la 'M'







								//let cont_Maestras_por_Instalac = 0;


							// BUSCAMOS LAS MAESTRAS Y LAS COLOCAMOS LA 1ERAS EN CADA ARRAY INSTAL:

								//for (  let num_instal =  0;  num_instal  <  NUM_INTALS_igroup;  num_instal++  )
								for (  let j_ROOT =  0;  j_ROOT  <  ar_ROOT_names.length;  j_ROOT++  ) // --------------------------------------------------------------------------------------------------------------[
								{

									//var j_ROOT = 0;

									//aux_instalss[ j_ROOT ] = [];

									//for (  let isl =  0;  isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length;  isl++  )   // EMPIEZA EN 0


									//var sl_1era =  0;  // DE LAS QUE QUEDEN PORQUE VAMOS SACANDO ELEMENTOS...
									//console.log(  "PRIMERA_INSTALACION: "  +  nombre_PRIMERA_INSTALACION  );


									var nombre_tiene_M =  false;

									var isl =  0;

									//--
									// -------------------------------------------------------------------------------------------------------------------------------------------------------[
									while (
												(  isl  <   aux_instalss[  j_ROOT  ].length   )
												&&
												(  nombre_tiene_M  == false )
									) // ------------------------------------------
									{

										const nombre_SL_COMPLETO =  (  aux_instalss[  j_ROOT  ][  isl  ].schema.fields[ 2 ].name.split( '_B' )[ 0 ]  ).toUpperCase();


										const indx_nombre_M =  nombre_SL_COMPLETO.indexOf( 'M' );		//  nombre_SL_COMPLETO.indexOf( 'm' );				------------>    MIRAR MAESTRA (S) !

										//--

										nombre_tiene_M =  (   indx_nombre_M  !=  -1  );

										//---------------------------------------------------------------

										if (  nombre_tiene_M  )
										{

											//var temp_obj =  aux_instalss[  j_ROOT  ][  isl  ];

											var ar_temp_obj = [];


											ar_temp_obj.push(    aux_instalss[  j_ROOT  ][  isl  ]   );


											var temp_slice_p1 =  aux_instalss[  j_ROOT  ].slice(	0,  isl  );
											var temp_slice_p2 =  aux_instalss[  j_ROOT  ].slice(	isl + 1,  aux_instalss[  j_ROOT  ].length     );



											var new_array =  ar_temp_obj.concat(	temp_slice_p1.concat(  temp_slice_p2  )		);

											//---

											aux_instalss[  j_ROOT  ] =  new_array;


										} // if (  nombre_tiene_M  )


										//--

										isl ++;

									}// while (  ()  &&  ()  )-------------------------------------------------------------------------------------------------------------------------------]


								} // for (  let j_ROOT =  0;  j_ROOT  <  ob_InsGroup_LENGTH;  j_ROOT++  ) -----------------------------------------------------------------------------------------------------]





								console.log(  " (2) >>>>>>>>>>>>>>>>>>>>>>> aux_instalss: "  );
								console.log(  aux_instalss  );

								//window.alert(  JSON.stringify(  aux_instalss  )  );



								console.log( "-----------------------------------------------" );
								console.log( "-----------------------------------------------" );




								// AQUI ARRIBA YA ORDENADOS (INCLUYENDO 'M's)











								//------------------------------------------------------------------------------------------
								//------------------------------------------------------------------------------------------


								// INICIALIZAMOS ESTE NUEVO ARRRAY AUXILIAR CON LOS VALORES DE "aux_instalss" (A PARTIR DE LOS VALORES DE..)

								var NEW_aux_instalss = [];


								//for (  let num_instal =  0;  num_instal  <  NUM_INTALS_igroup;  num_instal++  )
								for (   let j_ROOT =  0;  j_ROOT  <  ar_ROOT_names.length;  j_ROOT++   )
								{


									NEW_aux_instalss.push(
															new Array()
									); // .push(
									//NEW_aux_instalss[  j_ROOT  ] =  [];

									//----


									for (  let iisl =  0;  iisl  <  aux_instalss[  j_ROOT  ].length;  iisl++  )
									{

										NEW_aux_instalss[  j_ROOT  ].push(
																			aux_instalss[  j_ROOT  ][ iisl ]
										); // .push

										//----

									}// for : iisl


								}// for : j_ROOT -----------



								//console.log(   NEW_aux_instalss  );



								//-----------------------------------



								//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs (MITAD RENDER (3)): " );			///    OKKK
								//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );



								//-----------------------------------



								// ORDENAMOS POR NÚMERO DE SL


								//var ar_index_posicion_con_desorden =  [];
								//var ar_NUM_nombre =  [];



							// (NUMERACIÓN _CORRECTA_ DE LAS ESCLAVAS)


								//for (  let num_instal =  0;  num_instal  <  NUM_INTALS_igroup;  num_instal++  )
								for (   let j_ROOT =  0;  j_ROOT  <  ar_ROOT_names.length;  j_ROOT++   )
								{
									//ar_index_posicion_con_desorden[  j_ROOT  ] =  [];


									//var j_ROOT = 0;

									//aux_instalss[ j_ROOT ] = [];

									//for (  let isl =  0;  isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length;  isl++  )   // EMPIEZA EN 0


									//var sl_1era =  0;  // DE LAS QUE QUEDEN PORQUE VAMOS SACANDO ELEMENTOS...
									//console.log(  "PRIMERA_INSTALACION: "  +  nombre_PRIMERA_INSTALACION  );


									var NUMERO_EN_NOMBRE__1 =  -1;
									var NUMERO_EN_NOMBRE__2 =  -1;


									// UTILIZAMOS ORDENAMIENTO POR BURBUJA
									/*
									for (  ii =  1;  ii <  ar.len;  ii++ )
									{
										for (  j  = 0;  j  <  ( ar.len - ii );  j++  )
										{

											if (  aj >  aj+1 )
											{
												aux = aj;


												aj = aj+1;

												aj+1=aux
											}
										}
									}
									*/


									for (  let iii = 1;  iii  <  NEW_aux_instalss[  j_ROOT  ].length;  iii++  )		// EMPEZAR DESDE EL 2 ??     con 1 de momento ha funcionado (pero igual se itera una vez más de la cuenta... pero tampoco supone algo negativo al resultado final)
									{

										for (  let isl =  1;  isl  <  (  NEW_aux_instalss[  j_ROOT  ].length  -  iii  );  isl++  )	// EMPIEZA EN 1  // EMPIEZA DESDE INDEX 1 PORQUE EN EL 0, YA ESTÁ LA SL 'M'
										{

											var nombre_SL_COMPLETO__1 =  NEW_aux_instalss[  j_ROOT  ][    isl    ].schema.fields[ 2 ].name.split( '_B' )[ 0 ];
											var nombre_SL_COMPLETO__2 =  NEW_aux_instalss[  j_ROOT  ][    isl  +  1   ].schema.fields[ 2 ].name.split( '_B' )[ 0 ];


											var ar_AUX_Slice_1 =  nombre_SL_COMPLETO__1.slice(  8, 10  );
											NUMERO_EN_NOMBRE__1 =  parseInt(   String( ar_AUX_Slice_1[ 0 ] + ar_AUX_Slice_1[ 1 ] )  );


											var ar_AUX_Slice_2 =  nombre_SL_COMPLETO__2.slice(  8, 10  );
											NUMERO_EN_NOMBRE__2 =  parseInt(   String( ar_AUX_Slice_2[ 0 ] + ar_AUX_Slice_2[ 1 ] )  );



											if (   NUMERO_EN_NOMBRE__1  >  NUMERO_EN_NOMBRE__2   )
											{
												// INTERCAMBIAR


												var ar_temp_obj_1 = [];

												ar_temp_obj_1.push(    NEW_aux_instalss[  j_ROOT  ][  isl  ]   );


												var ar_temp_obj_2 = [];

												ar_temp_obj_2.push(    NEW_aux_instalss[  j_ROOT  ][   isl  +  1  ]   );

												//---

												var temp_slice_p1a =  NEW_aux_instalss[  j_ROOT  ].slice(	0,   isl  );

												var temp_slice_p2a =  NEW_aux_instalss[  j_ROOT  ].slice(	 isl + 2,   NEW_aux_instalss[  j_ROOT  ].length     );



												var ar_INTERCAMBIO_poss =  ar_temp_obj_2.concat(  ar_temp_obj_1  );

												var primera_mitad =  temp_slice_p1a.concat(   ar_INTERCAMBIO_poss   );

												var new_array =  primera_mitad.concat(  temp_slice_p2a  );


												NEW_aux_instalss[  j_ROOT  ] =  new_array;

											}// if ---


										}// for : isl ------------------------------------------------------------------------------------------------------------------]




										/*
										var isl =  1;	// EMPIEZA DESDE INDEX 1 PORQUE EN EL 0, YA ESTÁ LA SL 'M'					//1;	// 0         // AHORA EMPIEZA EN 1 !

										while (
													(  isl  <   (  NEW_aux_instalss[  j_ROOT  ].length  - iii  )   )  //&& 			... - 1

													//(  nombre_tiene_eseNUM  == false )
											)
										{





											//----------------------------------------

											var ar_AUX_Slice =  NEW_aux_instalss[  j_ROOT  ][  isl  ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice(  8, 10  );

											NUMERO_EN_NOMBRE =  parseInt(   String( ar_AUX_Slice[ 0 ] + ar_AUX_Slice[ 1 ] )  );



											if (   NUMERO_EN_NOMBRE  !=  0   )
											{

												if (   NUMERO_EN_NOMBRE  !=  isl   )	// HAY QUE RECOLOCARLO EN SU SITIO
												{
													console.log(  NUMERO_EN_NOMBRE );


													ar_index_posicion_con_desorden.push(  isl  );		// ESTOS 2 ARRAYS VAN A LA VEZ
													ar_NUM_nombre.push(  NUMERO_EN_NOMBRE  );

												}// if

											}// if

											//----

											isl ++;

										}// while ---
										*/

									}// for : iii

								}// for (  let j_ROOT =  0;  j_ROOT  <  ob_InsGroup_LENGTH;  j_ROOT++  ) --------------------------------------------------------------------------------------------------------------------]


								//--------------------------



								// COMPROBAMOS LOS RESULTADOS DE "NEW_aux_instalss" (NO HACE NADA MÁS, PARA PONER EL CONSOLE.LOG Y QUE ESTÉ ACCESIBLBE Y NO "COMENTARLO" Y SUPONER QUE ERA CÓDIGO DESCARTADO)

								// (NUMERACIÓN DE LAS ESCLAVAS)

								console.log(  "SÓLO NUMERACIÓN DE LAS ESCLAVAS: "  );


								//for (  let num_instal =  0;  num_instal  <  NUM_INTALS_igroup;  num_instal++  )
								for (   let j_ROOT =  0;  j_ROOT  <  ar_ROOT_names.length;  j_ROOT++   )
								{
									//----

									for (  let iisl =  1;  iisl  <  NEW_aux_instalss[  j_ROOT  ].length;  iisl++  )	  // DESDE  iisl =  1;  PORQUE EN POS 0 ESTÁ LA 'M'
									{

										var nombre_SL_COMPLETO__1 =  NEW_aux_instalss[  j_ROOT  ][    iisl    ].schema.fields[ 2 ].name.split( '_B' )[ 0 ];

										var ar_AUX_Slice_1 =  nombre_SL_COMPLETO__1.slice(  8, 10  );


										NUMERO_EN_NOMBRE__1 =  parseInt(   String( ar_AUX_Slice_1[ 0 ] + ar_AUX_Slice_1[ 1 ] )   );

										//----


										console.log(  "--> NEW_aux_instalss[" + j_ROOT + "]["  +  iisl  +  "].num_sl =  "  +  NUMERO_EN_NOMBRE__1  );


									}// for (  let iisl =  1;  iisl  <  NEW_aux_instalss[  j_ROOT  ].length;  iisl++  )	  // DESDE  iisl =  1;  PORQUE EN POS 0 ESTÁ LA 'M'


								}// for : j_ROOT ----------------------------------------------------------------------------------------------]



								//--------------------------










								// INTERCAMBIAMOS POSICIONES EN LOS ARRAYS AUX DE DESORDENES PARA ASI ORDENARLOS


								/*
								for (  let num_instal =  0;  num_instal  <  ob_InsGroup_LENGTH;  num_instal++  )
								{

									//var num_instal = 0;

									//aux_instalss[ num_instal ] = [];

									//for (  let isl =  0;  isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length;  isl++  )   // EMPIEZA EN 0


									//var sl_1era =  0;  // DE LAS QUE QUEDEN PORQUE VAMOS SACANDO ELEMENTOS...
									//console.log(  "PRIMERA_INSTALACION: "  +  nombre_PRIMERA_INSTALACION  );



									//var ar_index_posicion_con_desorden =  [];

									//var ar_NUM_nombre =  [];



									//var NUMERO_EN_NOMBRE =  -1;

									var aux_obj_1; //=  [];
									var aux_obj_2; //=  [];



									var ii =  0;



									while (
												(  ii  <  ( ar_index_posicion_con_desorden.length - 1 )  )  //&&

												//(  nombre_tiene_M  == false )
										)
									{


										aux_obj_1 =  aux_instalss[   ar_index_posicion_con_desorden[ ii ]   ];
										aux_obj_2 =  aux_instalss[   ar_index_posicion_con_desorden[ ii + 1 ]   ];


										aux_instalss[   ar_index_posicion_con_desorden[ ii ]   ] =  aux_obj_2;
										aux_instalss[   ar_index_posicion_con_desorden[ ii + 1 ]   ] =  aux_obj_1;


										//ar_NUM_nombre


										ii ++;

									}// while ---

								}// for
								*/





																				/*
																				for (  let isl =   1;  isl  <  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length;  isl++  )   // EMPIEZA EN 1
																				{

																					if (   nombre_PRIMERA_INSTALACION  ==  AUXXXXX__ALL__STREETLIGHTS_VARVAL[   isl   ].schema.fields[ 2 ].name.split( '_B' )[ 0 ].slice( 0, -2 )   )
																					{

																						aux_instalss[ 0 ].push(   AUXXXXX__ALL__STREETLIGHTS_VARVAL[  isl  ]   );


																						//AUXXXXX__ALL__STREETLIGHTS_VARVAL =   AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(   0,  isl  ).concat(   AUXXXXX__ALL__STREETLIGHTS_VARVAL.slice(   isl + 1,  AUXXXXX__ALL__STREETLIGHTS_VARVAL.length   )   );
																					}//

																				}// for
																				*/







								//------

								//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs (MITAD RENDER (4)): " );			///    OKKK
								//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );

								//------
								/*
								console.log(  "_obj_Inst_Group: "  );
								console.log(  _obj_Inst_Group  );

								//------


								console.log(  "_obj_Inst_Group.installations: "  );
								console.log(  _obj_Inst_Group.installations  );
								*/
								//console.log(  "ob_InsGroup_LENGTH: "  );
								//console.log(  ob_InsGroup_LENGTH  );




								var ar_CONCAT_instals = [];


								//for (  let num_instal =  0;  num_instal  <  NUM_INTALS_igroup;  num_instal++  )
								for (   let j_ROOT =  0;  j_ROOT  <  ar_ROOT_names.length;  j_ROOT++   )
								{

									//ar_CONCAT_instals =  ar_CONCAT_instals.concat(   aux_instalss[  j_ROOT  ]   ) ;


									ar_CONCAT_instals =  ar_CONCAT_instals.concat(   NEW_aux_instalss[  j_ROOT  ]   ) ;

									//---


									console.log(   "NEW_aux_instalss[  "  +  j_ROOT  +  "  ]: "  );
									console.log(   NEW_aux_instalss[  j_ROOT  ]  );


								} // for (  let j_ROOT =  0;  j_ROOT  <  ( ob_InsGroup_LENGTH );  j_ROOT++  ) -----------------------------



								//--


								//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs (MITAD RENDER (5)): " );			///    OKKK
								//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );

								//---


								_ar_ALL__STREETLIGHTS_VARVALs =  ar_CONCAT_instals;



								console.log(  "_ar_ALL__STREETLIGHTS_VARVALs: "  );
								console.log(  _ar_ALL__STREETLIGHTS_VARVALs  );

								console.log(  "------------------------------------------------"  );
								console.log(  "------------------------------------------------"  );

							} // if (   true /*ob_InsGroup_LENGTH  >= 2*/   ) 	// AQUI SEPARAMOS POR INSTALACION, PERO AÚN NO ORDENAMOS



							//--------------------------


							//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs (MITAD RENDER (6)): " );			///    OKKK
							//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );


							//--------------------------




							//////console.log(   "AUXXXXX__ALL__STREETLIGHTS_VARVAL.length : " + AUXXXXX__ALL__STREETLIGHTS_VARVAL.length   );
							//////console.log(   "ob_InsGroup_LENGTH:  " + ob_InsGroup_LENGTH   );

							//console.log(   /*"aux_instalss: "  +*/  aux_instalss   );









							//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
							//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
							//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------





							//var TIMESTAMP__FIRST_day_MONTH = -1;
							//var TIMESTAMP__LAST_day_MONTH = -1;


							const const_timestamp_week =  (  7  *  24  *  60  *  60  );


							var timesstamp_END_WEEK = [];

							timesstamp_END_WEEK[ 0 ] =  TIMESTAMP__FIRST_day_MONTH;	// semana -1

							timesstamp_END_WEEK[ 1 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 1 * const_timestamp_week )  /*+  1*/;
							timesstamp_END_WEEK[ 2 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 2 * const_timestamp_week )  /*+  1*/;
							timesstamp_END_WEEK[ 3 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 3 * const_timestamp_week )  /*+  1*/;
							timesstamp_END_WEEK[ 4 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 4 * const_timestamp_week )  /*+  1*/;


							if (  NUM_SEMANAS_mes  ==  5  )
							{
								timesstamp_END_WEEK[ 5 ] =  TIMESTAMP__LAST_day_MONTH;
							}// if



							//----


							//console.log( "__ar_ALL__STREETLIGHTS_VARVALs__FINAL.length_:" +  _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  );



							//var aux_SUM_epoch = 0;

							var aux_SUM_var_B =  0;
							var aux_SUM_var_P =  0;
							var aux_SUM_var_C =  0;


							var aux_SUM_NUM_timestamp = 0;

							//var aux_NUM_timestamp = 0;




							MAX_INST_GROUP__VARS_BP =  0.0;
							MAX_INST_GROUP__VARS_C  =  0.0;





							///---------------------------------------------------------------------------------------------------------------





							//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs ( MITAD RENDER (X) ): " );			///    NOOOOOOOOOOO
							//console.log(  _ar_ALL__STREETLIGHTS_VARVALs );


							//---------------------------------------------------------

							// con un map ???

							//_ar_ALL__STREETLIGHTS_VARVALs__FINAL = null;	//undefined;	// LIBERAR POSIBLE MEMORIA
							//_ar_ALL__STREETLIGHTS_VARVALs__FINAL = [];

							for (  let allSLs = 0;  allSLs  <  my_SL_Array_length;  allSLs++  )
							{

								//--------



								var SL_NAME_now =  _ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].schema.fields[ 2 ].name.split( "_" )[ 0 ];

												//_ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].schema.fields[ 2 ].name.split( "_" )[ 0 ]



								// INIT

								for (  let iweek = 0;  iweek  <  /*5*/ NUM_SEMANAS_mes;  iweek++  )
								{
									_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  undefined;
									_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  null;

									_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  {};		//[];

									//-------

									_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =	{
																										SL_NAME:	SL_NAME_now,



																										indice_INSTAL__objINST_GROUP:  -1,	// INDICE DE INSTALACION DENTRO DE OBJ INSTALLATION GROUP (PARA RECOGER LOS DATOS DE INSTALLATION DEDSDE OBJ INSTALLATION GROUP)

																										indice_SL__objINST_GROUP:  -1,		// INDICE DE SL DENTRO DE SLS EN OBJ INSTALLATION GROUP (PARA REFERENCIARLO DESDE OBJ INSTALLATION GROUP)

																										ES_MASTER:  false,					// bool MASTER



																										dat: [],



																										dat_DIV_DaysWeek: [],

																									};

									//---------

									/*
									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  undefined;
									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  null;

									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  {};		//[];

									//-------

									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =	{
																												SL_NAME:	SL_NAME_now,



																												indice_INSTAL__objINST_GROUP:  -1,	// INDICE DE INSTALACION DENTRO DE OBJ INSTALLATION GROUP (PARA RECOGER LOS DATOS DE INSTALLATION DEDSDE OBJ INSTALLATION GROUP)

																												indice_SL__objINST_GROUP:  -1,		// INDICE DE SL DENTRO DE SLS EN OBJ INSTALLATION GROUP (PARA REFERENCIARLO DESDE OBJ INSTALLATION GROUP)

																												ES_MASTER:  false,					// bool MASTER



																												dat: [],



																												dat_DIV_DaysWeek: [],

																											};
									*/

								}// for : iweek ----------------------------------------------------------------


								//---------------------------------------------------------------------------------------------------




								//_ar_ALL__STREETLIGHTS_NAMES[  allSLs  ]  =  SL_NAME_now;






								//////console.log(  "SL NAME from _ar_ALL__STREETLIGHTS_VARVALs: \n"  +  JSON.stringify(   SL_NAME_now, null, "	" )   );

								//----

								var str_var_B =  SL_NAME_now  +  "_B";
								var str_var_P =  SL_NAME_now  +  "_P";
								var str_var_C =  SL_NAME_now  +  "_C";


								/*
								var MEDIA_str_var_B =  "MEDIA_"  +  SL_NAME_now  +  "_B";
								var MEDIA_str_var_P =  "MEDIA_"  +  SL_NAME_now  +  "_P";
								var MEDIA_str_var_C =  "MEDIA_"  +  SL_NAME_now  +  "_C";
								*/
								//---



								/*
								aux_new_obj_TRAMA.timestamp = ;

								aux_new_obj_TRAMA.epoch = ;

								aux_new_obj_TRAMA[ str_var_B ] =  1.0;
								aux_new_obj_TRAMA[ str_var_P ] =  1.0;
								aux_new_obj_TRAMA[ str_var_C ] =  1.0;


								aux_new_obj_TRAMA.NEW_timestamp = ;

								aux_new_obj_TRAMA[ MEDIA_str_var_B ] =  1.0;
								aux_new_obj_TRAMA[ MEDIA_str_var_P ] =  1.0;
								aux_new_obj_TRAMA[ MEDIA_str_var_C ] =  1.0;


								c
								*/

								//---

								/*
								var aux_new_OBJ =	{

														SL_NAME: "",

														INSTALLLATION_NAME: "",

														TRAMA: []

													};
								*/

								//-----

								//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ] = [];



								//--------------------------------------------------------------



								var SL_obj =  _ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].data;



									//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs[" + allSLs + "].data[ 0 ].timestamp: "  +  _ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].data[ 0 ].timestamp  +  ",  "  +  new Date( SL_obj[ 0 ].epoch )  );
									//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs[" + allSLs + "].data[ 1 ].timestamp: "  +  _ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].data[ 1 ].timestamp  +  ",  "  +  new Date( SL_obj[ 1 ].epoch )  );
									//console.log(  "_ar_ALL__STREETLIGHTS_VARVALs[" + allSLs + "].data[ 2 ].timestamp: "  +  _ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].data[ 2 ].timestamp  +  ",  "  +  new Date( SL_obj[ 2 ].epoch )  );



									//SL_obj[ 0 ].timestamp



								//-------------------------------------------------------------------------------------------------------------------
								//-------------------------------------------------------------------------------------------------------------------


								// SI VAMOS A GENERAR POSICIONES TOTALES Y FIJAS EN LAS GRÁFICAS PARA "RELLENAR" A POSTERIORI CON LOS DATOS REALES (EN PRINCIPIO, "LOS BUENOS", CADA 10 MIN)

								// ENTONCES LOS INTERVALOS DE TIEMPO SON CADA 10 MIN DESDE EL TIMESTAMP DE COMIENZO DE SEMANA

								// NECESITAMOS CALCULAR EL TOTAL DE INTERVALOS POR SEMANA  ---->

									//	10 MIN = ( 10 * 60 )  	 ---->


									//	1 HORA = 6 INTERVALOS CADA HORA		=>

									//	24 HORAS =	24 * 6  =  144 INTERVALOS CADA DIA		=>

									//	1 SEMANA =	7 * 144 = 1008 INTERVALOS A LA SEMANA


								const const_intervs =  (  6 * 24 * 7  );	//   1008


								for (  let iweek = 0;  iweek  <  NUM_SEMANAS_mes;  iweek++  )
								{
									/*
									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat =  Array(  const_intervs  );	//   1008


									for (  let interv = 0;  interv  <  const_intervs;  interv++  )
									{

										let auxtimestamp =  timesstamp_END_WEEK[ iweek ]  	+  	(  ( 10 * 60 )  *  interv  );



										//var aux_STR_dato_timestamp =  SL_obj_DAT.timestamp;		//aux_new_obj_TRAMA.timestamp;		//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ][ i_dat_SL ].tiemstamp;

										var aux_new_date =  new Date(  auxtimestamp * 1000 );  // MULTIPLICANDO POR 1000 ES COMO CONSEGUIMOS QUE EL DATE SEA EL CORRECTO

										//var aux_NUM_timestamp =  Math.round(  parseInt( aux_new_date.getTime() )  /  1000  );		// round

										// SALEN BIEN LOS RESULTADOS
										//console.log( "auxtimestamp: "  +  auxtimestamp  +  ";  aux_new_date: " +  aux_new_date );


										let str_date_auxtimestamp =  aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  "";

										let str_date_auxtimestamp_COMPLETE =  aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  ", "  +  aux_new_date.getHours()  +  ":"  +  aux_new_date.getMinutes();

										// SALEN BIEN LOS RESULTADOS
										//console.log(  str_date_auxtimestamp_COMPLETE  );

										//----

										_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  interv  ] =	{
																																	timestamp:					auxtimestamp,				//aux_NUM_timestamp,		//aux_NUM_timestamp,

																																															//aux_STR_dato_timestamp,  //"",


																																	NEW_timestamp_DAY:			str_date_auxtimestamp,		// + 1

																																															//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA

																																	NEW_timestamp_COMPLETE:		str_date_auxtimestamp_COMPLETE,



																																	ES_DATO_RECIBIDO:			false,


																																	//epoch: 						aux_epoch,

																																	[ str_var_B ]: 				0.0,		//SL_obj_DAT[ str_var_B ],
																																	[ str_var_P ]:				0.0,		//SL_obj_DAT[ str_var_P ],
																																	[ str_var_C ]:				0.0			//SL_obj_DAT[ str_var_C ]//,

																																};
										//----------------------------

										// (TODO ESTE BLOQUE, COMENTADO)
										// POR SI ACASO:

										//aux_new_obj_TRAMA.timestamp =  aux_SUM_NUM_timestamp;

										//aux_new_obj_TRAMA.NEW_timestamp_DAY =  aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ] )  +  "";		//  +1

										//aux_new_obj_TRAMA[ str_var_B ] =  aux_SUM_var_B;
										//aux_new_obj_TRAMA[ str_var_P ] =  aux_SUM_var_P;
										//aux_new_obj_TRAMA[ str_var_C ] =  aux_SUM_var_C;




									}// for : interv ------------------
									*/
									//--------------------------------------------------------------------------------




									// CORRRECTO
									//window.alert(   _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ 0 ].dat[  0  ].NEW_timestamp_COMPLETE   );


									/*
									-- YA COMENTADO DE ANTES, EN PRINCIPIO, NO QUITAR EL COMENTADO  !!!!!

									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  undefined;
									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  null;

									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  {};		//[];

									//-------

									_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =	{
																												SL_NAME:	SL_NAME_now,



																												indice_INSTAL__objINST_GROUP:  -1,	// INDICE DE INSTALACION DENTRO DE OBJ INSTALLATION GROUP (PARA RECOGER LOS DATOS DE INSTALLATION DEDSDE OBJ INSTALLATION GROUP)

																												indice_SL__objINST_GROUP:  -1,		// INDICE DE SL DENTRO DE SLS EN OBJ INSTALLATION GROUP (PARA REFERENCIARLO DESDE OBJ INSTALLATION GROUP)

																												ES_MASTER:  false,					// bool MASTER



																												dat: [],



																												dat_DIV_DaysWeek: [],

																											};
									*/

									//----------------

									/*
									var aux_new_obj_TRAMA =	{
																timestamp:					aux_SUM_NUM_timestamp,		//aux_NUM_timestamp,		//aux_NUM_timestamp,

																							//aux_STR_dato_timestamp,  //"",


																NEW_timestamp_DAY:			aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  "",		// + 1

																						//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA


																//epoch: 						aux_epoch,

																[ str_var_B ]: 				aux_SUM_var_B,		//SL_obj_DAT[ str_var_B ],
																[ str_var_P ]:				aux_SUM_var_P,		//SL_obj_DAT[ str_var_P ],
																[ str_var_C ]:				aux_SUM_var_C,		//SL_obj_DAT[ str_var_C ]//,



																//[ MEDIA_str_var_B ]:		SL_obj[ i_dat_SL ][ str_var_B ],
																//[ MEDIA_str_var_P ]:		SL_obj[ i_dat_SL ][ str_var_P ],
																//[ MEDIA_str_var_C ]:		SL_obj[ i_dat_SL ][ str_var_C ]
															};
									*/

									//---------------------------------


									/*
									// POR SI ACASO:

									aux_new_obj_TRAMA.timestamp =  aux_SUM_NUM_timestamp;

									aux_new_obj_TRAMA.NEW_timestamp_DAY =  aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ] )  +  "";		// + 1

									aux_new_obj_TRAMA[ str_var_B ] =  aux_SUM_var_B;
									aux_new_obj_TRAMA[ str_var_P ] =  aux_SUM_var_P;
									aux_new_obj_TRAMA[ str_var_C ] =  aux_SUM_var_C;


									//console.log( aux_new_obj_TRAMA  );
									//console.log( SL_obj[ i_dat_SL ] );



									//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ][ i_dat_SL ] =  aux_new_obj_TRAMA;		 //.push(  aux_new_obj_TRAMA   );

									//-----------------------------------------

									//console.log( "aux_dato_timestamp:  "  +  aux_dato_timestamp  +  " -> " +  aux_NUM_timestamp  +  " ... ini: " + TIMESTAMP__FIRST_day_MONTH );

									*/ //------------------------------------

											/*

											START_END_days_week_DATE[ 0 ] =  new Date( our_monday_date );

											START_END_days_week_DATE[ 0 ].setHours( 0 );
											START_END_days_week_DATE[ 0 ].setMinutes( 0 );
											START_END_days_week_DATE[ 0 ].setSeconds( 1 );


											START_END_days_week_TIMESTAMP[ 0 ] =  Math.round(  parseInt( START_END_days_week_DATE[ 0 ].getTime() )  /  1000  );
											*/



									/*
									var aux_bool_week_search =  true;	// PARA QUE SALGA ANTES DEL FOR



									for (  let iweek = 0;  ( iweek  <=  ( NUM_SEMANAS_mes - 1 ) )  &&  (  aux_bool_week_search == true  );  iweek++  )
									{
										//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  [];




										if (
											(  aux_SUM_NUM_timestamp  >=  timesstamp_END_WEEK[ iweek ]  )  &&
											(  aux_SUM_NUM_timestamp  <   timesstamp_END_WEEK[ iweek + 1 ]  )

											//(  aux_NUM_timestamp  >=  timesstamp_END_WEEK[ iweek ]  )  &&
											//(  aux_NUM_timestamp  <   timesstamp_END_WEEK[ iweek + 1 ]  )
										)
										{
											//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ allSLs ].name =  	_ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].schema.fields[ 2 ].name.split( "_" )[ 0 ];

											_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ allSLs ].dat.push(
																														aux_new_obj_TRAMA		//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ][ i_dat_SL ]
											);//.push ------------




											aux_bool_week_search =  false;		// PARA QUE SALGA ANTES DEL FOR


											//console.log( "aux_dato_timestamp:  "  +  aux_dato_timestamp  +  " -> " +  aux_NUM_timestamp  +  " ... ini: " + TIMESTAMP__FIRST_day_MONTH );

										}// if --------------------------------------------------

									}// for
									*/




								}// for : iweek ----------------------------------------------------------------



								//-------------------------------------------------------------------------------------------------------------------
								//-------------------------------------------------------------------------------------------------------------------


								// AQUI, AHORA, ENTRE OTRAS COSAS, VAMOS A HACER LO DE COGER LOS DATOS DE 1 EN 1, 2 EN 2, O 3 EN 3, REALIZANDO LAS MEDIAS PERTINENTES EN EL CASO REQUERIDO:



								//aux_SUM_epoch = 0;


								aux_SUM_var_B  =  0.0;
								aux_SUM_var_P  =  0.0;
								aux_SUM_var_C  =  0.0;


								aux_SUM_NUM_timestamp = 0;


								//-----


								if ( GLOBAL_SIG_dat_SL_week  >=  2  )
								{

									for ( let iaux = 0;  iaux  <  ( GLOBAL_SIG_dat_SL_week - 1 );  iaux++  )
									{
										aux_SUM_var_B  +=  SL_obj[ 0 ][ str_var_B ];	//  SL_obj[  0  = i_dat_SL][ str_var_B ];
										aux_SUM_var_P  +=  SL_obj[ 0 ][ str_var_P ];
										aux_SUM_var_C  +=  SL_obj[ 0 ][ str_var_C ];

										aux_SUM_NUM_timestamp  +=  Math.round(   /*parseInt*/(  (new Date( SL_obj[ 0 ].timestamp )).getTime()  )  /  1000   );
									}// for

								}// if


								//-----------------------------------


								for (  let i_dat_SL = 0;  i_dat_SL  <  SL_obj.length;  i_dat_SL ++   )			// cada +2 = cada 20 min (72 al día)....  cada +3 = cada 30 min (48 al día)  ... cada +6, = cada hora (24 al día)
								{

									//console.log( "i_dat_SL:  "  +  i_dat_SL  );


									var SL_obj_DAT =  SL_obj[ i_dat_SL ];


									// MAXES

									if (  SL_obj_DAT[ str_var_B ]  >  MAX_INST_GROUP__VARS_BP   )
									{
										MAX_INST_GROUP__VARS_BP =  SL_obj_DAT[ str_var_B ];
									}//

									if (  SL_obj_DAT[ str_var_P ]  >  MAX_INST_GROUP__VARS_BP   )
									{
										MAX_INST_GROUP__VARS_BP =  SL_obj_DAT[ str_var_P ];
									}//



									if (  SL_obj_DAT[ str_var_C ]  >  MAX_INST_GROUP__VARS_C   )
									{
										MAX_INST_GROUP__VARS_C =  SL_obj_DAT[ str_var_C ];
									}//


									//--


									var aux_STR_dato_timestamp =  SL_obj_DAT.timestamp;		//aux_new_obj_TRAMA.timestamp;		//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ][ i_dat_SL ].tiemstamp;
									var aux_new_date =  new Date(  aux_STR_dato_timestamp  );

									var aux_NUM_timestamp =  Math.round(  parseInt( aux_new_date.getTime() )  /  1000  );		// round


									//--


									if ( GLOBAL_SIG_dat_SL_week  >=  2  )
									{
										aux_SUM_var_B  +=  SL_obj_DAT[ str_var_B ];
										aux_SUM_var_P  +=  SL_obj_DAT[ str_var_P ];
										aux_SUM_var_C  +=  SL_obj_DAT[ str_var_C ];

										aux_SUM_NUM_timestamp  +=  aux_NUM_timestamp;
									}// if



									//----------------------------------------------


									if (   (  i_dat_SL  %  GLOBAL_SIG_dat_SL_week  )  ==  0  )		//( i_dat_SL % 2 )  ===  0 )
									{


										if ( GLOBAL_SIG_dat_SL_week  >=  2  )
										{

											aux_SUM_var_B  =  parseFloat(  aux_SUM_var_B  /  GLOBAL_SIG_dat_SL_week  );
											aux_SUM_var_P  =  parseFloat(  aux_SUM_var_P  /  GLOBAL_SIG_dat_SL_week  );
											aux_SUM_var_C  =  parseFloat(  aux_SUM_var_C  /  GLOBAL_SIG_dat_SL_week  );

											aux_SUM_NUM_timestamp  =  Math.round(  parseFloat( aux_SUM_NUM_timestamp  /  GLOBAL_SIG_dat_SL_week )  );

											/*
											aux_SUM_var_B  =  SL_obj_DAT[ str_var_B ];
											aux_SUM_var_P  =  SL_obj_DAT[ str_var_P ];
											aux_SUM_var_C  =  SL_obj_DAT[ str_var_C ];

											aux_SUM_NUM_timestamp =  aux_NUM_timestamp;
											*/
										}
										else
										{

											aux_SUM_var_B  =  SL_obj_DAT[ str_var_B ];
											aux_SUM_var_P  =  SL_obj_DAT[ str_var_P ];
											aux_SUM_var_C  =  SL_obj_DAT[ str_var_C ];

											aux_SUM_NUM_timestamp =  aux_NUM_timestamp;

										} //




										//-------

										//var aux_epoch =  Math.floor(  /*parseInt*/( SL_obj_DAT.epoch )  /  1000  );


										var aux_new_obj_TRAMA =	{
																	timestamp:					aux_SUM_NUM_timestamp,		//aux_NUM_timestamp,		//aux_NUM_timestamp,

																								//aux_STR_dato_timestamp,  //"",


																	NEW_timestamp_DAY:			aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ] /*+ 1*/ )  +  "",

																							//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA


																	//epoch: 						aux_epoch,

																	[ str_var_B ]: 				aux_SUM_var_B,		//SL_obj_DAT[ str_var_B ],
																	[ str_var_P ]:				aux_SUM_var_P,		//SL_obj_DAT[ str_var_P ],
																	[ str_var_C ]:				aux_SUM_var_C,		//SL_obj_DAT[ str_var_C ]//,



																	//[ MEDIA_str_var_B ]:		SL_obj[ i_dat_SL ][ str_var_B ],
																	//[ MEDIA_str_var_P ]:		SL_obj[ i_dat_SL ][ str_var_P ],
																	//[ MEDIA_str_var_C ]:		SL_obj[ i_dat_SL ][ str_var_C ]
																};


										// POR SI ACASO:

										aux_new_obj_TRAMA.timestamp =  aux_SUM_NUM_timestamp;

										aux_new_obj_TRAMA.NEW_timestamp_DAY =  aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ] /*+ 1*/ )  +  "";

										aux_new_obj_TRAMA[ str_var_B ] =  aux_SUM_var_B;
										aux_new_obj_TRAMA[ str_var_P ] =  aux_SUM_var_P;
										aux_new_obj_TRAMA[ str_var_C ] =  aux_SUM_var_C;


										//console.log( aux_new_obj_TRAMA  );
										//console.log( SL_obj[ i_dat_SL ] );





										//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ][ i_dat_SL ] =  aux_new_obj_TRAMA;		 //.push(  aux_new_obj_TRAMA   );




										//-----------------------------------------





										//console.log( "aux_dato_timestamp:  "  +  aux_dato_timestamp  +  " -> " +  aux_NUM_timestamp  +  " ... ini: " + TIMESTAMP__FIRST_day_MONTH );



										/*

										START_END_days_week_DATE[ 0 ] =  new Date( our_monday_date );

										START_END_days_week_DATE[ 0 ].setHours( 0 );
										START_END_days_week_DATE[ 0 ].setMinutes( 0 );
										START_END_days_week_DATE[ 0 ].setSeconds( 1 );


										START_END_days_week_TIMESTAMP[ 0 ] =  Math.round(  parseInt( START_END_days_week_DATE[ 0 ].getTime() )  /  1000  );
										*/




										var aux_bool_week_search =  true;	// PARA QUE SALGA ANTES DEL FOR



										for (  let iweek = 0;  ( iweek  <= ( NUM_SEMANAS_mes - 1 ) )  &&  (  aux_bool_week_search == true  );  iweek++  )  // <= 4
										{
											//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  [];




											if (
												(  aux_SUM_NUM_timestamp  >=  timesstamp_END_WEEK[ iweek ]  )  &&
												(  aux_SUM_NUM_timestamp  <   timesstamp_END_WEEK[ iweek + 1 ]  )

												//(  aux_NUM_timestamp  >=  timesstamp_END_WEEK[ iweek ]  )  &&
												//(  aux_NUM_timestamp  <   timesstamp_END_WEEK[ iweek + 1 ]  )
											)
											{
												//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ allSLs ].name =  	_ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].schema.fields[ 2 ].name.split( "_" )[ 0 ];

												_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ allSLs ].dat.push(
																															aux_new_obj_TRAMA		//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ allSLs ][ i_dat_SL ]
												);//.push ------------


												//console.log( /*"aux_epoch: "  + aux_epoch +  " / SL_obj_DAT.epoch = " + aux_new_obj_TRAMA.epoch +*/ " /  aux_NUM_timestamp = "  +  aux_NUM_timestamp  +  " / aux_dato_timestamp = " + aux_dato_timestamp  );



												aux_bool_week_search =  false;		// PARA QUE SALGA ANTES DEL FOR


												//---------------------------------------------


												//let ind_interv =  0;


												let ts_ref =  aux_new_obj_TRAMA.timestamp;	// DATO "REAL"

												// TENEMOS QUE SITUAR EL DATO REAL EN SU INTERVALO QUE CORRESPONDA


												let ts_0 =   0;
												let ts_1 =   0;

												let DELTA_interv_timestamp 	   =  0;
												let DELTA_interv_timestamp_SIG =  0;

												let busqueda_interval =  false;

												/*
																	// ( intv <=  ( const_intervs - 2 ) )
												for (  let intv =  0;  ( intv <  ( const_intervs - 1 ) )  &&  ( busqueda_interval == false );  intv++  )	// 1008				//	intv <  ( const_intervs - 1 )
												{

													ts_0 =   _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ].timestamp;
													ts_1 =   _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  +  1 ].timestamp;


													if (
															(  ts_ref  >=  ts_0  )  &&
															(  ts_ref  <   ts_1  )
													)
													{
														busqueda_interval =  true;

														//----

														//DELTA_interv_timestamp 	   =  Math.abs(  ts_0  -  ts_ref  );
														//DELTA_interv_timestamp_SIG =  Math.abs(  ts_1  -  ts_ref  );


														//if (  DELTA_interv_timestamp  <  DELTA_interv_timestamp_SIG  )
														//{

															_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ][ str_var_B ] =  aux_new_obj_TRAMA[ str_var_B ];
															_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ][ str_var_P ] =  aux_new_obj_TRAMA[ str_var_P ];
															_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ][ str_var_C ] =  aux_new_obj_TRAMA[ str_var_C ];

															_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ].ES_DATO_RECIBIDO =		true;

														//}// if



														// YA COMENTADO DE ANTES, NO QUITAR A PRIORI EL COMENTADO !!!!
														//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  interv  ] =	{
														//																							timestamp:					auxtimestamp,				//aux_NUM_timestamp,		//aux_NUM_timestamp,

																																																			//aux_STR_dato_timestamp,  //"",


														//																							NEW_timestamp_DAY:			str_date_auxtimestamp,		// + 1

																																																			//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA

														//																							NEW_timestamp_COMPLETE:		str_date_auxtimestamp_COMPLETE,

																																					//epoch: 						aux_epoch,

														//																							[ str_var_B ]: 				0.0,		//SL_obj_DAT[ str_var_B ],
														//																							[ str_var_P ]:				0.0,		//SL_obj_DAT[ str_var_P ],
														//																							[ str_var_C ]:				0.0			//SL_obj_DAT[ str_var_C ]//,

														//																						};

													}// if


												}// for : intv --------------------
												*/

												//--

												// ULTIMA POSICION:

												//--- CONTINUACIÓN DEL FOR PARA ÚLTI POSICION

												//ts_0 =   _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ( const_intervs - 1 ) ].timestamp;
																//ts_1 =   _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[   ].timestamp;
																// YA COMENTADO DE ANTES, A PRIORI, NO QUITAR EL COMENTADO !!!


												/*
												if (
														(  ts_ref  >=  ts_0  )  &&
														(  ts_ref  <   timesstamp_END_WEEK[ iweek + 1 ]  )
												)
												{
													//----

													//DELTA_interv_timestamp 	   =  Math.abs(  ts_0  -  ts_ref  );
													//DELTA_interv_timestamp_SIG =  Math.abs(  ts_1  -  ts_ref  );


													//if (  DELTA_interv_timestamp  <  DELTA_interv_timestamp_SIG  )
													//{

														_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ( const_intervs - 1 )  ][ str_var_B ] =  aux_new_obj_TRAMA[ str_var_B ];
														_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ( const_intervs - 1 )  ][ str_var_P ] =  aux_new_obj_TRAMA[ str_var_P ];
														_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ( const_intervs - 1 )  ][ str_var_C ] =  aux_new_obj_TRAMA[ str_var_C ];

														_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ( const_intervs - 1 )  ].ES_DATO_RECIBIDO =		true;

													//}// if



													// YA COMENTADO DE ANTES, A PRIORI, NO QUITAR EL COMENTADO !!!!
													//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  interv  ] =	{
													//																							timestamp:					auxtimestamp,				//aux_NUM_timestamp,		//aux_NUM_timestamp,

																																																		//aux_STR_dato_timestamp,  //"",


													//																							NEW_timestamp_DAY:			str_date_auxtimestamp,		// + 1

																																																		//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA

													//																							NEW_timestamp_COMPLETE:		str_date_auxtimestamp_COMPLETE,

																																				//epoch: 						aux_epoch,

													//																							[ str_var_B ]: 				0.0,		//SL_obj_DAT[ str_var_B ],
													//																							[ str_var_P ]:				0.0,		//SL_obj_DAT[ str_var_P ],
													//																							[ str_var_C ]:				0.0			//SL_obj_DAT[ str_var_C ]//,

													//																						};

												}// if
												*/

															// YA COMENTADO DE ANTES, A PRIORI, NO QUITAR EL COMENTADO !!!!!!
															// _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ind_interv  ].timestamp

												//console.log( "aux_dato_timestamp:  "  +  aux_dato_timestamp  +  " -> " +  aux_NUM_timestamp  +  " ... ini: " + TIMESTAMP__FIRST_day_MONTH );

											}// if --------------------------------------------------


											//-------------------------------------------------------------------------------------------------------




										}// for : iweek -----------------------------------------------------------------------



										//---


										//aux_new_obj_TRAMA = undefined;	//undefined;		// LIBERAMOS MEMORIA
										//aux_new_obj_TRAMA = null;	//undefined;		// LIBERAMOS MEMORIA


										//---


										aux_SUM_var_B  =  0.0;
										aux_SUM_var_P  =  0.0;
										aux_SUM_var_C  =  0.0;

										aux_SUM_NUM_timestamp =  0;


									}// if (  ( i_dat_SL % 2 )  ==  0 )  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------]







									//SL_obj[ i_dat_SL ] = undefined;	// LIBERAR MEMORIA
									//SL_obj[ i_dat_SL ] = null;	// LIBERAR MEMORIA

									//SL_obj[ i_dat_SL ] = {};



								}// for : i_dat_SL -------------------------------------------------------------------------


								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------




								// AHORA DEBEMOS REVISITAR Y REVISAR LOS DATOS ANTES DISPUESTOS EN "_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK" Y DETERMINAR SI TIENEN GAPS O NO (SERÁ "GAP" SI HAY ALGÚN HUECO DESDE ÚLTIMO DATO REAL QUE SE OBTUVO Y SE HARÁ APROX LINEAL.
								// 		SI AL MENOS HAY 6 HUECOS, SE DEJARÁN YA A '0' O VALOR DESEADO SIN HACER MEDIAS)



								var pos_gap_FIRST = -1;
								var pos_gap_LAST = -1;

								var cont_gap = 0;


								// const_NUM_MAX_huecos_GAP  // 5

									// COMENTADO DE ANTES, A PRIORI, NO QUITAR COMENTADO !!!!
									// _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ].ES_DATO_RECIBIDO =		true;


								//allSLs


									// COMENTADO DE ANTES, A PRIORI, NO QUITAR COMENTADO !!!!

								//for (  let iweek = 0;  iweek  <  NUM_SEMANAS_mes;  iweek++  )
								//{
								//
								//	let intv = 0;
								//
								//	//----
								//
								//	while 	(
								//				( intv  <  const_intervs )
								//			)
								//	{
								//
								//		//-------
								//
								//		if (  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ].ES_DATO_RECIBIDO  ==  false  )
								//		{
								//
								//			pos_gap_FIRST = intv;
								//
								//
								//			let aux_intv = pos_gap_FIRST;  // intv
								//
								//
								//			//-------------
								//
								//
								//				// SOBREPASA EL FINAL / LIMITE DEL ARRAY DE INTERVALOS
								//			if (  (  pos_gap_FIRST  +  const_NUM_MAX_huecos_GAP   )  >=  ( const_intervs + 1 )  )	//  >=  !!
								//			{
								//
								//				// VER SI ALGUNO DE LOS < "const_NUM_MAX_huecos_GAP" HUECOS QUE HAY HASTA EL FINAL TIENE UN ".ES_DATO_RECIBIDO = TRUE"
								//				/*
								//				pos_gap_LAST =  const_intervs - 1;  // ULTIMA POSICIÓN POSIBLE DE LOS INTERVALOS
								//
								//				//-------
								//
								//				while	(
								//							(  aux_intv  <=    )  &&
								//
								//							(  aux_intv  <  ( pos_gap_FIRST + const_NUM_MAX_huecos_GAP )  )  &&
								//
								//							(  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  aux_intv  ].ES_DATO_RECIBIDO  ==  false  )
								//						)
								//				{
								//					aux_intv++;
								//
								//				}// while
								//
								//				*/
								//			}
								//			else //  if (  (  pos_gap_FIRST  +  const_NUM_MAX_huecos_GAP   )  <=  const_intervs  )
								//			{
								//
								//
								//
								//				// LOCALIZAR CUANDO SERÍA EL SIGUIENTE PERO CON "TRUE" Y ASÍ HACER ESA POS - 1
								//
								//
								//
								//				while	(
								//							(  aux_intv  <  const_intervs  )  &&	// QUE NO NOS SALGAMOS DEL TAMAÑO MÁXIMO TOTAL  (AUNQUE CUANDO ENTRÓ A ÉSTE IF-PADRE YA SE VE QUE NUNCA SE IBA A SALIR DEL TAMAÑO MÁXIMO TOTAL)
								//
								//							//----
								//
								//							(  aux_intv  <  ( pos_gap_FIRST + const_NUM_MAX_huecos_GAP )  )  &&		// SÓLO _RECORREREMOS_ "const_NUM_MAX_huecos_GAP" POSICIONES DESDE "pos_gap_FIRST"
								//
								//							(  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  aux_intv  ].ES_DATO_RECIBIDO  ==  false  )
								//						)
								//				{
								//					aux_intv++;
								//
								//				}// while -----
								//
								//
								//				//-----------
								//
								//
								//				if (  aux_intv  ==  const_intervs  )
								//				{
								//
								//					pos_gap_LAST =  const_intervs - 1;
								//
								//					// RELLENAMOS DESDE "pos_gap_FIRST" HASTA "pos_gap_LAST" CON EL VALOR DE "pos_gap_FIRST" (YA QUE ENTREMEDIAS NO HAY NINGÚN DATO CON VALOR DE DATO REAL)
								//
								//					for (  let ig = pos_gap_FIRST;  ig <=  pos_gap_LAST;  ig++  )
								//					{
								//						_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ig  ][ str_var_B ] =  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  pos_gap_FIRST  ][ str_var_B ];  // SE SOBREESCRIBE TAMBIEN "pos_gap_FIRST" PERO COMO ES LO MISMO, NO PASARÍA NADA
								//						_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ig  ][ str_var_P ] =  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  pos_gap_FIRST  ][ str_var_P ];
								//						_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  ig  ][ str_var_C ] =  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  pos_gap_FIRST  ][ str_var_C ];
								//
								//					}// for : ig
								//
								//
								//				}//
								//				/*
								//				else // (  aux_intv  <  const_intervs  )
								//				{
								//					//if (  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  aux_intv  ].ES_DATO_RECIBIDO  ==  true  )
								//					//{
								//					//
								//					//}
								//
								//					//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ][ str_var_B ] =  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  pos_gap_FIRST  ][ str_var_B ];  // SE SOBREESCRIBE TAMBIEN "pos_gap_FIRST" PERO COMO ES LO MISMO, NO PASARÍA NADA
								//				}// else
								//				*/
								//
								//
								//
								//				/*
								//				if (  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  aux_intv  ].ES_DATO_RECIBIDO  ==  true   )
								//				{
								//
								//
								//				}
								//				else
								//				{
								//
								//				} // else
								//				*/
								//
								//
								//
								//			}// else
								//
								//			//-----
								//
								//
								//
								//		} //
								//		else
								//		{
								//
								//			// if (  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat[  intv  ].ES_DATO_RECIBIDO  ==  true  )
								//
								//			intv ++;
								//
								//		}// else
								//
								//
								//
								//	}// while () -----------
								//
								//	//--------
								//
								//
								//}// for : iweek -----------------------------






								//-----------------------------------------------------------------------------------
								//-----------------------------------------------------------------------------------

								//-------------------------------------------------------------------------------------------------------------------
								//-------------------------------------------------------------------------------------------------------------------


								// FINALMENTE ESTO NO NOS HA SERVIDO DE MUCHO


								/*
							 	//A PARTIR DE LA INFO ANTERIOR  YA ALMACENADA EN .dat[], vamos a rellenar .dat_DIV_DaysWeek[ iweek ][ allSLs ] consecuentemente a los días que contenga esa semana
								//  dat_DIV_DaysWeek

								for (  let iweek =  0;  iweek <=  3;  iweek++  )
								{
									for (  let  isl = 0;   isl <  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ].length;   isl++  )
									{

										//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ isl ].dat


										for (  let iday = 0;  iday <  7;  iday++  )
										{
											_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ isl ].dat_DIV_DaysWeek[  iday  ] =	{
																																			day_MARGIN_aux_dat:  50
																																		};
										}// for : iday


									}// for : isl

								}// for : iweek
								*/

								//----------------------








								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------








								// array horas día, tanto como días mes


								// recorremos el dat de SLobj y vamos comprobando en que día entra (si tiene corriente superior a COTA MIN) y sumamos (10 min = 1/6 de hora).... sumar en min ... luego operación para transformar en hora

								// se guarda en array minutos por día (que se inicializó a 0, claro)

								// precalc el array de días con sus cotas min y max de timestamp o ... bueno, se puede hacer sobre la marcha (deentro otro for o while con escape booleano)



								// a su vez, si ésta dentro de cota de C, multiplicamos sus valores de C y B  y tiempo en horas( de lo que corresponda a ESE DATO CONCRETO).. y sumamos al sumatoiro a tal efecto también






									// ACUERDATE PONER EN CHROME TAMBIÉN LA NUEVA URL Y QUITAR LA ANTERIOR  ..................................  HECHO



								/*var*/ ar_Minutos_Dia__AllSLs =  new Array(  /*NUM_DAYS_MONTH*/  );

								/*var*/ ar_Ahorro_Dia__AllSLs  =  new Array(  /*NUM_DAYS_MONTH*/  );  // a la hora



								for (  let im = 0;  im  <  NUM_DAYS_MONTH;  im++  )
								{
									ar_Minutos_Dia__AllSLs.push( 0 );		//[  im  ] =  0;

									ar_Ahorro_Dia__AllSLs.push(  0.0  );	//[  im  ] =  parseFloat(  0.0  );

								}// for : im --------------


								//---------------------------------------
								//---------------------------------------


								var aux_SUM_var_C  =  parseFloat( 0.0 );

								var aux_SUM_var_B  =  parseFloat( 0.0 );
								var aux_SUM_var_P  =  parseFloat( 0.0 );

								var aux_SUM_NUM_timestamp = 0;

								//--------


								//var SL_obj =  _ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].data;  ...........    pillar así a pelo, a uno a uno, creo que va ser mejor..... luego si eso haces con interpolación


								/*
								console.log( "my_SL_Array_length: " );
								console.log( my_SL_Array_length );


								console.log(  "_ar_ALL__STREETLIGHTS_VARVALs: "  );
								console.log(  _ar_ALL__STREETLIGHTS_VARVALs  );
								*/



								for (  let miSL = 0;  miSL  <  my_SL_Array_length /*_obj_Inst_Group.info.streetlights.length*/;  miSL++  )
								{
									//var

									const SL_obj =  _ar_ALL__STREETLIGHTS_VARVALs[  miSL  ].data;

									//----


									aux_SUM_var_C  =  parseFloat( 0.0 );

									aux_SUM_var_B  =  parseFloat( 0.0 );
									aux_SUM_var_P  =  parseFloat( 0.0 );


									aux_SUM_NUM_timestamp = 0;


									//-----


									if ( GLOBAL_SIG_dat_SL_week  >=  2  )
									{
										const const_pos_0 =  0;

										for (  let iaux = 0;  iaux  <  (  GLOBAL_SIG_dat_SL_week  -  1  );  iaux++  )
										{
											aux_SUM_var_C  +=  SL_obj[ const_pos_0 ][ str_var_C ];

											aux_SUM_var_B  +=  SL_obj[ const_pos_0 ][ str_var_B ];	//  SL_obj[  0  = i_dat_SL][ str_var_B ];
											aux_SUM_var_P  +=  SL_obj[ const_pos_0 ][ str_var_P ];


											aux_SUM_NUM_timestamp  +=  Math.round(   parseInt(  (new Date( SL_obj[ const_pos_0 ].timestamp )).getTime()  )  /  1000   );			//  parseInt
										}// for

									}// if ( GLOBAL_SIG_dat_SL_week  >=  2  )






									//------------------------------------



									//TS__STR__FIRST_day_MONTH =  (    (  new Date( STR__FIRST_day_MONTH )  ).getTime()  /  1000    );
									//TS__STR__LAST_day_MONTH  =  (    (  new Date( STR__LAST_day_MONTH  )  ).getTime()  /  1000    );



									//console.log(  TIMESTAMP__FIRST_day_MONTH  );	// 1 654 034 399	segundos
									//console.log(  TS__STR__FIRST_day_MONTH  );		// 1 654 034 400	segundos



									for (  let idat = 0;  idat  <  SL_obj.length;  idat++  )  //----------------------
									{

										const SL_dat =  SL_obj[  idat  ];		//_ar_ALL__STREETLIGHTS_VARVALs[ miSL ].data[  idat  ];

										//--

										const SL_NAME_now =  _ar_ALL__STREETLIGHTS_VARVALs[  miSL  ].schema.fields[ 2 ].name.split( "_B" )[ 0 ];		// .split( "_" )

										//_ar_ALL__STREETLIGHTS_VARVALs[ allSLs ].schema.fields[ 2 ].name.split( "_" )[ 0 ]
										//console.log(  "SL NAME from _ar_ALL__STREETLIGHTS_VARVALs: \n"  +  JSON.stringify(   SL_NAME_now, null, "	" )   );


										const str_var_C =  SL_NAME_now  +  "_C";

										const str_var_B =  SL_NAME_now  +  "_B";
										const str_var_P =  SL_NAME_now  +  "_P";

										//----


										var v_C =  parseFloat(  SL_dat[  str_var_C  ]  );

										//console.log( "que pasa" );
										//console.log( v_C );

										var v_B =  parseFloat(  SL_dat[  str_var_B  ]  );
										var v_P =  parseFloat(  SL_dat[  str_var_P  ]  );



										var aux_STR_dato_timestamp =  SL_dat.timestamp;				//  2022-06-30T23:50:00.000
										var aux_new_date =  new Date(  aux_STR_dato_timestamp  );	// Tue Jun 21 2022 20:10:00 GMT+0200 (hora de verano de Europa central)


										// aux_new_date.getTime()   ...... 1 656 247 800 000  milisegundos

										var aux_NUM_timestamp =  /*Math.round*/(   /*parseInt*/(  aux_new_date.getTime()  )   /   ( /*60 **/ 1000 )   );	// 1 655 854 800  segundos


										var aux_dat_DAY =  String( aux_STR_dato_timestamp ).split( "T" )[ 0 ].split( "-" )[ 2 ];

										//console.log(  aux_dat_DAY  );



										//console.log(  aux_STR_dato_timestamp  );
										//console.log(  aux_new_date  );

										//console.log(  aux_new_date.getTime()  );

										//console.log(  aux_NUM_timestamp  );

										//-----------



										//NEW_timestamp_DAY:			aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ] /*+ 1*/ )  +  "",NEW_timestamp_DAY:			aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ] /*+ 1*/ )  +  "",


										//------


										/*
										const const_timestamp_week =  (  7  *  24  *  60  *  60  );


										var timesstamp_END_WEEK = [];

										timesstamp_END_WEEK[ 0 ] =  TIMESTAMP__FIRST_day_MONTH;	// semana -1

										timesstamp_END_WEEK[ 1 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 1 * const_timestamp_week );
										timesstamp_END_WEEK[ 2 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 2 * const_timestamp_week );
										timesstamp_END_WEEK[ 3 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 3 * const_timestamp_week );
										timesstamp_END_WEEK[ 4 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 4 * const_timestamp_week );

										timesstamp_END_WEEK[ 5 ] =  TIMESTAMP__LAST_day_MONTH;
										*/





										if ( GLOBAL_SIG_dat_SL_week  >=  2  )
										{
											aux_SUM_var_C  +=  v_C;

											aux_SUM_var_B  +=  v_B;
											aux_SUM_var_P  +=  v_P;

											aux_SUM_NUM_timestamp  +=  aux_NUM_timestamp;

										}// if ( GLOBAL_SIG_dat_SL_week  >=  2  )




										//----------------


										if (   (  idat  %  GLOBAL_SIG_dat_SL_week  )  ==  0   )
										{


											if ( GLOBAL_SIG_dat_SL_week  >=  2  )
											{

												aux_SUM_var_C  =  parseFloat(  aux_SUM_var_C  /  GLOBAL_SIG_dat_SL_week  );

												aux_SUM_var_B  =  parseFloat(  aux_SUM_var_B  /  GLOBAL_SIG_dat_SL_week  );
												aux_SUM_var_P  =  parseFloat(  aux_SUM_var_P  /  GLOBAL_SIG_dat_SL_week  );


												aux_SUM_NUM_timestamp  =  Math.round(  parseFloat( aux_SUM_NUM_timestamp  /  GLOBAL_SIG_dat_SL_week )  );

												/*
												aux_SUM_var_B  =  SL_obj_DAT[ str_var_B ];
												aux_SUM_var_P  =  SL_obj_DAT[ str_var_P ];
												aux_SUM_var_C  =  SL_obj_DAT[ str_var_C ];

												aux_SUM_NUM_timestamp =  aux_NUM_timestamp;
												*/
											}
											else
											{
												aux_SUM_var_C  =  v_C;

												aux_SUM_var_B  =  v_B;
												aux_SUM_var_P  =  v_P;

												aux_SUM_NUM_timestamp =  aux_NUM_timestamp;

											}// else -----------------






											//--------------------------------------------------------------------------------------------

											/*
											var cont_Time_day_INI =  0;	// =  TIMESTAMP__FIRST_day_MONTH;
											var cont_Time_day_FIN =  0;	// =  TIMESTAMP__FIRST_day_MONTH;


											const const_ONE_DAY_ts =   24 * 60 * 60;  // segundos



											let aux_bool_scape =  0;

											for (   let iday = 0;   (  iday  <  ( NUM_DAYS_MONTH )  )   &&   (  aux_bool_scape == 0  );   iday++  )
											{


												cont_Time_day_INI =   (   (  const_ONE_DAY_ts  *    iday  )  		+		TS__STR__FIRST_day_MONTH  );		// segundos					 //TIMESTAMP__FIRST_day_MONTH
												cont_Time_day_FIN =   (   (  const_ONE_DAY_ts  *  ( iday + 1 )  )	+		TS__STR__FIRST_day_MONTH   )   -	1;	// segundos


												if (
														(  aux_NUM_timestamp  >=  cont_Time_day_INI  )  &&
														(  aux_NUM_timestamp  <=  cont_Time_day_FIN  )
													)
												{

													if (  v_C  >=  parseFloat( const_cota_Min_Corriente_ENCENDIDO )  )
													{

														// DE MOMENTO SÓLO FUNCIONA CON CONST=1, YA QUE PARA CONST>1 TIENES QUE PROMEDIAR (Y ESQUIVAR) DATOS!!!! (NO LO ESTÁS HACIENDO AHORA)

														ar_Minutos_Dia__AllSLs[  iday  ]  +=	(  GLOBAL_SIG_dat_SL_week  ==  1  )
																								?
																									10
																								:
																									(  10 * GLOBAL_SIG_dat_SL_week  );


														ar_Ahorro_Dia__AllSLs[  iday  ]   +=   parseFloat(
																												(  GLOBAL_SIG_dat_SL_week  ==  1  )
																												?
																													( v_C * v_B )  /  parseFloat(   6.0   )		// 10 min						//  IGUAL ES MEJOR HACER LA MULTI DEL TIEMPO AL FINAL DEL TODO,  NO???
																												:
																													(  parseFloat( GLOBAL_SIG_dat_SL_week )  *  v_C  *  v_B  )  /  parseFloat(   6.0   )		//......
																										);

													}// if (  v_C  >=  const_cota_Min_Corriente_ENCENDIDO  )


													aux_bool_scape =  1;
													break;

												}// if (  ....  )



												//cont_Time_day  +=   const_ONE_DAY_ts;


											}// for (   let iday = 0;   (  iday  <  ( NUM_DAYS_MONTH )  )   &&   (  aux_bool_scape == 0  );   iday++  ) ----------------------------------
											*/



											//--------------------------------------------------------------------





											if (  v_C  >=  parseFloat( const_cota_Min_Corriente_ENCENDIDO )  )
											{
												let our_int_day =  parseInt(  aux_dat_DAY  )  -  1;


												ar_Minutos_Dia__AllSLs[  our_int_day  ]  +=		(  GLOBAL_SIG_dat_SL_week  ==  1  )
																								?
																									10
																								:
																									(  10 * GLOBAL_SIG_dat_SL_week  );


												ar_Ahorro_Dia__AllSLs[  our_int_day  ]   +=   parseFloat(
																												(  GLOBAL_SIG_dat_SL_week  ==  1  )
																												?
																													( v_C * v_B )  /  parseFloat(   6.0   )		// 10 min						//  IGUAL ES MEJOR HACER LA MULTI DEL TIEMPO AL FINAL DEL TODO,  NO???
																												:
																													( parseFloat( GLOBAL_SIG_dat_SL_week )  *  v_C  *  v_B )  /  parseFloat(   6.0   )		//......
																										);

											}// if (  v_C  >=  const_cota_Min_Corriente_ENCENDIDO  )








											//--------------------------------------------------------------------


											aux_SUM_var_B  =  parseFloat( 0.0 );
											aux_SUM_var_P  =  parseFloat( 0.0 );
											aux_SUM_var_C  =  parseFloat( 0.0 );

											aux_SUM_NUM_timestamp =  0;


										}// if (   (  idat  %  GLOBAL_SIG_dat_SL_week  )  ==  0   )





									}// for : idat

								}// for : miSL -----------------------------------------------------------------------------------------------------------------------------------










								//--------------------------------



								//ar_Ahorro_Dia__AllSLs



								//////console.log(  "ar_Minutos_Dia__AllSLs = "  );
								//////console.log(  ar_Minutos_Dia__AllSLs  );



								var ar_Minutos_Dia__MEDIA_SL =  new Array(  NUM_DAYS_MONTH  );
								//var ar_Ahorro_Dia__AllSLs  =  new Array(  NUM_DAYS_MONTH  );  // a la hora


								// MEDIA COMO SI HUBIESE SÓLO 1 FAROLA (POR CADA DÍA MES)

								for (  let im = 0;  im  <  NUM_DAYS_MONTH;  im++  )
								{
									ar_Minutos_Dia__MEDIA_SL[  im  ] =   parseFloat(  ar_Minutos_Dia__AllSLs[  im  ]  /  /*_obj_Inst_Group.info.streetlights.length*/ parseFloat( my_SL_Array_length )   );

									//ar_Ahorro_Dia__AllSLs[  im  ] =  parseFloat(  0.0  );

								}// for : im


								//////console.log(  "ar_Minutos_Dia__AllSLs  /  9  =  ar_Minutos_Dia__MEDIA_SL = "  );
								//////console.log(  ar_Minutos_Dia__MEDIA_SL  );


								//------


								ar_Horas_Dia__MEDIA_SL =  new Array(  NUM_DAYS_MONTH  );
								//var ar_Ahorro_Dia__AllSLs  =  new Array(  NUM_DAYS_MONTH  );  // a la hora



								for (  let im = 0;  im  <  NUM_DAYS_MONTH;  im++  )
								{
									ar_Horas_Dia__MEDIA_SL[  im  ] =   parseFloat(  ar_Minutos_Dia__MEDIA_SL[  im  ]  /  parseFloat( 60.0 )  );

									//ar_Ahorro_Dia__AllSLs[  im  ] =  parseFloat(  0.0  );

								}// for : im


								//////console.log(  "ar_Horas_Dia__MEDIA_SL = "  );
								//////console.log(  ar_Horas_Dia__MEDIA_SL  );



								//--------------------------------------


								SUMATORIO_MINUTOS_TOTALES =  0;


								for (  let im = 0;  im  <  NUM_DAYS_MONTH;  im++  )
								{
									SUMATORIO_MINUTOS_TOTALES  +=  ar_Minutos_Dia__AllSLs[  im  ];

									//ar_Ahorro_Dia__AllSLs[  im  ] =  parseFloat(  0.0  );

								}// for : im --------


								//////console.log(  "SUMATORIO_MINUTOS_TOTALES:  "  +  SUMATORIO_MINUTOS_TOTALES  );


								SUMATORIO_HORAS_TOTALES =  parseFloat(  SUMATORIO_MINUTOS_TOTALES  /  parseFloat(  60.0  )  );

								//////console.log(  "SUMATORIO_HORAS_TOTALES:  "  +  SUMATORIO_HORAS_TOTALES  );


								//----


								ar_Ahorro_Wh_Dia__APROX =  new Array(  NUM_DAYS_MONTH  );


								for (  let im = 0;  im  <  NUM_DAYS_MONTH;  im++  )
								{
									var horas_dia =  ar_Horas_Dia__MEDIA_SL[  im  ];	//(  ar_Minutos_Dia__AllSLs[  im  ]  /  parseFloat( 60.0 )  );

									ar_Ahorro_Wh_Dia__APROX[  im  ] =  ar_Ahorro_Dia__AllSLs[  im  ]  /   parseFloat( horas_dia );		//24.0;		//parseFloat(  horas_dia  );

								}// for : im -------

								//////console.log(  "ar_Ahorro_Wh_Dia__APROX = "  );
								//////console.log(  ar_Ahorro_Wh_Dia__APROX  );



								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
								//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



















								/*
								for (  let iweek = 0;  iweek  <=  4;  iweek++  )
								{
									//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ] =  [];

									console.log( "(SLs)  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[" +  iweek  + "].length_:" +  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek ].length  );

									console.log(  "_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ " +  iweek  + " ][ allSLs ].length:  "  +  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].length  );

								}//for
								*/


								//SL_obj = undefined;		// LIBERAMOS MEMORIA
								//SL_obj = null;	//undefined;		// LIBERAMOS MEMORIA


								//-----


								//_ar_ALL__STREETLIGHTS_VARVALs[ allSLs ] = undefined;
								//_ar_ALL__STREETLIGHTS_VARVALs[ allSLs ] = null;		//undefined;		// LIBERAMOS LA MEMORIA


							}// for : allSLs


							//------------------------------------------------------------------------------------


							//_ar_ALL__STREETLIGHTS_VARVALs = undefined;
							//_ar_ALL__STREETLIGHTS_VARVALs = null;	//undefined;		//[];		// LIBERAMOS LA MEMORIA



							//////console.log( "__ar_ALL__STREETLIGHTS_VARVALs__FINAL.length_:" +  _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  );

							//////console.log( "_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK.length_:" +  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK.length  );




							//-----------------------------------------------------------------------------------
							//-----------------------------------------------------------------------------------


							//////console.log( "---------------------------" );

							// MOSTRAR POR CONSOLA LA CANTIDAD DE DATOS POR SEMANA Y FAROLA

							for (  let  isl = 0;   isl <  my_SL_Array_length;  isl++  )
							{
								//////console.log(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  0  ][ isl ].SL_NAME  );

								//console.log( _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  isl  ].length );

								for (  let iweek =  0;  iweek <=  ( NUM_SEMANAS_mes - 1 );  iweek++  )
								{

									//////console.log( "iweek" + iweek + ":  " +_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  iweek  ][ isl ].dat.length );


								}// for : iweek


								//////console.log( "---------------------------" );
							}// for : isl



							//---------------------------------------------------------------------------



							//--------------------------------------------


							/*
							array COMIENZO NUM TIMESTAMP CADA SEMANA

								array COMIENZO NUM TIMESTAMP CADA DIA DE SEMANA
							*/


							/*
							const const_timestamp_week =  (  7  *  24  *  60  *  60  );

							var timesstamp_END_WEEK = [];

							timesstamp_END_WEEK[ 0 ] =  TIMESTAMP__FIRST_day_MONTH;	// semana -1					// MENOS LA POSICION '0' QUE NO ES END_WEEK, ES START, LAS DEMÁS SON ENDWEEK

							timesstamp_END_WEEK[ 1 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 1 * const_timestamp_week )  +  0;
							timesstamp_END_WEEK[ 2 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 2 * const_timestamp_week )  +  0;
							timesstamp_END_WEEK[ 3 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 3 * const_timestamp_week )  +  0;
							timesstamp_END_WEEK[ 4 ] =  TIMESTAMP__FIRST_day_MONTH	+	( 4 * const_timestamp_week )  +  0;

							timesstamp_END_WEEK[ 5 ] =  TIMESTAMP__LAST_day_MONTH;
							*/
							/*
							if (
								(  aux_NUM_timestamp  >=  timesstamp_END_WEEK[ iweek ]  )  &&
								(  aux_NUM_timestamp  <   timesstamp_END_WEEK[ iweek + 1 ]  )
							)*/


							//--------------------------------------------


							const const_timestamp_DAY =  (  24  *  60  *  60  );	// = 86400


							var timesstamp_END_DAY_week = new Array( /*5*/ NUM_SEMANAS_mes );


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{
								timesstamp_END_DAY_week[ iw ] = new Array( 8 );	// 7 + 1



								// ESTA SÍ QUE ES COMIENZO DE DÍA, MIRAR EL RESTO DE COMENTARIOS

								timesstamp_END_DAY_week[ iw ][ 0 ] =  timesstamp_END_WEEK[ iw ];	// MENOS LA POSICION '0' QUE NO ES END_DAY, ES START, LAS DEMÁS SON END-DAY


								for (  let iday = 1;  iday  <=  7;  iday++  )	//  DESDE EL 1 !!!		HASTA   7,  ....( 7 + 0 ) POSICIONES
								{

									// 1 dia va de:

										//			+0 .... <  ( 24 * 60 + 60 )	= 86400
										//			+0 .... <=					= 86400 - 1 = 86399

										//			+86400 .... <  ( 24 * 60 + 60 )	* 2	=   86400 * 2		= 172800
										//			+86400 .... <=						= ( 86400 * 2 )- 1	= 172799


									timesstamp_END_DAY_week[ iw ][ iday ] =  timesstamp_END_WEEK[ iw ]   +   (  iday *  const_timestamp_DAY  );			// MENOS LA POSICION '0' QUE NO ES END_DAY, ES START, LAS DEMÁS SON END-DAY

								}// for : iday

							}// for : iw  ---------------------------------------------


							//--------------------------------------------


							const const_timestamp_PERIOD =  ( 30 * 60 );

							var timesstamp_END_PERIODO_day_week =  new Array( /*5*/ NUM_SEMANAS_mes );


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{
								timesstamp_END_PERIODO_day_week[ iw ]  =  new Array( 7 );	// AHORA SÓLO 7


								for (  let iday = 0;  iday  <=  6;  iday++  )	//  DHASTA   6,  ....( 6 + 0 ) POSICIONES
								{
									timesstamp_END_PERIODO_day_week[ iw ][ iday ]  =  new Array( 49 );


									// ESTA SÍ QUE ES COMIENZO DE DÍA, MIRAR EL RESTO DE COMENTARIOS

									timesstamp_END_PERIODO_day_week[ iw ][ iday ][ 0 ] =  timesstamp_END_DAY_week[ iw ][ iday ];	// MENOS LA POSICION '0' QUE NO ES END_DAY, ES START, LAS DEMÁS SON END-DAY

									for (    let iper = 1;  iper  <=  48;  iper++    )	//  DESDE EL 1 !!!   HASTA EL 48 !!!  ( 49 POSICIONES )
									{

										// MEDIA HORA va de:

											//			+0 .... <  ( 30 + 60 )	= 1800
											//			+0 .... <=				= 1800 - 1 = 1799

											//			+1800 .... <  ( 30 + 60 )	* 2	=   1800 * 2		= 3600
											//			+1800 .... <=					= ( 1800 * 2 ) - 1	= 3599


										timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ] =  timesstamp_END_DAY_week[ iw ][ iday ]   +   (  iper *  const_timestamp_PERIOD  );		// MENOS LA POSICION '0' QUE NO ES END_DAY, ES START, LAS DEMÁS SON END-DAY

									}// for : iper


								}// for : iday

							}// for : iw



							//--------------------------------------------


							var ar_HORA_INICIO_PERIODO_30m = new Array( 48 );


							var aux_hora, aux_mins;



							for (  let iper =  0;  iper <  24;  iper++  )	// HASTA 47  ( < 48 )
							{
								aux_hora =  (  ( iper  %  2 )  ==  0  )		?	( iper / 2 )  :	 (  ( iper - 1 )  /  2  );

								aux_mins =  (  ( iper  %  2 )  ==  0  )		?	"00"  :	 "30";


								ar_HORA_INICIO_PERIODO_30m[ iper ] =  	"" + ( aux_hora + 0 )  +  ":"  +  aux_mins;

								//console.log(  ar_HORA_INICIO_PERIODO_30m[ iper ]  );

							}// for : iper ---------



							for (  let iper =  0;  iper <  24;  iper++  )	// HASTA 23  ( < 24 )
							{
								aux_hora =  (  ( iper  %  2 )  ==  0  )		?	( iper / 2 )  :	 (  ( iper - 1 )  /  2  );

								aux_mins =  (  ( iper  %  2 )  ==  0  )		?	"00"  :	 "30";

								ar_HORA_INICIO_PERIODO_30m[ iper + 24 ] =  	"" + ( aux_hora + 12 )  +  ":"  +  aux_mins;

								//console.log(  ar_HORA_INICIO_PERIODO_30m[ iper ]  );

							}// for : iper ---------

							//--------



							//--------------------------------------------


							// CADA HORA :   0s   -    <= 3599s
							//							< 3600s

							// CADA MEDIA HORA:   0s   -  <= 1799s
							//							   < 1800s


							// 12:00 _0s - 12:29_59s   .........   = +0		/   < +1800s					=		<= 1799
							// 12:30 _0s - 12:59_59s   .........   = +1800	/   < +(1800s*2) = 3600			=		<= 3599

							// 13:00 _0s - 13:29_59s
							// 13:30 _0s - 13:59_59s


							// 23:00 _0s - 23:29_59s
							// 23:30 _0s - 23:59_59s


							// + 1 día ---------

							// 00:00 _0s - 00:29_59s
							// 00:30 _0s - 00:59_59s


							// 01:00 _0s - 01:29_59s
							// 01:30 _0s - 01:59_59s


							// 10:00 _0s - 10:29_59s
							// 10:30 _0s - 10:59_59s


							// 11:00 _0s - 11:29_59s
							// 11:30 _0s - 11:59_59s




							/*
							var aux_new_obj_TRAMA =	{
															//[ MEDIA_str_var_B ]:		SL_obj[ i_dat_SL ][ str_var_B ],
														//[ MEDIA_str_var_P ]:		SL_obj[ i_dat_SL ][ str_var_P ],
														//[ MEDIA_str_var_C ]:		SL_obj[ i_dat_SL ][ str_var_C ]
													};
							*/



							// ARRAY MEDIAS

							/*
							aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iweek ][ i_periodo ] =	{

																										timestamp:				0,	//aux_SUM_NUM_timestamp,		//aux_NUM_timestamp,		//aux_NUM_timestamp,


																										NEW_timestamp_DAY:		"",
																																//aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  "",
																																//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA




																										HORA_INICIO_PERIODO_30m:	"",

																										num_TIMESTAMP_INICIO_PERIODO_30m:   0,


																										///-----


																										NUM_MUESTRAS_periodo: 0,


																										// AUNQUE SABEMOS QUE EL NÚM DE FAROLAS E (MATXIMPORTA) 9... DEBERÍA SALIR EL MISMO NÚMERO PERO SI NO SALE, COMO ES MEDIA DE MUESTRAS TOTALES, NO TIENE IMPORTANCIA

																										SUMA_B:		parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_B ],
																										SUMA_P:		parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_P ],
																										SUMA_C:		parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_C ]//,

																									};
							*/


							// INICIALIZACION ESTRUCTURA / OBJ

							aux_SUM_STREETLIGHTS_VARVALs__per_WEEK =  undefined;
							aux_SUM_STREETLIGHTS_VARVALs__per_WEEK = null

							aux_SUM_STREETLIGHTS_VARVALs__per_WEEK =  new Array( /*5*/ NUM_SEMANAS_mes );


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{
								aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ]  =  new Array( 7 );	// AHORA SÓLO 7


								for (  let iday = 0;  iday  <=  6;  iday++  )	//  HASTA   6,  ....( 7 + 0 ) POSICIONES
								{

									aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ]  =  [];	//new Array( 48 );


									// ESTA SÍ QUE ES COMIENZO DE DÍA, MIRAR EL RESTO DE COMENTARIOS
									//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ 0 ] =  timesstamp_END_DAY_week[ iw ][ iday ];	// MENOS LA POSICION '0' QUE NO ES END_DAY, ES START, LAS DEMÁS SON END-DAY


									for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
									{

										// MEDIA HORA va de:

											//			+0 .... <  ( 30 + 60 )	= 1800
											//			+0 .... <=				= 1800 - 1 = 1799

											//			+1800 .... <  ( 30 + 60 )	* 2	=   1800 * 2		= 3600
											//			+1800 .... <=					= ( 1800 * 2 ) - 1	= 3599


										//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ] =  timesstamp_END_DAY_week[ iw ][ iday ]   +   (  iper *  const_timestamp_PERIOD  );		// MENOS LA POSICION '0' QUE NO ES END_DAY, ES START, LAS DEMÁS SON END-DAY

										//------------------------------



										//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ]

										//ar_HORA_INICIO_PERIODO_30m[ iper ]


										// INICIALIZACIÓN..... LUEGO ABAJO YA VAMOS HACIENDO EL SUMATORIO CON LOS DATOS REALES
										//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ] =

										aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ].push(
																												{

																													//timestamp:				0,	//aux_SUM_NUM_timestamp,		//aux_NUM_timestamp,		//aux_NUM_timestamp,


																													//NEW_timestamp_DAY:		"",

																													//						//aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  "",
																													//						//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA


																													//HORA_INICIO_PERIODO_30m:	ar_HORA_INICIO_PERIODO_30m[ iper ],

																													//num_TIMESTAMP__START_PERIODO_30m:   timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ],
																													//num_TIMESTAMP__END_PERIODO_30m:   	timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper + 1 ],	// EN iper el 48 sí que existe

																													///-----

																													aux_NUM_MUESTRAS_periodo: 0,

																													// AUNQUE SABEMOS QUE EL NÚM DE FAROLAS E (MATXIMPORTA) 9... DEBERÍA SALIR EL MISMO NÚMERO PERO SI NO SALE, COMO ES MEDIA DE MUESTRAS TOTALES, NO TIENE IMPORTANCIA

																													SUM_B:		parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_B ],
																													SUM_P:		parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_P ],
																													SUM_C:		parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_C ]//,

																												}
																								);//.push()


									}// for : iper


								}// for : iday

							}// for : iw

							//--------------------------------------------------------------------------------------------------










							// COMIENZO DE SEMANA Y FIN DE SEMANA .... TIMESTAMP

							// TIMESTAMP SEGUN LA SEMANA, COMIENZO 12h hasta antes 12h día sig

							//const

							/*
							for (  let iweek = 0;  iweek  <  5;  iweek++  )
							{
							*/





							// SUPONEMOS WEEK = 0




							//num_TIMESTAMP__START_PERIODO_30m: 	timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ],
							//num_TIMESTAMP__END_PERIODO_30m:   	timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper + 1 ],	// EN iper el 48 sí que existe



							//const const_our_week =  0;

							//--------------------

							ar_MEDIA_period_WEEK__FINAL =  new Array( /*5*/ NUM_SEMANAS_mes );



							//----

							// INICIALIZACIÓN

							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{


								ar_MEDIA_period_WEEK__FINAL[ /*const_our_week*/ iw ] =  [];		//new Array( 48 );		//new Array();	// seria de 48



								for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0  HASTA EL 47  ( 48 POSICIONES )
								{

									var aux_obj_media =	{
															/*
															timestamp:				0,	//aux_SUM_NUM_timestamp,		//aux_NUM_timestamp,		//aux_NUM_timestamp,


															NEW_timestamp_DAY:		"",

																					//aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  "",
																					//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA
															*/

															HORA_INICIO_PERIODO_30m:			ar_HORA_INICIO_PERIODO_30m[ iper ],

															//num_TIMESTAMP__START_PERIODO_30m:	0,		//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ],
															//num_TIMESTAMP__END_PERIODO_30m:		0,		//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper + 1 ],	// EN iper el 48 sí que existe

															///-----

															NUM_MUESTRAS_periodo: 				0,


															// AUNQUE SABEMOS QUE EL NÚM DE FAROLAS E (MATXIMPORTA) 9... DEBERÍA SALIR EL MISMO NÚMERO PERO SI NO SALE, COMO ES MEDIA DE MUESTRAS TOTALES, NO TIENE IMPORTANCIA

															SUM__MEDIA_B:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_B ],
															SUM__MEDIA_P:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_P ],
															SUM__MEDIA_C:						parseFloat( 0.0 )		//SL_obj_DAT[ str_var_C ]//,

														};//


									// INICIALIZACION - ASIGNACION

									ar_MEDIA_period_WEEK__FINAL[ /*const_our_week*/ iw ].push(  aux_obj_media  );


									//console.log(   "iper: " + iper  +  ",  HORA_INICIO_PERIODO_30m: "  +  aux_obj_media.HORA_INICIO_PERIODO_30m  );


								}// for : iper

							}// for : iw


							//---------------------
							//---------------------



							// BUSCAR COINCIDENCIAS DE DIA Y PERIODO

							for (  let allSLs = 0;  allSLs  <  my_SL_Array_length;  allSLs++  )
							{


								for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
								{


									const const_allSLs =  allSLs;
									const const_iw =  iw;


									const const_SL_NAME_now =  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  const_iw  ][ const_allSLs ].SL_NAME;

									//----

									const const_sl_Indx__wk		=  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  const_iw  ][ const_allSLs ].dat;


									//---------------------------------------



									// SI SIGUIESE DANDO PROBLEMAS HABRÍA QUE HACERLO CON UN FOR TRADICIONAL (RECORRER, ESO SI, SIN PROBLEMAS, EL TEMA ES EL QUIZÁ, ACCESO AL DATO?? BUENO YO CREO QUE TAMPOCO)

									const_sl_Indx__wk.forEach(
									//
										( item, index ) =>
										{

											let aux_bol = false;

											let FOUND_DAY = -1;

											let idd = 0;


											while (
													(  idd <= 6  )  &&			//  6

													(  aux_bol == false )
											)
											{
												if (
														(  item.timestamp  >=  timesstamp_END_DAY_week[ const_iw ][ idd ]  )  &&
														(  item.timestamp  <   timesstamp_END_DAY_week[ const_iw ][ idd + 1 ]  )
												)
												{
													FOUND_DAY =  idd;
													aux_bol =  true;


													//----------------------------


													let aux_bol_2 = false;

													let FOUND_PERIOD = -1;

													let idp = 0;



													while (
															(  idp <= 47  ) &&			// 47
															(  aux_bol_2 == false )
													)
													{
														if (
																(  item.timestamp  >=  timesstamp_END_PERIODO_day_week[ const_iw ][ FOUND_DAY ][ idp ]  )  &&
																(  item.timestamp  <   timesstamp_END_PERIODO_day_week[ const_iw ][ FOUND_DAY ][ idp + 1 ]    )
														)
														{

															FOUND_PERIOD =  idp;
															aux_bol_2 =  true;


															//--------------------------------------------

															aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ const_iw ][ FOUND_DAY ][ FOUND_PERIOD ].SUM_B  +=  parseFloat( item[  const_SL_NAME_now  +  "_B" ] );
															aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ const_iw ][ FOUND_DAY ][ FOUND_PERIOD ].SUM_P  +=  parseFloat( item[  const_SL_NAME_now  +  "_P" ] );
															aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ const_iw ][ FOUND_DAY ][ FOUND_PERIOD ].SUM_C  +=  parseFloat( item[  const_SL_NAME_now  +  "_C" ] );

															aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ const_iw ][ FOUND_DAY ][ FOUND_PERIOD ].aux_NUM_MUESTRAS_periodo  +=  1;



														}// if

														//--------

														idp++;

													}// while

													//-------------------------------------


												}// if

												//--------

												idd++;

											}// while (  ...  )


											//---------------------------------------------------------------------



											//------

													//timesstamp_END_WEEK[ /*const_our_week*/ iw ]

											//timesstamp_END_DAY_week[ /*const_our_week*/ iw ][ iday ]

											//timesstamp_END_PERIODO_day_week[ /*const_our_week*/ iw ][ iday ][ iper ]

											//------


											// FOUND_DAY =  idd;
											// FOUND_PERIOD =  idp;

											/*

											// OK !!!!

											console.log(    " PERIODOS!! :: _ar_ALL__SL_VALs_F_WEEK[" +  iw + "][" + allSLs  +  "][i_dat=" + index  + 		//  const_our_week

															"] ::  FOUND_DAY[" + FOUND_DAY  + "] FOUND_PERIOD[" + FOUND_PERIOD  + "] con TIMESTAMP = " + item.timestamp	+


															" ["  + timesstamp_END_PERIODO_day_week[ iw ][ FOUND_DAY ][ FOUND_PERIOD ] +  ", "  +  timesstamp_END_PERIODO_day_week[  iw ][ FOUND_DAY ][ FOUND_PERIOD + 1 ]  +  "]"	// const_our_week
											);
											*/


											/*

											aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ FOUND_DAY ][ FOUND_PERIOD ].SUM_B  +=  parseFloat( item[  SL_NAME_now +  "_B" ] );	// const_our_week
											aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ FOUND_DAY ][ FOUND_PERIOD ].SUM_P  +=  parseFloat( item[  SL_NAME_now +  "_P" ] );
											aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ FOUND_DAY ][ FOUND_PERIOD ].SUM_C  +=  parseFloat( item[  SL_NAME_now +  "_C" ] );

											aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ FOUND_DAY ][ FOUND_PERIOD ].aux_NUM_MUESTRAS_periodo  +=  1;

											*/



											//------


										}// ( item, index ) =>
									//
									); //.forEach()

									//-------------

								}// for: iw

							}// for : allSLs -------------------------------------------





							//--------------------------------------------------------------------------------------------------
							//--------------------------------------------------------------------------------------------------





							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{
								//ar_MEDIA_WEEK__FINAL[ 0 ] =  new Array(  7 * 48  );

								for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
								{
									//var NUM_MUESTRAS_dia  =  0;

									for (  let iday = 0;  iday  <=  6;  iday++  )	//  HASTA   6,  ....( 7 + 0 ) POSICIONES
									{


										//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].SUM_B  /=  parseFloat(  NUM_MUESTRAS  );
										//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].SUM_P  /=  parseFloat(  NUM_MUESTRAS  );
										//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].SUM_C  /=  parseFloat(  NUM_MUESTRAS  );


										//iper  +  ( iday * 48 )  ]


										//ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].num_TIMESTAMP__START_PERIODO_30m =  0;
										//ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].num_TIMESTAMP__END_PERIODO_30m =  0;


										//ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].aux_NUM_MUESTRAS_periodo =  0;




										//ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].HORA_INICIO_PERIODO_30m =  ar_HORA_INICIO_PERIODO_30m[ iper ];		//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].HORA_INICIO_PERIODO_30m;




										ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_B  +=  aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].SUM_B;
										ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_P  +=  aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].SUM_P ;
										ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_C  +=  aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].SUM_C;





										//NUM_MUESTRAS_dia +=  aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].NUM_MUESTRAS_periodo;

										ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].NUM_MUESTRAS_periodo  +=  aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ /*const_our_week*/ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;


									}// for : iday


								}// for : iper

							}// for : iw


							//-----------


							var MAX_MEDIA_BP = 	parseFloat(  -10.0  );
							var MAX_MEDIA_C = 	parseFloat(  -10.0   );


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{
								//ar_MEDIA_WEEK__FINAL[ 0 ] =  new Array(  7 * 48  );

								for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
								{

									ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_B  /=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].NUM_MUESTRAS_periodo   );
									ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_P  /=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].NUM_MUESTRAS_periodo   );

									ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_C  /=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].NUM_MUESTRAS_periodo   );




									if (   MAX_MEDIA_BP  <   ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_B    )
									{
										MAX_MEDIA_BP =  ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_B;

									}//

									if (   MAX_MEDIA_BP  <   ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_P    )
									{

										MAX_MEDIA_BP =  ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_P;
									}//


									if (   MAX_MEDIA_C  <   ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_C    )
									{

										MAX_MEDIA_C =  ar_MEDIA_period_WEEK__FINAL[  /*const_our_week*/ iw  ][  iper  ].SUM__MEDIA_C;
									}//


								}// for : iper

							}// for : iw




							//--------------------------------------------------------------------------------------------------



							_c_MAX_INST_GROUP__VARS_C =  Math.round(   parseFloat(  parseFloat(  MAX_MEDIA_C  )  +  parseFloat( 1.0 / 3.0 )  )    );

							//var MUL_5_aux =   Math.floor(  Math.round(   parseFloat(  parseFloat(  MAX_INST_GROUP__VARS_BP )  +  parseFloat( 5.0 )  )    )   /   5  );

							_c_MAX_INST_GROUP__VARS_BP =  Math.floor(  Math.round(   parseFloat(  parseFloat(  MAX_MEDIA_BP )  +  parseFloat( 3.0 )  )    )   /   5  )    *    5;



							//--------------------------------------------------------------------------------------------------



							ar_MEDIA_period_MONTH__FINAL =  [];		//new Array( 48 );		//[];


							// INICIALIZACION !


							for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
							{
								var aux_obj_media =	{
														/*
														timestamp:				0,	//aux_SUM_NUM_timestamp,		//aux_NUM_timestamp,		//aux_NUM_timestamp,


														NEW_timestamp_DAY:		"",

																				//aux_new_date.getDate()   +  " "  +  ( STR_meses[   aux_new_date.getMonth()  ]  )  +  "",
																				//new Date( SL_obj[ i_dat_SL ].timestamp ).getFullYear(), 	// ORDEN FECHA ESPAÑA Y SÓLO FECHA
														*/

														HORA_INICIO_PERIODO_30m:			ar_HORA_INICIO_PERIODO_30m[ iper ],

														//num_TIMESTAMP__START_PERIODO_30m:	0,		//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper ],
														//num_TIMESTAMP__END_PERIODO_30m:		0,		//timesstamp_END_PERIODO_day_week[ iw ][ iday ][ iper + 1 ],	// EN iper el 48 sí que existe

														///-----

														NUM_MUESTRAS_periodo: 				0,


														// AUNQUE SABEMOS QUE EL NÚM DE FAROLAS E (MATXIMPORTA) 9... DEBERÍA SALIR EL MISMO NÚMERO PERO SI NO SALE, COMO ES MEDIA DE MUESTRAS TOTALES, NO TIENE IMPORTANCIA

														SUM__MEDIA_B:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_B ],
														SUM__MEDIA_P:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_P ],
														SUM__MEDIA_C:						parseFloat( 0.0 )		//SL_obj_DAT[ str_var_C ]//,

													};//


								ar_MEDIA_period_MONTH__FINAL.push(  aux_obj_media  );


							}// for : iper


							//console.log(   "MONTH! MONTH!  ar_MEDIA_period_MONTH__FINAL.length:  "  +  ar_MEDIA_period_MONTH__FINAL.length  );



							//--------------


							for (  let iw = 0;  iw  <=  3;  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 3 !!!!!! ( =  0...3 ) QUE SON 4 POSICIONES... PERO NO HASTA LA 4 (QUE SON 5 POSICIONES / SEMANAS),
							{
								//ar_MEDIA_WEEK__FINAL[ 0 ] =  new Array(  7 * 48  );

								for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
								{


									ar_MEDIA_period_MONTH__FINAL[  iper  ].SUM__MEDIA_B  +=  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].SUM__MEDIA_B;  		//	/=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo   );
									ar_MEDIA_period_MONTH__FINAL[  iper  ].SUM__MEDIA_P  +=  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].SUM__MEDIA_P;  		//	/=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo   );
									ar_MEDIA_period_MONTH__FINAL[  iper  ].SUM__MEDIA_C  +=  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].SUM__MEDIA_C;  		//	/=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo   );


									// SERÍAN 5 ( POR SEMANA )

									ar_MEDIA_period_MONTH__FINAL[  iper  ].NUM_MUESTRAS_periodo++;		//=  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo; 		//	+=  		aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;



								}// for : iper

							}// for : iw






							// IGUAL EN SEMANA 5 SÓLO HABRÍA QUE RECORRER SI EXISTEN DIAS

							// Y AHORA HACEMOS SEMANA 5:




							//--------------------------------------------------------


							for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
							{


								ar_MEDIA_period_MONTH__FINAL[  iper  ].SUM__MEDIA_B  /=  parseFloat(  ar_MEDIA_period_MONTH__FINAL[  iper  ].NUM_MUESTRAS_periodo  );  		//	/=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo   );
								ar_MEDIA_period_MONTH__FINAL[  iper  ].SUM__MEDIA_P  /=  parseFloat(  ar_MEDIA_period_MONTH__FINAL[  iper  ].NUM_MUESTRAS_periodo  );  		//	/=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo   );
								ar_MEDIA_period_MONTH__FINAL[  iper  ].SUM__MEDIA_C  /=  parseFloat(  ar_MEDIA_period_MONTH__FINAL[  iper  ].NUM_MUESTRAS_periodo  );  		//	/=  parseFloat(  ar_MEDIA_period_WEEK__FINAL[  iw  ][  iper  ].NUM_MUESTRAS_periodo   );


							}// for : iper



							//--------------------------------------------------------
							//--------------------------------------------------------
							//--------------------------------------------------------











							// STR_DiAS_MES[ id ]

							//var num_PORCENT_AHOORRO_ENERGÉTICO_DIAS = [];	// ESTA LA HAREMOS DESPUS DE LAS DE ABAJO USANDO EL ÚLTIMO ARRAY:  MEDIA_HORAS_ENCENDIDO_DIA

							//var ar_HORAS_ENCENDIDO_DIA_allSL = new Array( );	// POR SEMANA...   DIA.......   PERIODO..
							//var MEDIA_HORAS_ENCENDIDO_DIA = new Array( );  	// POR DIA (PARA SACAR DIRECTAMENTE LA GRÁFICA)




							//var ar_datos_MEDIA_ENCENDIDO_allSL = new Array( );	// POR SEMANA...   DIA.......   PERIODO..



							ar_datos_MEDIA_ENCENDIDO_allSL =  new Array( /*5*/ NUM_SEMANAS_mes );


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{
								ar_datos_MEDIA_ENCENDIDO_allSL[ iw ] =  new Array( 7 );


								for (  let iday = 0;  iday  <=  6;  iday++  )	//  HASTA   6,  ....( 7 + 0 ) POSICIONES
								{

									ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ]  =  [];		//new Array( 48 );


									for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
									{

										var aux_objMedia =	{

																HORA_INICIO_PERIODO_30m:			ar_HORA_INICIO_PERIODO_30m[ iper ],

																///-----

																f_NUM_MUESTRAS_periodo: 				0,


																// AUNQUE SABEMOS QUE EL NÚM DE FAROLAS E (MATXIMPORTA) 9... DEBERÍA SALIR EL MISMO NÚMERO PERO SI NO SALE, COMO ES MEDIA DE MUESTRAS TOTALES, NO TIENE IMPORTANCIA

																SUM__MEDIA_B:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_B ],
																SUM__MEDIA_P:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_P ],

																SUM__MEDIA_C:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_C ]//,



																bool_MEDIA_SLs_ENCENDIDA:			"false"

															};//


										ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ].push(  aux_objMedia  );


									}// for : iper

								}// for : iday

							}  // for : iw


							//--------------------------------------------------------------------------------------
							//--------------------------------------------------------------------------------------






							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{

								for (  let iday = 0;  iday  <=  6;  iday++  )	//  HASTA   6,  ....( 7 + 0 ) POSICIONES
								{

									for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
									{
										/*
										var aux_objMedia =	{

																HORA_INICIO_PERIODO_30m:			ar_HORA_INICIO_PERIODO_30m[ iper ],

																///-----

																f_NUM_MUESTRAS_periodo: 				0,


																// AUNQUE SABEMOS QUE EL NÚM DE FAROLAS E (MATXIMPORTA) 9... DEBERÍA SALIR EL MISMO NÚMERO PERO SI NO SALE, COMO ES MEDIA DE MUESTRAS TOTALES, NO TIENE IMPORTANCIA

																SUM__MEDIA_B:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_B ],
																SUM__MEDIA_P:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_P ],

																SUM__MEDIA_C:						parseFloat( 0.0 ),		//SL_obj_DAT[ str_var_C ]//,



																bool_MEDIA_SLs_ENCENDIDA:			"false"

															};//
										*/





										var aux_NUM_MUESTRAS =  aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;


										ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].f_NUM_MUESTRAS_periodo =   aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;			//aux_NUM_MUESTRAS;		//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;




										//console.log(  "aux_NUM_MUESTRAS: "  +  aux_NUM_MUESTRAS  );

										//console.log( "aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_C  = " + aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_C  );




										if (  aux_NUM_MUESTRAS  >= 1 )
										{


											var aux_MEDIA_C =  parseFloat( 	aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_C  )   /   parseFloat( aux_NUM_MUESTRAS );

											//console.log(  "aux_MEDIA_C = " + aux_MEDIA_C  );

											//----

											if (  parseFloat( aux_MEDIA_C )  >=  parseFloat( const_cota_Min_Corriente_ENCENDIDO )  )		// 0.062  // 0.62
											{

												//window.alert( "AHORA!"  );

												ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].bool_MEDIA_SLs_ENCENDIDA =  "true";




												ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_C =  aux_MEDIA_C;




												ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_B =  parseFloat( 	aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_B  )  /   parseFloat( aux_NUM_MUESTRAS );
												ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_P =  parseFloat( 	aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_P  )  /   parseFloat( aux_NUM_MUESTRAS );


												/*
												aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_B;
												aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_P ;
												aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].SUM_C;


												aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;
												*/

											}// if


										}// if
										/*
										else // = if (  aux_NUM_MUESTRAS  == 0 )
										{
											ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].bool_MEDIA_SLs_ENCENDIDA =  "false";

											ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].f_NUM_MUESTRAS_periodo =  0;				//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ iw ][ iday ][ iper ].aux_NUM_MUESTRAS_periodo;


											ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_C =  parseFloat(    0.0     );

											ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_B =  parseFloat( 	0.0  	);
											ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_P =  parseFloat( 	0.0  	);

										}*/



										//console.log(  "ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_C =  "  +  ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_C   );

										//console.log( "-----------" );





										//ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ].push(  aux_objMedia  );
										//ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ]


										/*
										if (  parseFloat( aux_MEDIA_C )  >  parseFloat( 0.62 )  )
										{
											window.alert(   JSON.stringify( ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ])   );
										}
										*/

									}// for (    let iper = 0;  iper  <=  47;  iper++    )

								}// for : iday

							}// for : iw





							//--------------------------------------------------------------------------------------
							//--------------------------------------------------------------------------------------





							var aux_datos_MEDIA_ENCENDIDO__DIA = new Array( /*5*/ NUM_SEMANAS_mes );	// __SIN PERIODO__, LUEGO CON WEKK Y DAY, SACAS EL DÍA DEL MES CONCRETO

							//---


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{

								aux_datos_MEDIA_ENCENDIDO__DIA[ iw ] =  [];		//new Array( 7 );


								var aux_CONT_PERIODOS__ESTA_ENCENDIDO =  0;	// PERIODOS DE 30 MIN = 0.5 HORAS

								//---

								for (  let iday = 0;  iday  <=  6;  iday++  )	//  HASTA   6,  ....( 7 + 0 ) POSICIONES
								{

									aux_CONT_PERIODOS__ESTA_ENCENDIDO =  0;	// PERIODOS DE 30 MIN = 0.5 HORAS


									var mi_obj_aux_ =	{
															NUM_MUESTRAS:  0,

															CONT_PERIODOS__ESTA_ENCENDIDO:  0,

															SUM_C:  parseFloat(  0.0  ),

															SUM_B:  parseFloat(  0.0  ),
															SUM_P:  parseFloat(  0.0  )
														};


									mi_obj_aux_.NUM_MUESTRAS =  0;

									mi_obj_aux_.CONT_PERIODOS__ESTA_ENCENDIDO =  0;


									mi_obj_aux_.SUM_C  =  parseFloat(  0.0  );

									mi_obj_aux_.SUM_B  =  parseFloat(  0.0  );
									mi_obj_aux_.SUM_P  =  parseFloat(  0.0  );




									//------------------


									for (    let iper = 0;  iper  <=  47;  iper++    )	//  DESDE EL 0    HASTA EL 47  ( 48 POSICIONES )
									{


										if (  ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].bool_MEDIA_SLs_ENCENDIDA    ==  "true"  )
										{

											aux_CONT_PERIODOS__ESTA_ENCENDIDO +=  1;


											//mi_obj_aux_.NUM_MUESTRAS =  48;		//ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].;

											mi_obj_aux_.CONT_PERIODOS__ESTA_ENCENDIDO =  aux_CONT_PERIODOS__ESTA_ENCENDIDO;


											mi_obj_aux_.SUM_C  +=  parseFloat( ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_C  );

											mi_obj_aux_.SUM_B  +=  parseFloat( ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_B  );
											mi_obj_aux_.SUM_P  +=  parseFloat( ar_datos_MEDIA_ENCENDIDO_allSL[ iw ][ iday ][ iper ].SUM__MEDIA_P  );

										}// if

									}// for : iper ---------------------------------------------------------------------------------------------------


									//------------------------------

									//aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ] =  aux_CONT_PERIODOS__ESTA_ENCENDIDO;



									aux_datos_MEDIA_ENCENDIDO__DIA[ iw ].push(  mi_obj_aux_  );			// =  aux_CONT_PERIODOS__ESTA_ENCENDIDO;


								}// for : iday -----------------------------------

							}// for : iw ----------------------




							//--------------------


							/*

							// STR_DiAS_MES[ id ]   MEDIA_HORAS_ENCENDIDO_DIA[ iday  +  (  iw * 7  )  ]
							//var num_PORCENT_AHOORRO_ENERGÉTICO_DIAS = [];



														var ar_HORAS_ENCENDIDO_DIA_allSL = new Array( );	// POR SEMANA...   DIA.......   PERIODO..
							var MEDIA_HORAS_ENCENDIDO_DIA = new Array( );	// POR DIA (PARA SACAR DIRECTAMENTE LA GRÁFICA)

							*/

							//--------------------


							MEDIA_HORAS_ENCENDIDO_DIA =  [];		//new Array(  35 /*5 * 7*/ /*NUM_DAYS_MONTH*/   );	// LUEGO LE HACEMOS UN SLICE


							for (  let iw = 0;  iw  <=  /*4*/ ( NUM_SEMANAS_mes - 1 );  iw++  )	// AQUI SÓLO RECORREMOS HASTA LA 4 ( =  0...4 ) QUE SON 5 POSICIONES... PERO NO HASTA LA 5 (QUE SON 6 POSICIONES),
							{


								for (  let iday = 0;  iday  <=  6;  iday++  )	//  HASTA   6,  ....( 7 + 0 ) POSICIONES
								{

									var aux_objNOW =	{
															num_horas: parseFloat( 0.0 ),

															dia_mes: "",

															RESULTADO_AHORRO:  parseFloat( 0.0 ),	// 	V_BATERIA (_B) * A_CORRIENTE (_C) * float_Horas_ENCENDIDO    (AL DÍA)


															RESULTADO_AHORRO_TEORICO:  parseFloat( 0.0 ),		//


															RESULTADO_AHORRO_TEORICO_aprox:  parseFloat( 0.0 ),	// 	V_BATERIA (_B) * A_CORRIENTE (_C) * float_Horas_ENCENDIDO    (AL DÍA)

															fhoras_sunset_sunrise:  parseFloat( 0.0 )
														};


									aux_objNOW.num_horas =  parseFloat(  0.0  );
									aux_objNOW.dia_mes =  "";
									aux_objNOW.RESULTADO_AHORRO =  parseFloat(  0.0  );

									aux_objNOW.RESULTADO_AHORRO_TEORICO =  parseFloat(  0.0  );

									aux_objNOW.RESULTADO_AHORRO_TEORICO_aprox =  parseFloat(  0.0  );

									aux_objNOW.fhoras_sunset_sunrise =  parseFloat(  0.0  );

									//----------------------------------



									var offset_day =	iday  +  (  iw * 7  );


									aux_objNOW.num_horas =		(   offset_day  <  NUM_DAYS_MONTH   )
																?
																	ar_Horas_Dia__MEDIA_SL[  offset_day  ]
																:
																	parseFloat( 0.0 );

															//parseFloat(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].CONT_PERIODOS__ESTA_ENCENDIDO  )  /  parseFloat( 2.0 );






									//-----------------------------------------


									if (  ( offset_day )  <   NUM_DAYS_MONTH  )
									{
										aux_objNOW.dia_mes   =  STR_DiAS_MES[   offset_day   ];

										aux_objNOW.fhoras_sunset_sunrise =   ar_Horas_SunsetSunrise_dias_Mes[   offset_day   ].num_horas;

									}// if (  ( offset_day )  <   NUM_DAYS_MONTH  )


									//---------------------------------------


									//---

									if (  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].CONT_PERIODOS__ESTA_ENCENDIDO  >=  1  )
									{

										aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM_C   /=  parseFloat(  /*aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].CONT_PERIODOS__ESTA_ENCENDIDO*/  48.0  );		//  24 * 2 =  48  (cada 30 min)


										aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM__B  /=  parseFloat(  /*aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].CONT_PERIODOS__ESTA_ENCENDIDO */ 48.0  );		//  24 * 2 =  48  (cada 30 min)

										//aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM__MEDIA_P  /=  parseFloat(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].CONT_PERIODOS__ESTA_ENCENDIDO  );

										//------

									}// if
									else
									{
										// CLARO, SALTA CON SEMANA 4 (LA 5º) Y A PARTIR DE DÍA INDX 3 (DIA 4 .... = DIA 32 DE MES)

										if (   ( iw != /*4*/ ( NUM_SEMANAS_mes - 1 ) )   ||   ( iday < 3 )   )	// QUEREMOS QUE SÓLO SALTE CUANDO REALMENTE EN UNO DE LOS DÍAS DE MES EXISTENTES, HAYA MEDIA DE FAROLAS APAGADAS (SIN NINGÚN INSTANTE DE ENCENDIDO)
										{
											////window.alert(   "aux_datos_MEDIA_ENCENDIDO__DIA[" + iw + "][" + iday + "].CONT_PERIODOS__ESTA_ENCENDIDO  <= 0"  );
										}// if

									}// else ----------------------------------------





									//let aux_Ahorro_Wh_Dia =  ar_Ahorro_Wh_Dia__APROX[  offset_day  ];


									aux_objNOW.RESULTADO_AHORRO  =		(   offset_day  <  NUM_DAYS_MONTH   )			// !!!!!!!!!!!!!!!!!!!!!!  ->    CAMBIO QUE HA HECHO QUE VAYA BIEN CALC AHORRO DIA!   ( 11/07/22 )
																		?
																			ar_Ahorro_Dia__AllSLs[  offset_day  ]	/   parseFloat(  my_SL_Array_length  )		//ar_Ahorro_Wh_Dia__APROX[  offset_day  ]
																		:
																			parseFloat( 0.0 );

																			//ar_Ahorro_Wh_Dia__APROX[    ];


																	/*
																	parseFloat(
																				aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM_B  *
																				aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM_C  //*

																				/  parseFloat( 2.0 )

																				//aux_objNOW.num_horas
																	);*/


																/*
									ar_Ahorro_Wh_Dia__APROX =  new Array(  NUM_DAYS_MONTH  );


									for (  let im = 0;  im  <  NUM_DAYS_MONTH;  im++  )
									{
										var horas_dia =  ar_Horas_Dia__MEDIA_SL[  im  ];	//(  ar_Minutos_Dia__AllSLs[  im  ]  /  parseFloat( 60.0 )  );

										ar_Ahorro_Wh_Dia__APROX[  im  ] =  ar_Ahorro_Dia__AllSLs[  im  ]  /   parseFloat( horas_dia );		//24.0;		//parseFloat(  horas_dia  );
									*/


									//let aux_Ahorro_Ener_Dia_Teorico =  ar_Ahorro_Dia__AllSLs[  im  ]  /   parseFloat(   ar_Horas_SunsetSunrise_dias_Mes[   offset_day   ].num_horas   );



									aux_objNOW.RESULTADO_AHORRO_TEORICO  =		(   offset_day  <  NUM_DAYS_MONTH   )
																				?
																					ar_Ahorro_Wh_DIA_TEORICO[  offset_day  ]		//aux_Ahorro_Ener_Dia_Teorico			//ar_Ahorro_Wh_Dia__APROX[  offset_day  ]

																					//parseFloat(   ar_Ahorro_Wh_Dia__APROX[  offset_day  ]   *   ar_Horas_Dia__MEDIA_SL[  offset_day  ]   )   /   parseFloat(   ar_Horas_SunsetSunrise_dias_Mes[   offset_day   ].num_horas   )
																				:
																					parseFloat( 0.0 );




									// EN PRINCIPIO ÉSTA NO ES VÁLIDA

									aux_objNOW.RESULTADO_AHORRO_TEORICO_aprox  =		(   offset_day  <  NUM_DAYS_MONTH   )
																						?
																							( ar_Ahorro_Dia__AllSLs[  offset_day  ] )  /   parseFloat(   ar_Horas_SunsetSunrise_dias_Mes[   offset_day   ].num_horas   )		//aux_Ahorro_Ener_Dia_Teorico			//ar_Ahorro_Wh_Dia__APROX[  offset_day  ]

																							//parseFloat(   ar_Ahorro_Wh_Dia__APROX[  offset_day  ]   *   ar_Horas_Dia__MEDIA_SL[  offset_day  ]   )   /   parseFloat(   ar_Horas_SunsetSunrise_dias_Mes[   offset_day   ].num_horas   )
																						:
																							parseFloat( 0.0 );




									//console.log(  aux_objNOW.RESULTADO_AHORRO  );

									//console.log(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM__MEDIA_B  );		//aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM__MEDIA_C



									//console.log(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM_C  );
									//console.log(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ].SUM_B  );







									//------------------------------------------------------------------------


									MEDIA_HORAS_ENCENDIDO_DIA.push(  aux_objNOW  );

									//MEDIA_HORAS_ENCENDIDO_DIA[ offset_day  ] =  parseFloat(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ]  )  /  parseFloat( 2.0 );		// ENTRE 2, PORQUE SON 2 PERIODOS 1 HORA

									//------------------------------------------------------------------------
								}//

							}//


							// OJO QUE NO SON HORAS, SON PERIODOS !!!

							MEDIA_HORAS_ENCENDIDO_DIA =  MEDIA_HORAS_ENCENDIDO_DIA.slice(  0,  NUM_DAYS_MONTH  );		// de la _POSICION_INDICE 0 a la POSICIÓN ÍNDICE (NUM_DAYS_MONTH - 1)

							//-----

							//console.log(  MEDIA_HORAS_ENCENDIDO_DIA  );



							//--------------------



							Horas_TOTALES_MES_LUMINARIA_allSLs =  0;
							/*
							MEDIA_HORAS_ENCENDIDO_DIA.forEach(
															( item, index ) =>
															{
																Horas_TOTALES_MES_LUMINARIA_allSLs  +=  item.num_horas;

															}//
							);// .forEach()
							*/

							//--------------------

							Horas_TOTALES_MES_LUMINARIA_allSLs =  Math.round(  SUMATORIO_HORAS_TOTALES  );


							//////console.log(  "Horas_TOTALES_MES_LUMINARIA_allSLs: "  +  Horas_TOTALES_MES_LUMINARIA_allSLs  );




							//----


							TODOS_DATOS_ACCESIBLES =  true;

							////window.alert(  "TODOS_DATOS_ACCESIBLES =  true;  "  +  TODOS_DATOS_ACCESIBLES  );

							//-----



							//------------------------------


							_cont_rerender_svg  += 1;


						}// if (   my_SL_Array_length  ==  _obj_Inst_Group.info.streetlights.length  )





					}// if (   _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  ==  0  )


				}// if 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  ) &&	(  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != "undefined"  )

				//---


			}// if	(   _ar_ALL__STREETLIGHTS_VARVALs.length  >  0  )  //&&








		}// if ( ... )
			/*
			if (
				(  this.state.LAST_re_render  !=  "true"  )  &&

				( _cont_rerender_svg == 0 )  &&


				(  _ar_ALL__STREETLIGHTS_VARVALs  !=  null  ) &&
				(  typeof( _ar_ALL__STREETLIGHTS_VARVALs ) != "undefined"  )
			)*/




		//---------------------------------------------------------------------------------------------------------------------------------------------------------


		// SE "RENDERIZAN" TODOS SVGs HABIDOS Y POR HABER

		if	(
				(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )   &&


				//(  PULSADO_BOTON  ==  true  )    &&


				(   this.state.sunRise_sunSet_GET  ==  "true"   )   &&



				(  this.state.SCRIPT_svg_rendered == "true" )
			)
		{

			//this.state.SCRIPT_svg_rendered = "false";

			NUM_renders_SVG_rendered++;

			//////console.log(  "NUM_renders_SVG_rendered:  "  +  NUM_renders_SVG_rendered  );


			//------


			if (
					( _cont_rerender_svg ==  1 /*0*/ )  &&

					( this.state.sunRise_sunSet_GET == "true" )
			)
			{
				//_cont_rerender_svg +=  1;

				//-----

				//window.alert( "AHOORAAA 1" );

				////window.alert( "3- ZONA PROCESADO Y LIMPIEZA SVG Y GENERAR ESTRUCTURA PDF" );


				svg_01_obj = document.getElementById( "id_ComposedChart_DIMMING" );

				svg_02_obj = document.getElementById( "id_ComposedChart_02" );
				svg_03_obj = document.getElementById( "id_ComposedChart_03" );
				svg_04_obj = document.getElementById( "id_ComposedChart_04" );


				//id_LINECHART_svg_MEDIA_allSLs_HORA	id_RespCont_svg_MEDIA_allSLs_HORA
				LINECHART_svg_MEDIA_allSLs_HORA_obj = document.getElementById( "id_LINECHART_svg_MEDIA_allSLs_HORA" );


				MEDIAS_HORAS_obj = document.getElementById( "id_BAR_MEDIA_HORAS" );


				AHORRO_ENERG_obj =  document.getElementById( "id_BAR_AHORRO" );
				//id_RP_AHORRO


				//--

				if (   svg_01_obj  !=  null  )
				{

					//window.alert( "AHOORAAA 2" );

					/*
					var ar_svg_circles = svg_01_obj.getElementsByClassName( "recharts-dot recharts-line-dot" );

					for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
					{
						console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );
						//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
						console.log(  ar_svg_circles[ ics ].outerHTML  );

						ar_svg_circles[ ics ].outerHTML = "";

						svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

					}// for
					*/


					var ar_svg_circles = svg_01_obj.getElementsByTagName( "circle" );



					while (  ar_svg_circles.length  >  0  )
					{
						for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );


							ar_svg_circles[ ics ].outerHTML = "";



							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles = svg_01_obj.getElementsByTagName( "circle" );
					}//

					//---

					//$( "text" ).css( "font-size", "10px" );


					svg_01_outerHTML =  svg_01_obj.outerHTML;

					svg_01_outerHTML =  String( svg_01_outerHTML ).replaceAll(	"<text ",
																				"<text style='font-size: 24px' " );


				}// if (   svg_01_obj  !=  null  )






				//----



				if (   svg_02_obj  !=  null  )
				{

					//window.alert( "AHOORAAA 2" );

					/*
					var ar_svg_circles = svg_01_obj.getElementsByClassName( "recharts-dot recharts-line-dot" );

					for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
					{
						console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );
						//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
						console.log(  ar_svg_circles[ ics ].outerHTML  );

						ar_svg_circles[ ics ].outerHTML = "";

						svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

					}// for
					*/


					var ar_svg_circles = svg_02_obj.getElementsByTagName( "circle" );



					while (  ar_svg_circles.length  >  0  )
					{
						for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );


							ar_svg_circles[ ics ].outerHTML = "";



							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles = svg_02_obj.getElementsByTagName( "circle" );
					}//

				}// if (   svg_02_obj  !=  null  )







				if (   svg_03_obj  !=  null  )
				{

					//window.alert( "AHOORAAA 2" );

					/*
					var ar_svg_circles = svg_01_obj.getElementsByClassName( "recharts-dot recharts-line-dot" );

					for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
					{
						console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );
						//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
						console.log(  ar_svg_circles[ ics ].outerHTML  );

						ar_svg_circles[ ics ].outerHTML = "";

						svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

					}// for
					*/


					var ar_svg_circles =  svg_03_obj.getElementsByTagName( "circle" );

					while (  ar_svg_circles.length  >  0  )
					{
						for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );


							ar_svg_circles[ ics ].outerHTML = "";



							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles = svg_03_obj.getElementsByTagName( "circle" );


					}//


					svg_03_outerHTML =  svg_03_obj.outerHTML;


					//$( "text" ).css( "font-size", "10px" );

					svg_03_outerHTML =  String( svg_03_outerHTML ).replaceAll(	"<text ",
																				"<text style='font-size: 18px; margin:20px;' " );


				}// if (   svg_03_obj  !=  null  )



				//-----







				if (   svg_04_obj  !=  null  )
				{

					//window.alert( "AHOORAAA 2" );

					/*
					var ar_svg_circles = svg_01_obj.getElementsByClassName( "recharts-dot recharts-line-dot" );

					for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
					{
						console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );
						//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
						console.log(  ar_svg_circles[ ics ].outerHTML  );

						ar_svg_circles[ ics ].outerHTML = "";

						svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

					}// for
					*/


					var ar_svg_circles =  svg_04_obj.getElementsByTagName( "circle" );
					var ar_svg_circles_length =  ar_svg_circles.length;

					while (  ar_svg_circles_length  >  0  )
					{

						for (  let ics = 0;  ics <  ar_svg_circles_length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );


							//svg_04_obj.getElementsByTagName( "circle" )[ ics ].hidden = true;

							//svg_04_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

							if (  typeof( ar_svg_circles[ ics ] )  !=  "undefined"  )
							{
								ar_svg_circles[ ics ].outerHTML = "";
							}

							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles =  svg_04_obj.getElementsByTagName( "circle" );
						ar_svg_circles_length =  ar_svg_circles.length;

					}// while


					//////console.log( "svg_04_obj .. QUITADO PUNTOS !!!!!" );

					//window.alert( "svg_04_obj .. QUITADO PUNTOS !!!!!" );



					//------------


					var ar_UL =  document.getElementById( "chart_04_prueba" ).getElementsByTagName( "ul" );


					for (  let iUL = 0;  iUL <  ar_UL.length;  iUL++  )
					{

						if (  typeof( ar_UL[ iUL ] )  !=  "undefined"  )
						{
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "padding: 0px", "padding: 7px" );
						}


					}// for


					//---

					svg_04_outerHTML =  svg_04_obj.outerHTML;


					//$( "text" ).css( "font-size", "10px" );

					svg_04_outerHTML =  String( svg_04_outerHTML ).replaceAll(	"<text ",
																				"<text style='font-size: 21px; font-weight: bolder; margin:20px;' " );


				}// if (   svg_04_obj  !=  null  )

				//--------------------------

				//------------------------------------------------------------------------------




				if (   LINECHART_svg_MEDIA_allSLs_HORA_obj  !=  null  )
				{

					//window.alert( "AHOORAAA 2" );

					/*
					var ar_svg_circles = svg_01_obj.getElementsByClassName( "recharts-dot recharts-line-dot" );

					for (  let ics = 0;  ics <  ar_svg_circles.length;  ics++  )
					{
						console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );
						//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
						console.log(  ar_svg_circles[ ics ].outerHTML  );

						ar_svg_circles[ ics ].outerHTML = "";

						svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

					}// for
					*/


					var ar_svg_circles =  LINECHART_svg_MEDIA_allSLs_HORA_obj.getElementsByTagName( "circle" );
					var ar_svg_circles_length =  ar_svg_circles.length;

					while (  ar_svg_circles_length  >  0  )
					{

						for (  let ics = 0;  ics <  ar_svg_circles_length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );


							//LINECHART_svg_MEDIA_allSLs_HORA_obj.getElementsByTagName( "circle" )[ ics ].hidden = true;

							//LINECHART_svg_MEDIA_allSLs_HORA_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

							if (  typeof( ar_svg_circles[ ics ] )  !=  "undefined"  )
							{
								ar_svg_circles[ ics ].outerHTML = "";
							}

							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles =  LINECHART_svg_MEDIA_allSLs_HORA_obj.getElementsByTagName( "circle" );
						ar_svg_circles_length =  ar_svg_circles.length;

					}// while


					//////console.log( "LINECHART_svg_MEDIA_allSLs_HORA_obj .. QUITADO PUNTOS !!!!!" );

					//window.alert( "LINECHART_svg_MEDIA_allSLs_HORA_obj .. QUITADO PUNTOS !!!!!" );



					//------------


					var ar_UL =  document.getElementById( "id_RespCont_svg_MEDIA_allSLs_HORA" ).getElementsByTagName( "ul" );


					for (  let iUL = 0;  iUL <  ar_UL.length;  iUL++  )
					{

						if (  typeof(  ar_UL[ iUL ]  )  !=  "undefined"  )
						{
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "padding: 0px", "padding: 7px" );
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "margin: 0px", "margin-top: 50px" );
						}


					}// for


					//---

					LINECHART_svg_MEDIA_allSLs_HORA_obj_outerHTML =  LINECHART_svg_MEDIA_allSLs_HORA_obj.outerHTML;


					//$( "text" ).css( "font-size", "10px" );

					LINECHART_svg_MEDIA_allSLs_HORA_obj_outerHTML =  String( LINECHART_svg_MEDIA_allSLs_HORA_obj.outerHTML ).replaceAll(	"<text ",
																																			"<text style='font-size: 18px; font-weight: bolder; margin:20px;' " );


				}// if (   LINECHART_svg_MEDIA_allSLs_HORA_obj  !=  null  )



				//---------------------------------------------------------------------------


				//var AHORRO_ENERG_obj =  document.getElementById( "id_BAR_AHORRO" );
				//id_RP_AHORRO

				//id_RP_MEDIA_HORAS  id_BAR_MEDIA_HORAS
				//MEDIAS_HORAS_obj


				if (   MEDIAS_HORAS_obj  !=  null  )
				{



					var ar_svg_circles =  MEDIAS_HORAS_obj.getElementsByTagName( "circle" );
					var ar_svg_circles_length =  ar_svg_circles.length;

					while (  ar_svg_circles_length  >  0  )
					{

						for (  let ics = 0;  ics <  ar_svg_circles_length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );


							//MEDIAS_HORAS_obj.getElementsByTagName( "circle" )[ ics ].hidden = true;

							//MEDIAS_HORAS_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

							if (  typeof( ar_svg_circles[ ics ] )  !=  "undefined"  )
							{
								ar_svg_circles[ ics ].outerHTML = "";
							}

							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles =  MEDIAS_HORAS_obj.getElementsByTagName( "circle" );
						ar_svg_circles_length =  ar_svg_circles.length;

					}// while


					//////console.log( "MEDIAS_HORAS_obj .. QUITADO PUNTOS !!!!!" );

					//window.alert( "MEDIAS_HORAS_obj .. QUITADO PUNTOS !!!!!" );




					//------------


					var ar_UL =  document.getElementById( "id_RP_MEDIA_HORAS" ).getElementsByTagName( "ul" );


					for (  let iUL = 0;  iUL <  ar_UL.length;  iUL++  )
					{

						if (  typeof(  ar_UL[ iUL ]  )  !=  "undefined"  )
						{
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "padding: 0px", "padding: 7px" );
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "margin: 0px", "margin: 50px" );
						}


					}// for


					//---

					MEDIAS_HORAS_obj_outerHTML =  MEDIAS_HORAS_obj.outerHTML;


					//$( "text" ).css( "font-size", "10px" );

					MEDIAS_HORAS_obj_outerHTML =  String( MEDIAS_HORAS_obj.outerHTML ).replaceAll(	"<text ",
																											"<text style='font-size: 22px; font-weight: bolder; margin: 20px; padding: 20px;' " );


					//MEDIAS_HORAS_obj_outerHTML =  String( MEDIAS_HORAS_obj.outerHTML ).replaceAll(	"<tspan ",
					//																						"<tspan style='font-size: 18px; font-weight: bolder; margin: 20px; padding: 20px;' " );

					//-----------------------------------------------------------


					if (   (   MEDIAS_HORAS_obj  !=  undefined  )  &&  (   MEDIAS_HORAS_obj  !=  null  )   )
					{


						let newimg = document.createElement( "img" );


						//<img src="/img/logotipo-light.svg" alt="ILUMEK" height="25px">

						//newimg.innerHTML =  "src='http://localhost:3000/img/very_cloudy.svg'";	//"ADFFSDFSDFDSDFSDFDSFDSFSDFSFSDFSDSDSDFSD";

						newimg.src =  "http://localhost:3000/img/very_cloudy.svg";

						// <img src="https://api.ilumek.com/media//user_picture/temp.pngnameSol_dpAa7sO.png" alt="my_icon" width="10" height="10" style={{width: "10px"}}/>

						//document.body.appendChild(p);

						MEDIAS_HORAS_obj.appendChild(  newimg  );

					}//


				}// if (   MEDIAS_HORAS_obj  !=  null  )



				//-----------------------------



				//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


				//var AHORRO_ENERG_obj =  document.getElementById( "id_BAR_AHORRO" );
				//id_RP_AHORRO

				if (   AHORRO_ENERG_obj  !=  null  )
				{



					var ar_svg_circles =  AHORRO_ENERG_obj.getElementsByTagName( "circle" );
					var ar_svg_circles_length =  ar_svg_circles.length;

					while (  ar_svg_circles_length  >  0  )
					{

						for (  let ics = 0;  ics <  ar_svg_circles_length;  ics++  )
						{
							//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );

							//svg_01_obj.removeChild( ar_svg_circles[ ics ]  );
							//console.log(  ar_svg_circles[ ics ].outerHTML  );

							if (  typeof( ar_svg_circles[ ics ] )  !=  "undefined"  )
							{
								ar_svg_circles[ ics ].outerHTML = "";
							}

							//svg_01_obj.getElementsByTagName( "circle" )[ ics ].outerHTML = "";

						}// for

						//---

						ar_svg_circles =  AHORRO_ENERG_obj.getElementsByTagName( "circle" );
						ar_svg_circles_length =  ar_svg_circles.length;

					}// while


					//////console.log( "AHORRO_ENERG_obj .. QUITADO PUNTOS !!!!!" );

					//window.alert( "AHORRO_ENERG_obj .. QUITADO PUNTOS !!!!!" );



					//------------


					var ar_UL =  document.getElementById( "id_RP_AHORRO" ).getElementsByTagName( "ul" );


					for (  let iUL = 0;  iUL <  ar_UL.length;  iUL++  )
					{

						if (  typeof(  ar_UL[ iUL ]  )  !=  "undefined"  )
						{
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "padding: 0px", "padding: 7px" );
							ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "margin: 0px", "margin-top: 50px" );
						}


					}// for


					//---

					AHORRO_ENERG_obj_outerHTML =  AHORRO_ENERG_obj.outerHTML;


					//$( "text" ).css( "font-size", "10px" );

					AHORRO_ENERG_obj_outerHTML =  String( AHORRO_ENERG_obj.outerHTML ).replaceAll(	"<text ",
																									"<text style='font-size: 23px; font-weight: bolder; margin: 20px;' " );


				}// if




				//------------------------------------------------------------------------------------------------
				//------------------------------------------------------------------------------------------------



				//weeks_RespContainer_SL0_W0 		/ 		weeks_svg_SL0_W0



				ar_obj_SVG__SL_WEEK = new Array( /*5*/ NUM_SEMANAS_mes  );

				ar_obj_SVG__SL_WEEK[ 0 ] = new Array(  			 _obj_Inst_Group.info.streetlights.length  );	// _ar_ALL__STREETLIGHTS_NAMES.length
				ar_obj_SVG__SL_WEEK[ 1 ] = new Array(  			 _obj_Inst_Group.info.streetlights.length   );
				ar_obj_SVG__SL_WEEK[ 2 ] = new Array( 			 _obj_Inst_Group.info.streetlights.length   );
				ar_obj_SVG__SL_WEEK[ 3 ] = new Array( 			 _obj_Inst_Group.info.streetlights.length   );


				if (  NUM_SEMANAS_mes  ==  5  )
				{
					ar_obj_SVG__SL_WEEK[ 4 ] = new Array(  			 _obj_Inst_Group.info.streetlights.length   );
				}//



				//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ]





				aux__SVG_SL_WEEK__boolean_existe =  true;



				for (  let mySLS = 0;  mySLS < _obj_Inst_Group.info.streetlights.length;  mySLS++  )	// _ar_ALL__STREETLIGHTS_NAMES.length
				{

					//---------

					for (  let iweek = 0;  iweek <= /*4*/ ( NUM_SEMANAS_mes - 1 );  iweek++  )		// poner a 4
					{

						ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ] =  document.getElementById( "weeks_svg_SL"  + mySLS +  "_W"  +  iweek );


						//---

						if (  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ]  !=  null  )
						{

							//console.log( ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].length );

							if (  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].innerHTML.length  >  0  )
							{


								var ar_svg_circles =  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].getElementsByTagName( "circle" );

								var ar_svg_circles_length =  ar_svg_circles.length;

								while (  ar_svg_circles_length  >  0  )
								{

									for (  let ics = 0;  ics <  ar_svg_circles_length;  ics++  )
									{
										//console.log( "CIRCULO " + ics +  ":  "  +  ( ( ar_svg_circles[ ics ] )  ) );


										if (  typeof( ar_svg_circles[ ics ] )  !=  "undefined"  )
										{
											ar_svg_circles[ ics ].outerHTML = "";
										}


									}// for

									//---

									ar_svg_circles =  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].getElementsByTagName( "circle" );
									ar_svg_circles_length =  ar_svg_circles.length;

								}// while


								//////console.log( "ar_obj_SVG__SL_WEEK[ " + iweek + " ][ " + mySLS + " ] .. QUITADO PUNTOS !!!!!" );




								//------------

								/*
								var ar_UL =  document.getElementById( "chart_04_prueba" ).getElementsByTagName( "ul" );


								for (  let iUL = 0;  iUL <  ar_UL.length;  iUL++  )
								{

									if (  typeof( ar_UL[ iUL ] )  !=  "undefined"  )
									{
										ar_UL[ iUL ].outerHTML =   ar_UL[ iUL ].outerHTML.replaceAll( "padding: 0px", "padding: 7px" );
									}


								}// for
								*/

								//---------



								// ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ] =    document.getElementById( "weeks_svg_SL"  + mySLS +  "_W"  +  iweek );


								//--------------------------------------------

								//id="weeks_svg_SL8_W4-clip"><rect x="105" y="20" height="330" width="1009"></rect></clipPath>

								//--------------------------------------------

								var ar_new_X_elems =  [];

								var ar_Text_objs = [];


								ar_Text_objs =  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].getElementsByTagName( "text" );

								if (
										(  ar_Text_objs  != undefined  )  &&
										(  ar_Text_objs.length  >  0  )
								)
								{

									var ar_Text_obs_bottom = [];


									for (  let  ios =  0;  ios  <   ar_Text_objs.length ;  ios++  )
									{

										if (  ar_Text_objs[  ios  ].getAttribute( "orientation"  )  ==   "bottom"  )
										{
											ar_Text_obs_bottom.push(  ar_Text_objs[  ios  ]  );

										}// if ----

									}// for : ios -------------------



									//------------------------------


									//const const_pos_0 =  0;


									var ar_aux_clipPath =  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].getElementsByTagName( "clipPath" )[ 0 ];

									var ar_aux_clipPath_rect =  ar_aux_clipPath.getElementsByTagName( "rect" )[ 0 ];


									//console.log( ar_aux_clipPath );

									//console.log( ar_aux_clipPath_rect.getAttribute( "x" ) );
									//console.log( ar_aux_clipPath_rect.getAttribute( "width" ) );


									var X_inicial_linea =  ar_aux_clipPath_rect.getAttribute( "x" );	//parseFloat(  ar_Text_obs_bottom[  const_pos_0  ].getAttribute(  "x"  )  );
									var ancho_grafica   =  ar_aux_clipPath_rect.getAttribute( "width" );	//parseFloat(   ar_Text_obs_bottom[  const_pos_0  ].getAttribute( "width" )   );



									//console.log( "X_inicial_linea: " + X_inicial_linea );
									//console.log( "ancho_grafica: "  +  ancho_grafica );


									var num_days_week =   (  iweek  <=  3  )   ?    7   :    ( NUM_DAYS_MONTH  -  28 );



									var new_X_margin =  parseFloat(   ancho_grafica   /  (  num_days_week    )   );

									//console.log(  "new_X_margin: "  +  ( new_X_margin )  );



									var INC_x =  parseFloat(  ( new_X_margin ) /  2.0  );

									//console.log( "INC_x: " + INC_x );



									var SUM_iniX_INCx =  parseFloat( X_inicial_linea )  +  INC_x;

									//console.log( "SUM_iniX_INCx: " + SUM_iniX_INCx );







									//----

									for (  let  ios =  0;  ios  <   ar_Text_obs_bottom.length;  ios++  )
									{

										//var val_text_X =  parseFloat(   ar_Text_obs_bottom[  ios  ].getElementsByTagName( "tspan" )[ 0 ].getAttribute( "x" )   )  +  ;


										var new_X =  (   SUM_iniX_INCx   +    parseFloat(  new_X_margin  *  ios    )   );


										//console.log(  "Math.round( new_X ): "  +  Math.round( new_X )  );


										ar_new_X_elems.push(  Math.round( new_X )  );


										//window.alert(  new_X  );


										ar_Text_obs_bottom[  ios  ].getElementsByTagName( "tspan" )[ 0 ].setAttribute(  "x",  Math.round( new_X )  );


										ar_Text_obs_bottom[  ios  ].setAttribute(   "x",  0   );   /*Math.round( new_X )*/

									}// for : ios -------------------



									//------------------------



									//var ar_new_X_elems =  [];

									var ar_Line_objs = [];

									ar_Line_objs =  ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].getElementsByTagName( "line" );


									if (
											(  ar_Line_objs  != undefined  )  &&
											(  ar_Line_objs.length  >  0  )
									)
									{

										var ar_Line_obs_bottom_Class = [];

										for (  let  ios =  0;  ios  <   ar_Line_objs.length ;  ios++  )
										{

											if (  ar_Line_objs[  ios  ].getAttribute( "class"  )  ==   "recharts-cartesian-axis-tick-line"  )
											{
												if (  ar_Line_objs[  ios  ].getAttribute( "orientation"  )  ==   "bottom"  )
												{
													ar_Line_obs_bottom_Class.push(  ar_Line_objs[  ios  ]  );
												}//

											}// if ----

										}// for : ios -------------------


										//------------------------------


										for (  let  ios =  0;  ios  <   ar_Line_obs_bottom_Class.length;  ios++  )
										{
											ar_Line_obs_bottom_Class[  ios  ].setAttribute(  "x",    0   );   //ar_new_X_elems[  ios  ]

											ar_Line_obs_bottom_Class[  ios  ].setAttribute(  "x1",   ar_new_X_elems[  ios  ]   );
											ar_Line_obs_bottom_Class[  ios  ].setAttribute(  "x2",   ar_new_X_elems[  ios  ]   );

										}// for : ios -------------------

									}// if




								}// if



								//----



									/*
									ar_Text_objs.forEach(

														( item, index ) =>
														{

															if (  item.getAttribute( "orientation"  )  ==   "bottom"  )
															{
																window.alert(  item.getAttribute( "x" )  );
															}// if

														}//

									);// .forEach()
									*/
							}
							else
							{
								aux__SVG_SL_WEEK__boolean_existe =  /*aux__SVG_SL_WEEK__boolean_existe  &&*/  false;
							}//

						}// if
						else
						{
							aux__SVG_SL_WEEK__boolean_existe =  /*aux__SVG_SL_WEEK__boolean_existe  &&*/  false;
						}//

					}// for : iweek




				}// for : mySLS


				//--------------------------------------------------------------------------------------
				//------------

				//isAnimationActive={false}


				// http://localhost:3000/img/very_cloudy.svg


				//return (	<img src="http://localhost:3000/img/very_cloudy.svg" alt="my_icon" width="10" height="10" style={{width: "10px"}}/>		);



				//var MEDIAS_HORAS_obj = document.getElementById( "id_RP_MEDIA_HORAS" );

				/*
				if (   (   MEDIAS_HORAS_obj  !=  undefined  )  &&  (   MEDIAS_HORAS_obj  !=  null  )   )
				{


					let newimg = document.createElement( "img" );


					//<img src="/img/logotipo-light.svg" alt="ILUMEK" height="25px">

					newimg.innerHTML =  "src='http://localhost:3000/img/very_cloudy.svg'";	//"ADFFSDFSDFDSDFSDFDSFDSFSDFSFSDFSDSDSDFSD";

					newimg.src =  "http://localhost:3000/img/very_cloudy.svg";

					// <img src="https://api.ilumek.com/media//user_picture/temp.pngnameSol_dpAa7sO.png" alt="my_icon" width="10" height="10" style={{width: "10px"}}/>

					//document.body.appendChild(p);

					MEDIAS_HORAS_obj.appendChild(  newimg  );

				}//
				*/





			}//	if ( ( _cont_rerender_svg ==  0 /*0*/ )  &&	( this.state.sunRise_sunSet_GET == "true" )	)


			//------------------------------------------------------------------------------------------------




			//--------------------------



			if (  (   svg_01_obj  !=  null  )   &&

				  (  aux__SVG_SL_WEEK__boolean_existe  == true  )


				  //(   svg_02_obj  !=  null  )  &&
				  //(   svg_03_obj  !=  null  )  &&
				  //(   svg_04_obj  !=  null  )
			)
			{


				//svg_01_outerHTML = ""; 		//.replaceAll(	"<text ",
											//	"<text style='font-size: 10px' " );


				//window.alert( "YA!:  _Num_Renders_RENDER_FUNC = "  +  _Num_Renders_RENDER_FUNC );

				//console.log( "svg outerHTML:  \n"  +  /*document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML*/ svg_01_outerHTML );


				//----------------------------------------------------

				// TO-DO:

				// IDIOMAS TEXTO
				// VARS GENERALES DE TOMA DE DATOS
				// VARS GENERALES DE MAQUETACIÓN





				/*
				var _ar_DATE_WEEK;    // ARRAY DE DATES DE LUNES A DOMINGO DE LA SEMANA SOLICITADA
				var _ar_STR_DATE_WEEK;

				//--

				var _ar_STR_sunriseWEEK;
				var _ar_STR_sunsetWEEK;


				// ---------------------------------------------

				var STR_meses =	[
				*/



				/*
				objINSTALLATION_GROUP._installation_GROUP__id =  result.data.id;


				//objINSTALLATION_GROUP._installation__selected =  result.data.installations[  0  ];


				var inst_selected_AUX =  result.data.installations[  0  ];

				objINSTALLATION_GROUP._installation__selected__indiceNum =  0;
				objINSTALLATION_GROUP._installation__selected__id =  inst_selected_AUX.id;		//  objINSTALLATION_GROUP._installation__selected__indiceNum =  0;


				objINSTALLATION_GROUP._installation_selected__name =  inst_selected_AUX.installation_name;

				objINSTALLATION_GROUP._installation_selected__NUM_SLs =  inst_selected_AUX.streetlights.length;

				objINSTALLATION_GROUP._installation_selected__installation_date =  inst_selected_AUX.installation_date;

				objINSTALLATION_GROUP._installation_selected__total_power =  inst_selected_AUX.total_power;


				objINSTALLATION_GROUP._installation_selected__installation_client__id =  inst_selected_AUX.installation_client;

				//console.log( "data Installation Group ::  "  +  JSON.stringify( objINSTALLATION_GROUP )   );
				*/



				//var str_mes_elegido = "Enero";
				//var str_ano_elegido = "2021";





				//----------------------------------------------------

				// PERFIL DIMMING ENERO:

				/*
																											0  +  (10* 70)  +  (16*  20)  +  (7* 70) + 0    = 1510

					------------------------



				0  +  (10* 40)  +  (16*  10)  +  (7* 40) + 0    = 840

				*/


																										// DE ENERO A FEB, DESCENSO DE UN ( 1170 / 1510 ) =  0.775

																										// 0.991 HORAS

					// DE ENERO A FEB, INCREMENTO DE UN ( 1170 / 840 ) =  1.393

					// 0.991 HORAS



				// PERFIL DIMMING FEBRERO:

				/*

				0 + (10* 50) + (16* 20) + (7* 50) + 0 =  1170

				*/


				//-----------


				//////console.log(  this.props.auth.user.user_profile  );



				var SUMATORIO_DIA_ALLSL_AHORRO =  0;


				for ( let iiday = 0;  iiday < NUM_DAYS_MONTH;  iiday++  )
				{

					SUMATORIO_DIA_ALLSL_AHORRO +=  ar_Ahorro_Dia__AllSLs[  iiday  ];

				}// for : iiday - - - - - - - - - - - - - - - - - - - - - - - - - - - - -




				var DATO_Resumen_Mensual__Num_Horas_Ilum =  	Horas_TOTALES_MES_LUMINARIA_allSLs;
				var DATO_Resumen_Mensual__Ahorro_Energetico =   Math.round(  SUMATORIO_DIA_ALLSL_AHORRO  );			// /  parseFloat( 1000.0 )



																												// PARA ÉSTE ÍNDICE HABRÍA QUE COGER EL AMANECEER Y ATARDECER DEL DIA 15 DEL MES ACTUAL Y EL DEL DÍA 15 DEL MES PRÓXIMOL, Y VER LA CORRELACIÓN DE HORAS DE LUZ, DE AHI SACAR EL ÍNDICE


				// IND_fmul_SIG_Ahorro_Energ_Mens =  parseFloat(  suma_Horas_Reales_SIG_TEORICAS  /  suma_Horas_Reales_TEORICAS  );

				var DATO_PREVISION_PROX_Mes__Num_Horas_Ilum =  Math.round( /*Horas_TOTALES_MES_LUMINARIA_allSLs * parseFloat( 0.9479 )*/  suma_Horas_Reales_SIG_TEORICAS  );
				var DATO_PREVISION_PROX_Mes__Ahorro_Energetico =  Math.round(  SUMATORIO_DIA_ALLSL_AHORRO   *  IND_fmul_SIG_Ahorro_Energ_Mens	/*parseFloat( 0.9479 )  //  +*/    );	 			// 0.775




				// 16 ene ->    8:35 - 17:59   =   1:25 + 8 - 00:01    =   1:24 + 8  =   9:24

				// 16 feb ->    8:02 - 18:41   =   2 - 00:02  +  8:41  =   10:39

																		//		DIF:  + 01:15  =  75 minutos

																		// 1 dia =  24 *  60 min =  1440

																								//    75  /  1440  =  0.0521  INCREMENTO

																											//  =   *  1.0521         =   CANTIDAD + ( CANTIDAD * 0.0521 )



																											//    75  /  1440  =  0.0521  DECREMENTO DE HORAS ILUMINACION Y DE AHORRO

																											//   CANTIDAD - ( CANTIDAD * 0.0521 )  =  CANTIDAD * (  1 -  0.0521  )  =  CANTIDAD *  0.9479



				//-----


				// SI NO SE PUEDE EXTRAER DESDE LA CONSULTA INICIAL AXIOS QUE SE HACE A LA INSTALLATION GROUP (PERO DESDE EL USER PROPIO A LA INSTALACION).... (Ó CALCULANDO EL SUMATORIO ENTRE LAS FAROLAS "USED_ENERGY")

				//window.alert(  this.props.auth.user.summary_info.total_used_energy  );



				//console.log(  this.props.auth.user  );

				//console.log( "_obj_Inst_Group: " );
				//console.log( _obj_Inst_Group );

				//--

													// SERGIO :: 08/05/23

				let TOTAL_ALL_SLS__USED_ENERGY  =  _obj_Inst_Group.info.total_used_energy;			//this.props.auth.user.summary_info.total_used_energy;



				//------------------------------------------------------------------------------------------------


															//var DATO_AHORRO_Wh_ACUM_float =  (   parseFloat(  SUMATORIO_DIA_ALLSL_AHORRO  /*+ 913124.1*/   )   )  /*+    parseFloat( 1000.0  *  188.403 )*/;	// DATOS TABLA MARIA



				var DATO_AHORRO_Wh_ACUM_float =  (   parseFloat(  TOTAL_ALL_SLS__USED_ENERGY  )   );



				//------------------------------------------------------------------------------------------------



				var DATO_AHORRO_KWh_ACUM =  Math.round(   DATO_AHORRO_Wh_ACUM_float  / 1000.0  );




				//------------------------------------------------------------------------------------------------
				//-----

				// NUEVO COEFICIENTE, HABLADO CON ARNAITZ EL V-06/05/22 =>  0.25    // PUESTO COMO EN EL CÁLCULO DE LA PÁGINA INICIAL DE ILUMEK			// *  parseFloat( 0.19  )		 // 0.25

				// 17/05/22 CAMBIAMOS EL COEFICIENTE, SEǴUN LA NUEVA TABLA/EXCEL PROPORCIONADA POR ARNAITZ, DE "0.25" A "0.14"

				var DATO_kg_CO2 =  Math.round(  parseFloat( DATO_AHORRO_Wh_ACUM_float / 1000.0 )  *  parseFloat( 0.14  )  );


				var DATO_g_SO2 =   Math.round(  parseFloat( DATO_AHORRO_Wh_ACUM_float / 1000.0 )  *  parseFloat( 0.536  )  );


				var DATO_g_NOx =  Math.round(  parseFloat( DATO_AHORRO_Wh_ACUM_float / 1000.0 )  *  parseFloat( 0.368  )  );


				//------------------------------------------------------------------------------------------------



				var DATO_NUM_Arboles =   Math.round(     ( /*CO2 en float*/ parseFloat( DATO_AHORRO_Wh_ACUM_float / 1000.0 )  *  parseFloat( 0.19  ) )  *  parseFloat(  0.1  )   );


				//-------


				var DATO_AHORRO_Wh_DIARIO =  Math.round( SUMATORIO_DIA_ALLSL_AHORRO  /  parseFloat( NUM_DAYS_MONTH ) );



				//----------------------------------------------------

				/*
				console.log( "______DATOS TOTAL USED ENERGY SL________:"  );

				for (  let jjj = 0;  jjj < _obj_Inst_Group.info.streetlights.length; jjj++ )
				{

					console.log( "SL [" +  jjj  +  "]_"  +   _obj_Inst_Group.info.streetlights[ jjj ].name    +  " =  "  +   _obj_Inst_Group.info.streetlights[ jjj ].total_used_energy  );

				}// for : jjjj
				*/


				//----------------------------


				/*
				if (  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME  !=  "480167f000m"  )
				{
					GLOB_numSL_Grafica_AUX =  5;
				}//
				*/


				//---------------------------

				var DATOS_SL_MATXINPORTA = new Array(  _obj_Inst_Group.info.streetlights.length  );


				/*

				00005922F000M 	(0)
				00005923F000M 	(1)
				00005924F000M 	(2)


				00005925F000M 	(3)
				00005926F000M 	(4)
				00005927F000M 	(5)


				00005928F000M 	(6)
				00005929F000M 	(7)
				00005930F000M 	(8)

				*/

				//---------


				DATOS_SL_MATXINPORTA[ 0 ] = {
												SL_NAME: "00005922F000M",		//"480166f000m",


												NUM_UBIC: "Ubicación 2",

												NUM_ID: "1002040",

												NEW_NAME: "URR1_M"
											};


				DATOS_SL_MATXINPORTA[ 1 ] = {
												SL_NAME: "00005923F000M",					//"480166f001s",


												NUM_UBIC: "Ubicación 5",

												NUM_ID: "1002043",

												NEW_NAME: "URR1_S1"
											};


				DATOS_SL_MATXINPORTA[ 2 ] = {
												SL_NAME: "00005924F000M",					//"480166f002s",


												NUM_UBIC: "Ubicación 7",

												NUM_ID: "1002045",

												NEW_NAME: "URR1_S2"
											};


				//--------



				DATOS_SL_MATXINPORTA[ 3 ] = {
												SL_NAME: "00005926F000M",					//"480166f003s",


												NUM_UBIC: "Ubicación 4",

												NUM_ID: "1002042",

												NEW_NAME: "URR1_S3"
											};


				DATOS_SL_MATXINPORTA[ 4 ] = {
												SL_NAME: "00005927F000M",					//"480166f004s",


												NUM_UBIC: "Ubicación 8",

												NUM_ID: "1002046",

												NEW_NAME: "URR1_S4"
											};

				DATOS_SL_MATXINPORTA[ 5 ] = {
												SL_NAME: "00005925F000M",					//"480167f000m",

												NUM_UBIC: "Ubicación 6",

												NUM_ID: "1002044",

												NEW_NAME: "URR2_M"
											};




				//--------



				DATOS_SL_MATXINPORTA[ 6 ] = {
												SL_NAME: "00005928F000M",					//"480167f001s",


												NUM_UBIC: "Ubicación 9",

												NUM_ID: "1002047",

												NEW_NAME: "URR2_S1"
											};


				DATOS_SL_MATXINPORTA[ 7 ] = {
												SL_NAME: "00005929F000M",					//"480167f002s",


												NUM_UBIC: "Ubicación 3",

												NUM_ID: "1002041",

												NEW_NAME: "URR2_S2"
											};


				DATOS_SL_MATXINPORTA[ 8 ] = {
												SL_NAME: "00005930F000M",					//"480167f003s",


												NUM_UBIC: "Ubicación 1",

												NUM_ID: "1002039",

												NEW_NAME: "URR2_S3"
											};



				//----------------------------------------------------



				//JSON.stringify( _obj_Inst_Group.installations[0].streetlights[ 0 ].luminary );


				const size_H_svg_SL_WEEK =  500;	// HORIZONTAL
				const size_V_svg_SL_WEEK =  150;	// VERTICAL



				const font_size_Titulares = 10;

				const font_size_Leyenda_MEDIA = 7;

				const font_size_texto = 8;

				var porcentaje_width_col_1 = 67;
				var porcentaje_width_col_2 = 100 - porcentaje_width_col_1;


				//nominal_voltage: 24
				//console.log( "AQUIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII" );
				//console.log(  _obj_Inst_Group.installations[0].streetlights[0].battery  );


				//---------------------------------------


													/*
														//STR_meses

														thisYEAR =  our_YEAR;	//our_monday_date.getFullYear();

														NUM_MES_Selected =   our_MONTH_ANT;		//;our_monday_date.getMonth()  +  1; 		//  Desde 1 hasta 12 ("getMonth()" devuelve de 0-11)

														//window.alert(  NUM_MES_Selected  );

														NUM_DAYS_MONTH =  	DaysMonth 	(
														*/


				const STR_FECHAS_CABECERA = 	"Del "   +  "01"  +  " de "  +  STR_meses[ NUM_MES_Selected - 1 ]  +  " "  +  thisYEAR  +

												"\nal "  +  String(  NUM_DAYS_MONTH  )  /*STR_DiAS_MES[ STR_DiAS_MES.length - 1 ].slice( 0, 2 )*/ /*+ "31"*/  +  " de "  +  STR_meses[ NUM_MES_Selected - 1 ]  +  " "  +  thisYEAR;		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,



				//--

				my_docDefinition =	{
										info:
										{
											title: 'EKIONA PDF',		//'EkionaPDF_semana_'  +  _lunes_INICIO_STR_completo,	//str_mes_elegido + "_" + str_ano_elegido,

											author: 'EKIONA',
										},

										pageSize: 'A4',

										pageMargins: [ 35, 40, 35, 35 ],	// 	pageMargins: [ 35, 40, 35, 40 ],

										alignment: 'center',


										//docName: 'Ekiona',
										//name: 'Ekiona',


										//--------------------------------------------------------------------------------------------

										content:
										[


											// content[ 0 ]

											// CABECERRA TODAS LAS PÁGINAS
											{

												margin: [ 0, 0, 0, -3 ],  //[ 0, 0,		5, 0 ],
												style: 'header',
												alignment: 'center',
												width: '*',


												stack:
												[

													// SOL DE FONDOA
													{
														//margin: [ -15, 0, 0, 0 ],

														alignment: 'center',
														//style: 'header',

														image: 'sol.PNG',  //'sampleImage.jpg'//,

														absolutePosition: { x: -210, y: 705 },

														fit: [ 330, 220 ],
													},




													// HEADER
													{
														margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
														style: 'header',
														alignment: 'left',



														/*
														background:	{
																		image: 'sol.PNG',

																		opacity: 0.5,	//0.05,

																		//width: '100%',

																		absolutePosition: { x: 150, y: 250 },
																	},
														*/


														columns:
														[
															// LOGO EKIONA, ZONA IZQUIERDA
															{
																margin: [ -15, 0, 0, 0 ],
																alignment: 'left',
																//style: 'header',

																image: 'ekiona_logo.png',  //'sampleImage.jpg'//,

																fit: [ 200, 180 ],
															},



															// ZONA DCHA
															{
																margin: [ 0, 10,		2, 0 ],	// margin: [ 0, 10,		10, 0 ],

																alignment: 'right',	// 'justify'

																columns:
																[
																	{
																		margin: [  0, 1,	-21, 0 ],
																		alignment: 'right',
																		//style: 'header',

																		text: 'Informe técnico',  //'sampleImage.jpg'//,

																		fontSize: 17,
																		bold: true,
																		//style: ['bold'],
																		//fit: [150, 100],
																	},

																	{
																		margin: [ 0, 0,	0, 0 ],
																		alignment: 'right',
																		//style: 'header',

																		image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																		fit: [105, 80],
																	},
																],
															}
														],
													},



													// MES, AÑO
													{
														margin: [ 0, -38,		5, 0 ],	// margin: [ 0, -38,		15, 0 ],
														alignment: 'right',
														//style: 'header',


														/*
														_ar_NUM_dayWEEK = new Array();
														_ar_STR_dayWEEK = new Array();

														_ar_NUM_monthWEEK = new Array();
														_ar_STR_monthWEEK = new Array();

														_ar_NUM_yearWEEK = new Array();
														_ar_STR_yearWEEK = new Array();
														*/

															/*
														text:	"Del "   +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																"\nal "  +  _ar_STR_dayWEEK[ 6 ]  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

														*/


														/*
														//STR_meses

														thisYEAR =  our_YEAR;	//our_monday_date.getFullYear();

														NUM_MES_Selected =   our_MONTH_ANT;		//;our_monday_date.getMonth()  +  1; 		//  Desde 1 hasta 12 ("getMonth()" devuelve de 0-11)

														//window.alert(  NUM_MES_Selected  );

														NUM_DAYS_MONTH =  	DaysMonth 	(
														*/


														text:	STR_FECHAS_CABECERA,		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,


														//text:	"Del "   +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
														//		"\nal "  +  STR_DiAS_MES[ STR_DiAS_MES.length - 1 ].slice( 0, 2 ) /*+ "31"*/  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,


														fontSize: 10,
														bold: true,

														//fit: [180, 150],
													},










													{
														text:	"Página 1 / "  +  String(  2  +  _obj_Inst_Group.info.streetlights.length  ),		 //,


														fontSize: font_size_texto  +  0, // + 1,
														//bold: true,

														absolutePosition: { x: 45, y: 797 },	// { x: 34, y: 798 },

														//margin: [ 0, 0,		 0, 0 ],

														alignment: 'center',
														//width: 500

														//fit: [250, 150],
														//width: '*',
													},



												],
											},






											/*

											{
												margin: [ 20, 20,		0, 0 ],

												aligment: 'center',

												text:	"\n" +
														this.state.ar_sunriseDAY[0]  +  " " +
														this.state.ar_sunriseDAY[1]  +  " " +
														this.state.ar_sunriseDAY[2]  +  " " +
														this.state.ar_sunriseDAY[3]  +  " " +
														this.state.ar_sunriseDAY[4]  +  " " +
														this.state.ar_sunriseDAY[5]  +  " " +
														this.state.ar_sunriseDAY[6]  +  "\n" +


														this.state.ar_sunsetDAY[0]  +  " " +
														this.state.ar_sunsetDAY[1]  +  " " +
														this.state.ar_sunsetDAY[2]  +  " " +
														this.state.ar_sunsetDAY[3]  +  " " +
														this.state.ar_sunsetDAY[4]  +  " " +
														this.state.ar_sunsetDAY[5]  +  " " +
														this.state.ar_sunsetDAY[6],						//  +  "\n\n",

												fontSize: 10,
											},

											*/


											/*
											// GRAFICA    ------------------------
											{
												margin: [ 10, 10,		0, 20 ],
												alignment: 'center',

												//width: '*',


												//fit: [105, 80],

												stack:
												[
													{
														svg:  svg_04_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML



														margin: [ 5, 5,	5, 20 ],


														//fontSize: 5,

														alignment: 'center',

														//width: '*',


														fit: [ 530, 350 ],





														//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,
														//fit: [450, 250],

														//relativePosition: { x: -20, y: 0 },

														//width: 500


														//margin: [ 0, 10, 	10, 10 ],
														//alignment: 'center',

														//width: '100%',
													},
												],
											},
											*/




											/*
											// GRAFICA    ------------------------
											{
												margin: [ 10, 10,		0, 0 ],
												alignment: 'center',

												//width: '*',


												//fit: [105, 80],

												stack:
												[
													{
														svg:  svg_03_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML



														margin: [ 5, 5,	15, 5 ],


														//fontSize: 5,

														alignment: 'center',

														//width: '*',


														fit: [ 270, 180 ],





														//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,
														//fit: [450, 250],

														//relativePosition: { x: -20, y: 0 },

														//width: 500


														//margin: [ 0, 10, 	10, 10 ],
														//alignment: 'center',

														//width: '100%',
													},
												],
											},
											*/








											//-----------------------------------------------------


											// content[ 1 ]

											// "BODY"
											{
												margin: [ 0,20,		0, 0 ],  //5, 0 ],
												alignment: 'center',

												width: '*',



												//--------

												stack:
												[

													// ROW "DATOS INSTALACION" + "DATOS DE CLIENTE"
													{
														margin: [ 0,20, 0, 0 ],
														alignment: 'center',
														width: '100%',

														columns:
														[
															{
																margin: [ 0, 0,		0, 0 ],
																alignment: 'left',
																width: porcentaje_width_col_1 + '%',

																//background: "grey",

																//color: "grey",


																stack:
																[
																	// CABECERA COLUMNA IZQUIERDA
																	{
																		margin: [ 0, 0,		 0, 0 ],

																		alignment: 'left',
																		//width: 500

																		width: '*',

																		height: 'auto',

																		stack:
																		[
																			{
																				margin: [ 0, -4,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='1' ry='1' width='345' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +	//  height='16
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: 0, y: -1 },

																				opacity: 1.0,
																			},

																			{
																				text: "DATOS DE TU INSTALACIÓN",  //,
																				//fit: [250, 150],
																				fontSize: font_size_Titulares,
																				bold: true,

																				color: "white",

																				margin: [ 5, 2,		 0, 0 ],

																				alignment: 'left',
																				//width: 500
																				width: '*',
																			},
																		],

																	},


																	// SUBCOLUMNAS DEBAJO DE CABECERA ANTERIOR
																	{
																		margin: [ 0, 10,		0, 10 ],
																		alignment: 'center',
																		width: '*',

																		stack:
																		[
																			{
																				margin: [ 0, 0,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='4' ry='4' width='345' height='88' style='fill:rgb(220,220,230); stroke:rgb(220,220,230); stroke-width:5; opacity:1.0' />" +		//  height='75
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: 0, y: 0 },

																				opacity: 1.0,
																			},


																			/*
																			const font_size_Titulares = 10;

																			const font_size_Leyenda_MEDIA = 7;

																			const font_size_texto = 8;
																			*/

																			{
																				margin: [ 20, 15,		0, 0 ],
																				alignment: 'center',
																				width: '*',

																				columns:
																				[
																					{
																						stack:
																						[

																							{
																								text:
																										_obj_Inst_Group.group_name,  //objINSTALLATION_GROUP._installation_selected__name	/*"Arbide"*/ + "\n" +

																								fontSize: 10,	//font_size_texto,

																								bold: true,

																								margin: [ 0, -2,		 0, 6 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								width: '*',
																							},


																							{
																								text:
																										"Nº de farolas:                                               " +	_obj_Inst_Group.info.streetlights.length	+	"\n"	+	/*+ objINSTALLATION_GROUP._installation_selected__NUM_SLs*/ /*"<b>13</b>"*/ /*+ "\n" +*/ //,
																										"Fecha de instalación:                   " +	_obj_Inst_Group.installation_date.split( '-' )[2]  + "/" + _obj_Inst_Group.installation_date.split( '-' )[1] + "/"	+ _obj_Inst_Group.installation_date.split( '-' )[0].slice(2,4) +	"\n"	+	// 02/06/21		 /*+  objINSTALLATION_GROUP._installation_selected__installation_date*/ /*"08/10/20"*/ /*+ "\n" +*/  //,
																										"Potencia total de instalación:          " +	_obj_Inst_Group.info.total_power + " W", /*+ String( objINSTALLATION_GROUP._installation_selected__total_power ) + " W",*/ /*"80W",*/  //,

																								fontSize: 9,	//font_size_texto,

																								bold: true,

																								margin: [ 0, 3,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								width: '*',
																							},
																						],
																					},




																					{

																						margin: [ -15, 13,		0, 0 ],
																						alignment: 'center',
																						width: '*',


																						stack:
																						[

																							{
																								text:
																										"Luminaria:               " + _obj_Inst_Group.installations[0].streetlights[0].luminary.led_power +  "  W\n" +

																										"Panel:                    " + _obj_Inst_Group.installations[0].streetlights[0].panel.peak_power  + "  Wp" + "\n" + //,

																										"Batería:               " +  (  _obj_Inst_Group.installations[0].streetlights[0].battery.nominal_voltage  *  _obj_Inst_Group.installations[0].streetlights[0].battery.capacity_ah  )  + "  Wh",  //,

																										//"Sensores: " + "80W",  //,


																								fontSize: 9,	//font_size_texto,

																								bold: true,

																								margin: [ 55, 6,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								width: '*',
																							},



																							{
																								text:
																										_obj_Inst_Group.installations[0].streetlights[0].battery.nominal_voltage  +  "  V - "  +  _obj_Inst_Group.installations[0].streetlights[0].battery.capacity_ah  + "  Ah" + "\n", // +  //,

																										//"Sensores: " + "80W",  //,


																								fontSize: 9,	//font_size_texto,

																								bold: true,

																								margin: [ 97, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								width: '*',
																							},
																						],
																					},
																				],
																			},
																		],
																	},
																]
															},

															// COLUMNA DE LA DERECHA
															{
																margin: [ 5, 0,		0, 0 ],
																alignment: 'right',
																width: porcentaje_width_col_2 + '%',

																stack:
																[
																	{
																		stack:
																		[
																			{
																				margin: [ 0, -4,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='1' ry='1' width='170' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: -1 },

																				opacity: 1.0,
																			},

																			{
																				text: "DATOS DE CLIENTE",
																				//fit: [250, 150],
																				fontSize: font_size_Titulares,
																				bold: true,

																				color: "white",

																				margin: [ 2, 2, 0, 0 ],

																				alignment: 'left',
																				//width: 500
																			},

																		],
																	},


																	{
																		stack:
																		[
																			{
																				margin: [ 0, 10,	0, 0 ],
																				alignment: 'right',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='4' ry='4' width='170' height='88' style='fill:rgb(220,220,230); stroke:rgb(220,220,230); stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: 0 },

																				opacity: 1.0,
																			},

																			{
																				text:
																						this.props.auth.user.user_profile.description_name  +  /*"UTE MIRAMON 2018"  +*/  "\n\n" + //this.state.sunriseDAY + " " +  AMANECERES_WEEK[ 0 ]  +  "\n\n"  +

																						/*
																						"Paseo Miramón, 170" + "\n" +
																						"20014 - Donostia-San Sebastián" + "\n" +
																						"Gipuzkoa",
																						*/

																						this.props.auth.user.user_profile.description_address,

																				//fit: [250, 150],

																				fontSize:  9,	//font_size_texto,

																				bold: true,

																				margin: [ 15, 14,		 0, 0 ],

																				alignment: 'left',
																				//width: 500
																			},
																		],
																	},

																],
															},
														],
													},



													// .content[ 1 ].stack[ 1 ]
													{
														margin: [ 0, 23,		0, 0 ],			// margin: [ 0, 35,		0, 0 ],
														alignment: 'center',

														width: '*',


														stack:
														[
															{
																margin: [ -3, 0,	0, 0 ],
																//alignment: 'center',
																//style: 'header',

																svg:	"<svg>" +  //  width='300' height='22'
																			"<rect x='0' y='0' rx='4' ry='4' width='525' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																		"</svg>",

																//fit: [300, 40],

																//width: 100,

																//height: 25,

																//absolutePosition: { x: -100, y: 150 },
																//absolutePosition: { x: 0, y: 0 },

																relativePosition: { x: 2, y: 0 },

																opacity: 1.0,
															},

															{
																text: "HORAS DE ENCENDIDO DE LA LUMINARIA   ·   horas/día",
																//fit: [250, 150],
																fontSize: font_size_Titulares,
																bold: true,

																color: "white",

																margin: [ 4, 3, 0, 0 ],

																alignment: 'left',
																//width: 500
															},

															/*
															{
																margin: [ 0, 0,	0, 0 ],
																alignment: 'center',
																//style: 'header',

																image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																fit: [105, 80],
															},
															*/


															// .content[ 1 ].stack[ 1 ].stack[ 2 ]

															// GRAFICA
															{
																margin: [ -7, 13,		0, 0 ],

																alignment: 'center',	//'left',


																//fit: [ 520, 430 ],

																//width: '100%',		// 130

																stack:
																[

																	// .content[ 1 ].stack[ 1 ].stack[ 2 ].stack[ 0 ]

																	{
																		svg:  MEDIAS_HORAS_obj_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML

																		alignment: 'center',	//'left',


																		//fontSize: 5,

																		//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,

																		fit: [ 530, 400 ],

																		//relativePosition: { x: -20, y: 0 },

																		//width: 500


																		//margin: [ 0, 10, 	10, 10 ],
																		//alignment: 'center',

																		//width: '100%',
																	},


																	{
																		text: Horas_TOTALES_MES_LUMINARIA_allSLs + ' h',

																		//fit: [250, 150],

																		fontSize: 32,

																		bold: true,


																		//absolutePosition: { x: -100, y: 150 },

																		absolutePosition: { x: 34, y: 370 },

																		color: "#d14111",	//"#d64d18",

																		//margin: [ 5, 3, 0, 0 ],

																		alignment: 'center',
																	},

																],
															},



															{


																margin: [ 0, 12, 0, 0 ],
																alignment: 'center',


																columns:
																[
																	{
																		text: "Horas de noche teóricas",
																		//fit: [250, 150],
																		fontSize: 8,

																		bold: true,

																		color: "grey",

																		//margin: [ 0, 0, 0, 0 ],

																		alignment: 'center',
																		//width: 500
																	},


																	{
																		text: "Horas de encendido reales",
																		//fit: [250, 150],
																		fontSize: 8,

																		bold: true,

																		color: "orange",

																		//margin: [ 0, 0, 0, 0 ],

																		alignment: 'center',
																		//width: 500
																	},
																],

															},
														],
													},

												],
											},



											// content[ 2 ]

											{
												margin: [ 0, 19,		0, 0 ],		// margin: [ 0, 20,		0, 0 ],	// [ 22, 0,		0, 0 ],						// [ 22, 0,		0, 0 ],
												//alignment: 'center',

												width: '*', //'100%',

												// LAS 2 COLUMNAS DE LA CABECERA 01
												columns:
												[
													// 2 COLUMNAS DE LA IZQUIERDA
													{
														margin: [ 0, 0,		0, 0 ],
														alignment: 'left',
														width: porcentaje_width_col_1 + '%',

														//background: "grey",

														//color: "grey",


														stack:
														[
															// CABECERA COLUMNA IZQUIERDA
															{
																margin: [ 0, 0,		 0, 0 ],

																alignment: 'left',
																//width: 500

																width: '*',

																stack:
																[
																	{
																		margin: [ 0, 0,	0, 0 ],
																		//alignment: 'center',
																		//style: 'header',

																		svg:	"<svg>" +  //  width='300' height='22'
																					"<rect x='0' y='0' rx='4' ry='4' width='345' height='15' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																				"</svg>",

																		//fit: [300, 40],

																		//width: 100,

																		//height: 25,

																		//absolutePosition: { x: -100, y: 150 },
																		//absolutePosition: { x: 0, y: 0 },

																		relativePosition: { x: 2, y: 0 },

																		opacity: 1.0,
																	},

																	{
																		text: "DATOS DE SOSTENIBILIDAD",  //,
																		//fit: [250, 150],
																		fontSize: font_size_Titulares,
																		bold: true,

																		color: "white",

																		margin: [ 7, 3,		 0, 0 ],

																		alignment: 'left',
																		//width: 500
																		width: '*',
																	},
																],

															},

															// SUBCOLUMNAS DEBAJO DE CABECERA ANTERIOR
															{
																margin: [ 0, 10,		0, 10 ],
																alignment: 'left',
																width: '*',

																stack:
																[





																	/*
																	{
																		margin: [ 0, 0,	0, 0 ],
																		//alignment: 'center',
																		//style: 'header',

																		svg:	"<svg>" +  //  width='300' height='22'
																					"<rect x='0' y='0' rx='4' ry='4' width='345' height='85' style='fill:rgb(220,220,230); stroke:rgb(220,220,230); stroke-width:5; opacity:1.0' />" +
																				"</svg>",

																		//fit: [300, 40],

																		//width: 100,

																		//height: 25,

																		//absolutePosition: { x: -100, y: 150 },
																		//absolutePosition: { x: 0, y: 0 },

																		relativePosition: { x: 0, y: 0 },

																		opacity: 1.0,
																	},*/



																	// -----------------------------------------------------------


																	{
																		margin: [ 10, 5,		0, 20 ],
																		alignment: 'center',
																		width: '*',

																		columns:
																		[

																			{
																				margin: [ 0, 0,		0, 0 ],
																				alignment: 'left',
																				width: '*',

																				columns:
																				[

																					// IMAGEN co2
																					{
																						margin: [  0, 0,   0, 0 ],


																						alignment: 'left',
																						//style: 'header',

																						image: '00_co2.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						fit: [ 50, 50 ],

																						//width: '*',
																					},











																					{

																						margin: [ 0, -1,		0, 0 ],

																						//alignment: 'left',


																						stack:
																						[

																							{
																								margin: [ 0, 0,		0, 0 ],

																								//alignment: 'left',


																								columns:
																								[
																									{
																										text:
																												DATO_kg_CO2.toLocaleString('de-DE')  +  " Kg CO", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto + 2,

																										bold: true,

																										margin: [ -27, 12,		 0, 2 ],

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},


																									{
																										text:
																												"2", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto - 1,

																										bold: true,

																										margin: [ -21, 15,		 0, 0 ],	//-22

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},
																								],
																							},




																							{
																								text:
																										"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																										//"Ahorro energético promedio diario" + "\n", //+  //,

																										//"Sensores: " + "80W",  //,

																								fontSize: font_size_texto + 1,
																								//bold: true,

																								margin: [ -27, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								//width: '*',
																							},

																						],
																					},











																				],

																			},


																			{
																				margin: [ 0, 0,		0, 0 ],
																				alignment: 'right',
																				width: '*',

																				columns:
																				[

																					// IMAGEN so2
																					{
																						margin: [  0, 0,   0, 0 ],


																						alignment: 'left',
																						//style: 'header',

																						image: '01_so2.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						fit: [ 50, 50 ],

																						//width: '*',
																					},





																					{

																						margin: [ 0, -1,		0, 0 ],

																						//alignment: 'left',


																						stack:
																						[

																							{
																								margin: [ 0, 0,		0, 0 ],

																								//alignment: 'left',


																								columns:
																								[
																									{
																										text:
																												DATO_g_SO2.toLocaleString('de-DE')  +  " g SO", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto + 2,

																										bold: true,

																										margin: [ -27, 12,		 0, 0 ],

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},


																									{
																										text:
																												"2", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto - 1,

																										bold: true,

																										margin: [ -28, 15,		 0, 2 ],   // -28

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},
																								],
																							},




																							{
																								text:
																										"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																										//"Ahorro energético promedio diario" + "\n", //+  //,

																										//"Sensores: " + "80W",  //,

																								fontSize: font_size_texto + 1,
																								//bold: true,

																								margin: [ -27, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								//width: '*',
																							},

																						],
																					},





																					/*
																					{
																						text:
																								DATO_g_SO2  +  " g SO2" + "\n" + //, //+
																								"ahorrados o no emitidos gracias a su instalación" + "\n",  // + //, //+

																								//"Se ha evitado la tala de árboles" + "\n\n" + //,
																								//"Ahorro energético promedio diario" + "\n", //+  //,

																								//"Sensores: " + "80W",  //,

																						fontSize: font_size_texto,
																						//bold: true,

																						margin: [ -27, 12,		 0, 0 ],

																						alignment: 'left',
																						//width: 500

																						//fit: [250, 150],
																						//width: '*',
																					},*/




																				],

																			},
																		],

																	},








																	{
																		margin: [ 10, 5,		0, 20 ],
																		alignment: 'center',
																		width: '*',

																		columns:
																		[

																			{
																				margin: [ 0, 0,		0, 0 ],
																				alignment: 'left',
																				width: '*',

																				columns:
																				[

																					// IMAGEN NOx
																					{
																						margin: [  0, 0,   0, 0 ],


																						alignment: 'left',
																						//style: 'header',

																						image: '10_nox.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						fit: [ 50, 50 ],

																						//width: '*',
																					},




																					{

																						margin: [ 0, -1,		0, 0 ],

																						//alignment: 'left',


																						stack:
																						[

																							{
																								margin: [ 0, 0,		0, 0 ],

																								//alignment: 'left',


																								columns:
																								[
																									{
																										text:
																												DATO_g_NOx.toLocaleString('de-DE')  +  " g NOx", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto + 2,

																										bold: true,

																										margin: [ -27, 12,		 0, 2 ],

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},

																								],
																							},




																							{
																								text:
																										"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																										//"Ahorro energético promedio diario" + "\n", //+  //,

																										//"Sensores: " + "80W",  //,

																								fontSize: font_size_texto + 1,
																								//bold: true,

																								margin: [ -27, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								//width: '*',
																							},

																						],
																					},











																				],

																			},


																			{
																				margin: [ 0, 0,		0, 0 ],
																				alignment: 'right',
																				width: '*',

																				columns:
																				[

																					// IMAGEN arbol
																					{
																						margin: [  0, 0,   0, 0 ],


																						alignment: 'left',
																						//style: 'header',

																						image: '11_arbol.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						fit: [ 50, 50 ],

																						//width: '*',
																					},















																					{

																						margin: [ 0, -1,		0, 0 ],

																						//alignment: 'left',


																						stack:
																						[

																							{
																								margin: [ 0, 0,		0, 0 ],

																								//alignment: 'left',


																								columns:
																								[
																									{
																										text:
																												DATO_NUM_Arboles  +  " Árboles", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto + 2,

																										bold: true,

																										margin: [ -27, 12,		 0, 2 ],

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},
																								],
																							},




																							{
																								text:
																										"cuya tala se ha evitado",	 /*+ "\n\n" +*/ //,
																										//"Ahorro energético promedio diario" + "\n", //+  //,

																										//"Sensores: " + "80W",  //,

																								fontSize: font_size_texto + 1,
																								//bold: true,

																								margin: [ -27, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								//width: '*',
																							},

																						],
																					},




																				],

																			},
																		],

																	},





																	{
																		margin: [ 10, 5,		0, 20 ],
																		alignment: 'center',
																		width: '*',

																		columns:
																		[

																			{
																				margin: [ 0, 0,		0, 0 ],
																				alignment: 'left',
																				width: '*',

																				columns:
																				[

																					// IMAGEN rayo
																					{
																						margin: [  0, 0,   0, 0 ],


																						alignment: 'left',
																						//style: 'header',

																						image: '20_rayo.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						fit: [ 50, 50 ],

																						//width: '*',
																					},





																					{

																						margin: [ 0, -1,		0, 0 ],

																						//alignment: 'left',


																						stack:
																						[

																							{
																								margin: [ 0, 0,		0, 0 ],

																								//alignment: 'left',


																								columns:
																								[
																									{
																										text:
																												DATO_AHORRO_KWh_ACUM.toLocaleString('de-DE')  +  " kWh", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto + 2,

																										bold: true,

																										margin: [ -27, 12,		 0, 2 ],

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},
																								],
																							},




																							{
																								text:
																										"ahorro energético acumulado",	 /*+ "\n\n" +*/ //,
																										//"Ahorro energético promedio diario" + "\n", //+  //,

																										//"Sensores: " + "80W",  //,

																								fontSize: font_size_texto + 1,
																								//bold: true,

																								margin: [ -27, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								//width: '*',
																							},

																						],
																					},

																				],

																			},





																			{
																				margin: [ 0, 0,		0, 0 ],
																				alignment: 'right',
																				width: '*',

																				columns:
																				[

																					// IMAGEN co2
																					{
																						margin: [  0, 0,   0, 0 ],


																						alignment: 'left',
																						//style: 'header',

																						image: '20_rayo.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						fit: [ 50, 50 ],

																						//width: '*',
																					},




																					{

																						margin: [ 0, -1,		0, 0 ],

																						//alignment: 'left',


																						stack:
																						[

																							{
																								margin: [ 0, 0,		0, 0 ],

																								//alignment: 'left',


																								columns:
																								[
																									{
																										text:
																												DATO_AHORRO_Wh_DIARIO.toLocaleString('de-DE')  +  " Wh", //, //+
																												//"ahorrados o no emitidos gracias a su instalación",	 /*+ "\n\n" +*/ //,
																												//"Ahorro energético promedio diario" + "\n", //+  //,

																												//"Sensores: " + "80W",  //,

																										fontSize: font_size_texto + 2,

																										bold: true,

																										margin: [ -27, 12,		 0, 2 ],

																										alignment: 'left',
																										//width: 500

																										//fit: [250, 150],
																										//width: '*',
																									},
																								],
																							},




																							{
																								text:
																										"ahorro energético \npromedio diario",	 /*+ "\n\n" +*/ //,
																										//"Ahorro energético promedio diario" + "\n", //+  //,

																										//"Sensores: " + "80W",  //,

																								fontSize: font_size_texto + 1,
																								//bold: true,

																								margin: [ -27, 0,		 0, 0 ],

																								alignment: 'left',
																								//width: 500

																								//fit: [250, 150],
																								//width: '*',
																							},

																						],
																					},





																				],

																			},







																		],

																	},


																	//-------

																	/*
																	{
																		margin: [ 10, 10,		0, 0 ],
																		alignment: 'center',
																		width: '*',

																		columns:
																		[
																			{
																				text:
																						"Ahorrados o no emitidas gracias a su instalación" + "\n\n", //+
																						//"Ahorradas o no emitidas gracias a su instalación" + "\n\n" + //,
																						//"Ahorro energético acumulado" + "\n", //+  //,

																						//"Potencia total de la instalación:  " + "80W",  //,

																				fontSize: font_size_texto,
																				//bold: true,

																				margin: [ 0, 0,		 0, 0 ],

																				alignment: 'left',
																				//width: 500

																				//fit: [250, 150],
																				width: '*',
																			},


																			{
																				text:
																						"Ahorradas o no emitidas gracas a su \ninstalación" + "\n\n", //+
																						//"Se ha evitado la tala de árboles" + "\n\n" + //,
																						//"Ahorro energético promedio diario" + "\n", //+  //,

																						//"Sensores: " + "80W",  //,

																				fontSize: font_size_texto,
																				//bold: true,

																				margin: [ 0, 0,		 0, 0 ],

																				alignment: 'left',
																				//width: 500

																				//fit: [250, 150],
																				width: '*',
																			},
																		],

																	},




																	//-------


																	{
																		margin: [ 10, 10,		0, 0 ],
																		alignment: 'center',
																		width: '*',

																		columns:
																		[
																			{
																				text:
																						"Ahorrados o no emitidas gracias a su instalación" + "\n\n", //+
																						//"Ahorradas o no emitidas gracias a su instalación" + "\n\n" + //,
																						//"Ahorro energético acumulado" + "\n", //+  //,

																						//"Potencia total de la instalación:  " + "80W",  //,

																				fontSize: font_size_texto,
																				//bold: true,

																				margin: [ 0, 0,		 0, 0 ],

																				alignment: 'left',
																				//width: 500

																				//fit: [250, 150],
																				width: '*',
																			},


																			{
																				text:
																						"Ahorradas o no emitidas gracas a su \ninstalación" + "\n\n", //+
																						//"Se ha evitado la tala de árboles" + "\n\n" + //,
																						//"Ahorro energético promedio diario" + "\n", //+  //,

																						//"Sensores: " + "80W",  //,

																				fontSize: font_size_texto,
																				//bold: true,

																				margin: [ 0, 0,		 0, 0 ],

																				alignment: 'left',
																				//width: 500

																				//fit: [250, 150],
																				width: '*',
																			},
																		],

																	},	*/








																],
															},
														]
													},

													//------------------------------------------


													// COLUMNA DE LA DERECHA
													{
														margin: [ 5, 0,		0, 0 ],
														alignment: 'right',
														width: porcentaje_width_col_2 + '%',

														stack:
														[
															{
																margin: [ 0, 0,		0, 0 ],
																alignment: 'right',

																width: '*',

																stack:
																[
																	{
																		margin: [ 0, 0,		0, 0 ],
																		alignment: 'right',
																		width: '*',

																		stack:
																		[
																			{
																				margin: [ 0, 0,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='4' ry='4' width='170' height='15' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: 0 },

																				opacity: 1.0,
																			},

																			{
																				text: "RESUMEN MENSUAL",
																				//fit: [250, 150],
																				fontSize: font_size_Titulares,
																				bold: true,

																				color: "white",

																				margin: [ 5, 3, 0, 0 ],

																				alignment: 'left',
																				//width: 500
																			},

																		],

																	},


																	{
																		margin: [ 0, 8,		0, 0 ],
																		alignment: 'right',

																		width: '100%',

																		stack:
																		[
																			{
																				margin: [ 0, 1,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='1' rx='4' ry='4' width='170' height='38' style='fill:rgb(220,220,230); stroke:rgb(220,220,230); stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: 0 },

																				opacity: 1.0,
																			},




																			{
																				margin: [  7, 11,		 -2, 8  ],	// [  12, 11,		 6, 8  ],

																				aligment: 'center',


																				columns:
																				[
																					{

																						text:
																								"Horas de iluminación:" + "\n" + /*+  DATO_PREVISION_PROX_Mes__Num_Horas_Ilum  +  " h \n" + */
																								"Ahorro energético mensual:",  //+  DATO_PREVISION_PROX_Mes__Ahorro_Energetico  +  " Wh \n",

																						//fit: [250, 150],
																						fontSize: font_size_texto + 1,
																						//bold: true,

																						margin: [ -2, 0,		 0, 0 ],

																						alignment: 'left',
																						//width: 500

																						width: '70%'
																					},



																					{
																						margin: [  -1, 0,		 0, 0  ],	// [  12, 11,		 6, 8  ],

																						alignment: 'right',


																						width: '30%',


																						columns:
																						[
																							{
																								text:
																										DATO_Resumen_Mensual__Num_Horas_Ilum.toLocaleString('de-DE')  +  "\n"  +
																										DATO_Resumen_Mensual__Ahorro_Energetico.toLocaleString('de-DE'),

																								//fit: [250, 150],
																								fontSize: font_size_texto + 1,

																								//bold: true,

																								margin: [ 0, 0,		 0, 0 ],	//  0, 2,		 4, 0 ],

																								alignment: 'right',
																								//width: 500

																								width: '*'
																							},


																							{
																								text:
																										"h"  +  "\n" +
																										"Wh",

																								//fit: [250, 150],
																								fontSize: font_size_texto + 1,

																								//bold: true,

																								margin: [ 4, 0,		 0, 0 ],	//  0, 2,		 4, 0 ],

																								alignment: 'left',
																								//width: 500

																								width: '*'
																							},
																						],
																					},

																				],
																			},


																			//{

																			//	columns:
																			//	[
																					/*
																					{
																						text:
																								"Horas de iluminación: "  +  DATO_Resumen_Mensual__Num_Horas_Ilum  +  " h \n" +
																								"Ahorro energético mensual: "  +  DATO_Resumen_Mensual__Ahorro_Energetico  +  " Wh \n",

																						//fit: [250, 150],
																						fontSize: font_size_texto,
																						//bold: true,

																						margin: [ 10, 10,		 0, 0 ],

																						alignment: 'left',
																						//width: 500
																					},*/

																					/*
																					{
																						text:
																								"Horas de iluminación: "  +  DATO_Resumen_Mensual__Num_Horas_Ilum  +  " h \n" +
																								"Ahorro energético mensual: "  +  DATO_Resumen_Mensual__Ahorro_Energetico  +  " Wh \n",

																						//fit: [250, 150],
																						fontSize: font_size_texto,
																						//bold: true,

																						margin: [ 10, 10,		 0, 0 ],

																						alignment: 'center',
																					},
																					*/

																			//	]
																			//},

																		],
																	},

																],
															},



															{
																margin: [ 0, 10,		0, 5 ],
																alignment: 'right',

																width: '100%',

																stack:
																[
																	{
																		margin: [ 0, 0,		0, 0 ],
																		alignment: 'right',

																		width: '100%',

																		stack:
																		[
																			{
																				margin: [ 0, 0,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='4' ry='4' width='170' height='15' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: 0 },

																				opacity: 1.0,
																			},


																			{
																				text: "PREVISIÓN PRÓXIMO MES",
																				//fit: [250, 150],
																				fontSize: font_size_Titulares,
																				bold: true,

																				color: "white",

																				margin: [ 5, 3, 0, 0 ],

																				alignment: 'left',
																				//width: 500
																			},

																		],
																	},











																	{
																		margin: [ 0, 8,		0, 0 ],
																		alignment: 'right',

																		width: '100%',

																		stack:
																		[
																			{
																				margin: [ 0, 1,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='1' rx='4' ry='4' width='170' height='38' style='fill:rgb(220,220,230); stroke:rgb(220,220,230); stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: 0 },

																				opacity: 1.0,
																			},




																			{
																				margin: [  7, 11,		 -2, 8  ],	// [  12, 11,		 6, 8  ],

																				aligment: 'center',


																				columns:
																				[
																					{

																						text:
																								"Horas de iluminación:" + "\n" + /*+  DATO_PREVISION_PROX_Mes__Num_Horas_Ilum  +  " h \n" + */
																								"Ahorro energético mensual:",  //+  DATO_PREVISION_PROX_Mes__Ahorro_Energetico  +  " Wh \n",

																						//fit: [250, 150],
																						fontSize: font_size_texto + 1,
																						//bold: true,

																						margin: [ -2, 0,		 0, 0 ],

																						alignment: 'left',
																						//width: 500

																						width: '70%'
																					},



																					{
																						margin: [  -1, 0,		 0, 0  ],	// [  12, 11,		 6, 8  ],

																						alignment: 'right',


																						width: '30%',


																						columns:
																						[
																							{
																								text:
																										DATO_PREVISION_PROX_Mes__Num_Horas_Ilum.toLocaleString('de-DE')  +  "\n"  +
																										DATO_PREVISION_PROX_Mes__Ahorro_Energetico.toLocaleString('de-DE'),

																								//fit: [250, 150],
																								fontSize: font_size_texto + 1,

																								//bold: true,

																								margin: [ 0, 0,		 0, 0 ],	//  0, 2,		 4, 0 ],

																								alignment: 'right',
																								//width: 500

																								width: '*'
																							},


																							{
																								text:
																										"h"  +  "\n" +
																										"Wh",

																								//fit: [250, 150],
																								fontSize: font_size_texto + 1,

																								//bold: true,

																								margin: [ 4, 0,		 0, 0 ],	//  0, 2,		 4, 0 ],

																								alignment: 'left',
																								//width: 500

																								width: '*'
																							},
																						],
																					},

																				],


																			},



																			//{

																			//	columns:
																			//	[
																					/*
																					{
																						text:
																								"Horas de iluminación: "  +  DATO_Resumen_Mensual__Num_Horas_Ilum  +  " h \n" +
																								"Ahorro energético mensual: "  +  DATO_Resumen_Mensual__Ahorro_Energetico  +  " Wh \n",

																						//fit: [250, 150],
																						fontSize: font_size_texto,
																						//bold: true,

																						margin: [ 10, 10,		 0, 0 ],

																						alignment: 'left',
																						//width: 500
																					},*/

																					/*
																					{
																						text:
																								"Horas de iluminación: "  +  DATO_Resumen_Mensual__Num_Horas_Ilum  +  " h \n" +
																								"Ahorro energético mensual: "  +  DATO_Resumen_Mensual__Ahorro_Energetico  +  " Wh \n",

																						//fit: [250, 150],
																						fontSize: font_size_texto,
																						//bold: true,

																						margin: [ 10, 10,		 0, 0 ],

																						alignment: 'center',
																					},
																					*/

																			//	]
																			//},

																		],
																	},







																],
															},



															{
																margin: [ 0, 5,		0, 0 ],
																alignment: 'right',
																width: '100%',

																stack:
																[
																	{
																		margin: [ 0, 0,		0, 0 ],
																		alignment: 'right',
																		width: '100%',

																		stack:
																		[
																			{
																				margin: [ 0, -1,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',

																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='4' ry='4' width='170' height='15' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: 0 },

																				opacity: 1.0,
																			},

																			{
																				text: "COMPROMISO CON LOS ODS",
																				//fit: [250, 150],
																				fontSize: font_size_Titulares,
																				bold: true,
																				color: "white",

																				margin: [ 5, 2, 0, 0 ],
																				alignment: 'left',
																				//width: 500
																			},



																			// IMAGEN ODS
																			{
																				margin: [  0, 10,   0, 10 ],

																				alignment: 'center',
																				//style: 'header',

																				image: 'los4iconos.png',  //'sampleImage.jpg'//,

																				//absolutePosition: { x: -205, y: 705 },

																				fit: [ 170, 100 ],
																			},



																		],
																	},


																	{
																		margin: [ 0, 0,		0, 0 ],
																		alignment: 'right',

																		width: '100%',

																		stack:
																		[
																			{
																				svg:	"<svg>" +  //  width='300' height='22'
																							"<rect x='0' y='0' rx='4' ry='4' width='171' height='102' style='fill:rgb(220,220,230); stroke:rgb(220,220,230); stroke-width:5; opacity:1.0' />" +
																						"</svg>",

																				//fit: [300, 40],

																				//width: 100,

																				//height: 25,

																				//absolutePosition: { x: -100, y: 150 },
																				//absolutePosition: { x: 0, y: 0 },

																				relativePosition: { x: -2, y: -1 },
																				opacity: 1.0,

																				margin: [ 0, 0,	0, 0 ],
																				//alignment: 'center',
																				//style: 'header',
																			},





																			{
																				margin: [ 12, 13,		 5, 9 ],

																				columns:
																				[

																					// IMAGEN INFO
																					{
																						//margin: [  0, 10,   0, 10 ],

																						alignment: 'left',
																						//style: 'header',

																						image: 'info2.PNG',  //'sampleImage.jpg'//,

																						//absolutePosition: { x: -205, y: 705 },

																						width: '*',

																						fit: [ 10, 10 ],
																					},


																					{
																						text: "¿Qué son los ODS?",

																						width: '90%',

																						//fit: [250, 150],
																						fontSize: font_size_texto,

																						bold: true,

																						//margin: [ 0, 3,		 0, 0 ],

																						alignment: 'left',
																						//width: 500
																					},

																				],

																			},




																			{
																				text: "Conjunto de objetivos globales para erradicar la pobreza, proteger el planeta y asegurar la prosperidad para todos como parte de una nueva agenda de desarrollo sostenible." + "\n",

																				//fit: [250, 150],
																				fontSize: font_size_texto + 1,
																				//bold: true,

																				margin: [ 12, 2,		 9, 0 ],		// margin: [ 12, 2,		 9, 6 ],

																				alignment: 'left',
																				//width: 500
																			},




																		],
																	},
																],
															},

														],
													},

												],

											},





											//-----------------------------------------------------------------------------------------------------------------------------------------------------
											//-----------------------------------------------------------------------------------------------------------------------------------------------------



											// content[ 3 ]

											// CABECERRA TODAS LAS PÁGINAS
											{
												pageBreak: 'before',

												//-------

												margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
												style: 'header',
												alignment: 'center',
												width: '*',


												stack:
												[

													// SOL DE FONDOA
													{
														//margin: [ -15, 0, 0, 0 ],

														alignment: 'center',
														//style: 'header',

														image:  'sol.PNG',  //'sampleImage.jpg'//,

														absolutePosition: { x: -210, y: 705 },

														fit: [ 330, 220 ],
													},



													// CABECERRA TODAS LAS PÁGINAS
													{
														margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
														style: 'header',
														alignment: 'center',
														width: '*',


														stack:
														[
															// HEADER
															{
																margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
																style: 'header',
																alignment: 'left',


																columns:
																[


																	// LOGO EKIONA, ZONA IZQUIERDA
																	{
																		margin: [ -15, 0, 0, 0 ],
																		alignment: 'left',
																		//style: 'header',

																		image: 'ekiona_logo.png',  //'sampleImage.jpg'//,

																		fit: [ 200, 180 ],
																	},



																	// ZONA DCHA
																	{
																		margin: [ 0, 10,		2, 0 ],	// margin: [ 0, 10,		10, 0 ],

																		alignment: 'right',	// 'justify'

																		columns:
																		[
																			{
																				margin: [  0, 1,	-21, 0 ],
																				alignment: 'right',
																				//style: 'header',

																				text: 'Informe técnico',  //'sampleImage.jpg'//,

																				fontSize: 17,
																				bold: true,
																				//style: ['bold'],
																				//fit: [150, 100],
																			},

																			{
																				margin: [ 0, 0,	0, 0 ],
																				alignment: 'right',
																				//style: 'header',

																				image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																				fit: [105, 80],
																			},
																		],
																	}
																],
															},


															// MES, AÑO
															{
																margin: [ 0, -38,		5, 0 ],	// margin: [ 0, -38,		15, 0 ],
																alignment: 'right',
																//style: 'header',


																/*
																_ar_NUM_dayWEEK = new Array();
																_ar_STR_dayWEEK = new Array();

																_ar_NUM_monthWEEK = new Array();
																_ar_STR_monthWEEK = new Array();

																_ar_NUM_yearWEEK = new Array();
																_ar_STR_yearWEEK = new Array();
																*/

																	/*
																text:	"Del "   +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																		"\nal "  +  _ar_STR_dayWEEK[ 6 ]  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

																*/

																text:	STR_FECHAS_CABECERA,		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

																fontSize: 10,
																bold: true,

																//fit: [180, 150],
															},
														],
													},

													/*
													// HEADER
													{
														margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
														style: 'header',
														alignment: 'left',


														columns:
														[
															// LOGO EKIONA, ZONA IZQUIERDA
															{
																margin: [ 0, 0, 0, 0 ],
																alignment: 'left',
																//style: 'header',

																image: 'ekiona_logo.png',  //'sampleImage.jpg'//,

																fit: [ 200, 180 ],
															},


															// ZONA DCHA
															{
																margin: [ 0, 10,		10, 0 ],
																alignment: 'right',	// 'justify'

																columns:
																[
																	{
																		margin: [  0, 0,	-20, 0 ],
																		alignment: 'right',
																		//style: 'header',

																		text: 'Informe técnico',  //'sampleImage.jpg'//,

																		fontSize: 18,
																		bold: true,
																		//style: ['bold'],
																		//fit: [150, 100],
																	},

																	{
																		margin: [ 0, 0,	0, 0 ],
																		alignment: 'right',
																		//style: 'header',

																		image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																		fit: [105, 80],
																	},
																],
															}
														],
													},

													// MES, AÑO
													{
														margin: [ 0, -38,		15, 0 ],
														alignment: 'right',
														//style: 'header',




														text:	"Del "   +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																"\nal "  +  STR_DiAS_MES[ STR_DiAS_MES.length - 1 ].slice( 0, 2 )    +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,


														//text:	"Del "  +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
														//		"\nal "  +  _ar_STR_dayWEEK[ 6 ]  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

														fontSize: 10,
														bold: true,

														//fit: [180, 150],
													},*/





													{
														text:	"Página 2 / "  +  String(  2  +  _obj_Inst_Group.info.streetlights.length  ),		 //,


														fontSize: font_size_texto  +  0, // + 1,
														//bold: true,

														absolutePosition: { x: 45, y: 797 },	// { x: 34, y: 798 },

														//margin: [ 0, 0,		 0, 0 ],

														alignment: 'center',
														//width: 500

														//fit: [250, 150],
														//width: '*',
													},

												],
											},




											// BODY 2DA PÁGINA ------------------------------------------------------------------------------------


											// content[ 4 ]
											{
												margin: [ 0, 35,		0, 18 ],
												alignment: 'center',

												width: '*',

												stack:
												[
													{
														margin: [ 0, 0,	0, 0 ],
														//alignment: 'center',
														//style: 'header',

														svg:	"<svg>" +  //  width='300' height='22'
																	"<rect x='0' y='0' rx='4' ry='4' width='525' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																"</svg>",

														//fit: [300, 40],

														//width: 100,

														//height: 25,

														//absolutePosition: { x: -100, y: 150 },
														//absolutePosition: { x: 0, y: 0 },

														relativePosition: { x: 2, y: 0 },

														opacity: 1.0,
													},

													{
														text: "DIMMING HORARIO",		//"PROGRAMACIÓN HORARIA DE FAROLAS   ·   % dimming/día",
														//fit: [250, 150],
														fontSize: font_size_Titulares,
														bold: true,

														color: "white",

														margin: [ 6, 3, 0, 0 ],

														alignment: 'left',
														//width: 500
													},

													/*
													{
														margin: [ 0, 0,	0, 0 ],
														alignment: 'center',
														//style: 'header',

														image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

														fit: [105, 80],
													},
													*/



													{
														margin: [ 10, 0, 	0, 0 ],

														columns:
														[
															{
																width: '3%',
																margin: [ 0, 20, 	-6, 0 ],
																alignment: 'left',	//'left',

																stack:
																[

																	{
																		//width: '3%',

																		text: "D",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 1, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},

																	{
																		//width: '3%',

																		text: "I",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 2, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},

																	{
																		//width: '3%',

																		text: "M",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 0, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},



																	{
																		//width: '3%',

																		text: "M",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 0, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},

																	{
																		//width: '3%',

																		text: "I",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 2, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},

																	{
																		//width: '3%',

																		text: "N",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 0, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},

																	{
																		//width: '3%',

																		text: "G",
																		//fit: [250, 150],

																		fontSize: 8,

																		//bold: false,

																		color: "grey",

																		margin: [ 0, 7, 	0, 0 ],

																		//alignment: 'left',	//'left',
																		//width: 500
																	},
																],
															},



															// GRAFICA DIMMING
															{
																margin: [ /*-5*/ -4, 20,		3, 0 ],

																alignment: 'center',	//'left',


																//fit: [ 520, 430 ],

																width: '*',		// 130


																stack:
																[
																	{
																		svg:  svg_01_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML

																		alignment: 'center',	//'left',


																		//fontSize: 5,

																		//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,

																		fit: [ 500, 430 ],		// 530

																		//relativePosition: { x: -20, y: 0 },

																		//width: 500


																		//margin: [ 0, 10, 	10, 10 ],
																		//alignment: 'center',

																		//width: '100%',
																	},
																],
															},
														],
													},



												],
											},



											// content[ 5 ]
											{
												margin: [ 0, 16,		0, 17 ],
												alignment: 'center',

												width: '*',

												stack:
												[
													{
														margin: [ 0, 0,	0, 0 ],
														//alignment: 'center',
														//style: 'header',

														svg:	"<svg>" +  //  width='300' height='22'
																	"<rect x='0' y='0' rx='4' ry='4' width='525' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																"</svg>",

														//fit: [300, 40],

														//width: 100,

														//height: 25,

														//absolutePosition: { x: -100, y: 150 },
														//absolutePosition: { x: 0, y: 0 },

														relativePosition: { x: 2, y: 0 },

														opacity: 1.0,
													},

													{
														text: "CORRIENTE LUMINARIA (A), VOLTAJE PANEL Y BATERIA (V)",		//"   ·   amperios/hora, voltios/hora",
														//fit: [250, 150],
														fontSize: font_size_Titulares,
														bold: true,

														color: "white",

														margin: [ 5, 3, 0, 0 ],

														alignment: 'left',
														//width: 500
													},

													/*
													{
														margin: [ 0, 0,	0, 0 ],
														alignment: 'center',
														//style: 'header',

														image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

														fit: [105, 80],
													},
													*/


													// GRAFICA
													{
														margin: [ -5, 10,		0, -5 ],

														alignment: 'center',	//'left',


														//fit: [ 520, 430 ],

														//width: '100%',		// 130

														stack:
														[
															{
																width: '*',
																alignment: 'center',	//'left',

																margin: [ 0, 10, 0, 0 ],


																columns:
																[
																	{
																		width: '3%',
																		margin: [ 15, 5, 	-5, 0 ],
																		alignment: 'left',	//'left',

																		stack:
																		[

																			{
																				//width: '3%',

																				text: "V",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "O",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "L",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "T",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},


																			{
																				//width: '3%',

																				text: "I",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 1, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "O",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "S",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 10, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},
																		],
																	},

																	{
																		svg:  LINECHART_svg_MEDIA_allSLs_HORA_obj_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML

																		alignment: 'center',	//'left',

																		width: '*',

																		//fontSize: 5,

																		//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,

																		fit: [ 520, 500 ],

																		//relativePosition: { x: -20, y: 0 },

																		//width: 500


																		margin: [ -15, 0, 	12, 0 ],
																		//alignment: 'center',

																		//width: '100%',
																	},


																	{
																		width: '3%',
																		margin: [ 10, 10, 20, 0 ],		//margin: [ 10, 5, 	-5, 0 ],
																		alignment: 'right',	//'left',

																		stack:
																		[

																			{
																				//width: '3%',

																				text: "A",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "M",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "P",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "E",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},


																			{
																				//width: '3%',

																				text: "R",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "I",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 1, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "O",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},

																			{
																				//width: '3%',

																				text: "S",
																				//fit: [250, 150],

																				fontSize: 8,

																				//bold: false,

																				color: "grey",

																				margin: [ 0, 8, 	0, 0 ],

																				//alignment: 'left',	//'left',
																				//width: 500
																			},
																		],
																	},

																	/*
																	{
																		width: '3%',

																		text: "A",
																		//fit: [250, 150],

																		fontSize: 13,

																		bold: true,

																		color: "black",

																		margin: [ 10, 10, 20, 0 ],

																		alignment: 'right',	//'left',
																		//width: 500
																	},*/
																],
															},


															{
																alignment: 'center',	//'left',

																margin: [ 0, -3, 0, 0 ],

																columns:
																[

																	{
																		text: "Tensión Batería (V)",
																		//fit: [250, 150],

																		fontSize: font_size_Leyenda_MEDIA,

																		bold: false,

																		color: "green",

																		margin: [ 0, 0, 0, 0 ],

																		alignment: 'center',	//'left',
																		//width: 500
																	},

																	{
																		text: "Tensión Panel (V)",
																		//fit: [250, 150],

																		fontSize: font_size_Leyenda_MEDIA,

																		bold: false,

																		color: "red",

																		margin: [ 0, 0, 0, 0 ],

																		alignment: 'center',	//'left',
																		//width: 500
																	},

																	{
																		text: "Corriente Luminaria (A)",
																		//fit: [250, 150],

																		fontSize: font_size_Leyenda_MEDIA,

																		bold: false,

																		color: "blue",

																		margin: [ 0, 0, 0, 0 ],

																		alignment: 'center',	//'left',
																		//width: 500
																	},
																],
															},

														],
													},
												],
											},






											// content[ 6 ]
											{
												margin: [ 0, 18,		0, 20 ],
												alignment: 'center',

												width: '*',

												stack:
												[
													{
														margin: [ 0, 0,	0, 0 ],
														//alignment: 'center',
														//style: 'header',

														svg:	"<svg>" +  //  width='300' height='22'
																	"<rect x='0' y='0' rx='4' ry='4' width='525' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																"</svg>",

														//fit: [300, 40],

														//width: 100,

														//height: 25,

														//absolutePosition: { x: -100, y: 150 },
														//absolutePosition: { x: 0, y: 0 },

														relativePosition: { x: 2, y: 0 },

														opacity: 1.0,
													},


													{
														text: "AHORRO ENERGÉTICO   ·   Wh/día",
														//fit: [250, 150],
														fontSize: font_size_Titulares,
														bold: true,

														color: "white",

														margin: [ 5, 3, 0, 0 ],

														alignment: 'left',
														//width: 500
													},
													/*
													{
														margin: [ 0, 0,	0, 0 ],
														alignment: 'center',
														//style: 'header',

														image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

														fit: [105, 80],
													},
													*/


													{
														margin: [ 0, 3, 0, 0 ],

														columns:
														[

															{
																text: "Wh",

																//fit: [250, 150],

																fontSize: 7,

																//bold: true,

																color: "grey",

																margin: [ 5, 29, 0, 0 ],

																alignment: 'left',

																width:  '5%',
															},



															// GRAFICA
															{
																margin: [ -13, 22,		0, 0 ],		// -5, 20

																alignment: 'center',	//'left',

																width: '*',

																//fit: [ 520, 430 ],

																//width: '100%',		// 130

																stack:
																[
																	{
																		svg:  AHORRO_ENERG_obj_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML

																		alignment: 'left',	//'left',


																		//fontSize: 5,

																		//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,

																		fit: [ 500, 600 ],

																		//relativePosition: { x: -20, y: 0 },

																		//width: 500


																		//margin: [ 0, 10, 	10, 10 ],
																		//alignment: 'center',

																		//width: '100%',
																	},
																],
															},

														],


													},



													{


														margin: [ 0, 11, 0, 0 ],
														alignment: 'center',


														columns:
														[
															{
																text: "Ahorro energético teórico",
																//fit: [250, 150],
																fontSize: 8,

																bold: true,

																color: "grey",

																//margin: [ 0, 0, 0, 0 ],

																alignment: 'center',
																//width: 500
															},


															{
																text: "Ahorro energético real",
																//fit: [250, 150],
																fontSize: 8,

																bold: true,

																color: "orange",

																//margin: [ 0, 0, 0, 0 ],

																alignment: 'center',
																//width: 500
															},
														],

													},

												],
											},












											//-----------------------------------------------------------------------------------------------------------------------------------------------------
											//-----------------------------------------------------------------------------------------------------------------------------------------------------


											/*
											// CABECERRA TODAS LAS PÁGINAS
											{
												pageBreak: 'before',

												margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
												style: 'header',
												alignment: 'center',
												width: '*',

												stack:
												[
													// HEADER
													{
														margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
														style: 'header',
														alignment: 'left',


														columns:
														[
															// LOGO EKIONA, ZONA IZQUIERDA
															{
																margin: [ 0, 0, 0, 0 ],
																alignment: 'left',
																//style: 'header',

																image: 'ekiona_logo.png',  //'sampleImage.jpg'//,

																fit: [ 200, 180 ],
															},


															// ZONA DCHA
															{
																margin: [ 0, 10,		10, 0 ],
																alignment: 'right',	// 'justify'

																columns:
																[
																	{
																		margin: [  0, 0,	-20, 0 ],
																		alignment: 'right',
																		//style: 'header',

																		text: 'Informe técnico',  //'sampleImage.jpg'//,

																		fontSize: 18,
																		bold: true,
																		//style: ['bold'],
																		//fit: [150, 100],
																	},

																	{
																		margin: [ 0, 0,	0, 0 ],
																		alignment: 'right',
																		//style: 'header',

																		image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																		fit: [105, 80],
																	},
																],
															}
														],
													},

													// MES, AÑO
													{
														margin: [ 0, -38,		15, 0 ],
														alignment: 'right',
														//style: 'header',




														text:	"Del "  +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																"\nal "  +  _ar_STR_dayWEEK[ 6 ]  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

														fontSize: 10,
														bold: true,

														//fit: [180, 150],
													},
												],
											},
											*/



											// BODY 3A PÁGINA ------------------------------------------------------------------------------------






										],
									};//




				//-------------------------------------


				//console.log(  "YEAR: "  +  thisYEAR  );
				//console.log(  "MONTH: "  +  NUM_MES_Selected  );


				//const our_JSON_sunrise =  this.state.selected_installationgroup.sunrise_sunset_year;


				/*
				if (   our_JSON_sunrise  !==  null   )
				{
					if (   our_JSON_sunrise  !=  undefined   )
					{
						//if (   our_JSON  !=  ""   )
						//{

						const our_JSON_OBJ_sunrise = JSON.parse(  our_JSON_sunrise  );

						console.log( our_JSON_OBJ_sunrise[ 5 ][ 16 ][ "sunrise" ] );

						//}
					}
				}//
				*/

				var our_JSON_OBJ_clouds =  null;


				const our_JSON_clouds =  this.state.selected_installationgroup.clouds_year/*_Historic*/;


				if (   our_JSON_clouds  !==  null   )
				{
					if (   our_JSON_clouds  !=  undefined   )
					{
						//if (   our_JSON  !=  ""   )
						//{

						our_JSON_OBJ_clouds = JSON.parse(  our_JSON_clouds  );

						//console.log(   our_JSON_OBJ_clouds[  String( thisYEAR )   ][  parseInt( NUM_MES_Selected ) - 1  ] );

						//console.log( our_JSON_OBJ_clouds[ "2022" ][ 6 - 1 ][ 17 - 1 ][ "Clouds" ] );

						//}

						//--------------------------




						my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack.push(

							// SOL DE FONDOA
							{
								/*
								margin: [ 0, 1, 0, 5 ],

								alignment: 'center',
								//style: 'header',

								image: '11_arbol.PNG',  //'sampleImage.jpg'//,

								//absolutePosition: { x: 0, y: 0 },

								relativePosition: { x: -238, y: 0 },

								fit: [ 30, 20 ],
								*/


								margin: [ 0, 1, 0, 5 ],
								alignment: 'center',


								columns:
								[

								],


							},

						);// my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack.push(

						//------



						my_docDefinition.content[ 6 ].stack[ 2 ].columns[ 1 ].stack.push(

							// SOL DE FONDOA
							{
								/*
								margin: [ 0, 1, 0, 5 ],

								alignment: 'center',
								//style: 'header',

								image: '11_arbol.PNG',  //'sampleImage.jpg'//,

								//absolutePosition: { x: 0, y: 0 },

								relativePosition: { x: -238, y: 0 },

								fit: [ 30, 20 ],
								*/


								margin: [ 0, 1, 0, 5 ],
								alignment: 'center',


								columns:
								[

								],


							},

						);// my_docDefinition.content[ 6 ].stack[ 2 ].columns[ 1 ].stack.push(

						//------



						//-------------------------------------------------------


						// COMIENZO EN X DE GRÁFICA:	-469
						// FIN EN X DE GRÁFICA:			 553

						// DIFERENCIA =  553 - ( -469 ) =  553 + 469  =		1022

						let INI_X_1 =		-466.0;
						let END_X_1 =		 559.0; 	//556.0;

						let DELTA_X_1 =	parseFloat(  END_X_1  -  INI_X_1  );


						const INC_graf_x_1 =  parseFloat(  parseFloat( DELTA_X_1 )  /  parseFloat(  NUM_DAYS_MONTH  )  );

						//---


						let INI_X_2 =		-430.0;
						let END_X_2 =		 535.0; 	//556.0;

						let DELTA_X_2 =	parseFloat(  END_X_2  -  INI_X_2  );


						const INC_graf_x_2 =  parseFloat(  parseFloat( DELTA_X_2 )  /  parseFloat(  NUM_DAYS_MONTH  )  );



						//const DIF_dias_31 =  (  31  -  NUM_DAYS_MONTH  );

						//---------


						let str_our_YEAR =  String( thisYEAR );		//"2022";
						let our_mon =  parseInt( NUM_MES_Selected );		// - 1;


						for ( let imd = 0;  imd < NUM_DAYS_MONTH;  imd++ )
						{

							//const POS_X =  -450  +  (   parseInt( imd ) * parseInt(  33  +  (  1 * parseInt( DIF_dias_31 )  )  )  );		// -238

							const POS_X_1 =  parseInt(   parseFloat( INI_X_1 )  +  parseFloat(   INC_graf_x_1   *  parseFloat(  imd + 1  )   )  -  parseFloat(  INC_graf_x_1  /  2.0  )   );		// -238

							const POS_X_2 =  parseInt(   parseFloat( INI_X_2 )  +  parseFloat(   INC_graf_x_2   *  parseFloat(  imd + 1  )   )  -  parseFloat(  INC_graf_x_2  /  2.0  )   );		// -238



							//let our_day =  17;


							let our_CLOUDS_DAY =  our_JSON_OBJ_clouds[  str_our_YEAR  ][  our_mon - 1  ][ /*our_day - 1*/ imd  ][ "Clouds" ];



							let str_name_ICON = "";



							if (  our_CLOUDS_DAY  ==  ""  )
							{
								str_name_ICON =  	"cloudy.png";	//"sunny.png";	// POR DEFECTO SI NO HAY DATO DE CLOUDS
							}
							else
							{

								var FLOAT_our_CLOUDS_DAY =  parseFloat(  our_CLOUDS_DAY  );


								if (  FLOAT_our_CLOUDS_DAY  >  65  )
								{
									str_name_ICON = "very_cloudy.png";
									/*
									return 	<td>
												<img
													src = "img/very_cloudy.png"
													title = {item.dom}
													className = "col-md-3"
													style = {{maxWidth: "60px", cursor: "pointer"}}

													onClick =
																{
																	( e ) =>
																	{
																		$( "#weatherInfo" ).modal( "show" );
																	}
																}
												/>
											</td>
									*/
								}
								else
								{
									if (  FLOAT_our_CLOUDS_DAY  >  35  )
									{
										str_name_ICON = "cloudy.png";

										/*
										return 	<td>
													<img
														src = "img/cloudy.png"
														title = { item.dom }
														className = "col-md-3"

														style = { { maxWidth: "60px", cursor: "pointer" } }

														onClick =
																	{
																		( e ) =>
																		{
																			$("#weatherInfo").modal("show");
																		}
																	}
													/>
												</td>
										*/
									}
									else
									{
										str_name_ICON = "sunny.png";
										/*
										return 	<td>
													<img
														src = "img/sunny.png"
														title = { item.dom }
														className = "col-md-3"
														style = { { maxWidth: "60px", cursor: "pointer" } }

														onClick =
																	{
																		( e ) =>
																		{
																			$( "#weatherInfo" ).modal( "show" );
																		}
																	}
													/>
												</td>
										*/
									}
								}//

							}///


							const FINAL_str_name_ICON =  str_name_ICON;




							//-----------------------------

							my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack[ 2 ].columns.push(

								/*
								{
									svg:  MEDIAS_HORAS_obj_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML

									alignment: 'center',	//'left',


									//fontSize: 5,

									//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,

									fit: [ 530, 400 ],

									//relativePosition: { x: -20, y: 0 },

									//width: 500


									//margin: [ 0, 10, 	10, 10 ],
									//alignment: 'center',

									//width: '100%',
								},
								*/


								{
									margin: [ 0, 0, 0, 0 ],

									//alignment: 'right',
									//style: 'header',

									image: 	FINAL_str_name_ICON,	//	'sunny.png',  //'sampleImage.jpg'//,

									absolutePosition: { x: POS_X_1,   y: 440 },

									//relativePosition: { x: POS_X, y: 0 },

									fit:	[ 16, 15 ],			//	[ 21, 17 ],
								},

							);// my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack.push(


							//--------



							my_docDefinition.content[ 6 ].stack[ 2 ].columns[ 1 ].stack[ 1 ].columns.push(


								{
									margin: [ 0, 0, 0, 0 ],

									//alignment: 'right',
									//style: 'header',

									image: 	FINAL_str_name_ICON,	//	'sunny.png',  //'sampleImage.jpg'//,

									absolutePosition: { x: POS_X_2,   y: 767 },

									//relativePosition: { x: POS_X, y: 0 },

									fit:	[ 15, 13 ],			//	[ 21, 17 ],
								},

							);// my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack.push(


							//-------


						}// for ( let imd = 0;  imd < NUM_DAYS_MONTH;  imd++ )
						// ------------------


						//-------------------------------------

						/*
						my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack.push(

							// SOL DE FONDOA
							{

								margin: [ 0, 0, 0, 0 ],

								alignment: 'center',
								//style: 'header',

								image: '11_arbol.PNG',  //'sampleImage.jpg'//,

								//absolutePosition: { x: 0, y: 0 },

								absolutePosition: { x: -469,   y: 456 },

								fit: [ 30, 20 ],


							},


							// SOL DE FONDOA
							{

								margin: [ 0, 0, 0, 0 ],

								alignment: 'center',
								//style: 'header',

								image: '11_arbol.PNG',  //'sampleImage.jpg'//,

								//absolutePosition: { x: 0, y: 0 },

								absolutePosition: { x: 553,   y: 456 },

								fit: [ 30, 20 ],


							},


						);// my_docDefinition.content[ 1 ].stack[ 1 ].stack[ 2 ].stack.push(
						*/

						//--------------------------

					}// if (   our_JSON_clouds  !=  undefined   )

				}// if (   our_JSON_clouds  !==  null   )




				//-------------------------------------
				//-------------------------------------
				//-------------------------------------


				const const_iweek_como_referencia =  0;		// iweek = 0.... por poner una, nos da igual, es para recorrer/.forEach todas las SLs y tener sus INDEX e ITEM

				//_obj_Inst_Group.info.streetlights.forEach(			/*iweek*/
				_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[  const_iweek_como_referencia  ].forEach(		// iweek = 0.... por poner una, nos da igual, es para recorrer/.forEach todas las SLs y tener sus INDEX e ITEM
				//

					(  item, index ) =>
					{
						//console.log(  /*item.name*/ item.SL_NAME + " (" + index + ")"  );

						//----------------------

						var aux_boooll =  0;

						var aux_str_plus = "";


						for (  let iaux =  0;  (  iaux  <  _obj_Inst_Group.info.streetlights.length  )  &&  (  aux_boooll  ==  0  );  iaux++  )
						{
							/*
							DATOS_SL_MATXINPORTA[ 0 ] = {
															SL_NAME: "480166f000m",


															NUM_UBIC: "Ubicación 2",

															NUM_ID: "1002040",

															NEW_NAME: "URR1_M"
														};*/

							if (   DATOS_SL_MATXINPORTA[  iaux  ].SL_NAME  ==  item.SL_NAME   )
							{
								aux_str_plus =		"                                         " +
																									DATOS_SL_MATXINPORTA[  iaux  ].NUM_UBIC  +  ",  id. "  +
																									DATOS_SL_MATXINPORTA[  iaux  ].NUM_ID  +  ",  nombre '"  +
																									DATOS_SL_MATXINPORTA[  iaux  ].NEW_NAME  +  "' ";

								aux_boooll = 1;

							}// if	-----

						}// for : iaux --------------------------------------


						//------------------------------------------------------------------------------------------------------


						my_docDefinition.content.push(
						//
														{
															stack:
															[
																/*
																{

																	pageBreak: 'before',

																	margin: [  0, 0,	-20, 0 ],
																	alignment: 'right',
																	//style: 'header',

																	text: 'Informe técnico',  //'sampleImage.jpg'//,

																	fontSize: 18,
																	bold: true,
																	//style: ['bold'],
																	//fit: [150, 100],
																},
																*/




																								// CABECERRA TODAS LAS PÁGINAS
																{
																	pageBreak: 'before',


																	margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
																	style: 'header',
																	alignment: 'center',
																	width: '*',

																	stack:
																	[

																		// SOL DE FONDOA
																		{
																			//margin: [ -15, 0, 0, 0 ],

																			alignment: 'center',
																			//style: 'header',

																			image: 'sol.PNG', //'sampleImage.jpg'//,

																			absolutePosition: { x: -210, y: 705 },

																			fit: [ 330, 220 ],
																		},




																		// CABECERRA TODAS LAS PÁGINAS
																		{
																			margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
																			style: 'header',
																			alignment: 'center',
																			width: '*',


																			stack:
																			[
																				// HEADER
																				{
																					margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
																					style: 'header',
																					alignment: 'left',


																					columns:
																					[


																						// LOGO EKIONA, ZONA IZQUIERDA
																						{
																							margin: [ -15, 0, 0, 0 ],
																							alignment: 'left',
																							//style: 'header',

																							image: 'ekiona_logo.png',  //'sampleImage.jpg'//,

																							fit: [ 200, 180 ],
																						},



																						// ZONA DCHA
																						{
																							margin: [ 0, 10,		2, 0 ],	// margin: [ 0, 10,		10, 0 ],

																							alignment: 'right',	// 'justify'

																							columns:
																							[
																								{
																									margin: [  0, 1,	-21, 0 ],
																									alignment: 'right',
																									//style: 'header',

																									text: 'Informe técnico',  //'sampleImage.jpg'//,

																									fontSize: 17,
																									bold: true,
																									//style: ['bold'],
																									//fit: [150, 100],
																								},

																								{
																									margin: [ 0, 0,	0, 0 ],
																									alignment: 'right',
																									//style: 'header',

																									image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																									fit: [105, 80],
																								},
																							],
																						}
																					],
																				},


																				// MES, AÑO
																				{
																					margin: [ 0, -38,		5, 0 ],	// margin: [ 0, -38,		15, 0 ],
																					alignment: 'right',
																					//style: 'header',


																					/*
																					_ar_NUM_dayWEEK = new Array();
																					_ar_STR_dayWEEK = new Array();

																					_ar_NUM_monthWEEK = new Array();
																					_ar_STR_monthWEEK = new Array();

																					_ar_NUM_yearWEEK = new Array();
																					_ar_STR_yearWEEK = new Array();
																					*/

																						/*
																					text:	"Del "   +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																							"\nal "  +  _ar_STR_dayWEEK[ 6 ]  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

																					*/

																					text:	STR_FECHAS_CABECERA,		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

																					fontSize: 10,
																					bold: true,

																					//fit: [180, 150],
																				},
																			],
																		},

																		/*
																		// HEADER
																		{
																			margin: [ 0, 0, 0, 0 ],  //[ 0, 0,		5, 0 ],
																			style: 'header',
																			alignment: 'left',


																			columns:
																			[
																				// LOGO EKIONA, ZONA IZQUIERDA
																				{
																					margin: [ 0, 0, 0, 0 ],
																					alignment: 'left',
																					//style: 'header',

																					image: 'ekiona_logo.png',  //'sampleImage.jpg'//,

																					fit: [ 200, 180 ],
																				},


																				// ZONA DCHA
																				{
																					margin: [ 0, 10,		10, 0 ],
																					alignment: 'right',	// 'justify'

																					columns:
																					[
																						{
																							margin: [  0, 0,	-20, 0 ],
																							alignment: 'right',
																							//style: 'header',

																							text: 'Informe técnico',  //'sampleImage.jpg'//,

																							fontSize: 18,
																							bold: true,
																							//style: ['bold'],
																							//fit: [150, 100],
																						},

																						{
																							margin: [ 0, 0,	0, 0 ],
																							alignment: 'right',
																							//style: 'header',

																							image: 'logo_ilumek.png',  //'sampleImage.jpg'//,

																							fit: [105, 80],
																						},
																					],
																				}
																			],
																		},

																		// MES, AÑO
																		{
																			margin: [ 0, -38,		15, 0 ],
																			alignment: 'right',
																			//style: 'header',


																			text:	"Del "   +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																			"\nal "  +  STR_DiAS_MES[ STR_DiAS_MES.length - 1 ].slice( 0, 2 )   +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,


																			//text:	"Del "  +  _ar_STR_dayWEEK[ 0 ]  +  " de "  +  _ar_STR_monthWEEK[ 0 ]  +  " "  +  _ar_STR_yearWEEK[ 0 ]  +
																			//		"\nal "  +  _ar_STR_dayWEEK[ 6 ]  +  " de "  +  _ar_STR_monthWEEK[ 6 ]  +  " "  +  _ar_STR_yearWEEK[ 6 ],		//str_mes_elegido + ' ' + str_ano_elegido,  //'sampleImage.jpg'//,

																			fontSize: 10,
																			bold: true,

																			//fit: [180, 150],
																		},
																		*/




																		{
																			text:	"Página "  +  ( index + 3 )  +  " / "  +  String(  2  +  _obj_Inst_Group.info.streetlights.length  ),		 //,


																			fontSize: font_size_texto  +  0, // + 1,
																			//bold: true,

																			absolutePosition: { x: 45, y: 797 },	// { x: 34, y: 798 },

																			//margin: [ 0, 0,		 0, 0 ],

																			alignment: 'center',
																			//width: 500

																			//fit: [250, 150],
																			//width: '*',
																		},


																	],
																},



																// GRAFICAS    ------------------------
																{
																	margin: [ 10, 10,		0, 5 ],
																	alignment: 'center',

																	//width: '*',


																	//fit: [105, 80],

																	stack:		// ESTE ES EL STACK DONDE INSERTAMOS LAS OTRAS 4 GRÁFICAS POR SL
																	[
																		{
																			margin: [ 0, 15,	  20, 0 ],


																			stack:
																			[
																				{
																					margin: [ -2, 0,	0, 0 ],

																					alignment: 'center',

																					//style: 'header',

																					svg:	"<svg>" +  //  width='300' height='22'
																								"<rect x='0' y='0' rx='4' ry='4' width='510' height='16' style='fill:#272361; stroke:#272361; stroke-width:5; opacity:1.0' />" +
																							"</svg>",

																					//fit: [300, 40],

																					//width: 100,

																					//height: 25,

																					//absolutePosition: { x: -100, y: 150 },
																					//absolutePosition: { x: 0, y: 0 },

																					relativePosition: { x: 2, y: 0 },

																					opacity: 1.0,
																				},

																				{
																					text: '  Farola ' + ( index + 1 ) + ' / ' + _obj_Inst_Group.info.streetlights.length + ',  "'

																						+ item.SL_NAME + '"' + '  '  +  (
																															(  item.SL_NAME.indexOf( 'm')  !=  -1  )
																															?
																																'Maestra'
																															:
																																//'esclava'
																																(  item.SL_NAME.indexOf( 'M' )  !=  -1  )
																																?
																																	'Maestra'
																																:
																																	'esclava'
																														)
																														+  aux_str_plus,


																					//fit: [250, 150],

																					fontSize: font_size_Titulares,
																					bold: true,

																					color: "white",

																					margin: [ 5, 3,  0, 15 ],

																					alignment: 'left',
																					//width: 500
																				},



																				/*
																				{

																					margin: [  0, 0,	-10, 0 ],
																					alignment: 'left',
																					//style: 'header',

																					text: 'Farola #' + index + ': ' + item.SL_NAME,  //'sampleImage.jpg'//,		// item.name

																					fontSize: 18,
																					bold: true,
																					//style: ['bold'],
																					//fit: [150, 100],

																				},*/

																			],
																		},


																	],
																},




															],
														}

						);// .push ---------------------------------------------------------------------------------------------------------------


						//----


						// PINTAMOS 5 GRÁFICAS MÁS POR SL (EN TOTAL 5, UNA POR "SEMANA")


						for (  let isvg = 0;  isvg <= 3;  isvg++  )
						{
													//ar_obj_SVG__SL_WEEK[ isvg ][ index ].outerHTML

							var ar_obj_outerHTML =  ar_obj_SVG__SL_WEEK[ isvg ][ index ].outerHTML;


							//$( "text" ).css( "font-size", "10px" );

							ar_obj_outerHTML =  String( ar_obj_SVG__SL_WEEK[ isvg ][ index ].outerHTML ).replaceAll(	"<text ",
																														"<text style='font-size: 24px; font-weight: bolder; margin:20px;' " );

							//-------------------------------------------------

							my_docDefinition.content[  my_docDefinition.content.length - 1   ].stack[ 1 ].stack.push(

																														{
																															alignment: 'center',	//'left',

																															margin: [ -5, 	(  ( NUM_SEMANAS_mes  ==  5 ) ?  11  :  33  ), 		0, 0 ],



																															columns:
																															[

																																{
																																	width: '*',
																																	margin: [ 4, 3, 	-7, 0 ],

																																	alignment: 'left',	//'left',

																																	stack:
																																	[

																																		{
																																			//width: '3%',

																																			text: "V",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "O",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "L",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},



																																		{
																																			//width: '3%',

																																			text: "T",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "I",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 1, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "O",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "S",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 5, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},
																																	],
																																},

																																/*
																																{
																																	width: '55%',

																																	svg:  ar_obj_SVG__SL_WEEK[ const_isvg ][ index ].outerHTML, 	//svg_01_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML



																																	margin: [ -25, 0,	5, 0 ],	//  [ 5, 0,	5, 0 ],


																																	//fontSize: 5,

																																	alignment: 'center',

																																	//width: '*',


																																	//fit: [ size_H_svg_SL_WEEK, 150 ],


																																	fit: [ 		450,  100	 ],	 // 550	// 490, 120




																																	//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,
																																	//fit: [450, 250],

																																	//relativePosition: { x: -20, y: 0 },

																																	//width: 500


																																	//margin: [ 0, 10, 	10, 10 ],
																																	//alignment: 'center',

																																	//width: '100%',
																																},
																																*/


																																{
																																	svg:  ar_obj_outerHTML,		//ar_obj_SVG__SL_WEEK[ isvg ][ index ].outerHTML, 		//svg_01_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML



																																	margin: [ -7, 0,	   1, 0 ], //[ 5, 5,	5, 5 ],


																																	//fontSize: 5,

																																	alignment: 'center',


																																	width: '94%',


																																	//fit: [ size_H_svg_SL_WEEK, /*size_V_svg_SL_WEEK*/ 150 ],


																																	fit: [ 		520,  120	 ],		// 490, 120




																																	//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,
																																	//fit: [450, 250],

																																	//relativePosition: { x: -20, y: 0 },

																																	//width: 500


																																	//margin: [ 0, 10, 	10, 10 ],
																																	//alignment: 'center',

																																	//width: '100%',
																																},



																																{
																																	width: '*',

																																	margin: [ 9, 2,    0, 0 ],		//margin: [ 10, 5, 	-5, 0 ],

																																	alignment: 'right',	//'left',

																																	stack:
																																	[

																																		{
																																			//width: '3%',

																																			text: "A",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "M",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 1, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "P",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "E",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},


																																		{
																																			//width: '3%',

																																			text: "R",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "I",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ -2, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "O",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},

																																		{
																																			//width: '3%',

																																			text: "S",
																																			//fit: [250, 150],

																																			fontSize: 6,

																																			//bold: false,

																																			color: "grey",

																																			margin: [ 0, 4, 	0, 0 ],

																																			//alignment: 'left',	//'left',
																																			//width: 500
																																		},
																																	],
																																},

																															],
																														},




							); // .... push();


						}// for : isvg -----------------------------------------------------------------------------------------------------------------------------------------------



						//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



						if (  NUM_SEMANAS_mes  ==  5  )
						{
							const const_isvg =  4;

							//ar_obj_SVG__SL_WEEK[ isvg ][ index ].outerHTML

							var ar_obj_outerHTML =  ar_obj_SVG__SL_WEEK[ const_isvg ][ index ].outerHTML;


							//$( "text" ).css( "font-size", "10px" );

							ar_obj_outerHTML =  String( ar_obj_SVG__SL_WEEK[ const_isvg ][ index ].outerHTML ).replaceAll(	"<text ",
																															"<text style='font-size: 24px; font-weight: bolder; margin:20px;' " );

							//-------------------------------------------------

							my_docDefinition.content[  my_docDefinition.content.length - 1   ].stack[ 1 ].stack.push(


																													{
																														alignment: 'center',	//'left',

																														margin: [ 0, 14, 	0, 0 ],


																														columns:
																														[

																															{
																																width: '*',
																																margin: [ -1, 2, 	0, 0 ],

																																alignment: 'right',	//'left',

																																stack:
																																[

																																	{
																																		//width: '3%',

																																		text: "V",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "O",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "L",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},



																																	{
																																		//width: '3%',

																																		text: "T",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "I",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ -4, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "O",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "S",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 5, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},
																																],
																															},


																															{
																																width: '55%',

																																svg:  ar_obj_outerHTML,		//ar_obj_SVG__SL_WEEK[ const_isvg ][ index ].outerHTML, 	//svg_01_outerHTML,	//document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML



																																margin: [ 0, 0,  	2, 0 ],	//[ -25, 0,	5, 0 ],	//  [ 5, 0,	5, 0 ],


																																//fontSize: 5,

																																alignment: 'center',

																																//width: '*',


																																//fit: [ size_H_svg_SL_WEEK, /*size_V_svg_SL_WEEK*/ 150 ],


																																fit: [ 		450,  100	 ],	 // 550	// 490, 120




																																//svg: document.getElementById( "id_ComposedChart_DIMMING" ).outerHTML //document.getElementById( "RP_id_dimming_ACT" ).getElementsByClassName( "recharts-surface" )[0].parentElement.innerHTML,  //,
																																//fit: [450, 250],

																																//relativePosition: { x: -20, y: 0 },

																																//width: 500


																																//margin: [ 0, 10, 	10, 10 ],
																																//alignment: 'center',

																																//width: '100%',
																															},



																															{
																																width: '*',

																																margin: [ 5, 2,    0, 0 ],		//margin: [ 10, 5, 	-5, 0 ],

																																alignment: 'left',	//'left',

																																stack:
																																[

																																	{
																																		//width: '3%',

																																		text: "A",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "M",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ -1, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "P",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "E",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},


																																	{
																																		//width: '3%',

																																		text: "R",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "I",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 1, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "O",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},

																																	{
																																		//width: '3%',

																																		text: "S",
																																		//fit: [250, 150],

																																		fontSize: 6,

																																		//bold: false,

																																		color: "grey",

																																		margin: [ 0, 4, 	0, 0 ],

																																		//alignment: 'left',	//'left',
																																		//width: 500
																																	},
																																],
																															},

																														],
																													},






																													{
																														alignment: 'center',	//'left',

																														margin: [ 0, 15, 0, 10 ],

																														columns:
																														[

																															{
																																text: "Tensión Batería (V)",
																																//fit: [250, 150],

																																fontSize: font_size_Leyenda_MEDIA,

																																bold: false,

																																color: "green",

																																margin: [ 0, 0, 0, 0 ],

																																alignment: 'center',	//'left',
																																//width: 500
																															},

																															{
																																text: "Tensión Panel (V)",
																																//fit: [250, 150],

																																fontSize: font_size_Leyenda_MEDIA,

																																bold: false,

																																color: "red",

																																margin: [ 0, 0, 0, 0 ],

																																alignment: 'center',	//'left',
																																//width: 500
																															},

																															{
																																text: "Corriente Luminaria (A)",
																																//fit: [250, 150],

																																fontSize: font_size_Leyenda_MEDIA,

																																bold: false,

																																color: "blue",

																																margin: [ 0, 0, 0, 0 ],

																																alignment: 'center',	//'left',
																																//width: 500
																															},
																														],
																													},


							); // .... push();

						} // if (  NUM_SEMANAS_mes  ==  5  )  ------------------
						else
						{

							//--------

							my_docDefinition.content[  my_docDefinition.content.length - 1   ].stack[ 1 ].stack.push(

																														{
																															alignment: 'center',	//'left',

																															margin: [ 0, 35, 0, 10 ],

																															columns:
																															[

																																{
																																	text: "Tensión Batería (V)",
																																	//fit: [250, 150],

																																	fontSize: font_size_Leyenda_MEDIA,

																																	bold: false,

																																	color: "green",

																																	margin: [ 0, 0, 0, 0 ],

																																	alignment: 'center',	//'left',
																																	//width: 500
																																},

																																{
																																	text: "Tensión Panel (V)",
																																	//fit: [250, 150],

																																	fontSize: font_size_Leyenda_MEDIA,

																																	bold: false,

																																	color: "red",

																																	margin: [ 0, 0, 0, 0 ],

																																	alignment: 'center',	//'left',
																																	//width: 500
																																},

																																{
																																	text: "Corriente Luminaria (A)",
																																	//fit: [250, 150],

																																	fontSize: font_size_Leyenda_MEDIA,

																																	bold: false,

																																	color: "blue",

																																	margin: [ 0, 0, 0, 0 ],

																																	alignment: 'center',	//'left',
																																	//width: 500
																																},
																															],
																														},

							); // .... push();


						}// else



					}// ( item, index ) =>
				//
				);// .forEach -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


				//console.log( _obj_Inst_Group.info.streetlights.length );






				//console.log(  "AMANECERES:  renderPDF"  +  JSON.stringify( _ar_STR_sunriseWEEK  )  );







				const PDF_mio = pdfMake.createPdf( my_docDefinition );

				//PDF_mio.download( "Ekiona_01" );   // FUNCIONA PERO LO DESCARGAR AUTOMÁTICAMENTE

				//----------------------------------------------------

				PDF_mio.getDataUrl(
										( dataUrl ) =>
										{
											const targetElement = document.getElementById( "iframeContainer_PDFMake" );		//.querySelector( '#iframeContainer_PDFMake' );		//document.getElementById( "iframeContainer_PDFMake" ); 	//document.querySelector( '#iframeContainer_PDFMake' );

											const iframe = document.createElement( 'iframe' );


											iframe.id = "id_my_iframe";

											iframe.name = "Ekioina_Iframe";

											iframe.width = "1120"; // 1280 // "980"
											iframe.height = "1235";	// 1195


											iframe.style = "margin: auto;";


											iframe.src = dataUrl;

											targetElement.appendChild( iframe );
										}
				);//

					//svg_01_obj.hidden = true;

				/*
				svg_01_obj.innerHTML = "";
				svg_01_obj.outerHTML = "";

				svg_01_obj = null;
				*/



				//svg_04_obj.innerHTML = "";




				//console.log(		JSON.stringify(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  )		);


				//------

											// ESTA ES LA INFO GENERAL DEL INSTALLATION GROUP (PERO NO SON LAS TRAMAS)
				//_obj_Inst_Group =  null;	// LIBERAR MEMORIA UNA VEZ HEMOS ACABADO CON TODO!


				// LUEGO HAY QUE CONSULTAR, MEJOR NO PONER A NULL


				// QUIZÁ PONER EL ARRAY FINAL QUE EN PRINCIPIO YA NO SE USA MÁS


				//-------------





				//document.getElementById( "id_Div_info" ).hidden =  "false";  //"true";  // MENSAJE DE GENERANDO PDF






				//----------------------------------

				//_cont_rerender_svg += 1;		//++;

				//--------





				//------------------------

				this.state.global_PDF_Generado_y_Renderizado =  "true";

				//------------------------


				this.state.LAST_re_render = "true";



				this.setState(
									{
										LAST_re_render: "true"  //,

										//global_PDF_Generado_y_Renderizado:  "true"
									}
				);//.setState()



				////window.alert(  "this.state.global_PDF_Generado_y_Renderizado:  "  +  this.state.global_PDF_Generado_y_Renderizado  );






				//-----------

				// RESETEAR Y LIBERAR MEMORIA" DE SVGs

				for (  let iweek = 0;  iweek <=  ( NUM_SEMANAS_mes - 1 );  iweek++  )	  // 4			// poner a 4
				{
					for (  let mySLS = 0;  mySLS <  	_obj_Inst_Group.info.streetlights;  mySLS++  )
					{

						//ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ] = document.getElementById( "weeks_svg_SL"  + mySLS +  "_W"  +  iweek );

						//---

						//////console.log(  "_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[" + iweek + "][" + mySLS + "].length = "  +  (_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat).length  );


						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat = undefined;
						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat = null;
						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ mySLS ].dat = [];



						(ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ]).innerHTML =  "";
						(ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ]).outerHTML =  "";

						//(ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ]).remove();

						ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ].remove();


						ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ] =  undefined;
						ar_obj_SVG__SL_WEEK[ iweek ][ mySLS ] =  null;

					}// for


					//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ] = undefined;
					//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ] = null;
					//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ] = [];


					ar_obj_SVG__SL_WEEK[ iweek ] = undefined;
					ar_obj_SVG__SL_WEEK[ iweek ] = null;

				} // for

				//---

				//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK = undefined;
				//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK = null;


				ar_obj_SVG__SL_WEEK =  undefined;	//[];
				ar_obj_SVG__SL_WEEK =  null;	//[];

				ar_obj_SVG__SL_WEEK =  [];	//[];


				//---------------------------------------------------------------------------------------------------------------




				//NUM_CALL_API =  0;



				//TODOS_DATOS_ACCESIBLES =  false;

				//HTML_GRAFS_GEN_DATOS_ACCESIBLES =  false;




				//NUM_renders_SVG_rendered =  0;







				//this.state.LAST_re_render = "false";

				//this.state.sunRise_sunSet_GET = "false";

				//this.state.SCRIPT_svg_rendered = "false";



				/*
				this.setState(

					{
						LAST_re_render: "false",

						sunRise_sunSet_GET: "false",

						SCRIPT_svg_rendered: "false",


						total_streetlights: 0,


						selected_installationgroup:  {}
					}

				); // this.setState(
				*/






				//------






				/*
				_obj_Inst_Group.info.streetlights = undefined;
				_obj_Inst_Group.info.streetlights = null;


				_obj_Inst_Group = undefined;
				_obj_Inst_Group = null;
				*/



				//------------------------

				//this.state.global_PDF_Generado_y_Renderizado =  true;

				//------------------------

			}// if   -------------------------------------------------------------------------------------------



			//else
			//{
				//this.forceUpdate();
			//}//


		}// if (  this.state.SCRIPT_svg_rendered == "true" ) ---------------------------------------


		// (   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )



		//const this_= this;   // (3ero puesto)



		//let refAreaLeft = null;
		//let refAreaRight = null;




		//--

		//let master_sl = {};

		if(  this.state.selected_installationgroup.id  ==  ""  )
		{
			return <div/>
		};


		/*
		this.state.selected_installationgroup.info.streetlights.forEach(
																			( item, index ) =>
																			{
																				if (  item.cpu_is_master  )
																				{
																					master_sl = item;
																				}
																			}
		);//.forEach()
		*/


		let slider_index = -1;

		/*
		let minutes_active = "";
		let seconds_active = "";

		if (  this.state.wait_in  ==  "seconds"  )
		{
			seconds_active ="btn btn-sm btn-light active";
			minutes_active = "btn btn-sm btn-light ";
		}
		else
		{
			seconds_active = "btn btn-sm btn-light ";
			minutes_active = "btn btn-sm btn-light active";
		}
		*/






		//_node = document.getElementById( 'dropdowncontainer' ); // 'id_div_graphSOURCE_01'  // 'id_report01'  // 'dropdowncontainer'

		//---
		/*
		function filter ( node )
		{
			return (  node.tagName !== 'p'  &&  node.tagName !== 'br'  );
		}//
		*/
		//---

		/*
		_AppPDFViewer_trigger =	( ) =>
								{
									console.log( "AppPDFViewer_trigger  HECHO **********" );

									//document.getElementById( "id_div_PDF" ).innerHTML = "<AppPDFViewer id='id_AppPDFViewer_01' />";

									return (  <AppPDFViewer />   );		//  id="id_AppPDFViewer_01"
								};
		*/
		//_AppPDFViewer_trigger();





		//---------------------------------------


				//(   this.state.sunRise_sunSet_GET  ==  "true"   )   &&

		if (  this.state.sunRise_sunSet_GET  ==  "true"   /*true*/  /*_data_Retrieved ==*/  )  //--------------------------------------------------------------------
		{


			/*
			_MyPDFDoc =	( ) =>
						(
							<Document>

								<Page size="A4">

									<View>
										<Image src= "/EKIONA_PDF/ekiona_logo.png">
										</Image>
									</View>

									<View>
										<Text>
											Esto ya es un PDF
										</Text>
									</View>

								</Page>

							</Document>
						);
			*/





			const this_ =  this;		// (2do puesto


			//---

			//console.log( "desde RENDER  AMANECERES___"  +  /*this.state.sunriseDAY*/  AMANECERES_WEEK );



			//console.log( "DATOS DE TODAS __INSTALACIONES __" + "" + ":\n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs,   null,   "		"  ) );
			//console.log( "DATOS DE _INSTALACIONES _ #0" + "" + ":\n"  +  JSON.stringify(  _ar_INSTALLATIONS__SLs_VARVALs[0],   null,   "		"  ) );

			//console.log( "DATOS DE _INSTALACIONES _ #0, STREELIGHT #0" + "" + ":\n"  +  JSON.stringify(  ( _ar_INSTALLATIONS__SLs_VARVALs[0] ) ?  _ar_INSTALLATIONS__SLs_VARVALs[0][0] : undefined,   null,   "		"  ) );


			//console.log( this_.state.comp_CHART_DATA.slice( 10, 45 )   );


			/*
			console.log( "DATOS DE _INSTALACIONES _ TODAS, STREELIGHT #0 / 9" + "" + ":\n"  +

							JSON.stringify(
											( typeof(_ar_ALL__STREETLIGHTS_VARVALs )  !=  "undefined" )

												?
													(  typeof( _ar_ALL__STREETLIGHTS_VARVALs[0] )  != "undefined"  )  ?   _ar_ALL__STREETLIGHTS_VARVALs[0]  :   "FAROLA 0 VACIA" 	 //.slice( 1, _ar_INSTALLATIONS__SLs_VARVALs[0][0].data - 2 )
												:
													"STREETLIGHTS ARRAY NO EXISTE",  //undefined,

										   null,   "		"  )
						);
			*/

			//-------

			/*
							this.setState(
											{
												//profileName:"Schedule",
												//showSpinner: false,

												sunriseDAY: this.state.sunriseDAY.push( day_sunrise ),
												sunsetDAY:  this.state.sunsetDAY.push(  day_sunset ),
											}
							);//
			*/

			if	(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
			{






				//NUM_renders_SVG_rendered

				//if (  this.state.sunRise_sunSet_GET  ==  "true"  )
				//if (  this.state.LAST_re_render  ==  "true"  )   // (  this.state.SCRIPT_svg_rendered = "true"  )

				//if (   (  NUM_renders_SVG_rendered  ==  2  )   &&   (  PULSADO_BOTON  ==  false  )   )











				// VERIFICAR QUE EL OBJETO ESTÉ CREADO

				/*
				if (   (  _Num_Renders_RENDER_FUNC  ==  6  )   &&   (  PULSADO_BOTON  ==  false  )   )
				{

					//window.alert(  "ENTRA en zona boton 1"  );

					//--------

					const targetElement = document.getElementById( "id_Div_button" );		//.querySelector( '#iframeContainer_PDFMake' );		//document.getElementById( "iframeContainer_PDFMake" ); 	//document.querySelector( '#iframeContainer_PDFMake' );

					const our_button = document.createElement( "button" );

					//const our_p = document.createElement( "p" );


					our_button.id = "id_my_button";


					//our_button.name = "Segundo Boton";


					our_button.innerHTML = "Generar Informe";



					our_button.onclick =	() =>
											{
												PULSADO_BOTON =  true;


												//window.alert(  "EEEEEEOOOOOOOO"  );
											};


					//iframe.width = "1120"; // 1280 // "980"
					//iframe.height = "1235";	// 1195

					//iframe.style = "margin: auto;";




					targetElement.appendChild( our_button );


					//-------------------

					//-------------------





				}// if ( NUM_renders_SVG_rendered  ==  1  ) //----------------------------------------------------------------------

				*/



				//-------




					/*
					this.state.pulsado_Button =  "true";

					this.setState(
									{
										pulsado_Button: "true",
									}
					);
					*/


					/*

									<button
										onclick ="Generar_Informe()"
									>
										Generar PDF Informe
									</button>
					*/



























				//------------------


				if ( true /*PULSADO_BOTON  ==  false*/  )
				{

					////window.alert(  "RENDER HTML"  );

					//return (

					const HTML_RENDER =	(

								<div	id ="dropdowncontainer"
										className = "container-fluid"
										width="100%"

										//onload=""

										style={{margin: "auto"}}

								> 	{/*marginBottom:"35px", marginLeft:"-10px"*/}


									{
									/*
									<div className="row">
										{
											//<div className="row">: LISTA DESPLEGABLE INSTALLATION_GROUP
										}
										<div id="cab-general" className="container-fluid" style={{marginLeft: "3px"}}>
											<div className="actions-left col-md-4 col-xs-12" style={{marginTop: "-5px"}}>


												<li className="nav-item dropdown" >

													<SelectorInstalaciones

														showAll = { false }

														onDataChanged =
																		{
																			( e ) =>
																			{

																				//CONT_axios_Alarms = 0;

																				this.setState(
																								{
																									selected_installationgroup: e,

																									total_streetlights: e.info.streetlights.length
																								}
																				);

																				//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);



																				//CONFIG[ "setPreferredInstallation" ](  e.value  );
																			}
																		}
													/>

												</li>

											</div>
										</div>
										{
											//<div className="row">: END: LISTA DESPLEGABLE INSTALLATION_GROUP
										}
									</div>
									*/
									}

									<center>

									<br/>


									<div id="id_div_informe_mensual">


										{
										/*
										<Returnobjhtml />
										*/

										<div>
										<center>
											<br/>
											{ /*console.log(  JSON.stringify(  svg_01_outerHTML  )  )*/  }

											{
												//<h2> Instalación &#34;{ this.state.selected_installationgroup.group_name /*_obj_Inst_Group.group_name*/ }&#34; </h2>
											}

											<h2> {  this.state.selected_installationgroup.group_name  } </h2>

											</center>
										</div>
										}

										{
										<div hidden>
											<br/>
											{ /*console.log(  JSON.stringify(  svg_01_outerHTML  )  )*/  }
											<p>  <b> GLOBAL_SIG_dat_SL_week = { GLOBAL_SIG_dat_SL_week }  </b> </p>

											<p hidden>  <b> const_cota_Min_Corriente_ENCENDIDO = { const_cota_Min_Corriente_ENCENDIDO }  </b> </p>
										</div>
										}




										{

										//( this.state.LAST_re_render != "false" )  &&

										/*
										<script>

										{

											function Generar_Informe ( )
											{
												window.alert(  "PULSADO"  );
											}//

										}

										</script>
										*/
										}




										{
										//( this.state.LAST_re_render != "true" )  &&		// this_.LAST_re_render

										/*
										<div id="id_Div_button">
										</div>
										*/
										}









										{
										/*
										( this.state.LAST_re_render != "true" )  &&		// this_.LAST_re_render

										<div id="id_Div_info">
										<center>
											<br/>
											<br/>
											{
												//console.log(  JSON.stringify(  svg_01_outerHTML  )  )
											}
											<p>  <b> GENERANDO PDF, espere un momento por favor </b> </p>
											<br/>
										</center>
										</div>
										*/
										}




										{
										<span>

											{
												( this.state.LAST_re_render == "false" )  &&

												this.showSpinner()

													/*
																		this.setState(
													{
														//profileName:"Schedule",
														//showSpinner: false,

														sunriseDAY: this.state.sunriseDAY.push( day_sunrise ),
														sunsetDAY:  this.state.sunsetDAY.push(  day_sunset ),
													}
													);
													*/
											}
										</span>
										}







										{
										//( this.state.SCRIPT_svg_rendered == "true" )  &&
										/*
										( this.state.LAST_re_render == "true" )  &&		// this_.LAST_re_render

										<div>
										<center>
											<br/>
											{
												//console.log(  JSON.stringify(  svg_01_outerHTML  )  )
											}
											<p>  <b> PDF A SU DISPOSICIÓN </b> </p>
										</center>
										</div>
										*/
										}










										{
										( true )  &&

										( this.state.sunRise_sunSet_GET == "true" )  &&

										//( _cont_rerender_svg == 0  )  &&


										<div style={{margin: "auto", width: "60%"}}>
										<center>


											<div id="id_div_graphSOURCE_01" className="container-fluid" style={{margin: "auto"}}  hidden>   {/*hidden*/}

											<center>
												{/* SE PINTA LA GRÁFICA DEL DIMMING DETALLADA */}

												<br/>



												{/*
												<img src="https://www.eldiario.es/assets/img/svg/logos/eldiario-tagline-2c.svg?id=129fb361f1eeeaf4af9b" alt="img_aux" onload={ () => {console.log("CAAAARRGADAAAA!!!");} } />
												*/}

												{
												//<GRAPH_recharts01 />

												( this.state.LAST_re_render != "true" )  &&

												(  /*this_.state.comp_CHART_DATA.length > 10*/ true  )  &&  ( this.state.sunRise_sunSet_GET == "true" )  &&



												<ResponsiveContainer	id = "RP_id_dimming_ACT"

																		width={ /*graph_size.xsize*/ 1800 }

																		height={ /*graph_size.ysize*/ 450 }


																		//debounce={0}

																		isAnimationActive={false}


												>

													<ComposedChart
																	data = { this_.state.comp_CHART_DATA.slice( 14, 43 ) }		// this_.state.comp_CHART_DATA.slice( 10, 45 )

																	ref =	{
																				( e ) =>
																				{
																					this_._chart_data  =  e;
																				}
																			}

																	isAnimationActive={false}

																	id = "id_ComposedChart_DIMMING"

													>

														{
														// GRID CARTESIANO

														<CartesianGrid stroke="#ccc" vertical={false} />

														}



														{
														// AREA DE COTA GRIS DE FONDO

														//<Area type='step' dataKey='sun' fill='#8a8a8a' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>

														}
														{
														// AREA DE COTA GRIS CLARO DE FONO

														//<Area type='step' dataKey='sun0' fill='#dedede' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>

														}



														{
														// LINEA GORDA CON SUS PUNTOS / CÍRCULOS
														/*
														<Line type="step" dataKey="sp" stroke="orange" strokeWidth={3} isAnimationActive={false}/>
														*/
														}

														{
														// AREA / SUPERFICIE NARANJA DEBAJO DE LÍNEA GORDA
														/*
														<Area type='step' dataKey='sp' fill='orange' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
														*/
														}



														{
														/*
														<Area type='bar' dataKey='sp' barSize={20} fill='red' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
														*/
														}


														{// BARRAS / COLUMNAS

														<Bar dataKey='sp' barSize={53} fill='orange' stroke='transparent' fillOpacity="1.0" isAnimationActive={false}/>

														}



														{
														// EJE X

														<XAxis
															dataKey="name"
															interval = { 1 }

															tickMargin={12}

															//interval = { parseInt( this_.state.comp_CHART_DATA.slice( 14, 43 ).length / 2 ) }
														/>

														}


														{
														// EJE Y

														<YAxis
															domain = { [ 0, 100 ] }


															//interval = { 20 }
														/>

														}

														{
														/*
														(  refAreaLeft  &&  refAreaRight  )		?
																								(	<ReferenceArea
																													yAxisId = "1"
																													x1 = { refAreaLeft }
																													x2 = { refAreaRight }
																													strokeOpacity = { 0.3 }
																									/>
																								)
																								:
																								null
														*/
														}

													</ComposedChart>

												</ResponsiveContainer>

												}

												{
												( this.state.LAST_re_render != "true" )  &&


												<script
													id = "id_script_AUX"

													onload =	{
																	//window.alert( "ONLOAD" )
																	(  this.state.SCRIPT_svg_rendered = "true"  )  //?  this.state.SCRIPT_svg_rendered = "false"  :  this.state.SCRIPT_svg_rendered = "true"
																}
												>
														{

															mi_aux = 	(  (  this.state.SCRIPT_svg_rendered == "true"  )  &&  (  NUM_renders_SVG_rendered <= /*2*/ 2  )  )
																						?
																							this.setState( { SCRIPT_svg_rendered: "true" } )
																						:
																							"false"



															//window.alert( "SCRIPT" )

																//() =>
																//{
																//	window.alert( "SCRIPT" );
																	/*
																	var svg_01_obj = document.getElementById( "id_ComposedChart_DIMMING" );


																	if (   svg_01_obj  !=  null  )
																	{

																		var svg_01_outerHTML =  svg_01_obj.outerHTML;



																		//$( "text" ).css( "font-size", "10px" );

																		svg_01_outerHTML =  String (svg_01_outerHTML ).replaceAll(	"<text ",
																																	"<text style='font-size: 10px' " );

																	*/
																//}//
															}
												</script>
												}

												<br/>
												<br/>





											</center>
											</div>




											{

												/*
												var aux_objnow =	{
													num_horas:  0.0,

													dia_mes: ""
												};


												aux_objnow.num_horas =  parseFloat(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ]  )  /  parseFloat( 2.0 );


												if (  (iday  +  (  iw * 7  ) )  <   NUM_DAYS_MONTH  )
												{
												aux_objnow.dia_mes   =  STR_DiAS_MES[ iday  +  (  iw * 7  ) ];
												}

												MEDIA_HORAS_ENCENDIDO_DIA.push(  aux_objnow  );
												*/



												//<GRAPH_recharts01 />



												//( this.state.LAST_re_render != "true" )  &&

												(  /*this_.state.comp_CHART_DATA.length > 10*/   true  )  &&  ( this.state.sunRise_sunSet_GET == "true" )  &&

												<div hidden>
													<ResponsiveContainer	id = "id_RP_MEDIA_HORAS"

																			width={ /*graph_size.xsize*/ 1800 }

																			height={ /*graph_size.ysize*/ 450 }


																			//debounce={0}

																			isAnimationActive={false}
																			//isAnimationActive={false}

													>

														<ComposedChart
																		data = {  MEDIA_HORAS_ENCENDIDO_DIA }		// this_.state.comp_CHART_DATA.slice( 10, 45 )
																		/*
																		ref =	{
																					( e ) =>
																					{
																						this_._chart_data  =  e;
																					}
																				}
																		*/
																		isAnimationActive={false}

																		id = "id_BAR_MEDIA_HORAS"

														>

															{
															// GRID CARTESIANO

															<CartesianGrid stroke="#ccc" vertical={false} />

															}



															{
															// AREA DE COTA GRIS DE FONDO

															//<Area type='step' dataKey='sun' fill='#8a8a8a' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>

															}
															{
															// AREA DE COTA GRIS CLARO DE FONO

															//<Area type='step' dataKey='sun0' fill='#dedede' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>

															}



															{
															// LINEA GORDA CON SUS PUNTOS / CÍRCULOS
															/*
															<Line type="step" dataKey="sp" stroke="orange" strokeWidth={3} isAnimationActive={false}/>
															*/
															}

															{
															// AREA / SUPERFICIE NARANJA DEBAJO DE LÍNEA GORDA
															/*
															<Area type='step' dataKey='sp' fill='orange' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}



															{
															/*
															<Area type='bar' dataKey='sp' barSize={20} fill='red' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}


															{// BARRAS / COLUMNAS

															<Bar dataKey='fhoras_sunset_sunrise' barSize={13} fill='grey' stroke='transparent' fillOpacity="0.4" isAnimationActive={false}/>

															}



															{// BARRAS / COLUMNAS

															<Bar dataKey='num_horas' barSize={40} fill='orange' stroke='transparent' fillOpacity="1.0" isAnimationActive={false}/>

															}




															{
															// EJE X

															//STR_DiAS_MES[ id ]   MEDIA_HORAS_ENCENDIDO_DIA[ iday  +  (  iw * 7  )  ]

															<XAxis
																dataKey="dia_mes"

																//height={50}

																//offset={10}

																tickMargin={13}

																//tick={ <CustomXAxisLabel/> }
																//interval = { 0 }

																//interval = { 1 }
																//interval = { parseInt( this_.state.comp_CHART_DATA.slice( 14, 43 ).length / 2 ) }

																isAnimationActive={false}
															/>

															}


															{
															// EJE Y

															<YAxis
																domain = { [ 0, 16 ] }

																//interval = { 20 }

																tickCount = {9}
															/>

															}

															{
															/*
															(  refAreaLeft  &&  refAreaRight  )		?
																									(	<ReferenceArea
																														yAxisId = "1"
																														x1 = { refAreaLeft }
																														x2 = { refAreaRight }
																														strokeOpacity = { 0.3 }
																										/>
																									)
																									:
																									null
															*/
															}

														</ComposedChart>

													</ResponsiveContainer>
												</div>
												}




											{




												/*
												var aux_objnow =	{
													num_horas:  0.0,

													dia_mes: ""
												};


												aux_objnow.num_horas =  parseFloat(  aux_datos_MEDIA_ENCENDIDO__DIA[ iw ][ iday ]  )  /  parseFloat( 2.0 );


												if (  (iday  +  (  iw * 7  ) )  <   NUM_DAYS_MONTH  )
												{
												aux_objnow.dia_mes   =  STR_DiAS_MES[ iday  +  (  iw * 7  ) ];
												}

												MEDIA_HORAS_ENCENDIDO_DIA.push(  aux_objnow  );
												*/






												//<GRAPH_recharts01 />

												//( this.state.LAST_re_render != "true" )  &&

												(  /*this_.state.comp_CHART_DATA.length > 10*/ true  )  &&  ( this.state.sunRise_sunSet_GET == "true" )  &&



												<div hidden>
													<ResponsiveContainer	id = "id_RP_AHORRO"

																			width={ /*graph_size.xsize*/ 1800 }

																			height={ /*graph_size.ysize*/ 500 }


																			//debounce={0}

																			isAnimationActive={false}


													>

														<ComposedChart
																		data = {  MEDIA_HORAS_ENCENDIDO_DIA }		// this_.state.comp_CHART_DATA.slice( 10, 45 )
																		/*
																		ref =	{
																					( e ) =>
																					{
																						this_._chart_data  =  e;
																					}
																				}
																		*/
																		isAnimationActive={false}

																		id = "id_BAR_AHORRO"

														>

															{
															// GRID CARTESIANO

															<CartesianGrid stroke="#ccc" vertical={false} />

															}



															{
															// AREA DE COTA GRIS DE FONDO

															//<Area type='step' dataKey='sun' fill='#8a8a8a' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>

															}
															{
															// AREA DE COTA GRIS CLARO DE FONO

															//<Area type='step' dataKey='sun0' fill='#dedede' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>

															}



															{
															// LINEA GORDA CON SUS PUNTOS / CÍRCULOS
															/*
															<Line type="step" dataKey="sp" stroke="orange" strokeWidth={3} isAnimationActive={false}/>
															*/
															}

															{
															// AREA / SUPERFICIE NARANJA DEBAJO DE LÍNEA GORDA
															/*
															<Area type='step' dataKey='sp' fill='orange' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}



															{
															/*
															<Area type='bar' dataKey='sp' barSize={20} fill='red' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}



															{// BARRAS / COLUMNAS
																			// RESULTADO_AHORRO_TEORICO_aprox
																<Bar dataKey='RESULTADO_AHORRO_TEORICO' barSize={13} fill='grey' stroke='transparent' fillOpacity="0.4" isAnimationActive={false}/>

															}


															{// BARRAS / COLUMNAS

																<Bar dataKey='RESULTADO_AHORRO' barSize={40} fill='orange' stroke='transparent' fillOpacity="1.0" isAnimationActive={false}/>

															}



															{
															// EJE X

															//STR_DiAS_MES[ id ]   MEDIA_HORAS_ENCENDIDO_DIA[ iday  +  (  iw * 7  )  ]

															<XAxis
																dataKey="dia_mes"

																tickMargin={13}
																//interval = { 1 }
																//interval = { parseInt( this_.state.comp_CHART_DATA.slice( 14, 43 ).length / 2 ) }
															/>

															}


															{
															// EJE Y

															<YAxis

																tickCount = { 7 }

																//domain = { [ 0, 18 ] }


																//interval = { 20 }
															/>

															}

															{
															/*
															(  refAreaLeft  &&  refAreaRight  )		?
																									(	<ReferenceArea
																														yAxisId = "1"
																														x1 = { refAreaLeft }
																														x2 = { refAreaRight }
																														strokeOpacity = { 0.3 }
																										/>
																									)
																									:
																									null
															*/
															}

														</ComposedChart>

													</ResponsiveContainer>
												</div>
												}


































												{/*
												<img src="https://www...." alt="img_aux" onload={ () => {console.log("CAAAARRGADAAAA!!!");} } />
												*/}

												{
												//<GRAPH_recharts01 />

												( false )  &&

												( this.state.LAST_re_render != "true" )  &&

												(  /*this_.state.comp_CHART_DATA.length > 10*/ true  )  &&  ( this.state.sunRise_sunSet_GET == "true" )  &&


												<div hidden>
													<ResponsiveContainer	id = "chart_02_prueba"

																			width={graph_size.xsize}

																			height={graph_size.ysize}


																			debounce={0}

																			//isAnimationActive={false}

																			//hidden
													>



														<ComposedChart
																		data = { this_.state.comp_CHART_DATA.slice( 10, 45 ) }		// this_.state.comp_CHART_DATA.slice( 10, 45 )

																		ref =	{
																					( e ) =>
																					{
																						this_._chart_data  =  e;
																					}
																				}

																		//isAnimationActive={false}

																		id = "id_ComposedChart_02"

																		//hidden
														>
															{
															// EJE X

															<XAxis dataKey="name"  	tickMargin={15}/>

															}


															{
															// EJE Y

															<YAxis
																domain = { [ 0, 100 ] }
																interval = { 1 }
															/>

															}

															{
															// GRID CARTESIANO

															<CartesianGrid stroke="#ccc"  vertical={false} />

															}



															{
															// AREA DE COTA GRIS DE FONDO
															/*
															<Area type='step' dataKey='sun' fill='#9a9a9a' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}
															{
															// AREA DE COTA GRIS CLARO DE FONO
															/*
															<Area type='step' dataKey='sun0' fill='#dedede' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}



															{
															// LINEA GORDA CON SUS PUNTOS / CÍRCULOS

															<Line type="monotone" dataKey="sp" stroke="blue" strokeWidth={1}/>

															}

															{
															// AREA / SUPERFICIE NARANJA DEBAJO DE LÍNEA GORDA
															/*
															<Area type='step' dataKey='sp' fill='orange' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}



															{
															/*
															<Area type='bar' dataKey='sp' barSize={20} fill='red' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}


															{// BARRAS / COLUMNAS
															/*
															<Bar dataKey='sp' barSize={20} fill='red' stroke='transparent' fillOpacity="0.3" isAnimationActive={false}/>
															*/
															}





															{
															/*
															(  refAreaLeft  &&  refAreaRight  )		?
																									(	<ReferenceArea
																														yAxisId = "1"
																														x1 = { refAreaLeft }
																														x2 = { refAreaRight }
																														strokeOpacity = { 0.3 }
																										/>
																									)
																									:
																									null
															*/
															}


														</ComposedChart>

													</ResponsiveContainer>

												</div>

												}








												{/*
												<img src="https://www....." alt="img_aux" onload={ () => {console.log("CAAAARRGADAAAA!!!");} } />
												*/}


												{
												//<GRAPH_recharts03 />

												( false )  &&

												( this.state.LAST_re_render != "true" )  &&

												(  /*this_.state.comp_CHART_DATA.length > 10*/ true  )  &&  ( this.state.sunRise_sunSet_GET == "true" )  &&


												<div hidden>
													<ResponsiveContainer	id = "chart_03_prueba"

																			width={2300}

																			height={450}


																			debounce={0}

																			isAnimationActive={false}

																			hidden
													>


														<LineChart	data={ this_.state.comp_CHART_DATA.slice( 10, 45 ) }
																	/*
																	ref =	{
																				( e ) =>
																				{
																					this_._chart_data  =  e;
																				}
																			}
																	*/

																	//width={graph_size.xsize} height={graph_size.ysize}


																	isAnimationActive={false}


																	id = "id_ComposedChart_03"     //"chart_03_prueba"


																	margin={{ top: 20, right: 20, left: 45, bottom: 20 }}

																	hidden
														>


															<CartesianGrid stroke="#ccc"  vertical={false} />
															{
															// EJE X

															<XAxis dataKey="name"  	tickMargin={15}/>

															}


															{
															// EJE Y

															<YAxis
																domain = { [ 0, 100 ] }
																interval = { 1 }
															/>

															}



															<Line type="monotone" dataKey="sp" stroke="green" strokeWidth={1} isAnimationActive={false} />


														</LineChart>


													</ResponsiveContainer>

												</div>
												}












												{
												//<GRAPH_recharts 04 />

												( false )  &&

												( this.state.LAST_re_render != "true" )  &&

												( this.state.sunRise_sunSet_GET == "true" )  &&


												<div hidden>
													<ResponsiveContainer	id = "chart_04_prueba"

																			width={/*2500*/1600}

																			height={/*800*/400}


																			//debounce={0}

																			isAnimationActive={false}

																			//hidden
													>


														<LineChart
																	data=	{
																				(  (  typeof( ar_MEDIA_period_WEEK__FINAL ) != "undefined"  )	 && 	(  ar_MEDIA_period_WEEK__FINAL  !=  null  )	&&	(  ar_MEDIA_period_WEEK__FINAL.length  >  0  )  )
																				?
																					(  (  typeof( ar_MEDIA_period_WEEK__FINAL[ 0 ] ) != "undefined"  )
																					?
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ]		//_ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ][ 0 ].dat

																						//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ 0 ]


																						( ar_MEDIA_period_WEEK__FINAL[ 0 ].slice( 24, 48 ) ).concat(  ar_MEDIA_period_WEEK__FINAL[ 0 ].slice( 0, 25 )  )

																						// ar_MEDIA_period_WEEK__FINAL

																						//ar_MEDIA_WEEK__FINAL[ 0 ][  iper  +  ( iday * 48 )  ]
																					:
																						[0,0,0]
																					)
																				:
																					[0,0,0]
																			}

																	/*
																	ref =	{
																				( e ) =>
																				{
																					this_._chart_data  =  e;
																				}
																			}
																	*/

																	//width={graph_size.xsize} height={graph_size.ysize}



																	id = "id_ComposedChart_04"     //"chart_03_prueba"


																	margin={{ top: 20, right: 20, left: 45, bottom: 50 }}


																	isAnimationActive={false}
														>


															<CartesianGrid stroke="#ccc"  vertical={false} />


															{
															// EJE X

																																//(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != "undefined"  )	 && 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  )	&&	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  >  0  )  )
																					//?
																						//Math.round(_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ].length  /  7 )
																						//Math.round(  	(_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ][ 0 ].dat).length  /  12   )
																					//:
																					//	1

															<XAxis
																	dataKey = "HORA_INICIO_PERIODO_30m"

																	tickMargin={15}

																	//interval =	{12	}
															/>

															}



															<YAxis domain = { [ parseFloat( 0.0 ), _c_MAX_INST_GROUP__VARS_BP ] }/>




															<YAxis yAxisId="right" orientation="right" domain = { [ parseFloat( 0.0 ), _c_MAX_INST_GROUP__VARS_C ] }/>








															{
															// EJE Y


															/*
															<YAxis yAxisId="left" />
															<YAxis yAxisId="right" orientation="right" />


															<YAxis
																yAxisId="right"

																orientation="right"

																//dataKey={ graph_ejeY }

																domain = { [ 0, 6 ] }
																//interval = { 10 }
															/>
															*/
															}

															{
															//<Legend/>
															}


															<Line type="monotone" dataKey={ "SUM__MEDIA_B"  } stroke="green" strokeWidth={1} isAnimationActive={false}/>

															<Line type="monotone" dataKey={  "SUM__MEDIA_P"  } stroke="red" strokeWidth={1} isAnimationActive={false}/>


															<Line type="monotone" yAxisId="right" dataKey={  "SUM__MEDIA_C" } stroke="blue" strokeWidth={1} isAnimationActive={false}/>


														</LineChart>


													</ResponsiveContainer>

												</div>

												}













												{
												//<GRAPH_recharts 04 />

												( true )  &&

												( this.state.LAST_re_render != "true" )  &&

												( this.state.sunRise_sunSet_GET == "true" )  &&



												<div hidden>
													<ResponsiveContainer	id = "id_RespCont_svg_MEDIA_allSLs_HORA"	//"chart_08_prueba"

																			width={/*2500*//*1470*/1600}

																			height={/*800*/550}


																			//debounce={0}

																			isAnimationActive={false}

																			//hidden
													>


														<LineChart
																	data=	{
																				(  (  typeof( ar_MEDIA_period_MONTH__FINAL ) != "undefined"  )	 && 	(  ar_MEDIA_period_MONTH__FINAL  !=  null  )	&&	(  ar_MEDIA_period_MONTH__FINAL.length  >  0  )  )
																				?
																					//(  (  typeof( ar_MEDIA_period_WEEK__FINAL[ 0 ] ) != "undefined"  )
																					//?
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ]		//_ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ][ 0 ].dat

																						//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ 0 ]


																						( ar_MEDIA_period_MONTH__FINAL.slice( 24, 48 ) ).concat(  ar_MEDIA_period_MONTH__FINAL.slice( 0, 25 )  )   // ar_MEDIA_period_MONTH__FINAL

																						//ar_MEDIA_period_WEEK__FINAL[ 0 ]

																						// ar_MEDIA_period_WEEK__FINAL

																						//ar_MEDIA_WEEK__FINAL[ 0 ][  iper  +  ( iday * 48 )  ]
																					//:
																					//</ResponsiveContainer>	[0,0,0]
																					//)
																				:
																					[0,0,0]
																			}

																	/*
																	ref =	{
																				( e ) =>
																				{
																					this_._chart_data  =  e;
																				}
																			}
																	*/

																	//width={graph_size.xsize} height={graph_size.ysize}



																	id = "id_LINECHART_svg_MEDIA_allSLs_HORA"     //ComposedChart_08		"chart_03_prueba"	// id_LINECHART_svg_MEDIA_allSLs_HORA	id_RespCont_svg_MEDIA_allSLs_HORA


																	margin={{ top: 20, right: 20, left: 45, bottom: 50 }}


																	isAnimationActive={false}
														>


															<CartesianGrid stroke="#ccc"  vertical={false} />


															{
															// EJE X

																																//(  (  typeof( _ar_ALL__STREETLIGHTS_VARVALs__FINAL ) != "undefined"  )	 && 	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL  !=  null  )	&&	(  _ar_ALL__STREETLIGHTS_VARVALs__FINAL.length  >  0  )  )
																					//?
																						//Math.round(_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ].length  /  7 )
																						//Math.round(  	(_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ][ 0 ].dat).length  /  12   )
																					//:
																					//	1

															<XAxis
																	dataKey = "HORA_INICIO_PERIODO_30m"

																	tickMargin={16}

																	//interval =	{12	}
															/>

															}



															<YAxis domain = { [ parseFloat( 0.0 ), _c_MAX_INST_GROUP__VARS_BP ] }/>




															<YAxis yAxisId="right" orientation="right" domain = { [ parseFloat( 0.0 ), _c_MAX_INST_GROUP__VARS_C ] }/>




															{
															// EJE Y


															/*
															<YAxis yAxisId="left" />
															<YAxis yAxisId="right" orientation="right" />


															<YAxis
																yAxisId="right"

																orientation="right"

																//dataKey={ graph_ejeY }

																domain = { [ 0, 6 ] }
																//interval = { 10 }
															/>
															*/
															}

															{
															<Legend/>
															}


															<Line type="monotone" dataKey={ "SUM__MEDIA_B"  } stroke="green" strokeWidth={1} isAnimationActive={false}/>

															<Line type="monotone" dataKey={  "SUM__MEDIA_P"  } stroke="red" strokeWidth={1} isAnimationActive={false}/>


															<Line type="monotone" yAxisId="right" dataKey={  "SUM__MEDIA_C" } stroke="blue" strokeWidth={1} isAnimationActive={false}/>


														</LineChart>


													</ResponsiveContainer>

												</div>

												}













												{

												(  false  )  &&

												<h3 hidden> { _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME } </h3>

												}






												{

												( false )  &&

												//( this.state.LAST_re_render != "true" )  &&

												( this.state.sunRise_sunSet_GET == "true" )  &&



												<div style={{marginLeft: "-35rem"}} hidden>
												<center>
													<ResponsiveContainer	id = "id_AUX_RP_1dia_SL"	//"chart_08_prueba"

																			width={/*2500*//*1470*/2800}

																			height={/*800*/550}


																			//debounce={0}

																			isAnimationActive={false}

																			//hidden
													>


														<LineChart
																	data=	{
																				(  (  typeof( ar_MEDIA_period_MONTH__FINAL ) != "undefined"  )	 && 	(  ar_MEDIA_period_MONTH__FINAL  !=  null  )	&&	(  ar_MEDIA_period_MONTH__FINAL.length  >  0  )  )
																				?
																					//(  (  typeof( ar_MEDIA_period_WEEK__FINAL[ 0 ] ) != "undefined"  )
																					//?
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ]		//_ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ][ 0 ].dat

																						//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ 0 ]

																						_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].dat.slice(

																																						Math.floor( 6.0 * _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].dat.length / parseFloat(7.0) ) ,

																																						_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].dat.length

																																						//Math.round(_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ 0 ].dat.length / 2.0)

																																						)

																						//( ar_MEDIA_period_MONTH__FINAL.slice( 24, 48 ) ).concat(  ar_MEDIA_period_MONTH__FINAL.slice( 0, 25 )  )   // ar_MEDIA_period_MONTH__FINAL

																						//ar_MEDIA_period_WEEK__FINAL[ 0 ]

																						// ar_MEDIA_period_WEEK__FINAL

																						//ar_MEDIA_WEEK__FINAL[ 0 ][  iper  +  ( iday * 48 )  ]
																					//:
																					//</ResponsiveContainer>	[0,0,0]
																					//)
																				:
																					[0,0,0]
																			}

																	/*
																	ref =	{
																				( e ) =>
																				{
																					this_._chart_data  =  e;
																				}
																			}
																	*/

																	//width={graph_size.xsize} height={graph_size.ysize}



																	id = "id_LINECHART_svg_id_AUX_RP_1dia_SL"     //ComposedChart_08		"chart_03_prueba"	// id_LINECHART_svg_MEDIA_allSLs_HORA	id_RespCont_svg_MEDIA_allSLs_HORA


																	margin={{ top: 20, right: 20, left: 45, bottom: 50 }}


																	isAnimationActive={false}
														>



															<CartesianGrid stroke="#ccc" /*vertical={false}*/ />



															<Ttip />


															{
															// EJE X

															<XAxis
																	dataKey="NEW_timestamp_DAY"

																	/*
																	interval =	{


																				Math.round(  	parseFloat( (_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ 0 ].dat).length )   /   parseFloat( 2.0 )   )


																				}
																	*/
																	//tickMargin={17}


																	//tickCount={3}


																	//padding={{ left: 50 }}

																	//tickSize={8}
															/>

															}





															<YAxis domain = { [ 0.0, 45.0 ] }/>

															<YAxis yAxisId="right" orientation="right" domain = { [ 0.0, 2.0 ] }/>

															{
															// EJE Y


															/*
															<YAxis yAxisId="left" />
															<YAxis yAxisId="right" orientation="right" />


															<YAxis
																yAxisId="right"

																orientation="right"

																//dataKey={ graph_ejeY }

																domain = { [ 0, 6 ] }
																//interval = { 10 }
															/>
															*/
															}



															{
																/*
																<Legend/>
																*/
															}



															<Line type="monotone" dataKey={  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME      +  "_B"  } stroke="green" strokeWidth={1} isAnimationActive={false}/>

															<Line type="monotone" dataKey={ _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME  +  "_P"  } stroke="red" strokeWidth={1} isAnimationActive={false}/>

															<Line type="monotone" yAxisId="right" dataKey={  _ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME  +  "_C"  } stroke="blue" strokeWidth={1} isAnimationActive={false}/>



														</LineChart>


													</ResponsiveContainer>
												</center>
												</div>

												}












												{
												/*
													// _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ iweek ][ allSLs ].dat

												( false )  &&

												//( this.state.LAST_re_render != "true" )  &&

												( this.state.sunRise_sunSet_GET == "true" )  &&

												<div style={{marginLeft: "-35rem"}} hidden>
												<center>

													<ResponsiveContainer	id = "id_AUX_RP_1dia_SL__B"	//"chart_08_prueba"

																			width={2800}
																			//2500
																			//1470

																			height={550}
																			//880

																			//debounce={0}

																			isAnimationActive={false}

																			//hidden
													>


														<LineChart
																	data=	{
																				(  (  typeof( ar_MEDIA_period_MONTH__FINAL ) != "undefined"  )	 && 	(  ar_MEDIA_period_MONTH__FINAL  !=  null  )	&&	(  ar_MEDIA_period_MONTH__FINAL.length  >  0  )  )
																				?
																					//(  (  typeof( ar_MEDIA_period_WEEK__FINAL[ 0 ] ) != "undefined"  )
																					//?
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL[ 0 ]		//_ar_ALL__STREETLIGHTS_VARVALs[ 0 ].data
																						//_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 0 ][ 0 ].dat

																						//aux_SUM_STREETLIGHTS_VARVALs__per_WEEK[ 0 ]


																						//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].dat


																																						//.slice(

																																						//Math.floor( 6.0 * _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].dat.length / parseFloat(7.0) ) ,

																																						//_NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].dat.length

																																						//Math.round(_ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ 0 ].dat.length / 2.0)

																																						//)

																						//( ar_MEDIA_period_MONTH__FINAL.slice( 24, 48 ) ).concat(  ar_MEDIA_period_MONTH__FINAL.slice( 0, 25 )  )   // ar_MEDIA_period_MONTH__FINAL

																						//ar_MEDIA_period_WEEK__FINAL[ 0 ]

																						// ar_MEDIA_period_WEEK__FINAL

																						//ar_MEDIA_WEEK__FINAL[ 0 ][  iper  +  ( iday * 48 )  ]
																					//:
																					//</ResponsiveContainer>	[0,0,0]
																					//)
																				:
																					[0,0,0]
																			}


																	//ref =	{
																	//			( e ) =>
																	//			{
																	//				this_._chart_data  =  e;
																	//			}
																	//		}


																	//width={graph_size.xsize} height={graph_size.ysize}



																	id = "id_LINECHART_svg_id_AUX_RP_1dia_SL__B"     //ComposedChart_08		"chart_03_prueba"	// id_LINECHART_svg_MEDIA_allSLs_HORA	id_RespCont_svg_MEDIA_allSLs_HORA


																	margin={{ top: 20, right: 20, left: 45, bottom: 50 }}


																	isAnimationActive={false}
														>


															{
																						//vertical={false}
															<CartesianGrid stroke="#ccc"  />
															}


															<Ttip />


															{
															// EJE X

															<XAxis
																	dataKey="NEW_timestamp_COMPLETE"


																	interval =	{


																				//Math.round(  	parseFloat( ( _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ 0 ].dat).length )   /   parseFloat( 7.0 )   )


																				}

																	//tickMargin={17}


																	//tickCount={3}


																	//padding={{ left: 50 }}

																	//tickSize={8}
															/>

															}





															<YAxis domain = { [ 0.0, 45.0 ] }/>

															<YAxis yAxisId="right" orientation="right" domain = { [ 0.0, 2.0 ] }/>

															{
															// EJE Y



															//<YAxis yAxisId="left" />
															//<YAxis yAxisId="right" orientation="right" />


															//<YAxis
															//	yAxisId="right"

															//	orientation="right"

															//	//dataKey={ graph_ejeY }

															//	domain = { [ 0, 6 ] }
															//	//interval = { 10 }
															///>

															}



															{

																//<Legend/>

															}



															<Line type="monotone" dataKey={  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME      +  "_B"  } stroke="green" strokeWidth={1} isAnimationActive={false}/>

															<Line type="monotone" dataKey={ _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME  +  "_P"  } stroke="red" strokeWidth={1} isAnimationActive={false}/>

															<Line type="monotone" yAxisId="right" dataKey={  _NEW__ar_ALL__STREETLIGHTS_VARVALs__FINAL__WEEK[ 3 ][ GLOB_numSL_Grafica_AUX ].SL_NAME  +  "_C"  } stroke="blue" strokeWidth={1} isAnimationActive={false}/>



														</LineChart>


													</ResponsiveContainer>
												</center>
												</div>
												*/
												}
















												{


												( this.state.LAST_re_render  !=  "true" )  &&

												( this.state.sunRise_sunSet_GET == "true" )  &&

												//(  _cont_rerender_svg == 0  )  &&



												//(  NUM_GENERATE_WEEK_GRAFS  == 0  )  &&


												(   TODOS_DATOS_ACCESIBLES  ==  true   )  &&



												<ReturnGraphSLweek />

												}








											{/*
											<br/>

											<div>
												<center> <b> <p> Prueba Render Gráficas </p> </b> </center>
											</div>
											*/}

											<br/>

											{/*
											<p>  sunriseDAY[0]=  { this_.state.sunriseDAY }  </p>
											*/}





											{

											//( this.state.SCRIPT_svg_rendered == "true" )  &&

											//( this.LAST_re_render == "true" )  &&		// this_.LAST_re_render

											<center>

											<div id="iframeContainer_PDFMake" style={{margin: "auto", padding: "auto"}}>
											</div>

											</center>
											}






											{
											/*
											( this.state.LAST_re_render == "true" )  &&


											//( this_.LAST_re_render == "true" )  &&
											<script>
												////window.alert( "LAST RE-RENDER" );
											</script>
											*/
											}


											<br/>
											<br/>

										</center>
										</div>
										}



									</div>






									</center>

								</div>


					);	// cosnt HTML_RENDER =			 // return()


					//--------

					return	HTML_RENDER;

				}// else


			}// if	(   (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  ||  (  this.props.auth.user.user_profile.contratado_informes  ==  true  )   )
			else
			{
				return(

							<div>

								<br/>
								<br/>

								<center>

									No hay permiso

								</center>

							</div>
				) //

			}// else


		}// if (   this.state.sunRise_sunSet_GET  ==  "true"    /*_data_Retrieved ==*/  /*true*/  )
		else
		{
			//window.alert( "NO HTML" );


			const HTML_RENDER =	(

						<div	id ="dropdowncontainer"
								className = "container-fluid"
								width="100%"

								//onload=""

								style={{margin: "auto"}}

						> 	{/*marginBottom:"35px", marginLeft:"-10px"*/}


							{
							/*
							<div className="row">
								{
								  //<div className="row">: LISTA DESPLEGABLE INSTALLATION_GROUP
								}
								<div id="cab-general" className="container-fluid" style={{marginLeft: "3px"}}>
									<div className="actions-left col-md-4 col-xs-12" style={{marginTop: "-5px"}}>


										<li className="nav-item dropdown" >

											<SelectorInstalaciones

												showAll = { false }


												onDataChanged =
																{
																	( e ) =>
																	{

																		//CONT_axios_Alarms = 0;

																		this.setState(
																						{
																							selected_installationgroup: e,

																							total_streetlights: e.info.streetlights.length
																						}
																		);

																		//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);




																		//CONFIG[ "setPreferredInstallation" ](  e.value  );
																	}
																}
											/>

										</li>

									</div>
								</div>
								{
									//<div className="row">: END: LISTA DESPLEGABLE INSTALLATION_GROUP
								}
							</div>
							*/
							}



							<center>


							<br/>


							{

							/*
							<Returnobjhtml />
							*/

							<div>
							<center>
								<br/>
								{ /*console.log(  JSON.stringify(  svg_01_outerHTML  )  )*/  }

								{
									//<h2> Instalación &#34;{ this.state.selected_installationgroup.group_name /*_obj_Inst_Group.group_name*/ }&#34; </h2>
								}

								<h2> {  this.state.selected_installationgroup.group_name  } </h2>

								</center>
							</div>
							}

							{
							<div hidden>
								<br/>
								{ /*console.log(  JSON.stringify(  svg_01_outerHTML  )  )*/  }
								<p>  <b> GLOBAL_SIG_dat_SL_week = { GLOBAL_SIG_dat_SL_week }  </b> </p>

								<p hidden>  <b> const_cota_Min_Corriente_ENCENDIDO = { const_cota_Min_Corriente_ENCENDIDO }  </b> </p>
							</div>
							}



							{
							<span>

								{
									( this.state.LAST_re_render == "false" )  &&

									this.showSpinner()

										/*
															this.setState(
										{
											//profileName:"Schedule",
											//showSpinner: false,

											sunriseDAY: this.state.sunriseDAY.push( day_sunrise ),
											sunsetDAY:  this.state.sunsetDAY.push(  day_sunset ),
										}
										);
										*/
								}
							</span>
							}









							</center>

						</div>


			);	// cosnt HTML_RENDER =			 // return()


			//--------

			return	HTML_RENDER;






		}// else -----------




	}// render ()-------------------------------------------------------------------------------------------


}// class Informe_mensual extends Component


//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const mapStateToProps =	function ( state )
						{
							return	{
										auth:	 state.auth,
										core: 	state.core
									}
						}

const mapDispatchToProps =	{
								saveProfile: 	saveProfile
							}


export default withTranslation() (  connect( mapStateToProps, mapDispatchToProps )( Informe_mensual )  );