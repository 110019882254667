
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { login } from '../actions/auth';

import { getInstallations } from '../actions/core';


import Spinner from 'react-spinner-material';

import fileDownload from 'js-file-download';

import { setup } from 'axios-cache-adapter';
//import axios from 'axios';
import CONFIG from '../config/config';


import * as am4core from "@amcharts/amcharts4/core";

import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import Moment from 'moment';


const $ = window.$;

const COLORS = [ '#0088FE', '#00C49F', '#FFBB28', '#FF8042' ];


const NOMBRE_CACHE = "graficador_ilumek_cache";


let headers = [];


am4core.useTheme( am4themes_animated );


const axios = setup(
					{
						cache:	{
									maxAge: 15 * 1000
								}
					}
)//


const decodeBase64 =	function b64DecodeUnicode ( str )
						{
							// Going backwards: from bytestream, to percent-encoding, to original string.

							return decodeURIComponent(
														atob( str ).split( '' ).map(
																						function ( c )
																						{
																							return '%' + (  '00' + c.charCodeAt(0).toString( 16 )  ).slice( -2 );
																						}
														).join( '' )

							); //return decodeURIComponent(
						};

//------


class CustomizedAxisTick extends Component
{

	render ( )
	{
		const { x, y, stroke, payload } =  this.props;

		return (
					<g transform={`translate(${x},${y})`}>
						<text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-10),scale(0.8)">
							{  payload.value.split( "+" )[ 0 ].replace( "T"," " )  }
						</text>
					</g>
		);// return

	}// render()

}; //class CustomizedAxisTick extends Component


//----


class CustomTooltip extends Component
{

	render ( )
	{
		const { active } = this.props;
		let { payload, label } = this.props;

		if (  active  &&  payload  )
		{
			label = label.replace( "T", " " ).split( "+" )[ 0 ];

			return (
						<div className="custom-tooltip" style = { { backgroundColor: "lightgrey", padding: "10px", opacity: "0.8" } }>

							<p className="label">
								{`${label}`}
							</p>
							
							<hr/>

							{
							payload.map(
											( item, index ) =>
											{
												return (
															<p className="item" style = { {color: item.color } }>
																{ item.name } : { this.props.var_unit }
															</p>
												)// return
											}

							)// payload.map()
							}
						</div>

			);// return ()

		}// if

		return null;

	}// render()

};// class CustomTooltip extends Component


//----


class CalculatedChart extends Component
{

	constructor ( props )
	{
		super( props );

		this.state =
					{
						installations: props.core.installations,
						selected_installation: props.selected_installation,

						//{installation_name: "", id:"", streetlights: []},

						selected_streetlight: { name: "" },
						view_as_cards: true,

						chartingVars: {},

						results: {},

						isLoading: false,
						showSpinner: false,

						checkboxes: {},
						error: null,
						focusedInput: undefined,
						minState: "Ocultar",

						disable_can_chart: true,
						disabled: props.disabled
					};

		this.state.chartingVars[ this.props.var_unit ] =
														{
															"vars": [],
															"streetlights": props.selected_streetlights
														};

		this.state.results[ this.props.var_unit ] = [];
		this.selectedVars = {};
		this.selectedVars[props.var_unit] = [];
		this.selectedStreetlights = {};
		this.selectedStreetlights[ props.var_unit ] = props.selected_streetlights;

		this.showSpinner = this.showSpinner.bind( this );
		this.doChart = this.doChart.bind( this );

		this.checkCache = this.checkCache.bind( this );

		this.prevSelectedInstallationId = -1;

		this.justDoItOnceDone = false;

	} // constructor ( props )

	//--------


	componentDidMount ( )
	{    
		let data =	[
						{
							"timestamp":"2019-03-12T12:00:00+01:00",
							"date":"2019-03-12 12:00:00",
							"480014f000m_B":13.9
						},

						{
							"timestamp":"2019-03-12T12:05:00+01:00",
							"date":"2019-03-12 12:05:00","480014f000m_B":12.9
						}
					];

		this.doChart( [] );

		setTimeout(
					( ) =>
					{
						this.justDoItOnceDone = true;
					}

					,10000
		);//

	}// componentDidMount ( )


	//--------


	checkCache ( )
	{
		this.selectedVars[ this.props.var_unit ] = [];

		if (  "vartype" in this.state.chartingVars[ this.props.var_unit ]  )
		{
			this.state.disable_can_chart = true;

			Object.keys(
							this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
			).map(
					( item, index ) =>
					{
						let this_value = false;

						let cache_data = JSON.parse(  localStorage.getItem( NOMBRE_CACHE )  );

						if (  cache_data && cache_data[this.state.selected_installation.id]  !==  undefined  )
						{
							if (  this.props.var_unit in cache_data[ this.state.selected_installation.id ]  )
							{
								if (
									cache_data[ this.state.selected_installation.id ][ this.props.var_unit ].indexOf(	this.state.chartingVars[ this.props.var_unit ][ "vartype" ][ item ].code ) > -1
								)
								{
									this.selectedVars[ this.props.var_unit ][  this.state.chartingVars[ this.props.var_unit ][ "vartype" ][ item ].code  ] = true;
									this_value = true;
									this.state.disable_can_chart = false;
								}                                                                              
							}
						}

						this.state.checkboxes[index] = this_value;                    
					}
			);// .map()
		}// if                

	}// checkCache ( )

	//--------


	doChart ( data )
	{
		if (  this.chart  )
		{
			this.chart.dispose();
		}

		let vars = [];


		if (  this.selectedStreetlights[ this.props.var_unit ]  !==  undefined  )
		{
			if (  Object.keys( this.selectedStreetlights[ this.props.var_unit ] ).length > 0  )
			{
				Object.keys(
							this.selectedStreetlights[ this.props.var_unit ]
				).map(
						( item_sl, index ) =>
						{
							Object.keys(
										this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
							).map(
									( item, index ) =>
									{
										let varname = "";

										if (  this.selectedStreetlights[ this.props.var_unit ][ item_sl ] != false  )
										{
											varname = this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  +  "_" +  item;

											vars.push( varname );    
										}// if
									}
							)// Object.keys()
						}
				)// ... .map()
			}
		}// if


		if (  vars.length == 0  )
		{
			return;
		}// if


		let chart = am4core.create( this.chartdiv, am4charts.XYChart );

		chart.data = data;

		// Enable export
		chart.exporting.menu = new am4core.ExportMenu();

		// Set input format for the dates
		chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

		// Create axes
		var dateAxis = chart.xAxes.push(  new am4charts.DateAxis()  );
		var valueAxis = chart.yAxes.push(  new am4charts.ValueAxis()  );

		if (  this.props.var_unit == " '%;"  )
		{
			valueAxis.max = 100;
			valueAxis.min = 0;
		}

		let scrollbarX = new am4charts.XYChartScrollbar();


		var series;
		var unit = this.props.var_unit;

		if (  unit == "all" )
		{
			unit = "";
		}

		valueAxis.numberFormatter = new am4core.NumberFormatter();
		valueAxis.numberFormatter.numberFormat = "#.00" + unit; 

		vars.map(
					( item, index ) =>
					{
						series = chart.series.push(  new am4charts.StepLineSeries()  );    
						series.dataFields.dateX = "date";
						series.strokeWidth = 2;
						series.dataFields.valueY = item;
						series.name = item;
						series.tooltipText = "{name}: >> [bold]{valueY}[/]"  +  unit  +  "\n\n"  +  "[small]{date}[/]";
						series.tensionX = 0.8;

						scrollbarX.series.push( series );
					}
		);// ... .map()


		var interfaceColors = new am4core.InterfaceColorSet();

		chart.dateFormatter.dateFormat = "yyyy-MM-dd H:m:s";

		
		chart.scrollbarX = scrollbarX;

		chart.cursor = new am4charts.XYCursor();

		this.chart = chart;

	}// doChart ( data )

	//--------


	componentWillUnmount ( )
	{
		if (  this.chart  )
		{
			this.chart.dispose();
		}

	}// componentWillUnmount ( )

	//--------


	componentWillReceiveProps ( nextProps )
	{
		console.log( "ASDASD" );

		this.setState( { selected_installation: nextProps.selected_installation } );

		let vartypes = {};

		nextProps.vartype.map(
								( item, index ) =>
								{
									vartypes[ item.code ] =	{
																"name": item.name,
																"code": item.code
															};
								}
		); //... .map()

		this.state.chartingVars[ this.props.var_unit ] =	{
																"vars":	[
																			{ "name": nextProps.vartype }
																		],

																"streetlights": nextProps.selected_streetlights,

																"vartype": vartypes
															};
		this.doChart( [] ) ;

	}// componentWillReceiveProps ( nextProps )

	//--------


	showSpinner ( )
	{
		if (  !this.state.showSpinner  )
		{
			return ( <div/> )
		}

		return(
				<div style={{position: "absolute", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "10"}}>

					<div style={{position: "absolute", left: "45%", top: "40%"}}>
						<Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
					</div>

				</div>
		); //return

	}// showSpinner ( )

	//--------


	recogerDatos ( unit )
	{
		// And save to LocalStorage for future options...

		let token = "Token " + this.props.auth.token;

		let filtro =
					{
						"vars": [],
						"streetlights": []
					};

		let cache_data = localStorage.getItem( NOMBRE_CACHE );
		let installation_id = this.state.selected_installation.id;

		if (  !cache_data  )
		{
			cache_data =	{
								"main": {},
								"all": {},
							};

			cache_data[ installation_id ] = {};
			cache_data[ installation_id ][ unit ] = [];

		}
		else
		{
			cache_data = JSON.parse( cache_data );
		}


		if (  ( this.state.selected_installation.id in cache_data ) == false  )
		{
			cache_data[ this.state.selected_installation.id ] = {};
		}

		cache_data[ this.state.selected_installation.id ][ unit ] = [];

		Object.keys(
					this.selectedVars[ unit ]
		).map(
				( item, index ) =>
				{
					if (  this.selectedVars[ unit ][ item ] == true  )
					{
						this.props.vartype.map(
												( item2, index ) =>
												{
													if( item2.code == item )
													{  
														item2.vars.map(
																		( var_code, index ) =>
																		{
																			filtro.vars.push( var_code );
																		}
														);//... .map()
													}
												}
						);//... .map()

						cache_data[ this.state.selected_installation.id ][ unit ].push( item );
					}// if
				}//
		);//... .map()


		Object.keys(
					this.selectedStreetlights[ unit ]
		).map(
				( item, index ) =>
				{
					if (  this.selectedStreetlights[ unit ][ item ] != false  )
					{
						filtro.streetlights.push( item );
					}
				}
		);//... .map() 


		cache_data[ this.state.selected_installation.id ][ "streetlights" ] = filtro.streetlights;

		cache_data[ "selected_installation_id" ] = this.state.selected_installation.id;

		// Save cache
		localStorage.setItem(  NOMBRE_CACHE, JSON.stringify( cache_data )  );

		this.setState( { showSpinner: true } );


		let startDate = this.props.startDate.unix();
		let endDate = this.props.endDate.unix();
		
		if (  this.props.endDate.isSameOrAfter( Moment() ,"day" )  )
		{
			endDate = Moment().unix();
		}
		

		axios.get(
					CONFIG.api_url  +
						"/installations/"  +  this.state.selected_installation.id  +

						"/data.json?page_size=1000&vars="  +  filtro.vars  +

						"&sl="  +	filtro.streetlights  +

						"&start_date="  +  startDate  +
						"&end_date="  +  endDate,

					{
						"headers":	{      
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
				( result ) =>
				{
					//let temp = this.state.results;
					//temp[this.props.var_unit]=result.data;

					console.log( "Read calculated!" );

					let temp = [];
					let info = {};

					this.props.vartype.map(
											( item, index ) =>
											{
												info[ item.code ] =	{
																		"vars": item.vars,
																		"name": item.name,
																		"formula": item.formula
																	};
											}
					);// ... .map()


					result.data.map(
									( item, index ) =>
									{
										let thisval = item;
										let calculatedvalues = {};
										let values = {};
										let sl_props = {};

										this.props.selected_installation.streetlights.map(
																							( item, index ) =>
																							{
																								sl_props[ item.id ] =
																														{
																															total_power: item.total_power,
																															panel_peak_power: Math.max( 1, item.panel.peak_power ),
																															battery_nominal_voltage: Math.max (1, item.battery.nominal_voltage )
																														}
																							}
										);// ... .map()


										Object.keys(
													this.props.selected_streetlights
										).map(
												( item_sl, index_sl ) =>
												{
													let varvalues = {};

													Object.keys( info ).map(
																				( item_var, index_var ) =>
																				{
																					info[ item_var ].vars.map(
																												( item_var2, index_var2 ) =>
																												{
																													varvalues[ item_var2 ] = item[  this.props.selected_streetlights[ item_sl ]  +  "_"  +  item_var2  ];
																												}
																					);//... .map()

																					if (  this.selectedVars[ unit ][ item_var ] == true  )
																					{
																						calculatedvalues[
																											this.props.selected_streetlights[item_sl]  +  "_"  +  item_var
																										] =
																											info[ item_var ].formula(  varvalues, item_sl,  sl_props[ item_sl ]  );
																					}//if
																				}//
													);//... .map()
												}
										);//... .map()


										Object.keys( calculatedvalues ).map(
																				( item_val, index_val ) =>
																				{
																					thisval[ item_val ] = calculatedvalues[ item_val ];
																				}
										)//... .map()

										temp.push(thisval);
									}//
					);//result.data.map()

					this.doChart( temp );
					this.setState( { showSpinner: false } );
				}
		).catch(
				error =>
				{
					console.log( error );

					this.setState(
									{
										error,
										isLoading: false,
										showSpinner: false
									}
					)//.setState()
				}
		);// .catch()

	} // recogerDatos ( unit )


	render ( )
	{
		console.log( "ASDASD2" );

		if (  this.state.selected_installation === undefined  ||  this.selectedStreetlights[this.props.var_unit] == undefined  )
		{
			return <div/>
		}

		let vars = [];

		if (  Object.keys(  this.selectedStreetlights[ this.props.var_unit ]  ).length > 0  )
		{
			Object.keys(
						this.selectedStreetlights[ this.props.var_unit ]
			).map(
					( item_sl, index ) =>
					{
						Object.keys(
									this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
						).map(
								( item, index ) =>
								{
									let varname = "";

									if (  this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  !=  false  )
									{
										varname = this.selectedStreetlights[ this.props.var_unit ][ item_sl ]  +  "_" +  item;
										vars.push( varname );
									}	
								}
						)// .map()
					}
			)//... .map()
		}//if

		this.checkCache();

		let unit = this.props.var_unit;


		if (  unit == "all" )
		{
			unit = "Todas las vars.";
		}

		let state_disabled = this.state.disable_can_chart  ||  this.props.disabled;


		if (  !this.justDoItOnceDone  &&  !state_disabled  )
		{
			this.selectedStreetlights[ this.props.var_unit ] = this.props.selected_streetlights;

			this.recogerDatos( this.props.var_unit );      

			this.justDoItOnceDone = true;
		}


		return (
				<div className="container-fluid">
					{ /* ------------    this.props.var_unit ----------   */}
					<div className="col-md-12 cont-graph" style={{marginTop: "30px"}}>


						<div className="row container2 graph-actions-inline2">

							<h2 className="col-md-4 graficador-title" style={{marginLeft: "20px"}}>

								{ this.props.title }

								<img src="img/ico/gray/ico-a-circle-arrow.svg" alt=""/>

								[
								<a
									href = ""
									className = "nav-link"
									style = { {color: "grey"} }

									onClick =
											{
												( e ) =>
												{
													e.preventDefault();

													if (  this.state.minState == "Ocultar"  )
													{
														this.setState(  { minState: "Mostrar" }  );
														$( this.chartdiv ).hide();
													}
													else
													{
														this.setState( { minState: "Ocultar" } );
														$( this.chartdiv ).show();
													}
												}
											}
								>
									{ this.state.minState	}
								</a>
								]
							</h2>


							<div id="graficador-selects" className="col-md-4">

								<ul className="dropdown-graficador">
									<li className="nav-item dropdown">

										<a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">                                  
											Tipo de variable
										</a>

										<ul className="dropdown-menu">
										{
											(  "vartype"  in  this.state.chartingVars[ this.props.var_unit ]  )  &&

											Object.keys(
														this.state.chartingVars[ this.props.var_unit ][ "vartype" ]
											).map(
													( item, index ) =>
													{
														let to_return =
																		(
																		<li>

																			<input
																				className = "dropdown-item form-check-input"
																				type = "checkbox" 
																				value = { true }

																				checked = { this.state.checkboxes[ index ] }
																				style = { {marginLeft: "-65px"} }

																				onChange =
																							{
																								( e ) =>
																								{ 
																									this.selectedVars[ this.props.var_unit ][ item ] = e.target.checked;

																									let temp = this.state.checkboxes;
																									temp[ index ] = e.target.checked;

																									this.setState(  { checkboxes: temp }  );
																									this.setState(
																													{
																														disable_can_chart: (  Object.values(  this.selectedVars[ this.props.var_unit ]  ).indexOf( true )  <  0  )
																													} 
																									);//.setState()
																								}
																							}
																			/>

																			<a className = "dropdown-item" href = "#">
																				{`${this.state.chartingVars[this.props.var_unit]["vartype"][item].name} (${this.state.chartingVars[this.props.var_unit]["vartype"][item].code})`}
																			</a>

																		</li>
																		); //

														return to_return;
													}
											)// .map()
										}
										</ul>

									</li>
								</ul>

							</div>


							<div className="col-md-2">
								<button
									type = "submit"
									className = "btn btn-orange"
									disabled = { state_disabled }
									data-toggle = "modal"
									data-target = ".bd-filtrar-modal-sm"

									onClick =
												{
													( e ) =>
													{                              
														this.selectedStreetlights[this.props.var_unit]=this.props.selected_streetlights;
														this.recogerDatos(this.props.var_unit);
														e.preventDefault();
													}
												}
								>
									Recoger datos
								</button>                        
							</div>

						</div>


						<br/>

						<div className="perfil">
							<div className="row">

								<div className="col-sm-12 col-md-12 col-xl-12">
								{
									this.showSpinner()
								}
									<div ref = {  ( e ) => {	this.chartdiv = e;	}  }  style = { { width: "100%", height: "500px", zIndex: "1" } }>
										<div style={{position: "absolute", marginLeft: "45%", marginTop: "250px"}}>
											... seleccione variables ...
										</div>
									</div>

								</div>

							</div>
						</div>


					</div>
				</div>
		) // return (

	} // render ()

} // class CalculatedChart extends Component


//----------

const mapStateToProps =	function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}


const mapDispatchToProps =	{
								getInstallations: getInstallations,
							}


export default connect(  mapStateToProps, mapDispatchToProps  )( CalculatedChart );