
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {getInstallations, getInstallationDetail, deleteScheduleProfile} from '../actions/core';

import { Link } from "react-router-dom";


import Spinner from 'react-spinner-material';

import CONFIG from '../config/config';

import Select from 'react-select';

import { withTranslation } from 'react-i18next';

import { MdDelete } from "react-icons/md";
import { Trans } from 'react-i18next';

import SelectorInstalaciones from './components/installations_select';
import { setup } from 'axios-cache-adapter';


const $ = window.$;


const axios = 	setup(	{
							cache:	{
										maxAge: 1000
									}
						}
				)// setup()


class PerfilesMaestros extends Component
{

	constructor ( props )
	{
		super( props );

		this.state =
					{
						showLoader: props.core.waiting_for_profile_info,
						showSpinner: false,
						scheduleprofiles: [],

						selected_installationgroup:	{
														group_name: "",
														id: "",
														info:	{
																	streetlights: [],
																	available_sensors:[]
																}
													},

						prev_selected_installation_id : "",
						selected_profile_id: "",
						view_as_cards: true
					};

		this.prev_selected_installation_id = "";


		this.showSpinner=this.showSpinner.bind( this );

		this.getScheduleProfiles = this.getScheduleProfiles.bind( this );


		this.tipoPerfil =  0;	// 1

	}// constructor ( props )



	componentDidMount ( )
	{
		//this.props.getInstallations(this.props.auth.token);

	}// componentDidMount ( )



	showSpinner ( )
	{
		if (  !this.state.showSpinner  )
		{
			return ( <div/> )
		}

		return(
					<div style={{position: "absolute", left: "48%", top: "45%"}}>
						<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true}/>
					</div>
		);

	}//showSpinner ( )



	getScheduleProfiles ( selected_installation_group )
	{

		//console.log( selected_installation_group );


		let token = "Token " + this.props.auth.token;

		this.setState( {  showSpinner: true  }  );


		axios.get(

					CONFIG.api_url  +  "/installationgroups/"  +  selected_installation_group.id  +  "/scheduleprofiles",

					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					},
		).then(
		//
				( result ) =>
				{


					//console.log( result.data );


					this.setState(
									{
										scheduleprofiles: result.data,
										showSpinner: false
									}
					)//

					//this.setState({resumen: result.data, showLoader:false});
				}

		).catch(
					error =>
					{
						//console.log( error );
						this.setState(  { showSpinner: false }  )
					}

		);//.catch()

	}// getScheduleProfiles ( selected_installation_group )




	render ( )
	{
		let needUpdate = false;


		//console.log( this.state.selected_installationgroup.info.max_cpu_version );

		//-----

		return(

				<div className="container-fluid">

					{
					false  &&  this.showSpinner()
					}


					{
					this.props.auth.user.user_profile.userlevel == "EKIONA" &&

					<center> <br/> <h3 style={{marginTop: -1, marginBottom: -5}}> { this.state.selected_installationgroup.group_name } </h3> </center>
					}

					<div className="row">

						<div id="cab-general" className="container-fluid" style={{marginLeft: "3px"}}>

							<div className="actions-left col-md-4 col-xs-12">

								<li className="nav-item dropdown" >

									<SelectorInstalaciones

										showAll = { false }

										onDataLoaded =	{
															( e ) =>
															{
																//console.log( e );
																this.setState(  { showLoader:false }  );
																//
																//this.setState({selected_installationgroup: e});
															}//
														}

										onDataChanged =	{
															( e ) =>
															{
																//console.log( e );

																this.setState(  { selected_installationgroup: e }  );
																this.getScheduleProfiles( e );

																//this.props.parent.updateOptionVisibility(this.state.installations[e.index].max_cpu_version);
																//CONFIG["setPreferredInstallation"](e.value);
															}
														}
									/>

								</li>

							</div>


							<div className="col-xs-4">
							</div>


							<div className="actions-right col-xs-2 d-none d-md-block" style={{marginLeft: "25px"}}>
							{
								false  &&
								<a href="farolas-tabla.html" className="btn btn-gray">
									Ver en tabla
								</a>
							}
								<button
										type = "submit"

										className="btn btn-orange"

										onClick =	{
														( e ) =>
														{
															//console.log(  this.props );
															//window.alert( "Pulsado!" );

															this.props.history.push(   "/perfiles/-1/detalle/?installationgroup_id="  +  this.state.selected_installationgroup.id   );

															e.preventDefault();
														}
													}
								>
									<Trans>profiles.new</Trans>

								</button>

							</div>


							<center className="d-md-none" style={{marginTop: "10px"}}>
							{
								false  &&
								<a href="farolas-tabla.html" className="btn btn-gray">
									Ver en tabla
								</a>
							}
								<button	type="submit"
										className="btn btn-orange"

										onClick =	{
														( e ) =>
														{
															this.props.history.push(  "/perfiles/-1/detalle/?installationgroup_id="  +  this.state.selected_installationgroup.id  );
															e.preventDefault();
														}
													}
								>
									<Trans>profiles.new</Trans>
								</button>
							</center>


						</div>


						<div id="filtrar" className="modal fade bd-filtrar-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

							<div className="modal-dialog modal-sm modal-dialog-centered">
								<div className="modal-content">

									<div className="modal-header">
										<h5 className="modal-title">
											Filtrar
										</h5>

										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">
												×
											</span>
										</button>
									</div>


									<div className="modal-body">
										<input className="campo-modal form-control form-control-lg" type="text" placeholder="Palabra clave..."/>
									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-orange">
											Buscar
										</button>
									</div>

								</div>
							</div>

						</div>


						<div id="deletemodal" className="modal fade bd-filtrar-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

							<div className="modal-dialog modal-sm modal-dialog-centered">
								<div className="modal-content">

									<div className="modal-header">

										<h5 className="modal-title">
											Borrar
										</h5>

										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>

									</div>


									<div className="modal-body">
										¿Está seguro?
									</div>


									<div className="modal-footer">

										<button
												type = "button"
												className = "btn btn-orange"

												onClick =	{
																( e ) =>
																{
																	this.props.deleteScheduleProfile(  this.props.auth.token,  this.state.selected_profile_id  );

																	const this_ = this;
																	const installation_group = this_.state.selected_installationgroup;

																	setTimeout(
																					( ) =>
																					{
																						this_.getScheduleProfiles(  installation_group  );

																						$( "#deletemodal" ).modal( "hide" );
																					}

																					, 2000
																	);//
																}
															}
										>
											Sí
										</button>

									</div>

								</div>
							</div>

						</div>


					</div>




					<div id="contenido-perfiles" className="container-fluid">

						<div className="row vista-bloques cont-meses">

							<div className="d-md-none" style={{height: "50px"}}>
								&nbsp;
							</div>

							{

							(  this.state.scheduleprofiles !== []  &&  this.state.scheduleprofiles.length > 0  )  &&

							this.state.scheduleprofiles.map(
							//

								( item, index ) =>
								{

									//console.log(  this.state.selected_installationgroup.ELORA_dimming_mode  );
									//console.log( item.tipoPERFIL );

									if (  item.tipoPERFIL == this.state.selected_installationgroup.ELORA_dimming_mode  )
									{
										return	(
										//
											<div className="col-sm-12 col-md-4 col-xl-2">

												<div className="mes">

													<div className="cab-mes" style={{width: "100%", "height": "30px"}}>

														<h3> { item.name } </h3>

														<Link to = {`/perfiles/${item.id}/detalle/?installationgroup_id=${this.state.selected_installationgroup.id}`}>
															<img
																src="/img/ico/gray/ico-b-edit.svg"
																data-toggle="tooltip"
																data-placement="bottom"
																title=""
																alt="Editar"
																data-original-title="Editar"
															/>
														</Link>

													</div>


													<div className="cont-mes"style={{display: "inline-block"}}>
													</div>


													<div className="cont-mes" style={{maxHeight: "200px", width: "100%"}}>

														<div style={{display: "inline-block", height: "90%", width: "95%"}}>

															<Link to={`/perfiles/${item.id}/detalle/?installationgroup_id=${this.state.selected_installationgroup.id}`}>

																<div
																		className = ""
																		style = {{float: "left", position: "absolute", color: "black"}}
																>
																	100%
																</div>

																<img
																	src = {`${CONFIG.server_url}${item.thumbnail}`}
																	alt = ""
																	style =
																			{
																				{
																					float: "left",
																					paddingRight: "5%", height: "146px"
																				}
																			}
																/>

																<div className="" style = {{float: "left", marginTop: "80px", position: "absolute", color: "black"}}>
																	0%
																</div>

															</Link>

														</div>

														<div style={{marginLeft: "5%", marginTop: "-30px", marginBottom: "40px"}}>

															<table style={{width: "100%"}}>

																<tr>
																	<td style={{textAlign: "left"}}>
																		<img src="/img/sunset.png" style={{height: "30px", width: "30px", marginLeft: "35%"}}/>
																	</td>

																	<td style={{textAlign: "right"}}>
																		<img src="/img/sunrise.png" style={{height: "30px", width: "30px", marginRight: "25%"}}/>
																	</td>
																</tr>

															</table>

														</div>

													</div>

													{
													item.is_template == false  &&

													<a
														href=""
														data-toggle="modal"
														data-target="#deletemodal"

														onClick =	{
																		( e ) =>
																		{
																			this.setState(
																							{
																								selected_profile_id:	item.id
																							}
																			);
																		}
																	}
													>
														<MdDelete color="red" size={25}/>
													</a>
													}


													{
														(  this.props.auth.user.user_profile.userlevel == "EKIONA"  )  &&

														(  this.state.selected_installationgroup.info.max_cpu_version  ==  3  )  &&


														<center>

															<p>
																mode:  <b>{ item.tipoPERFIL }</b>
															</p>

														</center>
													}


													{
													item.is_template == true  &&

													<div className="d-xs-none d-md-none d-lg-none d-xl-none" style={{minHeight: "30px"}}>
													</div>
													}
												</div>

											</div>
										//
										) // return


									}// if


								} // ( item, index ) =>

							)//.map()
							}

						</div>

					</div>


				</div>
		)// return

	}// render()

}// class PerfilesMaestros extends Component




const mapStateToProps =
						function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}

const mapDispatchToProps =	{
								getInstallations: getInstallations,
								getInstallationDetail: getInstallationDetail,
								deleteScheduleProfile: deleteScheduleProfile
							}

export default withTranslation() (  connect( mapStateToProps, mapDispatchToProps ) ( PerfilesMaestros )  );