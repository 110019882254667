



		//  ¡ VER EL EQUIVALENTE EN STREETLIGHTSERVER EN DJANGO! CAMBIARON COSAS: CONTRASTAR


const vCPU =	{
					"SIN_TELEGESTION":	0,

					"klm27v1":			1,
					"klm27v2":			2,
					
					//"klm27v3":			6,	//3,

					//"klm29v1":			4,				
					//"klm29v2":			5,

					"elorav1":			3//,	//6,

					//"SIN_DEF_1":		7,
					//"SIN_DEF_2":		8

				}; // vCPU



/*
0                   No tiene telegestión	SIN_TELEGESTION

1                   KLM2.7_V1				klm27v1
2                   KLM2.7_V2				klm27v2
3                   KLM2.7_V3				klm27v3

4                   KLM2.9_V1				klm29v1
5                   KLM2.9_V2				klm29v2

6                   ELORA_V1				elorav1
*/


//-------------------

export default vCPU;
